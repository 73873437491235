/* eslint-disable */
import {
    getProductCatalogs
} from '@/api/django';

const state = {
    catalogs: {},
    catalogProductsList: [],
    catalogCategoriesList: [],
    filters: {
        limit: 20,
        offset: 0,
    },
    searchQuery: '',
    next: '',
    busy: false,

};

const mutations = {
    SET_CATALOGS(state, catalogs) {
        state.catalogs = catalogs;
    },
    SET_NEXT_PAGE(state, { results, next }) {
        state.catalogs.results.push(...results);
        state.next = next;
        state.filters.offset = next !== null ? +next.split('&').find(string => string.trim().startsWith('offset=')).split('=').pop() : 0;
    },
    SET_FILTER_FIELD(state, { field, value }) {
        state.filters[field] = value;
        state.filters.offset = 0;
    },
    SET_SEARCH_QUERY(state) {
        let searchQuery = [];
        let filters = Object.entries(state.filters);
        filters.forEach(([ key, value ]) => {
            if (key && value ) searchQuery.push(`${key}=${value}`)
        })
        state.searchQuery = searchQuery.join('&');
    },
    ADD_GOOD_TO_PRODUCTS_LIST(state, good) {
        state.catalogProductsList.push(good);
        if (!state.catalogCategoriesList.find(item => item.id === good.category.id)) {
            state.catalogCategoriesList.push(good.category);
            if (good.category.parent_category && !state.catalogCategoriesList.find(item => item.id === good.category.parent_category)) {
                state.catalogCategoriesList.push({ id: good.category.parent_category });
            }
        }
    },
    REMOVE_GOOD_FROM_PRODUCTS_LIST(state, good) {
        state.catalogProductsList = state.catalogProductsList.filter(item => item.vendor_code !== good.vendor_code );
        let goodsCategories = state.catalogProductsList.map(item => {
            if (item.category && item.category.id) return item.category.id;
            return item.category;
        });
        let goodsParentCategories = state.catalogProductsList.map(item => { 
            if (item.category && item.category.parent_category) return item.category.parent_category;
            return item.category;
        });

        if (!goodsCategories.includes(good.category && good.category.id ? good.category.id : good.category)) {
            state.catalogCategoriesList = state.catalogCategoriesList.filter(item => item.id !== (good.category && good.category.id ? good.category.id : good.category) );
        }
        if (!goodsParentCategories.includes(good.category && good.category.parent_category ? good.category.parent_category : good.category)) {
            state.catalogCategoriesList = state.catalogCategoriesList.filter(item => item.id !== (good.category && good.category.parent_category ? good.category.parent_category : good.category) );
        }
    },
    CLEAR_FILTER(state) {
        state.filters = {
            limit: 20,
            offset: 0,
        }
    },
    SET_PRODUCTS_LIST(state, data) {
        state.catalogProductsList = data;
    },
    SET_CATEGORIES_LIST(state, data) {
        state.catalogCategoriesList = data;
    }
};

const actions = {
    async GET_CATALOGS({ state, commit }, filter = null) {
        if (filter) {
            commit('SET_FILTER_FIELD', filter);
        }
        commit('SET_SEARCH_QUERY');
        let data = await getProductCatalogs(state.searchQuery);
        commit('SET_CATALOGS', data);
        return data;
    },
    async GET_NEXT_PAGE({ state, commit, getters }) {
        if (getters['next']) {
            try {
                state.busy = true;
                commit('SET_SEARCH_QUERY');
                commit('SET_NEXT_PAGE', await getProductCatalogs(state.searchQuery));
                state.busy = false;
            } catch (error) {
                console.log(error);
            }
        }
    },
    SET_PRODUCTS({ commit, getters }, good) {
        if (getters['pickedProducts'](good.vendor_code)) {
            commit('REMOVE_GOOD_FROM_PRODUCTS_LIST', good);
        } else {
            commit('ADD_GOOD_TO_PRODUCTS_LIST', good)
        }
    },
};

const getters = {
    catalogs: state => state.catalogs,
    next: state => !!state.next_page,
    busy: state => state.busy,
    catalogProductsList: state => state.catalogProductsList,
    catalogCategoriesList: state => state.catalogCategoriesList,
    pickedProducts: state => id => !!state.catalogProductsList.find(good => good.vendor_code === id)
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

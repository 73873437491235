/* eslint-disable */
import axios from 'axios';

import store from '../store';
import router from '../router';

import token from '../utils/token';

import { responseErrorHandler, getNewAccessToken } from './auth'

const baseURL = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_HOST_NOTIFICATION;

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(
    async function(config) {
        store.dispatch('ui/startLoading', true);

        if (config._retry) {
            await getNewAccessToken();
        }

        if (token()) {
            config.headers['Authorization'] = token();
        }

        return config;
    },
    function(error) {
        const { status, data } = error.response;
        return Promise.reject({ status, data });
    }
);

instance.interceptors.response.use(
    function(response) {
        store.dispatch('ui/stopLoading', false);
        return response.data;
    },
    async function(error) {
        store.dispatch('ui/stopLoading', false);

        // if (error.response && error.response.status === 401) {
        //     store.commit('auth/REMOVE_DATA_AUTH');
        //     router.push('/');
        // }

        try {
            const res = await responseErrorHandler(error, instance);
            return res;
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                console.log({ status, data });
                return Promise.reject({ status, data });
            } else {
                return Promise.reject(err);
            }
        }
    }
);

export function getNotifications() {
    return instance('/admin/notifications/');
}
export function getNotification(id) {
    return instance(`/admin/notifications/${id}/`);
}
export function getNextPageNotifications(page) {
    return instance(`/admin/notifications/?page=${page}`);
}
export function createNotification(data) {
    return instance({
        method: 'post',
        url: `/admin/notifications/`,
        data
    });
}
export function editNotification(id, data) {
    return instance({
        method: 'put',
        url: `/admin/notifications/${id}/`,
        data
    });
}
export function removeNotification(id) {
    return instance.delete(`/admin/notifications/${id}/`);
}
export function getNotificationsForUser(params) {
    return instance({
        method: 'get',
        url: `/notifications/`,
        params
    });
}

export function getNotificationsCount() {
    return instance('/notifications/?seen=false&page_size=9999');
}

export function markSeenNotification(data) {
    return instance({
        method: 'post',
        url: `/notifications/mark_seen/`,
        data
    });
}
export function filteredNotificationsByQuery(query) {
    return instance(`/notifications/?${query}`);
}
export function readAllNotify() {
    return instance({
        method: "post",
        url: `/notifications/mark_seen/?all_notification=true`,
        data: {
            notifications: []
        }
    });
}

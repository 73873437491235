/* eslint-disable */
import router from '@/router';
const state = {
    socket: {
        socket: '',
        isConnected: false,
        message: [],
        reconnectError: false,
    }
};

const mutations = {
    SOCKET_ONCLOSE(state, event) {
        if (state.socket.socket) {
            state.socket.socket.close(1000, "работа закончена");
            console.log('Закрытие соединения уведомлений');
        }
    },
    SOCKET_ONMESSAGE(state, message) {
        state.socket.message = state.socket.message.concat(message);
    },
    CLEAR_MESSAGES(state) {
        state.socket.message = [];
    },
    READ_NOTIFICATION(state, { id, index }) {
        state.socket.socket.send(JSON.stringify({
            "jsonrpc": "2.0",
            "method": "mark_read",
            "id": 12,
            "params": {
                "notifications": [id]
            }
        }));
        state.socket.message.splice(index, 1);
    }
};

const actions = {
    CONNECT({ state, commit, dispatch }) {
        connect();
        dispatch('ONMESSAGE');

        function connect() {
            state.socket.socket = new WebSocket(process.env.VUE_APP_WS_HOST_NOTIFICATION);
            state.socket.socket.onopen = () => {
                let login = {
                    "jsonrpc": "2.0",
                    "method": "login",
                    "id": 12,
                    "params": {
                        "token": window.localStorage.getItem('token').split(' ')[1]
                    }
                }
                state.socket.socket.send(JSON.stringify(login));
            };
            state.socket.socket.onclose = (event) => {
                if (localStorage.getItem('token')) {
                    console.log('Код закрытия сокетов уведомлений', event.code, event);
                    if (!event.wasClean) {
                        let time = 3000 + Math.random() * 3000; // Рандомное время переподключения от 3 до 6 секунд.
                        setTimeout(() => {
                            console.log('Переподключение сокетов уведомлений');
                            connect();
                            dispatch('ONMESSAGE');
                        }, time);
                    }
                }
            };
        }
    },
    ONMESSAGE({ state, commit, rootGetters }) {
        state.socket.socket.onmessage = (res) => {
            let data = JSON.parse(res.data)

            if (data.method === 'new_notification' && data.result.title !== 'Импорт каталога товаров успешно завершен') {
                const notification = new Audio('/audio/notification.wav');
                notification.play();
                commit('SOCKET_ONMESSAGE', data.result);
                let count = rootGetters['notifications/notSeenMessages'];
                count++;
                commit('notifications/SET_NOT_SEEN_COUNT', count, { root: true });
                commit('notifications/ADD_USER_NOTIFICATION', data.result, { root: true });
                commit('ui/add_item_in_system_notifications', { message: data.result, type: 'base' }, { root: true });

                setTimeout(() => {
                    state.socket.message = state.socket.message.filter(item => item.id !== data.result.id);
                }, 10000);
            } else if (data.method === 'new_notification' && data.result.title === 'Импорт каталога товаров успешно завершен') {
                const notification = new Audio('/audio/notification.wav');
                notification.play();
                commit('ui/add_item_in_system_notifications', { message: 'Импорт успешно завершен. Обновите страницу', type: 'reload' }, { root: true })
            }

            if (data.method === 'order' && router.history.current.path === '/orders') {
                const notification = new Audio('/audio/notification.wav');
                notification.play();
                let count = rootGetters['notifications/notSeenMessages'];
                count++;
                commit('notifications/SET_NOT_SEEN_COUNT', count, { root: true });
                let orders = rootGetters['orders/orders'].results;
                let orderID = JSON.parse(data.result.text).id;
                let index = '';
                for (let i = 0; i < orders.length; i++) {
                    if (orders[i].id === orderID) {
                        index = i;
                    }
                }
                if (index !== '') {
                    orders.splice(index, 1, JSON.parse(data.result.text));
                    commit('orders/SET_ORDERS_RESULTS', orders, { root: true });
                } else {
                    commit('orders/ADD_ORDER_IN_LIST', JSON.parse(data.result.text), { root: true });
                }
                // let flag = orders.filter(item => item.id === orderID).length;
                // if (flag != 1) commit('orders/ADD_ORDER_IN_LIST', JSON.parse(data.result.text), { root: true });
            }
            if (data.method === "check_soon_order" && router.history.current.path === "/orders") {
                let orders = rootGetters["orders/orders"].results;
                let hotOrders = JSON.parse(data.result.text).ids.map(item => item.id);

                orders.forEach(item => {
                    if (hotOrders.includes(item.id)) item.is_hot = true;
                });

                commit("orders/SET_ORDERS_RESULTS", orders, { root: true });
            }
        };
    },
    READ_NOTIFICATION({ commit, rootGetters }, { id, index }) {
        commit('READ_NOTIFICATION', { id, index });
        let count = rootGetters['notifications/notSeenMessages'];
        count--;
        commit('notifications/SET_NOT_SEEN_COUNT', count, { root: true });
    },
};

const getters = {
    message: state => state.socket.message,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial
            v-if="$route.params.ID && arrival.status === 0 && group === 'supervisor' && !user.read_only"
            v-model="fab_open"
            class="btn__floating custom-speed-dial"
            close-on-click
            fixed
        >
            <template v-slot:activator>
                <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item @click="showConfirmDeleteModal = true">
                        <v-list-item-icon class="mr-3">
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Удалить поставку</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Поставка № ${$route.params.ID}` : 'Новая поставка'"
                left-btn="left-big"
                :show-save-btn="isShowSaveBtn"
                @click:left="$router.push({ name: 'deliveries' })"
                @click:save="saveDeliverie"
            >
                <Button
                    v-if="$route.params.ID"
                    :class="{'mr-4': isShowSaveBtn}"
                    text
                    compact
                    :icon="width <= 699"
                    icon-right="file-minus"
                    @click.prevent="exportFile"
                >
                    Скачать в xls
                </Button>
            </Header>
            <div class="page-container">
                <div class="page__info-group">
                    <Row v-if="arrival.date && !preloader">
                        <Col>
                            <InputCustom
                                :value="moment(arrival.date).format('DD.MM.YYYY')"
                                label="Дата поставки"
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row v-if="$route.params.ID && preloader">
                        <Col>
                            <ContentPreloader />
                        </Col>
                    </Row>
                    <Row>
                        <Col :cols="width > 991 ? 6 : 12">
                            <InputCustom
                                :value="`${arrival.employee === '' ? `${user.first_name} ${user.last_name}` : arrival.employee}`"
                                label="Сотрудник"
                                disabled
                            />
                        </Col>
                        <Col :cols="width > 991 ? 6 : 12">
                            <InputSelect
                                v-model="arrival.shop"
                                :options="shops.results"
                                label="Магазин"
                                :input-value="'id'"
                                :input-text="'address'"
                                input-icon="type_icon"
                                :disabled="!!$route.params.ID"
                                :error="errors ? errors.shop : null"
                            />
                        </Col>
                    </Row>
                </div>
                <div class="page__info-group">
                    <div v-show="!preloader" class="listing-table__wrap">
                        <div v-if="arrivals_goods.length" class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                <div class="listing-table__col pl-0">Наименование</div>
                                <div
                                    v-if="group === 'supervisor' || group === 'administrators'"
                                    class="listing-table__col"
                                    style="width: 140px; flex: 0 0 auto;text-align: center;"
                                >
                                    Себестоимость
                                </div>
                                <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;">Розничная цена</div>
                                <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;text-align: center;">Количество</div>
                                <div class="listing-table__col" style="width: 70px; flex: 0 0 auto;" />
                            </div>
                            <div v-for="good in arrivals_goods" :key="good.id" class="listing-table__row">
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image listing-table__image--with-link">
                                        <img
                                            :src="good.product.image || good.image ? good.product.image || good.image.preview :
                                                require('@/assets/img/flower.svg')
                                            "
                                            alt=""
                                        >
                                        <a
                                            href="#"
                                            class="listing-table__image-link"
                                            @click.stop.prevent="linkToGood(good.product.vendor_code || good.product)"
                                        >
                                            <v-icon color="primary">mdi-open-in-new</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1024 && (group === 'supervisor' || group === 'administrators') ? 'width: calc(100% - 602px)' :
                                        width > 1024 ? 'width: calc(100% - 462px)' : 'width: calc(100% - 68px)'"
                                >
                                    <div>
                                        <div class="listing-table__product-name">
                                            <span>{{ good.name || good.product.name }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                <div
                                    v-if="group === 'supervisor' || group === 'administrators'"
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 140px; flex: 0 0 auto;' : 'flex: 1 1 calc(50% - 8px)'"
                                >
                                    <InputCustom
                                        v-model="good.prime_price"
                                        :label="width <= 1024 ? 'Себестоимость' : ''"
                                        type="number"
                                        style="flex: 0 0 auto;"
                                        @input="good.retail_price = Math.round(good.prime_price * 2)"
                                    />
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : 'flex: 1 1 calc(50% - 8px)'"
                                >
                                    <InputCustom
                                        v-model="good.retail_price"
                                        :label="width <= 1024 ? 'Розничная цена' : ''"
                                        type="number"
                                        style="flex: 0 0 auto"
                                    />
                                </div>

                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : 'flex: 1 1 calc(50% - 8px);max-width: calc(50% - 8px)'"
                                >
                                    <InputCounter
                                        :value="good.count"
                                        :disabled="group === 'florist' && $route.params.ID"
                                        @input="$store.commit('arrivals/CHANGE_COUNT_ARRIVALS_GOOD', { product: good.product, value: +$event >= 0 ? +$event : 0 })"
                                    />
                                </div>

                                <div
                                    class="listing-table__col listing-table__col--mobile-inline ml-a"
                                    :style="width > 1024 ? 'width: 70px; flex: 0 0 auto;' : 'flex: 1 1 calc(50% - 8px)'"
                                >
                                    <v-btn
                                        class="ml-a"
                                        icon
                                        :disabled="group === 'florist' && $route.params.ID"
                                        @click.stop="$store.commit('arrivals/REMOVE_GOOD_FROM_ARRIVALS', good.product)"
                                    >
                                        <Icon color="#0D63F3">trash</Icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContentPreloader v-show="preloader" type="table" />
                    <div v-if="!$route.params.ID || ($route.params.ID && arrival.status == 0)" class="d-flex justify-center mt-4">
                        <Button bordered icon-right="edit-2" @click.prevent="isModalOpen = true">
                            Изменить
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmDeleteModal"
            title="Удалить поставку?"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteModal = false"
            @mainBtnClick="deleteItem"
        />
        <UniversalModal v-model="isModalOpen" size="full">
            <ArrivalsModal @closeModal="isModalOpen = false" />
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { applyingArrival, updateArrival, deleteArrival } from '@/api/stock';
import { exportArrivalToExcel } from '@/api/gateway';

import { date } from '@/utils/format';

import MiniModal from "@/components/modal/MiniModal";
import ArrivalsModal from '@/components/app/arrivals/modals/ArrivalsModal';

export default {
    name: 'TheStoreroomDeliveriesCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Поставка ${this.$route.params.ID}` : 'Новая поставка' }
    },
    components: {
        MiniModal, ArrivalsModal
    },
    data() {
        return {
            arrival: {
                employee: ''
            },
            errors: [],
            fab_open: false,
            showConfirmDeleteModal: false,
            isModalOpen: false
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            arrivals_goods: 'arrivals/arrivals_goods',
            user: 'auth/user',
            shops: 'shops/shops',
            preloader: 'ui/preloader',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        isShowSaveBtn() {
            return !this.user.read_only && this.group !== 'managers' &&
                (!this.$route.params.ID || (this.$route.params.ID && this.arrival.status == 0))
        }
    },
    watch: {
        user() {
            this.$store.dispatch('shops/GET_SHOPS');
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            this.$store.dispatch('shops/GET_SHOPS');
            if (this.$route.params.ID) {
                const arrival = await this.$store.dispatch('arrivals/GET_ARRIVAL_DATA', this.$route.params.ID)
                this.arrival = arrival;
                this.markAsViewed();
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        this.$store.commit('arrivals/CLEAR');
    },
    methods: {
        async saveDeliverie() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                let checkZeroCount = false;
                this.arrivals_goods.forEach((good) => {
                    if (good.count < 1) {
                        checkZeroCount = true;
                    }
                });

                if (checkZeroCount) {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Выберите товары', type: 'error' })
                    return;
                }

                if (this.$route.params.ID) {
                    const products = this.arrivals_goods.slice();
                    products.forEach((item) => {
                        if (typeof item.product === 'object') item.product = item.product.vendor_code;
                    });

                    await updateArrival(this.$route.params.ID, { products, shop: this.arrival.shop })
                } else {
                    await this.$store.dispatch('arrivals/CREATE_ARRIVAL', { products: this.arrivals_goods, shop: this.arrival.shop })
                }
                this.$store.dispatch('arrivals/GET_ALL_ARRIVALS');
                this.$router.push({ name: 'deliveries' });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Поставка успешно сохранена', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                if (error.data) {
                    this.errors = error.data;
                    if (this.errors.products) {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: 'Выберите товары', type: 'error' })
                    }
                }
            }
        },
        async deleteItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await deleteArrival(this.$route.params.ID);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Поставка успешно удалена', type: 'success' })
                this.$router.push({ name: 'deliveries' });
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка удаления поставки', type: 'error' })
            } finally {
                this.showConfirmDeleteModal = false;
            }
        },
        async confirmArrival(id) {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                await applyingArrival(id)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Успешно подтверждено', type: 'success' })
                this.$router.push({ name: 'deliveries' });
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка смены статуса', type: 'error' })
            }
        },
        async markAsViewed() {
            this.$store.dispatch('ui/checkUserEditPermission');
            if (this.$route.params.ID && !this.arrival.is_seen) {
                await updateArrival(this.$route.params.ID, { is_seen: true, shop: this.arrival.shop });
                this.$store.dispatch('franchises/GET_FRANCHISE_TARIFF');
                this.$store.dispatch('stock/GET_STOCK_INDICATIONS');
            }
        },
        linkToGood(vendorCode) {
            window.open(`/goods/${vendorCode}`, '_blank');
        },
        getShopName(id) {
            const shop = this.shops.results.find((item) => item.id === id);
            return shop ? shop.address : '';
        },
        async exportFile() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                const file = await exportArrivalToExcel(this.$route.params.ID);
                const data = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = data;
                link.target = '_blank';
                link.download = `Поставка-№${this.$route.params.ID}__${this.getShopName(this.arrival.shop)}__${date(new Date(this.arrival.date), 'ru')}.csv`;
                link.click();

                setTimeout(() => {
                    window.URL.revokeObjectURL(data);
                }, 1000);
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка экспорта', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        moment
    },
};
</script>

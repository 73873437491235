/* eslint-disable */
import {
    getCertificates,
    getCertificatesTemplates,
} from '@/api/django';

const state = {
    certificates: [],
    certificatesTemplates: [],
    certificatesStatuses: [
        { id: 0, name: 'Создан', icon: 'lightning', color: '#666666' },
        { id: 1, name: 'Оплачен', icon: 'rub', color: '#b66637' },
        { id: 2, name: 'Использован', icon: 'check', color: '#36b687' },
        { id: 3, name: 'Ожидает отправки', icon: 'clock', color: '#b63678' },
        { id: 4, name: 'Доставлен', icon: 'mailing', color: '#1a73e8' },
    ],
    filters: {
        limit: 20,
        offset: 0,
    },
    activeFilters: [],
    busy: false,
    next_page: false,
};

const mutations = {
    SET_CERTIFICATES(state, certificates) {
        state.certificates = certificates.results;
        state.next_page = !!certificates.next;
        state.filters.offset = 20;
    },
    SET_NEXT_CERTIFICATES(state, { results, next }) {
        state.certificates.push(...results);
        state.next_page = !!next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    SET_CERTIFICATES_TEMPLATES(state, templates) {
        state.certificatesTemplates = templates.results;
    },
    CLEAR(state) {
        state.certificates = [];
        state.next_page = false;
        state.busy = false;
        state.filters.offset = 20;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
        };
        state.next_page = false;
    },
};

const actions = {
    async GET_ALL_CERTIFICATES({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;

            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;
            let filters = await dispatch('prepareFilters');

            const certificates = await getCertificates(filters);
            commit('SET_CERTIFICATES', certificates);

            state.busy = false;
        } catch (error) {
            console.log(error);
        }
    },
    async GET_NEXT_PAGE({ state, commit, dispatch }) {
        try {
            if (state.next_page && !state.busy) {
                state.busy = true;

                let filters = await dispatch('prepareFilters');
                const certificates = await getCertificates(filters);
                commit('SET_NEXT_CERTIFICATES', certificates);
            }
        } catch (error) {
            console.log(error);
        }

    },
    async GET_CERTIFICATES_TEMPLATES({ commit }) {
        try {
            const res = await getCertificatesTemplates();
            commit('SET_CERTIFICATES_TEMPLATES', res);
        } catch (error) {
            console.log(error);
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([key, value]) => {
            if (key && (value || value === false || value === 0)) {
                filters[key] = value;
            }
        })
        let activeFilters = Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    certificates: state => state.certificates,
    certificatesTemplates: state => state.certificatesTemplates,
    certificatesStatuses: state => state.certificatesStatuses,
    next: state => !!state.next_page,
    busy: state => state.busy,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Остатки"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <p>В списке можно посмотреть остатки по магазинам. Флористы могут видеть остатки только своего магазина.</p>
                                <p>В таблице выводятся поля:</p>
                                <ul>
                                    <li>Фото товара</li>
                                    <li>Название товара</li>
                                    <li>Стоимость</li>
                                    <li>Количество в наличии</li>
                                </ul>

                                <p>Есть поиск по наименованию.</p>
                                <p>В фильтре можно выбрать магазин и категорию составляющих.</p>
                                <p>При клике на фото товара произойдет переход в товар.</p>
                                <img src="@/assets/img/help/stock/1.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'К оглавлению', link: '/help'}, {name: 'Поставки', link: '/help/arrivals'}]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpStock',
    metaInfo: {
        title: 'Остатки - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

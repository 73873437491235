<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Страницы" for-listing :show-save-btn="false" />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="300" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="pages && pages.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 50%">Название</div>
                            <div class="listing-table__col" style="width: 50%">Адрес</div>
                        </div>
                        <router-link v-for="item in pages" :key="item.id" :to="`/static-pages/${item.id}`" class="listing-table__row">
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 50%' : ''">
                                {{ item.name }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 50%' : ''">
                                <div class="listing-table__col-label">Адрес</div>
                                {{ item.slug }}
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="pages && !pages.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only && group === 'supervisor'" class="create__btn" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'ThePages',
    metaInfo: {
        title: 'Статичные страницы'
    },
    components: { Spinner },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
            width: 'ui/width',
            pages: 'pages/pages',
            busy: 'pages/busy',
            next: 'pages/next',
            preloader: 'ui/preloader',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await this.$store.dispatch('pages/GET_PAGES');
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        loadMore() {
            this.$store.dispatch('pages/LOAD_MORE');
        },
    },
};
</script>

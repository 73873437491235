<template>
    <div
        v-if="withoutPreloader || !preloader"
        ref="input"
        class="input-select-wrap"
        :class="{
            'input-select-wrap--with-label': label && !hideLabel,
            'input-select-wrap--with-icon': icon || computedIconFromValue,
            'is-focused': isInputFocused,
            'disabled': disabled,
            'error-item': error
        }"
        v-on="$listeners"
    >
        <div class="input-select__input-block">
            <label
                v-if="label && !hideLabel"
                class="input-select__label"
                :class="{'input-select__label--is-top': computedValue}"
                @click.prevent="toggleDropdown"
            >
                {{ label }}
            </label>
            <div v-if="icon || computedIconFromValue" class="input-select__icon">
                <Icon v-if="computedIconFromValue" color="#8AACCD">
                    {{ computedIconFromValue }}
                </Icon>
                <Icon v-else color="#8AACCD">{{ icon }}</Icon>
            </div>
            <input
                type="text"
                class="input-select__input"
                :value="computedValue"
                readonly
                :placeholder="showPlaceholder ? placeholder : null"
                :disabled="disabled"
                @focus="isInputFocused = true"
                @click.prevent="toggleDropdown"
            >
            <div class="input-select__arrow" :class="{'rotate': showDropdown}">
                <Icon color="#8AACCD">down</Icon>
            </div>
        </div>
        <div v-if="error" class="input-select__error">
            {{ error }}
        </div>
        <div v-if="hint && !error" class="input-select__hint">{{ hint }}</div>
        <transition name="fade">
            <div v-show="showDropdown" ref="dropdown" class="input-select__options" @touchstart.stop>
                <div v-if="addNewItemMode" class="input-select__options-item input-select__options-item-add-option" @click="$emit('add-new-item')">
                    {{ addNewItemText || 'Добавить' }}
                    <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.0002 12.9997H8.3335M13.0002 8.33301V12.9997V8.33301ZM13.0002 12.9997V17.6663V12.9997ZM13.0002 12.9997H17.6668H13.0002Z" stroke="#6E6CAD" stroke-width="2" stroke-linecap="round" />
                        <path d="M13.0002 24.6663C19.4435 24.6663 24.6668 19.443 24.6668 12.9997C24.6668 6.55635 19.4435 1.33301 13.0002 1.33301C6.55684 1.33301 1.3335 6.55635 1.3335 12.9997C1.3335 19.443 6.55684 24.6663 13.0002 24.6663Z" stroke="#6E6CAD" stroke-width="2" />
                    </svg>
                </div>
                <template v-if="options.length">
                    <div v-if="searchable" class="input-select__options-search">
                        <InputCustom v-model="searchValue" label="Поиск" left-icon="search" clearable without-preloader />
                    </div>
                    <div
                        v-for="item in filteredOptions.slice().sort(sortOptionsFunc)"
                        :key="item[inputValue]"
                        class="input-select__options-item"
                        :class="{
                            'active': !returnObject ? item[inputValue] === value : item[inputValue] === value[inputValue],
                            'editable': editItemsMode
                        }"
                        @click="setValue(item)"
                    >
                        <div
                            v-if="multiple"
                            class="input-select__options-item__multiple-checkbox"
                            :class="{'is-active': value.find(i => i === (!returnObject ? item[inputValue] : item))}"
                        >
                            <Icon size="16" color="#fff">check</Icon>
                        </div>
                        <div>
                            <Icon v-if="item[inputIcon]" class="mr-1" size="20" color="#8AACCD">
                                {{ item[inputIcon] }}
                            </Icon>
                            {{ item[inputText] }}
                            <div v-if="inputSubText && item[inputSubText]" class="input-select__options-item-subtext">
                                {{ item[inputSubText] }}
                            </div>
                        </div>
                        <button
                            v-if="editItemsMode && item[inputValue] && authUser.phone !== item[inputValue]"
                            type="button"
                            class="input-select__options-item-edit-btn"
                            @click.stop="$emit('edit', item)"
                        >
                            <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.7889 6.31748L17.8186 8.52269L15.7889 6.31748ZM17.0941 4.37477L11.6058 10.3404C11.3222 10.6482 11.1288 11.0404 11.0499 11.4675L10.543 14.2258L13.0806 13.6737C13.4736 13.5883 13.8339 13.3789 14.1176 13.0706L19.6059 7.10498C19.7709 6.92571 19.9017 6.71289 19.9909 6.47866C20.0802 6.24444 20.1261 5.9934 20.1261 5.73987C20.1261 5.48635 20.0802 5.23531 19.9909 5.00109C19.9017 4.76686 19.7709 4.55404 19.6059 4.37477C19.441 4.1955 19.2452 4.0533 19.0297 3.95628C18.8142 3.85926 18.5833 3.80933 18.35 3.80933C18.1168 3.80933 17.8858 3.85926 17.6703 3.95628C17.4549 4.0533 17.2591 4.1955 17.0941 4.37477V4.37477Z" stroke="#6E6CAD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18.209 16.3092V19.4342C18.209 19.9868 18.007 20.5167 17.6476 20.9074C17.2882 21.2981 16.8006 21.5176 16.2923 21.5176H5.75065C5.24232 21.5176 4.75481 21.2981 4.39536 20.9074C4.03592 20.5167 3.83398 19.9868 3.83398 19.4342V7.97591C3.83398 7.42338 4.03592 6.89347 4.39536 6.50277C4.75481 6.11207 5.24232 5.89258 5.75065 5.89258H8.62565" stroke="#6E6CAD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </div>
                </template>
                <div v-else class="input-select__options-item">
                    {{ noDataText }}
                </div>
            </div>
        </transition>
    </div>
    <ContentPreloader v-else />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'InputSelect',
    props: {
        value: { type: [String, Number, Array, Object, Boolean], default: '' },
        inputValue: { type: String, default: 'id' },
        inputText: { type: String, default: 'name' },
        inputSubText: { type: String, default: '' },
        inputIcon: { type: String, default: 'icon' },
        options: {
            type: Array,
            default: () => []
        },
        returnObject: { type: Boolean, default: false },
        label: { type: String, default: '' },
        hideLabel: { type: Boolean, default: false },
        placeholder: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false },
        addNewItemMode: { type: Boolean, default: false },
        addNewItemText: { type: String, default: '' },
        editItemsMode: { type: Boolean, default: false },
        noDataText: { type: String, default: 'Варианты отсутствуют' },
        icon: {  type: String, default: '' },
        searchable: { type: Boolean, default: false },
        hint: { type: String, default: '' },
        error: { type: String, default: '' },
        withoutPreloader: { type: Boolean, default: false },
    },
    data() {
        return {
            isInputFocused: false,
            showDropdown: false,
            searchValue: ''
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            authUser: 'user/user',
            preloader: 'ui/preloader',
        }),
        computedValue() {
            if (this.value !== '' && this.value !== undefined) {
                if (!this.multiple) {
                    let searchedValue = !this.returnObject ? this.value : this.value[this.inputValue];
                    const find = this.options.find(item => item[this.inputValue] === searchedValue);
                    if (find) {
                        return find[this.inputText];
                    }
                    return ''
                } else {
                    let filteredOptions = this.options.filter(item => {
                        if (!this.returnObject) {
                            return this.value.find(i => item[this.inputValue] === i)
                        }
                        return this.value.find(i => item[this.inputValue] === i[this.inputValue])
                    })
                    return filteredOptions.map(item => item[this.inputText]).join(', ')
                }
            }
            return ''
        },
        computedIconFromValue() {
            if (!this.multiple) {
                let searchedValue = !this.returnObject ? this.value : this.value[this.inputValue];
                const find = this.options.find(item => item[this.inputValue] === searchedValue);
                if (find) {
                    return find[this.inputIcon];
                }
            }
            return ''
        },
        filteredOptions() {
            if (this.searchable && this.searchValue) {
                return this.options.filter(item => item[this.inputText].toLowerCase().includes(this.searchValue.toLowerCase()));
            }
            return this.options
        },
        showPlaceholder() {
            if (this.placeholder && this.label && !this.hideLabel && this.isInputFocused) {
                return true
            } else if (this.placeholder && (!this.label || this.hideLabel)) {
                return true
            }
            return false
        }
    },
    watch: {
        async showDropdown(val) {
            if (val) {
                let maxBottom = document.documentElement.scrollHeight;
                let inputTopCoords = this.$refs.input.getBoundingClientRect().top + this.$refs.input.clientHeight + pageYOffset;
                await this.$nextTick();
                if (inputTopCoords + this.$refs.dropdown.clientHeight > maxBottom) {
                    this.$refs.dropdown.style.position = 'fixed';
                    this.$refs.dropdown.style.width = this.$refs.input.clientWidth + 'px';
                    this.$refs.dropdown.style.bottom = '16px';
                    this.$refs.dropdown.style.top = 'auto';
                    this.$refs.dropdown.style.borderRadius = '16px';
                    this.$refs.dropdown.style.paddingTop = '0';
                    this.$refs.dropdown.style.zIndex = '10';

                }
            } else {
                setTimeout(() => {
                    if (this.$refs.dropdown) {
                        this.$refs.dropdown.style.position = '';
                        this.$refs.dropdown.style.width = '';
                        this.$refs.dropdown.style.bottom = '';
                        this.$refs.dropdown.style.top = '';
                        this.$refs.dropdown.style.paddingTop = '';
                        this.$refs.dropdown.style.borderRadius = '';
                        this.$refs.dropdown.style.zIndex = '';
                    }
                }, 400);
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.checkClickOutside)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.checkClickOutside)
    },
    methods: {
        setValue(val) {
            let returnedValue = !this.returnObject ? val[this.inputValue] : val;
            if (!this.multiple) {
                this.$emit('input', returnedValue);
                this.closeDropdown();
            } else {
                let find = this.value.find(item => item === returnedValue)
                if (find) {
                    this.$emit('input', this.value.filter(item => item !== returnedValue));
                } else {
                    this.$emit('input', [...this.value, returnedValue]);
                }
            }
        },
        toggleDropdown() {
            if (!this.readonly && !this.disabled) {
                if (!this.showDropdown) {
                    this.showDropdown = true;
                    this.isInputFocused = true;
                } else {
                    this.closeDropdown();
                }
            }
        },
        async closeDropdown() {
            await this.$nextTick();
            this.showDropdown = false;
            this.$emit('blur');
            setTimeout(() => {
                this.isInputFocused = false
            }, 150);
        },
        checkClickOutside(e) {
            if (this.$refs.input && !this.$refs.input.contains(e.target) && this.showDropdown) {
                this.closeDropdown();
            }
        },
        sortOptionsFunc(item1, item2) {
            if (this.multiple && this.value) {
                let isActiveItem1 = this.value.find(i => i === (!this.returnObject ? item1[this.inputValue] : item1));
                let isActiveItem2 = this.value.find(i => i === (!this.returnObject ? item2[this.inputValue] : item2));
                if (isActiveItem1 && !isActiveItem2) {
                    return -1;
                }
                if (!isActiveItem1 && isActiveItem2) {
                    return 1;
                }
                if (isActiveItem1 && isActiveItem2) {
                    return 0;
                }
            }
            return 0
        }
    }
}
</script>

<template>
    <div class="side-filter__content">
        <div class="side-filter__content-group-list">
            <div class="side-filter__content-group">
                <InputSelect 
                    :value="filters.tariff"
                    :options="tariffOptions"
                    label="Тариф"
                    without-preloader
                    @input="filterFranchises('tariff', $event)"
                />
            </div>

            <div class="side-filter__content-group side-filter__content-group--block">
                <div class="side-filter__content-group-title">
                    Дней до конца подписки
                </div>
                <v-range-slider
                    v-model="daysUntilEnd"
                    track-color="#CFDDEA"
                    thumb-color="#FFFFFF"
                    dense
                    :min="filterMinMaxValues.days_until_end.count_days_until_end__min"
                    :max="filterMinMaxValues.days_until_end.count_days_until_end__max"
                    hide-details
                    @change="getFilteredFranchises('days_until_end__range', $event)"
                />
                <div class="side-filter__range-val-wrapper">
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(daysUntilEnd[0])"
                            label="От"
                            clearable
                            without-preloader
                            @click:clear="$set(daysUntilEnd, 0, toInt($event)), getFilteredFranchises('days_until_end__range', daysUntilEnd)"
                            @change="$set(daysUntilEnd, 0, toInt($event)), getFilteredFranchises('days_until_end__range', daysUntilEnd)"
                        />
                    </div>
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(daysUntilEnd[1])"
                            label="До"
                            clearable
                            without-preloader
                            @click:clear="$set(daysUntilEnd, 1, toInt($event)), getFilteredFranchises('days_until_end__range', daysUntilEnd)"
                            @change="$set(daysUntilEnd, 1, toInt($event)), getFilteredFranchises('days_until_end__range', daysUntilEnd)"
                        />
                    </div>
                </div>
            </div>
        </div>

        <button class="side-filter__clear-btn" @click.prevent="clearFilter">
            Очистить
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from '@/utils/functions';
import { numberFormat } from '@/utils/format';

export default {
    data() {
        return {
            tariffOptions: [],
            daysUntilEnd: [],
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            filters: 'franchises/filters',
            filterMinMaxValues: 'franchises/filterMinMaxValues',
            sort: 'franchises/sort',
        }),
    },
    mounted() {
        let interval = setInterval(() => {
            if (Object.keys(this.filterMinMaxValues).length > 1) {
                this.setFilterValues();
                clearInterval(interval);
            }
        }, 200);
    },
    methods: {
        setFilterValues() {
            this.tariffOptions = [{ id: null, name: 'Все' }, ...this.filterMinMaxValues.tariffs || []];
            this.daysUntilEnd = [
                this.filterMinMaxValues.days_until_end.count_days_until_end__min,
                this.filterMinMaxValues.days_until_end.count_days_until_end__max
            ];
        },
        getFilteredFranchises: debounce(function (field, value) {
            this.filterFranchises(field, value);
        }, 500),
        filterFranchises(field, value) {
            // console.log(value, field);
            if (field === "days_until_end__range") {
                if (value[0] || value[1]) {
                    value = `${value[0]},${value[1]}`;
                } else {
                    value = null;
                }
            }
            this.$store.dispatch('franchises/LOAD_FRANCHISES', { field, value });
        },
        closeFilter() {
            this.$store.commit('ui/CLOSE_FILTER', 'franchises-filter');
        },
        async clearFilter() {
            try {
                this.$store.commit('ui/CLOSE_FILTER', 'franchises-filter');
                this.$store.commit('ui/SET_PRELOADER', true);
                this.$store.commit('franchises/CLEAR_FILTERS_STATE');
                await this.$store.dispatch('franchises/LOAD_FRANCHISES', { field: 'ordering', value: `${this.sort.direction}${this.sort.type}` });
                window.scrollTo(0, 0);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        toInt(str) {
            str = str ? parseInt(str.replace(/\s/g, ''), 10) : 0;
            if (str && !isNaN(str)) {
                return str;
            }
            return 0
        },
        numberFormat(num) {
            if (num) return numberFormat(num);
            return 0;
        }
    }
}
</script>

<template>
    <v-btn
        class="btn__floating"
        :class="{
            'custom-offset-1' : message !== '' || showErrorMessage || notifications.length === 1,
            'custom-offset-2' : notifications.length === 2 || (notifications.length === 1 && showErrorMessage) ||
                (message !== '' && notifications.length === 1)
        }"
        fixed
        dark
        fab
        bottom
        right
        color="#1a73e8"
        @click.prevent="$router.push(`${path ? path : $route.path}/create`)"
    >
        <v-icon>mdi-plus</v-icon>
        <slot />
    </v-btn>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        path: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters({
            message: 'ui/message',
            notifications: 'autoNotification/message',
            error: 'ui/error',
            showErrorMessage: 'ui/showErrorMessage',
            errorMessage: 'ui/errorMessage',
            width: 'ui/width'
        })
    },
}
</script>

<style lang="sass" scoped>
    @media screen and (max-width: 991px)
        .btn__floating.custom-offset-1
            transform: translateY(-68px)
        .btn__floating.custom-offset-2
            transform: translateY(-136px)
</style>

<template>
    <div v-if="showModal" ref="modal" class="universal-modal__wrapper" :class="{'full-screen': size === 'full', 'big': size === 'big'}">
        <transition name="fade">
            <div v-show="showModalContent" class="universal-modal__overlay" @click="closeModal" />
        </transition>
        <div ref="wrap" class="universal-modal__outer" @click.self="closeModal">
            <transition name="modal-content-fade">
                <div v-show="showModalContent" ref="content" class="universal-modal__inner" @click.self="closeModal">
                    <div class="universal-modal__inner-wrap">
                        <slot :functions="{ close: closeModal }" />
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import modalSwipeClose from '@/utils/modalSwipeClose';

export default {
    name: 'UniversalModal',
    model: {
        prop: 'opened'
    },
    props: {
        opened: { type: Boolean, default: false },
        size: { type: String, default: '' } // варианты: big и full
    },
    data() {
        return {
            showModal: false,
            showModalContent: false,
            modalSwipeClose: {},
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
    },
    watch: {
        async opened(val) {
            if (val) {
                this.showModal = true;
                await this.$nextTick();
                this.showModalContent = true;
                await this.$nextTick();
                this.modalSwipeClose.initTouchHandler(this.$refs.wrap, this.$refs.content, this.closeModal, this.$refs.modal, '.universal-modal__wrapper');
                this.$store.dispatch('ui/toggle_opened_modals_count', 'open');
            } else if (this.showModal) {
                this.closeModal();
            }
        }
    },
    mounted() {
        this.modalSwipeClose = modalSwipeClose();
        document.addEventListener('keydown', this.onKeydownESC);
    },
    beforeDestroy() {
        this.modalSwipeClose.destroyTouch(this.$refs.wrap);
        document.removeEventListener('keydown', this.onKeydownESC);
    },
    methods: {
        closeModal() {
            if (this.showModal) {
                this.showModalContent = false;
                setTimeout(async () => {
                    this.showModal = false;
                    this.$emit('input', false);
                    await this.$nextTick();
                    this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
                }, 300);
            }
        },
        onKeydownESC(event) {
            if (event.key === 'Escape') {
                this.$emit('input', false);
            }
        },
    }
}
</script>

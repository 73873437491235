<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Подписка"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <p>В разделе "Подписки" можно проверить состояние своей текущей подписки, продлить ее или купить новую.</p>
                                <img src="@/assets/img/help/subscription/1.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>При покупке подписки нужно проверить тариф и выбрать способ оплаты.</p>
                                <img src="@/assets/img/help/subscription/2.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Есть история подписок.</p>
                                <p>Отображаются поля:</p>
                                <ul>
                                    <li>Дата начала подписки</li>
                                    <li>Дата окончания подписки</li>
                                    <li>Название тарифа</li>
                                    <li>Стоимость тарифа</li>
                                    <li>Количество купленных магазинов</li>
                                    <li>Статус подписки и количество оставшихся дней</li>
                                </ul>
                                <p>Есть возможность добавить новый магазин.</p>
                                <img src="@/assets/img/help/subscription/3.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>При добавлении магазина откроется модальное окно, где нужно проверить выбранную подписку и указать количество покупаемых магазинов.</p>
                                <img src="@/assets/img/help/subscription/4.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{ name: 'Настройки', link: '/help/settings' }, { name: 'Страницы', link: '/help/pages' }]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpSubscription',
    metaInfo: {
        title: 'Подписка - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

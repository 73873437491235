<template>
    <div ref="actionBar" class="action-bar" :class="{'fixed': isFixed}">
        <v-checkbox
            :input-value="allPicked"
            :indeterminate="!!picked.length && !allPicked"
            class="mt-0 pt-0"
            hide-details
            @change="changeHandler"
        />
        
        <div v-show="pickedCount" class="mx-2">
            {{ numberFormat(pickedCount) }}
        </div>

        <v-tooltip v-if="withHide" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="toggleVisibility">
                    <v-icon :color="AllOrSingleState ? '#96A5B9' : '#1a73e8'">mdi-eye</v-icon>
                </v-btn>
            </template>
            <span>{{ AllOrSingleState ? 'Отключить' : 'Активировать' }}</span>
        </v-tooltip>

        <v-tooltip v-if="withDownload" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn id="bar-download" icon v-bind="attrs" v-on="on" @click="$emit('download')">
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </template>
            <span>Скачать</span>
        </v-tooltip>

        <v-tooltip v-if="withNotification" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="$emit('notification')">
                    <v-icon color="#96A5B9">mdi-bell</v-icon>
                </v-btn>
            </template>
            <span>Уведомить</span>
        </v-tooltip>
    </div>
</template>

<script>
import { numberFormat } from '@/utils/format';

export default {
    name: 'ActionBar',
    props: {
        picked: {
            type: Array,
            default: () => []
        },
        pickedCount: {
            type: Number,
            default: 0
        },
        allPicked: {
            type: Boolean,
            default: false,
        },
        hideAllGoods: {
            type: Boolean,
            default: false
        },
        withHide: {
            type: Boolean,
            default: false
        },
        withDownload: {
            type: Boolean,
            default: false,
        },
        withNotification: {
            type: Boolean,
            default: false,
        },
        observerMargin: { type: [String, Number], default: 71 }
    },
    data() {
        return {
            hideGoods: true,
            isFixed: false
        }
    },
    computed: {
        AllOrSingleState() {
            if (this.allPicked && this.picked.length !== this.pickedCount) return !this.hideAllGoods; // Если не все продукты скрыты, тогда выключаем все.
            return this.hideGoods;
        }
    },
    watch: {
        picked() {
            const findHidden = this.picked.findIndex((item) => item.is_hidden);
            this.hideGoods = findHidden == '-1' ? true : false;
        }
    },
    mounted() {
        this.observer = new IntersectionObserver(this.checkScrollPosition, {
            rootMargin: `-${this.observerMargin}px 0px 0px 0px`,
            threshold: 1.0
        });
        this.observer.observe(this.$refs.actionBar)
    },
    methods: {
        changeHandler() {
            if (!this.picked.length || !this.allPicked) {
                this.$emit('checkbox', true);
            } else {
                this.$emit('checkbox', false);
            }
        },
        toggleVisibility() {
            if (this.AllOrSingleState) {
                this.$emit('hideGoods', true);
            } else {
                this.$emit('hideGoods', false);
            }
        },
        checkScrollPosition(entries) {
            if (entries[0] && !entries[0].isIntersecting) {
                this.isFixed = true
            } else {
                this.isFixed = false;
            }
        },
        numberFormat(value) {
            if (value) return numberFormat(value);
            return value || 0;
        },
    }
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Тег ${tag.name}` : 'Новый тег'"
                left-btn="left-big"
                :show-save-btn="tag.name && tag.parent_tag"
                @click:left="$router.push('/tags')"
                @click:save="saveItem"
            />
            <div class="tabs__list">
                <TabItem :active="activeTab === 0" @click="activeTab = 0">
                    Описание
                </TabItem>
                <TabItem :active="activeTab === 1" @click="activeTab = 1">
                    Товары
                </TabItem>
            </div>
            <div class="page-container page-container--with-tabs">
                <div v-show="activeTab === 0" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Основное</h2>
                                <Row>
                                    <Col>
                                        <InputCustom
                                            v-model="tag.name"
                                            label="Название"
                                            :error="errors.name ? errors.name[0] : ''"
                                        />
                                    </Col>
                                    <Col>
                                        <InputSelect
                                            v-model="tag.parent_tag"
                                            :options="categories"
                                            label="Категория"
                                            :error="errors.parent_tag ? errors.parent_tag[0] : ''"
                                        />
                                    </Col>
                                    <Col>
                                        <InputCustom v-model="tag.sort" type="number" label="Сортировка" />
                                    </Col>
                                </Row>
                                <Row v-if="forColorCategory && !tag.multi_color">
                                    <Col>
                                        <v-color-picker v-model="tag.color" hide-mode-switch hide-inputs canvas-height="100" />
                                    </Col>
                                </Row>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">SEO</h2>
                                <Row>
                                    <Col>
                                        <InputCustom v-model="tag.meta_h1" label="H1" />
                                    </Col>
                                    <Col>
                                        <InputCustom v-model="tag.meta_title" label="Title" />
                                    </Col>
                                    <Col>
                                        <InputCustom v-model="tag.meta_description" label="Description" />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div class="page-small-column">
                            <div class="checkbox-group">
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="tag.is_active"
                                        label="Активность"
                                        @change="tag.is_active = !tag.is_active"
                                    />
                                </div>
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="tag.display"
                                        label="Показывать на главной"
                                        @change="tag.display = !tag.display"
                                    />
                                </div>
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="tag.display_in_menu"
                                        label="Показывать в меню"
                                        @change="tag.display_in_menu = !tag.display_in_menu"
                                    />
                                </div>
                                <div class="checkbox-group__item">
                                    <v-tooltip bottom color="#000">
                                        <template v-slot:activator="{ on, attrs }">
                                            <Checkbox
                                                :value="tag.general_tag"
                                                label="Общий тег"
                                                v-bind="attrs" v-on="on"
                                                @change="tag.general_tag = !tag.general_tag"
                                            />
                                        </template>
                                        <span>Общий тег будет отображаться в фильтре каждой категории</span>
                                    </v-tooltip>
                                </div>
                                <div v-if="forColorCategory" class="checkbox-group__item">
                                    <Checkbox
                                        :value="tag.multi_color"
                                        label="Мультицвет"
                                        @change="tag.multi_color = !tag.multi_color"
                                    />
                                </div>
                            </div>
                            <Button
                                v-if="$route.params.ID"
                                class="mt-4"
                                block compact text
                                icon-left="file-minus"
                                @click.prevent="showConfirmDeleteCategory = true"
                            >
                                Удалить тег
                            </Button>
                        </div>
                    </div>
                </div>
                <div v-show="activeTab === 1" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <div v-if="tagsProductsList && tagsProductsList.length" class="listing-table">
                                <div class="listing-table__row listing-table__row--head">
                                    <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                    <div class="listing-table__col pl-0">Наименование</div>
                                    <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;">
                                        Стоимость
                                    </div>
                                    <div class="listing-table__col" style="width: 70px; flex: 0 0 auto;" />
                                </div>
                                <div
                                    v-for="(good, index) in tagsProductsList"
                                    :key="index"
                                    class="listing-table__row"
                                >
                                    <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                        <div class="listing-table__image listing-table__image--with-link">
                                            <img :src="good.images.length ? good.images[0].image.preview : require('@/assets/img/flower.svg')" :alt="good.name">
                                            <a href="#" class="listing-table__image-link" @click.stop.prevent="linkToGood(good)">
                                                <v-icon color="primary">mdi-open-in-new</v-icon>
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        class="listing-table__col listing-table__col--mobile-inline"
                                        :class="{'pt-0 mr-0': width <= 1024}"
                                        :style="width > 1024 ? 'width: calc(100% - 332px)' : 'width: calc(100% - 68px)'"
                                    >
                                        <div class="listing-table__product-name">
                                            <span>{{ good.name }}</span>
                                        </div>
                                    </div>
                                    <div
                                        v-if="width <= 1024"
                                        class="listing-table__col listing-table__col--mobile-bordered pt-0"
                                    />
                                    <div
                                        class="listing-table__col"
                                        :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : ''"
                                    >
                                        <div class="listing-table__col-label">Стоимость</div>
                                        {{ good.discount ? formatNumber(+good.discount.price_with_discount) : formatNumber(+good.price) }} ₽
                                    </div>
                                    <div
                                        class="listing-table__col listing-table__col--mobile-inline ml-a"
                                        :style="width > 1024 ? 'width: 70px; flex: 0 0 auto;' : ''"
                                    >
                                        <Icon
                                            color="#0D63F3"
                                            style="cursor: pointer;"
                                            @click.stop="$store.dispatch('tags/setTagProducts', good)"
                                        >
                                            trash
                                        </Icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="page-small-column page-small-column--mobile-top">
                            <button
                                type="button"
                                class="edit-list-button"
                                @click.prevent="$store.commit('ui/OPEN_MODAL', 'modal-goods')"
                            >
                                <div class="edit-list-button__icon">
                                    <Icon>edit-2</Icon>
                                </div>
                                Изменить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmDeleteCategory"
            :title="`Удалить тег ${ tag.name }?`"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteCategory = false"
            @mainBtnClick="deleteTag"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { numberFormat } from '@/utils/format';
import {
    getTag, createTag, editTag, deleteTag
} from '@/api/django';

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'TheTagsCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Тег ${this.tag.name}` : 'Новый тег' }
    },
    components: { MiniModal },
    data() {
        return {
            tag: {
                is_active: true,
                display: false,
                display_in_menu: false,
                general_tag: false,
                name: '',
                parent_tag: null,
                color: '',
                multi_color: false,
                meta_title: '',
                meta_description: '',
                meta_h1: '',
                childrens: [],
                products: [],
                sort: 0,
                type: 'text'
            },
            errors: {
                parent_tag: []
            },
            activeTab: 0,
            showConfirmDeleteCategory: false
        }
    },
    computed: {
        ...mapGetters({
            categories: 'tags/categories',
            tagsProductsList: 'tags/tagsProductsList',
            franchiseTariff: 'franchises/franchiseTariff',
            width: 'ui/width',
            preloader: 'ui/preloader',
            categoryIDForCreate: 'tags/categoryIDForCreate'
        }),
        forColorCategory() {
            const colorCategory = this.categories.filter((item) => item.type === 'color');
            if (colorCategory && colorCategory.map((i) => i.id).includes(this.tag.parent_tag)) {
                return true;
            }
            return false
        },
    },
    async created() {
        this.$store.commit('ui/SET_PRELOADER', true);
        this.$store.commit('ui/SET_GOODS_MODAL_MODE', 'tags');
        if (!this.categories.length) {
            await this.$store.dispatch('tags/getTags');
        }
        if (this.categories.length && !this.categoryIDForCreate) {
            this.tag.parent_tag = this.categories[0].id;
        } else if (this.categories.length && this.categoryIDForCreate) {
            this.tag.parent_tag = this.categoryIDForCreate;
        } else {
            this.errors.parent_tag = ['Перед созданием тега необходимо добавить хотя бы одну категорию'];
        }
        if (this.$route.params.ID) {
            const response = await getTag(this.$route.params.ID);
            this.tag = response;
            this.$store.commit('tags/SET_PRODUCTS_LIST', response.products);
        }
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    beforeDestroy() {
        this.$store.commit('ui/SET_GOODS_MODAL_MODE', '');
        this.$store.commit('tags/SET_PRODUCTS_LIST', []);
        this.$store.commit('tags/SET_CATEGORY_ID_FOR_CREATE', null);
    },
    methods: {
        async saveItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (!this.tag.parent_tag) {
                    this.errors.parent_tag = ['Выберите категорию'];
                    return;
                }

                this.$store.commit('ui/SET_PRELOADER', true);
                this.tag.products = this.tagsProductsList.map((item) => item.vendor_code);

                if (!this.$route.params.ID) {
                    await createTag({ ...this.tag, site: this.franchiseTariff.site_id });
                } else {
                    await editTag(this.tag.id, this.tag);
                }

                this.$router.push({ name: 'tags' });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Успешно сохранено', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка сохранения', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async deleteTag() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                await deleteTag(this.tag.id);
                this.$router.push({ name: 'tags' });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Успешно удалено', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка сохранения', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        linkToGood(product) {
            window.open(`/goods/${product.vendor_code}/`, '_blank');
        },
        formatNumber(number) {
            return number === 0 ? 0 : numberFormat(number);
        },
    },
};
</script>

<template>
    <div class="tabs__item" :class="{'active': active}" @click.prevent="clickToItem">
        <span>
            <slot />
        </span>
    </div>
</template>

<script>
export default {
    name: 'TabsItem',
    props: {
        active: { type: Boolean, default: false }
    },
    methods: {
        clickToItem() {
            this.$emit('click');
            window.scrollTo(0, 0);
        }
    }
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Скидки"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список скидок</h2>
                                <p>Список скидок содержит следующие поля:</p>
                                <ul>
                                    <li>Название</li>
                                    <li>Величина</li>
                                    <li>Активность</li>
                                </ul>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание и редактирование скидки</h2>
                                <p>Можно задать скидку в рублях или в процентах.</p>
                                <p>Соответственно величина скидки будет иметь значение рублей или процентов.</p>
                                <p>Максимальная величина скидки учитывается, если выбрано значение в процентах.</p>
                                <p>Пример: скидка на букет 50% и составляет 1500 рублей, но максимальная скидка задана 500 рублей - это значение и будет применяться.</p>
                                <img src="@/assets/img/help/discounts/1.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>На вкладке <b>"Товары"</b> находится список товаров, к которым применяется скидка.</p>
                                <p>При нажатии на кнопку <b>"Изменить"</b> откроется модальное окно, в котором нужно выбрать товары, к которым будет применяться создаваемая скидка.</p>
                                <img src="@/assets/img/help/discounts/2.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>При редактировании скидки все поля можно изменять. Через дополнительное меню можно удалить скидку.</p>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'К оглавлению', link: '/help'}, { name: 'Промокоды', link: '/help/promocodes' }]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpDiscounts',
    metaInfo: {
        title: 'Скидки - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

/* eslint-disable */
export const date = (value, type) => {
    let _yyyy = value.getFullYear();
    let _mm = (value.getMonth() + 1) < 10 ? `0${value.getMonth() + 1}` : value.getMonth() + 1;
    let _dd = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate();

    if (type === 'dott') {
        return `${_yyyy}.${_mm}.${_dd}`;
    }

    if (type === 'hours') {
        let _hours = value.getHours() < 10 ? `0${value.getHours()}` : value.getHours();
        let _min = value.getMinutes() < 10 ? `0${value.getMinutes()}` : value.getMinutes();
        return `${_yyyy}.${_mm}.${_dd} ${_hours}:${_min}`;
    }
    if (type === 'hours-ru') {
        let _hours = value.getHours() < 10 ? `0${value.getHours()}` : value.getHours();
        let _min = value.getMinutes() < 10 ? `0${value.getMinutes()}` : value.getMinutes();
        return `${_dd}.${_mm}.${_yyyy} • ${_hours}:${_min}`;
    }

    if(type == 'time'){
        let _hours = value.getHours() < 10 ? `0${value.getHours()}` : value.getHours();
        let _min = value.getMinutes() < 10 ? `0${value.getMinutes()}` : value.getMinutes();
        return `${_hours}:${_min}`;
    }
    if (type === 'time-utc') {
        let _hours = value.getUTCHours() < 10 ? `0${value.getUTCHours()}` : value.getUTCHours();
        let _min = value.getUTCMinutes() < 10 ? `0${value.getUTCMinutes()}` : value.getUTCMinutes();
        return `${_hours}:${_min}`;
    }

    if (type === 'ru') {
        return `${_dd}.${_mm}.${_yyyy}`;
    }

    if (type === 'utc') {
        let _hours = value.getHours() < 10 ? `0${value.getHours()}` : value.getHours();
        let _min = value.getMinutes() < 10 ? `0${value.getMinutes()}` : value.getMinutes();
        return `${_yyyy}-${_mm}-${_dd}T${_hours}:${_min}`;
    }
    if(type === 'full-ISO-date') {
        let _hours = value.getUTCHours() < 10 ? `0${value.getUTCHours()}` : value.getUTCHours();
        let _min = value.getMinutes() < 10 ? `0${value.getMinutes()}` : value.getMinutes();
        let _sec = value.getSeconds() < 10 ? `0${value.getSeconds()}` : value.getSeconds();
        return `${_yyyy}-${_mm}-${_dd}T${_hours}:${_min}:${_sec}Z`;
    }

    if (typeof type === 'undefined' || type === null) {
        return `${_yyyy}-${_mm}-${_dd}`;
    }
};

export const numberFormat = (number) => {
    try {
        if (number) {
            let arr = number.toString().split('');
            let result = '';
            arr.reverse().forEach((letter, index) => {
                if (index % 3 == 0) {
                    result += " "
                }
                result += letter;
            })
            return result.split('').reverse().join('');
        } else {
            return 0
        }
    } catch (error) {
        return 0
    }
}

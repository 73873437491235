<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Поставки"
                for-listing
                :show-save-btn="false"
                filter
                :filterIndicator="!!activeFilters.length"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'arrivals-side-filter')"
            />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="arrivals && arrivals.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 25%">№ поставки</div>
                            <div class="listing-table__col" style="width: 25%">Магазин</div>
                            <div class="listing-table__col" style="width: 25%">Дата поставки</div>
                            <div class="listing-table__col" style="width: 25%">Статус</div>
                        </div>
                        <router-link
                            v-for="item in arrivals"
                            :key="item.id"
                            :to="`/storeroom/deliveries/${item.id}`"
                            class="listing-table__row"
                            :class="{'not-seen': !item.is_seen }"
                        >
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                {{ item.id }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Магазин</div>
                                {{ item.shop }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Дата</div>
                                {{ formatDate(item.date, 'ru') }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <StatusSelect
                                    :value="item.status"
                                    :options="options"
                                    :disabled="authUser.read_only || item.status === 1 || authUserGroup === 'managers'"
                                    @select="patchStatus($event, item)"
                                />
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="arrivals && !arrivals.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only && authUserGroup !== 'managers'" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { date } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheStoreroomDeliveries',
    metaInfo: {
        title: 'Поставки'
    },
    components: {
        Spinner,
    },
    data() {
        return {
            options: [
                {
                    id: 0,
                    name: 'Ожидает подтверждения',
                    icon: 'clock',
                    color: '#F2C94C'
                },
                {
                    id: 1,
                    name: 'Выполнено',
                    icon: 'check',
                    color: '#36B687'
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
            width: 'ui/width',
            arrivals: 'arrivals/arrivals',
            shops: 'shops/shops',
            busy: 'arrivals/busy',
            next: 'arrivals/next',
            preloader: 'ui/preloader',
            activeFilters: 'arrivals/activeFilters',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        const user = JSON.parse(window.localStorage.getItem('user'));

        if (user.city) {
            await this.$store.dispatch('shops/GET_SHOPS');
        }
        if (!this.activeFilters.length) {
            if (this.authUserGroup !== 'florist') {
                await this.$store.dispatch('arrivals/GET_ALL_ARRIVALS');
            } else {
                await this.filterArrivals(user.shop);
            }
        }
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    methods: {
        async filterArrivals(shop) {
            await this.$store.dispatch('arrivals/GET_ALL_ARRIVALS', { field: 'shop', value: shop });
        },
        async patchStatus(status, arrival) {
            try {
                if (status === 0) return;
                arrival.status = status;
                await this.$store.dispatch('arrivals/APPLY_ARRIVALS', arrival.id)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Успешно подтверждено', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка смены статуса', type: 'error' })
            }
        },
        loadMore() {
            this.$store.dispatch('arrivals/LOAD_MORE');
        },
        formatDate(value) {
            return date(new Date(value), 'hours-ru');
        },
    },
};
</script>

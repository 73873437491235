<template>
    <div v-if="withoutPreloader || !preloader" class="input-custom" :class="{'error-item': error}" v-on="$listeners">
        <div
            class="input-custom__input-wrap"
            :class="{
                'input-custom__input-wrap--with-label': label && !hideLabel,
                'input-custom__input-wrap--with-left-icon': leftIcon,
                'input-custom__input-wrap--with-right-icon': clearable || rightIcon,
                'input-custom__input-wrap--disabled': disabled
            }"
        >
            <div class="input-custom__input-block">
                <label
                    v-if="label && !hideLabel"
                    class="input-custom__label"
                    :class="{'input-custom__label--is-top': value || value === 0 || isInputFocused}"
                    @click.prevent="focusInput"
                >
                    {{ label }}
                </label>
                <input
                    ref="input"
                    :value="value"
                    class="input-custom__input"
                    :class="{'input-custom__input--compact': compact}"
                    :type="type"
                    :placeholder="showPlaceholder ? placeholder : null"
                    :disabled="disabled"
                    :readonly="readonly"
                    autocomplete="nope"
                    :maxlength="maxlength"
                    @focus="isInputFocused = true"
                    @blur="isInputFocused = false"
                    @input.stop="inputEvent"
                    @change.stop="$emit('change', $event.target.value)"
                >
                <button
                    v-if="(value || value == 0) && clearable && !disabled && !readonly && !rightIcon"
                    type="button"
                    class="input-custom__clear"
                    @click.stop.prevent="clearInput"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#F1F5F9"/>
                        <path d="M15 9L9 15" stroke="#2C4A67" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 9L15 15" stroke="#2C4A67" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button
                    v-if="leftIcon"
                    type="button"
                    class="input-custom__left-icon"
                    :disabled="disabledLeftIcon"
                    @click.prevent="$emit('click:left')"
                >
                    <Icon color="#8AACCD">{{ leftIcon }}</Icon>
                </button>
                <button
                    v-if="rightIcon"
                    type="button"
                    class="input-custom__right-icon"
                    @click.prevent="$emit('click:right')"
                >
                    <Icon :color="rightIconColor ? rightIconColor : '#2B77F5'">{{ rightIcon }}</Icon>
                </button>
            </div>
            <div v-if="error" class="input-custom__error">{{ error }}</div>
            <div v-if="hint && !error" class="input-custom__hint">{{ hint }}</div>
        </div>
    </div>
    <ContentPreloader v-else />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'InputCustom',
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        label: { type: String, default: '' },
        hideLabel: { type: Boolean, default: false },
        type: { type: String, default: 'text' },
        placeholder: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
        rightIcon: { type: String, default: '' },
        rightIconColor: { type: String, default: '' },
        leftIcon: { type: String, default: '' },
        disabledLeftIcon: { type: Boolean, default: false },
        hint: { type: String, default: '' },
        maxlength: { type: [String, Number], default: '' },
        error: { type: String, default: '' },
        withoutPreloader: { type: Boolean, default: false },
        compact: { type: Boolean, default: false },
    },
    data() {
        return {
            isInputFocused: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
        }),
        showPlaceholder() {
            if (this.placeholder && this.label && !this.hideLabel && this.isInputFocused) {
                return true
            } else if (this.placeholder && (!this.label || this.hideLabel)) {
                return true
            }
            return false
        }
    },
    methods: {
        focusInput() {
            // Функция может быть вызвана через родительский компонент.
            // Нужно повесить ref на InputCustom, и вызвать функцию this.$refs.RefName.focusInput();
            if (this.disabled || this.readonly) {
                return;
            }
            this.$refs.input.focus();
            this.isInputFocused = true;
        },
        inputEvent(e) {
            if (!this.maxlength || e.target.value.length <= this.maxlength) {
                this.$emit('input', e.target.value)
            } else {
                this.$refs.input.value = e.target.value.slice(0, this.maxlength)
            }
        },
        clearInput() {
            this.$emit('input', this.type !== 'number' ? '' : 0);
            this.$emit('click:clear');
            this.focusInput();
        }
    }
}
</script>

<template>
    <div v-if="type === 'table'" class="content-preloader--table">
        <div v-if="screenWidth > 1024" class="content-preloader__item" style="height: 28px" />
        <div
            v-for="(item, index) in 9"
            :key="index"
            class="content-preloader__item"
            :style="`width: ${width}px;height: ${height}px`" 
        />
    </div>
    <div v-else class="content-preloader--block">
        <div
            class="content-preloader__item"
            :class="{'rounded-item': rounded}"
            :style="`width: ${width}px;height: ${height}px`"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ContentPreloader',
    props: {
        type: { type: String, default: 'block' },
        width: { type: [String, Number], default: '' },
        height: { type: [String, Number], default: '' },
        rounded: { type: Boolean, default: false }
    },
    computed: {
        ...mapGetters({
            screenWidth: 'ui/width',
        }),
    },
}
</script>

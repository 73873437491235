<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Аккаунты"
                for-listing
                :show-save-btn="false"
                filter
                :filterIndicator="!!activeFilters.length"
                :search="width <= 699"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'franchises-filter')"
                @click:search="isVisibleSearch = !isVisibleSearch"
            />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <Search
                    :value="filters.search"
                    :is-visible-mobile="isVisibleSearch"
                    @input="$store.commit('franchises/SET_FILTER', { field: 'search', value: $event}), getFilteredFranchises()"
                />

                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="franchises && franchises.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: calc(100% / 7)">Логин</div>
                            <div class="listing-table__col" style="width: calc(100% / 7)">Телефон</div>
                            <div class="listing-table__col" style="width: calc(100% / 7)">Email</div>
                            <div class="listing-table__col" style="width: calc(100% / 7)">Активный тариф</div>
                            <div class="listing-table__col" style="width: calc(100% / 7)">Количество магазинов</div>
                            <div class="listing-table__col" style="width: calc(100% / 7)">
                                <sort-table
                                    title="Дней до конца подписки"
                                    :direction="sort.direction"
                                    type="subscription_franchiseoptions__count_days_until_end"
                                    :active="sort.type === 'subscription_franchiseoptions__count_days_until_end'"
                                    @change="changeTableSort"
                                />
                            </div>
                            <div class="listing-table__col" style="width: calc(100% / 7)">
                                <sort-table
                                    title="Дата создания аккаунта"
                                    :direction="sort.direction"
                                    type="date_created"
                                    :active="sort.type === 'date_created'"
                                    @change="changeTableSort"
                                />
                            </div>
                        </div>
                        <router-link
                            v-for="item in franchises"
                            :key="item.id"
                            :to="`/business/${item.id}`"
                            class="listing-table__row"
                        >
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                <div class="listing-table__col-label">Логин</div>
                                <span class="text-cut">{{ item.admin.username }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                <div class="listing-table__col-label">Телефон</div>
                                <span class="text-cut">{{ item.admin.phone || '&mdash;' }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                <div class="listing-table__col-label">Email</div>
                                <span class="text-cut">{{ item.admin.email || '&mdash;' }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                <div class="listing-table__col-label">Активный тариф</div>
                                <span class="text-cut">{{ item.tariff || '&mdash;' }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                <div class="listing-table__col-label">Количество магазинов</div>
                                <span class="text-cut">{{ item.count_of_shop }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                <div class="listing-table__col-label">Дней до конца подписки</div>
                                <span class="text-cut">{{ item.count_days_until_end_subscription }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                <div class="listing-table__col-label">Дата создания аккаунта</div>
                                <span class="text-cut">{{ item.date_created ? date(item.date_created, 'hours-ru') : '&mdash;' }}</span>
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="franchises && !franchises.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from '@/utils/functions';
import { date, numberFormat } from '@/utils/format';
import Spinner from '@/components/app/Spinner.vue';

export default {
    name: 'TheFranchise',
    metaInfo: {
        title: 'Аккаунты'
    },
    components: { Spinner },
    data() {
        return {
            isVisibleSearch: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            franchises: 'franchises/franchises',
            busy: 'franchises/busy',
            next: 'franchises/next',
            sort: 'franchises/sort',
            filters: 'franchises/filters',
            activeFilters: 'franchises/activeFilters',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
        authUserSite() {
            return JSON.parse(localStorage.getItem("user")).site;
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        if (this.authUserGroup !== 'superadministrators') {
            this.$router.push(`/business/${this.authUserSite}`);
        } else {
            await Promise.all([
                this.$store.dispatch('franchises/LOAD_FRANCHISES'),
                this.$store.dispatch('franchises/GET_FILTER_MIN_MAX_VALUES')
            ]);
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        loadMore() {
            this.$store.dispatch('franchises/GET_NEXT_PAGE');
        },
        getFilteredFranchises: debounce(function (field, value) {
            this.filterFranchises(field, value);
        }, 1000),
        async filterFranchises(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('franchises/LOAD_FRANCHISES', { field, value });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        changeTableSort({ type }) {
            if (this.sort.type === type) {
                this.$store.commit('franchises/CHANGE_SORT', { type, direction: this.sort.direction === '' ? '-' : '' });
            }
            this.$store.commit('franchises/CHANGE_SORT', { type, direction: this.sort.direction });
            this.$store.dispatch('franchises/LOAD_FRANCHISES', { field: 'ordering', value: `${this.sort.direction}${type}` });
            window.scrollTo(0, 0);
        },
        date(value, type) {
            return date(new Date(value), type);
        },
        numberFormat(value) {
            if (value) return numberFormat(value);
            return value || 0;
        },
    }
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Клиенты"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <p>В списке клиентов отображаются поля:</p>
                                <ul>
                                    <li>Фото</li>
                                    <li>Имя</li>
                                    <li>Пол</li>
                                    <li>Телефон</li>
                                    <li>Количество покупок</li>
                                    <li>Сумма всех покупок</li>
                                    <li>Средний чек</li>
                                    <li>Текущее количество бонусов</li>
                                    <li>Дата последней покупки</li>
                                </ul>

                                <p>Есть поиск по имени и номеру телефона.</p>

                                <p>В фильтре можно выбрать параметры:</p>
                                <ul>
                                    <li>Пол</li>
                                    <li>Количество покупок</li>
                                    <li>Сумма покупок</li>
                                    <li>Средний чек</li>
                                    <li>Бонусы</li>
                                    <li>Дата последней покупки</li>
                                </ul>

                                <p>При нажатии на некоторые заголовки в шапке таблицы можно отсортировать список.</p>
                                <img src="@/assets/img/help/clients/1.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Пользователя можно выделить, если нажать на фото. При клике на колокольчик откроется страница создания рассылки.</p>
                                <img src="@/assets/img/help/clients/2.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Просмотр и редактирование клиента описаны в разделе <router-link to="/help/users">"Сотрудники"</router-link>, так как система не разделяет пользователей на клиент/персонал. Все - это пользователи с отличающимися ролями. </p>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'Товары', link: '/help/products'}, rightNavigationLink]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpClients',
    metaInfo: {
        title: 'Клиенты - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        rightNavigationLink() {
            if (this.authUserGroup === 'supervisor') {
                return { name: 'Статистика', link: '/help/statistic' }
            }
            return { name: 'К оглавлению', link: '/help' }
        }
    },
};
</script>

/* eslint-disable */
import { getReturns, getReturnData, getNextPageReturns } from '@/api/django';

const state = {
    returns_list: [],
    returns_good_list: [],
    return_all:true,
    busy: false,
    next:null,
    offset:20,
    limit:20
};

const mutations = {
    SET_RETURNS_LIST(state, returns) {
        state.returns_list = returns.results;
        state.next = !!returns.next;
        state.busy = false;
    },
    SET_NEXT_RETURNS_LIST(state, returns) {
        state.returns_list = state.returns_list.concat(returns.results);
        state.next = !!returns.next;
        state.offset += 20;
        state.busy = false;
    },
    SET_RETURNS_GOOD_LIST(state, returns_good_list ) {
        state.returns_good_list = returns_good_list;
        for (let i = 0; i < state.returns_good_list.length; i++) {
            state.returns_good_list[i].return = true;
            state.returns_good_list[i].max_count = state.returns_good_list[i].count;
        }
    },
    CHECK_ALL_GOODS(state,value) {
        if (value === true) {
            for (let i = 0; i < state.returns_good_list.length; i++) {
                state.returns_good_list[i].return = true;
            }
        }
        else {
            for (let i = 0; i < state.returns_good_list.length; i++) {
                state.returns_good_list[i].return = false;
            }
        }
    },
    CHANGE_GOOD_FIELD_VALUE(state, { vendor_code, value, field }) {
        for (let i = state.returns_good_list.length; i--;) {
            if (state.returns_good_list[i].vendor_code === vendor_code) {
                state.returns_good_list[i][field] = value;
                break;
            }
            if (state.returns_good_list[i].product === vendor_code) {
                state.returns_good_list[i][field] = value;
                break;
            }
        }
    },
    SET_GOOD_RETURN(state,{good,index}) {
        state.returns_good_list.splice(index, 1, good);
    },
    CLEAR_GOODS_LIST(state) {
        state.returns_good_list = [];
    }
};

const actions = {
    async GET_RETURNS_LIST({ commit }) {
        try {
            const data = await getReturns();
            commit('SET_RETURNS_LIST', data);
        } catch (error) {
            console.log(error);
        }
    },
    async GET_RETURN_DATA({ commit }, id) {
        try {
            const res = await getReturnData(id);
            return res;
        } catch (error) {
            console.log(error);
        }
    },
    async LOAD_MORE({ state, commit }) {
        try {
            if (state.next) {
                state.busy = true;
                const data = await getNextPageReturns(state.limit, state.offset);
                commit('SET_NEXT_RETURNS_LIST', data);
            }
        } catch (error) {
            console.log(error);
        }
    },
};

const getters = {
    returns_list: state => state.returns_list,
    returns_good_list: state => state.returns_good_list,
    return_all: state => state.return_all,
    busy: state => state.busy,
    next: state => state.next,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Статичные страницы"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <p>Раздел <b>"Страницы"</b> отвечает за статичные страницы на сайте. Активные страницы этого раздела отображаются в футере сайта.</p>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список страниц</h2>
                                <p>Список страниц содержит поля:</p>
                                <ul>
                                    <li>Название</li>
                                    <li>Адрес</li>
                                </ul>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание и редактирование страницы</h2>
                                <p>При создании страницы доступны 2 вкладки с полями.</p>
                                <ul>
                                    <li>Основное</li>
                                    <li>Текст</li>
                                </ul>
                                <p>Кнопка <b>"Активность"</b> включает или отключает отображение страницы на сайте.</p>
                                <p>Все остальные поля имеют понятные подписи.</p>
                                <p>Редактирование страниц не имеет никаких отличительных особенностей.</p>
                                <p>Удалить статичную страницу можно через дополнительное меню.</p>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{ name: 'Подписка', link: '/help/subscription' }, {name: 'К оглавлению', link: '/help'}]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpPages',
    metaInfo: {
        title: 'Статичные страницы - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

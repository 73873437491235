<template>
    <div class="side-filter__content">
        <div class="side-filter__content-group-list">
            <div class="side-filter__content-group">
                <InputSelect 
                    :value="filters.seen"
                    :options="notificationsTypeOptions"
                    input-value="value"
                    icon="medal"
                    without-preloader
                    @input="filterNotifications('seen', $event)"
                />
            </div>
        </div>
        
        <button class="side-filter__clear-btn" @click.prevent="clearFilter">
            Очистить
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'NotificationsFilter',
    data() {
        return {
            notificationsTypeOptions: [
                { name: 'Все', value: null },
                { name: 'Непрочитано', value: false },
                { name: 'Прочитано', value: true }
            ],
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            filters: 'notifications/filters'
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    methods: {
        async filterNotifications(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('notifications/GET_USER_NOTIFICATIONS', { field, value });
                window.scrollTo(0, 0);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async clearFilter() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                window.scrollTo(0, 0);
                this.$store.commit('ui/CLOSE_FILTER', 'notifications-filter');
                await this.$store.dispatch('notifications/GET_USER_NOTIFICATIONS', { field: 'seen', value: null });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
    },
};
</script>

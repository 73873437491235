<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Товары"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 id="list" class="page__info-group-title">Список товаров</h2>
                                <p>В списке товаров отображаются поля:</p>
                                <ul>
                                    <li>Фото товара</li>
                                    <li>Название товара</li>
                                    <li>Время сборки (сколько нужно времени флористу, чтобы собрать букет или приготовить торт - с учетом разных обстоятельств у товаров это время может быть разным. У тортов обычно - 24 часа (готовят по утрам), у букетов из каталога - 1 час, у букетов с витрины - 0ч, у свадебных букетов - 7 дней и тд)</li>
                                    <li>Стоимость товара</li>
                                </ul>

                                <template v-if="authUserGroup !== 'florist'">
                                    <p>Товары можно отфильтровать по показателям:</p>
                                    <ul>
                                        <li>Наличие в магазине</li>
                                        <li>Удаленные - фильтр позволяет посмотреть удаленные товары</li>
                                        <li>Создатель</li>
                                    </ul>
                                </template>

                                <div v-if="['supervisor', 'administrators'].includes(authUserGroup)" class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Кнопка генерирует csv файл для скачивания товаров
                                        </div>
                                        <div class="help__table-col" style="max-width: 96px">
                                            <img src="@/assets/img/help/categories/2.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>

                                <template v-if="!['florist', 'managers'].includes(authUserGroup)">
                                    <p>Если нажать на фотографию товара, то товар выделится. С помощью иконки <b>"Глаз"</b> можно переключать видимость товара.</p>
                                    <img src="@/assets/img/help/products/1.jpg" class="help__image" alt="" style="margin: 16px auto">
                                </template>

                                <p>При нажатии на стрелочку можно посмотреть состав товара, если товар не является составляющим.</p>
                                <p>У товаров могут быть значки <b>"Акция"</b> и <b>"Недоступен"</b>.</p>
                                <img src="@/assets/img/help/products/2.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <template v-if="authUserGroup !== 'florist'">
                                    <p>Справа отображается блок управления категориями товаров.</p>
                                    <p>Если категория второго уровня имеет дочерние, то ее можно развернуть.</p>
                                    <template v-if="['supervisor', 'administrators'].includes(authUserGroup)">
                                        <p>Если нажать на иконку карандаша, то откроется страница редактирования выбранной категории.</p>
                                        <p>Как управлять категориями товаров описано в разделе <a href="/help/categories" target="_blank">"Категории и теги"</a>.</p>
                                    </template>
                                    <img src="@/assets/img/help/products/3.jpg" class="help__image" alt="" style="margin: 16px auto">
                                </template>
                            </div>
                            <div class="page__info-group">
                                <h2 id="create" class="page__info-group-title">
                                    {{ authUserGroup !== 'managers' ? 'Создание, просмотр и редактирование товаров' : 'Просмотр и редактирование товаров' }}
                                </h2>
                                <p>На странице товара есть {{ !['florist', 'managers'].includes(authUserGroup) ? '3' : '2' }} вкладки: Описание, Товары{{ !['florist', 'managers'].includes(authUserGroup) ? ', Теги' : '' }}.</p>
                                <p v-if="['supervisor', 'administrators'].includes(authUserGroup)">
                                    Через дополнительное меню можно посмотреть статистику продаж или удалить товар.
                                </p>

                                <h3 id="about">Вкладка Описание</h3>
                                <p>Тут заполняются основные данные товара.</p>
                                <p v-if="authUserGroup !== 'managers'">Если товару не добавлять изображение, то добавится изображение-заглушка.</p>

                                <template v-if="authUserGroup !== 'florist'">
                                    <p>У товара в зависимости от выбранного типа могут отличаться переключатели.</p>
                                    <p>Переключатели для типа <b>"Основной"</b> (Основной - тип, используемый для букетов, десертов и других товаров, которые состоят из нескольких составляющих).</p>
                                    <img src="@/assets/img/help/products/4.jpg" class="help__image" alt="" style="margin: 16px auto">

                                    <p><b>Показывать на сайте</b> - товар можно будет найти на сайте.</p>
                                    <p><b>Доступен для покупки</b> - товар отображается на сайте, но с подписью <b>"Нет в наличии"</b>.</p>
                                    <img src="@/assets/img/help/products/5.jpg" class="help__image" alt="" style="margin: 16px auto">
                                    <p><b>Пожелание для заказа</b> - при создании заказа у товара будет текстовое поле, куда можно ввести текст. Работает на сайте и в IS.</p>
                                    <img src="@/assets/img/help/products/6.jpg" class="help__image" alt="" style="margin: 16px auto">

                                    <p>Для товаров типа <b>"Комплектующий"</b> доступны дополнительные переключатели.</p>
                                    <img src="@/assets/img/help/products/7.jpg" class="help__image" alt="" style="margin: 16px auto">
                                    <p><b>Изменять цену</b> - отвечает за возможность изменять цену комплектующего при создании товара типа <b>"Основной"</b>.</p>
                                    <img src="@/assets/img/help/products/8.jpg" class="help__image" alt="" style="margin: 16px auto">

                                    <template v-if="authUserGroup !== 'managers'">
                                        <p>Доступные опции в селекте категорий изменяются в зависимости от типа товара. Например, если тип товара “Основной”, то в опциях будут только те категории, которые отмечены как основные.</p>
                                        <p>Если категория имеет только 1 уровень, то она добавится в опции. Если 2 или 3 уровня, то первый уровень не добавляется.</p>
                                        <p>Если в списке товаров какая-то категория была активна, то создаваемый товар сразу будет отнесен к этой категории.</p>
                                        <p>Например, в списке просматривали товары категории "Букеты из роз" и нажали на кнопку "создать товар". Новый товар автоматически получит категорию "Розы". При необходимости категорию можно менять.</p>
                                        <img src="@/assets/img/help/products/9.jpg" class="help__image" alt="" style="margin: 16px auto">

                                        <p>Тип увеличения количества отвечает за способ увеличения букета на сайте и в IS.</p>
                                        <p><b>Не установлено</b> - на сайте увеличить букет нельзя.</p>
                                        <p><b>На 30%/60%</b> - добавляется переключатель Стандарт/Большой/Премиум.</p>
                                        <img src="@/assets/img/help/products/10.jpg" class="help__image" alt="" style="margin: 16px auto">
                                        <p><b>Прибавить по одному</b> - добавляется поштучное увеличение.</p>
                                        <img src="@/assets/img/help/products/11.jpg" class="help__image" alt="" style="margin: 16px auto">
                                    </template>


                                    <p>Поле <b>"Время сборки"</b> влияет на минимально возможное время доставки/самовывоза товара.</p>

                                    <p>Для товаров типа <b>"Основной"</b> поле <b>"Стоимость"</b> заблокировано. Значение рассчитывается исходя из стоимости добавленных комплектующих.</p>

                                    <template v-if="authUserGroup !== 'managers'">
                                        <p><b>Описание</b> - будет выводиться на сайте на вкладке "Состав и описание".</p>
                                        <img src="@/assets/img/help/products/12.jpg" class="help__image" alt="" style="margin: 16px auto">

                                        <p><b>META-TITLE</b> и <b>META-DESCRIPTION</b> - поля необходимые для SEO. Обычно изменение этих полей не требуется.</p>

                                        <p>Если выбран тип товаров <b>"Комплектующий"</b>, то доступные для редактирования поля немного изменяются.</p>
                                        <p><b>Стоимость</b> - вводится вручную в отличие от основных товаров.</p>
                                    </template>
                                </template>

                                <p v-if="authUserGroup !== 'managers'">Для добавления изображения товара нужно нажать на кнопку <b>"Загрузить фото"</b>.</p>
                                <p>У изображений есть меню управления. Можно менять порядок или удалять их.</p>
                                <img src="@/assets/img/help/products/17.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <h3 id="products">Вкладка Товары</h3>
                                <p>На этой вкладке заполняется состав основного типа товаров. Если товары типа <b>"Комплектующий"</b>, то на этой вкладке ничего не отображается. На ПК порядок составляющих в списке можно менять через Drag&Drop.</p>
                                <p v-if="authUserGroup !== 'managers'">Для редактирования списка товаров нужно нажать на кнопку <b>"Изменить"</b>. Откроется модальное окно с комплектующими.</p>

                                <p v-if="authUserGroup !== 'managers'">У комплектующих можно изменить цену (если комплектующему это разрешено), количество.</p>
                                <p v-if="authUserGroup !== 'florist'">Также есть 2 переключателя: <b>Изменять кол-во</b>, <b>Показывать кол-во</b>.</p>
                                <img src="@/assets/img/help/products/13.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <template v-if="authUserGroup !== 'florist'">
                                    <p><b>Изменять кол-во</b> - имеет значение, если для создаваемого товара указан тип увеличения количества. Если выставлен вариант <b>"Прибавлять по одному"</b>, то в списке комплектующих может быть только 1 товар с активным переключателем <b>"Изменять кол-во"</b>. Именно это составляющие и будет в счетчике. Если у какого-то комплектующего это опция отключена, то при изменении количества на 30/60% значение штук меняться не будет.</p>
                                    <p><b>Показывать кол-во</b> - отвечает за отображение счетчика количества.</p>
                                    <img src="@/assets/img/help/products/14.jpg" class="help__image" alt="" style="margin: 16px auto">

                                    <template v-if="authUserGroup !== 'managers'">
                                        <h3 id="tags">Вкладка Теги</h3>
                                        <p>На вкладке отображаются доступные теги. Тут нужно отнести товар к подходящим вариантам.</p>
                                        <p>Пример работы на уже созданном товаре:</p>
                                        <p>На сайте в какой-нибудь категории доступен для фильтрации тег "Другу". Отметим этот тег для товара в IS, а затем активируем фильтр на сайте. Теперь товар отображается.</p>
                                        <img src="@/assets/img/help/products/15.jpg" class="help__image" alt="" style="margin: 16px auto">
                                        <img src="@/assets/img/help/products/16.jpg" class="help__image" alt="" style="margin: 16px auto">
                                    </template>
                                </template>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[leftNavigationLink, rightNavigationLink]" />
                    </div>
                    <div class="page-small-column page-small-column--mobile-top">
                        <HelpSidebar
                            :links="[
                                { name: 'Список товаров', link: 'list' },
                                {
                                    name: authUserGroup !== 'managers' ? 'Создание, просмотр и редактирование товара' : 'Просмотр и редактирование товара',
                                    link: 'create',
                                    children: [
                                        { name: 'Вкладка описание', link: 'about' },
                                        { name: 'Вкладка товары', link: 'products' },
                                        { name: 'Вкладка теги', link: 'tags', groups: ['administrators', 'supervisor'] },
                                    ]
                                }
                            ]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'
import HelpSidebar from '@/components/app/help/HelpSidebar'

export default {
    name: 'TheHelpProducts',
    metaInfo: {
        title: 'Товары - Помощь'
    },
    components: {
        HelpNavigationFooter, HelpSidebar
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        leftNavigationLink() {
            if (['florist', 'courier'].includes(this.authUserGroup)) {
                return { name: 'Заказы', link: '/help/orders' }
            }
            return { name: 'Чаты', link: '/help/chat' }
        },
        rightNavigationLink() {
            if (['administrators', 'supervisor'].includes(this.authUserGroup)) {
                return { name: 'Клиенты', link: '/help/clients' }
            }
            return { name: 'К оглавлению', link: '/help' }
        }
    },
};
</script>

<template>
    <div class="universal-modal__component">
        <UniversalModalHeader
            :title="order_list.length ? `${order_list.length} шт.` : 'Выберите товары'"
            @click:right="$store.commit('ui/CLOSE_MODAL', 'modal-goods')"
        >
            <template #rightButtons>
                <button
                    v-if="order_list.length"
                    type="button"
                    class="universal-modal__header-right-btn"
                    @click.prevent="clearEquip"
                >
                    <Icon>file-minus</Icon>
                </button>
                <button
                    v-if="width <= 699"
                    type="button"
                    class="universal-modal__header-right-btn"
                    @click.prevent="isSearchShow = !isSearchShow"
                >
                    <Icon>search</Icon>
                </button>
            </template>
        </UniversalModalHeader>
        <div
            ref="scrolledBlock"
            class="universal-modal__content"
            v-infinite-scroll="loadMore" infinite-scroll-distance="300" infinite-scroll-immediate-check="false"
        >
            <Search
                :value="filters.name"
                :is-visible-mobile="isSearchShow"
                observer-margin="45"
                @input="$store.commit('goods/SET_FILTER', { field: 'name', value: $event}), getFilteredGoods()"
            />

            <button
                :id="width <= 699 ? 'order-tutorial__step-3' : ''"
                type="button"
                class="goods__category-name"
                @click.prevent="showCategoryModal = true"
            >
                {{ selectedCategory.is_name || 'Все товары' }}
                <Icon class="ml-2" color="#8AACCD">down</Icon>
            </button>

            <div class="page-columns">
                <div class="page-big-column">
                    <div v-show="!preloader" class="listing-table__wrap">
                        <div v-if="goods && goods.length" class="listing-table listing-table--products">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                <div class="listing-table__col pl-0">Наименование</div>
                                <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;text-align:center;">Количество</div>
                                <div class="listing-table__col" style="width: 96px; flex: 0 0 auto;">Сборка</div>
                                <div class="listing-table__col" style="width: 100px; flex: 0 0 auto;">Стоимость</div>
                            </div>
                            <a
                                v-for="item in goods"
                                :key="item.vendor_code"
                                class="listing-table__row"
                                :class="{ active: selected(item.vendor_code) }"
                                @click.prevent="addGood(item)"
                            >
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image">
                                        <img
                                            :src="item.images.length && item.images[0].image ? item.images[0].image.preview : require('@/assets/img/flower.svg')"
                                            alt=""
                                        >
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width < 1024}"
                                    :style="width > 1024 ? 'width: calc(100% - 466px)' : 'width: calc(100% - 68px)'"
                                >
                                    <div>
                                        <div class="listing-table__product-name">
                                            <span>{{ item.name }}</span>
                                            <Icon
                                                v-if="item.equipment_set.length"
                                                class="listing-table__product-name-chevron"
                                                :class="{'rotate': opened.includes(item.vendor_code)}"
                                                color="#8AACCD"
                                                @click.stop.prevent="openHandler(item.vendor_code)"
                                            >
                                                down
                                            </Icon>
                                        </div>
                                        <div v-if="item.discount || item.is_hidden" class="listing-table__badges">
                                            <div v-if="item.is_hidden" class="listing-table__badges-item">
                                                Недоступен
                                            </div>
                                            <div
                                                v-if="item.discount"
                                                class="listing-table__badges-item"
                                                :class="{ 'listing-table__badges-item--yellow': item.discount }"
                                            >
                                                Акция
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="item.equipment_set.length && opened.includes(item.vendor_code)"
                                    class="listing-table__product-equipment-list"
                                >
                                    <div class="listing-table__product-equipment-list-label">Состав:</div>
                                    <div v-for="(eq, index) in item.equipment_set" :key="index" class="listing-table__product-equipment-item">
                                        <div>{{ eq.equipment_product.name }}</div>
                                        <div>{{ eq.count }} шт.</div>
                                    </div>
                                </div>

                                <div v-if="width < 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                <div
                                    class="listing-table__col"
                                    :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : ''"
                                >
                                    <InputCounter v-model="item.count" :disabled="!!goodsModalMode" @input="changeProductCount(item)" />
                                </div>
                                <div
                                    class="listing-table__col"
                                    :style="width > 1024 ? 'width: 96px; flex: 0 0 auto;' : ''"
                                >
                                    <div class="listing-table__col-label">Сборка</div>
                                    {{ item.order_time }} ч.
                                </div>
                                <div
                                    class="listing-table__col"
                                    :style="width > 1024 ? 'width: 100px; flex: 0 0 auto;' : ''"
                                >
                                    <div class="listing-table__col-label">Стоимость</div>
                                    {{ item.discount ? formatNumber(+item.discount.price_with_discount) : formatNumber(+item.price) }} ₽
                                </div>
                            </a>
                            <div class="listing-table__row listing-table__row--preloader">
                                <div class="listing-table__col">
                                    <Spinner v-show="busy && next" />
                                </div>
                            </div>
                        </div>
                        <empty-table v-show="goods && !goods.length" />
                    </div>
                    <ContentPreloader v-show="preloader" type="table" height="96" />
                </div>
                <div v-if="width > 699" class="page-small-column">
                    <ProductsCategories
                        :offsetHeight="190"
                        :activeCategory="filters.category"
                        @changeCategory="filterByCategory"
                    />
                </div>
            </div>
        </div>
        <UniversalModal v-model="showCategoryModal">
            <UniversalModalHeader
                :title="'Категории'"
                @click:right="showCategoryModal = false"
            />
            <div class="universal-modal__content">
                <ProductsCategories
                    :activeCategory="filters.category"
                    @changeCategory="filterByCategory"
                />
            </div>
        </UniversalModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getGoodData } from '@/api/django';
import { debounce } from '@/utils/functions';
import { numberFormat } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'GoodsModal',
    components: { Spinner },
    data() {
        return {
            selectedCategory: {},
            opened: [],
            isSearchShow: false,
            showCategoryModal: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            user: 'auth/user',
            busy: 'goods/busy',
            isModalOpened: 'ui/isModalOpened',
            order_list: "order/order_list",
            filters: 'goods/filters',
            goods: 'goods/goods',
            next: 'goods/next',
            preloader: 'ui/preloader',
            selectedProductsOrders: 'order/selected',
            pickedProductsMotivations: 'motivations/pickedProducts',
            pickedProductsCatalogs: 'catalogs/pickedProducts',
            pickedProductsTags: 'tags/pickedProducts',
            goodsModalMode: 'ui/goodsModalMode'
        }),
        selected() {
            if (this.goodsModalMode === 'motivations') {
                return this.pickedProductsMotivations;
            } if (this.goodsModalMode === 'catalogs') {
                return this.pickedProductsCatalogs;
            } if (this.goodsModalMode === 'tags') {
                return this.pickedProductsTags;
            } else {
                return this.selectedProductsOrders;
            }
        },
        group() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            this.$store.commit('goods/CLEAR');
            this.$store.commit('goods/CLEAR_FILTERS_STATE');
            await this.$nextTick();
            await this.$store.dispatch('goods/GET_ALL_GOODS');
            this.syncOrderListWithProductList();
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }

    },
    beforeDestroy() {
        this.$store.commit('goods/CLEAR_FILTERS_STATE');
    },
    methods: {
        openHandler(id) {
            if (this.opened.includes(id)) {
                this.opened = this.opened.filter((a) => a !== id);
            } else {
                this.opened.push(id);
            }
        },
        getFilteredGoods: debounce(function (field, value) {
            this.filterGoods(field, value);
        }, 1000),
        async filterGoods(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('goods/GET_ALL_GOODS', { field, value });
                this.syncOrderListWithProductList();
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async addGood(good) {
            let obj = good;
            if (!this.goodsModalMode) {
                obj = await getGoodData(good.vendor_code);
                obj = { ...obj, count: +good.count }
            }

            if (this.goodsModalMode === 'motivations') {
                this.$store.dispatch('motivations/SET_PRODUCTS', obj);
            } else if (this.goodsModalMode === 'catalogs') {
                this.$store.dispatch('catalogs/SET_PRODUCTS', obj);
            } else if (this.goodsModalMode === 'tags') {
                this.$store.dispatch('tags/setTagProducts', obj);
            } else {
                this.$store.dispatch('order/EVENT_HANDLER', obj);
            }
        },
        changeProductCount: debounce(function (product) {
            // Если товар уже в списке заказа, то изменяем его количество и заменяем весь список на новый с учетом изменений
            // Если товара нет в списке заказов, то его количество сохраняется через addGood()
            if (this.selected(product.vendor_code)) {
                let copyOrderList = JSON.parse(JSON.stringify(this.order_list));
                let find = copyOrderList.find((item) => item.vendor_code === product.vendor_code);
                if (find) {
                    find.count = +product.count;
                    this.$store.commit('order/SET_ORDER_LIST', { order_list: copyOrderList });
                }
            }
        }, 500),
        syncOrderListWithProductList() {
            // синхронизируем количество товаров в списке заказа с количеством в текущей модалке
            if (this.goods.length) {
                this.goods.forEach((item) => {
                    if (this.selected(item.vendor_code)) {
                        const find = this.order_list.find((product) => product.vendor_code === item.vendor_code);
                        if (find) {
                            item.count = find.count;
                        }
                    }
                })
            }
        },
        async loadMore() {
            await this.$store.dispatch('goods/GET_NEXT_PAGE');
            this.syncOrderListWithProductList();
        },
        async filterByCategory(category) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.showCategoryModal = false;
                this.$refs.scrolledBlock.scrollIntoView();
                if (this.filters.category === null || this.filters.category !== category.id) {
                    this.selectedCategory = category;
                    await this.$store.dispatch('goods/GET_ALL_GOODS', { field: 'category', value: category.id });
                } else {
                    this.selectedCategory = {};
                    await this.$store.dispatch('goods/GET_ALL_GOODS', { field: 'category', value: null });
                }
                this.syncOrderListWithProductList();
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        clearEquip() {
            this.$store.commit('order/CLEAR_ORDER_LIST');
        },
        formatNumber(number) {
            return number === 0 ? 0 : numberFormat(number);
        },
    }
};
</script>

/* eslint-disable */
import router from '@/router';

import {
    getGoodData,
    createGood,
    updateGood,
    deleteGood,
} from '@/api/django';

const state = {};

const mutations = {};

const actions = {
    async GET_GOOD_DATA({ commit, dispatch }, vendor_code) {
        try {
            const good = await getGoodData(vendor_code);
            commit('equipments/SET_EQUIPMENT', good.equipment_set, { root: true });
            dispatch('equipments/TRANSFORM_EQUIPMENT_SET', good, { root: true });
            return good;
        } catch (error) {
            if(error.message === 'Network Error') {
                localStorage.setItem('redirectUnavailable', location.pathname);
                router.push('/unavailable');
            }
            return {}
        }
    },
    async CREATE_GOOD({ rootGetters, commit }, good) {
        commit('equipments/PATCH_SET', good.equipment_set, { root: true });
        return await createGood({...good, equipment_set: rootGetters['equipments/equipment_set_server'] });
    },
    async UPDATE_GOOD({ rootGetters, commit }, { vendor_code, good }) {
        commit('equipments/PATCH_SET', good.equipment_set, { root: true })
        return await updateGood(vendor_code, {...good, equipment_set: rootGetters['equipments/equipment_set_server'] });
    },
    async DELETE_GOOD({}, vendor_code) {
        try {
            return await deleteGood(vendor_code);
        } catch (error) {
            return error;
        }
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

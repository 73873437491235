/* eslint-disable */
import {
    getOrderData,
    createOrder,
    updateOrderData,
    getOrderHistory,
} from '@/api/django';

const state = {
    order_list: [],
    order_history: [],
};

const mutations = {
    SET_ORDER_LIST(state, { order_list }) {
        state.order_list = order_list;
    },
    ADD_GOOD_TO_ORDER_LIST(state, { name, price, ...good }) {
        let equipment_set = [];
        good.equipment_set.forEach(equipment => {
            equipment_set.push({
                vendor_code: equipment.equipment_product.vendor_code,
                name: equipment.equipment_product.name,
                price: equipment.price,
                count: equipment.count,
                image: equipment.equipment_product.images.length ? equipment.equipment_product.images[0].image : null,
                sort: equipment.sort,
            })
        });

        let equipmentSummPrice = equipment_set.reduce((sum, current) => {
            return sum + (current.count * current.price)
        }, 0)

        if (!good.equipment_set.length) equipmentSummPrice = +price;

        state.order_list.push({
            vendor_code: good.vendor_code,
            name,
            discount: good.discount ? { ...good.discount, active: true } : null,
            price: good.discount ? good.discount.price_with_discount : equipmentSummPrice,
            price_without_discount: +price,
            count: good.count || 1,
            image: good.images[0].image,
            order_variant: "regular",
            slug: good.slug,
            equipment_set,
            sort: state.order_list.length ? state.order_list[state.order_list.length - 1].sort + 1 : 1,
            upgrade_type: good.upgrade_type,
            price_change: good.price_change,
            is_wishes_allow: good.is_wishes_allow,
            wishes_for_product: good.wishes_for_product,
            type: good.type
        });
    },
    REMOVE_GOOD_FROM_ORDER_LIST(state, good) {
        state.order_list = state.order_list.filter(item => item.vendor_code !== good.vendor_code );
    },
    CHANGE_ORDER_LIST(state) {
        state.order_list = state.order_list.map(a => a.vendor_code);
    },
    CHANGE_GOOD_FIELD_VALUE(state, { product, value, field }) {
        for (let i = state.order_list.length; i--;) {
            if (state.order_list[i].product.vendor_code === product.vendor_code) {
                state.order_list[i].product[field] = value;
                break;
            }
        }
    },
    CLEAR_ORDER_LIST(state) {
        state.order_list = [];
    },
    SET_ORDER_HISTORY(state, history) {
        state.order_history = history;
    }
};

const actions = {
    async GET_ORDER_DATA({ commit }, id) {
        try {
            const order = await getOrderData(id);
            commit('SET_ORDER_LIST', order);
            return order;
        } catch (error) {
            console.log(error);
        }
    },
    async CREATE_ORDER({ commit, getters }, order) {
        return await createOrder({...order });
    },
    async UPDATE_ORDER({ commit, getters }, { id, order }) {
        let data = {...order };
        delete data.florist_photo;
        delete data.photo_with_receiver;
        // delete data.delivery_time_start;
        // delete data.delivery_time_end;
        return await updateOrderData({...data }, id)
    },
    EVENT_HANDLER({ commit, getters }, good) {
        if (getters['selected'](good.vendor_code)) {
            commit('REMOVE_GOOD_FROM_ORDER_LIST', good);
        } else {
            commit('ADD_GOOD_TO_ORDER_LIST', good)
        }
    },
};

const getters = {
    order_list: state => state.order_list,
    order_history: state => state.order_history,
    selected: state => id => !!state.order_list.find(good => good.vendor_code === id)
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <aside ref="fixed-sidebar" class="navigation" :class="{'is-open': isOpenedNavigation}">
        <div class="navigation__content-wrap" @scroll="scrollMenu">
            <div class="navigation__content">
                <div class="navigation__header">
                    <div class="navigation__logo" @click.prevent="reloadPage">
                        <img src="@/assets/img/content/logotype.svg" alt="Saastra">
                    </div>
                    <v-tooltip v-if="group !== 'florist' && group !== 'superadministrators'" right color="#000">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <ButtonTimeTracking  />
                            </div>
                        </template>
                        <span>Таймер рабочего времени</span>
                    </v-tooltip>
                </div>
                <nav v-if="isLoad" class="navigation__links">
                    <div v-for="item in menu" :key="item.id" class="navigation__links-group">
                        <div v-if="item.name" class="navigation__links-group-title">
                            {{ item.name }}
                        </div>
                        <template v-for="link in item.links">
                            <a
                                v-if="link.groups.includes(group) && franchiseTariff.tariff.includes(link.id) && link.id !== 'feedback-modal'"
                                :key="link.id"
                                :href="link.to"
                                class="navigation__link"
                                :class="{
                                    'active': $route.path === link.to ||
                                        $route.path.split('/').slice(0, -1).join('/') === link.to
                                }"
                                @click.prevent="goToNavLink(link)"
                            >
                                <v-tooltip right color="#000" :disabled="width <= 899 || width > 1270">
                                    <template v-slot:activator="{ on, attrs }">
                                        <Icon class="navigation__link-icon" v-bind="attrs" v-on="on">
                                            {{ link.icon }}
                                        </Icon>
                                    </template>
                                    <span>{{ link.name }}</span>
                                </v-tooltip>
                                <span class="navigation__link-text">
                                    {{ link.name }}
                                </span>
                                <div v-if="link.unseenCount" class="navigation__link-unseen-indicator">
                                    {{ link.unseenCount }}
                                </div>
                            </a>
                            <button
                                v-else-if="link.id === 'feedback-modal' && link.groups.includes(group)"
                                :key="link.id"
                                type="button"
                                class="navigation__link"
                                @click.prevent="$store.commit('ui/OPEN_MODAL', 'help-modal')"
                            >
                                <v-tooltip right color="#000" :disabled="width <= 899 || width > 1270">
                                    <template v-slot:activator="{ on, attrs }">
                                        <Icon class="navigation__link-icon" v-bind="attrs" v-on="on">
                                            {{ link.icon }}
                                        </Icon>
                                    </template>
                                    <span>{{ link.name }}</span>
                                </v-tooltip>
                                <span class="navigation__link-text">{{ link.name }}</span>
                            </button>
                        </template>

                    </div>
                </nav>
                <a
                    :href="`/users/${authUserID}`"
                    class="navigation__profile-wrap"
                    @click.prevent="toProfile"
                >
                    <div class="navigation__profile-image">
                        <img :src="authUser.avatar ? authUser.avatar : require('@/assets/img/icon/avatar2.svg')" alt="Фото профиля">
                    </div>
                    <div class="navigation__profile-info">
                        <div class="navigation__profile-name">
                            {{ username && username !== ' ' ? username : 'Суперадмин' }}
                        </div>
                        <div v-if="authUserRole" class="navigation__profile-role">{{ authUserRole }}</div>
                    </div>
                    <!-- <button type="button" class="navigation__profile-btn" @click.stop.prevent>
                        <v-icon color="#fff">mdi-dots-vertical</v-icon>
                    </button> -->
                </a>
            </div>
        </div>
    </aside>
</template>

<script>
import { mapGetters } from 'vuex';

import { getNotificationsCount } from '@/api/notifications';

import { debounce } from '@/utils/functions';

import ButtonTimeTracking from '@/components/buttons/ButtonTimeTracking';

export default {
    name: 'TheNavigation',
    components: {
        ButtonTimeTracking
    },
    data() {
        return {
            isLoad: false,
            menu: [
                {
                    id: 'main',
                    name: 'Основные',
                    groups: [
                        'florist',
                        'managers',
                        'supervisor',
                        'courier',
                        'administrators',
                        'superadministrators'
                    ],
                    links: [
                        {
                            id: 'user-notifications',
                            to: '/user-notifications',
                            name: 'Уведомления',
                            groups: [
                                'managers',
                                'courier',
                                'florist',
                                'administrators',
                                'supervisor',
                            ],
                            icon: 'bell',
                            unseenCount: 0
                        },
                        {
                            id: 'orders',
                            to: '/orders',
                            name: 'Заказы',
                            groups: [
                                'managers',
                                'courier',
                                'florist',
                                'supervisor',
                                'administrators',
                            ],
                            icon: 'shopping-cart',
                            unseenCount: 0
                        },
                        {
                            id: 'chat',
                            to: '/chat',
                            name: 'Чаты',
                            groups: [
                                'managers',
                                'supervisor',
                                'administrators',
                            ],
                            icon: 'message-circle',
                            unseenCount: 0
                        },
                        {
                            id: 'goods',
                            to: '/goods',
                            name: 'Товары',
                            groups: [
                                'florist',
                                'managers',
                                'supervisor',
                                'administrators',
                                'superadministrators'
                            ],
                            icon: 'grid'
                        },
                        {
                            id: 'clients',
                            to: '/clients',
                            name: 'Клиенты',
                            groups: [
                                'administrators',
                                'supervisor'
                            ],
                            icon: 'users'
                        },
                        {
                            id: 'statistics',
                            to: '/statistics',
                            name: 'Статистика',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'bar-chart'
                        },
                    ],
                },
                {
                    id: 'stock',
                    name: 'Склад',
                    groups: [
                        'florist',
                        'managers',
                        'supervisor',
                        'administrators',
                        'courier'
                    ],
                    links: [
                        {
                            id: 'store',
                            to: '/storeroom/store',
                            name: 'Остатки',
                            groups: [
                                'managers',
                                'supervisor',
                                'administrators',
                            ],
                            icon: 'list-ul'
                        },
                        {
                            id: 'deliveries',
                            to: '/storeroom/deliveries',
                            name: 'Поставки',
                            groups: [
                                'florist',
                                'managers',
                                'supervisor',
                                'administrators',
                            ],
                            icon: 'archive-tray',
                            unseenCount: 0
                        },
                        {
                            id: 'offs',
                            to: '/storeroom/offs',
                            name: 'Списания',
                            groups: [
                                'florist',
                                'supervisor',
                                'administrators',
                            ],
                            icon: 'trash',
                            unseenCount: 0
                        },
                        {
                            id: 'movement',
                            to: '/storeroom/movement',
                            name: 'Перемещения',
                            groups: [
                                'florist',
                                'managers',
                                'supervisor',
                                'courier',
                                'administrators',
                            ],
                            icon: 'git-diff',
                            unseenCount: 0
                        },
                        {
                            id: 'inventories',
                            to: '/storeroom/inventories',
                            name: 'Инвентаризации',
                            groups: [
                                'supervisor',
                                'administrators',
                            ],
                            icon: 'clipboard-text',
                            unseenCount: 0
                        },
                    ],
                },
                {
                    id: 'finances',
                    name: 'Финансы',
                    groups: [
                        'supervisor'
                    ],
                    links: [
                        // {
                        //     id: 'bills',
                        //     to: '/finances/bills',
                        //     name: 'Доп счета',
                        //     groups: [
                        //         'managers',
                        //         'supervisor'
                        //     ]
                        // },
                        // {
                        //     id: 'notifications',
                        //     to: '/notifications',
                        //     name: 'Уведомить',
                        //     groups: [
                        //         'supervisor'
                        //     ],
                        //     icon: 'mdi-email'
                        // },
                        {
                            id: 'shops',
                            to: '/shops',
                            name: 'Магазины',
                            groups: [
                                'supervisor',
                            ],
                            icon: 'store',
                            unseenCount: 0
                        },
                        {
                            id: 'motivations',
                            to: '/motivations',
                            name: 'Мотивации',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'fire'
                        },
                        {
                            id: 'returns',
                            to: '/orders-returns',
                            name: 'Возвраты',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'arrow-back'
                        },
                        {
                            id: 'salarys-changes',
                            to: '/salarys-changes',
                            name: 'Премии/штрафы',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'medal'
                        },
                        {
                            id: 'salarys',
                            to: '/salarys',
                            name: 'Зарплаты',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'rub'
                        },
                        {
                            id: 'users',
                            to: '/users',
                            name: 'Сотрудники',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'users-three'
                        },
                        // {
                        //     id: 'encashments',
                        //     to: '/finances/encashments',
                        //     name: 'Инкассация',
                        //     groups: [
                        //         'supervisor',
                        //         'florist'
                        //     ],
                        //     icon: 'mdi-cash-lock-open'
                        // },
                        // {
                        //     id: 'payments',
                        //     to: '/finances/payments',
                        //     name: 'Типы оплат',
                        //     groups: [
                        //         'superadministrators'
                        //     ],
                        //     icon: 'mdi-credit-card-outline'
                        // },
                    ],
                },
                {
                    id: 'feedback',
                    name: 'Обратная связь',
                    groups: [
                        'managers',
                        'supervisor',
                        'administrators',
                    ],
                    links: [
                        {
                            id: 'reviews',
                            to: '/reviews',
                            name: 'Отзывы',
                            groups: [
                                'managers',
                                'supervisor',
                                'administrators'
                            ],
                            icon: 'star'
                        },
                        {
                            id: 'wishes',
                            to: '/wishes-for-products',
                            name: 'Пожелания',
                            groups: [
                                'managers',
                                'supervisor',
                                'administrators',
                            ],
                            icon: 'wish-list'
                        },
                    ],
                },
                {
                    id: 'clientsSection',
                    name: '',
                    groups: [
                        'supervisor'
                    ],
                    links: [
                        {
                            id: 'discount',
                            to: '/discounts',
                            name: 'Скидки',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'sale'
                        },
                        {
                            id: 'promocodes',
                            to: '/promocodes',
                            name: 'Промокоды',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'promocode'
                        },
                        {
                            id: 'certificates',
                            to: '/certificates',
                            name: 'Сертификаты',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'party'
                        },
                        {
                            id: 'mailings',
                            to: '/mailings',
                            name: 'Рассылки',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'mailing'
                        },
                        {
                            id: 'integrations',
                            to: '/integrations',
                            name: 'Интеграции',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'link'
                        },
                    ],
                },
                {
                    id: 'flowerSubscriptionSection',
                    name: 'Цветочная подписка',
                    groups: [
                        'managers',
                        'supervisor'
                    ],
                    links: [
                        {
                            id: 'flower-subscriptions',
                            to: '/flower-subscriptions',
                            name: 'Подписки',
                            groups: [
                                'managers',
                                'supervisor'
                            ],
                            icon: 'flower'
                        },
                        {
                            id: 'flower-subscription-settings',
                            to: '/flower-subscription-settings',
                            name: 'Настройки',
                            groups: [
                                'managers',
                                'supervisor'
                            ],
                            icon: 'sliders'
                        },
                    ]
                },
                {
                    id: 'other',
                    name: '',
                    groups: [
                        'florist',
                        'courier',
                        'managers',
                        'administrators',
                        'supervisor',
                        'superadministrators',
                    ],
                    links: [
                        {
                            id: 'franchise',
                            to: JSON.parse(localStorage.getItem('user')).groups[0] === 'superadministrators' ? '/business' : `/business/${JSON.parse(localStorage.getItem("user")).site}`,
                            name: JSON.parse(localStorage.getItem('user')).groups[0] === 'superadministrators' ? 'Клиенты' : 'Настройки',
                            groups: [
                                'superadministrators',
                                'supervisor'
                            ],
                            icon: 'settings'
                        },
                        {
                            id: 'subscription',
                            to: '/subscription',
                            name: 'Подписки',
                            groups: [
                                'supervisor',
                            ],
                            icon: 'crown-simple'
                        },
                        {
                            id: 'static-pages',
                            to: '/static-pages',
                            name: 'Страницы',
                            groups: [
                                'supervisor'
                            ],
                            icon: 'file-code'
                        },
                        {
                            id: 'help',
                            to: '/help',
                            name: 'Помощь',
                            groups: [
                                'courier',
                                'florist',
                                'managers',
                                'supervisor',
                                'administrators',
                            ],
                            icon: 'help-circle'
                        },
                        {
                            id: 'subscriptions',
                            to: '/subscriptions',
                            name: 'Подписки',
                            groups: [
                                'superadministrators',
                            ],
                            icon: 'crown-simple'
                        },
                        {
                            id: 'catalogs',
                            to: '/catalogs',
                            name: 'Каталоги',
                            groups: [
                                'superadministrators',
                            ],
                            icon: 'list-ul'
                        },
                        {
                            id: 'feedback-modal',
                            name: 'Написать в ТП',
                            groups: [
                                'florist',
                                'managers',
                                'supervisor',
                                'administrators',
                            ],
                            icon: 'envelope-simple'
                        },
                    ],
                },
            ],
            activeCategory: null,
        };
    },
    computed: {
        ...mapGetters({
            username: 'auth/username',
            width: 'ui/width',
            groups: 'users/groups',
            authUser: 'auth/user',
            chatUnreadCount: 'chat/unread',
            notificationsUnseenCount: 'notifications/notSeenMessages',
            currentPage: 'ui/page',
            franchiseTariff: 'franchises/franchiseTariff',
            stockIndications: 'stock/stockIndications',
            allUnseenMessages: 'chat/allUnseenMessages',
            isOpenedNavigation:'ui/isOpenSidebar'
        }),
        authUserID() {
            return JSON.parse(localStorage.getItem('user')).id || '';
        },
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        tariff() {
            return JSON.parse(localStorage.getItem('tariff'));
        },
        authUserRole() {
            if (this.authUser && this.authUser.groups) {
                let find = this.groups.find((item) => item.slug === this.authUser.groups[0])
                if (find) {
                    return find.name
                }
            }
            return '';
        },
    },
    watch: {
        franchiseTariff() {
            this.changeMenu();
        },
        chatUnreadCount(value) {
            let mainGroup = this.menu.find(item => item.id === 'main');
            if (mainGroup) {
                let chatLink = mainGroup.links.find(item => item.id === 'chat');
                if (chatLink) {
                    chatLink.unseenCount = value;
                }
            }
        },
        notificationsUnseenCount(value) {
            let mainGroup = this.menu.find(item => item.id === 'main');
            if (mainGroup) {
                let notificationsLink = mainGroup.links.find(item => item.id === 'user-notifications');
                if (notificationsLink) {
                    notificationsLink.unseenCount = value;
                }
            }
        }
    },
    async mounted() {
        this.$store.commit('ui/SET_PAGE', this.$route.name);
        this.changeMenu();
        const res = await getNotificationsCount()
        this.$store.commit('notifications/SET_NOT_SEEN_COUNT', res.length);
    },
    methods: {
        changeMenu() {
            let interval = setInterval(() => {
                if (this.franchiseTariff.tariff && this.franchiseTariff.tariff.length) {
                    clearInterval(interval);
                    const userGroup = window.localStorage.group;
                    if (this.tariff.tariff) {
                        this.menu = this.menu.filter((item) => item.groups.includes(userGroup) && this.tariff.tariff.includes(item.id));
                    }
                    this.setUnseenCount();
                    this.isLoad = true;
                }
            }, 200);
        },
        notificationFunc() {
            if (this.$route.name === 'user-notifications') {
                this.$store.dispatch('notifications/GET_USER_NOTIFICATIONS');
            } else {
                this.$router.push({ name: 'user-notifications' });
            }
            this.$store.commit('ui/CLOSE_SIDEBAR');
            this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
        },
        toProfile() {
            if (this.$route.name !== 'user' || this.$route.params.ID != this.authUserID) {
                this.$router.push(`/users/${this.authUserID}`);
            } else {
                this.$store.commit('ui/SET_REMOUNT_PAGE_KEY');
            }
            this.$store.commit('ui/CLOSE_SIDEBAR');
            this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
        },
        goToNavLink(link) {
            if (link.to !== this.$route.path) {
                this.$router.push(link.to);
            } else {
                this.$store.commit('ui/SET_REMOUNT_PAGE_KEY');
            }
            this.$store.commit('ui/CLOSE_SIDEBAR');
            this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
        },
        scrollMenu(event) {
            if (this.width <= 1024) {
                event.target.classList.add('scrolling');
                this.hideScroll(event);
            }
        },
        hideScroll: debounce(function (event) {
            event.target.classList.remove('scrolling');
        }, 500),
        setUnseenCount() {
            if (Object.keys(this.stockIndications).length) {
                let stock = this.menu.find(item => item.id === 'stock');
                if (stock) {
                    stock.links.forEach((item) => {
                        if (item.id === 'deliveries') item.unseenCount = this.stockIndications.count_of_unseen_arrival;
                        if (item.id === 'movement') item.unseenCount = this.stockIndications.count_of_unseen_transfers;
                        if (item.id === 'offs') item.unseenCount = this.stockIndications.count_of_unseen_writeoffs;
                        if (item.id === 'inventories') item.unseenCount = this.stockIndications.count_of_unseen_inventory;
                    });
                }
            }
            let other = this.menu.find(item => item.id === 'other');
            if (other) {
                other.links.forEach((item) => {
                    if (item.id === 'shops') item.unseenCount = this.franchiseTariff.count_of_unseen_encasement;
                });
            }
        },
        reloadPage() {
            document.location.reload();
        },
    },
};
</script>

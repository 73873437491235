<template>
    <div
        ref="search"
        class="page-search"
        :class="{'fixed': isFixed, 'is-visible': isVisibleMobile}"
    >
        <InputCustom
            ref="searchInput"
            :value="value"
            label="Поиск"
            :placeholder="placeholder"
            clearable
            without-preloader
            @input="$emit('input', $event)" 
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Search',
    props: {
        value: { type: [String, Number], default: '' },
        placeholder: { type: String, default: '' },
        isVisibleMobile: { type: Boolean, default: false },
        observerMargin: { type: [String, Number], default: 1 }
    },
    data() {
        return {
            isFixed: false,
            observer: null
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
    },
    watch: {
        async isVisibleMobile(val) {
            if (val) {
                await this.$nextTick();
                this.$refs.searchInput.focusInput();
            }
        }
    },
    mounted() {
        this.observer = new IntersectionObserver(this.checkScrollPosition, {
            rootMargin: `-${this.observerMargin}px 0px 0px 0px`,
            threshold: 1.0
        });
        this.observer.observe(this.$refs.search)
    },
    methods: {
        checkScrollPosition(entries) {
            if (entries[0] && !entries[0].isIntersecting) {
                this.isFixed = true
            } else {
                this.isFixed = false;
            }
        }
    }
}
</script>

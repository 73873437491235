<template>
    <div class="horizonatal-picker__wrap" :class="{'horizonatal-picker__wrap--disabled': disabled}">
        <div
            v-for="item in items"
            :key="item[inputValue]"
            class="horizonatal-picker__item"
            :class="{'active': value === item[inputValue]}"
            @click="!disabled ? $emit('input', item[inputValue]) : false"
        >
            {{ item[inputText] }}
        </div>
        <div
            v-if="value !== null && value !== undefined && items.find(i => i[inputValue] === value)"
            class="horizonatal-picker__value"
            :style="`
                width: calc(100% / ${items.length});
                transform: translateX(${100 * activeElIndex}%)`
            "
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'HorizontalPicker',
    props: {
        value: { type: [Number, Boolean, String], default: 0 },
        items: { type: Array, default: () => [] },
        inputValue: { type: String, default: 'value' },
        inputText: { type: String, default: 'name' },
        disabled: { type: [Boolean, Number, String], defaul: false }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        activeElIndex() {
            return this.items.findIndex(i => i[this.inputValue] === this.value)
        }
    },
}
</script>
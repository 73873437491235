<template>
    <div class="universal-modal__component">
        <UniversalModalHeader
            :title="usersList.length ? `${usersList.length} шт.` : 'Выберите пользователя'"
            @click:right="$emit('closeModal')"
        >
            <template #rightButtons>
                <button
                    v-if="usersList.length"
                    type="button"
                    class="universal-modal__header-right-btn"
                    @click.prevent="clearUsersList"
                >
                    <Icon>file-minus</Icon>
                </button>
                <button
                    v-if="width <= 699"
                    type="button"
                    class="universal-modal__header-right-btn"
                    @click.prevent="isSearchShow = !isSearchShow"
                >
                    <Icon>search</Icon>
                </button>
            </template>
        </UniversalModalHeader>
        <div
            ref="scrolledBlock"
            class="universal-modal__content"
            v-infinite-scroll="loadMore" infinite-scroll-distance="300" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
        >
            <Search
                :value="filters.search"
                class="mb-0"
                :is-visible-mobile="isSearchShow"
                observer-margin="45"
                @input="$store.commit('users/SET_FILTER', { field: 'search', value: $event}), getFilteredUsers()"
            />

            <FastFilters :filters="fastFilters" @change="toogleFastFilter" />

            <div v-show="!preloader" class="listing-table__wrap">
                <div v-if="onlyPersonalUsers && onlyPersonalUsers.length" class="listing-table">
                    <div class="listing-table__row listing-table__row--head">
                        <div class="listing-table__col" style="width: 33%">Имя</div>
                        <div class="listing-table__col" style="width: 33%">Роль</div>
                        <div class="listing-table__col" style="width: 33%">Посл.авториз</div>
                    </div>
                    <a
                        v-for="item in onlyPersonalUsers"
                        :key="item.id"
                        class="listing-table__row"
                        :class="{ active: selected(item.id) }"
                        @click.prevent="$store.dispatch('notifications/EVENT_HANDLER', item)"
                    >
                        <div
                            class="listing-table__col"
                            :style="width > 1024 ? 'width: 33%' : ''"
                        >
                            {{ item.last_name }} {{ item.first_name }}
                        </div>

                        <div v-if="width <= 1024" class=" listing-table__col listing-table__col--mobile-bordered" />

                        <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                            <div class="listing-table__col-label">Роль</div>
                            {{ printRole(item) }}
                        </div>
                        <div
                            class="listing-table__col"
                            :style="width > 1024 ? 'width: calc((100% - 80px) / 4)' : ''"
                        >
                            <div class="listing-table__col-label">Посл. авторизация</div>
                            {{ item.last_login ? dateFormat(item.last_login, 'hours-ru') : '&mdash;' }}
                        </div>
                    </a>
                    <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                        <div class="listing-table__col">
                            <Spinner />
                        </div>
                    </div>
                </div>
                <empty-table v-show="onlyPersonalUsers && !onlyPersonalUsers.length" />
            </div>
            <ContentPreloader v-show="preloader" type="table" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { debounce } from '@/utils/functions';
import { date } from '@/utils/format';

import Spinner from '@/components/app/Spinner';
import FastFilters from '@/components/app/FastFilters';

export default {
    name: 'GoodsModal',
    components: { FastFilters, Spinner },
    data() {
        return {
            isSearchShow: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            usersList: 'notifications/users_list',
            users: 'users/users',
            groups: 'users/groups',
            selected: 'notifications/selected',
            user: 'auth/user',
            busy: 'users/busy',
            next: 'users/next',
            preloader: 'ui/preloader',
            filters: 'users/filters',
            fastFilters: 'users/fastFilter',
        }),
        onlyPersonalUsers() {
            if (!Object.keys(this.users).length) return [];
            return this.users.filter((user) => !user.groups.includes('customers'));
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
        this.$store.commit('notifications/CLEAR_USERS_LIST');
    },
    async mounted() {
        await this.$store.dispatch('users/GET_USERS');
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    methods: {
        getFilteredUsers: debounce(function (field, value) {
            this.filterUsers(field, value);
        }, 1000),
        async filterUsers(field, value) {
            this.$store.commit('ui/SET_PRELOADER', true);
            await this.$store.dispatch('users/GET_USERS', { field, value });
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        async toogleFastFilter(event) {
            this.$store.commit('ui/SET_PRELOADER', true);
            this.$store.commit('users/CLEAR_FILTERS_STATE');
            const currentActiveFiltert = this.fastFilters.find((item) => item.active);

            if (currentActiveFiltert && currentActiveFiltert.name === event.name) {
                this.$store.commit('users/CHANGE_FAST_FILTER');
                await this.$store.dispatch('users/GET_USERS');
            } else {
                this.$store.commit('users/CHANGE_FAST_FILTER', { name: event.name, active: true });
                await this.$store.dispatch('users/GET_USERS', { field: 'group', value: event.slug });
            }
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        loadMore() {
            this.$store.dispatch('users/GET_NEXT_PAGE');
        },
        printRole(user) {
            const group = user.groups[0];
            if (this.groups.find((g) => g.slug === group)) return this.groups.find((g) => g.slug === group).name;
            return 'Суперадминистратор';
        },
        dateFormat(value, type) {
            return date(new Date(value), type);
        },
        clearUsersList() {
            this.$store.commit('notifications/CLEAR_USERS_LIST');
        },
    }
};
</script>

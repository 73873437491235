/* eslint-disable */
import { getPages, getNextPagePages } from '@/api/django';

const state = {
    pages: [],
    next: null,
    busy: false,
    offset: 20
};

const mutations = {
    SET_PAGES(state, pages) {
        state.pages = pages.results;
        state.next = pages.next;
    },
    SET_NEXT_PAGES(state, data) {
        state.pages = state.pages.concat(data.results);
        state.next = data.next;
        state.offset += 20;
    }
};

const actions = {
    async GET_PAGES({ commit }) {
        try {
            const data = await getPages();
            commit('SET_PAGES', data);
        } catch (error) {
            console.log(error);
        }
    },
    async LOAD_MORE({ state, commit }) {
        if (!!state.next) {
            try {
                state.busy = true;
                const data = await getNextPagePages(state.offset);
                commit('SET_NEXT_PAGES', data);
                state.busy = false;
            } catch (error) {
                console.log(error);
            }
        }
    },
};

const getters = {
    pages: state => state.pages,
    busy: state => state.busy,
    next: state => state.next,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

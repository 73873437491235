/* eslint-disable */
import {
    getShopData,
    createShop,
    editShop,
    fetchShops,
    removeShop,
    getShopBalance,
    getNextPageShops, fetchShopsTypes
} from '@/api/django';

const state = {
    shop: {},
    shops: {},
    next: '',
    busy: false,
    withBalance: false,
    shopsTypes: []
};

const mutations = {
    SET_SHOP(state, shop) {
        state.shop = shop;
    },
    SET_SHOPS(state, shops) {
        state.shops = shops;
    },
    SET_NEXT_PAGE(state, { results, next }) {
        state.shops.results.push(...results);
        state.next = next;
        state.offset = next !== null ? +next.split('&').find(string => string.trim().startsWith('offset=')).split('=').pop() : 0;
    },
    SET_WITH_BALANCE(state, withBalance){
        state.withBalance = withBalance;
    },
    SET_SHOPS_TYPES(state, types) {
        state.shopsTypes = types;
    }
};

const actions = {
    async GET_SHOPS({ state, commit }, with_balance = false) {
        commit('SET_WITH_BALANCE', false);
        let data = await fetchShops();

        if (with_balance){
            commit('SET_WITH_BALANCE', true);
            const balance = await getShopBalance()
            balance.forEach(shop => {
                data.results.forEach((s, index) => {
                    if (s['id'] == shop['id']){
                        data.results[index].balance = shop.balance;
                    }
                });
            });

        }

        const interval = setInterval(() => {
            if (state.shopsTypes.length) {
                clearInterval(interval);
                data.results = data.results.map(item => ({
                    ...item,
                    type_icon: state.shopsTypes.find(i => item.type === i.id).icon_path
                }))
            }
        }, 100)


        commit('SET_SHOPS', data);
        return data;
    },
    async GET_SHOP_DATA({ commit }, { shopID, with_balance=false }) {
        const data = await getShopData(shopID);

        if (with_balance){
            const balance = await getShopBalance()
            balance.forEach((shop) => {
               if (data.id == shop.id){
                   data.balance = shop.balance;
               }
            });
        }

        commit('SET_SHOP', data);
        return data;
    },
    async CREATE_SHOP({ commit }, data) {
        await createShop(data);
    },
    async EDIT_SHOP({ commit }, { shopID, data }) {
        await editShop(shopID, data);
    },
    async REMOVE_SHOP({ commit }, shopID) {
        await removeShop(shopID);
    },
    async GET_NEXT_PAGE ({ commit, getters }) {
        if (getters['next']) {
            try {
                state.busy = true;
                commit('SET_NEXT_PAGE', await getNextPageShops(20, getters['offset']));
                state.busy = false;
            } catch (error) {
                console.log(error);
            }
        }
    },
    async GET_SHOPS_TYPES({ commit }) {
        const res = await fetchShopsTypes();
        commit('SET_SHOPS_TYPES', res);
    }
};

const getters = {
    shop: state => state.shop,
    shops: state => state.shops,
    withBalance: state => state.withBalance,
    next: state => !!state.next_page,
    busy: state => state.busy,
    shopsTypes: state => state.shopsTypes
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.id ? category.name : 'Новая категория'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only"
                @click:left="$router.push('/tags')"
                @click:save="saveItem"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="page__info-group">
                            <Row>
                                <Col>
                                    <InputCustom v-model="category.name" label="Название" />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div class="page-small-column">
                        <div class="checkbox-group">
                            <div class="checkbox-group__item">
                                <Checkbox
                                    :value="category.is_active"
                                    label="Активность"
                                    @change="category.is_active = !category.is_active"
                                />
                            </div>
                            <div class="checkbox-group__item">
                                <Checkbox
                                    :value="category.type === 'color'"
                                    label="Тип: цвет"
                                    @change="category.type = category.type === 'color' ? 'text' : 'color'"
                                />
                            </div>
                        </div>
                        <Button
                            v-if="$route.params.id"
                            class="mt-4"
                            block compact text
                            icon-left="file-minus"
                            @click.prevent="showConfirmDeleteCategory = true"
                        >
                            Удалить категорию
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmDeleteCategory"
            :title="`Удалить категорию ${ category.name }?`"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteCategory = false"
            @mainBtnClick="deleteCategory"
        />
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import { getTag, createTag, editTag, deleteTag } from '@/api/django';

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'TheTagsCategoiesCreate',
    metaInfo() {
        return { title: this.$route.params.id ? `Категория ${this.category.name}` : 'Новая категория' }
    },
    components: { MiniModal },
    data() {
        return {
            category: {
                is_active: true,
                name: '',
                type: 'text'
            },
            showConfirmDeleteCategory: false
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
            width: 'ui/width',
            preloader: 'ui/preloader',
            franchiseTariff: 'franchises/franchiseTariff',
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            if (this.$route.params.id) {
                this.category = await getTag(this.$route.params.id);
            }
        } catch (error) {
            console.log(error);
            this.$store.commit('ui/add_item_in_system_notifications', { message: 'При загрузке данных произошла ошибка', type: 'error' })
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        async saveItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (!this.category.name) {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Введите название', type: 'error' })
                    return;
                }

                this.$store.commit('ui/SET_PRELOADER', true);

                const data = { ...this.category }

                if (!this.$route.params.id) {
                    data.childrens = [];
                    data.site = this.franchiseTariff.site_id;

                    await createTag(data);
                } else {
                    await editTag(this.$route.params.id, data);
                }

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Категория успешно сохранена', type: 'success' })
                this.$router.push('/tags');
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка сохранения категории', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async deleteCategory() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await deleteTag(this.category.id);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Категория удалена', type: 'success' })
                this.$router.push('/tags');
            } catch (error) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка удаления категории', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
    }
};
</script>

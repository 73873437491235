<template>
    <transition name="fade">
        <div
            v-if="showSubAlert"
            class="subscription-notification"
            :class="{'alarm': franchiseTariff.count_days_until_end <= 3}"
            @click="$router.push('/subscription')"
        >
            <div class="subscription-notification__icon">
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16.5" cy="16.5" r="16.5" fill="#36B687" />
                    <path d="M27.0417 16.5L24.7034 13.8358L25.0292 10.3092L21.5696 9.52333L19.7584 6.47583L16.5 7.875L13.2417 6.47583L11.4305 9.52333L7.97087 10.2996L8.29671 13.8262L5.95837 16.5L8.29671 19.1642L7.97087 22.7004L11.4305 23.4862L13.2417 26.5337L16.5 25.125L19.7584 26.5242L21.5696 23.4767L25.0292 22.6908L24.7034 19.1642L27.0417 16.5ZM17.4584 21.2917H15.5417V19.375H17.4584V21.2917ZM17.4584 17.4583H15.5417V11.7083H17.4584V17.4583Z" fill="white" />
                </svg>
            </div>
            <div class="subscription-notification__title">
                {{ franchiseTariff.count_days_until_end ? 'Подписка истекает' : 'Подписка истекла' }}
            </div>
            <div class="subscription-notification__date">
                {{ endDate }}
            </div>
            <v-btn v-if="width > 480" class="ml-4" color="primary" depressed>Продлить</v-btn>
            <v-btn class="ml-a" icon @click.stop.prevent="close">
                <v-icon color="#96A5B9">
                    mdi-close
                </v-icon>
            </v-btn>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    name: 'TheSubscriptionNotification',
    data() {
        return {
            showSubAlert: false
        };
    },
    computed: {
        ...mapGetters({
            franchiseTariff: 'franchises/franchiseTariff',
            width: 'ui/width',
        }),
        endDate() {
            if (this.franchiseTariff && this.franchiseTariff.count_days_until_end) {
                return moment().add(this.franchiseTariff.count_days_until_end, 'days').format('DD.MM.YYYY');
            }
            return null;
        }
    },
    mounted() {
        let interval = setInterval(() => {
            if (Object.keys(this.franchiseTariff).length) {
                if (!this.$cookies.get('hideSubAlert') && (!this.franchiseTariff.count_days_until_end || (this.franchiseTariff.count_days_until_end && this.franchiseTariff.count_days_until_end <= 14))) {
                    this.showSubAlert = true;
                }
                clearInterval(interval);
            }
        }, 150);
    },
    methods: {
        close() {
            this.$cookies.set('hideSubAlert', true);
            this.showSubAlert = false;
        },
    }
};
</script>

<template>
    <UserDetail @set-user="user = $event" />
</template>

<script>
import UserDetail from "@/components/app/users/UserDetail";

export default {
    name: "TheClientsCreate",
    components: {
        UserDetail
    },
    metaInfo() {
        return { title: this.$route.params.ID ? `Клиент ${this.user.last_name} ${this.user.first_name}` : 'Новый клиент' }
    },
    data() {
        return {
            user: {}
        }
    },
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial
            v-if="!authUser.read_only && !!$route.params.salaryID && (!salaryData.issued_to_user || (salaryData.issued_to_user && statusChanged))"
            v-model="fab_open"
            class="btn__floating custom-speed-dial"
            close-on-click
            fixed
        >
            <template v-slot:activator>
                <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item @click="deleteSalary">
                        <v-list-item-icon class="mr-3">
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Удалить зарплату</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="$route.params.salaryID ? `Зарплата №${$route.params.salaryID}` : 'Отменить'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only &&
                    ((!$route.params.salaryID && users_list && users_list[0]) || ($route.params.salaryID && !salaryData.issued_to_user))"
                @click:left="$router.push({ name: 'salarys' })"
                @click:save="giveOutSalary"
            >
                <Button
                    v-if="$route.params.salaryID"
                    :class="{'mr-2': !authUser.read_only}"
                    text
                    compact
                    :icon="width <= 699"
                    icon-right="file-minus"
                    @click.prevent="exportFile"
                >
                    Скачать в xls
                </Button>
            </Header>
            <div class="page-container page-container--with-fab">
                <div class="page__info-group">
                    <Row>
                        <Col :cols="width > 1024 ? 6 : 12">
                            <InputCustom
                                :value="userName"
                                label="Кому"
                                readonly
                                :disabled="!!$route.params.salaryID"
                                @click="isUsersModalOpen = true"
                            />
                        </Col>
                        <template v-if="(!$route.params.salaryID && users_list && users_list[0]) || ($route.params.salaryID && user)">
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="menu1"
                                    offset-y
                                    attach
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            :value="dateRangeForamted"
                                            label="Период"
                                            :hint="lastSalary ? 'Дата последнего расчета: ' + momentForamtDate(lastSalary) : ''"
                                            :disabled="salaryData.issued_to_user"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker v-model="dateRange" no-title range first-day-of-week="1" />
                                </v-menu>
                            </Col>
                            <Col
                                v-if="($route.params.salaryID || users_list.length) && user.groups && user.groups[0] !== 'courier'"
                                :cols="width > 1024 ? 6 : 12"
                            >
                                <InputCustom
                                    :value="+user.salary_rate"
                                    label="Ставка"
                                    @input="user.salary_rate = $event, changeSalaryRate(user.salary_rate)"
                                />
                            </Col>
                            <Col cols="12">
                                <TextareaCustom
                                    :value="$route.params.salaryID && salaryData && salaryData.read_user ?
                                        salaryData.read_user.additional_info :
                                        users_list && users_list[0] ? users_list[0].additional_info : ''
                                    "
                                    label="Комментарий"
                                    :disabled="salaryData.issued_to_user"
                                />
                            </Col>
                        </template>
                    </Row>
                    <Row>
                        <Col v-if="$route.params.salaryID" :cols="width > 1024 ? 6 : 12">
                            <InputSelect
                                v-model="salaryData.issued_to_user"
                                :options="salaryStatuses"
                                label="Статус"
                                :disabled="salaryData.issued_to_user"
                                @input="statusChanged = true"
                            />
                        </Col>
                    </Row>
                    <ContentPreloader v-show="preloader" type="table" class="mt-4" />
                </div>

                <template v-if="($route.params.salaryID && user && user.groups) ||
                    (!$route.params.salaryID && users_list.length) && range.endDate && range.startDate"
                >
                    <div class="page__info-group">
                        <div class="price-list__wrap">
                            <div class="price-list__item">
                                <div class="price-list__item-label">Итого</div>
                                <div class="price-list__item-value">
                                    <span v-if="salaryForCourier" class="salary-final">
                                        {{
                                            formatNumber(
                                                summOrdersPay + summTransfersPay + +($route.params.salaryID ?
                                                    getSalaryAllow(true) + getSalaryAllow(false) : +salary.bonus + +salary.penalty)
                                            )
                                        }} ₽
                                    </span>
                                    <span v-else class="salary-final">
                                        {{
                                            formatNumber(
                                                ($route.params.salaryID ?
                                                    getSalaryAllow(true) + getSalaryAllow(false) :
                                                    +salary.bonus + +salary.penalty) + (salaryData && +salaryData.amount ? +salaryData.amount : summHoursPay)
                                            )
                                        }} ₽
                                    </span>
                                </div>
                            </div>
                            <div class="price-list__item">
                                <div class="price-list__item-label">
                                    {{ salaryForCourier ? 'Кол-во заказов' : 'Кол-во часов' }}
                                </div>
                                <div class="price-list__item-value">
                                    <span v-if="salaryForCourier">
                                        {{ courierOrders.length }}
                                    </span>
                                    <span v-else>
                                        {{ $route.params.salaryID ? salaryData.hour_worked : salary.workingHours }}
                                    </span>
                                </div>
                            </div>
                            <div class="price-list__item">
                                <div class="price-list__item-label">
                                    {{ salaryForCourier ? 'Начисления по заказам' : 'Учёт рабочего времени' }}
                                </div>
                                <div class="price-list__item-value">
                                    <span v-if="salaryForCourier">
                                        {{ formatNumber(summOrdersPay) }} ₽
                                    </span>
                                    <span v-else>
                                        {{ formatNumber(salaryData && +salaryData.amount ? +salaryData.amount : summHoursPay) }} ₽
                                    </span>
                                </div>
                            </div>
                            <div v-if="salaryForCourier && courierTransfers.length" class="price-list__item">
                                <div class="price-list__item-label">
                                    Количество перемещений
                                </div>
                                <div class="price-list__item-value">
                                    {{ courierTransfers.length }}
                                </div>
                            </div>
                            <div v-if="salaryForCourier && courierTransfers.length" class="price-list__item">
                                <div class="price-list__item-label">
                                    Начисления по перемещениям
                                </div>
                                <div class="price-list__item-value">
                                    {{ formatNumber(summTransfersPay) }} ₽
                                </div>
                            </div>
                            <div class="price-list__item">
                                <div class="price-list__item-label">
                                    Премии и штрафы
                                </div>
                                <div class="price-list__item-value">
                                    {{
                                        formatNumber($route.params.salaryID ? getSalaryAllow(true) + getSalaryAllow(false) : +salary.bonus + +salary.penalty)
                                    }} ₽
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="!salaryForCourier && workingTime.length || additional_ids.length" class="page__info-group">
                        <h2 class="page__info-group-title">
                            Учёт рабочего времени
                        </h2>
                        <div class="listing-table__wrap">
                            <div class="listing-table">
                                <div class="listing-table__row listing-table__row--head">
                                    <div class="listing-table__col" style="width: 20%">Дата</div>
                                    <div class="listing-table__col" style="width: 20%">Начало</div>
                                    <div class="listing-table__col" style="width: 20%">Конец</div>
                                    <div class="listing-table__col" style="width: 20%">Продолжительность</div>
                                    <div class="listing-table__col" style="width: 20%">Сумма</div>
                                </div>
                                <div v-for="(item, index) in workingTime" :key="item.id" class="listing-table__row">
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                        <div class="listing-table__col-label">Дата</div>
                                        {{ formatDate(item.date, 'ru') }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                        <div class="listing-table__col-label">Начало</div>
                                        {{ setTimeWithTimezone(item, 'start') }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                        <div class="listing-table__col-label">Конец</div>
                                        {{ setTimeWithTimezone(item, 'end') }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                        <div class="listing-table__col-label">Продолжительность</div>
                                        <v-menu
                                            :return-value.sync="item.duration"
                                            ref="menu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <InputCustom
                                                    v-model="item.duration"
                                                    style="width: 95px"
                                                    v-on="on"
                                                />
                                            </template>
                                            <v-time-picker
                                                v-model="item.duration"
                                                format="24hr"
                                                full-width
                                                scrollable
                                                use-seconds
                                                @click:second="$refs.menu[index].save(item.duration)"
                                                @change="recalculateSalary"
                                            />
                                        </v-menu>
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                        <div class="listing-table__col-label">Сумма</div>
                                        {{
                                            formatNumber(Math.ceil(
                                                (+item.duration.split(':')[0] + (+item.duration.split(':')[1] / 60) + (+item.duration.split(':')[2] / 3600)) * +user.salary_rate
                                            ))
                                        }} ₽
                                    </div>
                                </div>
                                <div class="listing-table__row">
                                    <template v-if="width > 1024">
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''" />
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''" />
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''" />
                                    </template>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                        <div class="listing-table__col-label">Сумма часов</div>
                                        {{ $route.params.salaryID ? salaryData.hour_worked : salary.workingHours }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                        <div class="listing-table__col-label">Общая выплата</div>
                                        {{ formatNumber(salaryData && +salaryData.amount ? +salaryData.amount : summHoursPay) }} ₽
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="salaryForCourier && (courierOrders.length || additional_ids.length)" class="page__info-group">
                        <h2 class="page__info-group-title">Настройка ставок для доставки</h2>
                        <Row>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputSelect
                                    v-model="paymentInCityDay.type"
                                    :options="paymentTypeOptions"
                                    label="Тип выплаты в городе днем"
                                    @input="setDefaultOrdersPayment"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="paymentInCityDay.value"
                                    label="Величина выплаты днем"
                                    @input="setDefaultOrdersPayment"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputSelect
                                    v-model="paymentInCityNight.type"
                                    :options="paymentTypeOptions"
                                    label="Тип выплаты в городе ночью"
                                    @input="setDefaultOrdersPayment"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="paymentInCityNight.value"
                                    label="Величина выплаты ночью"
                                    @input="setDefaultOrdersPayment"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputSelect
                                    v-model="paymentOutCity.type"
                                    :options="paymentTypeOptions"
                                    label="Тип выплаты за городом"
                                    @input="setDefaultOrdersPayment"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="paymentOutCity.value"
                                    label="Величина выплаты"
                                    @input="setDefaultOrdersPayment"
                                />
                            </Col>
                        </Row>
                    </div>
                    <div v-if="salaryForCourier && (courierOrders.length || additional_ids.length)" class="page__info-group">
                        <h2 class="page__info-group-title">Заказы</h2>
                        <div class="listing-table__wrap">
                            <div class="listing-table">
                                <div class="listing-table__row listing-table__row--head">
                                    <div class="listing-table__col" style="width: calc(100% / 5)">Номер заказа</div>
                                    <div class="listing-table__col" style="width: calc(100% / 5)">Дата доставки</div>
                                    <div class="listing-table__col" style="width: calc(100% / 5)">Стоимость</div>
                                    <div class="listing-table__col" style="width: calc(100% / 5)">Доставка</div>
                                    <div class="listing-table__col" style="width: calc(100% / 5)">Выплата</div>
                                </div>
                                <div v-for="item in courierOrders" :key="item.id" class="listing-table__row" @click="pushToOrder(item.id)">
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''">
                                        {{ item.id }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''">
                                        <div class="listing-table__col-label">Дата доставки</div>
                                        {{ formatDate(item.delivery_date, 'ru') }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''">
                                        <div class="listing-table__col-label">Стоимость</div>
                                        {{ Math.ceil(+item.final_cost) }} ₽
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''">
                                        <div class="listing-table__col-label">Доставка</div>
                                        {{ +item.delivery_cost }} ₽
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''" @click.stop>
                                        <div class="listing-table__col-label">Выплата</div>
                                        <InputCustom
                                            v-model.number="item.price"
                                            type="number"
                                            :style="width <= 1024 ? 'width: 80px' : ''"
                                            @input="setOrdersPay($event, item)"
                                        />
                                    </div>
                                </div>
                                <div class="listing-table__row">
                                    <template v-if="width > 1024">
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''" />
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''" />
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''" />
                                    </template>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''">
                                        <div class="listing-table__col-label">Сумма заказов</div>
                                        {{ formatNumber(courierOrders.reduce((sum, item) => sum + +item.delivery_cost, 0)) }} ₽
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''">
                                        <div class="listing-table__col-label">Сумма выплат</div>
                                        {{ formatNumber(summOrdersPay) }} ₽
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="user.id && group === 'courier' && courierTransfers.length" class="page__info-group">
                        <h2 class="page__info-group-title">Перемещения</h2>
                        <div class="listing-table__wrap">
                            <div class="listing-table">
                                <div class="listing-table__row listing-table__row--head">
                                    <div class="listing-table__col" style="width: calc(100% / 5)">Склад-отправитель</div>
                                    <div class="listing-table__col" style="width: calc(100% / 5)">Склад-получатель</div>
                                    <div class="listing-table__col" style="width: calc(100% / 5)">Отправка</div>
                                    <div class="listing-table__col" style="width: calc(100% / 5)">Получение</div>
                                    <div class="listing-table__col" style="width: calc(100% / 5)">Выплата</div>
                                </div>
                                <div v-for="item in courierTransfers" :key="item.id" class="listing-table__row">
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 6)' : ''">
                                        <div class="listing-table__col-label">Склад-отправитель</div>
                                        {{ item.from_stock }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 6)' : ''">
                                        <div class="listing-table__col-label">Склад-получатель</div>
                                        {{ item.to_stock }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 6)' : ''">
                                        <div class="listing-table__col-label">Отправка</div>
                                        {{ item.date_sended ? formatDate(item.date_sended, 'ru') : '&mdash;' }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 6)' : ''">
                                        <div class="listing-table__col-label">Получение</div>
                                        {{ item.date_received ? formatDate(item.date_received, 'ru') : '&mdash;' }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 6)' : ''" @click.stop>
                                        <div class="listing-table__col-label">Выплата</div>
                                        <InputCustom
                                            v-model.number="item.price"
                                            type="number"
                                            :style="width <= 1024 ? 'width: 80px' : ''"
                                            @input="setTransfersPay($event, item)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="user.id && userAllowances.length" class="page__info-group">
                        <h2 class="page__info-group-title">
                            Премии / штрафы
                        </h2>
                        <div class="listing-table__wrap">
                            <div class="listing-table">
                                <div class="listing-table__row listing-table__row--head">
                                    <div class="listing-table__col" style="width: calc(100% / 4)">Дата</div>
                                    <div class="listing-table__col" style="width: calc(100% / 4)">Тип</div>
                                    <div class="listing-table__col" style="width: calc(100% / 4)">Название</div>
                                    <div class="listing-table__col" style="width: calc(100% / 4)">Сумма</div>
                                </div>
                                <div
                                    v-for="(change, index) in userAllowances"
                                    :key="index"
                                    class="listing-table__row"
                                    @click="pushToChanges(change.salary_change__id || change.id)"
                                >
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''">
                                        <div class="listing-table__col-label">Дата</div>
                                        {{ formatDate(change.salary_change__date_added || change.date_added, 'ru') }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''">
                                        <div class="listing-table__col-label">Тип</div>
                                        {{ change.salary_change__salary_choice || change.salary_choice === 'bonus' ? 'Премия' : 'Штраф' }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''">
                                        <div class="listing-table__col-label">Название</div>
                                        {{ change.salary_change__name || change.name  }}
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''">
                                        <div class="listing-table__col-label">Сумма</div>
                                        {{ formatNumber(+change.salary_change__amount || +change.amount) }} ₽
                                    </div>
                                </div>
                                <div class="listing-table__row">
                                    <template v-if="width > 1024">
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''" />
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''" />
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''" />
                                    </template>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 5)' : ''">
                                        <div class="listing-table__col-label">Итого</div>
                                        {{
                                            formatNumber(
                                                $route.params.salaryID ? getSalaryAllow(true) + getSalaryAllow(false) :
                                                +salary.bonus + +salary.penalty
                                            )
                                        }} ₽
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <UniversalModal v-model="isUsersModalOpen" size="full">
            <UsersModal @closeModal="isUsersModalOpen = false" />
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import {
    getCourierOrder,
    getLastSalary,
    calculateSalary,
    postSalary,
    deleteSalary,
    getUserAllowense,
    getSalaryById,
    updateUser,
    patchCourierOrders,
    patchSalary,
    exportSalaryToExcel
} from '@/api/django';

import { getTransfersForSalary, markTransferAsPaid } from '@/api/stock';
import { getTimesheet, patchUserChange } from '@/api/timetracking';

import { debounce } from '@/utils/functions';
import { date, numberFormat } from '@/utils/format';

import UsersModal from '@/components/app/users/modal/UsersModal';

let Duration = require("duration-js");

let timer = '';

export default {
    name: 'TheSalarysCreate',
    metaInfo() {
        return { title: this.$route.params.salaryID ? `Зарплата ${this.$route.params.salaryID}` : 'Новая зарплата' }
    },
    components: {
        UsersModal
    },
    data() {
        return {
            range: {
                startDate: moment().set({'hour': 0, 'minute': 0, 'second': 0}),
                endDate: moment().set({'hour': 0, 'minute': 0, 'second': 0}),
            },
            fab_open: false,
            startDate: new Date(),
            endTime: new Date(),
            salary: {
                date_salary_start: '',
                date_salary_end: '',
                workingHours: 0,
                amount: 0,
                workingSeconds: 0,
                bonus: 0,
                penalty: 0
            },
            salaryStatuses: [
                { id: false, name: 'Ожидает подтверждения' },
                { id: true, name: 'Выплачено' }
            ],
            userAllowances: [],
            courierOrders: [],
            workingTime: [],
            courierTransfers: [],
            paymentTypeOptions: [
                { id: 0, name: 'В рублях' },
                { id: 1, name: 'В процентах' }
            ],
            paymentInCityDay: {
                type: 0,
                value: 0
            },
            paymentInCityNight: {
                type: 0,
                value: 0
            },
            paymentOutCity: {
                type: 0,
                value: 0
            },
            transfersPay: [],
            summTransfersPay: null,
            ordersPay: [],
            summOrdersPay: null,
            summHoursPay: null,
            user: {},
            group: '',
            salaryData: {},
            lastSalary: '',
            additional_ids: '',
            firstDate: true,
            statusChanged: false,
            isUsersModalOpen: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            users_list: 'notifications/users_list',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
            franchiseTariff: 'franchises/franchiseTariff',
            orderStatuses: 'orders/statuses'
        }),
        total_amount() {
            return this.summHoursPay + this.summTransfersPay + this.summOrdersPay + this.salary.bonus + this.salary.penalty;
        },
        timeZone() {
            return localStorage.getItem('timeZone');
        },
        userName() {
            if (!!this.$route.params.salaryID && this.user) {
                return `${this.user.last_name} ${this.user.first_name}`
            } else if (!this.$route.params.salaryID && this.users_list && this.users_list[0]) {
                return `${this.users_list[0].last_name} ${this.users_list[0].first_name}`
            }
            return 'Выберите пользователя'
        },
        dateRange: {
            get() {
                if (this.range.endDate) {
                    return [moment(this.range.startDate).format('YYYY-MM-DD'), moment(this.range.endDate).format('YYYY-MM-DD')];
                } else {
                    return [moment(this.range.startDate).format('YYYY-MM-DD')];
                }
            },
            set(value) {
                if (this.firstDate) {
                    this.range.startDate = value[0];
                    this.range.endDate = null
                    this.firstDate = false
                } else {
                    this.firstDate = true;
                    this.range.endDate = value[1];
                    if (this.range.startDate && this.range.endDate && this.user.id) {
                        clearTimeout(timer)
                        timer = setTimeout(async () => {
                            if (this.group !== 'customers') {
                                this.loadTimesheet(this.range.startDate, this.range.endDate);
                            }

                            if (this.group === 'courier') {
                                // this.$store.dispatch('orders/GET_ORDER_STATUSES');
                                this.loadCourierOrders(this.range.startDate, this.range.endDate);
                                this.getTransfersForSalary();
                            }
                            this.loadSalaryData(this.range.startDate, this.range.endDate);
                            if (!this.$route.params.salaryID) {
                                const response = await getUserAllowense(this.user.id)
                                if (response.allowances) {
                                    this.userAllowances = response.allowances;
                                } else {
                                    this.userAllowances = []
                                }
                            }
                        }, 500)
                    }
                }
            }
        },
        dateRangeForamted() {
            return `${moment(this.range.startDate).format('DD.MM.YYYY')} ~ ${moment(this.range.endDate).format('DD.MM.YYYY')}`;
        },
        salaryForCourier() {
            return (this.users_list.length && this.users_list[0].groups[0] === 'courier') || (this.user && this.user.groups && this.user.groups[0] === 'courier')
        }
    },
    watch: {
        async users_list(value) {
            if (value.length) {
                const user = await this.$store.dispatch('users/GET_USER', value[0].id)
                this.user = user;
                this.group = user.groups[0];
                const res = await getLastSalary(this.user.id)
                this.lastSalary = res.date_salary_end;
            }
        },
        range() {
            if (this.range.startDate && this.range.endDate && this.user.id) {
                clearTimeout(timer);
                timer = setTimeout(async () => {
                    if (this.group !== 'customers') {
                        this.loadTimesheet(this.formatDate(this.range.startDate._d), this.formatDate(this.range.endDate._d));
                    }

                    if (this.group === 'courier') {
                        // this.$store.dispatch('orders/GET_ORDER_STATUSES');
                        this.loadCourierOrders(this.range.startDate, this.range.endDate);
                        this.getTransfersForSalary();
                    }
                    this.loadSalaryData(this.range.startDate, this.range.endDate);
                    if (!this.$route.params.salaryID) {
                        const response = await getUserAllowense(this.user.id)
                        if (response.allowances) {
                            this.userAllowances = response.allowances;
                        } else {
                            this.userAllowances = []
                        }
                    }
                }, 500)
            }
        },
        async user() {
            if (this.range.startDate && this.range.endDate && this.user.id) {
                if (this.group !== 'customers') {
                    this.loadTimesheet(this.formatDate(this.range.startDate._d), this.formatDate(this.range.endDate._d));
                }

                if (this.group === 'courier') {
                    // this.$store.dispatch('orders/GET_ORDER_STATUSES');
                    if (!this.$route.params.salaryID) {
                        this.loadCourierOrders(this.range.startDate, this.range.endDate);
                        this.getTransfersForSalary()
                    }
                }
                this.loadSalaryData(this.range.startDate, this.range.endDate);
                if (!this.$route.params.salaryID) {
                    const response = await getUserAllowense(this.user.id)
                    if (response.allowances) {
                        this.userAllowances = response.allowances;
                    } else {
                        this.userAllowances = []
                    }
                }
            }
        },
    },
    created() {
        if (this.$route.params.salaryID) {
            this.$store.commit('ui/SET_PRELOADER', true);
        }
    },
    async mounted() {
        try {
            this.$store.commit('users/CLEAR_FILTERS_STATE');
            this.$store.commit('users/SET_FILTER', { field: 'is_staff', value: 'True' });
            this.$store.commit('users/SET_SINGLE_MODAL_MODE', true);
            this.$store.dispatch('orders/GET_ORDER_STATUSES');
            if (this.$route.params.salaryID) {
                const response = await getSalaryById(this.$route.params.salaryID)
                const user = await this.$store.dispatch('users/GET_USER', response.user)
                this.user = user;
                this.group = user.groups[0];
                this.range.startDate = moment(response.date_salary_start).utc();
                this.range.endDate = moment(response.date_salary_end).utc();
                this.userAllowances = response.read_allowances;
                this.salaryData = response;
                this.additional_ids = typeof response.additional_ids === 'string' ? JSON.parse(response.additional_ids) : [];
                this.workingTime = response.additional_ids && response.additional_ids.length ? response.additional_ids : [];
                this.ordersPay = response.couriers_payment.orders;
                this.courierOrders = response.couriers_payment.orders;
                this.transfersPay = response.couriers_payment.transfers;
                this.courierTransfers = response.couriers_payment.transfers;
                this.$store.commit('ui/SET_PRELOADER', false);
            } else {
                this.isUsersModalOpen = true;
            }
        } catch (error) {
            console.log(error);
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        this.$store.commit('notifications/CLEAR_USERS_LIST');
        this.$store.commit('users/CLEAR_FILTERS_STATE');
        this.$store.commit('users/SET_FILTER', { field: 'is_staff', value: 'True' });
        this.$store.commit('users/SET_SINGLE_MODAL_MODE', false);
        this.$store.commit('notifications/CLEAR_USERS_LIST');
    },
    methods: {
        async loadTimesheet(startDate, endDate) {
            try {
                this.dateInterval += 3;

                this.startDate.setDate(this.startDate.getDate() - this.dateInterval);

                if (startDate && endDate) {
                    if (!this.$route.params.salaryID) {
                        const response = await getTimesheet(this.user.id, startDate, endDate, false, null)
                        if (response.length && this.workingTime.length && this.workingTime[0].id === response[0].id) {
                            // this.canLoadMoreTime = false;
                        }
                        this.workingTime = response.slice().reverse();
                    }
                } else {
                    const response = await getTimesheet(this.user.id, null, null, false)
                    if (response.length) {
                        this.showTimeTable = true;
                    }
                    this.workingTime = response.slice().reverse();
                }
            } catch (error) {
                console.log(error);
            }
        },
        async loadSalaryData(startDateR, endDateR) {
            await getLastSalary(this.user.id)
            let startDate = moment(startDateR);
            let endDate = moment(endDateR);

            this.salary.date_salary_start = startDate;
            this.salary.date_salary_end = endDate;
            if (this.user.groups[0] !== 'customers' && !this.$route.params.salaryID) {
                const timesheetStart = startDate.format('YYYY-MM-DD');
                const timesheetEnd = endDate.format('YYYY-MM-DD')
                const response = await getTimesheet(this.user.id, timesheetStart, timesheetEnd, !!this.$route.params.salaryID, this.$route.params.salaryID || null)
                let sumSecond = 0;
                response.forEach((day) => {
                    let timing = day.duration.substr(-8).split(':');
                    sumSecond += new Duration(`${timing[0]}h${timing[1]}m${timing[2]}s`).seconds();
                });
                this.salary.workingSeconds = sumSecond;
                this.salary.workingHours = new Duration(`${sumSecond}s`).hours();
                this.calculateUserSalary();
            } else if (this.$route.params.salaryID) {
                this.recalculateSalary();
            }
        },
        recalculateSalary() {
            let sumSecond = 0;
            this.workingTime.forEach((day) => {
                if (day.duration.length === 5) {
                    day.duration += ':00';
                }
                let timing = day.duration.split(':');
                sumSecond += new Duration(`${timing[0]}h${timing[1]}m${timing[2]}s`).seconds();
            });
            this.salary.workingSeconds = sumSecond;
            this.salary.workingHours = new Duration(`${sumSecond}s`).hours();
            this.salaryData.hour_worked = new Duration(`${sumSecond}s`).hours();

            if (!this.salary.date_salary_start || !this.salary.date_salary_end) return;
            this.calculateUserSalary();
        },
        async giveOutSalary() {
            this.$store.dispatch('ui/checkUserEditPermission');

            if (!this.$route.params.salaryID) {
                let allowances = [];
                this.userAllowances.forEach((allow) => {
                    allowances.push({
                        salary_change: allow.salary_change__id,
                        count: allow.count,
                    })
                })
                let additional_ids = this.workingTime.length ? this.workingTime : [];
                additional_ids.forEach((day) => {
                    if (day.duration.length === 5) {
                        day.duration += ':00';
                    }
                });
                const sendData = {
                    user: this.user.id,
                    date_salary_start: moment(this.salary.date_salary_start).format('YYYY-MM-DDT00:00'),
                    date_salary_end: moment(this.salary.date_salary_end).format('YYYY-MM-DDT00:00'),
                    hour_worked: this.salary.workingSeconds,
                    user_salary_rate: this.user.salary_rate,
                    allowances,
                    additional_ids,
                    couriers_payment: {
                        orders: this.ordersPay,
                        transfers: this.transfersPay
                    },
                    total_amount: this.total_amount
                };

                await postSalary(this.user.id, sendData)

                this.$router.push({ name: 'salarys' });
            } else if (this.$route.params.salaryID && (!this.salaryData.issued_to_user || (this.salaryData.issued_to_user && this.statusChanged))) {
                const sendData = { ...this.salaryData };
                sendData.couriers_payment = {
                    orders: this.ordersPay,
                    transfers: this.transfersPay
                };
                sendData.total_amount = +this.total_amount;
                sendData.date_salary_start = moment(this.range.startDate).format('YYYY-MM-DDT00:00');
                sendData.date_salary_end = moment(this.range.endDate).format('YYYY-MM-DDT00:00');

                await patchSalary(sendData)

                let dates = sendData.additional_ids.length ? sendData.additional_ids.map(item => item.date).join() : '';
                let data = {
                    uid: sendData.user,
                    salary_id: sendData.id
                }
                if (dates) data.dates = dates;

                if (sendData.issued_to_user && this.statusChanged) {
                    patchUserChange(data);
                    if (sendData.couriers_payment.orders.length) {
                        patchCourierOrders({
                            order_ids: sendData.couriers_payment.orders.map(item => item.id),
                        });
                    }
                    if (sendData.couriers_payment.transfers.length) {
                        markTransferAsPaid({
                            transfer_ids: sendData.couriers_payment.transfers.map(item => item.id),
                            is_paid_for_courier: 'True'
                        });
                    }
                }
                this.$router.push({ name: 'salarys' });
            } else {
                this.$router.push({ name: 'salarys' });
            }
        },
        async delAllowance(index) {
            let isEmptyList = false;
            this.userAllowances.splice(index, 1);
            if (!this.userAllowances.length) isEmptyList = true;
            this.calculateUserSalary(isEmptyList);
        },
        async deleteSalary() {
            this.$store.dispatch('ui/checkUserEditPermission');
            await deleteSalary(this.salaryData);
            this.$router.push({ name: 'salarys' });
        },
        formatDate(value, type) {
            return date(new Date(value), type);
        },
        momentForamtDate(date) {
            return moment(date).format('DD.MM.YY');
        },
        onChange(range) {
            this.range = range;
            const startDate = this.formatDate(this.range.startDate._d);
            const endDate = this.formatDate(this.range.endDate._d);
            this.loadTimesheet(startDate, endDate);
        },
        clearDate() {
            this.range.startDate = null;
            this.range.endDate = null;
            this.loadTimesheet();
        },
        getSalaryAllow(bool) {
            return this.userAllowances.reduce((sum, a) => {
                if (a.salary_choice === (bool ? 'bonus' : 'penalty')) {
                    return sum + +a.amount;
                } else {
                    return sum;
                }
            }, 0);
        },
        changeSalaryRate(rate) {
            clearTimeout(timer);
            this.$store.dispatch('ui/checkUserEditPermission');
            timer = setTimeout(async () => {
                await updateUser(this.users_list[0] ? this.users_list[0].id : this.user.id, { salary_rate: +rate })
                this.loadSalaryData(this.range.startDate, this.range.endDate);
            }, 1000);
        },
        pushToChanges(id) {
            window.open(`/salarys-changes/${id}`, '_blank');
        },
        pushToOrder(id) {
            window.open(`/orders/${id}`, '_blank');
        },
        async loadCourierOrders(startDate, endDate) {
            try {
                const formatedStartDate = moment(startDate._i ? startDate._i : startDate).format('YYYY-MM-DD');
                const formatedEndDate = moment(endDate._i ? endDate._i : endDate).format('YYYY-MM-DD');
                const issuedToUser = !!this.$route.params.salaryID && !!(this.salaryData && this.salaryData.issued_to_user)
                const res = await getCourierOrder(this.user.id, formatedStartDate, formatedEndDate, issuedToUser)
                this.courierOrders = res.results;
                this.ordersPay = this.courierOrders;
                if (!this.$route.params.salaryID) {
                    this.setDefaultOrdersPayment();
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getTransfersForSalary() {
            const startDate = this.range.startDate._d ? this.formatDate(this.range.startDate._d) : this.range.startDate;
            const endDate = this.range.endDate._d ? this.formatDate(this.range.endDate._d) : this.range.endDate;
            const { results } = await getTransfersForSalary(this.user.id, startDate, endDate, !!this.salaryData.issued_to_user)
            this.courierTransfers = results;
            this.transfersPay = this.courierTransfers;
            if (!this.$route.params.salaryID) {
                this.courierTransfers.forEach((item) => {
                    item.price = 0;
                    this.setTransfersPay(+item.price, item);
                });
            } else {
                this.courierTransfers.forEach((item) => {
                    let findItem = this.transfersPay.find((transfer) => transfer.id == item.id);
                    item.price = findItem ? +findItem.value : '';
                });
                this.courierTransfers = this.courierTransfers.filter((item) => +item.price);
                this.calculateUserSalary();
            }
        },
        setTransfersPay(value, transferItem) {
            let index = this.transfersPay.findIndex((item) => item.id === transferItem.id);
            if (index >= 0) {
                this.transfersPay.splice(index, 1, { ...transferItem, value });
            }

            this.calculateUserSalary();
        },
        setOrdersPay(value, orderItem) {
            let index = this.ordersPay.findIndex((item) => item.id === orderItem.id);
            if (index >= 0) {
                this.ordersPay.splice(index, 1, { ...orderItem, value });
            }

            this.calculateUserSalary();
        },
        setDefaultOrdersPayment() {
            let orders_list = this.courierOrders;
            orders_list.forEach((item) => {
                if (item.delivery_in_city) {
                    if (item.delivery_cost < this.franchiseTariff.delivery_markup_bytime_city) {
                        item.price = this.paymentInCityDay.type === 0 ? +this.paymentInCityDay.value :
                            Math.ceil(item.delivery_cost * (this.paymentInCityDay.value / 100));
                    }  else {
                        item.price = this.paymentInCityNight.type === 0 ? +this.paymentInCityNight.value :
                            Math.ceil(item.delivery_cost * (this.paymentInCityNight.value / 100));
                    }
                } else {
                    item.price = this.paymentOutCity.type === 0 ? this.paymentOutCity.value :
                        Math.ceil(item.delivery_cost * (this.paymentOutCity.value / 100));
                }
                this.setOrdersPay(item.price, item);
            });
        },
        setTimeWithTimezone(item, mode) {
            try {
                let date;
                if (mode === 'start') {
                    date = new Date(`${item.date}T${item.start_time}Z`);
                } else {
                    date = new Date(`${item.date}T${item.end_time}Z`);
                }
                let formatedTimeZone = date.toLocaleString("ru", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    timeZone: this.timeZone
                });
                return formatedTimeZone;
            } catch (error) {
                console.log(error);
                return mode === 'start' ? item.start_time : item.end_time;
            }
        },
        calculateUserSalary: debounce(async function(isEmptyList) {
            this.summTransfersPay = this.courierTransfers.reduce((sum, current) => sum + +current.price, 0);
            this.summOrdersPay = isNaN(this.courierOrders.reduce((sum, current) => sum + +current.price, 0)) ? 0 : this.courierOrders.reduce((sum, current) => sum + +current.price, 0);

            let data = {
                date_salary_start: this.salary.date_salary_start,
                date_salary_end: this.salary.date_salary_end,
                hour_worked: this.salary.workingSeconds,
                orders_summary: this.summOrdersPay,
                transfers_summary: this.summTransfersPay,
            }
            if (this.userAllowances.length) {
                data.allowances = this.userAllowances.map(item => item.salary_change__id || item.id);
            } else if (isEmptyList || (this.$route.params.salaryID && !this.userAllowances.length)) {
                data.allowances = [];
            }
            const response = await calculateSalary(this.user.id, data);
            this.summHoursPay = Math.ceil(+response.summary_by_hour_worked);
            this.salary.bonus = +response.bonus;
            this.salary.penalty = +response.penalty;
        }, 500),
        async exportFile() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                const file = await exportSalaryToExcel(this.$route.params.salaryID);
                const data = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = data;
                link.target = '_blank';
                link.download = `Зарплата-№${this.$route.params.salaryID}.xlsx`;
                link.click();

                setTimeout(() => {
                    window.URL.revokeObjectURL(data);
                }, 1000);
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки файла', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        formatNumber(num) {
            return numberFormat(num);
        },
    },
}
</script>

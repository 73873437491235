/* eslint-disable */

const state = {
    changingGoods: {}
};

const mutations = {
    SET_EQUIPMENT(state, good) {
        for (let i = 0; i < state.changingGoods.equipment_set.length; i++) {
            if (state.changingGoods.equipment_set[i] === good) {
                state.changingGoods.equipment_set[i] = good;
            }
        }
    },
    REMOVE_EQUIPMENT(state, good) {
        state.changingGoods.equipment_set = state.changingGoods.equipment_set.filter(equi => equi.vendor_code !== good.vendor_code);
    },
    SET_NEW_EQUIPMENT_LIST(state, list) {
        state.changingGoods.equipment_set = list;
    },
    SET_GOOD(state, good) {
        good.price = good.equipment_set.reduce((sum, current) => {
            return sum + (current.count * current.price)
        }, 0)
        state.changingGoods = good;
    },
    ADD_EQUIPMENT(state, good) {
        state.changingGoods.equipment_set.push(good);
    },
    CLEAR(state) {
        state.changingGoods.equipment_set = [];
    }
};

const actions = {

};

const getters = {
    changingGoods: state => state.changingGoods,
    selected: state => id => !!state.changingGoods.equipment_set.find(good => good.vendor_code === id)
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

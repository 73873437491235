<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Статистика"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <p>В разделе "Статистика" можно посмотреть различные показатели за определенный период времени.</p>
                                <p>С помощью быстрых фильтров можно выбрать время отображения статистики. Изначально выбрано <b>"Сегодня"</b>.</p>
                                <img src="@/assets/img/help/statistic/1.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Есть возможность выбрать определенный промежуток дат, источник заказов, магазин и флориста.</p>
                                <img src="@/assets/img/help/statistic/2.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>При клике на показатель график будет перестроен.</p>
                                <img src="@/assets/img/help/statistic/3.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>У некоторых графиков есть возможность переключить тип отображаемых данных.</p>
                                <img src="@/assets/img/help/statistic/4.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <p>Например, график "Топ продаж" переключается в режимах Составляющие / Букеты. Изначально отображается статистика по составляющим.</p>

                                <p>Статистику количества клиентов можно отфильтровать по полу пользователя.</p>
                                <img src="@/assets/img/help/statistic/5.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[ { name: 'Клиенты', link: '/help/clients' } , { name: 'К оглавлению', link: '/help' }]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpStatistic',
    metaInfo: {
        title: 'Статистика - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

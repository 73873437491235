<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Сертификаты" for-listing :show-save-btn="false" />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="300" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="certificates.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                            <div class="listing-table__col pl-0" style="width: calc((100% - 112px) / 5)">Сертификат</div>
                            <div class="listing-table__col" style="width: calc((100% - 112px) / 5)">Дата отправки</div>
                            <div class="listing-table__col" style="width: calc((100% - 112px) / 5)">Дата оплаты</div>
                            <div class="listing-table__col" style="width: calc((100% - 112px) / 5)">Номинал</div>
                            <div class="listing-table__col" style="width: calc((100% - 112px) / 5)">Статус</div>
                        </div>
                        <router-link
                            v-for="item in certificates"
                            :key="item.id"
                            :to="`/certificates/${item.id}`"
                            class="listing-table__row"
                        >
                            <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                <div class="listing-table__image">
                                    <img :src="findCertificateTemplate(item) ? findCertificateTemplate(item).image.preview : require('@/assets/img/flower.svg')" alt="">
                                </div>
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-inline"
                                :class="{'pt-0 mr-0': width <= 1024}"
                                :style="width > 1024 ? 'width: calc((100% - 112px) / 5)' : ''"
                            >
                                <div class="text-cut">
                                    №<span>{{ item.id }}</span><br>
                                    <span class="table-col__small-text">
                                        {{ item.owner.first_name ? item.owner.first_name : 'Пользователь' }}
                                    </span>
                                </div>
                            </div>

                            <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 5)' : ''">
                                <div class="listing-table__col-label">Дата отправки</div>
                                <span class="text-cut">{{ formatDate(item.delivery_date, 'hours-ru') }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 5)' : ''">
                                <div class="listing-table__col-label">Дата оплаты</div>
                                <span class="text-cut">
                                    {{ item.payment_date ? formatDate(item.payment_date, 'hours-ru') : '&mdash;' }}
                                </span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 5)' : ''">
                                <div class="listing-table__col-label">Номинал</div>
                                <div class="d-flex align-center">
                                    <Icon :color="item.is_paid ? '#0BD58A' : '#F00C29'" size="16" class="mr-1">
                                        {{ item.is_paid ? 'check' : 'close' }}
                                    </Icon>
                                    {{ formatNumber(Math.ceil(+item.amount)) }} ₽
                                </div>
                            </div>
                            <div
                                class="listing-table__col"
                                :style="width > 1024 ? 'width: calc((100% - 112px) / 5)' : 'order: 1; margin: 0 0 0 auto'"
                            >
                                <StatusSelect
                                    :value="item.status"
                                    :options="certificatesStatuses"
                                    disabled
                                />
                            </div>
                        </router-link>
                        <div class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner v-show="busy && next" />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="!certificates.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button
            v-if="!authUser.read_only"
            v-show="['supervisor', 'administrators', 'managers'].includes(authUserGroup)"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { date, numberFormat } from '@/utils/format';

import Spinner from '@/components/app/Spinner.vue';

export default {
    name: 'TheCertificates',
    metaInfo: {
        title: 'Сертификаты'
    },
    components: { Spinner },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            certificates: 'certificates/certificates',
            certificatesTemplates: 'certificates/certificatesTemplates',
            certificatesStatuses: 'certificates/certificatesStatuses',
            authUser: 'auth/user',
            busy: 'certificates/busy',
            next: 'certificates/next',
            preloader: 'ui/preloader',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await Promise.all([
                this.$store.dispatch('certificates/GET_ALL_CERTIFICATES'),
                this.$store.dispatch('certificates/GET_CERTIFICATES_TEMPLATES')
            ]);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        loadMore() {
            this.$store.dispatch('certificates/GET_NEXT_PAGE');
        },
        findCertificateTemplate(cert) {
            return this.certificatesTemplates.find((i) => i.id === cert.template) || false;
        },
        formatDate(value, type) {
            return date(new Date(value), type);
        },
        formatNumber(number) {
            return number === 0 ? 0 : numberFormat(number);
        },
    }
};
</script>

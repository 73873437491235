<template>
    <label
        v-if="!preloader"
        class="checkbox__wrap"
        :class="{'active': value, 'disabled': disabled, 'inline': inline}"
        v-on="$listeners"
        @click.stop
    >
        <input
            type="checkbox"
            :checked="value"
            style="display:none;"
            :disabled="disabled"
            :readonly="readonly"
            @change.stop="$emit('change', $event.target.checked)"
        >
        <div v-if="label" class="checkbox__label" :class="{'right': labelAlign === 'right'}">
            {{ label }}
        </div>
        <div v-if="additionalText" class="checkbox__additional-text" :style="{'color': additionalTextColor}">
            {{ additionalText }}
        </div>
        <div class="checkbox">
            <div class="checkbox__circle" :class="{'active': value}" />
        </div>
    </label>
    <ContentPreloader v-else height="26" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CheckboxCustom',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        label: { type: String, default: '' },
        labelAlign: { type: String, default: 'left' },
        additionalText: { type: String, default: '' },
        additionalTextColor: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        inline: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
        }),
    },
}
</script>

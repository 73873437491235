<template>
    <div class="universal-modal__component">
        <UniversalModalHeader title="История заказов товара" @click:right="$emit('closeModal')" />
        <div class="universal-modal__content">
            <div class="page__info-group">
                <Row>
                    <Col>
                        <v-menu
                            ref="goodStatisticDatepicker"
                            offset-y
                            :close-on-content-click="false"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <InputCustom
                                    :value="formatedStatisticGoodDate"
                                    label="Дата"
                                    left-icon="calendar"
                                    readonly
                                    clearable
                                    v-on="on"
                                    @click:clear="statistic.date = [], getGoodStatistic()"
                                />
                            </template>
                            <v-date-picker
                                v-model="statistic.date"
                                no-title
                                range
                                first-day-of-week="1"
                                @input="() => {
                                    if (statistic.date[0] && statistic.date[1]) {
                                        $refs.goodStatisticDatepicker.save(statistic.date);
                                        getGoodStatistic();
                                    }
                                }"
                            />
                        </v-menu>
                    </Col>
                    <Col>
                        За указанный период товар "{{ product.name }}" продали {{ statistic.results.count_of_product || 0 }} {{ declOfNum(statistic.results.count_of_product || 0, ['раз', 'раза', 'раз']) }}
                    </Col>
                </Row>
            </div>
            <div class="page__info-group">
                <div v-show="!isLoading" class="listing-table__wrap">
                    <div v-if="statistic.results.orders && statistic.results.orders.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 33%">Заказ</div>
                            <div class="listing-table__col" style="width: 33%">Товара продано</div>
                            <div class="listing-table__col" style="width: 33%">Дата</div>
                        </div>
                        <a
                            v-for="item in statistic.results.orders"
                            :key="item.order__visible_id"
                            :href="`/orders/${item.order__visible_id}`"
                            class="listing-table__row"
                            target="_blank"
                        >
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                <div class="listing-table__col-label">Заказ</div>
                                {{ item.order__visible_id }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                <div class="listing-table__col-label">Товара продано</div>
                                {{ item.count }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                <div class="listing-table__col-label">Дата</div>
                                {{ item.order__date_created ? moment(item.order__date_created).format('DD.MM.YYYY HH:mm') : '&mdash;' }}
                            </div>
                        </a>
                    </div>
                </div>
                <ContentPreloader v-show="isLoading" type="table" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { declOfNum } from '@/utils/functions';

import { getProductOrdersHistory } from '@/api/django';


export default {
    name: 'ProductWriteoffStatistic',
    props: {
        product: { type: Object, default: () => {} }
    },
    data() {
        return {
            statistic: {
                date: [],
                results: {}
            },
            isLoading: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
        }),
        formatedStatisticGoodDate() {
            if (this.statistic.date.length > 1) {
                return this.statistic.date.map(i => this.moment(i).format('DD.MM.YYYY')).join(' - ')
            }
            return ''
        },
    },
    mounted() {
        this.getGoodStatistic();
    },
    methods: {
        async getGoodStatistic() {
            this.isLoading = true;
            const response = await getProductOrdersHistory(this.$route.params.ID, {
                date_start: this.statistic.date[0] || null,
                date_end: this.statistic.date[1] || null
            });
            this.statistic.results = response;
            this.isLoading = false;
        },
        declOfNum,
        moment
    }
}
</script>

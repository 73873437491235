<template>
    <div
        v-if="franchiseTariff.tariff && franchiseTariff.tariff.includes('chat')"
        class="chat-lost-connection-wrap"
        :class="{
            'is-visible': isMessageShow,
            'lost': chatConnectionLost, 
            'restore': isMessageShow && !chatConnectionLost}
        "
    >
        {{ 
            chatConnectionLost ? 'Соединение с чатами потеряно. Пытаемся переподключиться!' : 
            'Соединение с чатами восстановлено!' 
        }}
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ChatLostConnection',
    data() {
        return {
            isMessageShow: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            chatWSReady: 'chat/wsReady',
            chatConnectionLost: 'chat/chatConnectionLost',
            franchiseTariff: 'franchises/franchiseTariff',
        }),
    },
    watch: {
        chatConnectionLost(val) {
            if (val) {
                this.isMessageShow = true;
            }
        },
        chatWSReady(val) {
            if (val) {
                setTimeout(() => {
                    this.isMessageShow = false;
                }, 2000);
            }
        }
    }
}
</script>


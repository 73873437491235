<template>
    <div class="side-filter__content">
        <div class="side-filter__content-group-list">
            <div v-if="width <= 1024" class="side-filter__content-group">
                <InputSelect
                    :value="filters.sort"
                    :options="sortOptions"
                    label="Сортировка"
                    input-value="value"
                    icon="list-ul"
                    without-preloader
                    @input="sortOrders"
                />
            </div>
            <div class="side-filter__content-group">
                <v-menu ref="date" offset-y :close-on-content-click="false" transition="scale-transition" max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                        <InputCustom
                            :value="datepickerFormatted"
                            left-icon="calendar"
                            placeholder="Выберите период"
                            clearable
                            readonly
                            without-preloader
                            v-on="on"
                            @click:clear="datepicker = [], setFilterDate()"
                        />
                    </template>
                    <v-date-picker
                        v-model="datepicker"
                        no-title
                        range
                        first-day-of-week="1"
                        @input="() => {
                            if (datepicker[0] && datepicker[1]) {
                                $refs.date.save(datepicker);
                                setFilterDate();
                            }
                        }"
                    />
                </v-menu>
            </div>

            <div v-if="group !== 'courier'" class="side-filter__content-group">
                <InputSelect
                    :value="filters.pickup"
                    :options="orderTypeOptions"
                    input-value="value"
                    icon="map"
                    without-preloader
                    @input="filterOrders('pickup', $event)"
                />
            </div>

            <div v-if="group !== 'florist'" class="side-filter__content-group">
                <InputSelect
                    :value="filters.shop"
                    :options="[{ id: null, address: 'Все мастерские', type_icon: 'store' }, ...shops.results]"
                    input-text="address"
                    input-icon="type_icon"
                    icon="store"
                    without-preloader
                    @input="filterOrders('shop', $event)"
                />
            </div>

            <div v-if="Object.keys(statuses).length" class="side-filter__content-group">
                <InputSelect
                    :value="filters.status"
                    :options="[{ slug: null, name: 'Все статусы' }, ...statuses]"
                    input-value="slug"
                    icon="medal"
                    without-preloader
                    @input="filterOrders('status', $event)"
                />
            </div>

            <div v-if="sources.length && group !== 'courier'" class="side-filter__content-group">
                <InputSelect
                    :value="filters.source"
                    :options="[{ id: null, name: 'Все источники' } , ...sources]"
                    icon="archive-tray"
                    without-preloader
                    searchable
                    @input="filterOrders('source', $event)"
                />
            </div>

            <div v-if="paymentMethods.length && group !== 'courier'" class="side-filter__content-group">
                <InputSelect
                    :value="filters.payment_method"
                    :options="[{ id: null, name: 'Все способы' }, ...paymentMethods]"
                    icon="rub"
                    without-preloader
                    @input="filterOrders('payment_method', $event)"
                />
            </div>

            <div v-if="group !== 'courier'" class="side-filter__content-group">
                <InputSelect
                    :value="filters.review_rating"
                    :options="[{ value: null, name: 'Все оценки' }, ...ratingOptions]"
                    input-value="value"
                    icon="star"
                    without-preloader
                    @input="filterOrders('review_rating', $event)"
                />
            </div>

            <div v-if="group === 'supervisor'" class="side-filter__content-group">
                <InputSelect
                    :value="filters.promocode_id"
                    :options="computedPromocodes"
                    label="Промокод"
                    input-sub-text="subText"
                    icon="sale"
                    without-preloader
                    searchable
                    @input="filterOrders('promocode_id', $event)"
                />
            </div>

            <div v-if="group !== 'courier'" class="side-filter__content-group">
                <InputSelect
                    :value="filters.courier_id"
                    :options="[{ id: null, first_name: 'Не задан' } ,...couriers]"
                    label="Курьер"
                    input-text="first_name"
                    icon="users"
                    searchable
                    without-preloader
                    @input="filterOrders('courier_id', $event)"
                />
            </div>

            <div v-if="!['courier', 'florist'].includes(group)" class="side-filter__content-group">
                <InputSelect
                    :value="selectValues.florist"
                    :options="[
                        { id: null, first_name: 'Не выбран' },
                        { id: 'True', first_name: 'Не указан' },
                        ...florists
                    ]"
                    label="Флорист"
                    input-text="first_name"
                    icon="users"
                    searchable
                    without-preloader
                    @input="filterOrders('florist__id', $event)"
                />
            </div>

            <div v-if="!['courier', 'florist'].includes(group)" class="side-filter__content-group">
                <InputSelect
                    :value="filters.is_referral"
                    :options="[
                        { value: null, name: 'Не задан' },
                        { value: 'True', name: 'Реферальный' },
                        { value: 'False', name: 'Не реферальный' },
                    ]"
                    label="Реферальный"
                    input-value="value"
                    icon="users-three"
                    without-preloader
                    @input="filterOrders('is_referral', $event)"
                />
            </div>

            <div v-if="!['courier', 'florist'].includes(group)" class="side-filter__content-group">
                <InputSelect
                    :value="filters.is_coffee"
                    :options="[
                        { value: null, name: 'Все' },
                        { value: false, name: 'Цветочная мастерская' },
                        { value: true, name: 'Кофейня' },
                    ]"
                    label="Тип заказа"
                    input-value="value"
                    icon="clipboard-text"
                    without-preloader
                    @input="filterOrders('is_coffee', $event)"
                />
            </div>

            <div class="side-filter__content-group">
                <Button
                    block compact text
                    icon-left="file-minus"
                    @click.stop="downloadFileProperties.isShowModal = true"
                >
                    Скачать заказы в pdf
                </Button>
            </div>
        </div>

        <Button class="mt-8" @click="$store.commit('ui/CLOSE_FILTER', 'order-filter')">
            Смотреть {{ numberFormat(ordersCount) }} {{ declOfNum(ordersCount, ['заказ', 'заказа', 'заказов']) }}
        </Button>
        <button class="side-filter__clear-btn mt-4" @click.prevent="clearFilter">
            Очистить
        </button>

        <MiniModal
            v-model="downloadFileProperties.isShowModal"
            title="Вы уверены, что хотите скачать файл?"
            additional-btn-text="Отмена"
            main-btn-text="Скачать"
            @additionalBtnClick="downloadFileProperties.isShowModal = false"
            @mainBtnClick="downloadOrders"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'

import { declOfNum } from '@/utils/functions';
import { numberFormat } from '@/utils/format';

import { downloadOrdersFile, getUserByGroup } from '@/api/django'

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'OrderFilter',
    components: { MiniModal },
    data() {
        return {
            datepicker: [],
            orderTypeOptions: [
                { name: 'Доставка и самовывоз', value: null },
                { name: 'Доставка', value: false },
                { name: 'Самовывоз', value: true }
            ],
            sortOptions: [
                { name: '№ Заказа ↓', value: '-visible_id' },
                { name: '№ Заказа ↑', value: 'visible_id' },
                { name: 'Дата ↓', value: '-delivery_date' },
                { name: 'Дата ↑', value: 'delivery_date' },
            ],
            ratingOptions: [
                { name: '1', value: '1' },
                { name: '2', value: '2' },
                { name: '3', value: '3' },
                { name: '4', value: '4' },
                { name: '5', value: '5' },
            ],
            downloadFileProperties: {
                isShowModal: false,
            },
            selectValues: {
                florist: null
            },
            florists: []
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            shops: 'shops/shops',
            statuses_orders: 'orders/statuses',
            sort: 'orders/sort',
            paymentMethods: 'paymentType/paymentMethods',
            sources: 'data/sources',
            filters: 'orders/filters',
            activeFilters: 'orders/activeFilters',
            ordersCount: 'orders/ordersCount',
            promocodes: 'promocodes/promocodes',
            couriers: "users/users",
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        statuses() {
            if (this.statuses_orders !== null && typeof this.statuses_orders.statuses !== 'undefined') {
                let excludes = [0]; // для удаления ненужных фильтров статуса(id||slug статусов)
                if (this.group === 'florist') excludes.push(1, 5, 6, 7, 9);
                if (this.group === 'courier') excludes.push(1, 6, 7, 9);
                let preFiltred = Object.keys(this.statuses_orders.statuses).map((i) => ({ ...this.statuses_orders.statuses[i], slug: Number(i) }));
                return preFiltred.filter((status) => !excludes.includes(status.slug));
            }
            return [];
        },
        computedPromocodes() {
            let promocodes = [{ id: null, name: 'Все' }]
            let promocodesWithSubText = this.promocodes.map(i => {
                return {...i, subText: i.is_active ? 'Активен': 'Не активен' }
            })
            promocodes.push(...promocodesWithSubText)
            return promocodes
        },
        datepickerFormatted() {
            if (this.datepicker.length > 1) {
                return this.datepicker.map(i => this.moment(i).format('DD.MM.YYYY')).join(' - ')
            }
            return ''
        }
    },
    mounted() {
        if (this.filters.delivery_date_after && this.filters.delivery_date_before) {
            this.datepicker = [this.filters.delivery_date_after, this.filters.delivery_date_before]
        }

        this.selectValues.florist = this.filters.florist__isnull ? this.filters.florist__isnull : this.filters.florist__id

        // При переработке прелоадеров и фильтров нужно вынести запрос отсюда. Сейчас он вызывается при каждом открытии фильтра, что плохо
        getUserByGroup('florist', 'all')
            .then(res => {
                this.florists = res.results;
            })
    },
    methods: {
        async setFilterDate() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                this.datepicker.sort();

                this.$store.commit('orders/SET_FILTER', { field: 'delivery_date_after', value: this.datepicker[0] || null })
                this.$store.commit('orders/SET_FILTER', { field: 'delivery_date_before', value: this.datepicker[1] || null })

                await this.$store.dispatch('orders/GET_ALL_ORDERS');
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async filterOrders(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                if (field === 'florist__id') {
                    this.selectValues.florist = value;

                    this.$store.commit('orders/SET_FILTER', { field: 'florist__id', value: null })
                    this.$store.commit('orders/SET_FILTER', { field: 'florist__isnull', value: null })

                    if (value === 'True') {
                        field = 'florist__isnull'
                    }
                }

                await this.$store.dispatch('orders/GET_ALL_ORDERS', { field, value });
                window.scrollTo(0, 0);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async sortOrders(value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const type = value.startsWith('-') ? value.slice(1) : value;
                const direction = value.startsWith('-') ? '-' : '';
                this.$store.commit('orders/CHANGE_SORT', { type, direction });

                await this.$store.dispatch('orders/GET_ALL_ORDERS', { field: 'sort', value });
                window.scrollTo(0, 0);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async clearFilter() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.datepicker = [];
                this.$store.commit('orders/CLEAR_FILTERS_STATE');
                this.$store.commit('orders/CHANGE_FAST_FILTER');
                this.$store.commit('ui/CLOSE_FILTER', 'order-filter');

                await this.$store.dispatch('orders/GET_ALL_ORDERS', { field: 'sort', value: `${this.sort.direction}${this.sort.type}` });
                window.scrollTo(0, 0);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async downloadOrders() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.downloadFileProperties.isShowModal = false;

                const filters = await this.$store.dispatch('orders/prepareFilters');
                for (let key in filters) {
                    if (!this.activeFilters.includes(key)) {
                        delete filters[key]
                    }
                }

                if (!filters.delivery_date_after || !filters.delivery_date_before) {
                    filters.delivery_date_after = this.moment().subtract(7, 'd').format('YYYY-MM-DD');
                    filters.delivery_date_before = this.moment().add(7, 'd').format('YYYY-MM-DD')
                }

                const file = await downloadOrdersFile(filters);
                const fileUrl = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                link.download = 'Заказы.pdf';
                link.click();
            } catch (e) {
                console.log(e)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки заказов', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        numberFormat,
        declOfNum,
        moment
    },
};
</script>

/* eslint-disable */
import {
    getWriteOffsData,
    getAllWriteOffs,
    createWriteOffs,
    applyingWriteOffs,
    saveWriteOffs
} from '@/api/stock';

const state = {
    writeoffs: {},
    filters: {
        limit: 20,
        offset: 0,
        stock: null,
        date_from: null,
        date_to: null
    },
    activeFilters: [],
    busy: false,
    next_page: false,
    writeoffs_goods: [],
    status: '',
    selectedShop: '',
};

const mutations = {
    SET_WRITEOFFS(state, writeoffs) {
        state.writeoffs = writeoffs.results;
        state.next_page = writeoffs.next;
        state.filters.offset = 20;
    },
    SET_NEXT_PAGE_WRITEOFFS(state, writeoffs) {
        state.writeoffs.push(...writeoffs.results);
        state.next_page = writeoffs.next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    SET_WRITEOFFS_GOODS(state, goods) {
        state.writeoffs_goods = goods;
    },
    ADD_GOOD_IN_WRITEOFFS(state, good) {
        state.writeoffs_goods.push({
            count: 1,
            product: good.vendor_code,
            name: good.name,
            category: good.category,
            image: good.images.length && good.images[0].image ? good.images[0].image.preview : null
        });
    },
    REMOVE_GOOD_FROM_WRITEOFFS(state, id) {
        state.writeoffs_goods = state.writeoffs_goods.filter(item => item.product !== id);
    },
    CHANGE_COUNT_WRITEOFFS_GOOD(state, { product, value }) {
        for (let i = state.writeoffs_goods.length; i--;) {
            if (state.writeoffs_goods[i].product === product) {
                state.writeoffs_goods[i].count = value;
                break;
            }
        }
    },
    SET_STATUS_WRITEOFFS(state, { status }) {
        state.status = status;
    },
    CLEAR(state) {
        state.writeoffs = {};
        state.next_page = false;
        state.busy = false;
        state.filters.offset = 0;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            stock: null,
            date_from: null,
            date_to: null
        };
        state.next_page = null;
    },
    CLEAR_WRITEOFFS_GOODS(state) {
        state.writeoffs_goods = [];
    },
    SET_SHOP(state, shop) {
        state.selectedShop = shop;
    },
};

const actions = {
    async GET_ALL_WRITEOFFS({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;
            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;

            let filters = await dispatch('prepareFilters');
            const writeoffs = await getAllWriteOffs(filters);
            commit('SET_WRITEOFFS', writeoffs);
            state.busy = false;
        } catch (error) {
            console.log(error);
        }
    },
    async LOAD_MORE({ state, commit, dispatch }) {
        try {
            if (state.next_page && !state.busy) {
                state.busy = true;
                let filters = await dispatch('prepareFilters');

                const res = await getAllWriteOffs(filters);
                commit('SET_NEXT_PAGE_WRITEOFFS', res);
            }
        }
        catch(e) {
            console.log(e);
        }
    },
    async GET_WRITEOFFS_DATA({ commit }, id) {
        try {
            const writeoffs = await getWriteOffsData(id);
            commit('SET_WRITEOFFS_GOODS', writeoffs.products.map(i => { 
                return { ...i.product, count: i.count, product: i.product.vendor_code }
            }));
            return writeoffs;
        } catch (error) {
            console.log(error);
        }
    },
    async CREATE_NEW_WRITEOFFS({ getters }, { stock, images, manager_comment }) {
        return await createWriteOffs({ products: getters['writeoffs_goods'], stock: stock, images, manager_comment });
    },
    async SAVE_WRITEOFF({ getters }, { id, stock, images, manager_comment }) {
        return await saveWriteOffs({ products: getters['writeoffs_goods'], id, stock: stock, images, manager_comment });
    },
    async APPLY_WRITEOFFS({ commit }, id) {
        return commit('SET_STATUS_WRITEOFFS', await applyingWriteOffs(id));
    },
    EVENT_HANDLER({ commit, getters }, good) {
        if (getters['selected'](good.vendor_code)) {
            commit('REMOVE_GOOD_FROM_WRITEOFFS', good.vendor_code);
        } else {
            commit('ADD_GOOD_IN_WRITEOFFS', good);
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([ key, value ]) => {
            if (key && (value || value === false || value === 0) ) { 
                filters[key] = value;
            }
        })
        let activeFilters =  Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    writeoffs: state => state.writeoffs,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    next: state => !!state.next_page,
    busy: state => state.busy,
    shop: state => state.selectedShop,
    writeoffs_goods: state => state.writeoffs_goods,
    selected: state => id => state.writeoffs_goods.find(item => item.product === id),
    status: state => state.status,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

/* eslint-disable */
import axios from 'axios';

import store from '../store';
import router from '../router';

import token from '../utils/token';

import { responseErrorHandler, getNewAccessToken } from './auth'

const baseURL = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_HOST_TIMETRACKING;

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(
    async function(config) {
        store.dispatch('ui/startLoading', true);

        if (config._retry) {
            await getNewAccessToken();
        }

        if (token()) {
            config.headers['Authorization'] = token();
        }

        return config;
    },
    function(error) {
        const { status, data } = error.response;
        return Promise.reject({ status, data });
    }
);

instance.interceptors.response.use(
    function(response) {
        store.dispatch('ui/stopLoading', false);
        return response.data;
    },
    async function(error) {
        store.dispatch('ui/stopLoading', false);

        // if (error.response && error.response.status === 401) {
        //     store.commit('auth/REMOVE_DATA_AUTH');
        //     router.push('/');
        // }

        try {
            const res = await responseErrorHandler(error, instance);
            return res;
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                console.log({ status, data });
                return Promise.reject({ status, data });
            } else {
                return Promise.reject(err);
            }
        }
    }
);

export function createEntry(uid) {
    return instance({
        method: 'post',
        url: '/time/create_entry',
        data: {
            uid
        }
    });
}
export function updateEntry(id) {
    return instance({
        method: 'post',
        url: '/time/update_entry',
        data: {
            id
        }
    });
}
export function userBusy(id) {
    return instance(`/time/is_user_busy?uid=${id}`)
}
export function patchUserChange(data) {
    return instance({
        method: 'post',
        url: `/time/salary_paid`,
        data,
    })
}
export function getTimesheet(id, startDate, endDate, bool, salaryID) {
    if (startDate && endDate) {
        if (salaryID) {
            return instance(`/time/get_timesheet?uid=${id}&min_date=${startDate}&max_date=${endDate}&is_salary_paid=${!!bool ? bool : false}&salary_id=${salaryID}`);
        } else {
            return instance(`/time/get_timesheet?uid=${id}&min_date=${startDate}&max_date=${endDate}&is_salary_paid=${!!bool ? bool : false}`);
        }
    } else if (bool !== undefined) {
        return instance(`/time/get_timesheet?uid=${id}&is_salary_paid=${bool}`);
    } else {
        return instance(`/time/get_timesheet?uid=${id}`);
    }
}

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Зарплаты"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список зарплат</h2>
                                <p>В списке используются поля:</p>
                                <ul>
                                    <li>Имя сотрудника</li>
                                    <li>Сумма зарплаты</li>
                                    <li>Период выплаты</li>
                                    <li>Статус</li>
                                </ul>

                                <p>Изменить статус можно через выпадающий список.</p>
                                <img src="@/assets/img/help/salaries/1.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание зарплаты</h2>
                                <p>При создании зарплаты изначально открывается модальное окно с выбором сотрудника.</p>
                                <p>Есть поиск по имени, также можно активировать фильтр по группе.</p>
                                <p>После выбора пользователя нужно выбрать период зарплаты.</p>
                                <p>Для разных ролей отображаемые поля могут изменяться.</p>
                                <p>Например, для курьеров можно задать ставку по городу и за город. Можно выставить в рублях или процентах. После ввода значения таблица автоматически заполнится соответствующими числами.</p>
                                <img src="@/assets/img/help/salaries/2.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>При этом для каждого заказа отдельно можно будет задать выплату вручную.</p>
                                <img src="@/assets/img/help/salaries/3.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Для курьеров доступна таблица с перемещениями. Она выглядит аналогично.</p>
                                <p>При этом для других групп пользователей, у которых зарплата может зависеть от количества часов, вводится ставка в час.</p>
                                <img src="@/assets/img/help/salaries/4.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Также для таких пользователей выводится таблица смен. Продолжительность рабочего времени можно корректировать.</p>
                                <img src="@/assets/img/help/salaries/5.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Поле комментарий подтягивается автоматически из данных пользователя.</p>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Просмотр и редактирование зарплаты</h2>
                                <p>Пока зарплата в статусе "Ожидает подтверждения", то ее можно практически полностью редактировать. Нельзя менять сотрудника.</p>
                                <p>Если статус "Выплачено", то редактирование зарплаты невозможно.</p>
                                <p>Удалить зарплату можно через дополнительное меню в нижней части экрана.</p>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'Премии и штрафы', link: '/help/salarys-changes'}, {name: 'Сотрудники', link: '/help/users'}]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpSalaries',
    metaInfo: {
        title: 'Зарплаты - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

/* eslint-disable */
import { getEncashments, getNextPageEncashments, getEncashmentsForShop, getNextEncashmentsForShop } from '@/api/django.js'

const state = {
    encashments: [],
    busy: false,
    next:null,
    offset:5,
    limit:20,
    shopForEncashment: null
};

const mutations = {
    SET_ENCASHMENTS(state, encashments) {
        state.encashments = encashments.results;
        state.busy = false;
        state.offset = 5;
        state.next = !!encashments.next;
    },
    SET_NEXT_ENCASHMENTS(state, encashments) {
        state.encashments = state.encashments.concat(encashments.results);
        state.next = !!encashments.next;
        state.offset += 5;
        state.busy = false;
    },
    SET_SHOP_FOR_ENCASHMENT(state, shop) {
        state.shopForEncashment = shop;
    }
}

const actions = {
    async GET_ENCASHMENTS({ commit }) {
        commit('SET_ENCASHMENTS', await getEncashments())
    },
    async GET_ENCASHMENTS_FOR_SHOP({ commit }, shop) {
        commit('SET_ENCASHMENTS', await getEncashmentsForShop(shop))
    },
    async LOAD_MORE({ state, commit }) {
        try {
            if (state.next) {
                state.busy = true;
                const data = await getNextPageEncashments(state.limit, state.offset);
                commit('SET_NEXT_ENCASHMENTS', data);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async LOAD_MORE_FOR_SHOP({ state, commit }, shop) {
        try {
            if (state.next) {
                state.busy = true;
                const data = await getNextEncashmentsForShop(shop, state.offset);
                commit('SET_NEXT_ENCASHMENTS', data);
            }
        } catch (error) {
            console.log(error);
        }
    },
}

const getters = {
    encashments: state => state.encashments,
    busy: state => state.busy,
    next: state => state.next,
    shopForEncashment: state => state.shopForEncashment,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <div>
        <v-menu
            ref="goodStatisticDatepicker"
            offset-y
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <InputCustom
                    :value="formatedStatisticGoodDate"
                    label="Дата"
                    left-icon="calendar"
                    readonly
                    clearable
                    v-on="on"
                    @click:clear="filters.date = [], getGoodStatistic()"
                />
            </template>
            <v-date-picker
                v-model="filters.date"
                no-title
                range
                first-day-of-week="1"
                @input="() => {
                    if (filters.date[0] && filters.date[1]) {
                        $refs.goodStatisticDatepicker.save(filters.date);
                        getGoodStatistic();
                    }
                }"
            />
        </v-menu>
        <div class="product-writeoffs-statistic__chips">
            <div class="chips__wrap chips__wrap--scrollable hide-scrollbar">
                <Chips
                    v-for="item in shopsWithEmpty"
                    :key="item.id"
                    :active="filters.shop === item.id"
                    @click.prevent="filters.shop = item.id, getGoodStatistic()"
                >
                    {{ item.address }}
                </Chips>
            </div>
        </div>
        <div>
            <div v-show="!isLoading" class="listing-table__wrap">
                <div v-if="statistic.results.length" class="listing-table">
                    <div class="listing-table__row listing-table__row--head">
                        <div class="listing-table__col" style="width: calc(100% / 6)">№ действия</div>
                        <div class="listing-table__col" style="width: calc(100% / 6)">Действие</div>
                        <div class="listing-table__col" style="width: calc(100% / 6)">Дата</div>
                        <div class="listing-table__col" style="width: calc(100% / 6)">Мастерская</div>
                        <div class="listing-table__col" style="width: calc(100% / 6)">Изменение</div>
                        <div class="listing-table__col" style="width: calc(100% / 6)">Остаток</div>
                    </div>
                    <a
                        v-for="(item, index) in statistic.results"
                        :key="index"
                        class="listing-table__row"
                        @click.prevent="openLink(item)"
                    >
                        <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1023 ? 'width: calc(100% / 6)' : ''">
                            {{ item.code }}
                        </div>
                        <div class="listing-table__col" :style="width > 1023 ? 'width: calc(100% / 6)' : ''">
                            <div class="listing-table__col-label">Действие</div>
                            {{ item.type_action }}
                        </div>
                        <div class="listing-table__col" :style="width > 1023 ? 'width: calc(100% / 6)' : ''">
                            <div class="listing-table__col-label">Дата</div>
                            {{ item.residue_date ? moment(item.residue_date).format('DD.MM.YYYY') : '&mdash;' }}
                        </div>
                        <div class="listing-table__col" :style="width > 1023 ? 'width: calc(100% / 6)' : ''">
                            <div class="listing-table__col-label">Мастерская</div>
                            {{ item.shop_name || '&mdash;' }}
                        </div>
                        <div class="listing-table__col" :style="width > 1023 ? 'width: calc(100% / 6)' : ''">
                            <div class="listing-table__col-label">Изменение</div>
                            {{ item.product_count }}
                        </div>
                        <div class="listing-table__col" :style="width > 1023 ? 'width: calc(100% / 6)' : ''">
                            <div class="listing-table__col-label">Остаток</div>
                            {{ item.residue_count || '&mdash;' }}
                        </div>
                    </a>
                </div>
                <empty-table v-show="!statistic.results.length" />
            </div>
            <ContentPreloader v-show="isLoading" type="table" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { getProductResidueHistory } from '@/api/django';

export default {
    name: 'ProductWriteoffStatistic',
    props: {
        productId: { type: [Number, String, null], default: null }
    },
    data() {
        return {
            statistic: {
                date: [],
                results: {}
            },
            filters: {
                date: [],
                shop: null
            },
            isLoading: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            shops: 'data/shops',
        }),
        formatedStatisticGoodDate() {
            if (this.filters.date.length) {
                if (this.filters.date[0] == this.filters.date[1]) {
                    return `${this.moment(this.filters.date[0]).format('DD.MM.YYYY')}`;
                } else {
                    return `${this.moment(this.filters.date[0]).format('DD.MM.YYYY')} - ${this.moment(this.filters.date[1]).format('DD.MM.YYYY')}`;
                }
            }
            return ''
        },
        shopsWithEmpty() {
            return [{id: null, address: 'Все магазины'}, ...this.shops]
        }
    },
    mounted() {
        if (this.productId) {
            this.getGoodStatistic();
        }
    },
    methods: {
        async getGoodStatistic() {
            this.isLoading = true;
            this.filters.date.sort();
            const { data } = await getProductResidueHistory(this.productId, {
                date_start: this.filters.date[0] || null,
                date_end: this.filters.date[1] || null,
                shop: this.filters.shop
            });
            this.statistic.results = data;
            this.isLoading = false;
        },
        openLink(item) {
            let link = item.type_action === 'Заказ' ? '/orders/' :
                item.type_action === 'Поставка' ? '/storeroom/deliveries/' :
                    item.type_action === 'Списание' ? '/storeroom/offs/' :
                        item.type_action === 'Перемещение' ? '/storeroom/movement/' : 
                            item.type_action === 'Возврат' ? '/orders-returns/' : '';
            if (link) {
                window.open(link + item.code, '_blank')
            }
        },
        moment
    }
}
</script>
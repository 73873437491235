<template>
    <div class="status-select__wrap" :class="{'disabled': disabled}">
        <div
            class="status-select__input"
            :class="{ 'active': dropdown }"
            tabindex="1"
            @blur="closeDropdown"
            @click.stop.prevent="onDropdown($event)"
        >
            <Icon v-if="computedValue.icon" class="status-select__input-icon" :color="computedValue.color" size="16">
                {{ computedValue.icon }}
            </Icon>
            <span class="status-select__input-text" :style="`color: ${computedValue.color}`">
                {{ computedValue.name }}
            </span>
            <Icon v-if="!disabled" class="status-select__input-arrow" color="#8AACCD" size="16">
                down
            </Icon>
        </div>
        <transition name="fade">
            <div v-show="dropdown && Object.keys(availableStatuses).length" class="status-select__options">
                <div
                    v-for="(item, index) in availableStatuses"
                    :key="index"
                    class="status-select__options-item"
                    @click.stop.prevent="$emit('select', item.id);"
                >
                    <Icon v-if="item.icon" class="status-select__options-item-icon" :color="item.color" size="16">
                        {{ item.icon }}
                    </Icon>
                    <span class="status-select__options-item-text" :style="`color: ${item.color}`">{{ item.name }}</span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'StatusSelect',
    props: {
        value: { type: [String, Number, Boolean], default: 1 },
        order: { type: Object, default: () => {} },
        options: { type: [Array, Object], default: () => [] },
        disabled: { type: Boolean, default: false },
        mode: { type: String, default: '' },
    },
    data() {
        return {
            dropdown: false,
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        arrayFromOptions() {
            if (this.mode === 'order') {
                let arrayFromOptions = Object.keys(this.options).map((item) => {
                    return { name: this.options[item].name, id: +item, color: '', icon: '' }
                });
                arrayFromOptions.forEach(item => {
                    if (item.id === 1) {
                        item.color = '#666666';
                        item.icon = 'lightning'
                    } else if (item.id === 2) {
                        item.color = '#b66637';
                        item.icon = 'rub'
                    } else if (item.id === 3) {
                        item.color = '#b63678';
                        item.icon = 'file-minus'
                    } else if (item.id === 4) {
                        item.color = '#36b687';
                        item.icon = 'clock'
                    } else if (item.id === 5) {
                        item.color = '#399db7';
                        item.icon = 'map'
                    } else if (item.id === 6) {
                        item.color = '#8136b6';
                        item.icon = 'check'
                    } else if (item.id === 7) {
                        item.color = '#F00C29';
                        item.icon = 'close'
                    } else if (item.id === 8) {
                        item.color = '#FF6E40';
                        item.icon = 'alert-triangle'
                    } else if (item.id === 9) {
                        item.color = '#1a73e8';
                        item.icon = 'refresh'
                    }
                })
                return arrayFromOptions || []
            }
            return this.options || []
        },
        availableStatuses() {
            if (this.options && this.mode === 'order') {
                let excludes = [0, 9]; // для удаления ненужных статусов
                if (this.order.status === 1) excludes.push(3, 4, 5, 6);
                if (this.authUserGroup === 'florist') excludes.push(1, 2, 3, 5, 6, 7, 8, 9);
                if (this.authUserGroup === 'courier') excludes.push(1, 2, 3, 4, 7, 8, 9);
                if (this.authUserGroup === 'courier' && this.value === 4) excludes.push(6);
                if (this.authUserGroup === 'courier' && this.value === 5) excludes.push(5);
                if (this.order.pickup) excludes.push(5);

                let filteredStatuses = this.arrayFromOptions.filter((item) => !excludes.includes(item.id));
                // Статус "отменен" удаляем из списка и переносим в конец.
                if (filteredStatuses.find((item) => item.id === 7)) {
                    filteredStatuses = filteredStatuses.filter((item) => item.id !== 7);
                    filteredStatuses.push({ name: 'Отменен', id: 7, color: '#F00C29', icon: 'close' });
                }

                return filteredStatuses
            } else if (this.options && this.mode === 'transfers' && this.authUserGroup === 'courier') {
                return this.arrayFromOptions.filter(i => i.id === 4) || []
            } else {
                return this.arrayFromOptions || [];
            }
        },
        computedValue() {
            let find = this.arrayFromOptions.find(item => item.id === this.value);
            return find || {};
        }
    },
    methods: {
        async onDropdown() {
            if (!this.disabled) {
                this.dropdown = !this.dropdown;
            }
        },
        async closeDropdown() {
            await this.$nextTick();
            this.dropdown = false;
        },
    }
};
</script>

<template>
    <div id="layout" class="app-layout">
        <the-navigation v-if="!indexPage && !unavailablePage" />
        <main
            class="app-content"
            :class="{'auth': !indexPage && !unavailablePage}"
            :style="$route.name === 'chat' ? 'display:flex' : ''"
            @click="hideSidebars"
        >
            <transition name="fade">
                <div
                    v-if="(isOpenSidebar || $store.getters['ui/isOpenFilter']) && width <= 899"
                    class="content-overlay"
                    v-touch="{ left: () => closeNavigation() }"
                    @click.stop="closeNavigation(), closeFilter()"
                />
            </transition>
            <slot />
        </main>
        <the-subscription-notification v-if="!indexPage && !unavailablePage" />
        <Filters v-if="!indexPage && !unavailablePage" />
        <the-modals />
        <SystemNotifications v-if="!indexPage && !unavailablePage" />
        <ChatLostConnection />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TheNavigation from '@/layout/TheNavigation';
import Filters from '@/layout/Filters';
import SystemNotifications from '@/components/layout/SystemNotifications';
import TheSubscriptionNotification from '@/layout/TheSubscriptionNotification';
import ChatLostConnection from '@/components/app/chat/ChatLostConnection';
import TheModals from '@/layout/TheModals';

export default {
    name: 'TheLayout',
    components: {
        Filters,
        TheNavigation,
        TheModals,
        TheSubscriptionNotification,
        ChatLostConnection,
        SystemNotifications
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            isOpenSidebar: 'ui/isOpenSidebar',
        }),
        indexPage() {
            return this.$route.path === '/' || this.$route.path === '/registration';
        },
        unavailablePage() {
            return this.$route.path === '/unavailable';
        }
    },
    created() {
        this.connect();
    },
    methods: {
        connect() {
            if (!this.indexPage && !this.unavailablePage) {
                this.$store.dispatch('chat/INIT');
                this.$store.dispatch('autoNotification/CONNECT');
            }
        },
        hideSidebars() {
            this.$store.commit('ui/CLOSE_FILTER');
        },
        closeNavigation() {
            if (this.isOpenSidebar) {
                this.$store.commit('ui/CLOSE_SIDEBAR');
                this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
            }
        },
        closeFilter() {
            this.$store.commit('ui/CLOSE_FILTER');
            this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
        }
    }
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Рассылка ${$route.params.ID}` : 'Новая рассылка'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only"
                @click:left="$router.back()"
                @click:save="saveMailing"
            />
            <div class="page-container">
                <div class="page__info-group">
                    <Row>
                        <Col>
                            <InputSelect
                                v-model="mailing.channel"
                                :options="channels"
                                label="Канал"
                                input-value="value"
                                :error="error && error.channel ? 'Выберите канал отправки' : ''"
                                :disabled="!!$route.params.ID"
                            />
                        </Col>
                        <Col v-if="mailing.channel !== 'SMS' && mailing.channel !== 'Chat'">
                            <InputCustom
                                v-model="mailing.title"
                                label="Заголовок"
                                :error="error && error.title && error.title[0] === 'Field may not be null.' ? 'Поле не может быть пустым' :
                                    error && error.title ? 'Ошибка поля при сохранении' : ''"
                            />
                        </Col>
                        <Col>
                            <TextareaCustom
                                v-model="mailing.text"
                                label="Сообщение"
                                :messages="mailing.channel === 'SMS' ? `Введено: ${smsData.charsUsed}/${totalPartsChars} символов. Сообщение: ${smsData.smsParts}` : ''"
                                :disabled="!!$route.params.ID"
                                :error="error && error.text && error.text[0] === 'Field may not be null.' ? 'Поле не может быть пустым' :
                                    error && error.text ? 'Ошибка поля при сохранении' : '' "
                                @input="getSMSLength"
                            />
                        </Col>
                        <Col v-if="!$route.params.ID">
                            <InputSelect
                                v-model="scheduledMode"
                                :options="[{ value: null, name: 'Отправить сейчас' }, { value: true, name: 'Отложить отправку' }]"
                                label="Запланировать"
                                input-value="value"
                            />
                        </Col>
                        <template v-if="!$route.params.ID && scheduledMode">
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="notificationDate"
                                    offset-y
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            :value="formattedDate"
                                            label="Дата доставки"
                                            left-icon="calendar"
                                            readonly
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker
                                        :value="mailing.scheduled"
                                        no-title
                                        first-day-of-week="1"
                                        :min="moment().format('YYYY-MM-DD')"
                                        @input="setScheduledDate($event)"
                                    />
                                </v-menu>
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="notificationTime"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            :value="mailing.scheduled ? moment(mailing.scheduled).format('HH:mm') : ''"
                                            label="Время отправки"
                                            readonly
                                            v-on="on"
                                        />
                                    </template>
                                    <v-time-picker
                                        :value="mailing.scheduled ? moment(mailing.scheduled).format('HH:mm') : ''"
                                        format="24hr"
                                        full-width
                                        scrollable
                                        @input="setScheduledTime($event)"
                                        @click:minute="$refs.notificationTime.save()"
                                    />
                                </v-menu>
                            </Col>
                        </template>
                        <Col v-if="$route.params.ID">
                            <InputCustom
                                :value="scheduledMode ? moment(mailing.scheduled).format('DD.MM.YYYY HH:mm') : moment(mailing.created).format('DD.MM.YYYY HH:mm')"
                                label="Время отправки"
                                disabled
                            />
                        </Col>
                        <Col v-if="$route.params.ID">
                            <InputCustom
                                :value="mailing.is_sent || mailing.sended ? 'Отправлено' : 'Не отправлено'"
                                label="Статус"
                                disabled
                            />
                        </Col>
                    </Row>
                    <template v-if="!$route.params.ID && !users_list.length">
                        <ClientsFilterHorizontal
                            :style="loadingForFilter ? 'opacity: .3; pointer-events:none;' : ''"
                            @loading="loadingForFilter = $event"
                        />
                    </template>
                    <Row>
                        <Col>
                            <div style="font-size: 15px" :style="loadingForFilter ? 'opacity: .3;' : ''">
                                Количество выбранных пользователей: {{ numberFormat(clientsIDs.length || users_list.length) }}
                            </div>
                        </Col>
                        <Col v-if="!clientsIDs.length">
                            <div v-if="users_list && users_list.length" class="listing-table">
                                <div class="listing-table__row listing-table__row--head">
                                    <div class="listing-table__col" style="width: calc((100% - 70px) / 3)">Имя</div>
                                    <div class="listing-table__col" style="width: calc((100% - 70px) / 3)">Телефон</div>
                                    <div class="listing-table__col" style="width: calc((100% - 70px) / 3)">Посл. авториз</div>
                                    <div v-if="!$route.params.ID" class="listing-table__col" style="width: 70px" />
                                </div>
                                <div v-for="item in users_list" :key="item.id" class="listing-table__row">
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 70px) / 3)' : ''">
                                        {{ item.last_name }} {{ item.first_name }}
                                    </div>
                                    <div
                                        class="listing-table__col listing-table__col--mobile-inline"
                                        :style="width > 1024 ? 'width: calc((100% - 70px) / 3)' : ''"
                                    >
                                        {{ item.phone }}
                                    </div>
                                    <div
                                        v-if="width > 1024"
                                        class="listing-table__col listing-table__col--mobile-inline"
                                        :style="width > 1024 ? 'width: calc((100% - 70px) / 3)' : ''"
                                    >
                                        {{ item.last_login ? moment(item.last_login).format('DD.MM.YYYY HH:mm') : '&mdash;' }}
                                    </div>
                                    <div
                                        v-if="!$route.params.ID"
                                        class="listing-table__col listing-table__col--mobile-inline ml-a"
                                        :style="width > 1024 ? 'width: 70px' : ''"
                                    >
                                        <Button text compact @click.stop="$store.commit('notifications/REMOVE_USER_FROM_USERS_LIST', item)">
                                            <Icon color="#0D63F3">trash</Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';

import { removeNotification, editNotification } from '@/api/notifications';
import { getClientsIDs } from '@/api/django';
import { getNotificationsChanells, createNotificationCustomer, getNotificationData } from '@/api/mailings';

import { numberFormat } from '@/utils/format';

import ClientsFilterHorizontal from '@/components/app/users/ClientsFilterHorizontal';
import UsersModal from '@/components/app//users/modal/UsersModal';

export default {
    name: 'TheMailingsCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Рассылка ${this.$route.params.ID}` : 'Новая рассылка' }
    },
    components: {
        ClientsFilterHorizontal
    },
    data() {
        return {
            mailing: {
                channel: 'SMS',
                title: '',
                text: '',
                scheduled: this.moment().format(),
                receivers: {
                    type: 'user',
                    values: []
                },
                type: 'manual',
                receivers_group: 'user',
            },
            smsData: {
                smsParts: 1,
                smsChars: 160,
                charsUsed: 0,
                unicodeType: '7bit'
            },
            scheduledMode: null,
            error: {},
            channels: [],
            loadingForFilter: false,
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            users_list: 'notifications/users_list',
            preloader: 'ui/preloader',
            clientsForNotification: 'clients/clientsForNotification',
            clientsIDs: 'clients/clientsIDs',
            authUser: 'auth/user',
        }),
        formattedDate() {
            if (this.mailing.scheduled) {
                return moment(this.mailing.scheduled).format("DD.MM.YYYY");
            }
            return moment().format("DD.MM.YYYY");
        },
        totalPartsChars() {
            return this.smsData.smsChars * this.smsData.smsParts;
        }
    },
    async mounted() {
        // При просмотре рассылки отрабатывает первая ветка
        // При создании рассылки есть 3 варианта
        // - рассылка создается со страницы Рассылки. Тогда отработает 2 ветка условий
        // - рассылка создается со страницы Клиенты, выбраны все юзеры. Отрабатывает 3 ветка, но не выводится список пользователей и фильтры, потому что заполнена переменная clientsIDs
        // - рассылка создается со страницы Клиенты, выбрана часть юзеров. Отрабатывает нижняя ветка, выводится список пользователей, потому что в clientsIDs пусто
        this.$store.commit('ui/SET_PRELOADER', true);
        this.$store.commit('clients/CLEAR_FILTERS_STATE');

        this.channels = await getNotificationsChanells();

        this.$store.dispatch('clients/GET_FILTER_MIN_MAX_VALUES');

        if (this.$route.params.ID) {
            const res = await getNotificationData(this.$route.params.ID);
            this.mailing = res;
            this.$store.commit('clients/SET_CLIENTS_IDS', res.users);
        } else if (!this.$route.params.ID && !this.clientsForNotification.length) {
            const ids = await getClientsIDs();
            this.$store.commit('clients/SET_CLIENTS_IDS', ids);
        } else {
            this.$store.commit('notifications/SET_USERS_LIST', this.clientsForNotification);
        }
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    beforeDestroy() {
        this.$store.commit('clients/CLEAR_FILTERS_STATE');
        this.$store.commit('notifications/CLEAR_USERS_LIST');
        this.$store.commit('clients/SET_CLIENTS_FOR_NOTIFICATION', []);
        this.$store.commit('clients/SET_CLIENTS_IDS', []);
    },
    methods: {
        saveMailing() {
            if (this.$route.params.ID) {
                this.editMailing();
            } else {
                this.createMailing();
            }
        },
        async createMailing() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const data = {
                    channel: this.mailing.channel,
                    title: this.mailing.title,
                    text: this.mailing.text,
                    send_date: this.mailing.scheduled
                }

                if (this.clientsIDs.length) {
                    data.users = this.clientsIDs;
                } else {
                    data.users = this.users_list.map((item) => item.id);
                }

                console.log(data);
                // await createNotificationCustomer(data);

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Рассылка успешно создана', type: 'success' })
                this.$router.push('/mailings');
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При создании рассылки произошла ошибка', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async editMailing() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const data = {
                    title: this.mailing.title,
                    text: this.mailing.text,
                };

                // await editNotification(this.$route.params.ID, data)

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Рассылка успешно обновлена', type: 'success' })
                this.$router.push('/mailings');
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При редактировании рассылки произошла ошибка', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async deleteMailing() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                // await removeNotification(this.$route.params.ID)

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Рассылка успешно удалена', type: 'success' })
                this.$router.push('/mailings');
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При удалении рассылки произошла ошибка', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        setScheduledDate(event) {
            let scheduledTime = this.mailing.scheduled.split('T')[1];
            this.mailing.scheduled = this.moment(`${event}T${scheduledTime}`).format('YYYY-MM-DDTHH:mm')
        },
        setScheduledTime(event) {
            let scheduledDate = this.mailing.scheduled.split('T')[0];
            this.mailing.scheduled = this.moment(`${scheduledDate}T${event}`).format('YYYY-MM-DDTHH:mm')
        },
        numberFormat(value) {
            if (value) return numberFormat(value);
            return value || 0;
        },
        getSMSLength() {
            // Взято с https://codepen.io/thirdtiu/pen/XMXxoO. Можно доработать и добавить дополнительные счетчики в textarea
            let charset7bit = ['@', '£', '$', '¥', 'è', 'é', 'ù', 'ì', 'ò', 'Ç', '\n', 'Ø', 'ø', '\r', 'Å', 'å', 'Δ', '_', 'Φ', 'Γ', 'Λ', 'Ω', 'Π', 'Ψ', 'Σ', 'Θ', 'Ξ', 'Æ', 'æ', 'ß', 'É', ' ', '!', '"', '#', '¤', '%', '&', '\'', '(', ')', '*', '+', ',', '-', '.', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';', '<', '=', '>', '?', '¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ä', 'Ö', 'Ñ', 'Ü', '§', '¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'ä', 'ö', 'ñ', 'ü', 'à'];

            let charset7bitext = ['\f', '^', '{', '}', '\\', '[', '~', ']', '|', '€'];
            let charsArr = this.mailing.text.split('');
            let charsUsed = 0;
            let part = 1;
            let parts = 1;
            let i = 0;

            for (i = 0; i < charsArr.length; i += 1) {
                if (charset7bit.indexOf(charsArr[i]) >= 0) {
                    charsUsed += 1;
                    this.smsData.unicodeType = '7bit';
                } else if (charset7bitext.indexOf(charsArr[i]) >= 0) {
                    charsUsed += 2;
                    this.smsData.unicodeType = '7bit';
                } else {
                    this.smsData.unicodeType = '16bit';
                    charsUsed = charsArr.length;
                    break;
                }
            }

            if (this.smsData.unicodeType === '7bit') {
                if (charsUsed > 160) {
                    parts = Math.ceil(charsUsed / 153);
                    let part_chars_used = 7;
                    this.smsData.smsChars = 153;

                    for (i = 0; i < charsArr.length; i++) {
                        if (part_chars_used + 1 > 160) {
                            part = part + 1;
                            part_chars_used = 7;
                        }
                        if (charset7bitext.indexOf(charsArr[i]) >= 0 && part_chars_used + 2 > 160) {
                            part = part + 1;
                            part_chars_used = 7;
                        }
                        if (charset7bitext.indexOf(charsArr[i]) === -1) {
                            part_chars_used = part_chars_used + 1;
                        } else {
                            part_chars_used = part_chars_used + 2;
                        }
                    }
                } else {
                    this.smsData.smsChars = 160;
                }
            } else {
                if (charsUsed > 70) {
                    parts = Math.ceil(charsUsed / 67);
                    let part_chars_used = 3;
                    this.smsData.smsChars = 67;

                    for (i = 0; i < charsArr.length; i++) {
                        if (part_chars_used + 1 > 70) {
                            part = part + 1;
                            part_chars_used = 3;
                        }
                        part_chars_used = part_chars_used + 1;
                    }
                } else {
                    this.smsData.smsChars = 70;
                }
            }

            if (charsUsed === 0) {
                this.smsData.smsParts = 1;
                this.smsData.smsChars = 160;
                this.smsData.unicodeType = '7bit';
            }

            this.smsData.charsUsed = charsUsed;
            this.smsData.smsParts = parts;
        },
        moment
    }
};
</script>

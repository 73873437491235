<template>
    <div class="universal-modal__component">
        <UniversalModalHeader
            title="История баланса"
            @click:right="$emit('closeModal')"
        />
        <div class="universal-modal__content" v-infinite-scroll="loadMoreHistory" infinite-scroll-disabled="busy" infinite-scroll-distance="300">
            <div v-show="!preloader" class="listing-table__wrap">
                <div v-if="shopBalanceHistory.results.length" class="listing-table">
                    <div class="listing-table__row listing-table__row--head">
                        <div class="listing-table__col" style="width: 25%">Изменение</div>
                        <div class="listing-table__col" style="width: 25%">Дата</div>
                        <div class="listing-table__col" style="width: 25%">Сумма</div>
                        <div class="listing-table__col" style="width: 25%">Баланс</div>
                    </div>
                    <div
                        v-for="item in shopBalanceHistory.results"
                        :key="item.id"
                        class="listing-table__row"
                    >
                        <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 25%' : ''">
                            {{ item.name === 'encasement' ? 'Инкассация №' : 'Заказ №' }}{{ item.id }}
                        </div>
                        <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                            <div class="listing-table__col-label">Дата</div>
                            {{ formatDate(item.date, 'hours-ru') }}
                        </div>
                        <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                            <div class="listing-table__col-label">Сумма</div>
                            <span :style="item.name === 'encasement' ? 'color:#EF6A6E' : 'color:#36B687'">
                                {{ item.name === 'encasement' ? '-' : '' }}{{ formatNumber(item.amount) }} ₽
                            </span>
                        </div>
                        <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                            <div class="listing-table__col-label">Баланс</div>
                            {{ formatNumber(item.computed_balance) }} ₽
                        </div>
                    </div>
                    <div class="listing-table__row listing-table__row--preloader">
                        <div class="listing-table__col">
                            <Spinner v-show="busy && shopBalanceHistory.next" />
                        </div>
                    </div>
                </div>
                <div v-else>История пуста</div>
            </div>
            <ContentPreloader v-show="preloader" type="table" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getShopBalanceHistory, getNextShopBalanceHistory } from '@/api/django';
import { date, numberFormat } from '@/utils/format';
import Spinner from '@/components/app/Spinner';

export default {
    name: 'ShopBalanceModal',
    components: {
        Spinner
    },
    data() {
        return {
            shopBalanceHistory: {
                results: {},
                next: false,
                offset: 5,
            },
            busy: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            const balance = await getShopBalanceHistory(this.$route.params.ID);
            this.shopBalanceHistory = balance;
            this.shopBalanceHistory.offset = 20;
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        async loadMoreHistory() {
            if (this.shopBalanceHistory.next) {
                this.busy = true;
                const res = await getNextShopBalanceHistory(this.$route.params.ID, this.shopBalanceHistory.offset);
                this.shopBalanceHistory.results = this.shopBalanceHistory.results.concat(res.results);
                this.shopBalanceHistory.next = res.next;
                this.shopBalanceHistory.offset += 20;
                this.busy = false;
            }
        },
        formatNumber(num) {
            if (num) {
                return numberFormat(num);
            } else {
                return 0;
            }
        },
        formatDate(value, type) {
            return date(new Date(value), type);
        },
    }
}
</script>

<template>
    <div class="side-filter__content">
        <div class="side-filter__content-group-list">
            <div class="side-filter__content-group">
                <v-menu ref="date" offset-y :close-on-content-click="false" transition="scale-transition" max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                        <InputCustom
                            :value="datepickerFormatted"
                            label="Дата"
                            placeholder="Выберите период"
                            left-icon="calendar"
                            clearable
                            readonly
                            without-preloader
                            v-on="on"
                            @click:clear="datepicker = [], setFilterDate()"
                        />
                    </template>
                    <v-date-picker
                        v-model="datepicker"
                        no-title
                        range
                        first-day-of-week="1"
                        @input="() => {
                            if (datepicker[0] && datepicker[1]) {
                                $refs.date.save(datepicker);
                                setFilterDate();
                            }
                        }"
                    />
                </v-menu>
            </div>
            <div class="side-filter__content-group">
                <InputSelect
                    :value="filters.shop"
                    :options="[{ id: null, address: 'Все', type_icon: 'store' }, ...shops.results || []]"
                    label="Магазин"
                    input-text="address"
                    input-icon="type_icon"
                    icon="store"
                    without-preloader
                    @input="filterTransfers('shop', $event)"
                />
            </div>
            <div class="side-filter__content-group">
                <InputSelect
                    :value="filters.direction"
                    :options="options"
                    label="Перемещения"
                    input-value="slug"
                    without-preloader
                    @input="filterTransfers('direction', $event)"
                />
            </div>
            <div class="side-filter__content-group">
                <Button
                    block compact text
                    icon-left="file-minus"
                    @click.prevent="isShowDownloadModal = true"
                >
                    Скачать перемещения в xls
                </Button>
            </div>
        </div>

        <MiniModal
            v-model="isShowDownloadModal"
            title="Вы уверены, что хотите скачать файл?"
            additional-btn-text="Отмена"
            main-btn-text="Скачать"
            @additionalBtnClick="isShowDownloadModal = false"
            @mainBtnClick="downloadFile"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import moment from 'moment'

import { downloadStock } from '@/api/stock'

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'TransfersFilter',
    components: { MiniModal },
    data() {
        return {
            datepicker: [],
            options: [
                { slug: null, name: 'Все' },
                { slug: 'incoming', name: 'Входящие' },
                { slug: 'outgoing', name: 'Исходящие' }
            ],
            isShowDownloadModal: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            filters: 'transfers/filters',
            activeFilters: 'transfers/activeFilters',
            shops: 'shops/shops',
            authUser: 'auth/user',
        }),
        datepickerFormatted() {
            if (this.datepicker.length > 1) {
                return this.datepicker.map(i => this.moment(i).format('DD.MM.YYYY')).join(' - ')
            }
            return ''
        }
    },
    mounted() {
        if (this.filters.date_received_from && this.filters.date_received_to) {
            this.datepicker = [this.filters.date_received_from, this.filters.date_received_to]
        }
    },
    methods: {
        async filterTransfers(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('transfers/GET_ALL_TRANSFERS', { field, value });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async setFilterDate() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.datepicker.sort();

                this.$store.commit('transfers/SET_FILTER', { field: 'date_received_from', value: this.datepicker[0] || null })
                this.$store.commit('transfers/SET_FILTER', { field: 'date_received_to', value: this.datepicker[1] || null })
                await this.$store.dispatch('transfers/GET_ALL_TRANSFERS');
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async downloadFile() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.isShowDownloadModal = false;

                const filters = await this.$store.dispatch('transfers/prepareFilters');
                for (let key in filters) {
                    if (!this.activeFilters.includes(key)) {
                        delete filters[key]
                    }
                }

                const file = await downloadStock({ page: 'transfers', ...filters});
                const fileUrl = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                link.download = 'Перемещения.xlsx';
                link.click();
            } catch (e) {
                console.log(e)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки файла', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        moment
    }
}
</script>

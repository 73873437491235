<template>
    <div id="order-tutorial__step-3" class="products-categories__wrap">
        <div ref="categoriesList" class="products-categories__list">
            <template v-for="category in filteredCategories">
                <ProductCategoriesItem
                    :key="category.id"
                    :category="category"
                    :editable="allowEditCategories"
                    :is-active="category.id === activeCategory"
                    @click.prevent="$emit('changeCategory', category)"
                />
                <template v-if="category.childrens.length">
                    <template v-for="child1 in category.childrens">
                        <ProductCategoriesItem
                            :key="child1.id"
                            :category="child1"
                            :level="2"
                            :is-opened="openedCategory === child1.id"
                            :editable="allowEditCategories"
                            :is-active="child1.id === activeCategory"
                            @click:expand="openedCategory = openedCategory !== $event ? $event : null"
                            @click.prevent="$emit('changeCategory', child1)"
                        />
                        <template v-if="child1.childrens.length && openedCategory === child1.id">
                            <ProductCategoriesItem
                                v-for="child2 in child1.childrens"
                                :key="child2.id"
                                :category="child2"
                                :level="3"
                                :editable="allowEditCategories"
                                :is-active="child2.id === activeCategory"
                                @click.prevent="$emit('changeCategory', child2)"
                            />
                        </template>
                    </template>
                </template>
            </template>
        </div>

        <a
            v-if="allowEditCategories"
            href="/categories/create"
            class="action-btn"
            @click.prevent="addNewCategory"
        >
            <Icon color="#0D63F3">folder-plus</Icon>
            Добавить категорию
        </a>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ProductCategoriesItem from '@/components/app/goods/ProductCategoriesItem'

export default {
    name: 'ProductsCategories',
    components: { ProductCategoriesItem },
    props: {
        offsetHeight: { type: [Number, String], default: 310 },
        manage: { type: Boolean, default: false },
        activeCategory: { type: [Number, null], default: null },
        visibleCategoriesType: { type: String, default: 'all' } // варианты: 'all', 'bouquet', 'equipment'
    },
    data() {
        return {
            openedCategory: null,
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            categories: 'data/categories',
            authUser: 'auth/user',
        }),
        filteredCategories() {
            if (this.visibleCategoriesType === 'bouquet') {
                return this.categories.filter(i => i.type === 'bouquet')
            } else if (this.visibleCategoriesType === 'equipment') {
                return this.categories.filter(i => i.type === 'equipment')
            }
            return this.categories
        },
        allowEditCategories() {
            return !!(!this.authUser.read_only && ['supervisor', 'administrators', 'superadministrators'].includes(this.group) && this.manage);
        },
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    mounted() {
        this.calculateCategoriesHeight();
        window.addEventListener('resize', this.calculateCategoriesHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calculateCategoriesHeight);
    },
    methods: {
        addNewCategory() {
            this.$router.push(`/categories/create`);
            this.$store.dispatch('ui/toggle_opened_modals_count', 'close')
        },
        calculateCategoriesHeight() {
            if (this.width > 699 && this.$refs.categoriesList) {
                let maxHeight = document.documentElement.clientHeight - this.offsetHeight; // offsetHeight - включает отступы, высоту шапки, поиска, navbar и кнопки "Добавить категорию"
                this.$refs.categoriesList.style.maxHeight = `${maxHeight}px`;
            }
        }
    }
}
</script>

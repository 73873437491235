/* eslint-disable */
import {
    getMotivations
} from '@/api/django';

const state = {
    motivations: [],
    motivationsProductsList: [],
    filters: {
        limit: 20,
        offset: 0,
        shop: null,
    },
    activeFilters: [],
    next: '',
    busy: false,

};

const mutations = {
    SET_MOTIVATIONS(state, motivations) {
        state.motivations = motivations.results;
        state.next = motivations.next;
        state.filters.offset = 20;
    },
    SET_NEXT_PAGE(state, { results, next }) {
        state.motivations.push(...results);
        state.next = next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    ADD_GOOD_TO_PRODUCTS_LIST(state, good) {
        state.motivationsProductsList.push({ product: {...good}, percent: 5 });
    },
    REMOVE_GOOD_FROM_PRODUCTS_LIST(state, good) {
        state.motivationsProductsList = state.motivationsProductsList.filter(item => item.product.vendor_code !== good.vendor_code );
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            shop: null,
        }
        state.next = false
    },
    SET_PRODUCTS_LIST(state, data) {
        state.motivationsProductsList = data;
    }
};

const actions = {
    async GET_MOTIVATIONS({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;
            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;
            let filters = await dispatch('prepareFilters');

            const data = await getMotivations(filters);
            commit('SET_MOTIVATIONS', data);
            state.busy = false;
        } catch (error) {
            console.log(error);
        }
    },
   async GET_NEXT_PAGE({ state, commit, dispatch }) {
        try {
            if (state.next && !state.busy) {
                state.busy = true;
                let filters = await dispatch('prepareFilters');
                commit('SET_NEXT_PAGE', await getMotivations(filters));
            }
        } catch (error) {
            console.log(error);
        }
    },
    SET_PRODUCTS({ commit, getters }, good) {
        if (getters['pickedProducts'](good.vendor_code)) {
            commit('REMOVE_GOOD_FROM_PRODUCTS_LIST', good);
        } else {
            commit('ADD_GOOD_TO_PRODUCTS_LIST', good)
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([key, value]) => {
            if (key && (value || value === false || value === 0)) {
                filters[key] = value;
            }
        })
        let activeFilters = Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    motivations: state => state.motivations,
    next: state => !!state.next_page,
    busy: state => state.busy,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    motivationsProductsList: state => state.motivationsProductsList,
    pickedProducts: state => id => !!state.motivationsProductsList.find(good => good.product.vendor_code === id)
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

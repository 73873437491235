<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Цветочная подписка №${$route.params.ID}` : 'Создание подписки'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only && !$route.params.ID"
                @click:left="$router.push('/flower-subscriptions')"
                @click:save="saveSubscription"
            />
            <div class="tabs__list">
                <TabItem :active="activeTab === 0" @click="activeTab = 0">
                    Основное
                </TabItem>
                <TabItem v-if="$route.params.ID" :active="activeTab === 1" @click="activeTab = 1">
                    Заказы
                </TabItem>
                <TabItem v-if="$route.params.ID" :active="activeTab === 2" @click="activeTab = 2">
                    Периоды
                </TabItem>
            </div>
            <div class="page-container page-container--with-tabs">
                <div v-show="activeTab === 0" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <div class="page__info-group">
                                <Row>
                                    <Col :cols="12">
                                        <InputCustom
                                            v-model="subscription.amount"
                                            label="Сумма"
                                            type="number"
                                            :error="error.amount || null"
                                            @input="error.amount = ''"
                                        />
                                    </Col>
                                </Row>
                                <div class="chips__wrap mt-4">
                                    <Chips
                                        v-for="day in daysOfWeekOptions"
                                        :key="day.id"
                                        :active="subscription.weekday === day.id"
                                        @click="subscription.weekday = day.id"
                                    >
                                        {{ day.label }}
                                    </Chips>
                                </div>
                                <div v-if="error.weekday" class="mx-2" style="color: #ef6a6e">
                                    {{ error.weekday }}
                                </div>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Предпочтения</h2>
                                <Row>
                                    <Col :cols="width > 1023 ? 6 : 12">
                                        <InputSelect
                                            v-model="subscription.desired_colors"
                                            :options="colorsOptions"
                                            label="Цвета"
                                            :input-value="'name'"
                                            multiple
                                        />
                                    </Col>
                                    <Col :cols="width > 1023 ? 6 : 12">
                                        <InputSelect
                                            v-model="subscription.desired_categories"
                                            :options="categoriesOptions"
                                            label="Цветы"
                                            :input-value="'name'"
                                            multiple
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Заказчик</h2>
                                <Row>
                                    <Col :cols="width > 1023 ? 6 : 12">
                                        <InputCustom
                                            v-model="subscription.user.first_name"
                                            label="Имя заказчика"
                                            :right-icon="subscription.user.id ? 'new-window': null"
                                            @click:right="openLink(`/clients/${subscription.user.id}`)"
                                        />
                                    </Col>
                                    <Col :cols="width > 1023 ? 6 : 12">
                                        <InputCustom
                                            v-model="subscription.user.phone"
                                            label="Телефон заказчика"
                                            :hint="searchUserHint"
                                            left-icon="phone"
                                            :disabled-left-icon="!subscription.user.phone"
                                            :right-icon="subscription.user.phone ? 'whatsapp' : null"
                                            :error="error.user.phone ? error.user.phone : ''"
                                            @click:left="phoneCall(subscription.user.phone)"
                                            @click:right="openLink(`https://wa.me/${subscription.user.phone}`)"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Получатель</h2>
                                <Row>
                                    <Col :cols="width > 1023 ? 6 : 12">
                                        <InputCustom
                                            v-model="subscription.receiver_name"
                                            label="Имя получателя"
                                            :error="error.receiver_name || null"
                                            @input="error.receiver_name = ''"
                                        />
                                    </Col>
                                    <Col :cols="width > 1023 ? 6 : 12">
                                        <InputCustom
                                            v-model="subscription.receiver_phone"
                                            label="Телефон получателя"
                                            left-icon="phone"
                                            :disabled-left-icon="!subscription.receiver_phone"
                                            :error="error.receiver_phone || null"
                                            @input="error.receiver_phone = ''"
                                            @click:left="phoneCall(subscription.receiver_phone)"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Доставка</h2>
                                <Row>
                                    <Col :cols="12">
                                        <InputCustom
                                            v-model="subscription.receiver_address"
                                            label="Адрес доставки полностью"
                                            hint="Указывайте город и адрес полностью"
                                            :error="error.receiver_address || null"
                                            @input="error.receiver_address = ''"
                                            @change="getAddressCoords"
                                        />
                                    </Col>
                                    <Col :cols="12">
                                        <TextareaCustom v-model="subscription.customer_comment" label="Комментарий" />
                                    </Col>
                                </Row>
                                <Row v-if="$route.params.ID">
                                    <Col :cols="width > 1023 ? 6 : 12">
                                        <InputCustom
                                            :value="subscription.next_delivery_date ? moment(subscription.next_delivery_date).format('DD.MM.YYYY') : 'Отсутствует'"
                                            label="Дата следующей доставки"
                                            readonly
                                        />
                                    </Col>
                                    <Col :cols="width > 1023 ? 6 : 12">
                                        <InputCustom
                                            :value="subscription.last_delivery_date ? moment(subscription.last_delivery_date).format('DD.MM.YYYY') : 'Отсутствует'"
                                            label="Дата последней доставки"
                                            readonly
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div class="page-small-column page-small-column--mobile-top">
                            <div class="checkbox-group">
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="subscription.is_active"
                                        label="Активна"
                                        :disabled="!$route.params.ID"
                                        @change="subscription.is_active = !subscription.is_active"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="$route.params.ID" v-show="activeTab === 1" class="tabs__content">
                    <div v-if="subscription.orders.length" class="page__info-group">
                        <div class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                <div class="listing-table__col pl-0" style="width: calc((100% - 112px) / 3);">№ заказа</div>
                                <div class="listing-table__col" style="width: calc((100% - 112px) / 3);">Дата доставки</div>
                                <div class="listing-table__col" style="width: calc((100% - 112px) / 3);">Адрес доставки</div>
                            </div>
                            <div v-for="item in subscription.orders" :key="item.visible_id" class="listing-table__row">
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image listing-table__image--with-link">
                                        <img :src="item.florist_photo ? item.florist_photo : require('@/assets/img/flower.svg')" alt="">
                                        <a href="#" class="listing-table__image-link" @click.stop.prevent="openLink(`/orders/${item.visible_id}`)">
                                            <v-icon color="primary">mdi-open-in-new</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1024 ? 'width: calc((100% - 112px) / 3);' : ''"
                                >
                                    №{{ item.visible_id }}
                                </div>

                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 3);' : ''">
                                    <div class="listing-table__col-label">Дата доставки</div>
                                    {{ moment(item.delivery_date).format('DD.MM.YYYY') }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 3);' : ''">
                                    {{ item.receiver_address }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="$route.params.ID" v-show="activeTab === 2" class="tabs__content">
                    <div v-if="subscription.periods.length" class="page__info-group">
                        <div class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 33%">Дата начала периода</div>
                                <div class="listing-table__col" style="width: 33%">Дата конца периода</div>
                                <div class="listing-table__col" style="width: 33%">Статус</div>
                            </div>
                            <div v-for="item in subscription.periods" :key="item.id" class="listing-table__row">
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                    <div class="listing-table__col-label">Начало</div>
                                    {{ item.date_from ? moment(item.date_from).format('DD.MM.YYYY') : '-' }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                    <div class="listing-table__col-label">Конец</div>
                                    {{ item.date_to ? moment(item.date_to).format('DD.MM.YYYY') : '-' }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                    <div class="listing-table__col-label">Статус</div>
                                    <span :style="{'color': item.is_paid ? '#36B687' : '#EF6A6E'}">
                                        {{ item.is_paid ? 'Оплачен' : 'Не оплачен' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmDeleteModal"
            :title="`Удалить подписку №${ $route.params.ID }?`"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteModal = false"
            @mainBtnClick="deleteSubscription"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'
import { geocode } from 'esri-leaflet-geocoder';

import {
    getFlowerSubscription,
    getFlowerSubscriptionSettings,
    getUserByPhone,
    saveFlowerSubscription,
    calculateDeliveryPrice
} from "@/api/django";

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: "TheFlowerSubscriptionsCreate",
    components: { MiniModal },
    metaInfo() {
        return { title: this.$route.params.ID ? `Цветочная подписка №${this.$route.params.ID}` : 'Создание цветочной подписки' }
    },
    data() {
        return {
            subscription: {
                amount: 5000,
                weekday: null,
                desired_colors: [],
                desired_categories: [],
                orders: [],
                periods: [],
                receiver_name: '',
                receiver_phone: '',
                receiver_address: '',
                customer_comment: '',
                user: {},
                lat: null,
                lon: null,
                delivery_cost: 0
            },
            daysOfWeekOptions: [
                { id: 1, name: 'понедельник', label: 'Пн' },
                { id: 2, name: 'вторник', label: 'Вт' },
                { id: 3, name: 'среда', label: 'Ср' },
                { id: 4, name: 'четверг', label: 'Чт' },
                { id: 5, name: 'пятница', label: 'Пт' },
                { id: 6, name: 'суббота', label: 'Сб' },
                { id: 7, name: 'воскресенье', label: 'Вс' },
            ],
            colorsOptions: [
                { value: 'multi', name: 'Мультицвет', color: 'conic-gradient(from 180deg at 50% 50%, #20FA1B -20.63deg, #FF0000 20.63deg, #FF9C06 90deg, #FF1CC2 163.12deg, #8155FF 195deg, #0A40FF 271.87deg, #20FA1B 339.37deg, #FF0000 380.62deg)' },
                { value: 'blue', name: 'Синий', color: '#0367E6' },
                { value: 'green', name: 'Зеленый', color: '#1AB372' },
                { value: 'white', name: 'Белый', color: '#FFFFFF' },
                { value: 'red', name: 'Красный', color: '#FF2F2F' },
                { value: 'purple', name: 'Фиолетовый', color: '#A82BBE' },
                { value: 'yellow', name: 'Желтый', color: '#FFCE19' },
                { value: 'orange', name: 'Оранжевый', color: '#FF971F' },
                { value: 'pink', name: 'Розовый', color: '#FF88D4' },
                { value: 'light-blue', name: 'Голубой', color: '#1BC6FF' },
            ],
            categoriesOptions: [],
            fab_open: false,
            showConfirmDeleteModal: false,
            searchUser: null,
            error: {
                amount: '',
                weekday: '',
                receiver_name: '',
                receiver_phone: '',
                receiver_address: '',
                user: { phone: '' }
            },
            activeTab: 0
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
            franchiseTariff: 'franchises/franchiseTariff',
        }),
        searchUserHint() {
            if (!this.$route.params.ID) {
                if (this.subscription.user.phone && this.subscription.user.phone.length > 10 && this.searchUser) {
                    return `Пользователь найден (${this.searchUser.phone})`;
                } else {
                    return 'Пользователь не найден';
                }
            }
            return '';
        },
    },
    watch: {
        'subscription.user.phone'(value) {
            if (!this.$route.params.ID && value && value.length > 10) {
                this.setSearchUser();
            } else if (value && value.length <= 10) {
                this.searchUser = null;
            }
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    mounted() {
        try {
            const interval = setInterval(async () => {
                if (this.franchiseTariff.site_id) {
                    clearInterval(interval)
                    const res = await getFlowerSubscriptionSettings(this.franchiseTariff.site_id);

                    if (this.$route.params.ID) {
                        this.subscription = await getFlowerSubscription(this.$route.params.ID);
                    }

                    this.categoriesOptions = res.categories;
                    this.$store.commit('ui/SET_PRELOADER', false);
                }
            }, 200)
            setTimeout(() => {
                if (!this.franchiseTariff.site_id) {
                    clearInterval(interval)
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Данные не были загружены. Попробуйте перезагрузить страницу', type: 'error' })
                }
            }, 20000)
        } catch (error) {
            console.log(error)
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        async saveSubscription() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (!this.searchUser) {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Заказчик с указанным телефоном не зарегистрирован', type: 'error' })
                    return;
                }

                if (this.errorProcessing()) {
                    return;
                }

                this.$store.commit('ui/SET_PRELOADER', true);

                let data = JSON.parse(JSON.stringify(this.subscription))
                data.user = data.user.id;

                await saveFlowerSubscription(data);

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Подписка сохранена', type: 'success' })
                this.$router.push('/flower-subscriptions');
            } catch (error) {
                console.log(error)
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При создании подписки произошла ошибка', type: 'error' })
            }
        },
        deleteSubscription() {
            console.log('Удаление подписки')
        },
        async setSearchUser() {
            try {
                const { results } = await getUserByPhone(this.subscription.user.phone);
                if (results.length === 1) {
                    const user = await this.$store.dispatch('users/GET_USER', results[0].id);
                    this.searchUser = user;
                    this.subscription.user.id = user.id;
                    this.subscription.user.first_name = user.first_name;
                } else {
                    this.searchUser = null;
                }
            } catch (error) {
                console.log(error);
            }
        },
        getAddressCoords() {
            if (this.subscription.receiver_address) {
                geocode().text(this.subscription.receiver_address).run(async (error, res) => {
                    if (error) {
                        console.log(error);
                        this.$store.commit('ui/add_item_in_system_notifications', { message: 'Координаты адреса не определены', type: 'error' })
                        return;
                    }
                    if (res.results.length) {
                        this.subscription.lat = res.results[0].latlng.lat;
                        this.subscription.lon = res.results[0].latlng.lng;
                        const deliveryData = await calculateDeliveryPrice({
                            lat: this.subscription.lat,
                            lon: this.subscription.lon,
                            address: this.subscription.receiver_address,
                            delivery_time_start: null,
                            delivery_time_end: null,
                        });
                        this.subscription.delivery_cost = deliveryData.delivery_cost
                    } else {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: 'Координаты адреса не определены', type: 'error' })
                    }
                });
            } else {
                this.subscription.lat = null;
                this.subscription.lon = null;
                this.subscription.delivery_cost = 0;
            }
        },
        errorProcessing() {
            let findErrors = false
            if (!this.subscription.amount) { this.error.amount = 'Введите сумму'; findErrors = true }

            if (!this.subscription.weekday) {
                this.error.weekday = 'Выберите день';
                findErrors = true;
            }

            if (!this.subscription.receiver_name) { this.error.receiver_name = 'Введите имя'; findErrors = true }
            if (!this.subscription.receiver_phone) { this.error.receiver_phone = 'Введите телефон'; findErrors = true }
            if (!this.subscription.receiver_address) { this.error.receiver_address = 'Введите адрес'; findErrors = true }

            return findErrors;
        },
        openLink(urlString) {
            window.open(urlString, '_blank');
        },
        phoneCall(phone) {
            if (phone) {
                location.href = `tel:${phone}`
            }
        },
        moment,
    }
}
</script>


<template>
    <UserDetail @set-user="user = $event" :key="key" />
</template>

<script>
import UserDetail from "@/components/app/users/UserDetail";

export default {
    name: 'TheUserCreate',
    components: {
        UserDetail
    },
    metaInfo() {
        return { title: this.$route.params.ID ? `Сотрудник ${this.user.last_name} ${this.user.first_name}` : 'Новый сотрудник' }
    },
    beforeRouteUpdate(to, from, next) {
        this.key += 1;
        next()
    },
    data() {
        return {
            user: {},
            key: 0
        }
    },
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial
            v-if="$route.params.ID"
            v-show="tabActive !== 1"
            v-model="fab_open"
            class="btn__floating custom-speed-dial"
            close-on-click
            fixed
        >
            <template v-slot:activator>
                <v-btn color="#1a73e8" dark fab @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <!-- <v-list-item v-if="certificate.is_paid" @click="sendCertificate">
                        <v-list-item-icon class="mr-3">
                            <v-icon>mdi-email-fast</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Отправить сертификат получателю</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item v-if="!certificate.is_paid" @click="copyPaymentLink">
                        <v-list-item-icon class="mr-3">
                            <v-icon>mdi-credit-card-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Скопировать ссылку на оплату</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showConfirmDeleteModal = true">
                        <v-list-item-icon class="mr-3">
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Удалить сертификат</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Сертификат №${$route.params.ID}` : 'Новый сертификат'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only && ['supervisor', 'administrators'].includes(authUserGroup)"
                @click:left="$router.back()"
                @click:save="saveCertificate"
            />
            <div class="tabs__list">
                <TabItem :active="tabActive === 0" @click="tabActive = 0">
                    Информация
                </TabItem>
                <TabItem
                    v-if="$route.params.ID && ['managers', 'administrators', 'supervisor'].includes(authUserGroup) &&
                        franchiseTariff.tariff && franchiseTariff.tariff.includes('site')"
                    :active="tabActive === 1"
                    @click="tabActive = 1"
                >
                    Чат
                    <div v-if="orderChat.unseen_count" class="tabs__item-indication">
                        {{ orderChat.unseen_count }}
                    </div>
                </TabItem>
            </div>
            <div class="page-container page-container--with-tabs page-container--with-fab">
                <div v-show="tabActive === 0" class="tabs__content">
                    <div class="page__info-group">
                        <Row>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputSelect
                                    v-model="certificate.template"
                                    :options="certificatesTemplates"
                                    label="Шаблон сертификата"
                                    :error="error && error.payment_method ? error.payment_method[0] : ''"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model.number="certificate.amount"
                                    type="number"
                                    label="Номинал"
                                    :disabled="!['supervisor', 'administrators'].includes(authUserGroup)"
                                    :error="error && error.amount ? error.amount : ''"
                                    @input="clearError('amount')"
                                />
                            </Col>
                            <Col>
                                <TextareaCustom
                                    v-model="certificate.text"
                                    label="Пожелание"
                                    clearable
                                />
                            </Col>
                            <Col v-if="$route.params.ID">
                                <InputCustom
                                    :value="findCertificateStatus(certificate).name + ' / ' +
                                        `${certificate.is_paid ? `Оплачен ${moment(certificate.payment_date).format('DD.MM.YYYY • HH:mm')}` :
                                            'Не оплачен'}`"
                                    label="Статус"
                                    readonly
                                />
                            </Col>
                        </Row>
                    </div>
                    <div class="page__info-group">
                        <h2 class="page__info-group-title">Получатель</h2>
                        <Row>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="certificate.receiver_name"
                                    label="Имя получателя"
                                    left-icon="user"
                                    :disabled="!['supervisor', 'administrators'].includes(authUserGroup)"
                                    :error="error && error.receiver_name ? error.receiver_name : ''"
                                    @input="clearError('receiver_name')"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="certificate.receiver_phone"
                                    type="tel"
                                    label="Телефон получателя"
                                    left-icon="phone"
                                    :disabled="!['supervisor', 'administrators'].includes(authUserGroup)"
                                    :error="error && error.receiver_phone ? error.receiver_phone : ''"
                                    @input="clearError('receiver_phone')"
                                    @click:left="phoneCall(certificate.receiver_phone)"
                                />
                            </Col>
                            <Col>
                                <InputCustom
                                    v-model="certificate.receiver_email"
                                    label="Email получателя"
                                    :disabled="!['supervisor', 'administrators'].includes(authUserGroup)"
                                    :error="error && error.receiver_email ? error.receiver_email : ''"
                                    @input="clearError('receiver_email')"
                                />
                            </Col>
                        </Row>
                    </div>
                    <div class="page__info-group">
                        <h2 class="page__info-group-title">Получение</h2>
                        <Row>
                            <Col>
                                <InputSelect
                                    v-model="certificate.delivery_type"
                                    :options="deliveryTypes"
                                    label="Способ доставки"
                                    input-value="value"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="deliveryDate"
                                    offset-y
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            :value="moment(pickedDate).format('DD.MM.YYYY')"
                                            label="Дата отправки"
                                            left-icon="calendar"
                                            readonly
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="pickedDate"
                                        no-title
                                        first-day-of-week="1"
                                        :min="moment().format('YYYY-MM-DD')"
                                        @input="$refs.deliveryDate.save(pickedDate)"
                                    />
                                </v-menu>
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="pickedTime"
                                    offset-y
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template #activator="{ on }">
                                        <InputCustom
                                            :value="pickedTime"
                                            label="Время отправки"
                                            left-icon="clock"
                                            readonly
                                            v-on="on"
                                        />
                                    </template>
                                    <v-time-picker
                                        v-model="pickedTime"
                                        format="24hr"
                                        full-width
                                        scrollable
                                        :min="moment().format('HH:mm')"
                                        @click:minute="() => { $refs.pickedTime.save(pickedTime), error.pickedTime = '' }"
                                    />
                                </v-menu>
                            </Col>
                        </Row>
                    </div>
                    <div class="page__info-group">
                        <h2 class="page__info-group-title">Заказчик</h2>
                        <Row>
                            <Col :cols="width > 1023 ? 6 : 12">
                                <InputCustom
                                    v-model="certificate.owner.first_name"
                                    label="Имя заказчика"
                                    left-icon="user"
                                    :right-icon="certificate.owner.id ? 'new-window': null"
                                    @click:right="openLink(`/clients/${certificate.owner.id}`)"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="certificate.owner.phone"
                                    label="Телефон заказчика"
                                    left-icon="phone"
                                    :right-icon="certificate.owner.phone ? 'whatsapp' : null"
                                    :disabled-left-icon="!certificate.owner.phone"
                                    clearable
                                    :hint="certificate.owner.first_name ? `Пользователь найден: ${certificate.owner.first_name}` : 'Пользователь не найден'"
                                    :error="error.owner ? error.owner : ''"
                                    @input="clearError('owner'), findClientDebounced()"
                                    @click:left="phoneCall(certificate.owner.phone)"
                                    @click:right="openLink(`https://wa.me/${certificate.owner.phone}`)"
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div
                    v-if="$route.params.ID && ['managers', 'administrators', 'supervisor'].includes(authUserGroup) &&
                        franchiseTariff.tariff && franchiseTariff.tariff.includes('site')"
                    v-show="tabActive === 1"
                    class="tabs__content"
                >
                    <ChatMessageSection minimize />
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmDeleteModal"
            :title="`Удалить сертификат ${ certificate.id }?`"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteModal = false"
            @mainBtnClick="deleteCertificate"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { debounce } from '@/utils/functions';

import { date, numberFormat } from '@/utils/format';

import {
    getCertificate, createCertificate, editCertificate, getUserByPhone, createCertificatePaymentLink, deleteCertificate
} from '@/api/django';

import MiniModal from "@/components/modal/MiniModal";
import ChatMessageSection from '@/components/app/chat/ChatMessageSection';

export default {
    name: 'TheCertificatesCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Сертификат №${this.$route.params.ID}` : 'Новый сертификат' }
    },
    components: {
        MiniModal,
        ChatMessageSection
    },
    data() {
        return {
            certificate: {
                owner: {
                    first_name: '',
                    phone: ''
                },
                receiver_name: '',
                receiver_phone: '',
                receiver_email: '',
                template: 1,
                text: '',
                amount: '',
                delivery_date: moment().format('YYYY-MM-DD'),
                delivery_type: 'Email',
            },
            pickedDate: moment().format('YYYY-MM-DD'),
            pickedTime: moment().format('HH:mm'),
            deliveryTypes: [{ value: 'Email', name: 'Email' }, { value: 'SMS', name: 'СМС' }],
            error: {
                amount: '',
                receiver_email: '',
                receiver_name: '',
                receiver_phone: '',
                owner: '',
            },
            tabActive: 0,
            fab_open: false,
            showConfirmDeleteModal: false
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            authUser: 'auth/user',
            preloader: 'ui/preloader',
            orderChat: 'chat/orderChat',
            chatLimit: 'chat/limit',
            franchiseTariff: 'franchises/franchiseTariff',
            certificatesTemplates: 'certificates/certificatesTemplates',
            certificatesStatuses: 'certificates/certificatesStatuses',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    watch: {
        tabActive() {
            if (this.tabActive === 1) {
                this.$store.dispatch('chat/SELECT_CHAT', this.orderChat);
            } else {
                this.$store.commit('chat/SET_SELECTED_CHAT', {});
            }
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await this.$store.dispatch('certificates/GET_CERTIFICATES_TEMPLATES');
            if (this.$route.params.ID) {
                this.certificate = await getCertificate(this.$route.params.ID);
                this.certificate.amount = +this.certificate.amount;
                this.pickedDate = moment(this.certificate.delivery_date).format('YYYY-MM-DD');
                this.pickedTime = moment(this.certificate.delivery_date).format('HH:mm');

                this.findClient();
                this.$store.dispatch('chat/SEARCH_CHAT_BY_USER_ID', { id: `${this.certificate.owner.id}`, limit: 1 });
            }
        } catch (error) {
            console.log(error);
            this.$store.commit('ui/add_item_in_system_notifications', { message: 'При загрузке данных произошла ошибка', type: 'error' })
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        this.$store.dispatch('chat/SEARCH_CHATS', { str: '', limit: this.chatLimit, type: 'True' });
        this.$store.commit('chat/SET_SELECTED_CHAT', {});
        this.$store.commit('chat/SET_ORDER_CHAT', {});
        this.$store.commit('chat/SET_MESSAGES', []);
    },
    methods: {
        async saveCertificate() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (!this.validateData()) {
                    return;
                }

                const data = {
                    ...this.certificate,
                    site: this.franchiseTariff.site_id,
                }
                data.owner = data.owner.id;
                data.delivery_date = moment(`${this.pickedDate}T${this.pickedTime}`).format('YYYY-MM-DDTHH:mmZ');

                // console.log(data);
                this.$store.commit('ui/SET_PRELOADER', true);
                if (!this.certificate.id) {
                    await createCertificate(data);
                } else {
                    await editCertificate(this.certificate.id, data);
                }
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Сертификат успешно сохранен', type: 'success' })
                this.$router.push('/certificates');
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении сертификата произошла ошибка', type: 'error' })
            }
        },
        validateData() {
            this.error = {
                amount: '',
                receiver_email: '',
                receiver_name: '',
                receiver_phone: '',
                owner: '',
            }
            let findErrors = false;
            if (+this.certificate.amount <= 0 || isNaN(+this.certificate.amount)) {
                this.error.amount = 'Введите корректное числовое значение';
                findErrors = true;
            }
            if (!this.certificate.owner.first_name) {
                this.error.owner = 'Заказчик не указан или не найден';
                findErrors = true;
            }
            if (!this.certificate.receiver_name) {
                this.error.receiver_name = 'Введите имя получателя';
                findErrors = true;
            }
            if (!this.certificate.receiver_phone) {
                this.error.receiver_phone = 'Введите телефон получателя';
                findErrors = true;
            }
            if (this.certificate.delivery_type === 'Email') {
                const regExp = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/
                const match = this.certificate.receiver_email.match(regExp)
                if (!match) {
                    this.error.receiver_email = 'Введите корректный email';
                    findErrors = true
                }
            }

            if (findErrors) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Проверьте корректность введенных данных', type: 'error' })
            }
            return !findErrors;
        },
        async deleteCertificate() {
            this.showConfirmDeleteModal = false;
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await deleteCertificate(this.certificate.id);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Сертификат успешно удален', type: 'success' })
                this.$router.push('/certificates');
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка удаления сертификата', type: 'error' })
            }
        },
        async copyPaymentLink() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                const paymentResponse = await createCertificatePaymentLink({
                    orderNumber: this.certificate.id,
                    returnUrl: `https://${this.franchiseTariff.site_domain}/api/v1/payment/result/?is_certificate=True`,
                    failUrl: `https://${this.franchiseTariff.site_domain}/api/v1/payment/result/?is_certificate=True`
                });
                if (paymentResponse.formUrl) {
                    await navigator.clipboard.writeText(paymentResponse.formUrl);
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ссылка на оплату скопирована', type: 'success' })
                } else {
                    throw new Error('Ссылка на оплату не получена');
                }
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка получения ссылки на оплату', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        // sendCertificate() {
        //     this.$store.commit('ui/add_item_in_system_notifications', { message: 'Функциональность не готова', type: 'base' })
        // },
        findClientDebounced: debounce(function () {
            this.findClient();
        }, 1000),
        async findClient() {
            try {
                if (this.certificate.owner.phone.length > 10) {
                    const { results } = await getUserByPhone(this.certificate.owner.phone);
                    if (results.length === 1) {
                        const user = await this.$store.dispatch('users/GET_USER', results[0].id);
                        this.certificate.owner = user;
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        findCertificateTemplate() {
            return this.certificatesTemplates.find((i) => i.id === this.certificate.template) || false;
        },
        findCertificateStatus(cert) {
            return this.certificatesStatuses.find((i) => i.id === cert.status) || false;
        },
        formatDate(value, type) {
            return date(new Date(value), type);
        },
        formatNumber(number) {
            return number === 0 ? 0 : numberFormat(number);
        },
        clearError(field) {
            this.error[field] = '';
        },
        openLink(urlString) {
            window.open(urlString, '_blank');
        },
        phoneCall(phone) {
            if (phone) {
                location.href = `tel:${phone}`
            }
        },
        moment,
    }
};
</script>

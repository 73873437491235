<template>
    <section :class="['page-section', 'good']">
        <v-speed-dial
            v-if="$route.params.ID && (['supervisor', 'administrators', 'superadministrators'].includes(group) || allowProcessForFlorist)"
            v-model="fab_open"
            class="btn__floating custom-speed-dial"
            close-on-click
            fixed
        >
            <template v-slot:activator>
                <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item
                        v-if="['supervisor', 'administrators', 'superadministrators'].includes(group)"
                        @click="isStatisticModalOpen = true"
                    >
                        <v-list-item-icon class="mr-3">
                            <v-icon>mdi-chart-box</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Посмотреть историю заказов</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="!user.read_only && product.is_deleted" @click="restoreGood">
                        <v-list-item-icon class="mr-3">
                            <v-icon color="#36b687">mdi-restore</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Восстановить товар</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="!user.read_only && !product.is_deleted" @click="showConfirmDeleteModal = true">
                        <v-list-item-icon class="mr-3">
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Удалить товар</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? product.name : 'Новый товар'"
                left-btn="left-big"
                :show-save-btn="isVisibleSaveBtn"
                @click:left="$router.push({ name: 'goods' })"
                @click:save="saveProduct"
            >
                <button
                    v-if="$route.params.ID && !product.is_deleted"
                    class="header__button"
                    @click="openLink(`https://${user.franchise_domain}/product/${product.slug}/`)"
                >
                    <Icon>new-window</Icon>
                </button>
            </Header>
            <div class="tabs__list">
                <TabItem :active="activeTab === 0" @click="activeTab = 0">
                    Описание
                </TabItem>
                <TabItem
                    v-if="product.type === 'bouquet'"
                    :active="activeTab === 1"
                    @click="activeTab = 1"
                >
                    Товары
                </TabItem>
                <TabItem v-if="!['florist', 'managers'].includes(group)" :active="activeTab === 2" @click="activeTab = 2">
                    Теги
                </TabItem>
                <TabItem v-if="$route.params.ID && product.type === 'equipment'" :active="activeTab === 3" @click="activeTab = 3">
                    История товара
                </TabItem>
            </div>
            <div class="page-container page-container--with-tabs page-container--with-fab">
                <div v-show="activeTab === 0" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Основное</h2>
                                <Row>
                                    <Col :cols="width > 1024 ? 6 : 12">
                                        <InputCustom
                                            v-model="product.name"
                                            label="Название"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group) && !allowProcessForFlorist"
                                            :error="error && error.name ? error.name[0] : ''"
                                        />
                                    </Col>
                                    <Col v-if="group !== 'florist' || $route.params.ID" :cols="width > 1024 ? 6 : 12">
                                        <InputSelect
                                            v-model="product.type"
                                            :options="types"
                                            label="Тип"
                                            :input-value="'slug'"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                        />
                                    </Col>
                                    <Col v-if="$route.params.ID" :cols="width > 1024 ? 6 : 12">
                                        <InputCustom
                                            :value="product.creator && product.creator.first_name ? product.creator.first_name : 'Не определен'"
                                            label="Создатель"
                                            left-icon="user"
                                            disabled
                                        />
                                    </Col>
                                    <Col v-if="$route.params.ID" :cols="width > 1024 ? 6 : 12">
                                        <InputCustom
                                            :value="product.date_created ? moment(product.date_created).format('DD.MM.YYYY HH:mm') : ''"
                                            label="Дата создания"
                                            left-icon="calendar"
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div v-if="group !== 'florist' || $route.params.ID" class="page__info-group">
                                <h2 class="page__info-group-title">Категория</h2>
                                <Row>
                                    <Col :cols="width > 1024 ? 6 : 12">
                                        <InputSelect
                                            v-model="product.category"
                                            :options="categoryOptions"
                                            label="Категория"
                                            multiple
                                            searchable
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                            :error="error && error.category ? error.category[0] : ''"
                                        />
                                    </Col>
                                    <Col :cols="width > 1024 ? 6 : 12">
                                        <InputSelect
                                            v-model="product.main_category"
                                            label="Категория в хлебных крошках"
                                            :options="categoryOptions.filter(i => product.category.includes(i.id)) || []"
                                            no-data-text="Сначала задайте категории товару"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                            :error="error && error.main_category ? error.main_category[0] : ''"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div v-if="product.type === 'bouquet'" class="page__info-group">
                                <h2 class="page__info-group-title">Наличие</h2>
                                <Row>
                                    <Col :cols="width > 1024 ? 6 : 12">
                                        <InputSelect
                                            :value="allowProcessForFlorist ? product.available_stores[0] : product.available_stores"
                                            :options="shopsOptions"
                                            label="Наличие в магазине"
                                            input-text="address"
                                            :multiple="allowProcessForFlorist ? false : true"
                                            :error="error && error.available_stores ? error.available_stores[0]: ''"
                                            @input="product.available_stores = Array.isArray($event) ? [...$event] : [$event]"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Настройки</h2>
                                <Row>
                                    <template v-if="product.type === 'bouquet'">
                                        <Col v-if="group !== 'florist' || $route.params.ID" :cols="width > 1024 ? 6 : 12">
                                            <InputSelect
                                                v-model="product.upgrade_type"
                                                :options="upgradeTypeOptions"
                                                label="Тип увеличения количества"
                                                :input-value="'type'"
                                                :input-text="'label'"
                                                :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                            />
                                        </Col>
                                        <Col :cols="width > 1024 ? 6 : 12">
                                            <InputCustom
                                                v-model="product.height"
                                                label="Высота, см:"
                                                type="number"
                                                :disabled="$route.params.ID && !['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                                :error="error ? error.height: ''"
                                            />
                                        </Col>
                                        <Col :cols="width > 1024 ? 6 : 12">
                                            <InputCustom
                                                v-model="product.width"
                                                label="Ширина, см:"
                                                type="number"
                                                :disabled="$route.params.ID && !['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                                :error="error ? error.width: ''"
                                            />
                                        </Col>
                                    </template>
                                    <Col v-if="product.type === 'equipment'" :cols="width > 1024 ? 3 : width < 600 ? 12 : 6">
                                        <InputCustom
                                            :value="+product.price"
                                            label="Стоимость"
                                            type="number"
                                            :disabled="$route.params.ID && !['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                            :error="error && error.price ? error.price: ''"
                                            @input="product.price = $event"
                                        />
                                    </Col>
                                    <Col v-if="product.type === 'equipment'" :cols="width > 1024 ? 3 : width < 600 ? 12 : 6">
                                        <InputCustom
                                            :value="+product.prime_cost"
                                            label="Себестоимость"
                                            type="number"
                                            :disabled="$route.params.ID && !['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                            :error="error && error.prime_cost ? error.prime_cost: ''"
                                            @input="product.prime_cost = $event"
                                        />
                                    </Col>
                                    <Col v-if="group !== 'florist' || $route.params.ID" :cols="width > 1024 ? 3 : width < 600 ? 12 : 6">
                                        <InputCustom
                                            v-model="product.order_time"
                                            label="Время сборки (часов)"
                                            type="number"
                                            :disabled="group === 'florist' || ($route.params.ID && !['supervisor', 'managers', 'administrators', 'superadministrators'].includes(group))"
                                            :error="error && error.order_time ? error.order_time: ''"
                                        />
                                    </Col>
                                    <Col v-if="product.type === 'bouquet' && (group !== 'florist' || $route.params.ID)" :cols="width > 1024 ? 3 : width < 600 ? 12 : 6">
                                        <InputCustom
                                            v-model="price"
                                            label="Стоимость"
                                            type="number"
                                            disabled
                                            :error="error ? error.price: ''"
                                        />
                                    </Col>
                                    <Col v-if="product.type === 'equipment'" :cols="width > 1024 ? 3 : 12">
                                        <InputSelect
                                            v-model="product.units"
                                            :options="units.results"
                                            label="Единицы измерения"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div v-if="group !== 'florist' || $route.params.ID" class="page__info-group">
                                <h2 class="page__info-group-title">Видимость</h2>
                                <div class="checkbox-group">
                                    <div v-if="franchiseTariff.tariff && franchiseTariff.tariff.includes('site')" class="checkbox-group__item">
                                        <Checkbox
                                            v-model="product.is_active"
                                            label="Показывать на сайте"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                        />
                                    </div>
                                    <div class="checkbox-group__item">
                                        <Checkbox
                                            :value="!product.is_hidden"
                                            label="Доступен для покупки"
                                            :disabled="!['managers', 'supervisor', 'administrators', 'superadministrators'].includes(group)"
                                            @change="product.is_hidden = !product.is_hidden"
                                        />
                                    </div>
                                    <div class="checkbox-group__item">
                                        <Checkbox
                                            v-model="product.is_wishes_allow"
                                            label="Пожелание для заказа"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                        />
                                    </div>
                                    <div v-if="product.type === 'equipment'" class="checkbox-group__item">
                                        <Checkbox
                                            v-model="product.price_change"
                                            label="Изменять цену"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                        />
                                    </div>
                                    <div v-if="product.type === 'equipment'" class="checkbox-group__item">
                                        <Checkbox
                                            v-model="product.show_amount"
                                            label="Показывать количество"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                        />
                                    </div>
                                    <div v-if="product.type === 'equipment'" class="checkbox-group__item">
                                        <Checkbox
                                            v-model="product.is_for_arrival"
                                            label="Учитывать в поставке"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                        />
                                    </div>
                                    <div v-if="$route.params.ID" class="checkbox-group__item">
                                        <Checkbox
                                            v-model="product.is_coffee"
                                            label="Товар для кофейни"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">SEO</h2>
                                <Row>
                                    <Col>
                                        <TextareaCustom
                                            v-model="product.description"
                                            label="Описание"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group) && !allowProcessForFlorist"
                                        />
                                    </Col>
                                    <Col v-if="group !== 'florist' || $route.params.ID">
                                        <TextareaCustom
                                            v-model="product.meta_title"
                                            label="META-TITLE"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                        />
                                    </Col>
                                    <Col v-if="group !== 'florist' || $route.params.ID">
                                        <TextareaCustom
                                            v-model="product.meta_description"
                                            label="META-DESCRIPTION"
                                            :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group)"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div class="page-small-column page-small-column--mobile-top">
                            <PhotoSlider
                                :images="product.images"
                                :with-new-photo-btn="!user.read_only && (['supervisor', 'administrators', 'superadministrators'].includes(group) || allowProcessForFlorist)"
                                @newPhoto="loadImage($event)"
                                @changeSort="changeSort($event)"
                                @remove="removeImage($event)"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="product.type === 'bouquet'"
                    v-show="activeTab === 1"
                    class="tabs__content"
                >
                    <div v-if="equipment_set.length" class="listing-table listing-table--products">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                            <div class="listing-table__col pl-0">Наименование</div>
                            <div class="listing-table__col" style="width: 125px; flex: 0 0 auto;text-align:center;">Стоимость</div>
                            <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;text-align:center;">Количество</div>
                            <div class="listing-table__col" style="width: 110px; flex: 0 0 auto;text-align:right;">Сумма</div>
                            <div class="listing-table__col" style="width: 70px; flex: 0 0 auto;" />
                        </div>
                        <draggable v-model="equipment_set" handle=".draggable-row">
                            <div
                                v-for="(good, index) in equipment_set"
                                :key="index"
                                class="listing-table__row"
                                :class="{'draggable-row': width > 1024}"
                            >
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image listing-table__image--with-link">
                                        <img
                                            :src="good.equipment_product.images.length && good.equipment_product.images[0].image ?
                                            good.equipment_product.images[0].image.preview :
                                                require('@/assets/img/flower.svg')
                                            "
                                            alt=""
                                        >
                                        <a
                                            href="#"
                                            class="listing-table__image-link"
                                            @click.stop.prevent="openLink(`/goods/${good.equipment_product.vendor_code}`)"
                                        >
                                            <v-icon color="primary">mdi-open-in-new</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1024 ? 'width: calc(100% - 567px)' : 'width: calc(100% - 100px)'"
                                >
                                    <div>
                                        <div class="listing-table__product-name">
                                            <span>{{ good.equipment_product.name }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 125px; flex: 0 0 auto;' : 'flex: 1 1 100%; max-width:calc(30% - 8px)'"
                                >
                                    <InputCustom
                                        :value="+good.price"
                                        :label="width <= 1024 ? 'Стоимость' : ''"
                                        type="number"
                                        :disabled="(!['supervisor', 'administrators', 'superadministrators'].includes(group) && !allowProcessForFlorist) ||
                                            !good.equipment_product.price_change
                                        "
                                        style="flex: 0 0 auto;"
                                        @input="editEquipmentField(good, $event, 'price')"
                                    />
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : 'flex: 1 1 100%; max-width:calc(45% - 8px)'"
                                >
                                    <InputCounter
                                        v-model="good.count"
                                        :disabled="(!['supervisor', 'administrators', 'superadministrators'].includes(group) && !allowProcessForFlorist)"
                                        @input="editEquipmentField(good, $event, 'count')"
                                    />
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 110px; flex: 0 0 auto;text-align:right;' :
                                        'flex: 1 1 100%; max-width:calc(25% - 8px);align-items: flex-end'"
                                >
                                    <div>
                                        <div class="listing-table__col-label">Сумма</div>
                                        {{ numberFormat((good.count * good.price)) }} ₽
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline ml-a"
                                    :style="width > 1024 ? 'width: 70px; flex: 0 0 auto;' : 'position: absolute; right:0;'"
                                >
                                    <v-btn
                                        v-if="group !== 'courier'"
                                        icon
                                        :disabled="!['supervisor', 'administrators', 'superadministrators'].includes(group) && !allowProcessForFlorist"
                                        @click.stop="$store.dispatch('equipments/FILTERED_EQUIPMENT_SET', good.equipment_product.vendor_code)"
                                    >
                                        <Icon color="#2B77F5">trash</Icon>
                                    </v-btn>
                                </div>
                                <div
                                    v-if="group !== 'florist'"
                                    class="checkbox-group"
                                    :class="{
                                        'checkbox-group--inline mx-4 mb-2': width > 1024,
                                        'mt-2' : width <= 1024
                                    }"
                                >
                                    <div class="checkbox-group__item">
                                        <Checkbox v-model="good.is_calculate" label="Изменять кол-во" />
                                    </div>
                                    <div class="checkbox-group__item">
                                        <Checkbox v-model="good.display_amount" label="Показывать кол-во" />
                                    </div>
                                </div>
                            </div>
                        </draggable>
                    </div>
                    <div
                        v-if="['florist', 'supervisor', 'administrators', 'superadministrators'].includes(group) &&
                            (group !== 'florist' || group === 'florist' && allowProcessForFlorist)
                        "
                        class="d-flex justify-center mt-4"
                    >
                        <Button
                            bordered
                            icon-right="edit-2"
                            @click.prevent="isEquipmentModalOpen = true"
                        >
                            Изменить
                        </Button>
                    </div>
                    <div class="price-list__wrap">
                        <div class="price-list__item">
                            <div class="price-list__item-label">Итого</div>
                            <div class="price-list__item-value">
                                {{
                                    (!!product.discount.amount_perc || !!product.discount.amount_rub) ?
                                        numberFormat(product.discount.price_with_discount) : numberFormat(price)
                                }}
                                ₽
                            </div>
                        </div>
                        <div class="price-list__item">
                            <div class="price-list__item-label">Общая стоимость</div>
                            <div class="price-list__item-value">{{ numberFormat((price)) }} ₽</div>
                        </div>
                        <div v-if="product.discount.price_with_discount" class="price-list__item">
                            <div class="price-list__item-label">
                                Скидка:
                                <a
                                    :href="`/discounts/${product.discount.discount_id}/`"
                                    style="color:#F54157;"
                                    target="_blank"
                                >
                                    {{ product.discount.discount_name }} ({{ product.discount.amount_perc !== 0 ? `${product.discount.amount_perc}%` : `${product.discount.amount_rub}₽` }})
                                </a>
                            </div>
                            <div class="price-list__item-value" style="color:#F54157;">
                                -{{ numberFormat(price - product.discount.price_with_discount) }} ₽
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="activeTab === 2 && !['florist', 'managers'].includes(group)" class="tabs__content">
                    <Row>
                        <Col cols="12">
                            <template v-for="item in tags">
                                <div v-if="item.childrens.length" :key="item.id" class="tags-manage__category-item">
                                    <div class="tags-manage__category-item-title">
                                        {{ item.name }}
                                    </div>
                                    <div class="tags-manage__category-tags">
                                        <button
                                            v-for="tag in item.childrens"
                                            :key="tag.id"
                                            type="button"
                                            class="tags-manage__category-tag-item"
                                            :class="{
                                                'colors': tag.color || tag.multi_color,
                                                'active': product.products_tags.find(item => item.id === tag.id)
                                            }"
                                            :style="getBackgroundColorForFilter(tag)"
                                            @click.prevent="toggleTagActive(tag)"
                                        >
                                            {{ !tag.color && !tag.multi_color ? tag.name : '' }}
                                        </button>
                                    </div>
                                </div>
                            </template>
                        </Col>
                    </Row>
                </div>
                <div v-show="activeTab === 3" class="tabs__content">
                    <ProductWriteoffStatistic :product-id="$route.params.ID" />
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmDeleteModal"
            :title="`Удалить товар ${ product.name }?`"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteModal = false"
            @mainBtnClick="deleteProduct"
        />
        <UniversalModal v-model="isStatisticModalOpen" size="big">
            <ProductOrdersHistory :product="product" @closeModal="isStatisticModalOpen = false" />
        </UniversalModal>
        <UniversalModal v-model="isEquipmentModalOpen" size="full">
            <EquipmentsModal @closeModal="isEquipmentModalOpen = false" />
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import moment from 'moment';

import { fetchImageID, patchImageById, restoreGood } from '@/api/django';

import { numberFormat } from '@/utils/format';

import MiniModal from "@/components/modal/MiniModal";
import ProductOrdersHistory from '@/components/app/goods/modal/ProductOrdersHistory';
import ProductWriteoffStatistic from '@/components/app/goods/ProductWriteoffStatistic';
import PhotoSlider from '@/components/app/PhotoSlider';
import EquipmentsModal from '@/components/app/goods/modal/EquipmentsModal';

export default {
    name: 'TheGoodCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Товар ${this.product.name}` : 'Новый товар' }
    },
    components: { draggable, MiniModal, ProductOrdersHistory, ProductWriteoffStatistic, PhotoSlider, EquipmentsModal },
    data() {
        return {
            product: {
                vendor_code: null,
                name: '',
                description: '',
                discount: {},
                order_time: 1,
                is_coffee: false,
                is_hidden: false,
                is_for_arrival: true,
                price: 0,
                prime_cost: 0,
                price_change: false,
                height: null,
                width: null,
                images: [],
                category: [],
                main_category: null,
                type: 'bouquet',
                equipment_type: 'kit',
                date_created: null,
                creator: '',
                last_change: null,
                is_active: true,
                sort: 0,
                meta_title: '{{product.name}} - купить с доставкой в {{franchise.city}} - {{franchise.name}}',
                meta_description: '{{product.name}} с доставкой в {{franchise.city}}. В {{franchise.name}} вы найдете, чем удивить любимую! Для заказа цветов звоните: {{franchise.main_phone}}.',
                meta_h1: '',
                show_amount: true,
                units: 1,
                deliveryTime: '',
                upgrade_type: null,
                available_stores: [],
                is_wishes_allow: false,
                products_tags: []
            },
            upgradeTypeOptions: [
                { type: null, label: 'Не установлено' },
                { type: 'by_variant', label: 'На 30%/60%' },
                { type: 'by_amount', label: 'Прибавить по одному' }
            ],
            error: null,
            fab_open: false,
            activeTab: 0,
            showConfirmDeleteModal: false,
            isStatisticModalOpen: false,
            isEquipmentModalOpen: false
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            categories: 'data/categories',
            units: 'data/units',
            types: 'data/types',
            selectedCategory: 'goods/selectedCategory',
            user: 'auth/user',
            preloader: 'ui/preloader',
            franchiseTariff: 'franchises/franchiseTariff',
            shops: "data/shops",
            tags: 'tags/categories',
        }),
        equipment_set: {
            get() {
                return this.$store.getters['equipments/equipment_set'];
            },
            set(set) {
                set.forEach((product, index) => {
                    product.sort = index + 1;
                });
                this.$store.commit('equipments/UPDATE_EQUIPMENT_SET', set);
            }
        },
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        categoryOptions() {
            let sub = [];
            const filteredCat = this.categories.filter((category) => category.type === this.product.type);
            if (filteredCat) {
                filteredCat.forEach((item) => {
                    if (item.childrens && item.childrens.length) {
                        item.childrens.forEach((child) => {
                            sub.push(child);
                            if (child.childrens && child.childrens.length) {
                                child.childrens.forEach((c) => sub.push(c));
                            }
                        });
                    } else {
                        sub.push(item);
                    }
                });
            }
            if (this.group === 'florist') {
                sub = sub.filter((item) => item.for_showcase === true);
            }
            return sub;
        },
        price() {
            if (this.product.type === 'bouquet') {
                if (this.equipment_set.length) {
                    return this.equipment_set.reduce((summ, good) => {
                        return summ + (good.price * good.count);
                    }, 0)
                } else {
                    return 0;
                }
            }
            return this.product.price;
        },
        allowProcessForFlorist() {
            const showcase = this.categoryOptions.find((item) => item.for_showcase === true);
            if (showcase && this.product.category.find((item) => item === showcase.id)) {
                return true;
            }
            return false;
        },
        isVisibleSaveBtn() {
            return !!(!this.user.read_only && !this.product.is_deleted && (this.group !== 'florist' || this.group === 'florist' && this.allowProcessForFlorist))
        },
        shopsOptions() {
            return [
                { id: null, address: 'Не выбран', icon: 'store' },
                ...this.shops.map(item => ({ ...item, icon: item?.type?.icon_path || '' }))
            ]
        }
    },
    watch: {
        'product.category'() {
            if (!this.product.category.includes(this.product.main_category)) {
                this.product.main_category = null;
            }

            if (!this.$route.params.ID) {
                if (this.allowProcessForFlorist) {
                    this.product.order_time = 0;
                } else {
                    this.product.order_time = 1;
                    this.product.available_stores = this.shops.map((i) => i.id);
                }
            }
        },
        categoryOptions(val) {
            if (!this.$route.params.ID && this.group === 'florist') {
                if (val && val.length) {
                    this.product.category = [val[0].id];
                }
            }
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            if (this.$route.params.ID && this.$route.query.tab) {
                this.activeTab = +this.$route.query.tab;
            }

            this.$store.dispatch("data/LOAD_SHOPS", JSON.parse(localStorage.getItem("user")).city);
            this.$store.dispatch('data/LOAD_TYPES');
            this.$store.dispatch('data/LOAD_UNITS');
            if (this.$route.params.ID) {
                const good = await this.$store.dispatch('good/GET_GOOD_DATA', this.$route.params.ID)
                this.product = { ...good, discount: good.discount || {}};
            } else if (this.selectedCategory && Object.keys(this.selectedCategory).length) {
                this.product.type = this.selectedCategory.type;
                this.product.category = [this.selectedCategory.id];
            }

            if (!this.tags.length) {
                this.$store.dispatch('tags/getTags');
            }

            if (!this.$route.params.ID && this.group === 'florist') {
                this.product.is_hidden = true;
                this.product.is_active = false;
                this.product.order_time = 0;
                if (this.categoryOptions.length) {
                    this.product.category = [this.categoryOptions[0].id];
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    destroyed() {
        this.$store.commit('equipments/CLEAR_EQUIPMENTS');
    },
    methods: {
        async saveProduct() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                if (typeof this.product.images[0] !== 'number') {
                    this.product.images.forEach((image, index) => {
                        patchImageById('patch', `/product_images/${image.id}/`, { sort: index });
                    });
                }

                let data = JSON.parse(JSON.stringify(this.product));

                if (!this.product.width) {
                    data.width = null;
                }
                if (!this.product.height) {
                    data.height = null;
                }
                if (this.product.order_time === null) {
                    data.order_time = 2;
                }
                if (this.product.show_amount) {
                    delete data.show_amount;
                }

                if (this.allowProcessForFlorist) {
                    data.available_stores = this.product.available_stores.slice(0, 1);
                }

                if (this.product.upgrade_type === '') {
                    data.upgrade_type = null
                }

                if (this.product.sticker) {
                    data.sticker = this.product.sticker.id;
                }

                delete data.discount;

                data = {
                    ...data,
                    price: this.price,
                    equipment_type: this.product.type === 'bouquet' ? 'kit' : 'single',
                    images: this.product.images.map((image) => image.id),
                    products_tags: this.product.products_tags.map((i) => i.id)
                }

                if (!this.$route.params.ID) {
                    await this.$store.dispatch('good/CREATE_GOOD', {
                        ...data,
                        equipment_set: this.equipment_set,
                    })
                } else {
                    await this.$store.dispatch('good/UPDATE_GOOD', {
                        vendor_code: this.$route.params.ID,
                        good: data
                    })
                }

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Товар успешно сохранен', type: 'success' })
                this.$router.push({ name: 'goods' });
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);

                if (error.data) {
                    this.error = error.data;
                }
                if (this.error.non_field_errors || this.error.category) {
                    this.$store.commit('ui/add_item_in_system_notifications', {
                        message: this.error.non_field_errors ? this.error.non_field_errors[0] : this.error.category[0],
                        type: 'error'
                    })
                } else {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении произошла ошибка', type: 'error' })
                }
            }
        },
        async deleteProduct() {
            this.showConfirmDeleteModal = false;
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('good/DELETE_GOOD', this.$route.params.ID)
                this.$router.push({ name: 'goods' });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Товар успешно удалён', type: 'success' })
            } catch (error) {
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При удалении товара произошла ошибка', type: 'error' })
            }
        },
        async restoreGood() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await restoreGood(this.$route.params.ID);
                this.$set(this.product, 'is_deleted', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Товар успешно восстановлен', type: 'success' })
            } catch (error) {
                console.log(error)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка восстановления товара', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        editEquipmentField(obj, event, field) {
            if (field === 'price') {
                obj.price = event;
            }
            this.$store.commit('equipments/CHANGE_VALUE_FIELD', {
                vendor_code: obj.equipment_product.vendor_code,
                value: +event,
                field
            });
        },
        async loadImage(event) {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (event.target.files.length === 0) {
                    return;
                }

                this.$store.commit('ui/SET_PRELOADER', true);

                const file = event.target.files[0];
                const data = new FormData();

                data.append('image', file);
                event.target.value = '';
                const { id, image } = await fetchImageID(
                    'post',
                    '/product_images/',
                    { 'Content-Type': 'multipart/form-data' },
                    data,
                );

                this.product.images.push({ id, image });
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки фото', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        changeSort({ index, direction }) {
            this.$store.dispatch('ui/checkUserEditPermission');
            const changedImage = JSON.parse(JSON.stringify(this.product.images[index]));
            this.product.images.splice(index, 1)

            if (direction === 'left') {
                this.product.images.splice(index - 1, 0, changedImage)
            } else if (direction === 'right') {
                this.product.images.splice(index + 1, 0, changedImage)
            } else if (direction === 'main') {
                this.product.images.splice(0, 0, changedImage)
            }
        },
        removeImage(index) {
            this.$store.dispatch('ui/checkUserEditPermission');
            this.product.images.splice(index, 1);
        },
        toggleTagActive(tag) {
            if (this.product.products_tags.find((item) => item.id === tag.id)) {
                this.product.products_tags = this.product.products_tags.filter((item) => item.id !== tag.id);
            } else {
                this.product.products_tags.push(tag);
            }
        },
        openLink(link) {
            window.open(link, '_blank', 'noopener,noreferrer');
        },
        getBackgroundColorForFilter(tag) {
            if (tag.color || tag.multi_color) {
                let str = 'background: ';
                if (tag.multi_color) {
                    str += 'conic-gradient(from 180deg at 50% 50%, #20FA1B -20.63deg, #FF0000 20.63deg, #FF9C06 90deg, #FF1CC2 163.12deg, #8155FF 195deg, #0A40FF 271.87deg, #20FA1B 339.37deg, #FF0000 380.62deg)'
                } else {
                    str += tag.color;
                }
                return str;
            }
            return '';
        },
        numberFormat,
        moment
    }
};
</script>

<style>
    .goods-item__price .v-text-field  .v-input__control  div.v-input__slot {
        padding: 0 !important;
    }
</style>

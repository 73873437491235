<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Перемещения"
                for-listing
                :show-save-btn="false"
                filter
                :filterIndicator="!!activeFilters.length"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'transfers-filter')"
            />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="transfers && transfers.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 20%">Склад-отправитель</div>
                            <div class="listing-table__col" style="width: 20%">Склад-получатель</div>
                            <div class="listing-table__col" style="width: 20%">Отправка</div>
                            <div class="listing-table__col" style="width: 20%">Получение</div>
                            <div class="listing-table__col" style="width: 20%">Статус</div>
                        </div>
                        <router-link
                            v-for="(item, index) in transfers"
                            :key="item.id"
                            :to="`/storeroom/movement/${item.id}`"
                            class="listing-table__row"
                            :class="{'not-seen': !item.is_seen }"
                        >
                            <div v-if="width <= 1024" class="listing-table__col">
                                Перемещение №{{ item.id }}
                            </div>
                            <div class="listing-table__col" :style="width > 1023 ? 'width: 20%' : ''">
                                <div class="listing-table__col-label">Отправитель</div>
                                {{ item.from_stock.replace('Омск,' , '') }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 20%' : ''">
                                <div class="listing-table__col-label">Получатель</div>
                                {{ item.to_stock.replace('Омск,' , '') }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                <div class="listing-table__col-label">Отправка</div>
                                {{ item.date_sended ? formatDate(item.date_sended, 'hours-ru') : '&mdash;' }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                <div class="listing-table__col-label">Получение</div>
                                {{ item.date_received ? formatDate(item.date_received, 'hours-ru') : '&mdash;' }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                <StatusSelect
                                    :value="item.status"
                                    :options="statuses"
                                    mode="transfers"
                                    :disabled="authUser.read_only || group === 'courier' && (item.status == 2 || item.status == 4)"
                                    @select="changeStatus($event, item.id)"
                                />
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="transfers && !transfers.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import {
    confirmTransfer, sendTransfer, acceptedTransfer, readyTransfer
} from '@/api/stock';

import { date } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheStoreroomMovement',
    metaInfo: {
        title: 'Перемещения'
    },
    components: {
        Spinner
    },
    data() {
        return {
            groups: [],
            options: [
                { slug: 'incoming', name: 'Входящие' },
                { slug: 'outgoing', name: 'Исходящие' }
            ],
            statuses: [
                { id: 2, name: 'Готов к выдаче', icon: 'clock', color: '#F2C94C' },
                { id: 3, name: 'Курьер выехал', icon: 'map', color: '#399db7' },
                { id: 4, name: 'Выполнен', icon: 'check', color: '#36B687' },
            ],
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            transfers: 'transfers/transfers',
            shops: 'shops/shops',
            busy: 'transfers/busy',
            next: 'transfers/next',
            preloader: 'ui/preloader',
            activeFilters: 'transfers/activeFilters',
            authUser: 'auth/user',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true)
    },
    async mounted() {
        const user = JSON.parse(window.localStorage.getItem('user'));
        this.groups = user.groups;
        if (user.city) {
            this.$store.dispatch('shops/GET_SHOPS');
        }
        if (this.group === 'florist') {
            this.$store.commit('transfers/SET_FILTER', { field: 'shop', value: this.authUser.shop });
        }
        await this.$store.dispatch('transfers/GET_ALL_TRANSFERS');
        this.$store.commit('ui/SET_PRELOADER', false)
    },
    methods: {
        async changeStatus(status, id) {
            try {
                if (status === 1) {
                    await acceptedTransfer(id)
                } else if (status === 2) {
                    await readyTransfer(id)
                } else if (status === 3) {
                    await sendTransfer(id)
                } else if (status === 4) {
                    await confirmTransfer(id)
                }
                this.$store.dispatch('transfers/GET_ALL_TRANSFERS');
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка смены статуса', type: 'error' })
            }
        },
        formatDate(value, type) {
            return date(new Date(value), type);
        },
        loadMore() {
            this.$store.dispatch('transfers/LOAD_MORE');
        },
    }
};
</script>

<template>
    <div>
        <div v-if="!preloader && show" class="table__empty-wrapper" :class="{'table__empty-wrapper--empty-catalog': emptyCatalog}">
            <div class="table__empty">
                <img src="@/assets/img/icon/not-found.svg" alt="">
                <span class="table__empty-title">
                    {{ !emptyCatalog ? 'Ничего не найдено' : 'У вас еще нет товаров' }}
                </span>
                <span v-if="emptyCatalog" class="table__empty-subtitle">
                    Добавьте категории и товары вручную, либо импортируйте предложенный нами каталог
                </span>
                <v-btn v-if="emptyCatalog && !startImport" color="primary" @click.prevent="openConfirmModal">
                    Импортировать каталог
                </v-btn>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmModal"
            title="Импорт каталога"
            text="В ваш аккаунт будут импортированы товары и категории. Каталог можно будет очистить и сделать повторный импорт в любой момент. Запустить импорт?"
            additional-btn-text="Отмена"
            main-btn-text="Запустить"
            @additionalBtnClick="showConfirmModal = false"
            @mainBtnClick="importDefaultCatalog"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { importCatalogByID } from '@/api/django';

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'EmptyTable',
    components: {
        MiniModal,
    },
    props: {
        emptyCatalog: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show: false,
            showConfirmModal: false,
            startImport: false,
        }
    },
    computed: {
        ...mapGetters({
            preloader: 'ui/preloader'
        })
    },
    created() {
        setTimeout(() => {
            this.show = true;
        }, 0);
    },
    methods: {
        openConfirmModal() {
            this.showConfirmModal = true;
            this.$store.commit('ui/OPEN_MODAL', 'base-modal');
        },
        async importDefaultCatalog() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await importCatalogByID(1);
                this.startImport = true;
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Каталог начал импортироваться, подождите немного', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При импорте возникла ошибка', type: 'error' })
            } finally {
                this.showConfirmModal = false;
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        }
    }
}
</script>

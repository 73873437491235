<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="`Отзыв на заказ ${review.order}`"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only"
                @click:left="$router.push('/reviews')"
                @click:save="saveReview"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="page__info-group">
                            <Row>
                                <Col>
                                    <v-rating
                                        v-show="!preloader"
                                        v-model="review.rating"
                                        background-color="#F4A725"
                                        color="#F4A725"
                                        size="24"
                                        :readonly="group !== 'supervisor' && group !== 'administrators'"
                                    />
                                    <ContentPreloader v-show="preloader" width="200" height="40" />
                                </Col>
                                <Col>
                                    <InputCustom
                                        v-model="review.order"
                                        label="Номер заказа"
                                        disabled
                                        right-icon="new-window"
                                        @click:right="openLink(`/orders/${review.order}`)"
                                    />
                                </Col>
                                <Col>
                                    <InputCustom
                                        v-model="review.user.first_name"
                                        label="Покупатель"
                                        disabled
                                        right-icon="new-window"
                                        @click:right="openLink(`/clients/${review.user.id}`)"
                                    />
                                </Col>
                                <Col>
                                    <InputCustom
                                        :value="moment(review.datetime_created).format('DD.MM.YYYY HH:mm')"
                                        label="Дата создания"
                                        left-icon="calendar"
                                        disabled
                                    />
                                </Col>
                                <Col>
                                    <TextareaCustom
                                        v-model="review.text"
                                        label="Текст"
                                        :disabled="!['supervisor', 'administrators'].includes(group)"
                                    />
                                </Col>
                                <Col>
                                    <TextareaCustom
                                        v-model="review.employee_comment"
                                        label="Комментарий сотрудника"
                                        :messages="review.employee ? `Изменено пользователем: ${review.employee}` : ''"
                                        :disabled="!['supervisor', 'administrators', 'managers'].includes(group)"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div class="page-small-column">
                        <div class="page__info-group">
                            <h2 class="page__info-group-title">Видимость отзыва</h2>
                            <div class="checkbox-group">
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="review.is_active"
                                        label="Активность"
                                        @change="review.is_active = !review.is_active"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="review.rating === 5" class="page__info-group">
                            <h2 class="page__info-group-title">Понравилось</h2>
                            <div class="checkbox-group">
                                <div
                                    v-for="item in excellentWishes"
                                    :key="item.name"
                                    class="checkbox-group__item"
                                >
                                    <Checkbox
                                        :value="review.wishes.includes(item.value)"
                                        :label="item.name"
                                        readonly
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="review.rating === 4" class="page__info-group">
                            <h2 class="page__info-group-title">Над чем поработать</h2>
                            <div class="checkbox-group">
                                <div
                                    v-for="item in goodWishes"
                                    :key="item.name"
                                    class="checkbox-group__item"
                                >
                                    <Checkbox
                                        :value="review.wishes.includes(item.value)"
                                        :label="item.name"
                                        readonly
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="review.rating <= 3" class="page__info-group">
                            <h2 class="page__info-group-title">Не понравилось</h2>
                            <div class="checkbox-group">
                                <div
                                    v-for="item in badWishes"
                                    :key="item.name"
                                    class="checkbox-group__item"
                                >
                                    <Checkbox
                                        :value="review.wishes.includes(item.value)"
                                        :label="item.name"
                                        readonly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'

import { fetchReviewsID, apiEditReview } from '@/api/django';

export default {
    name: 'TheReviewID',
    metaInfo() {
        return { title: this.$route.params.ID ? `Отзыв на заказ ${this.review.order}` : 'Новый отзыв' }
    },
    data() {
        return {
            review: {
                is_active: false,
                user: '',
                order: '',
                rating: 5,
                datetime_created: '',
                text: '',
                employee_comment: '',
                employee: null,
                wishes: []
            },
            wishes: [
                { name: 'Упаковка', active: false, type: 'excellent', value: 'packing' },
                { name: 'Букет', active: false, type: 'excellent', value: 'bouquet' },
                { name: 'Сервис', active: false, type: 'excellent', value: 'service' },
                { name: 'Доставка', active: false, type: 'excellent', value: 'delivery' },
                { name: 'Десерты или кофе', active: false, type: 'excellent', value: 'eat' },
                { name: 'Над упаковкой', active: false, type: 'good', value: 'improve_packing' },
                { name: 'Над доставкой', active: false, type: 'good', value: 'improve_delivery' },
                { name: 'Сборкой цветов', active: false, type: 'good', value: 'improve_bouquet' },
                { name: 'Общением менеджера/флориста', active: false, type: 'good', value: 'improve_employee_communication' },
                { name: 'Вкусом десертов/кофе', active: false, type: 'good', value: 'improve_eat' },
                { name: 'Неверно собран заказ', active: false, type: 'bad', value: 'bad_order' },
                { name: 'Испорчена упаковка', active: false, type: 'bad', value: 'bad_packing' },
                { name: 'Не устроил курьер', active: false, type: 'bad', value: 'bad_courier' },
                { name: 'Опоздали с доставкой', active: false, type: 'bad', value: 'bad_delivery' },
                { name: 'Цветы завяли', active: false, type: 'bad', value: 'bad_flowers' },
                { name: 'Было невкусно', active: false, type: 'bad', value: 'bad_eat' },
            ],
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        excellentWishes() {
            return this.wishes.filter(i => i.type === 'excellent')
        },
        goodWishes() {
            return this.wishes.filter(i => i.type === 'good')
        },
        badWishes() {
            return this.wishes.filter(i => i.type === 'bad')
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            if (this.$route.params.ID) {
                this.review = await fetchReviewsID(this.$route.params.ID);
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        async saveReview() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const data = {
                    is_active: this.review.is_active,
                    rating: this.review.rating,
                    text: this.review.text,
                    employee_comment: this.review.employee_comment
                };

                await apiEditReview(this.review.id, data);

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Отзыв успешно сохранен', type: 'success' })
                this.$router.push({ name: 'reviews' });
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении отзыва произошла ошибка', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        openLink(url) {
            window.open(url, '_blank');
        },
        moment
    }
};
</script>

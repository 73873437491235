<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Сотрудники"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список сотрудников</h2>
                                <p>В списке выводятся поля:</p>
                                <ul>
                                    <li>Фото пользователя</li>
                                    <li>Имя</li>
                                    <li>Роль</li>
                                    <li>Дата последней авторизации</li>
                                    <li>Онлайн</li>
                                </ul>
                                <p>Доступны быстрые фильтры по ролям.</p>
                                <img src="@/assets/img/help/users/1.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание пользователя (описание раздела для сотрудников и клиентов)</h2>
                                <p>Сначала необходимо выбрать группу для нового пользователя.</p>
                                <p>Для всех ролей, кроме покупателей и флористов, доступны следующие поля:</p>
                                <ul>
                                    <li>Имя</li>
                                    <li>Логин</li>
                                    <li>Пароль</li>
                                    <li>Email</li>
                                    <li>Ставка в час</li>
                                    <li>Комментарий</li>
                                </ul>
                                <p>Для флориста добавляется поле:</p>
                                <ul>
                                    <li>Магазин</li>
                                </ul>
                                <p>Для сотрудников есть 2 переключателя:</p>
                                <ul>
                                    <li>Не начислять бонусы</li>
                                    <li>Только просмотр - режим блокирует возможность создавать/редактировать данные в IS этим пользователем).</li>
                                </ul>
                                <img src="@/assets/img/help/users/2.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Для роли "Покупатель" доступны поля:</p>
                                <ul>
                                    <li>Имя</li>
                                    <li>Телефон</li>
                                    <li>Email</li>
                                    <li>Пол</li>
                                    <li>Дата рождения</li>
                                </ul>

                                <p>Есть 2 переключателя.</p>
                                <ul>
                                    <li>Не начислять бонусы</li>
                                    <li>"Участвует в рассылке" - при создании массовых рассылок пользователь получит SMS/Email.</li>
                                </ul>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Просмотр и редактирование пользователя</h2>
                                <p>При редактировании сотрудника можно указать новый пароль. Он автоматически будет изменен при сохранении данных пользователя.</p>
                                <img src="@/assets/img/help/users/3.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>У флористов при просмотре профиля появляется поле PIN, которое нужно использовать при авторизации на кассе.</p>
                                <img src="@/assets/img/help/users/4.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Для сотрудников, которые отмечают время работы, отображается соответствующая таблица на вкладке "Время работы".</p>
                                <img src="@/assets/img/help/users/5.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Для курьеров на вкладке "Заказы" отображается таблица с назначенными заказами. Если нажать на иконку корзины, то у заказа поле "курьер" станет пустым.</p>
                                <img src="@/assets/img/help/users/6.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Для клиентов отображается таблица с созданными заказами.</p>
                                <img src="@/assets/img/help/users/7.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>При просмотре данных клиента появляются новые вкладки:</p>
                                <ul>
                                    <li>Бонусы</li>
                                    <li>Поводы</li>
                                    <li>Чат</li>
                                </ul>

                                <p>На вкладке "Бонусы" можно посмотреть историю начисления и списания бонусов, а также отредактировать баланс.</p>
                                <p>При изменении количества бонусов нужно будет указать причину.</p>
                                <img src="@/assets/img/help/users/8.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>На вкладке "Поводы" находится список сохраненных поводов заказов.</p>
                                <img src="@/assets/img/help/users/9.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Удалить пользователя можно с помощью соответствующей кнопки.</p>
                                <img src="@/assets/img/help/users/10.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'Зарплаты', link: '/help/salaries'}, {name: 'К оглавлению', link: '/help'}]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpUsers',
    metaInfo: {
        title: 'Сотрудники - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Списания"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список списаний</h2>
                                <p>В списке списаний выводятся поля:</p>
                                <ul>
                                    <li>Магазин</li>
                                    <li>Дата списания</li>
                                    <li>Статус</li>
                                </ul>

                                <p>Непросмотренные списания выделяются светло-желтым цветом.</p>
                                <img src="@/assets/img/help/offs/1.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Есть фильтр по магазинам. Флористам доступны только списания своего магазина.</p>
                                <p>Статус списания можно изменить с помощью выпадающего списка.</p>
                                <img src="@/assets/img/help/offs/2.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание списания</h2>
                                <p>К списанию можно прикрепить до 9 фотографий.</p>
                                <p>Имя сотрудника подставляется автоматически исходя из имени авторизованного пользователя.</p>
                                <p>При создании списания нужно обязательно выбрать магазин и комплектующие. Также можно ввести комментарий.</p>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Просмотр и редактирование списания</h2>
                                <p>Редактировать или удалить списание можно только в статусе <b>"Ожидает подтверждения"</b>. В статусе <b>"Выполнено"</b> кнопка сохранения изменений недоступна.</p>
                                <p>Для редактирования доступен <span v-if="authUserGroup !== 'florist'">список товаров, </span>список фотографий, комментарий.</p>
                                <p>При нажатии на кнопку <b>"Посмотреть"</b>, фотография откроется в отдельном окне. При нажатии на галочку в левой части фотография будет отмечена.</p>
                                <p>Кнопки <b>"Сделать основным"</b>, <b>"Сместить вперед"</b>, <b>"Сместить назад"</b> отвечают за сортировку фотографий.</p>
                                <img src="@/assets/img/help/offs/3.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Отмеченные фотографии можно скачать архивом, нажав на кнопку со стрелкой вниз.</p>
                                <img src="@/assets/img/help/offs/4.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p v-if="authUserGroup !== 'florist'">Удалить списание можно через дополнительное меню.</p>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'Поставки', link: '/help/arrivals'}, {name: 'Перемещения', link: '/help/transfers'}]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpOffs',
    metaInfo: {
        title: 'Списания - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
    },
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial
            v-if="!authUser.read_only && $route.params.ID"
            v-model="fab_open"
            class="btn__floating custom-speed-dial"
            close-on-click
            fixed
        >
            <template v-slot:activator>
                <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item @click="deleteItem()">
                        <v-list-item-icon class="mr-3">
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Удалить мотивацию</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Мотивация ${$route.params.ID}` : 'Новая мотивация'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only"
                @click:left="$router.back()"
                @click:save="saveItem"
            />
            <div class="page-container page-container--with-fab">
                <div class="chips__wrap">
                    <Chips :active="motivation.is_active" @click="motivation.is_active = !motivation.is_active">
                        Активна
                    </Chips>
                </div>
                <Row>
                    <Col :cols="width > 1023 ? 6 : 12">
                        <InputSelect
                            v-model="motivation.shop"
                            :options="shops.results"
                            label="Магазин"
                            input-text="address"
                            input-icon="type_icon"
                            :error="errors.shop ? errors.shop[0] : ''"
                        />
                    </Col>
                    <Col :cols="width > 1023 ? 6 : 12">
                        <v-menu
                            ref="calendar"
                            offset-y
                            :close-on-content-click="false"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <InputCustom
                                    :value="calendarText"
                                    label="Период действия"
                                    readonly
                                    :error="errors.active_from || errors.active_to ? errors.active_from[0] : ''"
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                v-model="calendar"
                                no-title
                                range
                                first-day-of-week="1"
                                @input="() => {
                                    if (calendar[0] && calendar[1]) $refs.calendar.save(calendar)
                                }"
                            />
                        </v-menu>
                    </Col>
                </Row>
                <v-divider class="my-4" />
                <div v-if="motivationsProductsList && motivationsProductsList.length" class="listing-table">
                    <div class="listing-table__row listing-table__row--head">
                        <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                        <div class="listing-table__col pl-0">Наименование</div>
                        <div class="listing-table__col" style="width: 160px; flex: 0 0 auto;">Стоимость</div>
                        <div class="listing-table__col" style="width: 125px; flex: 0 0 auto;text-align: center;">Процент</div>
                        <div class="listing-table__col" style="width: 70px; flex: 0 0 auto;" />
                    </div>
                    <div
                        v-for="good in motivationsProductsList"
                        :key="good.product.vendor_code"
                        class="listing-table__row"
                    >
                        <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                            <div class="listing-table__image listing-table__image--with-link">
                                <img
                                    :src="good.product.images.length && good.product.images[0].image ? good.product.images[0].image.preview :
                                        require('@/assets/img/flower.svg')"
                                    alt=""
                                >
                                <a href="#" class="listing-table__image-link" @click.stop.prevent="linkToGood(good.product)">
                                    <v-icon color="primary">mdi-open-in-new</v-icon>
                                </a>
                            </div>
                        </div>
                        <div
                            class="listing-table__col listing-table__col--mobile-inline"
                            :class="{'pt-0 mr-0': width < 1024}"
                            :style="width > 1024 ? 'width: calc(100% - 467px)' : 'width: calc(100% - 68px)'"
                        >
                            <div>
                                <div class="listing-table__product-name">
                                    <span>{{ good.product.name }}</span>
                                </div>
                            </div>
                        </div>

                        <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                        <div
                            class="listing-table__col listing-table__col--mobile-inline"
                            :style="width > 1024 ? 'width: 160px; flex: 0 0 auto;' : ''"
                        >
                            <div class="listing-table__col-label">Стоимость</div>
                            {{ formatNumber(+good.product.price) }} ₽
                        </div>
                        <div
                            class="listing-table__col"
                            :style="width > 1024 ? 'width: 125px; flex: 0 0 auto;' : ''"
                        >
                            <div class="listing-table__col-label">Процент</div>
                            <v-text-field
                                v-model="good.percent"
                                class="order-goods-table__price-input text-center"
                                type="number"
                                outlined
                                single-line
                                dense
                                required
                                hide-details="auto"
                                style="flex: 0 0 auto"
                            >
                                <template slot="append">
                                    <span>%</span>
                                </template>
                            </v-text-field>
                        </div>
                        <div
                            class="listing-table__col listing-table__col--mobile-inline ml-a"
                            :style="width > 1024 ? 'width: 70px; flex: 0 0 auto;' : ''"
                        >
                            <v-btn icon @click.stop="$store.dispatch('motivations/SET_PRODUCTS', good.product)">
                                <v-icon color="error">mdi-delete</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center mt-4">
                    <Button bordered icon-right="edit-2" @click="$store.commit('ui/OPEN_MODAL', 'modal-goods')">
                        {{ !motivationsProductsList.length ? 'Добавить товары' : 'Изменить' }}
                    </Button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import {
    getMotivationItem, postMotivations, patchMotivations, deleteMotivations
} from '@/api/django';

import { date, numberFormat } from '@/utils/format';

export default {
    name: 'TheMotivationCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Мотивация ${this.$route.params.ID}` : 'Новая мотивация' }
    },
    data() {
        return {
            motivation: {
                is_active: true,
                shop: null,
                active_from: null,
                active_to: null
            },
            calendar: [null, null],
            errors: {},
            fab_open: false
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            shops: 'shops/shops',
            preloader: 'ui/preloader',
            motivationsProductsList: 'motivations/motivationsProductsList',
            authUser: 'auth/user',
            franchiseTariff: 'franchises/franchiseTariff',
        }),
        calendarText() {
            if (this.calendar[0] && this.calendar[1]) {
                return `с ${this.dateFormat(this.calendar[0], 'ru')} до ${this.dateFormat(this.calendar[1], 'ru')}`;
            }
            return 'Период действия не указан';
        },
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        this.$store.commit('motivations/SET_PRODUCTS_LIST', []);
        this.$store.commit('ui/SET_GOODS_MODAL_MODE', 'motivations');
        await this.$store.dispatch('shops/GET_SHOPS', true);

        if (this.$route.params.ID) {
            const data = await getMotivationItem(this.$route.params.ID);
            this.motivation = data;
            if (data.active_from && data.active_to) {
                this.calendar = [data.active_from, data.active_to];
            } else {
                this.calendar = [null, null];
            }
            this.$store.commit('motivations/SET_PRODUCTS_LIST', data.products);
        }
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    beforeDestroy() {
        this.$store.commit('ui/SET_GOODS_MODAL_MODE', '');
    },
    methods: {
        async saveItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.errors = {};
                this.setCalendarDataForServer();

                const data = {
                    ...this.motivation,
                    products: this.motivationsProductsList.map((item) => ({ product: item.product.vendor_code, percent: item.percent }))
                }
                if (!this.$route.params.ID) {
                    await postMotivations(data);
                } else {
                    await patchMotivations(this.$route.params.ID, data);
                }
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Мотивация успешно сохранена', type: 'success' })
                this.$router.push({ name: 'motivations' });
            } catch (error) {
                console.log(error);
                this.errors = error.data;
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка сохранения мотивации', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async deleteItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await deleteMotivations(this.$route.params.ID);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Мотивация успешно удалена', type: 'success' })
                this.$router.push({ name: 'motivations' });
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка удаления мотивации', type: 'error' })
            }
        },
        dateFormat(value, type) {
            return date(new Date(value), type);
        },
        linkToGood(good) {
            if (good.slug && good.equipment_set.length && this.group === "courier") {
                window.open(`https://${this.franchiseTariff.site_domain}/product/${good.slug}/`, '_blank');
            } else {
                window.open(`/goods/${good.vendor_code}`, '_blank');
            }
        },
        formatNumber(num) {
            return numberFormat(num);
        },
        setCalendarDataForServer() {
            if (this.calendar[0] && this.calendar[1]) {
                this.motivation.active_from = this.calendar[0];
                this.motivation.active_to = this.calendar[1];
            } else {
                this.motivation.active_from = null;
                this.motivation.active_to = null;
            }
        }
    }
};
</script>

/* eslint-disable */
import router from '@/router';
import { auth } from '@/api/auth';
import { getProfileData, patchProfile } from '@/api/django';

import token from '@/utils/token';

const state = {
    token: token(),
    refreshToken: localStorage.getItem('refreshToken'),
    user: {},
    group: null,
    error: false
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
        if (window.localStorage) {
            window.localStorage.setItem('token', token);
        }
    },
    SET_REFRESH_TOKEN(state, token) {
        state.refreshToken = token;
        localStorage.setItem('refreshToken', token);
    },
    REMOVE_DATA_AUTH: (state) => {
        state.token = null;
        state.refreshToken = null;
        state.user = {};
        if (window.localStorage) {
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('user');
            window.localStorage.removeItem('group');
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('tariff');
        }
    },
    SET_AUTH_ERROR: (state, status) => {
        state.error = status;
    },
    SET_USER: (state, user) => {
        state.user = user;
        if (window.localStorage) {
            window.localStorage.setItem('user', JSON.stringify(user));
        }
    },
    SET_USER_GROUP(state, group) {
        state.group = group.join();
        if (window.localStorage) {
            window.localStorage.setItem('group', state.group);
        }
    }
};

const actions = {
    async LOGIN({ state, commit, dispatch }, data) {
        try {
            const response = await auth(data);
            commit('SET_TOKEN', `Bearer ${response.access_token}`);
            commit('SET_REFRESH_TOKEN', response.refresh_token);

            dispatch('users/GET_USERS_GROUPS', null, { root: true });

            await dispatch('LOAD_PROFILE_DATA');
            await dispatch('chat/INIT', null, { root: true });
            await dispatch('autoNotification/CONNECT', null, { root: true });
            dispatch('franchises/GET_FRANCHISE_TARIFF', null, { root: true });
            dispatch('stock/GET_STOCK_INDICATIONS', null, { root: true });
            dispatch('data/LOAD_CATEGORY', null, { root: true });
            dispatch("orders/GET_ORDER_STATUSES", null, { root: true });
            dispatch('shops/GET_SHOPS_TYPES', null, { root: true });

            setInterval(() => {
                dispatch('franchises/GET_FRANCHISE_TARIFF', null, { root: true });
                dispatch('stock/GET_STOCK_INDICATIONS', null, { root: true });
            }, 300000);

            setTimeout(() => {
                if (state.group === 'superadministrators') {
                    router.push('/business');
                } else {
                    router.push('/orders');
                }
            }, 1000);
        } catch (error) {
            console.log(error);
            throw error;
        }
    },
    async LOAD_PROFILE_DATA({ commit }) {
        const user = await getProfileData();
        commit('SET_USER', user);
        commit('SET_USER_GROUP', user.groups);
        commit('chat/SET_USER_ID', user.id, { root: true });
        return user;
    },
    async PATCH_PROFILE_DATA({commit}, data) {
        const res = await patchProfile(data);
        commit('SET_USER', res);
    },
    async LOGOUT({ commit }) {
        commit('autoNotification/CLEAR_MESSAGES', null, { root: true });
        commit('autoNotification/SOCKET_ONCLOSE', null, { root: true });
        commit('chat/SOCKET_ONCLOSE', null, { root: true });
        commit('REMOVE_DATA_AUTH');
    },
};

const getters = {
    logged: state => !!state.token,
    user: state => state.user,
    username: state => {
        if (state.user.first_name) {
            return `${state.user.first_name} ${state.user.last_name}`;
        }
        return 'Пользователь';
    },
    error: state => state.error
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

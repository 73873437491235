<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Цветочные подписки"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание и редактирование цветочной подписки</h2>
                                <p>Страницы для создания и редактирования подписки практически не отличается. При создании доступна только одна вкладка "Основное". Во время редактирования подписки добавляются вкладки "Заказы" и "Периоды".</p>
                                <p>На вкладке <b>"Основное"</b> расположена главная информация о подписке:</p>
                                <ul>
                                    <li>Сумма подписки</li>
                                    <li>Желаемые дни недели для доставки</li>
                                    <li>Предпочтения по цвету и цветам</li>
                                    <li>Информация о заказчике и получателе</li>
                                    <li>Данные доставки</li>
                                </ul>

                                <p>После оплаты подписки в системе автоматически создаются заказы-заглушки на нужные даты. В заказе будет указана информация о предпочтениях заказчика. На вкладке <b>"Заказы"</b> отображаются эти созданные заказы.</p>

                                <p>На вкладке <b>"Периоды"</b> можно просмотреть информацию о том, сколько раз пользователь продлевал подписку, а также когда она закончится.</p>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'К оглавлению', link: '/help'}, {name: 'Настройки', link: '/help/flower-subscription-settings'}]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpFlowerSubscription',
    metaInfo: {
        title: 'Цветочные подписки - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Цветочные подписки" for-listing :show-save-btn="false" />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="subscriptions.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 25%">№ подписки</div>
                            <div class="listing-table__col" style="width: 25%">Покупатель</div>
                            <div class="listing-table__col" style="width: 25%">Сумма</div>
                            <div class="listing-table__col" style="width: 25%">Статус</div>
                        </div>
                        <router-link v-for="item in subscriptions" :key="item.id" :to="`/flower-subscriptions/${item.id}`" class="listing-table__row">
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                {{ item.id }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Покупатель</div>
                                {{ item.user.first_name }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Сумма</div>
                                {{ numberFormat(item.amount) }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Статус</div>
                                <span :style="{'color': item.is_active ? '#36B687' : '#EF6A6E'}">
                                    {{ item.is_active ? 'Активна' : 'Не активна' }}
                                </span>
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="!subscriptions.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { numberFormat } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: "TheFlowerSubscriptions",
    metaInfo() {
        return { title: 'Цветочные подписки' }
    },
    components: { Spinner },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
            subscriptions: 'flowerSubscriptions/subscriptions',
            busy: 'flowerSubscriptions/busy',
            next: 'flowerSubscriptions/next',
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await this.$store.dispatch('flowerSubscriptions/GET_SUBSCRIPTIONS');
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        loadMore() {
            this.$store.dispatch('flowerSubscriptions/GET_NEXT_PAGE');
        },
        numberFormat
    }
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Перемещение № ${$route.params.ID}` : 'Новое перемещение'"
                left-btn="left-big"
                :show-save-btn="!loggedUser.read_only && !$route.params.ID && group !== 'courier'"
                @click:left="$router.back()"
                @click:save="saveTransfer"
            />
            <div class="page-container">
                <div class="page__info-group">
                    <Row>
                        <Col :cols="width > 1023 ? 6 : 12">
                            <InputSelect
                                v-if="!$route.params.ID"
                                v-model="transfer.from_stock"
                                :options="shops.results"
                                label="Склад отправитель"
                                :input-text="'address'"
                                input-icon="type_icon"
                                :error="errors.from_stock ? 'Выберите магазин' : ''"
                                @input="selectFromStock"
                            />
                            <InputCustom
                                v-else
                                :value="transfer.from_stock"
                                label="Склад отправитель"
                                disabled
                            />
                        </Col>
                        <Col v-if="$route.params.ID" :cols="width > 1023 ? 6 : 12">
                            <InputCustom
                                :value="`${transfer.date_sended ? moment(transfer.date_sended).format('DD.MM.YYYY HH:mm') : '-'}`"
                                label="Дата и время отправки"
                                disabled
                            />
                        </Col>
                        <Col :cols="width > 1023 ? 6 : 12">
                            <InputSelect
                                v-if="!$route.params.ID"
                                v-model="transfer.to_stock"
                                :options="shops.results"
                                label="Склад получатель"
                                :input-text="'address'"
                                input-icon="type_icon"
                                :error="errors.to_stock ? 'Выберите магазин' : ''"
                                @input="selectToStock"
                            />
                            <InputCustom
                                v-else
                                :value="transfer.to_stock"
                                label="Склад получатель"
                                disabled
                            />
                        </Col>
                        <Col v-if="$route.params.ID" :cols="width > 1023 ? 6 : 12">
                            <InputCustom
                                :value="`${transfer.date_received ? moment(transfer.date_received).format('DD.MM.YYYY HH:mm') : '-'}`"
                                label="Дата и время получения"
                                disabled
                            />
                        </Col>
                        <Col v-if="group !== 'courier'" :cols="width > 1023 ? 6 : 12">
                            <InputSelect
                                v-model="transfer.courier"
                                :options="couriers"
                                label="Курьер"
                                :input-text="'first_name'"
                                :disabled="!!$route.params.ID"
                            />
                        </Col>
                        <Col v-if="$route.params.ID" :cols="width > 1023 ? 6 : 12">
                            <InputSelect
                                v-model="transfer.status"
                                :options="statuses"
                                label="Статус"
                                disabled
                            />
                        </Col>
                    </Row>
                </div>
                <div class="page__info-group">
                    <div v-show="!preloader" class="listing-table__wrap">
                        <div v-if="transfers_goods.length" class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                <div class="listing-table__col pl-0">Наименование</div>
                                <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;text-align: center;">Количество</div>
                                <div v-if="!$route.params.ID" class="listing-table__col" style="width: 70px; flex: 0 0 auto;" />
                            </div>
                            <div v-for="good in transfers_goods" :key="good.product" class="listing-table__row">
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image listing-table__image--with-link">
                                        <img
                                            :src="good.image ? good.image : require('@/assets/img/flower.svg')"
                                            alt=""
                                        >
                                        <a href="#" class="listing-table__image-link" @click.stop.prevent="linkToGood(good.product)">
                                            <v-icon color="primary">mdi-open-in-new</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1024 && !$route.params.ID ? 'width: calc(100% - 362px)' :
                                        width > 1024 && $route.params.ID ? 'width: calc(100% - 262px)' : 'width: calc(100% - 68px)'
                                    "
                                >
                                    <div>
                                        <div class="listing-table__product-name">
                                            <span>{{ good.name }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : 'max-width: 150px'"
                                >
                                    <InputCounter
                                        v-model="good.count"
                                        :disabled="!!$route.params.ID"
                                        @input="$store.commit('transfers/CHANGE_COUNT_TRANSFERS_GOOD', { product: good.product, value: +$event })"
                                    />
                                </div>
                                <div
                                    v-if="!$route.params.ID"
                                    class="listing-table__col listing-table__col--mobile-inline ml-a"
                                    :style="width > 1024 ? 'width: 70px; flex: 0 0 auto;' : ''"
                                >
                                    <v-btn icon @click.stop="$store.commit('transfers/REMOVE_GOOD_FROM_TRANSFERS', good.product)">
                                        <Icon color="#0D63F3">trash</Icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContentPreloader v-show="preloader && $route.params.ID" type="table" height="96" />
                    <div v-if="!$route.params.ID" class="d-flex justify-center mt-4">
                        <Button bordered icon-right="edit-2" @click.prevent="openModalHandler">
                            Изменить
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        <UniversalModal v-model="isModalOpen" size="full">
            <TransfersModal @closeModal="isModalOpen = false" />
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { getUserByGroup } from '@/api/django';

import {
    confirmTransfer, sendTransfer, acceptedTransfer, readyTransfer, editTransfer
} from '@/api/stock';

import TransfersModal from '@/components/app/transfers/TransfersModal';

export default {
    name: 'TheStoreroomMovementCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Перемещение ${this.$route.params.ID}` : 'Новое перемещение' }
    },
    components: { TransfersModal },
    data() {
        return {
            statuses: [
                { id: 2, name: 'Готов к выдаче', icon: 'mdi-cube-send', color: '#36B687' },
                { id: 3, name: 'Курьер выехал', icon: 'mdi-truck', color: '#399DB7' },
                { id: 4, name: 'Выполнен', icon: 'mdi-marker-check', color: '#B63678' },
                // { id: 5, name: 'Отменено' },
            ],
            transfer: {
                date_received: '',
                date_sended: '',
                from_stock: null,
                from_user: null,
                status: 2,
                to_stock: null,
                to_user: null,
                courier: null,
            },
            couriers: [],
            errors: [],
            isModalOpen: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            transfers_goods: 'transfers/transfers_goods',
            shops: 'shops/shops',
            loggedUser: 'auth/user',
            preloader: 'ui/preloader',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    watch: {
        'transfer.from_stock'() {
            if (!this.$route.params.ID) {
                this.$store.commit('transfers/CLEAR');
            }
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            if (this.$route.params.ID) {
                const transfer = await this.$store.dispatch('transfers/GET_TRANSFER_DATA', this.$route.params.ID)
                transfer.from_stock = transfer.from_stock.replace('Омск, ', '')
                transfer.to_stock = transfer.to_stock.replace('Омск, ', '')
                this.transfer = transfer;
            }
            if (this.group !== 'courier') {
                getUserByGroup('courier', 'all')
                    .then(res => {
                        this.couriers = res.results;
                    })
            }
            this.$store.dispatch('data/LOAD_SHOPS', JSON.parse(localStorage.getItem('user')).city);
            this.$store.dispatch('shops/GET_SHOPS');
            this.markAsViewed();
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false)
        }
    },
    beforeDestroy() {
        this.$store.commit('transfers/CLEAR');
    },
    methods: {
        async saveTransfer() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true)

                let checkZeroCount = false;
                this.transfers_goods.forEach((good) => {
                    if (good.count < 1) {
                        checkZeroCount = true;
                    }
                });

                if (checkZeroCount) {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Выберите товары', type: 'error' })
                    return;
                }

                let data = { ...this.transfer };
                delete data.date_received;
                delete data.date_sended;

                await this.$store.dispatch('transfers/CREATE_TRANSFER', data)
                this.$store.dispatch('transfers/GET_ALL_TRANSFERS');
                this.$router.push({ name: 'movements' });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Перемещение сохранено', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false)
                if (error.data) {
                    this.errors = error.data;
                    if (this.errors.products) {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: 'Выберите товары', type: 'error' })
                    }
                }
            }
        },
        async changeStatus(status, id) {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (status === 1) {
                    await acceptedTransfer(id)
                } else if (status === 2) {
                    await readyTransfer(id)
                } else if (status === 3) {
                    await sendTransfer(id)
                } else if (status === 4) {
                    await confirmTransfer(id)
                }
                this.$store.dispatch('transfers/GET_ALL_TRANSFERS');
            } catch (error) {
                console.log(error);
                if (error.data) {
                    this.errors.push({ id, message: error.data.detail });
                }
            }
        },
        selectFromStock(event) {
            this.transfer.from_stock = event;
            this.$store.commit('transfers/SET_SHOP', event);
        },
        selectToStock(event) {
            this.transfer.to_stock = event;
        },
        openModalHandler() {
            if (this.transfer.from_stock) {
                this.isModalOpen = true;
            } else {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Выберите склад-отправитель', type: 'error' })
            }
        },
        async markAsViewed() {
            this.$store.dispatch('ui/checkUserEditPermission');
            if (this.$route.params.ID && !this.transfer.is_seen) {
                await editTransfer(this.$route.params.ID, { is_seen: true });
                this.$store.dispatch('franchises/GET_FRANCHISE_TARIFF');
                this.$store.dispatch('stock/GET_STOCK_INDICATIONS');
            }
        },
        linkToGood(vendorCode) {
            window.open(`/goods/${vendorCode}`, '_blank');
        },
        moment
    },
};
</script>

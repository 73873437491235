<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.orderID ? `${$route.params.orderID}` : 'Новый заказ'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only && showSaveBtn"
                @click:left="checkOrderChanges"
                @click:save="eventHandlerButton"
            >
                <StatusSelect
                    v-if="$route.params.orderID && !preloader"
                    :value="order.status"
                    :options="statuses.statuses || []"
                    class="mr-4"
                    :order="order"
                    mode="order"
                    :disabled="!$route.params.orderID || authUser.read_only || preloader ||
                        (authUserGroup === 'florist' && order.status !== 3) ||
                        (authUserGroup === 'courier' && order.status !== 4 && order.status !== 5)
                    "
                    @select="showCheckEquipmentModal($event)"
                />
                <v-menu v-if="authUserGroup !== 'courier' && !preloader" offset-y>
                    <template v-slot:activator="{ on }">
                        <button type="button" class="header__button" v-on="on">
                            <Icon color="#0f1922">more</Icon>
                        </button>
                    </template>
                    <v-list>
                        <v-list-item @click="copyOrder">
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-cart</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Скопировать заказ</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="copyEquipmentSet">
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-basket</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Скопировать состав</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="$route.params.orderID && !authUser.read_only &&
                                (authUserGroup === 'supervisor' || authUserGroup === 'administrators') &&
                                order.status === 8 && order.cashbox_viewed"
                            @click="returnOrder()"
                        >
                            <v-list-item-icon class="mr-3">
                                <v-icon color="#EF6A6E">mdi-clipboard-arrow-left</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Оформить возврат</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-show="order.payment_method === 1306" @click="downloadBillPDF">
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-download</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ $route.params.orderID ? 'Скачать счет' : 'Сформировать счет' }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="$route.params.orderID && !authUser.read_only && order.status === 1"
                            @click="createPaymentLink"
                        >
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-credit-card-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                Получить ссылку на оплату
                            </v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item @click="printChequeAtol">
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-ticket-confirmation</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                Распечатать чек АТОЛ
                            </v-list-item-title>
                        </v-list-item> -->
                    </v-list>
                </v-menu>
            </Header>
            <div v-if="$route.params.orderID && authUserGroup !== 'courier'" class="tabs__list">
                <TabItem :active="tabActive === 0" @click="tabActive = 0">
                    Общее
                    <v-icon
                        v-if="error && (error.source || error.payment_method || error.delivery_time_start || error.delivery_time_end)"
                        class="ml-4"
                    >
                        mdi-alert-circle
                    </v-icon>
                </TabItem>
                <TabItem :active="tabActive === 1" @click="tabActive = 1">
                    Рабочее
                </TabItem>
                <TabItem
                    v-if="['managers', 'administrators', 'supervisor'].includes(authUserGroup) &&
                        franchiseTariff.tariff && franchiseTariff.tariff.includes('site')"
                    :active="tabActive === 2"
                    @click="tabActive = 2"
                >
                    Чат
                    <div v-if="orderChat.unseen_count" class="tabs__item-indication">
                        {{ orderChat.unseen_count }}
                    </div>
                </TabItem>
                <TabItem
                    v-if="!['courier', 'florist'].includes(authUserGroup)"
                    :active="tabActive === 3"
                    @click="tabActive = 3"
                >
                    История заказа
                </TabItem>
            </div>
            <div
                class="page-container page-container--with-fab"
                :class="{'page-container--with-tabs': $route.params.orderID && authUserGroup !== 'courier'}"
            >
                <div v-show="tabActive === 0" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <template v-if="authUserGroup !== 'courier'">
                                <div class="page__info-group">
                                    <h2 class="page__info-group-title">Тип заказа</h2>
                                    <HorizontalPicker
                                        v-show="!preloader"
                                        v-model="order.pickup"
                                        :items="[{value: false, name: 'Доставка'}, {value: true, name: 'Самовывоз'}]"
                                        :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                        @input="setPickupType"
                                    />
                                    <ContentPreloader v-show="preloader" height="44" />
                                    <template v-if="$route.params.orderID || order.pickup !== null && order.pickup !== undefined">
                                        <Row class="mt-3">
                                            <Col v-if="order.pickup">
                                                <InputSelect
                                                    v-model="order.shop"
                                                    :options="shopsOptions"
                                                    label="Магазин"
                                                    input-text="address"
                                                    icon="store"
                                                    :disabled="order.status > 3 || ['courier', 'florist'].includes(authUserGroup)"
                                                />
                                            </Col>
                                            <Col v-else>
                                                <TextareaCustom
                                                    v-model="order.receiver_address"
                                                    label="Адрес доставки c городом"
                                                    :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                                    @change="getDeliveryCost"
                                                />
                                                <div
                                                    v-if="!preloader"
                                                    class="d-flex justify-center px-4 py-2 mt-2"
                                                    style="background: #E1ECFE;border-radius: 8px;"
                                                >
                                                    <span style="font-weight: 500;font-size: 12px;line-height: 17px;color: #0D63F3;">
                                                        {{ numberFormat(+order.delivery_cost) }} ₽ доставка
                                                    </span>
                                                </div>
                                                <ContentPreloader v-show="preloader" class="mt-2" height="33" />

                                                <div class="d-flex justify-end ml-a mt-4 mb-6">
                                                    <Checkbox
                                                        v-model="order.address_is_hidden"
                                                        label="Заказчик не знает адрес"
                                                        label-align="right"
                                                        inline
                                                        :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                                        @change="
                                                            order.receiver_address = 'Омск, Согласовать с получателем',
                                                            getDeliveryCost()
                                                        "
                                                    />
                                                </div>
                                            </Col>
                                            <Col :cols="width > 1280 ? 3 : 6">
                                                <v-menu
                                                    ref="deliveryDate"
                                                    offset-y
                                                    absolute
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    max-width="290px"
                                                    min-width="290px"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <InputCustom
                                                            :value="moment(order.delivery_date).format('DD.MM.YYYY')"
                                                            label="Дата заказа"
                                                            left-icon="calendar"
                                                            readonly
                                                            :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                                            v-on="on"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                        v-model="order.delivery_date"
                                                        no-title
                                                        first-day-of-week="1"
                                                        :min="moment().format('YYYY-MM-DD')"
                                                        @input="$refs.deliveryDate.save(order.delivery_date), changeDeliveryTimes()"
                                                    />
                                                </v-menu>
                                            </Col>
                                            <Col :cols="width > 1280 ? 3 : 6">
                                                <div @click="order.status !== 6 && !['courier', 'florist'].includes(authUserGroup) ? isTimeEditModalOpen = true : false">
                                                    <InputCustom
                                                        :value="deliveryTimeString"
                                                        label="Время"
                                                        left-icon="clock"
                                                        readonly
                                                        :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                                        :error="error && (error.delivery_time_start || error.delivery_time_end) ?
                                                            'Проверьте корректность времени' : ''
                                                        "
                                                    />
                                                </div>
                                            </Col>
                                            <Col
                                                v-if="!$route.params.orderID || searchedClient"
                                                :cols="width > 1280 ? 6 : 12"
                                            >
                                                <InputCustom
                                                    v-model="order.order_reason.name"
                                                    label="Повод"
                                                    left-icon="party"
                                                    :disabled="['courier', 'florist'].includes(authUserGroup)"
                                                />
                                            </Col>
                                        </Row>
                                        <div
                                            v-if="!$route.params.orderID || searchedClient"
                                            class="d-flex justify-end ml-a mt-4 mb-6"
                                        >
                                            <Checkbox
                                                v-model="order.order_reason.is_remind"
                                                label="Напоминать"
                                                label-align="right"
                                                inline
                                                :disabled="['courier', 'florist'].includes(authUserGroup)"
                                            />
                                        </div>
                                        <Row>
                                            <Col>
                                                <TextareaCustom
                                                    v-model="order.additional_info"
                                                    label="Доп. информация"
                                                    :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                                />
                                            </Col>
                                            <Col>
                                                <TextareaCustom
                                                    v-model="order.manager_comment"
                                                    label="Комментарий менеджера"
                                                    messages="Текст виден только сотрудникам"
                                                    :disabled="['courier', 'florist'].includes(authUserGroup)"
                                                />
                                            </Col>
                                        </Row>
                                    </template>
                                </div>
                            </template>
                            <template v-else>
                                <div class="page__info-group">
                                    <div v-show="!preloader" class="order__courier-info-wrap">
                                        <div v-if="order.take_photo_with_receiver" class="order__courier-info-badge">
                                            Требуется фото с получателем
                                        </div>
                                        <div class="order__courier-info-item">
                                            <div class="order__courier-info-item-icon">
                                                <Icon color="#8AACCD">calendar</Icon>
                                            </div>
                                            <div class="order__courier-info-item-text">
                                                {{ moment(order.delivery_date).format('DD MMMM') }}, {{ deliveryTimeString }}
                                            </div>
                                        </div>
                                        <div class="order__courier-info-item">
                                            <div class="order__courier-info-item-icon">
                                                <Icon color="#8AACCD">map</Icon>
                                            </div>
                                            <div class="order__courier-info-item-text">
                                                {{ order.receiver_address }}
                                            </div>
                                        </div>
                                        <div class="order__courier-info-item">
                                            <div class="order__courier-info-item-text">
                                                Дополнительная информация: {{ order.additional_info || 'Отсутствует' }}
                                            </div>
                                        </div>
                                        <div class="order__courier-info-item">
                                            <div class="order__courier-info-item-text">
                                                Комментарий менеджера: {{ order.manager_comment || 'Отсутствует' }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="!preloader"
                                            class="d-flex justify-center px-4 py-2 mt-2"
                                            style="background: #E1ECFE;border-radius: 8px;"
                                        >
                                            <span style="font-weight: 500;font-size: 12px;line-height: 17px;color: #0D63F3;">
                                                {{ numberFormat(+order.delivery_cost) }} ₽ доставка
                                            </span>
                                        </div>
                                        <ContentPreloader v-show="preloader" class="mt-2" height="33" />
                                    </div>
                                    <ContentPreloader v-show="preloader" height="100" />
                                </div>
                                <div class="page__info-group">
                                    <button
                                        v-show="!preloader"
                                        type="button"
                                        class="order__courier-btn-item"
                                        @click.prevent="isCourierProductsListModalShow = true"
                                    >
                                        <div class="order__courier-btn-item-icon">
                                            <Icon>file-minus</Icon>
                                        </div>
                                        <div class="order__courier-btn-item-text">Список товаров</div>
                                        <Icon class="order__courier-btn-item-arrow" color="#8AACCD">
                                            chevron-right
                                        </Icon>
                                    </button>
                                    <button
                                        v-if="order.take_photo_with_receiver && !preloader"
                                        type="button"
                                        class="order__courier-btn-item"
                                        @click.prevent="isPhotoWithReceiverModalShow = true"
                                    >
                                        <div class="order__courier-btn-item-icon">
                                            <Icon>camera</Icon>
                                        </div>
                                        <div class="order__courier-btn-item-text">Фото с получателем</div>
                                        <Icon class="order__courier-btn-item-arrow" color="#8AACCD">
                                            chevron-right
                                        </Icon>
                                    </button>
                                    <ContentPreloader v-show="preloader" height="80" />
                                </div>
                            </template>
                            <template v-if="$route.params.orderID || order.pickup !== null && order.pickup !== undefined">
                                <div class="page__info-group">
                                    <h2 class="page__info-group-title">Заказчик</h2>
                                    <template v-if="authUserGroup !== 'courier'">
                                        <Row>
                                            <Col :cols="width > 1024 ? 6 : 12">
                                                <InputCustom
                                                    v-model="order.user.phone"
                                                    label="Телефон"
                                                    :hint="searchedClientHint"
                                                    left-icon="phone"
                                                    clearable
                                                    :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                                    :disabled-left-icon="!order.user.phone"
                                                    :right-icon="order.user.phone ? 'whatsapp' : null"
                                                    :error="error && error[0] === 'Не поддерживаемый формат телефона.' ? error[0] : ''"
                                                    @click:left="phoneCall(order.user.phone)"
                                                    @click:right="openLink(`https://wa.me/${order.user.phone}`)"
                                                    @input="setSearchedClient"
                                                />
                                            </Col>
                                            <Col :cols="width > 1024 ? 6 : 12">
                                                <InputCustom
                                                    v-model="order.user.first_name"
                                                    label="Имя"
                                                    clearable
                                                    :right-icon="!['courier', 'florist'].includes(authUserGroup) && order.user ? 'new-window': null"
                                                    :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                                    @click:right="openLink(`/clients/${order.user.id}`)"
                                                />
                                            </Col>
                                            <div
                                                v-if="!$route.params.orderID && !!searchedClient.phone && authUserGroup !== 'courier'"
                                                class="d-flex align-center mt-3 width-100"
                                            >
                                                <InputCustom
                                                    v-model.number="bonuses.value"
                                                    type="number"
                                                    label="Оплата бонусами клиента"
                                                    clearable
                                                    :error="bonuses.error ? bonuses.error : ''"
                                                    :disabled="bonuses.readonly || promocodeField.active"
                                                    style="flex: 1 1 auto; margin-right: 16px"
                                                    @keyup.enter="applyBonuses"
                                                    @click:clear="clearBonus"
                                                />
                                                <Checkbox
                                                    :value="bonuses.readonly"
                                                    :label="`Бонусы ${numberFormat(+searchedClient.number_of_bonus)} ₽`"
                                                    label-align="right"
                                                    inline
                                                    :disabled="promocodeField.active"
                                                    style="flex: 1 0 auto"
                                                    @change="!bonuses.readonly ? applyBonuses() : clearBonus()"
                                                />
                                            </div>
                                        </Row>
                                    </template>
                                    <div v-else v-show="!preloader" class="order__user-card-wrap">
                                        <div class="order__user-card-icon">
                                            <Icon>user</Icon>
                                        </div>
                                        <div class="order__user-card-info">
                                            <div class="order__user-card-name">
                                                {{ order.user.first_name }}
                                            </div>
                                            <div class="order__user-card-phone">
                                                {{ order.user.phone }}
                                            </div>
                                        </div>
                                        <div class="order__user-card-call-btn">
                                            <a :href="`tel:${order.user.phone}`">
                                                <Icon color="#4A8AF6">phone</Icon>
                                            </a>
                                        </div>
                                    </div>
                                    <ContentPreloader v-if="authUserGroup === 'courier'" v-show="preloader" height="80" />
                                </div>
                                <div class="page__info-group">
                                    <h2 class="page__info-group-title">Получатель</h2>
                                    <template v-if="authUserGroup !== 'courier'">
                                        <Row>
                                            <Col :cols="width > 1024 ? 6 : 12">
                                                <InputCustom
                                                    v-model="order.receiver_phone"
                                                    label="Телефон"
                                                    left-icon="phone"
                                                    clearable
                                                    :disabled="order.status === 6 || order.user_is_receiver || ['courier', 'florist'].includes(authUserGroup)"
                                                    :disabled-left-icon="order.user_is_receiver || !order.receiver_phone.length > 0"
                                                    :error="error && error.receiver_phone ? error.receiver_phone[0] : ''"
                                                    @input="clearError('receiver_phone')"
                                                    @click:left="phoneCall(order.receiver_phone)"
                                                />
                                            </Col>
                                            <Col :cols="width > 1024 ? 6 : 12">
                                                <InputCustom
                                                    v-model="order.receiver_name"
                                                    label="Имя"
                                                    clearable
                                                    :disabled="order.status === 6 || order.user_is_receiver || ['courier', 'florist'].includes(authUserGroup)"
                                                />
                                            </Col>
                                        </Row>
                                        <div class="d-flex justify-end ml-a mt-4">
                                            <Checkbox
                                                :value="!order.user_is_receiver"
                                                label="Другой получатель"
                                                label-align="right"
                                                inline
                                                :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                                @change="otherReceiverHandler"
                                            />
                                        </div>
                                        <div class="d-flex justify-end ml-a mt-4">
                                            <Checkbox
                                                v-model="order.is_anonymous_order"
                                                label="Анонимный заказ"
                                                label-align="right"
                                                inline
                                                :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                            />
                                        </div>
                                        <div v-if="!order.pickup" class="d-flex justify-end ml-a mt-4">
                                            <Checkbox
                                                v-model="order.take_photo_with_receiver"
                                                label="Сделать фото с получателем"
                                                label-align="right"
                                                inline
                                                :disabled="order.status === 6 || order.pickup || ['courier', 'florist'].includes(authUserGroup)"
                                            />
                                        </div>
                                        <div
                                            v-if="$route.params.orderID && order.take_photo_with_receiver && !order.photo_with_receiver"
                                            class="d-flex justify-end ml-a mt-4"
                                        >
                                            <Checkbox
                                                v-model="order.is_photo_added"
                                                label="Отказались фотографироваться"
                                                label-align="right"
                                                inline
                                                :disabled="order.status === 6 || order.pickup"
                                            />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div v-if="order.is_anonymous_order" class="order__warning-message">
                                            Анонимный заказ! Не сообщайте получателю кто заказчик!
                                        </div>
                                        <div v-show="!preloader" class="order__user-card-wrap">
                                            <div class="order__user-card-icon">
                                                <Icon>user</Icon>
                                            </div>
                                            <div class="order__user-card-info">
                                                <div class="order__user-card-name">
                                                    {{ order.receiver_name }}
                                                </div>
                                                <div class="order__user-card-phone">
                                                    {{ order.receiver_phone }}
                                                </div>
                                            </div>
                                            <div class="order__user-card-call-btn">
                                                <a :href="`tel:${order.receiver_phone}`">
                                                    <Icon color="#4A8AF6">phone</Icon>
                                                </a>
                                            </div>
                                        </div>
                                        <ContentPreloader v-show="preloader" height="80" />
                                    </template>
                                </div>
                                <template v-if="!$route.params.orderID && !order.pickup">
                                    <div class="page__info-group">
                                        <h2 class="page__info-group-title">Магазин</h2>
                                        <InputSelect
                                            v-model="order.shop"
                                            :options="shopsOptions"
                                            input-text="address"
                                            :disabled="order.status > 3 || ['courier', 'florist'].includes(authUserGroup)"
                                        />
                                    </div>
                                    <div class="page__info-group">
                                        <h2 class="page__info-group-title">Курьер</h2>
                                        <InputSelect
                                            v-model="order.courier"
                                            :options="[{ id: null, first_name: 'Не выбран' }, ...couriers]"
                                            input-text="first_name"
                                            searchable
                                            :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                        />
                                    </div>
                                </template>
                                <div v-if="authUserGroup !== 'courier'" class="page__info-group">
                                    <div class="price-list__wrap">
                                        <div class="price-list__item">
                                            <div class="price-list__item-label">Итого</div>
                                            <div class="price-list__item-value">
                                                {{ numberFormat(+order.final_cost) }} ₽
                                            </div>
                                        </div>
                                        <div class="price-list__item">
                                            <div class="price-list__item-label">Оплачено заказчиком:</div>
                                            <div class="price-list__item-value">
                                                {{ numberFormat(order.amount_user_paid ? +order.amount_user_paid : 0) }} ₽
                                            </div>
                                        </div>
                                        <div v-if="!order.pickup" class="price-list__item">
                                            <div class="price-list__item-label">Доставка:</div>
                                            <div class="price-list__item-value d-flex">
                                                <Button
                                                    v-if="!['courier', 'florist'].includes(authUserGroup)"
                                                    class="mr-1"
                                                    compact
                                                    text
                                                    @click="isDeliveryPriceChangeMode = !isDeliveryPriceChangeMode"
                                                >
                                                    <Icon>
                                                        {{ !isDeliveryPriceChangeMode ? 'edit-2' : 'check'}}
                                                    </Icon>
                                                </Button>
                                                <span v-if="!isDeliveryPriceChangeMode">
                                                    {{ numberFormat(+order.delivery_cost) }} ₽
                                                </span>
                                                <InputCustom
                                                    v-else
                                                    v-model.number="order.delivery_cost"
                                                    type="number"
                                                    compact
                                                    :disabled="order.status >= 4 || ['courier', 'florist'].includes(authUserGroup)"
                                                    style="width: 80px"
                                                    @change="calculateSetPrice"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            v-if="order.pickup === false && calculatedOrderData.cost_to_delivery_free"
                                            class="price-list__notification"
                                        >
                                            {{ numberFormat(calculatedOrderData.cost_to_delivery_free) }}₽ до бесплатной доставки
                                        </div>
                                        <div v-if="+order.discount" class="price-list__item price-list__item--red">
                                            <div class="price-list__item-label">Скидка:</div>
                                            <div class="price-list__item-value">-{{ numberFormat(+order.discount) }} ₽</div>
                                        </div>
                                        <div
                                            v-if="order.reason_change_discount || order.promocode"
                                            class="price-list__notification"
                                        >
                                            {{
                                                order.reason_change_discount ? order.reason_change_discount :
                                                    order.promocode ? `Применен промокод ${order.promocode.name}` : ''
                                            }}
                                        </div>
                                        <div class="price-list__item price-list__item--red">
                                            <div class="price-list__item-label">
                                                Бонусов {{ !$route.params.orderID ? 'будет' : '' }} потрачено:
                                            </div>
                                            <div class="price-list__item-value">
                                                -{{ numberFormat($route.params.orderID ? +order.debiting_bonuses : bonuses.fixedValue) || 0 }} ₽
                                            </div>
                                        </div>
                                        <div class="price-list__item">
                                            <div class="price-list__item-label">
                                                Бонусов начислено:
                                            </div>
                                            <div class="price-list__item-value">
                                                {{ searchedClient.not_accrue_bonuses ? 0 : numberFormat(+order.number_of_bonus) }} ₽
                                                ({{ addedBonusesPercentValue }}%)
                                            </div>
                                        </div>
                                        <div
                                            v-if="calculatedOrderData.next_bonuses_step && calculatedOrderData.next_bonuses_step.amount_to_next_bonuses_step >=0"
                                            class="price-list__notification"
                                        >
                                            {{ numberFormat(calculatedOrderData.next_bonuses_step.amount_to_next_bonuses_step) }}₽ до бонусов {{ Math.round(calculatedOrderData.next_bonuses_step.next_bonuses_percent * 100) }}%
                                        </div>
                                        <div class="price-list__item">
                                            <div class="price-list__item-label">
                                                Себестоимость:
                                            </div>
                                            <div class="price-list__item-value">
                                                {{ numberFormat(+order.total_prime_cost) }} ₽
                                            </div>
                                        </div>
                                        <Row class="mt-3">
                                            <Col :cols="width > 1280 ? 6 : 12">
                                                <InputSelect
                                                    v-model="order.payment_method"
                                                    :options="filteredPaymentMethods"
                                                    placeholder="Способ оплаты"
                                                    :disabled="order.status >= 2 || ['courier', 'florist'].includes(authUserGroup)"
                                                    :error="error && error.payment_method ? error.payment_method[0] : ''"
                                                    @input="clearError('payment_method')"
                                                />
                                            </Col>
                                            <Col v-if="!['courier', 'florist'].includes(authUserGroup)" :cols="width > 1280 ? 6 : 12">
                                                <InputSelect
                                                    v-model="order.source"
                                                    :options="sourcesList"
                                                    placeholder="Источник"
                                                    :disabled="order.status >= 2 || ['courier', 'florist'].includes(authUserGroup)"
                                                    :error="error && error.source ? error.source[0] : ''"
                                                    @input="clearError('source')"
                                                />
                                            </Col>
                                        </Row>

                                        <div class="mt-4" :class="{'d-flex justify-end ml-a': width > 1280}">
                                            <Checkbox
                                                :value="order.cashbox_viewed"
                                                label="Распечатан чек"
                                                :label-align="width > 1280 ? 'right' : ''"
                                                :inline="width > 1280"
                                                :disabled="order.status === 6 || !['managers', 'supervisor', 'administrators'].includes(authUserGroup)"
                                                @change="order.cashbox_viewed = !order.cashbox_viewed"
                                            />
                                        </div>

                                        <div v-if="$route.params.orderID" class="mt-4" :class="{'d-flex justify-end ml-a': width > 1280}">
                                            <Checkbox
                                                :value="order.order_type_outlet === 1"
                                                label="Заказ для кофейни"
                                                :label-align="width > 1280 ? 'right' : ''"
                                                :inline="width > 1280"
                                                disabled
                                            />
                                        </div>

                                        <div
                                            v-if="$route.params.orderID && !['courier', 'florist'].includes(authUserGroup) &&
                                                paymentMethods.find(item => item.name === 'СБП') &&
                                                paymentMethods.find(item => item.name === 'СБП').id === order.payment_method"
                                            class="mt-4"
                                            :class="{'d-flex justify-end ml-a': width > 1280}"
                                        >
                                            <Checkbox
                                                :value="order.is_transaction"
                                                label="Успешная транзакция"
                                                :label-align="width > 1280 ? 'right' : ''"
                                                :inline="width > 1280"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="order.payment_method === 1306" class="page__info-group">
                                    <h2 class="page__info-group-title">Оплата по счету</h2>
                                    <Row>
                                        <Col>
                                            <InputCustom
                                                v-model="order.company_name"
                                                label="Название компании"
                                                :disabled="order.status === 6 || ['courier'].includes(authUserGroup)"
                                            />
                                        </Col>
                                        <Col>
                                            <InputCustom
                                                v-model="order.legal_address"
                                                label="Юридический адрес"
                                                :disabled="order.status === 6 || ['courier'].includes(authUserGroup)"
                                            />
                                        </Col>
                                        <Col>
                                            <InputCustom
                                                v-model="order.tax_id"
                                                label="ИНН"
                                                :disabled="order.status === 6 || ['courier'].includes(authUserGroup)"
                                            />
                                        </Col>
                                        <Col>
                                            <InputCustom
                                                v-model="order.kpp_field"
                                                label="КПП"
                                                :disabled="order.status === 6 || ['courier'].includes(authUserGroup)"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </template>
                        </div>
                        <div class="page-small-column page-small-column--mobile-top">
                            <template v-if="authUserGroup !== 'courier'">
                                <div v-show="!preloader" class="order__product-list">
                                    <div class="order__product-list-title-wrap">
                                        <div class="order__product-list-title">
                                            Состав <span>{{ order.order_list.length }}</span>
                                        </div>
                                        <div class="order__product-list-title-triggers">
                                            <Button compact text @click="orderListCardsMode = 'mini'">
                                                <Icon :color="orderListCardsMode === 'mini' ? '#0D63F3' : '#8AACCD'">
                                                    list-ul
                                                </Icon>
                                            </Button>
                                            <Button compact text @click="orderListCardsMode = 'big'">
                                                <Icon :color="orderListCardsMode === 'big' ? '#0D63F3' : '#8AACCD'">
                                                    grid
                                                </Icon>
                                            </Button>
                                        </div>
                                    </div>
                                    <OrderProductCard
                                        v-for="item in order.order_list"
                                        :key="item.vendor_code"
                                        :product="item"
                                        :order="order"
                                        :size="orderListCardsMode"
                                        @toggleDiscount="applyDiscountForGood(item)"
                                        @changePrice="item.price = $event, calculateSetPrice(), orderListChanged = true"
                                        @changeCount="item.count = $event, calculateSetPriceDebounced(), orderListChanged = true"
                                        @openEquipmentModal="updateGoodEquipment(item)"
                                        @click:remove="removeProductFromOrderList(item)"
                                    />
                                </div>
                                <ContentPreloader
                                    v-show="preloader && $route.params.orderID"
                                    class="mb-3"
                                    height="36"
                                />
                                <ContentPreloader
                                    v-show="preloader && $route.params.orderID"
                                    v-for="(i, index) in 3"
                                    :key="i"
                                    :class="{'mt-4': index}"
                                    height="88"
                                />
                            </template>
                            <template v-else>
                                <!-- <div v-show="!preloader" id="addressMap" class="order__map" /> -->
                                <div class="page__info-group">
                                    <h2 class="page__info-group-title">Фото флориста</h2>
                                    <ImageLoader
                                        v-if="order.florist_photo && !preloader"
                                        ref="floristPhoto"
                                        :value="order.florist_photo"
                                        :disabled="authUser.read_only || ['courier', 'administrators'].includes(authUserGroup)"
                                        @change="changePhoto($event, 'florist')"
                                    />
                                    <div v-else-if="!preloader">Отсутствует</div>
                                </div>
                                <ContentPreloader v-show="preloader" :height="width > 1024 ? 500 : 300" />
                            </template>
                        </div>
                    </div>
                </div>
                <div v-if="$route.params.orderID && authUserGroup !== 'courier'" v-show="tabActive === 1" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Магазин</h2>
                                <InputSelect
                                    v-model="order.shop"
                                    :options="shopsOptions"
                                    input-text="address"
                                    :disabled="order.status > 3 || ['courier', 'florist'].includes(authUserGroup)"
                                />
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Курьер</h2>
                                <InputSelect
                                    v-model="order.courier"
                                    :options="[{ id: null, first_name: 'Не выбран' }, ...couriers]"
                                    input-text="first_name"
                                    searchable
                                    :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                />
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Флорист</h2>
                                <InputSelect
                                    v-model="order.florist"
                                    :options="[{ id: null, first_name: 'Не выбран' }, ...florists]"
                                    input-text="first_name"
                                    searchable
                                    :disabled="order.status === 6 || ['courier'].includes(authUserGroup)"
                                />
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Отмена заказа</h2>
                                <Row>
                                    <Col>
                                        <InputSelect
                                            v-model="order.return_method"
                                            :options="[{ value: 'cash', name: 'Денежными средствами' }, { value: 'bonus', name: 'Бонусами' }]"
                                            label="Способ отмены заказа"
                                            input-value="value"
                                            disabled
                                        />
                                    </Col>
                                    <Col>
                                        <TextareaCustom
                                            v-model="order.cancel_reason"
                                            label="Причина отмены заказа"
                                            :disabled="['courier', 'florist'].includes(authUserGroup)"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Реферальный</h2>
                                <InputSelect
                                    v-model="order.is_referral"
                                    :options="[
                                        { value: null, name: 'Не задан' },
                                        { value: true, name: 'Реферальный' },
                                        { value: false, name: 'Не реферальный' },
                                    ]"
                                    input-value="value"
                                    disabled
                                />
                            </div>
                            <div v-if="order.has_review" class="page__info-group">
                                <h2 class="page__info-group-title">Отзыв</h2>
                                <Row>
                                    <Col>
                                        <v-rating
                                            v-model="orderReview.rating"
                                            medium
                                            background-color="#FFC200"
                                            color="#FFC200"
                                            :readonly="!['supervisor', 'administrators'].includes(authUserGroup)"
                                        />
                                    </Col>
                                    <Col>
                                        <TextareaCustom
                                            v-model="orderReview.text"
                                            label="Отзыв клиента"
                                            right-icon="new-window"
                                            :disabled="!['supervisor', 'administrators'].includes(authUserGroup)"
                                            @click:right="openLink(`/reviews/${order.review_id}`)"
                                        />
                                    </Col>
                                    <Col>
                                        <TextareaCustom
                                            v-model="orderReview.employee_comment"
                                            label="Комментарий сотрудника к отзыву"
                                            :messages="orderReview.employee ? `Изменено пользователем: ${orderReview.employee}` : ''"
                                            :disabled="['courier', 'florist'].includes(authUserGroup)"
                                        />
                                    </Col>
                                    <Col>
                                        <Checkbox
                                            v-model="orderReview.is_active"
                                            label="Выводить на сайте"
                                            :disabled="['courier', 'florist'].includes(authUserGroup)"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div class="page-small-column">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Фото флориста</h2>
                                <ImageLoader
                                    ref="floristPhoto"
                                    :value="order.florist_photo"
                                    :disabled="authUser.read_only || ['courier', 'administrators'].includes(authUserGroup)"
                                    @change="changePhoto($event, 'florist')"
                                />
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Фото курьера</h2>
                                <ImageLoader
                                    :value="order.photo_with_receiver"
                                    :disabled="!order.take_photo_with_receiver || authUser.read_only || authUserGroup === 'florist'"
                                    @change="changePhoto($event, 'courier')"
                                />
                                <Checkbox
                                    v-if="$route.params.orderID && order.take_photo_with_receiver && !order.photo_with_receiver"
                                    v-model="order.is_photo_added"
                                    label="Отказались фотографироваться"
                                    class="mt-2"
                                    :disabled="order.status === 6 || order.pickup"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="$route.params.orderID && ['managers', 'administrators', 'supervisor'].includes(authUserGroup) &&
                        franchiseTariff.tariff && franchiseTariff.tariff.includes('site')"
                    v-show="tabActive === 2"
                    class="tabs__content"
                >
                    <ChatMessageSection minimize />
                </div>
                <div v-if="$route.params.orderID && !['courier', 'florist'].includes(authUserGroup)" v-show="tabActive === 3" class="tabs__content">
                    <OrderHistory />
                </div>
            </div>
            <OrderBottomBar
                v-if="order.pickup !== null && order.pickup !== undefined && (tabActive === 0 || tabActive === 1) && authUserGroup !== 'courier'"
                :order-cost="numberFormat(+order.final_cost)"
                :sale-error="!!error.reason_change_discount || !!error.promocode"
                :disabled-catalog="order.status >= 4 || authUserGroup === 'courier'"
                :disabled-sale="!order.order_list.length || order.status >= 4 || authUserGroup === 'courier'"
                :disabled-save="authUser.read_only || !showSaveBtn || preloader"
                @click:catalog="$store.commit('ui/OPEN_MODAL', 'modal-goods')"
                @click:sale="discountModal.isDiscountModalOpen = true"
                @click:save="eventHandlerButton"
            />
        </div>
        <UniversalModal v-model="discountModal.isDiscountModalOpen">
            <UniversalModalHeader title="Добавить скидку" @click:right="discountModal.isDiscountModalOpen = false" />
            <div class="universal-modal__content">
                <HorizontalPicker
                    v-if="!$route.params.orderID && searchedClient && searchedClient.id"
                    v-model="discountModal.tab"
                    :items="[{value: 0, name: 'Рубли'}, {value: 1, name: 'Промокод'}]"
                />
                <div v-show="discountModal.tab === 0" class="mt-4">
                    <Row>
                        <Col>
                            <InputCustom
                                :value="+order.discount"
                                label="Сумма"
                                type="number"
                                clearable
                                @input="order.discount = $event"
                                @change="discountVariants.forEach(item => item.active = false),
                                    clearPromocodeField(), calculateSetPriceDebounced()"
                                @click:clear="discountVariants.forEach(item => item.active = false),
                                    clearPromocodeField(), calculateSetPriceDebounced()"
                            />
                        </Col>
                        <Col v-if="order.reason_change_discount || initialDiscountValue !== +order.discount">
                            <TextareaCustom
                                v-model="order.reason_change_discount"
                                label="Причина изменения скидки"
                                :error="error.reason_change_discount ? error.reason_change_discount : ''"
                                @input="$event ? clearError('reason_change_discount') : false"
                            />
                        </Col>
                    </Row>
                    <div class="d-flex flex-wrap my-8" style="gap: 8px">
                        <Button
                            v-for="variant in discountVariants"
                            :key="variant.value"
                            :bordered="!variant.active"
                            :disabled="promocodeField.active"
                            style="flex: 1 1 100%;max-width: calc(25% - 8px)"
                            @click.prevent="applyDiscountVariant(variant)"
                        >
                            {{ variant.value }} %
                        </Button>
                    </div>
                </div>
                <div v-if="!$route.params.orderID" v-show="discountModal.tab === 1" class="mt-4">
                    <InputCustom
                        v-model="promocodeField.value"
                        label="Промокод"
                        :disabled="promocodeField.active || !!bonuses.fixedValue"
                        :error="error && error.promocode ? error.promocode[0] : ''"
                        @input="clearError('promocode')"
                    />
                </div>
                <div class="price-list__wrap mt-4">
                    <div class="price-list__item">
                        <div class="price-list__item-label">К оплате</div>
                        <div class="price-list__item-value">
                            {{ numberFormat(+order.final_cost) }} ₽
                        </div>
                    </div>
                    <Button
                        class="mt-4"
                        block
                        :disabled="discountModal.tab === 1 && !!bonuses.fixedValue"
                        @click.prevent="discountModal.tab === 0 ? applyDiscountModal() : promocodeHandler()"
                    >
                        {{
                            discountModal.tab === 0 || !promocodeField.active ? 'Применить' : 'Удалить промокод'
                        }}
                    </Button>
                </div>
            </div>
        </UniversalModal>
        <UniversalModal v-model="isTimeEditModalOpen">
            <UniversalModalHeader title="Время получения" @click:right="isTimeEditModalOpen = false" />
            <div class="universal-modal__content">
                <HorizontalPicker
                    v-model="order.delivery_time_type"
                    :items="[{value: 1, name: 'Ближайшее'}, {value: 2, name: 'Согласовать'}, {value: 3, name: 'Вручную'}]"
                    :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                    @input="toggleDeliveryTimeType"
                />
                <Row class="mt-3">
                    <Col v-if="!order.pickup">
                        <InputSelect
                            v-model="order.delivery_time_type"
                            :options="deliveryIntervals"
                            label="Интервал"
                            @input="setDeliveryTimeByInterval"
                        />
                    </Col>
                    <Col cols="6">
                        <v-menu
                            ref="deliveryTimeStart"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <InputCustom
                                    :value="order.delivery_time_start ? moment(order.delivery_time_start).utc().format('HH:mm') : ''"
                                    label="C"
                                    readonly
                                    :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                    :error="error && error.delivery_time_start ? 'Проверьте корректность времени' : ''"
                                    v-on="on"
                                />
                            </template>
                            <v-time-picker
                                :value="order.delivery_time_start ? moment(order.delivery_time_start).utc().format('HH:mm') : ''"
                                format="24hr"
                                full-width
                                scrollable
                                @input="setDeliveryTime($event, 'start'), clearError('delivery_time_start')"
                                @click:minute="$refs.deliveryTimeStart.save()"
                            />
                        </v-menu>
                    </Col>
                    <Col cols="6">
                        <v-menu
                            ref="deliveryTimeEnd"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <InputCustom
                                    :value="order.delivery_time_end ? moment(order.delivery_time_end).utc().format('HH:mm') : ''"
                                    label="До"
                                    readonly
                                    :disabled="order.status === 6 || ['courier', 'florist'].includes(authUserGroup)"
                                    :error="error && error.delivery_time_end ? 'Проверьте корректность времени' : ''"
                                    v-on="on"
                                />
                            </template>
                            <v-time-picker
                                :value="order.delivery_time_end ? moment(order.delivery_time_end).utc().format('HH:mm') : ''"
                                format="24hr"
                                full-width
                                scrollable
                                @input="setDeliveryTime($event, 'end'), clearError('delivery_time_end')"
                                @click:minute="$refs.deliveryTimeEnd.save()"
                            />
                        </v-menu>
                    </Col>
                </Row>
            </div>
        </UniversalModal>
        <UniversalModal v-model="isProductEquipmentListChangeModalShow" size="full">
            <ProductEquipmentListChange
                :good="changingGood"
                @update="updateEquipSet"
                @closeModal="isProductEquipmentListChangeModalShow = false"
            />
        </UniversalModal>
        <UniversalModal v-if="authUserGroup === 'courier'" v-model="isPhotoWithReceiverModalShow">
            <UniversalModalHeader title="Фото с получателем" @click:right="isPhotoWithReceiverModalShow = false" />
            <div class="universal-modal__content">
                <ImageLoader
                    v-show="!preloader"
                    :value="order.photo_with_receiver"
                    :disabled="!order.take_photo_with_receiver || authUser.read_only || authUserGroup === 'florist'"
                    @change="changePhoto($event, 'courier')"
                />

                <ContentPreloader v-show="preloader" height="150" />

                <Checkbox
                    v-if="$route.params.orderID && order.take_photo_with_receiver && !order.photo_with_receiver"
                    v-model="order.is_photo_added"
                    label="Отказались фотографироваться"
                    class="mt-2"
                    :disabled="order.status === 6 || order.pickup"
                />
            </div>
        </UniversalModal>
        <UniversalModal v-if="authUserGroup === 'courier'" v-model="isCourierProductsListModalShow">
            <OrderCourierProductsList :order="order" @closeModal="isCourierProductsListModalShow = false" />
        </UniversalModal>
        <UniversalModal v-if="authUserGroup === 'courier'" v-model="isCourierPhotoNotificationModalShow">
            <UniversalModalHeader title="Фото с получателем" @click:right="isCourierPhotoNotificationModalShow = false" />
            <div class="universal-modal__content">
                <div class="d-flex justify-center mb-4">
                    <img src="@/assets/img/content/courierPhotoNotificationIllustration.svg" alt="">
                </div>
                <Button class="width-100" @click="isCourierPhotoNotificationModalShow = false">
                    Сделаю
                </Button>
            </div>
        </UniversalModal>
        <MiniModal
            v-model="exit.isShowExitModal"
            title="Вы уверены, что хотите выйти не сохранив изменения?"
            additional-btn-text="Выйти"
            main-btn-text="Отмена"
            @additionalBtnClick="exit.isShowExitModal = false, $router.back()"
            @mainBtnClick="exit.isShowExitModal = false"
        />
        <MiniModal
            v-model="isShowCheckEquipmentModal"
            title="Соответствует ли состав букета действительности?"
            additional-btn-text="Да"
            main-btn-text="Отмена"
            @additionalBtnClick="isShowCheckEquipmentModal = false, changeStatus(4, $route.params.orderID)"
            @mainBtnClick="isShowCheckEquipmentModal = false"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import moment from "moment";
import { geocode } from 'esri-leaflet-geocoder';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';

import {
    getDeliveryPrice,
    patchOrderSet,
    setStatusOrder,
    updateOrderID,
    apiEditReview,
    getBillPDF,
    getUserByPhone,
    getUserByGroup,
    calculateDeliveryPrice,
    fetchReviewsID,
    getOrderTimeIntervals,
    postPrintChequeAtol,
    createOrderPaymentLink
} from "@/api/django";

import { debounce } from '@/utils/functions';
import { numberFormat } from "@/utils/format";

import MiniModal from "@/components/modal/MiniModal";
import OrderHistory from '@/components/app/orders/OrderHistory';
import ChatMessageSection from '@/components/app/chat/ChatMessageSection';
import OrderBottomBar from '@/components/app/orders/OrderBottomBar';
import OrderProductCard from '@/components/app/orders/OrderProductCard'
import ProductEquipmentListChange from "@/components/app/orders/modal/ProductEquipmentListChange";
import OrderCourierProductsList from "@/components/app/orders/modal/OrderCourierProductsList";
import ImageLoader from '@/components/app/ImageLoader'

// let addressMap = null;

export default {
    name: 'TheOrderCreate',
    metaInfo() {
        return { title: this.$route.params.orderID ? `Заказ ${this.$route.params.orderID}` : 'Новый заказ' }
    },
    components: {
        MiniModal, OrderHistory, ChatMessageSection, OrderBottomBar, OrderProductCard, ImageLoader,
        ProductEquipmentListChange, OrderCourierProductsList
    },
    data() {
        return {
            order: {
                additional_info: "",
                address_is_hidden: false,
                available_statuses: {},
                cancel_reason: '',
                city: null,
                company_name: null,
                courier: null,
                datetime_open_page: null,
                date_created: "",
                delivery_cost: 0,
                delivery_date: this.moment().format('YYYY-MM-DD'),
                delivery_time_start: null,
                delivery_time_end: null,
                delivery_in_city: true,
                delivery_time_type: 3,
                discount: 0,
                final_cost: 0,
                florist: null,
                is_anonymous_order: false,
                is_photo_added: false,
                is_transaction: false,
                kpp_field: null,
                last_change: "",
                legal_address: null,
                manager_comment: "",
                number_of_bonus: 0,
                order_list: [],
                order_reason: {
                    name: '',
                    is_remind: false
                },
                order_type_outlet: 0,
                pickup: null,
                pickup_time_type: 3,
                postcard: "",
                reason_change_discount: '',
                return_method: null,
                receiver_address: "",
                receiver_appartement: "",
                receiver_name: "",
                receiver_phone: "",
                shop: null,
                status: 1,
                source: null,
                tax_id: null,
                total_prime_cost: 0,
                take_photo_with_receiver: false,
                total_cost: 0,
                user_comment: "",
                user: {
                    first_name: '',
                    phone: ''
                },
                user_is_receiver: true,
                visible_id: null,
            },
            fab_open: false,
            tabActive: 0,
            error: {},
            orderID: this.$route.params.orderID || null, // Сохраняем сюда id после создания нового заказа, а потом создаем счет на оплату
            florists: [],
            couriers: [],
            bonuses: {
                value: 0,
                fixedValue: 0,
                maxBonuses: 0,
                error: null,
                readonly: false
            },
            promocodeField: {
                value: '',
                active: false
            },
            discountModal: {
                isDiscountModalOpen: false,
                tab: 0
            },
            discountVariants: [
                { value: 10, active: false },
                { value: 15, active: false },
                { value: 20, active: false },
                { value: 30, active: false },
                { value: 35, active: false },
                { value: 40, active: false },
                { value: 50, active: false },
                { value: 100, active: false },
            ],
            initialDiscountValue: 0,
            exit: {
                isShowExitModal: false,
                defaultOrderData: {}
            },
            searchedClient: {},
            orderReview: {},
            orderListChanged: false,
            calculatedOrderData: {},
            isProductEquipmentListChangeModalShow: false,
            changingGood: {},
            isDeliveryPriceChangeMode: false,
            isTimeEditModalOpen: false,
            isPageLoaded: false,
            receiverAddressCoords: [],
            isShowCheckEquipmentModal: false,
            isPhotoWithReceiverModalShow: false,
            isCourierProductsListModalShow: false,
            isCourierPhotoNotificationModalShow: false,
            orderListCardsMode: 'mini',
            deliveryIntervals: []
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
            width: 'ui/width',
            sources: 'data/sources',
            shops: "data/shops",
            statuses: 'orders/statuses',
            preloader: 'ui/preloader',
            paymentMethods: 'paymentType/paymentMethods',
            referer: 'ui/origin',
            orderChat: 'chat/orderChat',
            franchiseTariff: 'franchises/franchiseTariff',
            orderListVuex: "order/order_list",
            changingGoods: "changeEquipments/changingGoods",
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
        sourcesList() {
            if (!this.$route.params.orderID) {
                return this.sources.filter(item => item.type === 'manual')
            }
            return this.sources
        },
        filteredPaymentMethods() {
            if (!this.$route.params.orderID && !['administrators', 'supervisor'].includes(this.authUserGroup)) {
                return this.paymentMethods.filter(item => !item.is_for_cashbox)
            }
            return this.paymentMethods
        },
        searchedClientHint() {
            if (!this.$route.params.orderID) {
                if (this.searchedClient.phone) {
                    return `Пользователь найден (${this.searchedClient.phone})`;
                } else if (this.order.user.phone) {
                    return 'Пользователь не найден';
                }
            }
            return '';
        },
        showSaveBtn() {
            if ((!this.order.pickup
                && (!this.order.order_list.length || !this.order.user.first_name || !this.order.user.phone || !this.order.receiver_name || !this.order.receiver_phone || !this.order.receiver_address || !this.order.delivery_date || !this.order.delivery_time_type)
            )
                || (this.order.pickup
                    && (!this.order.order_list.length || !this.order.user.first_name || !this.order.user.phone || !this.order.receiver_name || !this.order.receiver_phone || !this.order.receiver_address || !this.order.delivery_time_type)
                )) {
                return false
            }
            return true;
        },
        deliveryTimeString() {
            if (this.order.delivery_time_start && this.order.delivery_time_end) {
                return `с ${this.moment(this.order.delivery_time_start).utc().format('HH:mm')} до ${this.moment(this.order.delivery_time_end).utc().format('HH:mm')}`
            } else if (this.order.delivery_time_type === 3) {
                return 'Вручную'
            } else if (this.order.delivery_time_type === 2) {
                return 'Согласовать с получателем'
            } else if (this.order.delivery_time_type === 1) {
                return 'Ближайшее'
            }
            return ''
        },
        addedBonusesPercentValue() {
            let userPaidValue = this.order.amount_user_paid ? +this.order.amount_user_paid : 0;
            if (!this.$route.params.orderID || !userPaidValue) {
                userPaidValue = +this.order.final_cost
            }
            let addedBonusesValue = this.searchedClient.not_accrue_bonuses ? 0 : +this.order.number_of_bonus;

            if (!this.order.pickup) {
                userPaidValue -= +this.order.delivery_cost;
            }

            let result = Math.ceil(addedBonusesValue * 100 / userPaidValue);
            return !isNaN(result) ? result : 0;
        },
        shopsOptions() {
            return [
                { id: null, address: 'Не выбран', icon: 'store' },
                ...this.shops
                    .map(item => ({ ...item, icon: item?.type?.icon_path || '' }))
                    .slice()
                    .sort((a, b) => a.type.id - b.type.id || a.address.localeCompare(b.address))
            ]
        }
    },
    watch: {
        'order.shop'() {
            this.setReceiverAddressByShop();
        },
        'order.user.first_name'(value) {
            if (this.order.user_is_receiver) this.order.receiver_name = value;
        },
        'order.user.phone'(value) {
            if (this.order.user_is_receiver) this.order.receiver_phone = value;
        },
        orderListVuex(value) {
            this.order.order_list = value;
            this.calculateSetPrice();
            if (this.isPageLoaded) {
                this.orderListChanged = true;
            }
        },
        tabActive() {
            if (this.tabActive === 2) {
                this.$store.dispatch('chat/SELECT_CHAT', this.orderChat);
            } else {
                this.$store.commit('chat/SET_SELECTED_CHAT', {});
            }
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            if (!this.sources.length || !this.shops.length || !this.paymentMethods.length) {
                await Promise.all([
                    this.$store.dispatch('data/GET_SOURCES'),
                    this.$store.dispatch("data/LOAD_SHOPS", JSON.parse(localStorage.getItem("user")).city),
                    this.$store.dispatch('paymentType/LOAD_PAYMENT_TYPES')
                ]);
            }

            if (this.authUserGroup !== "courier") {
                getUserByGroup('courier', 'all')
                    .then(res => {
                        this.couriers = res.results;
                    })
            }

            getUserByGroup('florist', 'all')
                .then(res => {
                    this.florists = res.results;
                })

            if (this.$route.params.orderID) {
                const order = await this.$store.dispatch("order/GET_ORDER_DATA", this.$route.params.orderID);
                this.order = order;
                this.order.datetime_open_page = new Date();
                this.order.order_list = order.order_list.slice().sort((a, b) => a.sort - b.sort);
                this.initialDiscountValue = +order.discount;

                this.order.order_list.forEach((item) => {
                    if (item.discount) {
                        item.discount.active = true;
                    }
                });

                if (this.order.review_id) {
                    fetchReviewsID(this.order.review_id)
                        .then(res => {
                            this.orderReview  = res;
                        })
                }

                await this.searchCustomer();

                if (this.authUserGroup === 'courier' && this.order.take_photo_with_receiver) {
                    this.isCourierPhotoNotificationModalShow = true;
                }

                this.$store.dispatch('chat/SEARCH_CHAT_BY_USER_ID', { id: order.user.id, limit: 1 });
            }

            if (this.order.pickup) {
                this.order.delivery_time_type = this.order.pickup_time_type;
                if (![1, 26].includes(this.order.pickup_time_type)) {
                    this.order.delivery_time_type = 3;
                } else if (this.order.pickup_time_type === 26) {
                    this.order.delivery_time_type = 2;
                }
            }

            await this.calculateSetPrice();
            this.getDeliveryIntervals();

            this.exit.defaultOrderData = JSON.parse(JSON.stringify(this.order));

            // Генерация карты для курьера. Просили карту отключить.
            /* if (this.$route.params.orderID && this.authUserGroup === 'courier') {
                await this.getDeliveryCost();
                this.initMap();
            } */
        } catch (error) {
            console.log(error);
            this.$store.commit('ui/add_item_in_system_notifications', { message: 'При загрузке данных произошла ошибка', type: 'error' })
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
            this.isPageLoaded = true;
        }
    },
    beforeDestroy() {
        this.$store.commit('order/CLEAR_ORDER_LIST');
        this.$store.dispatch('chat/SEARCH_CHATS', { str: '', limit: 30, type: 'True' });
        this.$store.commit('chat/SET_SELECTED_CHAT', {});
        this.$store.commit('chat/SET_ORDER_CHAT', {});
        this.$store.commit('chat/SET_MESSAGES', []);
    },
    methods: {
        async eventHandlerButton(withRedirect = true) {
            if (this.order.user.phone && !this.order.user.phone.startsWith("+")) {
                this.order.user.phone = `+${this.order.user.phone}`;
            }
            if (this.order.receiver_phone && !this.order.receiver_phone.startsWith("+")) {
                this.order.receiver_phone = `+${this.order.receiver_phone}`;
            }

            if (this.$route.params.orderID) {
                await this.updateOrder(withRedirect);
            } else {
                await this.createOrder(withRedirect);
            }
        },
        async createOrder(withRedirect) {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (this.promocodeField.active && !!this.bonuses.fixedValue) {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Одновременное использование бонусов и промокода невозможно', type: 'error' })
                    return;
                }

                this.$store.commit('ui/SET_PRELOADER', true);

                await this.calculateSetPrice();

                if (this.order.delivery_time_type === 3 && (!this.order.delivery_time_start || !this.order.delivery_time_end ||
                    this.order.delivery_time_start.startsWith('01.01.1970') || this.order.delivery_time_end.startsWith('01.01.1970'))) {
                    this.$set(this.error, 'delivery_time_start', 'Укажите время');
                    throw new Error('Укажите время');
                }

                if (this.order.order_reason.name) {
                    if (this.searchedClient) {
                        let userReasons = [...this.searchedClient.user_reasons];
                        const newReason = this.order.order_reason;
                        newReason.date = moment(this.order.delivery_date).add(1, 'years').format('YYYY-MM-DD');
                        newReason.site = +this.franchiseTariff.site_id;
                        userReasons.push(newReason);

                        userReasons = await this.$store.dispatch('clients/getUniqueReasons', {
                            primalArray: userReasons,
                            date: newReason.date,
                            newReason
                        });

                        const userSettings = await this.$store.dispatch('users/UPDATE_USER', {
                            user: { user_reasons: userReasons },
                            id: this.searchedClient.id
                        })

                        const findReason = userSettings.user_reasons.find((i) => i.name === this.order.order_reason.name);
                        if (findReason) {
                            this.order.order_reason = findReason;
                        }
                    } else {
                        this.order.order_reason = {
                            name: this.order.order_reason.name,
                            is_remind: this.order.order_reason.is_remind,
                            date: moment(this.order.delivery_date).add(1, 'years').format('YYYY-MM-DD'),
                            site: this.franchiseTariff.site_id
                        }
                    }
                }

                const data = { ...this.order, city: this.authUser.city };

                if (!this.order.order_reason.name) {
                    delete data.order_reason
                }
                if (+this.bonuses.fixedValue) {
                    data.paid_by_bonus = true;
                    data.debiting_bonuses = this.bonuses.fixedValue;
                }

                // if (this.order.delivery_time_type === 3 && (!this.order.delivery_time_start || !this.order.delivery_time_end ||
                //     this.order.delivery_time_start.startsWith('01.01.1970') || this.order.delivery_time_end.startsWith('01.01.1970'))) {
                //     delete data.delivery_time_start;
                //     delete data.delivery_time_end;
                // }

                if (this.order.pickup) {
                    data.pickup_time_type = this.order.delivery_time_type === 2 ? 26 :
                        this.order.delivery_time_type === 3 ? 27 : this.order.delivery_time_type;
                }

                const res = await this.$store.dispatch("order/CREATE_ORDER", data);

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Заказ успешно создан', type: 'success' })
                this.orderID = res.visible_id;

                if (withRedirect) this.$router.push({ name: "orders" });
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                if (error.data) {
                    this.error = error.data;
                    if (error.data.bonuses_error) {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: 'Нельзя применять бонусы и промокод одновременно', type: 'error' })
                    } else {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка создания заказа, проверьте заполненность обязательных полей', type: 'error' })
                    }
                } else {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка создания заказа, проверьте заполненность обязательных полей', type: 'error' })
                }
            }
        },
        async updateOrder(withRedirect) {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                await this.calculateSetPrice();

                if (this.order.delivery_time_type === 3 && (!this.order.delivery_time_start || !this.order.delivery_time_end ||
                    this.order.delivery_time_start.startsWith('01.01.1970') || this.order.delivery_time_end.startsWith('01.01.1970'))) {
                    this.$set(this.error, 'delivery_time_start', 'Укажите время');
                    throw new Error('Укажите время');
                }

                if (this.authUserGroup !== 'courier' && this.$refs.floristPhoto.files.length) {
                    try {
                        const data = new FormData();
                        data.append('datetime_open_page', this.moment(this.order.datetime_open_page).format());
                        data.append("florist_photo", this.$refs.floristPhoto.files[0]);

                        const imgResponse = await updateOrderID(this.$route.params.orderID, data, {
                            "Content-Type": "multipart/form-data"
                        });
                        this.order.datetime_open_page = new Date(new Date(imgResponse.last_change).getTime() + 1000);
                        this.order.visible_id = imgResponse.visible_id;
                    } catch (error) {
                        this.$store.commit('ui/SET_PRELOADER', false);
                        if (error.data && error.data.non_field_errors && error.data.non_field_errors[0] === 'Данные заказа не актуальны. Обновите страницу') {
                            this.$store.commit('ui/add_item_in_system_notifications', { message: 'Данные заказа не актуальны. Обновите страницу', type: 'reload' })
                        } else {
                            this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки фото', type: 'error' })
                        }
                        throw new Error('Ошибка загрузки фото');
                    }
                }

                if (this.orderReview && this.orderReview.id) {
                    await apiEditReview(this.orderReview.id, {
                        is_active: this.orderReview.is_active,
                        rating: this.orderReview.rating,
                        text: this.orderReview.text,
                        employee_comment: this.orderReview.employee_comment
                    });
                }

                if (this.order.order_reason.name && this.searchedClient) {
                    let userReasons = [...this.searchedClient.user_reasons];

                    const findIndex = userReasons.findIndex((item) => item.id === this.order.order_reason.id);

                    if (findIndex !== -1) {
                        userReasons.splice(findIndex, 1, {
                            ...this.order.order_reason,
                            date: moment(this.order.delivery_date).add(1, 'years').format('YYYY-MM-DD')
                        });
                    } else {
                        let newReason = this.order.order_reason;
                        newReason.date = moment(this.order.delivery_date).add(1, 'years').format('YYYY-MM-DD');
                        newReason.site = +this.franchiseTariff.site_id;
                        userReasons.push(newReason);
                    }

                    userReasons = await this.$store.dispatch('clients/getUniqueReasons', {
                        primalArray: userReasons,
                        date: this.order.order_reason.date,
                        newReason: this.order.order_reason
                    });

                    const userSettings = await this.$store.dispatch('users/UPDATE_USER', {
                        user: { user_reasons: userReasons },
                        id: this.searchedClient.id
                    })
                    const findReason = userSettings.user_reasons.find((i) => i.name === this.order.order_reason.name);
                    if (findReason) {
                        this.order.order_reason = findReason;
                    }
                }

                try {
                    this.compareRouteIdAndVisibleId();
                } catch (error) {
                    this.$store.commit('ui/SET_PRELOADER', false);
                    console.log(error);
                    return;
                }

                const data = { ...this.order };
                if (this.orderListChanged) data.order_list_changed = true;

                if (this.order.pickup) {
                    data.pickup_time_type = this.order.delivery_time_type === 2 ? 26 :
                        this.order.delivery_time_type === 3 ? 27 : this.order.delivery_time_type;
                }

                /* if (!this.order.delivery_time_start || !this.order.delivery_time_end || this.order.delivery_time_start.startsWith('01.01.1970') || this.order.delivery_time_end.startsWith('01.01.1970')) {
                    delete data.delivery_time_start;
                    delete data.delivery_time_end;
                } */

                delete data.florist_photo_rating;

                const res = await this.$store.dispatch("order/UPDATE_ORDER", {
                    order: data,
                    id: this.$route.params.orderID
                })

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Заказ успешно обновлён', type: 'success' })
                this.orderID = res.visible_id;

                if (withRedirect) {
                    this.$router.push({ name: "orders" });
                }
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                if (error.data) {
                    this.error = error.data;
                }
                if (this.error.non_field_errors && this.error.non_field_errors[0] === 'Данные заказа не актуальны. Обновите страницу') {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Данные заказа не актуальны. Обновите страницу', type: 'reload' })
                } else {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка в обновлении данных заказа', type: 'error' })
                }
            }
        },
        async setPickupType() {
            if (this.order.pickup) {
                if (![1, 2, 3].includes(this.order.delivery_time_type)) {
                    this.order.delivery_time_type = 3;
                }
                this.order.additional_info = "Самовывоз";
                this.order.delivery_cost = 0;
                this.setReceiverAddressByShop();
                this.calculateSetPrice();
            } else {
                this.order.additional_info = '';
                await this.getDeliveryCost();
                if (!this.order.receiver_address) {
                    this.calculateSetPrice();
                }
            }
        },
        setReceiverAddressByShop() {
            if (this.order.pickup) {
                let find = this.shops.find(item => item.id === this.order.shop);
                if (find) {
                    this.order.receiver_address = find.address;
                }
            }
        },
        otherReceiverHandler() {
            this.order.user_is_receiver = !this.order.user_is_receiver;
            if (!this.order.user_is_receiver) {
                this.order.receiver_phone = '';
                this.order.receiver_name = '';
            } else {
                this.order.receiver_phone = this.order.user.phone;
                this.order.receiver_name = this.order.user.first_name;
            }
        },
        async getDeliveryIntervals() {
            this.deliveryIntervals = await getOrderTimeIntervals(this.order.delivery_date);
        },
        toggleDeliveryTimeType() {
            this.clearError('delivery_time_start');
            this.clearError('delivery_time_end');
            if (this.order.delivery_time_type === 2) {
                this.order.delivery_time_start = null;
                this.order.delivery_time_end = null;
            }
        },
        async changeDeliveryTimes() {
            await this.getDeliveryIntervals();

            this.order.delivery_time_type = 3;

            if (this.order.delivery_time_start && this.order.delivery_time_end) {
                let timeStart = this.moment(this.order.delivery_time_start).utc().format('HH:mm');
                let timeEnd = this.moment(this.order.delivery_time_end).utc().format('HH:mm');

                this.order.delivery_time_start = this.moment(`${this.order.delivery_date}T${timeStart}Z`).format();
                this.order.delivery_time_end = this.moment(`${this.order.delivery_date}T${timeEnd}Z`).format();
            }
        },
        setDeliveryTimeByInterval() {
            let findInterval = this.deliveryIntervals.find(item => item.id === this.order.delivery_time_type);
            if (findInterval) {
                this.setDeliveryTime(findInterval.time_start, 'start', false);
                this.setDeliveryTime(findInterval.time_end, 'end', false);
            }
        },
        setDeliveryTime(event, type, setManualTimeType = true) {
            if (type === 'start') {
                this.order.delivery_time_start = this.moment(`${this.order.delivery_date}T${event}Z`).utc().format();

                let HH = (+event.split(':')[0] + 1).toString().padStart(2, '0');
                let mm = event.split(':')[1];
                if (HH >= 24) {
                    HH = '00';
                }

                this.order.delivery_time_end = this.moment(`${this.order.delivery_date}T${HH}:${mm}Z`).utc().format();
            } else if (type === 'end') {
                this.order.delivery_time_end = this.moment(`${this.order.delivery_date}T${event}Z`).utc().format();
            }

            if (this.moment(this.order.delivery_time_end) < this.moment(this.order.delivery_time_start)) {
                this.order.delivery_time_end = this.moment(this.order.delivery_time_end).add(1, 'days').utc().format();
            }

            if (setManualTimeType) {
                this.order.delivery_time_type = 3;
            }

            this.getDeliveryCostDebounced();
        },
        updateGoodEquipment(good) {
            this.changingGood = good;
            this.isProductEquipmentListChangeModalShow = true;
        },
        async updateEquipSet() {
            this.$store.dispatch('ui/checkUserEditPermission');

            const data = { order_list: [] };
            this.order.order_list.forEach((good) => {
                if (good.vendor_code === this.changingGoods.vendor_code) {
                    const obj = { ...this.changingGoods };
                    if (obj.discount) {
                        obj.discount.active = false;
                    }
                    data.order_list.push(obj);
                } else {
                    data.order_list.push(good);
                }
            });

            this.order.order_list = data.order_list;

            if (this.$route.params.orderID) {
                const response = await patchOrderSet(data, this.$route.params.orderID)
                this.order.datetime_open_page = new Date(new Date(response.last_change).getTime() + 1000);
                this.orderListChanged = true;
                this.calculateSetPrice();
            }

            this.$store.commit('order/SET_ORDER_LIST', { order_list: this.order.order_list });
        },
        calculateSetPriceDebounced: debounce(function () {
            this.calculateSetPrice();
        }, 1000),
        async calculateSetPrice() {
            try {
                if (this.order.pickup === null || this.order.pickup === undefined) {
                    return;
                }

                const data = {
                    order_list: this.order.order_list,
                    city_id: this.authUser.city,
                    pickup: this.order.pickup,
                    promocode: '',
                    debiting_bonuses: 0,
                    delivery_cost: this.order.delivery_cost || 0,
                    manual_discount: this.order.discount || 0,
                    amount_user_paid: this.order.amount_user_paid ? +this.order.amount_user_paid : 0,
                }

                if (this.bonuses.fixedValue) {
                    data.debiting_bonuses = +this.bonuses.fixedValue;
                }

                if (!this.$route.params.orderID && this.promocodeField.value && this.order.user.phone) {
                    data.promocode = this.promocodeField.value;
                    data.customer_phone = this.order.user.phone.startsWith('+') ? this.order.user.phone : `+${this.order.user.phone}`;
                    delete data.manual_discount

                    data.order_list = data.order_list.map(item => {
                        return {
                            ...item,
                            price: !item.discount ? item.price : item.price_without_discount
                        }
                    })
                }

                if (!data.delivery_cost || data.pickup ) {
                    delete data.delivery_cost;
                }

                if (!data.promocode) {
                    delete data.promocode;
                }

                if (this.$route.params.orderID) {
                    data.is_referral = this.order.is_referral;
                }

                const response = await getDeliveryPrice(data);

                this.calculatedOrderData = response;
                this.order.total_cost = response.total_price_without_discount - response.delivery_cost;
                this.order.final_cost = response.total_price_with_discount;
                this.order.delivery_cost = response.delivery_cost;
                this.order.bonus_discount = response.bonus_discount || 0;
                this.order.number_of_bonus = response.added_bonus || 0;
                this.bonuses.maxBonuses = response.max_bonus_to_final_cost || 0;

                this.order.order_list = this.order.order_list.map(item => ({
                    ...item,
                    price_in_receipt: this.calculatedOrderData.products.find(i => i.vendor_code === item.vendor_code).price_in_receipt
                }))

                if (this.promocodeField.value && response.promocode_is_active) {
                    this.promocodeField.active = true;
                    this.order.reason_change_discount = `Активирован промокод: ${this.promocodeField.value}`
                    this.order.discount = response.discount_amount || 0;
                }
                if (data.manual_discount) {
                    this.order.discount = response.discount_amount || 0;
                }
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Произошла ошибка при расчетах стоимости заказа', type: 'error' })
                if (error.data && error.data[0] === 'Неверный промокод') {
                    this.error = { promocode: ['Неверный промокод'] }
                } else if (error.data && error.data[0] === 'Промокод доступен для новых пользователей') {
                    this.error = { promocode: ['Промокод доступен только для новых пользователей'] }
                }
                throw error;
            }
        },
        getDeliveryCostDebounced: debounce(function () {
            this.getDeliveryCost();
        }, 500),
        getDeliveryCost() {
            if (!this.order.pickup && this.order.receiver_address) {
                return new Promise((resolve) => {
                    geocode().text(this.order.receiver_address).run(async (error, res) => {
                        if (error) {
                            console.log(error);
                            this.$store.commit('ui/add_item_in_system_notifications', { message: 'Координаты адреса не определены', type: 'error' })
                            return;
                        }
                        if (res.results.length) {
                            this.receiverAddressCoords = [res.results[0].latlng.lat, res.results[0].latlng.lng];

                            const data = {
                                lat: res.results[0].latlng.lat,
                                lon: res.results[0].latlng.lng,
                                address: this.order.receiver_address,
                                delivery_time_start: this.order.delivery_time_start ? this.moment(this.order.delivery_time_start).utc().format('HH:mm') : null,
                                delivery_time_end: this.order.delivery_time_end ? this.moment(this.order.delivery_time_end).utc().format('HH:mm') : null,
                            }

                            const deliveryData = await calculateDeliveryPrice(data);

                            this.order.delivery_cost = deliveryData.delivery_cost;
                            this.order.delivery_in_city = deliveryData.delivery_in_city;
                            this.calculateSetPrice();
                            resolve()
                        } else {
                            this.$store.commit('ui/add_item_in_system_notifications', { message: 'Координаты адреса не определены', type: 'error' })
                        }
                    })
                })
            }
        },
        setSearchedClient: debounce(function () {
            this.searchCustomer();
        }, 1000),
        async searchCustomer() {
            try {
                const { results } = await getUserByPhone(this.order.user.phone, false, true);
                if (results.length === 1) {
                    const user = await this.$store.dispatch('users/GET_USER', results[0].id);
                    this.searchedClient = user;
                    this.order.user.first_name = user.first_name;
                    this.setDefaultBonusCount();

                    if (this.$route.params.orderID) {
                        if (this.order.order_reason.id) {
                            const find = user.user_reasons.find((item) => item.id === this.order.order_reason.id);
                            if (find) {
                                this.order.order_reason = find;
                            } else {
                                this.order.order_reason = { name: '', is_remind: false }
                            }
                        } else {
                            this.order.order_reason = { name: '', is_remind: false }
                        }
                    }
                } else if (!this.$route.params.orderID && this.order.user.phone.length === 12) {
                    this.$store.dispatch('ui/checkUserEditPermission');
                    this.$store.commit('ui/SET_PRELOADER', true);

                    const user = await this.$store.dispatch('users/CREATE_USER', {
                        phone: this.order.user.phone,
                        first_name: this.order.user.first_name || 'Покупатель',
                        groups: ['customers']
                    })

                    this.searchedClient = user;
                    this.$set(this.searchedClient, 'user_reasons', []);
                    this.order.user.first_name = user.first_name;
                    this.setDefaultBonusCount();

                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Покупатель успешно зарегистрирован', type: 'success' })
                } else {
                    this.searchedClient = {};
                }

                if (!this.$route.params.orderID) {
                    this.promocodeHandler();
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async applyDiscountVariant(variant) {
            const isSomeVariantActive = this.discountVariants.some(item => item.active);
            const selectedVariant = JSON.parse(JSON.stringify(variant));

            if (isSomeVariantActive) {
                this.discountVariants.forEach(item => item.active = false);
                this.order.discount = 0;
                // this.setInitialPriceForProducts();
                this.clearPromocodeField();
                await this.calculateSetPrice();
            }
            if (!selectedVariant.active) {
                this.discountVariants.forEach((item) => { item.active = false; });
                variant.active = true;
                this.order.discount = Math.floor(+this.order.final_cost * variant.value / 100)
            }
            this.calculateSetPrice();
        },
        applyDiscountModal() {
            if (this.initialDiscountValue !== +this.order.discount && !this.order.reason_change_discount) {
                this.$set(this.error, 'reason_change_discount', 'Укажите причину изменения скидки');
                return;
            }
            this.discountModal.isDiscountModalOpen = false;
        },
        async applyDiscountForGood(good) {
            this.$store.dispatch('ui/checkUserEditPermission');
            if (good.discount && good.discount.active) {
                return;
            }
            if (good.discount && good.discount.amount_perc) {
                good.price -= Math.floor(good.price / 100 * good.discount.amount_perc);
            } else if (good.discount && good.discount.amount_rub) {
                good.price -= good.discount.amount_rub;
            }
            good.discount.active = true;

            if (this.$route.params.orderID) {
                const response = await patchOrderSet({ order_list: this.order.order_list }, this.$route.params.orderID);
                this.order.datetime_open_page = new Date(new Date(response.last_change).getTime() + 1000);
                this.orderListChanged = true;
            }
            this.calculateSetPrice();
            this.$store.commit('order/SET_ORDER_LIST', { order_list: this.order.order_list });
        },
        setDefaultBonusCount() {
            this.bonuses.value = 0;
            this.bonuses.fixedValue = 0;
            this.bonuses.readonly = false;
        },
        setMaxAvailableBonusCount() {
            if (this.bonuses.value === 0 && isFinite(+this.searchedClient.number_of_bonus)) {
                if (this.calculatedOrderData.max_bonus_to_final_cost > +this.searchedClient.number_of_bonus) {
                    this.bonuses.value = +this.searchedClient.number_of_bonus;
                } else {
                    this.bonuses.value = this.calculatedOrderData.max_bonus_to_final_cost;
                }
            }
        },
        async applyBonuses() {
            try {
                this.setMaxAvailableBonusCount();

                if (
                    !this.bonuses.value ||
                    this.bonuses.value < 0 ||
                    !isFinite(this.bonuses.value) ||
                    this.bonuses.value > this.bonuses.maxBonuses ||
                    this.bonuses.value > +this.searchedClient.number_of_bonus
                ) {
                    this.bonuses.error = 'Некорректное значение';
                    setTimeout(() => {
                        this.setDefaultBonusCount();
                        this.bonuses.error = null;
                    }, 2000);
                } else if (this.bonuses.value <= +this.searchedClient.number_of_bonus) {
                    this.bonuses.readonly = true;
                    this.bonuses.fixedValue = this.bonuses.value;

                    await this.calculateSetPrice();

                    this.bonuses.error = null;
                }
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', {
                    message: error.data && error.data[0] ? error.data[0] : 'Произошла ошибка при применении бонусов. Проверьте корректность введенного значения',
                    type: 'error'
                })
                this.setDefaultBonusCount();
            }
        },
        async clearBonus() {
            this.bonuses.fixedValue = 0;

            // this.setInitialPriceForProducts();

            await this.calculateSetPrice();

            this.bonuses.error = null;
            this.setDefaultBonusCount();
        },
        setInitialPriceForProducts() {
            // восстанавливаем оригинальную стоимость для товаров. при применении бонусов price заменяется на price_in_receipt
            this.order.order_list = this.order.order_list.map(item => ({
                ...item,
                price: this.calculatedOrderData.products.find(i => i.vendor_code === item.vendor_code) ?
                    this.calculatedOrderData.products.find(i => i.vendor_code === item.vendor_code).price : item.price
            }))
        },
        promocodeHandler() {
            if (!this.promocodeField.active) {
                this.order.discount = 0;
                // this.setInitialPriceForProducts();
                this.calculateSetPrice();
            } else {
                this.clearPromocodeField();
                this.order.discount = 0;
                // this.setInitialPriceForProducts();
                this.calculateSetPrice();
            }
        },
        clearPromocodeField() {
            this.promocodeField.value = '';
            this.promocodeField.active = false;
            this.order.reason_change_discount = '';
        },
        async changePhoto(event, type) {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (event.target.files.length === 0) {
                    return;
                }
                this.$store.commit('ui/SET_PRELOADER', true);

                const file = event.target.files[0];

                if (type === 'florist') {
                    this.order.florist_photo = URL.createObjectURL(file);
                } else if (type === 'courier' && this.authUserGroup !== "florist") {
                    const data = new FormData();
                    data.append('photo_with_receiver', file);
                    data.append('datetime_open_page', moment(this.order.datetime_open_page).format());

                    const response = await updateOrderID(this.$route.params.orderID, data, {
                        "Content-Type": "multipart/form-data"
                    });

                    this.order.photo_with_receiver = response.photo_with_receiver;
                    this.order.datetime_open_page = new Date(new Date(response.last_change).getTime() + 1000);
                    event.target.value = "";
                }
            } catch (error) {
                if (error.data && error.data.non_field_errors && error.data.non_field_errors[0] === 'Данные заказа не актуальны. Обновите страницу') {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Данные заказа не актуальны. Обновите страницу', type: 'reload' })
                } else {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки фото', type: 'error' })
                }
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        removeProductFromOrderList(good) {
            this.$store.commit("order/REMOVE_GOOD_FROM_ORDER_LIST", good);
            this.orderListChanged = true;
        },
        showCheckEquipmentModal(event) {
            if (this.order.status === 3 && event === 4) {
                this.isShowCheckEquipmentModal = true;
            } else {
                this.changeStatus(event, this.$route.params.orderID)
            }
        },
        async changeStatus(statusID, orderID) {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                await setStatusOrder(statusID, orderID);
                const order = await this.$store.dispatch("order/GET_ORDER_DATA", this.$route.params.orderID);

                this.order.status = order.status;
                this.error = {};
                this.order.datetime_open_page = new Date(new Date(order.last_change).getTime() + 1000);
            } catch (error) {
                this.error = error.data;
                this.$store.commit('ui/add_item_in_system_notifications', {
                    message: this.error && this.error[0] === 'Невозможно перевести заказа в эту стадию, так как не выбран магазин' ? this.error[0] : 'Ошибка смены статуса',
                    type: 'error'
                })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async copyOrder() {
            let str = "";
            if (this.orderID) {
                str += `Заказ №${this.orderID} \n\n`;
            }
            if (this.order.order_list) {
                str += "Состав заказа: \n";

                this.order.order_list.forEach((good) => {
                    str += `${good.name} - ${good.count}шт. ${good.price} ₽`;
                    str += good.wishes_for_product ? `, ${good.wishes_for_product}\n` : '\n'
                });
            }
            if (!this.order.pickup) {
                str += `Доставка курьером - ${this.order.delivery_cost} ₽ \n`;
            }
            str += `Итого - ${ Math.ceil(this.order.final_cost) } ₽ \n`;

            if (this.order.payment_method) {
                str += `Способ оплаты: ${
                    this.paymentMethods.find((item) => item.id === this.order.payment_method).name
                } \n`;
            }
            if (this.order.shop) {
                str += `Магазин: ${this.shops.find((sh) => sh.id === this.order.shop).address} \n`;
            }
            str += "\n";
            if (this.order.postcard) {
                str += `Открытка: ${this.order.postcard} \n`;
            }
            if (this.order.receiver_name) {
                str += `Имя получателя: ${this.order.receiver_name} \n`;
            }
            if (this.order.receiver_phone) {
                str += `Телефон получателя: ${this.order.receiver_phone} \n`;
            }
            if (!this.order.pickup) {
                str += `Адрес доставки: ${this.order.receiver_address} \n`;
            }
            if (this.order.delivery_date) {
                str += `Дата доставки: ${this.moment(this.order.delivery_date).format("DD.MM.YYYY")} \n`;
            }
            if (this.order.courier) {
                const courier = this.couriers.find((item) => item.id === this.order.courier);
                str += `Курьер: ${courier.last_name} ${courier.first_name} \n`;
            }
            if (this.order.delivery_time_start && this.order.delivery_time_end && !this.order.delivery_time_start.startsWith('01.01.1970') && !this.order.delivery_time_end.startsWith('01.01.1970')) {
                str += `Время доставки: ${this.moment(this.order.delivery_time_start).utc().format('HH:mm')} до ${this.moment(this.order.delivery_time_end).utc().format('HH:mm')} \n`;
            } else {
                str += `Время доставки: не указано \n`;
            }
            if (this.order.additional_info) {
                str += `Дополнительная информация: ${this.order.additional_info} \n`;
            }
            str += "\n";
            if (this.order.user) {
                str += `Имя заказчика: ${this.order.user.first_name} \n`;
                str += `Телефон заказчика: ${this.order.user.phone} \n`;
            }
            navigator.clipboard.writeText(str);
            this.$store.commit('ui/add_item_in_system_notifications', { message: 'Скопировано в буфер обмена', type: 'success' })
        },
        copyEquipmentSet() {
            let str = "";
            if (this.order.order_list) {
                str += "Состав: \n";
                this.order.order_list.forEach((good) => {
                    str += `${good.name}: \n`;
                    good.equipment_set.forEach((equi) => {
                        str += `- ${equi.name} ${equi.count}шт. * ${equi.price} = ${equi.count * +equi.price} ₽ \n`;
                    });
                    str += `- Стоимость: ${good.price} ₽ \n`;
                    if (good.wishes_for_product) {
                        str += `- Пожелание: ${good.wishes_for_product}\n`
                    }
                });
            }
            if (!this.order.pickup) {
                str += `Доставка курьером - ${this.order.delivery_cost} ₽ \n`;
            }
            navigator.clipboard.writeText(str);
            this.$store.commit('ui/add_item_in_system_notifications', { message: 'Скопировано в буфер обмена', type: 'success' })
        },
        async returnOrder() {
            try {
                await this.eventHandlerButton(false);
                this.$router.push(`/orders-returns/create/?id=${this.$route.params.orderID}`);
            } catch (error) {
                console.log(error);
            }
        },
        async downloadBillPDF() {
            try {
                await this.eventHandlerButton(false);

                const res = await getBillPDF(this.$route.params.orderID || this.orderID, this.referer)
                if (!this.$route.params.orderID) {
                    this.$router.push({ name: 'order', params: { orderID: this.orderID } });
                }

                const newBlob = new Blob([res], { type: "application/pdf" });
                const file = window.URL.createObjectURL(newBlob);
                const link = document.createElement('a');
                link.href = file;
                link.target = '_blank';
                link.download = "Счет на оплату.pdf";
                link.click();

                setTimeout(() => {
                    window.URL.revokeObjectURL(file);
                }, 1000);
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При получении счета на оплату произошла ошибка', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async printChequeAtol() {
            this.$store.dispatch('ui/checkUserEditPermission');

            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const data = { 
                    order_data: this.calculatedOrderData 
                }
                await postPrintChequeAtol(data);

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Чек успешно отправлен на печать', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При отправке чека на печать произошла ошибка', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async createPaymentLink() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const paymentResponse = await createOrderPaymentLink({
                    orderNumber: this.$route.params.orderID,
                    is_sbp: this.order.payment_method?.name === 'СБП',
                    payment_method: this.order.payment_method?.name !== 'СБП' ? 'bank_card' : 'sbp',
                    returnUrl: `https://${this.franchiseTariff.site_domain}/api/v1/payment/result/`,
                    failUrl: `https://${this.franchiseTariff.site_domain}/api/v1/payment/result/`
                });

                if (paymentResponse.formUrl) {
                    await navigator.clipboard.writeText(paymentResponse.formUrl);
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ссылка на оплату скопирована', type: 'success' })
                } else {
                    throw new Error('Ссылка на оплату не получена');
                }
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка получения ссылки на оплату', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        compareRouteIdAndVisibleId() {
            if (this.$route.params.orderID != this.order.visible_id) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка. Несовпадение id заказа с исходным', type: 'error' })
                setTimeout(() => {
                    location.reload();
                }, 5000);
                throw new Error('Несовпадение visible_id с $route.params.orderID');
            }
        },
        checkOrderChanges() {
            const copyOrderData = JSON.parse(JSON.stringify(this.order));
            delete copyOrderData.bonus_discount;

            const copyDefaultOrderData = JSON.parse(JSON.stringify(this.exit.defaultOrderData));
            copyDefaultOrderData.total_cost = +copyDefaultOrderData.total_cost
            copyDefaultOrderData.final_cost = +copyDefaultOrderData.final_cost
            copyDefaultOrderData.number_of_bonus = +copyDefaultOrderData.number_of_bonus;
            delete copyDefaultOrderData.bonus_discount;

            const isOrderChanged = JSON.stringify(copyDefaultOrderData) !== JSON.stringify(copyOrderData);

            // console.log(JSON.stringify(copyDefaultOrderData));
            // console.log(JSON.stringify(copyOrderData));
            if (isOrderChanged) {
                this.exit.isShowExitModal = true;
            } else {
                this.$router.back();
            }
        },
        // initMap() {
        //     let coords = [54.989347, 73.368221];
        //     if (this.receiverAddressCoords.length) {
        //         coords = this.receiverAddressCoords;
        //     }
        //     addressMap = L.map('addressMap').setView(coords, this.receiverAddressCoords.length ? 15 : 7);
        //     L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(addressMap);
        //
        //     const customIconMarker = L.icon({
        //         iconUrl: require('@/assets/img/icon/mapMarker.svg'),
        //         iconSize: [46, 46],
        //         iconAnchor: [22, 46]
        //     });
        //
        //     L.marker(coords, { icon: customIconMarker }).addTo(addressMap);
        // },
        clearError(field) {
            if (this.error && this.error[field]) {
                this.$delete(this.error, field)
            }
        },
        openLink(urlString) {
            window.open(urlString, '_blank');
        },
        phoneCall(phone) {
            if (phone) {
                location.href = `tel:${phone}`
            }
        },
        moment,
        numberFormat
    }
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Промокоды" for-listing :show-save-btn="false" />
            <div class="page-container page-container--listing page-container--with-fab">
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="promocodesWithAmmount && promocodesWithAmmount.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 25%">Промокод</div>
                            <div class="listing-table__col" style="width: 25%">Период действия</div>
                            <div class="listing-table__col" style="width: 25%">Величина</div>
                            <div class="listing-table__col" style="width: 25%">Активность</div>
                        </div>
                        <router-link
                            v-for="item in promocodesWithAmmount"
                            :key="item.id"
                            :to="`/promocodes/${item.id}`"
                            class="listing-table__row"
                        >
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                {{ item.promocode }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Период действия</div>
                                {{ item.activity_start }} - {{ item.activity_end }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Величина</div>
                                {{ formatNumber(item.amount) }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <StatusSelect
                                    :value="item.is_active"
                                    :options="[
                                        { id: true, name: 'Активен', color: '#36B687', icon: 'check' },
                                        { id: false, name: 'Не активен', color: '#F00C29', icon: 'close' }
                                    ]"
                                    disabled
                                />
                            </div>
                        </router-link>
                    </div>
                    <empty-table v-show="promocodesWithAmmount && promocodesWithAmmount.length === 0" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only && authUserGroup === 'supervisor'" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import moment from 'moment';

import { numberFormat } from '@/utils/format';

export default {
    name: 'ThePromocodes',
    metaInfo: {
        title: 'Промокоды'
    },
    components: {},
    computed: {
        ...mapGetters({
            width: 'ui/width',
            promocodes: 'promocodes/promocodes',
            discounts: 'discounts/discounts',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
        }),
        promocodesWithAmmount() {
            if (this.discounts.length && this.promocodes.length) {
                this.promocodes.forEach((element) => {
                    const find = this.discounts.find((i) => i.id === element.discount);
                    element.amount = find ? find.amount : 0;
                    element.activity_start = find ? moment(find.activity_start).format('DD.MM.YY') : 0;
                    element.activity_end = find ? moment(find.activity_end).format('DD.MM.YY') : 0;
                });
                return this.promocodes;
            }
            return [];
        },
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        if (this.authUserGroup === 'supervisor') {
            this.$store.commit('promocodes/CLEAR');
            await Promise.all([
                this.$store.dispatch('discounts/LOAD_DISCOUNTS'),
                this.$store.dispatch('promocodes/LOAD_PROMOCODES')
            ])
        }
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    beforeDestroy() {
        this.$store.commit('promocodes/CLEAR');
    },
    methods: {
        formatNumber(number) {
            try {
                const value = number.slice(0, -1);
                const type = number.slice(-1);
                return numberFormat(value) + type;
            } catch (error) {
                return number;
            }
        },
    },
};
</script>

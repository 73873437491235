<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Интеграции" for-listing :show-save-btn="false" />
            <div class="page-container page-container--listing">
                <div class="listing-table__wrap">
                    <div class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 100%">Название</div>
                        </div>
                        <router-link v-for="item in integrations" :key="item.id" :to="item.link" class="listing-table__row">
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 100%' : ''">
                                {{ item.name }}
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TheIntegrations',
    metaInfo: {
        title: 'Интеграции'
    },
    data() {
        return {
            integrations: [
                { id: 1, name: 'ВКонтакте', link: '/integrations/vk' },
                { id: 2, name: 'Facebook', link: '/integrations/fb' }
            ]
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
    },
    mounted() {
        this.$store.commit('ui/SET_PRELOADER', false);
    }
}
</script>

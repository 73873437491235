<template>
    <div class="all-app-modals">
        <UniversalModal :opened="!!isModalOpened('modal-goods')" size="full" @input="$store.commit('ui/CLOSE_MODAL', 'modal-goods')">
            <GoodsModal />
        </UniversalModal>

        <FeedbackModal />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import GoodsModal from '@/components/app/goods/modal/GoodsModal';
import FeedbackModal from '@/components/app/help/FeedbackModal';

export default {
    name: 'TheModals',
    components: {
        GoodsModal,
        FeedbackModal,
    },
    computed: {
        ...mapGetters({
            isModalOpened: 'ui/isModalOpened',
        }),
    }
};
</script>

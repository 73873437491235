export default function modalSwipeClose() {
    let elContent;
    let elWrap;
    let currentModal;
    let compareSelector;
    let touchClose = {
        drag: false,
        difference: 0,
        currentCoords: 0,
    }

    let closeModal;

    function initTouchHandler(wrap, content, closeFn, modal, selector) {
        if (document.documentElement.clientWidth <= 899 && wrap) {
            elWrap = wrap;
            elContent = content;
            closeModal = closeFn;
            currentModal = modal;
            compareSelector = selector;


            elWrap.addEventListener('touchstart', touchStart, { passive: true });
            elWrap.addEventListener('touchmove', touchMove);
            elWrap.addEventListener('touchend', touchEnd);
        }
    }

    function destroyTouch(wrap) {
        if (document.documentElement.clientWidth <= 899 && wrap) {
            elWrap.removeEventListener('touchstart', touchStart);
            elWrap.removeEventListener('touchmove', touchMove);
            elWrap.removeEventListener('touchend', touchEnd);
        }
    }

    function touchStart(e) {
        // Запускаем закрытие модалки по свайпу, если модальное окно прокручено полностью вверх, и пользователь не пытается скроллить mini-modal
        if (elContent.scrollTop === 0 && !window.pageYOffset && !e.target.closest('.mini-modal__wrapper')) {
            touchClose.drag = true;
            touchClose.difference = e.touches[0].clientY;
            touchClose.currentCoords = 0;
        }
    }

    function touchMove(e) {
        if (e.touches.length > 1 || (e.scale && e.scale !== 1)) { e.preventDefault(); }
        // Свайп по экрану закрывает только последнее открытое модальное окно
        if (touchClose.drag && e.target.closest(compareSelector) === currentModal) {
            touchClose.currentCoords = e.touches[0].clientY - touchClose.difference;
            elWrap.style.transition = 'transform 0s';

            const pixelDelay = 40; // Устанвливаем задержку для свайпа. Если пользователь делает свайп вниз меньше, чем на значение px, то модалка закрываться не будет. Это нужно для того, чтобы при боковых скроллах модалки не пытались закрыться.

            if (touchClose.currentCoords > 0 && touchClose.currentCoords <= pixelDelay) {
                e.preventDefault();
            } else if (touchClose.currentCoords > pixelDelay) {
                e.preventDefault();
                elContent.scrollTop = 0;
                elContent.style.overflow = 'hidden';
                elContent.style.touchAction = 'none';
                elWrap.style.transform = `translateY(${touchClose.currentCoords - pixelDelay}px)`;
            } else {
                elWrap.style.transform = '';
            }
        }
    }

    function touchEnd() {
        touchClose.drag = false;
        if (elContent.scrollTop === 0) {
            elWrap.style.transition = 'transform .3s';
            if (touchClose.currentCoords > 100) {
                elWrap.style.transform = 'translateY(100%)';
                closeModal();
            } else {
                elWrap.style.transform = 'translateY(0px)';
                clearState();
            }
        }
    }

    function clearState() {
        setTimeout(() => {
            if (elWrap) {
                elWrap.style.transition = '';
                elWrap.style.transform = '';
                touchClose.currentCoords = 0;
                elContent.style.overflow = 'auto';
                elContent.style.touchAction = '';
            }
        }, 300);
    }

    return { initTouchHandler, destroyTouch }
}
<template>
    <button
        v-if="!preloader"
        type="button"
        class="chips"
        :class="{
            'active': active,
            'is-disabled': disabled,
        }"
        :disabled="disabled"
        v-on="$listeners"
    >
        <slot />
    </button>
    <ContentPreloader v-else width="190" height="32" rounded />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Chips',
    props: {
        color: { type: String, default: 'blue' },
        active: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
        }),
    },
}
</script>
<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                v-if="width > 699 || !selectedChat.id"
                title="Чаты"
                for-listing
                :show-save-btn="false"
                :search="width <= 699"
                @click:search="isVisibleSearch = !isVisibleSearch"
            />
            <div class="page-container page-container--listing">
                <div class="page-columns">
                    <div v-show="width > 699 || !selectedChat.id" class="page-small-column page-small-column--mobile-top mb-0">
                        <Search v-model="search" class="mb-4" :is-visible-mobile="isVisibleSearch" />
                        <div>
                            <div
                                v-if="chatList.length"
                                class="chat__dialogues-list"
                                @scroll.passive="onLoadMore"
                            >
                                <div
                                    v-for="chat in chatListFiltered"
                                    :key="chat.id"
                                    class="chat__dialogues-list-item"
                                    :class="{'active': chat.id === selectedChat.id}"
                                    @click.prevent="select(chat)"
                                >
                                    <div class="chat__dialogues-list-item-avatar">
                                        <img src="@/assets/img/icon/avatar2.svg" alt="">
                                    </div>
                                    <div class="chat__dialogues-list-item-info">
                                        <div class="chat__dialogues-list-item-info-row">
                                            <div class="chat__dialogues-list-item-name">
                                                {{ 
                                                    chat.name === 'Гость' ? `${chat.name} №${chat.id}` :
                                                    chat.user && chat.user.first_name ? chat.user.first_name :
                                                    chat.name
                                                }}
                                            </div>
                                            <div class="chat__dialogues-list-item-time">
                                                {{ parseTime(chat.last_message.time, chat.last_message.date) }}
                                                <div class="chat__dialogues-list-item-status">
                                                    <img v-if="chat.last_message.seen" src="../assets/img/icon/good.svg" alt="">
                                                    <img v-else src="../assets/img/icon/good1.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="chat.last_message" class="chat__dialogues-list-item-info-row">
                                            <div class="chat__dialogues-list-item-last-message-wrap">
                                                <div v-if="chat.user_id !== chat.last_message.user_id" class="chat__dialogues-list-item-last-message-user">
                                                    Вы:
                                                </div>
                                                <div class="chat__dialogues-list-item-last-message" v-html="clearHtml(chat.last_message.text)" />
                                            </div>
                                            <div
                                                v-if="chat.last_message.user_id != user_id && chat.unseen_count > 0"
                                                class="chat__dialogues-list-item-unread-indicator"
                                            >
                                                {{ chat.unseen_count }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="chat__dialogues-empty">
                                <Icon size="120" color="#CFDDEA">search</Icon>
                                <div class="mt-2">Ничего не найдено</div>
                            </div>
                        </div>
                    </div>
                    <div v-show="width > 699 || selectedChat.id" class="page-big-column">
                        <ChatMessageSection />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

import ChatMessageSection from '@/components/app/chat/ChatMessageSection'

let timer = null;

export default {
    name: 'TheChat',
    metaInfo: {
        title: 'Чат'
    },
    components: {
        ChatMessageSection
    },
    data() {
        return {
            search: '',
            isVisibleSearch: false
        }
    },
    computed: {
        ...mapGetters({
            chatList: 'chat/chatList',
            selectedChat: 'chat/selectedChat',
            width: 'ui/width',
            user_id: 'chat/user_id',
            limit: 'chat/limit',
            step: 'chat/step',
        }),
        chatListFiltered() {
            return this.chatList.slice(0).sort((a, b) => {
                if ((b.unseen_count > 0 && a.unseen_count > 0) || (b.unseen_count === 0 && a.unseen_count === 0)) {
                    let first_date = a.last_message.date.split('.');
                    let second_date = b.last_message.date.split('.')
                    if (new Date(`${first_date[2]}-${first_date[1]}-${first_date[0]}`) > new Date(`${second_date[2]}-${second_date[1]}-${second_date[0]}`)) {
                        return -1;
                    } else if (new Date(`${first_date[2]}-${first_date[1]}-${first_date[0]}`) < new Date(`${second_date[2]}-${second_date[1]}-${second_date[0]}`)) {
                        return 1;
                    } else {
                        if (a.last_message.time > b.last_message.time) {
                            return -1;
                        } else if (b.last_message.time > a.last_message.time) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                } else if (a.unseen_count > 0 && b.unseen_count === 0) {
                    return -1;
                } else if (b.unseen_count > 0 && a.unseen_count === 0) {
                    return 1;
                }
            })
        },
    },
    watch: {
        search(value) {
            if (value) {
                this.$store.dispatch('chat/SEARCH_CHATS', { str: this.search, limit: this.limit, type: 'True' });
            } else if (!value) {
                this.$store.dispatch('chat/LOAD_CHATS', this.search);
            }
        },
    },
    beforeDestroy() {
        this.$store.commit('chat/SET_SELECTED_CHAT', {});
        this.$store.commit('chat/SET_MESSAGES', []);
    },
    methods: {
        ...mapActions({
            select: 'chat/SELECT_CHAT',
            loadMore: 'chat/LOAD_MORE',
            loadMoreSearch: 'chat/LOAD_MORE_SEARCH',
        }),
        parseTime(time, date) {
            let dateFormat = `${date[3]}${date[4]}/${date[0]}${date[1]}/${date.slice(-4)}`
            let localtime = new Date(Date.parse(`${dateFormat} ${time} UTC`))
            let today = moment().format('YYYY-MM-DD') === moment(localtime).format('YYYY-MM-DD')
            let thisYear = moment().format('YYYY') === moment(localtime).format('YYYY')

            if (today) {
                return moment(localtime).format('HH:mm');
            } else if (thisYear && !today) {
                return moment(localtime).format('DD MMM').replace(/\./g, '');
            } else {
                return moment(localtime).format('DD MMM YYYY').replace(/\./g, '');
            }
        },
        onLoadMore(event) {
            if (!event || !event.target) return;
            let element = event.target;
            if (element.scrollHeight - element.scrollTop < element.clientHeight + 300) {
                clearTimeout(timer)
                timer = setTimeout(() => {
                    if (this.search === '') {
                        this.loadMore('all');
                    } else {
                        if (this.chatList.length >= (this.limit * this.step)) {
                            this.$store.dispatch('chat/LOAD_MORE_SEARCH', this.search);
                        }
                    }
                }, 100)
            }
        },
        clearHtml(str) {
            try {
                let changedStr = str.replace(/<\/?[^>]+>/ig, ' ').replace(/&nbsp;/ig, ' ').trim();
                return changedStr;
            } catch {
                return str;
            }
        }
    },
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Отзывы"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список отзывов</h2>

                                <p>Выводятся все отзывы клиентов.</p>
                                <p>Поля:</p>
                                <ul>
                                    <li>Название</li>
                                    <li>Оценка</li>
                                    <li>Дата создания</li>
                                    <li>Текст отзыва</li>
                                </ul>
                                <p>Есть фильтр по оценкам.</p>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Редактирование отзывов</h2>
                                <template v-if="authUserGroup !== 'managers'">
                                    <p>При редактировании отзыва можно переключать активность, изменять оценку и текст отзыва, а также добавлять комментарий менеджера. Остальные поля заблокированы. Удалять отзывы нельзя.</p>
                                    <img src="@/assets/img/help/reviews/1.jpg" class="help__image" alt="" style="margin: 16px auto">
                                </template>
                                <template v-else>
                                    <p>При редактировании отзыва можно переключать активность и добавлять комментарий менеджера. Остальные поля заблокированы.</p>
                                    <img src="@/assets/img/help/reviews/1_2.jpg" class="help__image" alt="" style="margin: 16px auto">
                                </template>

                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{}, {}]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpReviews',
    metaInfo: {
        title: 'Отзывы - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Товары"
                for-listing
                :show-save-btn="false"
                :filter="group !== 'florist'"
                :filterIndicator="!!activeFilters.length"
                :search="width <= 699"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'goods-parameters-filter')"
                @click:search="isVisibleSearch = !isVisibleSearch"
            />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <Search
                    :value="filters.name"
                    :class="{'mb-0': goods && goods.length && Object.keys(picked).length}"
                    :is-visible-mobile="isVisibleSearch"
                    @input="$store.commit('goods/SET_FILTER', { field: 'name', value: $event}), getFilteredGoods()"
                />

                <button
                    v-if="group !== 'florist'"
                    type="button"
                    class="goods__category-name"
                    :class="{'mb-2': Object.keys(picked).length}"
                    @click.prevent="showCategoryModal = true"
                >
                    {{ selectedCategory && selectedCategory.is_name ? selectedCategory.is_name : 'Все товары' }}
                    <Icon class="ml-2" color="#8AACCD">down</Icon>
                </button>

                <ActionBar
                    v-show="goods && goods.length && Object.keys(picked).length"
                    :picked="picked"
                    :all-picked="allPicked"
                    :hide-all-goods="isHiddenAllGoods"
                    :picked-count="checkedCountComputed"
                    with-hide
                    @checkbox="checkboxAllHandler"
                    @hideGoods="toggleHiddenGoods($event)"
                />

                <div class="page-columns">
                    <div class="page-big-column">
                        <div v-show="!preloader" class="listing-table__wrap">
                            <div v-if="goods && goods.length" class="listing-table listing-table--products">
                                <div class="listing-table__row listing-table__row--head">
                                    <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                    <div class="listing-table__col pl-0">Наименование</div>
                                    <div class="listing-table__col" style="width: 96px; flex: 0 0 auto;">Cборка</div>
                                    <div class="listing-table__col" style="width: 100px; flex: 0 0 auto;">Стоимость</div>
                                </div>
                                <router-link
                                    v-for="item in goods"
                                    :key="item.vendor_code"
                                    :to="`/goods/${item.vendor_code}`"
                                    class="listing-table__row"
                                    :class="{ active: picked.includes(item) }"
                                >
                                    <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                        <div class="listing-table__image" @click.stop.prevent="checkboxHandler(item)">
                                            <img
                                                :src="item.images.length && item.images[0].image ? item.images[0].image.preview : require('@/assets/img/flower.svg')"
                                                alt=""
                                            >
                                        </div>
                                    </div>
                                    <div
                                        class="listing-table__col listing-table__col--mobile-inline"
                                        :class="{'pt-0 mr-0': width <= 1024}"
                                        :style="width > 1024 ? 'width: calc(100% - 308px)' : 'width: calc(100% - 68px)'"
                                    >
                                        <div>
                                            <div class="listing-table__product-name">
                                                <span>{{ item.name }}</span>
                                                <Icon
                                                    v-if="item.equipment_set.length"
                                                    class="listing-table__product-name-chevron"
                                                    :class="{'rotate': opened.includes(item.vendor_code)}"
                                                    color="#8AACCD"
                                                    @click.stop.prevent="openHandler(item.vendor_code)"
                                                >
                                                    down
                                                </Icon>
                                            </div>
                                            <div v-if="item.discount || item.is_hidden" class="listing-table__badges">
                                                <div v-if="item.is_hidden" class="listing-table__badges-item">
                                                    Недоступен
                                                </div>
                                                <div
                                                    v-if="item.discount"
                                                    class="listing-table__badges-item"
                                                    :class="{ 'listing-table__badges-item--yellow': item.discount }"
                                                >
                                                    Акция
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="item.equipment_set.length && opened.includes(item.vendor_code)"
                                        class="listing-table__product-equipment-list"
                                    >
                                        <div class="listing-table__product-equipment-list-label">Состав:</div>
                                        <div v-for="(eq, index) in item.equipment_set" :key="index" class="listing-table__product-equipment-item">
                                            <div>{{ eq.equipment_product.name }}</div>
                                            <div>{{ eq.count }} шт.</div>
                                        </div>
                                    </div>

                                    <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                    <div class="listing-table__col" :style="width > 1024 ? 'width: 96px; flex: 0 0 auto;' : ''">
                                        <div class="listing-table__col-label">Сборка</div>
                                        {{ item.order_time }} ч.
                                    </div>
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: 100px; flex: 0 0 auto;' : ''">
                                        <div class="listing-table__col-label">Стоимость</div>
                                        {{ item.discount ? formatNumber(+item.discount.price_with_discount) : formatNumber(+item.price) }} ₽
                                    </div>
                                </router-link>
                                <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                                    <div class="listing-table__col">
                                        <Spinner />
                                    </div>
                                </div>
                            </div>
                            <empty-table v-show="goods && !goods.length && categories.length" />
                            <empty-table v-show="goods && !goods.length && !categories.length" :empty-catalog="!categories.length" />
                        </div>
                        <ContentPreloader v-show="preloader" type="table" height="96" />
                    </div>
                    <div v-if="width > 699" class="page-small-column">
                        <ProductsNavbar />
                        <div v-if="group !== 'florist'" :class="{'mt-4': group !== 'managers'}">
                            <ProductsCategories manage :activeCategory="filters.category" @changeCategory="filterByCategory" />
                        </div>
                    </div>
                </div>
                <ProductsNavbar v-if="width <= 699" />
            </div>
        </div>
        <UniversalModal v-model="showCategoryModal">
            <UniversalModalHeader
                :title="'Категории'"
                @click:right="showCategoryModal = false"
            />
            <div class="universal-modal__content">
                <ProductsCategories manage :activeCategory="filters.category" @changeCategory="filterByCategory" />
            </div>
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { debounce } from '@/utils/functions';
import { numberFormat } from '@/utils/format';
import { getHiddenState, toggleHiddenGoods, syncVK } from '@/api/django';

import ProductsNavbar from '@/components/app/goods/ProductsNavbar';
import Spinner from "@/components/app/Spinner";
import ActionBar from '@/components/app/ActionBar';

export default {
    name: 'TheGoods',
    metaInfo: {
        title: 'Товары'
    },
    components: { ProductsNavbar, Spinner, ActionBar },
    data() {
        return {
            isHiddenAllGoods: null,
            opened: [],
            picked: [],
            listOfGoodsID: [],
            isVisibleSearch: false,
            showCategoryModal: false
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            categories: 'data/categories',
            goods: "goods/goods",
            busy: "goods/busy",
            next: "goods/next",
            goodsCount: 'goods/goodsCount',
            selectedCategory: 'goods/selectedCategory',
            activeFilters: 'goods/activeFilters',
            origin: 'ui/origin',
            preloader: 'ui/preloader',
            filters: 'goods/filters',
            authUser: 'auth/user',
        }),
        group() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
        allPicked() {
            if (this.goods && this.goods.length) {
                const arr = this.picked.map((item) => item.vendor_code) || [];
                return this.goods.every((a) => arr.includes(a.vendor_code));
            }
            return false;
        },
        checkedCountComputed() {
            if (this.allPicked) return this.goodsCount;
            return this.picked.length;
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
        this.$store.dispatch('shops/GET_SHOPS');
    },
    async mounted() {
        if (this.group !== 'florist') {
            await this.$store.dispatch('goods/GET_ALL_GOODS');
        } else if (this.group === 'florist') {
            await this.$store.dispatch('data/LOAD_CATEGORY');
            let showCaseCat = [];
            const filteredCat = this.categories.filter((category) => category.type === 'bouquet');
            if (filteredCat) {
                filteredCat.forEach((item) => {
                    if (item.childrens && item.childrens.length) {
                        item.childrens.forEach((child) => showCaseCat.push(child));
                    } else {
                        showCaseCat.push(item);
                    }
                });
            }
            showCaseCat = showCaseCat.find((item) => item.for_showcase === true);
            await this.$store.dispatch('goods/GET_ALL_GOODS', { field: 'category', value: showCaseCat.id });
        }
        this.$store.commit('ui/SET_PRELOADER', false);

        if (this.$route.query && this.$route.query.start_sync) {
            const timer = setInterval(() => {
                if (document.location.host) {
                    syncVK(this.origin);
                    this.$router.push({ name: 'goods' });
                    clearInterval(timer);
                }
            }, 300);
        }
    },
    methods: {
        getFilteredGoods: debounce(function (field, value) {
            this.filterGoods(field, value);
        }, 1000),
        async filterGoods(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('goods/GET_ALL_GOODS', { field, value });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        loadMore() {
            this.$store.dispatch('goods/GET_NEXT_PAGE');
        },
        async filterByCategory(category) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                window.scrollTo(0, 0);
                this.showCategoryModal = false
                this.checkboxAllHandler(false); // снимаем выделение со всех товаров при смене категории

                if (this.filters.category === null || this.filters.category !== category.id) {
                    this.$store.commit('goods/SET_SELECTED_CATEGORY', category);
                    await this.$store.dispatch('goods/GET_ALL_GOODS', { field: 'category', value: category.id });
                } else {
                    this.$store.commit('goods/SET_SELECTED_CATEGORY', null);
                    await this.$store.dispatch('goods/GET_ALL_GOODS', { field: 'category', value: null });
                }
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async toggleHiddenGoods(event) {
            this.$store.commit('ui/SET_PRELOADER', true);
            await toggleHiddenGoods({ is_hidden: event, products_ids: this.listOfGoodsID.length ? this.listOfGoodsID : [] });
            await this.$store.dispatch('goods/GET_ALL_GOODS');
            this.picked = [];
            this.listOfGoodsID = [];
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        async getHiddenState(params = null, changeList = false) {
            const { all_products_is_hidden, ids_list } = await getHiddenState(params);
            this.isHiddenAllGoods = all_products_is_hidden;
            if (changeList) this.listOfGoodsID = ids_list;
        },
        formatNumber(number) {
            return number === 0 ? 0 : numberFormat(number);
        },
        checkboxHandler(item) {
            if (!['florist', 'managers'].includes(this.group)) {
                if (this.picked.includes(item)) {
                    this.picked = this.picked.filter((el) => el.vendor_code !== item.vendor_code);
                    this.listOfGoodsID = this.listOfGoodsID.filter((el) => el !== item.vendor_code);
                } else {
                    this.picked.push(item);
                    this.listOfGoodsID.push(item.vendor_code);
                }
            } else {
                this.$router.push(`/goods/${item.vendor_code}`);
            }
        },
        async checkboxAllHandler(bool) {
            if (bool) {
                this.picked = this.goods;
                if (this.filters.name) {
                    this.getHiddenState({ mode: 'search', items: this.goods.length ? this.goods.map((item) => item.vendor_code).join() : '' }, true);
                } else if (this.filters.category && !this.filters.name) {
                    this.getHiddenState({ mode: 'category', items: this.filters.category }, true);
                } else {
                    await this.getHiddenState(null, true);
                }
            } else {
                this.picked = [];
                this.listOfGoodsID = [];
            }
        },
        openHandler(id) {
            if (this.opened.includes(id)) {
                this.opened = this.opened.filter((a) => a !== id);
            } else {
                this.opened.push(id);
            }
        },
    }
};
</script>

<template>
    <LineChartGenerator :chart-data="chartData" :chart-options="chartOptions" :styles="styles" />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler
} from 'chart.js'

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    Filler
)

export default {
    name: 'LineChart',
    components: {
        LineChartGenerator
    },
    props: {
        chartData: { type: Object, default: () => {} },
        chartOptions: { type: Object, default: () => {} },
        styles: { type: Object, default: () => {} },
    },
}
</script>

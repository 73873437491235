<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Помощь" for-listing :show-save-btn="false" />
            <div class="page-container page-container--listing">
                <Row>
                    <Col :cols="width >= 1024 ? 4 : width >= 480 ? 6 : 12">
                        <div class="help-contents__item">
                            <div class="help-contents__item-image">
                                <img src="@/assets/img/help/index/1.svg" alt="">
                            </div>
                            <div class="help-contents__item-info">
                                <div class="help-contents__item-title">Основное</div>
                                <router-link to="/help/menu" class="help-contents__item-link">
                                    Основное меню и профиль
                                </router-link>
                                <router-link to="/help/notifications" class="help-contents__item-link">
                                    Уведомления
                                </router-link>
                                <router-link to="/help/orders" class="help-contents__item-link">
                                    Заказы
                                </router-link>
                                <router-link
                                    v-if="!forCourier && !forFlorist"
                                    to="/help/chat"
                                    class="help-contents__item-link"
                                >
                                    Чаты
                                </router-link>
                                <router-link
                                    v-if="!forCourier"
                                    to="/help/products"
                                    class="help-contents__item-link"
                                >
                                    Товары
                                </router-link>
                                <router-link
                                    v-if="forAdministrator || forSupervisor"
                                    to="/help/categories"
                                    class="help-contents__item-link"
                                >
                                    Категории и теги
                                </router-link>
                                <router-link
                                    v-if="forAdministrator || forSupervisor"
                                    to="/help/clients"
                                    class="help-contents__item-link"
                                >
                                    Клиенты
                                </router-link>
                                <router-link
                                    v-if="forSupervisor"
                                    to="/help/statistic"
                                    class="help-contents__item-link"
                                >
                                    Статистика
                                </router-link>
                            </div>
                        </div>
                    </Col>
                    <Col :cols="width >= 1024 ? 4 : width >= 480 ? 6 : 12">
                        <div class="help-contents__item">
                            <div class="help-contents__item-image">
                                <img src="@/assets/img/help/index/2.svg" alt="">
                            </div>
                            <div class="help-contents__item-info">
                                <div class="help-contents__item-title">Склад</div>
                                <router-link
                                    v-if="!forCourier && !forFlorist"
                                    to="/help/stock"
                                    class="help-contents__item-link"
                                >
                                    Остатки
                                </router-link>
                                <router-link
                                    v-if="!forCourier"
                                    to="/help/arrivals"
                                    class="help-contents__item-link"
                                >
                                    Поставки
                                </router-link>
                                <router-link
                                    v-if="!forCourier && !forManager"
                                    to="/help/offs"
                                    class="help-contents__item-link"
                                >
                                    Списания
                                </router-link>
                                <router-link to="/help/transfers" class="help-contents__item-link">
                                    Перемещения
                                </router-link>
                                <router-link
                                    v-if="forAdministrator || forSupervisor"
                                    to="/help/inventories"
                                    class="help-contents__item-link"
                                >
                                    Инвентаризации
                                </router-link>
                            </div>
                        </div>
                    </Col>
                    <Col v-if="forSupervisor" :cols="width >= 1024 ? 4 : width >= 480 ? 6 : 12">
                        <div class="help-contents__item">
                            <div class="help-contents__item-image">
                                <img src="@/assets/img/help/index/3.svg" alt="">
                            </div>
                            <div class="help-contents__item-info">
                                <div class="help-contents__item-title">Финансы</div>
                                <router-link to="/help/shops" class="help-contents__item-link">
                                    Магазины
                                </router-link>
                                <router-link to="/help/returns" class="help-contents__item-link">
                                    Возвраты
                                </router-link>
                                <router-link to="/help/salarys-changes" class="help-contents__item-link">
                                    Премии и штрафы
                                </router-link>
                                <router-link to="/help/salaries" class="help-contents__item-link">
                                    Зарплаты
                                </router-link>
                                <router-link to="/help/users" class="help-contents__item-link">
                                    Сотрудники
                                </router-link>
                            </div>
                        </div>
                    </Col>
                    <Col v-if="!forCourier && !forFlorist" :cols="width >= 1024 ? 4 : width >= 480 ? 6 : 12">
                        <div class="help-contents__item">
                            <div class="help-contents__item-image">
                                <img src="@/assets/img/help/index/4.svg" alt="">
                            </div>
                            <div class="help-contents__item-info">
                                <div class="help-contents__item-title">Обратная связь</div>
                                <router-link to="/help/reviews" class="help-contents__item-link">
                                    Отзывы
                                </router-link>
                            </div>
                        </div>
                    </Col>
                    <Col v-if="forManager || forSupervisor" :cols="width >= 1024 ? 4 : width >= 480 ? 6 : 12">
                        <div class="help-contents__item">
                            <div class="help-contents__item-image">
                                <img src="@/assets/img/help/index/5.svg" alt="">
                            </div>
                            <div class="help-contents__item-info">
                                <div class="help-contents__item-title">Цветочная подписка</div>
                                <router-link to="/help/flower-subscriptions" class="help-contents__item-link">
                                    Цветочные подписки
                                </router-link>
                                <router-link to="/help/flower-subscription-settings" class="help-contents__item-link">
                                    Настройки
                                </router-link>
                            </div>
                        </div>
                    </Col>
                    <Col v-if="forSupervisor" :cols="width >= 1024 ? 4 : width >= 480 ? 6 : 12">
                        <div class="help-contents__item">
                            <div class="help-contents__item-image">
                                <img src="@/assets/img/help/index/6.svg" alt="">
                            </div>
                            <div class="help-contents__item-info">
                                <div class="help-contents__item-title">
                                    Другое
                                </div>
                                <router-link to="/help/discounts" class="help-contents__item-link">
                                    Скидки
                                </router-link>
                                <router-link to="/help/promocodes" class="help-contents__item-link">
                                    Промокоды
                                </router-link>
                                <router-link to="/help/integrations" class="help-contents__item-link">
                                    Интеграции
                                </router-link>
                                <router-link to="/help/settings" class="help-contents__item-link">
                                    Настройки
                                </router-link>
                                <router-link to="/help/subscription" class="help-contents__item-link">
                                    Подписка
                                </router-link>
                                <router-link to="/help/pages" class="help-contents__item-link">
                                    Страницы
                                </router-link>
                            </div>
                        </div>
                    </Col>
                    <Col :cols="width >= 1024 ? 4 : width >= 480 ? 6 : 12">
                        <div class="help-contents__item">
                            <div class="help-contents__item-image">
                                <img src="@/assets/img/help/index/7.svg" alt="">
                            </div>
                            <div class="help-contents__item-info">
                                <div class="help-contents__item-title">
                                    Обучение
                                </div>
                                <router-link to="/help/services-standards" class="help-contents__item-link">
                                    Стандарты сервиса
                                </router-link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TheHelp',
    metaInfo: {
        title: 'Помощь'
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
        forFlorist() {
            return ['florist'].includes(this.authUserGroup);
        },
        forCourier() {
            return ['courier'].includes(this.authUserGroup);
        },
        forManager() {
            return ['managers'].includes(this.authUserGroup);
        },
        forAdministrator() {
            return ['administrators'].includes(this.authUserGroup);
        },
        forSupervisor() {
            return ['supervisor'].includes(this.authUserGroup);
        },
    },
};
</script>

/* eslint-disable */
import {
    fetchDiscounts,
    fetchDiscountsID
} from '@/api/discounts';

const state = {
    discounts: []
};

const mutations = {
    SET_DISCOUNTS(state, discounts) {
        state.discounts = discounts;
    },
    CLEAR(state) {
        state.discounts = [];
    }
};

const actions = {
    async LOAD_DISCOUNTS({ commit }) {
        const { items } = await fetchDiscounts();
        commit('SET_DISCOUNTS', items);
    },
    async EDIT_DISCOUNT({}, discountID) {
        return await fetchDiscountsID(discountID);
    }
};

const getters = {
    discounts: state => state.discounts
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta'

import TheAuth from '@/routes/TheAuth.vue';
import TheRegistration from '@/routes/TheRegistration.vue'
import TheOrders from '@/routes/TheOrders.vue';
import TheOrderCreate from '@/routes/TheOrderCreate.vue';
import TheOrdersReturns from '@/routes/TheOrdersReturns.vue';
import TheOrdersTutorial from '@/routes/TheOrdersTutorial.vue';
import TheOrdersReturnCreate from '@/routes/TheOrdersReturnCreate.vue';
import TheGoods from '@/routes/TheGoods.vue';
import TheGoodCreate from '@/routes/TheGoodCreate.vue';
import TheProductCategoriesCreate from '@/routes/TheProductCategoriesCreate';
import TheDiscounts from '@/routes/TheDiscounts.vue';
import TheDiscountCreate from '@/routes/TheDiscountCreate.vue';
import ThePromocodes from '@/routes/ThePromocodes.vue';
import ThePromocodeCreate from '@/routes/ThePromocodeCreate.vue';
import TheCertificates from '@/routes/TheCertificates';
import TheCertificatesCreate from '@/routes/TheCertificatesCreate';
import TheChat from '@/routes/TheChat.vue';
import TheUsers from '@/routes/TheUsers.vue';
import TheUserCreate from '@/routes/TheUserCreate.vue';
import TheClients from '@/routes/TheClients.vue';
import TheClientsCreate from '@/routes/TheClientsCreate';
import TheReviews from '@/routes/TheReviews.vue';
import TheReviewsID from '@/routes/TheReviewsID.vue';
import TheStoreroomStore from '@/routes/TheStoreroomStore.vue';
import TheStoreroomDeliveries from '@/routes/TheStoreroomDeliveries.vue';
import TheStoreroomDeliveriesCreate from '@/routes/TheStoreroomDeliveriesCreate.vue';
import TheStoreroomOffs from '@/routes/TheStoreroomOffs.vue';
import TheStoreroomOffsCreate from '@/routes/TheStoreroomOffsCreate.vue';
import TheStoreroomMovement from '@/routes/TheStoreroomMovement.vue';
import TheStoreroomMovementCreate from '@/routes/TheStoreroomMovementCreate.vue';
import TheInventories from '@/routes/TheInventories';
import TheInventoriesCreate from '@/routes/TheInventoriesCreate';
import TheFranchise from '@/routes/TheFranchise.vue';
import TheFranchiseCreate from '@/routes/TheFranchiseCreate.vue';
import TheShops from '@/routes/TheShops.vue';
import TheShopsCreate from '@/routes/TheShopsCreate.vue';
import TheNotificationsCreate from '@/routes/TheNotificationsCreate.vue';
import TheMailings from '@/routes/TheMailings';
import TheMailingsCreate from '@/routes/TheMailingsCreate';
import TheUserNotification from '@/routes/TheUserNotification.vue';
import ThePages from '@/routes/ThePages';
import ThePagesCreate from '@/routes/ThePagesCreate';
import TheSalarys from '@/routes/TheSalarys';
import TheSalarysCreate from '@/routes/TheSalarysCreate';
import TheSalarysChanges from '@/routes/TheSalarysChanges';
import TheSalarysChangesCreate from '@/routes/TheSalarysChangesCreate';
import TheStatistics from '@/routes/TheStatistics';
import TheEncashmentsCreate from '@/routes/TheEncashmentsCreate';
import TheIntegrations from '@/routes/TheIntegrations';
import TheIntegrationsVK from '@/routes/TheIntegrationsVK';
import TheIntegrationsFb from '@/routes/TheIntegrationsFb';
import TheSubscription from '@/routes/TheSubscription';
import TheSubscriptionsAdmin from '@/routes/TheSubscriptionsAdmin';
import TheMotivations from '@/routes/TheMotivations';
import TheMotivationsCreate from '@/routes/TheMotivationsCreate';
import TheCatalogs from '@/routes/TheCatalogs';
import TheCatalogsCreate from '@/routes/TheCatalogsCreate';
import TheUnavailable from '@/routes/TheUnavailable';
import TheTags from '@/routes/TheTags';
import TheTagsCreate from '@/routes/TheTagsCreate';
import TheTagsCategoryCreate from '@/routes/TheTagsCategoryCreate';
import TheWishesForProducts from "@/routes/TheWishesForProducts";
import TheWishesForProductsID from "@/routes/TheWishesForProductsID";
import TheFlowerSubscriptions from "@/routes/TheFlowerSubscriptions";
import TheFlowerSubscriptionsCreate from "@/routes/TheFlowerSubscriptionsCreate";
import TheFlowerSubscriptionSettings from "@/routes/TheFlowerSubscriptionSettings";
import TheHelpIndex from '@/routes/help/index';
import TheHelpMenu from '@/routes/help/menu';
import TheHelpNotifications from '@/routes/help/notifications';
import TheHelpOrders from '@/routes/help/orders';
import TheHelpChat from '@/routes/help/chat';
import TheHelpProducts from '@/routes/help/products';
import TheHelpCategories from '@/routes/help/categories';
import TheHelpPages from '@/routes/help/pages';
import TheHelpIntegrations from '@/routes/help/integrations';
import TheHelpFlowerSubscriptions from '@/routes/help/flowerSubscriptions';
import TheHelpFlowerSubscriptionSettings from '@/routes/help/flowerSubscriptionSettings';
import TheHelpPromocodes from '@/routes/help/promocodes';
import TheHelpDiscounts from '@/routes/help/discounts';
import TheHelpReviews from '@/routes/help/reviews';
import TheHelpSalarysChanges from '@/routes/help/salarysChanges';
import TheHelpClients from '@/routes/help/clients';
import TheHelpStatistic from '@/routes/help/statistic';
import TheHelpStock from '@/routes/help/stock';
import TheHelpArrivals from '@/routes/help/arrivals';
import TheHelpOffs from '@/routes/help/offs';
import TheHelpTransfers from '@/routes/help/transfers';
import TheHelpInventories from '@/routes/help/inventories';
import TheHelpShops from '@/routes/help/shops';
import TheHelpReturns from '@/routes/help/returns';
import TheHelpSalaries from '@/routes/help/salaries';
import TheHelpUsers from '@/routes/help/users';
import TheHelpSettings from '@/routes/help/settings';
import TheHelpSubscription from '@/routes/help/subscription';
import TheServicesStandards from '@/routes/help/servicesStandards'

Vue.use(Router);
Vue.use(Meta)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        {
            path: '/unavailable',
            name: 'unavailable',
            component: TheUnavailable,
        },
        {
            path: '/',
            name: 'home',
            component: TheAuth,
            meta: {
                guest: true
            }
        },
        {
            path: '/registration',
            name: 'registration',
            component: TheRegistration,
            meta: {
                guest: true
            }
        },
        {
            path: '/orders',
            name: 'orders',
            component: TheOrders
        },
        {
            path: '/orders/create',
            name: 'order-create',
            component: TheOrderCreate
        },
        {
            path: '/orders/:orderID',
            name: 'order',
            component: TheOrderCreate
        },
        {
            path: '/orders-returns',
            name: 'orders-returns',
            component: TheOrdersReturns
        },
        {
            path: '/orders-returns/create',
            name: 'order-return-create',
            component: TheOrdersReturnCreate
        },
        {
            path: '/orders-returns/:ID',
            name: 'order-return',
            component: TheOrdersReturnCreate
        },
        {
            path: '/orders-tutorial',
            name: 'orders-tutorial',
            component: TheOrdersTutorial
        },
        {
            path: '/goods',
            name: 'goods',
            component: TheGoods
        },
        {
            path: '/goods/create',
            name: 'good-create',
            component: TheGoodCreate
        },
        {
            path: '/goods/:ID',
            name: 'good',
            component: TheGoodCreate
        },
        {
            path: '/categories/create',
            name: 'category-create',
            component: TheProductCategoriesCreate
        },
        {
            path: '/categories/:id',
            name: 'category',
            component: TheProductCategoriesCreate
        },
        {
            path: '/salarys',
            name: 'salarys',
            component: TheSalarys
        },
        {
            path: '/salarys/create',
            name: 'salarys-create',
            component: TheSalarysCreate
        },
        {
            path: '/salarys/:salaryID',
            name: 'salary',
            component: TheSalarysCreate
        },
        {
            path: '/salarys-changes/',
            name: 'salarys-changes',
            component: TheSalarysChanges
        },
        {
            path: '/salarys-changes/create',
            name: 'salarys-changes-create',
            component: TheSalarysChangesCreate
        },
        {
            path: '/salarys-changes/:changesID',
            name: 'salarys-change',
            component: TheSalarysChangesCreate
        },
        {
            path: '/discounts',
            name: 'discounts',
            component: TheDiscounts
        },
        {
            path: '/discounts/create',
            name: 'discount-create',
            component: TheDiscountCreate
        },
        {
            path: '/discounts/:discountID',
            name: 'discount',
            component: TheDiscountCreate
        },
        {
            path: '/promocodes',
            name: 'promocodes',
            component: ThePromocodes
        },
        {
            path: '/promocodes/create',
            name: 'promocode-create',
            component: ThePromocodeCreate
        },
        {
            path: '/promocodes/:promocodeID',
            name: 'promocode',
            component: ThePromocodeCreate
        },
        {
            path: '/certificates',
            name: 'certificates',
            component: TheCertificates
        },
        {
            path: '/certificates/create',
            name: 'certificates-create',
            component: TheCertificatesCreate
        },
        {
            path: '/certificates/:ID',
            name: 'certificate',
            component: TheCertificatesCreate
        },
        {
            path: '/finances/encashments/create',
            name: 'encashment-create',
            component: TheEncashmentsCreate
        },
        {
            path: '/finances/encashments/:ID',
            name: 'encashment',
            component: TheEncashmentsCreate
        },
        {
            path: '/chat',
            name: 'chat',
            component: TheChat
        },
        {
            path: '/users',
            name: 'users',
            component: TheUsers
        },
        {
            path: '/users/create',
            name: 'user-create',
            component: TheUserCreate
        },
        {
            path: '/users/:ID',
            name: 'user',
            component: TheUserCreate
        },
        {
            path: '/clients',
            name: 'clients',
            component: TheClients
        },
        {
            path: '/clients/create',
            name: 'clients-create',
            component: TheClientsCreate
        },
        {
            path: '/clients/:ID',
            name: 'client',
            component: TheClientsCreate
        },
        {
            path: '/reviews',
            name: 'reviews',
            component: TheReviews
        },
        {
            path: '/reviews/:ID',
            name: 'review',
            component: TheReviewsID
        },
        {
            path: '/statistics',
            name: 'Statistics',
            component: TheStatistics
        },
        {
            path: '/storeroom/store',
            name: 'store',
            component: TheStoreroomStore
        },
        {
            path: '/storeroom/deliveries',
            name: 'deliveries',
            component: TheStoreroomDeliveries
        },
        {
            path: '/storeroom/deliveries/create',
            name: 'deliveries-create',
            component: TheStoreroomDeliveriesCreate
        },
        {
            path: '/storeroom/deliveries/:ID',
            name: 'deliverie',
            component: TheStoreroomDeliveriesCreate
        },
        {
            path: '/storeroom/offs',
            name: 'offs',
            component: TheStoreroomOffs
        },
        {
            path: '/storeroom/offs/create',
            name: 'offs-create',
            component: TheStoreroomOffsCreate
        },
        {
            path: '/storeroom/offs/:ID',
            name: 'off',
            component: TheStoreroomOffsCreate
        },
        {
            path: '/storeroom/movement',
            name: 'movements',
            component: TheStoreroomMovement
        },
        {
            path: '/storeroom/movement/create',
            name: 'movement-create',
            component: TheStoreroomMovementCreate
        },
        {
            path: '/storeroom/movement/:ID',
            name: 'movement',
            component: TheStoreroomMovementCreate
        },
        {
            path: '/storeroom/inventories',
            name: 'inventories',
            component: TheInventories
        },
        {
            path: '/storeroom/inventories/create',
            name: 'inventory-create',
            component: TheInventoriesCreate
        },
        {
            path: '/storeroom/inventories/:ID',
            name: 'inventory',
            component: TheInventoriesCreate
        },
        {
            path: '/business',
            name: 'franchises',
            component: TheFranchise
        },
        {
            path: '/business/create',
            name: 'franchises-create',
            component: TheFranchiseCreate
        },
        {
            path: '/business/:ID',
            name: 'franchise',
            component: TheFranchiseCreate
        },
        {
            path: '/shops',
            name: 'shops',
            component: TheShops
        },
        {
            path: '/shops/create',
            name: 'shops-create',
            component: TheShopsCreate
        },
        {
            path: '/shops/:ID',
            name: 'shop',
            component: TheShopsCreate
        },
        {
            path: '/mailings',
            name: 'mailings',
            component: TheMailings
        },
        {
            path: '/mailings/create',
            name: 'mailings-create',
            component: TheMailingsCreate
        },
        {
            path: '/mailings/:ID',
            name: 'mailing',
            component: TheMailingsCreate
        },
        {
            path: '/notifications/create',
            name: 'notification-create',
            component: TheNotificationsCreate
        },
        {
            path: '/notifications/:ID',
            name: 'notification',
            component: TheNotificationsCreate
        },
        {
            path: '/user-notifications',
            name: 'user-notifications',
            component: TheUserNotification
        },
        {
            path: '/static-pages',
            name: 'static-pages',
            component: ThePages
        },
        {
            path: '/static-pages/create',
            name: 'static-pages-create',
            component: ThePagesCreate
        },
        {
            path: '/static-pages/:ID',
            name: 'static-page',
            component: ThePagesCreate
        },
        {
            path: '/integrations/',
            name: 'integrations',
            component: TheIntegrations
        },
        {
            path: '/integrations/vk',
            name: 'integrationsVK',
            component: TheIntegrationsVK
        },
        {
            path: '/integrations/fb',
            name: 'integrationsFb',
            component: TheIntegrationsFb
        },
        {
            path: '/subscription',
            name: 'subscription',
            component: TheSubscription
        },
        {
            path: '/subscriptions',
            name: 'subscriptions',
            component: TheSubscriptionsAdmin
        },
        {
            path: '/motivations',
            name: 'motivations',
            component: TheMotivations
        },
        {
            path: '/motivations/create',
            name: 'motivations-create',
            component: TheMotivationsCreate
        },
        {
            path: '/motivations/:ID',
            name: 'motivation',
            component: TheMotivationsCreate
        },
        {
            path: '/catalogs',
            name: 'catalogs',
            component: TheCatalogs
        },
        {
            path: '/catalogs/create',
            name: 'catalogs-create',
            component: TheCatalogsCreate
        },
        {
            path: '/catalogs/:ID',
            name: 'catalog',
            component: TheCatalogsCreate
        },
        {
            path: '/tags',
            name: 'tags',
            component: TheTags
        },
        {
            path: '/tags/create',
            name: 'tag-create',
            component: TheTagsCreate
        },
        {
            path: '/tags/:ID',
            name: 'tag',
            component: TheTagsCreate
        },
        {
            path: '/tags/category/create',
            name: 'tag-category-create',
            component: TheTagsCategoryCreate
        },
        {
            path: '/tags/category/:id',
            name: 'tag-category',
            component: TheTagsCategoryCreate
        },
        {
            path: '/wishes-for-products',
            name: 'wishes-for-products',
            component: TheWishesForProducts
        },
        {
            path: '/wishes-for-products/:id',
            name: 'wish-for-products',
            component: TheWishesForProductsID
        },
        {
            path: '/flower-subscriptions',
            name: 'flower-subscriptions',
            component: TheFlowerSubscriptions
        },
        {
            path: '/flower-subscriptions/create',
            name: 'flower-subscriptions-create',
            component: TheFlowerSubscriptionsCreate
        },
        {
            path: '/flower-subscriptions/:ID',
            name: 'flower-subscription',
            component: TheFlowerSubscriptionsCreate
        },
        {
            path: '/flower-subscription-settings',
            name: 'flower-subscription-settings',
            component: TheFlowerSubscriptionSettings
        },
        {
            path: '/help',
            name: 'help',
            component: TheHelpIndex
        },
        {
            path: '/help/menu',
            name: 'help-menu',
            component: TheHelpMenu
        },
        {
            path: '/help/notifications',
            name: 'help-notifications',
            component: TheHelpNotifications
        },
        {
            path: '/help/orders',
            name: 'help-orders',
            component: TheHelpOrders
        },
        {
            path: '/help/promocodes',
            name: 'help-promocodes',
            component: TheHelpPromocodes
        },
        {
            path: '/help/discounts',
            name: 'help-discounts',
            component: TheHelpDiscounts
        },
        {
            path: '/help/reviews',
            name: 'help-reviews',
            component: TheHelpReviews
        },
        {
            path: '/help/salarys-changes',
            name: 'help-salarys-changes',
            component: TheHelpSalarysChanges
        },
        {
            path: '/help/chat',
            name: 'help-chat',
            component: TheHelpChat
        },
        {
            path: '/help/products',
            name: 'help-products',
            component: TheHelpProducts
        },
        {
            path: '/help/categories',
            name: 'help-categories',
            component: TheHelpCategories
        },
        {
            path: '/help/clients',
            name: 'help-clients',
            component: TheHelpClients
        },
        {
            path: '/help/statistic',
            name: 'help-statistic',
            component: TheHelpStatistic
        },
        {
            path: '/help/stock',
            name: 'help-stock',
            component: TheHelpStock
        },
        {
            path: '/help/arrivals',
            name: 'help-arrivals',
            component: TheHelpArrivals
        },
        {
            path: '/help/offs',
            name: 'help-offs',
            component: TheHelpOffs
        },
        {
            path: '/help/transfers',
            name: 'help-transfers',
            component: TheHelpTransfers
        },
        {
            path: '/help/inventories',
            name: 'help-inventories',
            component: TheHelpInventories
        },
        {
            path: '/help/shops',
            name: 'help-shops',
            component: TheHelpShops
        },
        {
            path: '/help/returns',
            name: 'help-returns',
            component: TheHelpReturns
        },
        {
            path: '/help/salaries',
            name: 'help-salaries',
            component: TheHelpSalaries
        },
        {
            path: '/help/users',
            name: 'help-users',
            component: TheHelpUsers
        },
        {
            path: '/help/integrations',
            name: 'help-integrations',
            component: TheHelpIntegrations
        },
        {
            path: '/help/flower-subscriptions',
            name: 'help-flower-subscriptions',
            component: TheHelpFlowerSubscriptions
        },
        {
            path: '/help/flower-subscription-settings',
            name: 'help-flower-subscription-settings',
            component: TheHelpFlowerSubscriptionSettings
        },
        {
            path: '/help/settings',
            name: 'help-settings',
            component: TheHelpSettings
        },
        {
            path: '/help/subscription',
            name: 'help-subscription',
            component: TheHelpSubscription
        },
        {
            path: '/help/pages',
            name: 'help-pages',
            component: TheHelpPages
        },
        {
            path: '/help/services-standards',
            name: 'help-services-standards',
            component: TheServicesStandards
        },
    ],
});

router.beforeEach((to, from, next) => {
    let access = ['subscription', 'help', 'user'];
    let tariff = JSON.parse(localStorage.getItem('tariff'));

    if (to.meta.guest) {
        if (localStorage.getItem('token')) {
            let group = localStorage.getItem('group');
            if (tariff && !tariff.tariff_name) {
                next({ name: 'subscription' })
            } else if (group === 'courier' || group === 'florist' || group === 'supervisor' || group === 'administrators') {
                next({ name: 'orders' })
            } else {
                next({ name: 'goods' })
            }
        } else {
            next();
        }
    } else {
        if (!localStorage.getItem('token')) {
            next('/');
        } else if (tariff && !tariff.tariff_name && !access.includes(to.name)) {
            next('/subscription');
        } else {
            next();
        }
    }
});

// Подключение yandex метрики
if (process.env.NODE_ENV === 'production') {
    let ready = false;
    const basePath = (router.options.base || '/').replace(/\/$/, '');

    router.onReady(() => {
        ready = true
    });

    if (window.ym === undefined) {
        create()
    }

    function create() {
        if (!ready) {
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            ym(86837279, "init", {
                defer: true,
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true
            });
        }
        router.afterEach((to, from) => {
            ym(86837279, 'hit', basePath + to.fullPath, {
                referer: basePath + from.fullPath
            })
        })
    }
}

export default router;

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Мотивации" for-listing :show-save-btn="false" />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="motivations && motivations.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 25%">Магазин</div>
                            <div class="listing-table__col" style="width: 25%">Состав</div>
                            <div class="listing-table__col" style="width: 25%">Период активности</div>
                            <div class="listing-table__col" style="width: 25%">Статус</div>
                        </div>
                        <router-link v-for="item in motivations" :key="item.id" :to="`/motivations/${item.id}`" class="listing-table__row">
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                {{ getShopName(item.shop) }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <div v-if="item.products.length">
                                    <span v-for="product in item.products" :key="product.product.name"> {{ product.product.name }}, {{ product.percent }}%; </span>
                                </div>
                                <span v-else>Товары не указаны</span>
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Период активности</div>
                                {{ dateFormat(item.active_from, 'ru') }} - {{ dateFormat(item.active_to, 'ru') }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <StatusSelect
                                    :value="item.is_active"
                                    :options="[
                                        { id: true, name: 'Активна', color: '#0BD58A', icon: 'check' },
                                        { id: false, name: 'Не активна', color: '#F00C29', icon: 'close' }
                                    ]"
                                    disabled
                                />
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="motivations.results && !motivations.results.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { date } from '@/utils/format';
import Spinner from '@/components/app/Spinner.vue';

export default {
    name: 'TheMotivations',
    metaInfo: {
        title: 'Мотивации'
    },
    components: { Spinner },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            motivations: 'motivations/motivations',
            busy: 'motivations/busy',
            next: 'motivations/next',
            preloader: 'ui/preloader',
            shops: 'shops/shops',
            authUser: 'auth/user',
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        await this.$store.dispatch('shops/GET_SHOPS', true);
        await this.$store.dispatch('motivations/GET_MOTIVATIONS');
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    beforeDestroy() {
        this.$store.commit('motivations/CLEAR_FILTERS_STATE');
    },
    methods: {
        loadMore() {
            this.$store.dispatch('motivations/GET_NEXT_PAGE');
        },
        getShopName(shop) {
            const find = this.shops.results.find((item) => item.id === shop);
            if (find) return find.address;
            return shop;
        },
        dateFormat(value, type) {
            return date(new Date(value), type);
        }
    }
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Отзывы"
                for-listing
                :show-save-btn="false"
                filter
                :filterIndicator="!!activeFilters.length"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'reviews-filter')"
            />
            <div
                class="page-container page-container--listing"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="reviews && reviews.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 20%">Заказ</div>
                            <div class="listing-table__col" style="width: 25%">Оценка</div>
                            <div class="listing-table__col" style="width: 35%">Отзыв</div>
                            <div class="listing-table__col" style="width: 150px; flex: 0 0 auto">Дата создания</div>
                        </div>
                        <router-link
                            v-for="review in reviews"
                            :key="review.id"
                            :to="`/reviews/${review.id}`"
                            class="listing-table__row"
                        >
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                <div class="d-flex align-center">
                                    {{ review.order }}
                                    <Icon color="#0D63F3" class="ml-2" @click.stop.prevent="openLink(`/orders/${review.order}`)">
                                        new-window
                                    </Icon>
                                </div>
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 25%' : ''">
                                <div>
                                    <v-icon v-for="index in review.rating" :key="index" size="14" color="#F7D431">mdi-star</v-icon>
                                </div>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 35%' : ''">
                                <div>
                                    <div v-html="review.text || '&mdash;'" />
                                    <div v-if="review.wishes.length" class="mt-4">
                                        {{ review.rating === 5 ? 'Понравилось:' : review.rating === 4 ? 'Поработать:' : 'Не понравилось:' }}
                                        {{ getWishesString(review) }}
                                    </div>
                                </div>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 150px; flex: 0 0 auto' : ''">
                                <div class="listing-table__col-label">Дата создания</div>
                                <span style="color: #8AACCD">{{ formatDate(review.datetime_created, 'hours-ru') }}</span>
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="reviews && !reviews.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { date } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheReviews',
    metaInfo: {
        title: 'Отзывы'
    },
    components: { Spinner },
    data() {
        return {
            wishes: [
                { name: 'упаковка', value: 'packing' },
                { name: 'букет', value: 'bouquet' },
                { name: 'сервис', value: 'service' },
                { name: 'доставка', value: 'delivery' },
                { name: 'десерты или кофе', value: 'eat' },
                { name: 'над упаковкой', value: 'improve_packing' },
                { name: 'над доставкой', value: 'improve_delivery' },
                { name: 'со сборкой цветов', value: 'improve_bouquet' },
                { name: 'с общением менеджера/флориста', value: 'improve_employee_communication' },
                { name: 'со вкусом десертов/кофе', value: 'improve_eat' },
                { name: 'неверно собран заказ', value: 'bad_order' },
                { name: 'испорчена упаковка', value: 'bad_packing' },
                { name: 'не устроил курьер', value: 'bad_courier' },
                { name: 'опоздали с доставкой', value: 'bad_delivery' },
                { name: 'цветы завяли', value: 'bad_flowers' },
                { name: 'было невкусно', value: 'bad_eat' },
            ],
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            reviews: 'reviews/reviews',
            busy: 'reviews/busy',
            next: 'reviews/next',
            preloader: 'ui/preloader',
            activeFilters: 'reviews/activeFilters'
        }),
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await this.$store.dispatch('reviews/GET_REVIEWS');
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        loadMore() {
            this.$store.dispatch('reviews/GET_NEXT_PAGE');
        },
        getWishesString(review) {
            return this.wishes.filter(item => review.wishes.includes(item.value)).map(item => item.name).join(', ')
        },
        formatDate(value, type) {
            return date(new Date(value), type);
        },
        openLink(link) {
            window.open(link, '_blank')
        }
    }
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Клиенты"
                for-listing
                :show-save-btn="false"
                filter
                :filterIndicator="!!activeFilters.length"
                :search="width <= 699"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'clients-filter')"
                @click:search="isVisibleSearch = !isVisibleSearch"
            />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <Search
                    :value="filters.search"
                    :class="{'mb-0': users && users.length && Object.keys(picked).length}"
                    :is-visible-mobile="isVisibleSearch"
                    @input="$store.commit('clients/SET_FILTER', { field: 'search', value: $event}), getFilteredUsers()"
                />
                <ActionBar
                    v-show="users && users.length && Object.keys(picked).length"
                    with-notification
                    :picked="picked"
                    :all-picked="allPicked"
                    :picked-count="checkedCountComputed"
                    :observerMargin="width <= 699 ? 60 : 120"
                    @checkbox="checkboxAllHandler"
                    @notification="sendNotification"
                />
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="users && users.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                            <div class="listing-table__col pl-0" style="width: calc((100% - 112px) / 7)">Имя</div>
                            <div class="listing-table__col" style="width: calc((100% - 112px) / 7)">Телефон</div>
                            <div class="listing-table__col" style="width: calc((100% - 112px) / 7)">
                                <sort-table
                                    title="Покупок"
                                    :direction="sort.direction"
                                    type="num_of_purchases"
                                    :active="sort.type === 'num_of_purchases'"
                                    @change="changeTableSort"
                                />
                            </div>
                            <div class="listing-table__col" style="width: calc((100% - 112px) / 7)">
                                <sort-table
                                    title="Сумма"
                                    :direction="sort.direction"
                                    type="summ_of_purchases"
                                    :active="sort.type === 'summ_of_purchases'"
                                    @change="changeTableSort"
                                />
                            </div>
                            <div class="listing-table__col" style="width: calc((100% - 112px) / 7)">
                                <sort-table
                                    title="Ср. чек"
                                    :direction="sort.direction"
                                    type="avge_check"
                                    :active="sort.type === 'avge_check'"
                                    @change="changeTableSort"
                                />
                            </div>
                            <div class="listing-table__col" style="width: calc((100% - 112px) / 7)">
                                <sort-table
                                    title="Бонусы"
                                    :direction="sort.direction"
                                    type="number_of_bonus"
                                    :active="sort.type === 'number_of_bonus'"
                                    @change="changeTableSort"
                                />
                            </div>
                            <div class="listing-table__col" style="width: calc((100% - 112px) / 7)">
                                <sort-table
                                    title="Последняя покупка"
                                    :direction="sort.direction"
                                    type="last_order"
                                    :active="sort.type === 'last_order'"
                                    @change="changeTableSort"
                                />
                            </div>
                        </div>
                        <router-link
                            v-for="item in users"
                            :key="item.id"
                            :to="`/clients/${item.id}`"
                            class="listing-table__row"
                            :class="{ active: picked.includes(item) }"
                        >
                            <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                <div class="listing-table__image" @click.prevent.stop="checkboxHandler(item)">
                                    <img v-if="item.avatar" :src="item.avatar" alt="">
                                    <Icon v-else>user</Icon>
                                </div>
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-inline"
                                :class="{'pt-0 mr-0': width <= 1024}"
                                :style="width > 1024 ? 'width: calc((100% - 112px) / 7)' : ''"
                            >
                                <span class="text-cut">{{ item.last_name }} {{ item.first_name }}</span>
                                <span class="text-cut" style="color: #8AACCD">
                                    {{ item.sex && item.sex === 'Man' ? 'Мужской' : item.sex === 'Woman' ? 'Женский' : 'Не указан' }}
                                </span>
                            </div>

                            <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pb-0" />

                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 7)' : ''">
                                <div class="listing-table__col-label">Телефон</div>
                                <span class="text-cut">{{ item.phone || '&mdash;' }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 7)' : ''">
                                <div class="listing-table__col-label">Покупок</div>
                                <span class="text-cut">{{ numberFormat(item.num_of_purchases) }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 7)' : ''">
                                <div class="listing-table__col-label">Сумма</div>
                                <span class="text-cut">{{ numberFormat(+item.summ_of_purchases) }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 7)' : ''">
                                <div class="listing-table__col-label">Ср. чек</div>
                                <span class="text-cut">{{ numberFormat(+item.avge_check) }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 7)' : ''">
                                <div class="listing-table__col-label">Бонусы</div>
                                <span class="text-cut">{{ numberFormat(+item.number_of_bonus) }}</span>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 7)' : ''">
                                <div class="listing-table__col-label">Последняя покупка</div>
                                <span class="text-cut">{{ item.last_order ? date(item.last_order, 'hours-ru') : '&mdash;' }}</span>
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="users && !users.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" height="96" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from '@/utils/functions';
import { date, numberFormat } from '@/utils/format';
import { getClientsIDs } from '@/api/django';

import Spinner from '@/components/app/Spinner';
import ActionBar from '@/components/app/ActionBar';

export default {
    name: 'TheClients',
    metaInfo: {
        title: 'Клиенты'
    },
    components: { Spinner, ActionBar },
    data() {
        return {
            isVisibleSearch: false,
            picked: []
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            users: 'clients/users',
            busy: 'clients/busy',
            next: 'clients/next',
            sort: 'clients/sort',
            usersCount: 'clients/usersCount',
            filters: 'clients/filters',
            activeFilters: 'clients/activeFilters',
            authUser: 'auth/user',
        }),
        allPicked() {
            if (this.users && this.users.length) {
                const arr = this.picked.map((item) => item.id) || [];
                return this.users.every((a) => arr.includes(a.id));
            }
            return false;
        },
        checkedCountComputed() {
            if (this.allPicked) return this.usersCount;
            return this.picked.length;
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            this.$store.dispatch('clients/GET_FILTER_MIN_MAX_VALUES');
            await this.$store.dispatch('clients/GET_USERS', { field: 'ordering', value: `${this.sort.direction}${this.sort.type}` });
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        getFilteredUsers: debounce(function (field, value) {
            this.filterUsers(field, value);
        }, 1000),
        async filterUsers(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('clients/GET_USERS', { field, value });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        loadMore() {
            this.$store.dispatch('clients/GET_NEXT_PAGE');
        },
        async changeTableSort({ type }) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                window.scrollTo(0, 0);
                if (this.sort.type === type) {
                    this.$store.commit('clients/CHANGE_SORT', { type, direction: this.sort.direction === '' ? '-' : '' });
                }
                this.$store.commit('clients/CHANGE_SORT', { type, direction: this.sort.direction });
                await this.$store.dispatch('clients/GET_USERS', { field: 'ordering', value: `${this.sort.direction}${type}` });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        checkboxHandler(user) {
            if (this.picked.includes(user)) {
                this.picked = this.picked.filter((item) => item.id !== user.id);
            } else {
                this.picked.push(user);
            }
        },
        async checkboxAllHandler(bool) {
            if (bool) {
                this.picked = this.users;
                const filters = await this.$store.dispatch('clients/prepareFilters');
                const ids = await getClientsIDs(filters);
                this.$store.commit('clients/SET_CLIENTS_IDS', ids);
            } else {
                this.picked = [];
                this.$store.commit('clients/SET_CLIENTS_IDS', []);
            }
        },
        sendNotification() {
            this.$store.dispatch('ui/checkUserEditPermission');
            this.$store.commit('clients/SET_CLIENTS_FOR_NOTIFICATION', this.picked);
            this.$router.push('/mailings/create');
        },
        date(value, type) {
            return date(new Date(value), type);
        },
        numberFormat(value) {
            if (value) return numberFormat(value);
            return value || 0;
        },
        name(index) {
            return `${this.users[index].last_name} ${this.users[index].first_name}`;
        },
    }
};
</script>

/* eslint-disable */
import router from '@/router';

import {
    getGoods,
    getGoodsByType,
    getNextPageGoodsByType,
    getGoodsByField,
} from '@/api/django';

const state = {
    goods: {},
    filters: {
        limit: 20,
        offset: 0,
        shop: null,
        name: '',
        is_template: '',
        category: null,
        type: '',
        is_deleted: 'False',
        creator__id: null
    },
    savedFilters: {}, // используется для сохранения фильтров на странице /goods, потому что при открытии любой модалки с товарами фильтры из filters стираются
    activeFilters: [],
    busy: false,
    next_page: false,
    goodsCount: 0,
    selectedCategory: null,
    query: '/admin/products/',
    search: '',
};

const mutations = {
    SET_ALL_GOODS(state, goods) {
        state.goods = goods.results.map(i => ({...i, count: 1}));
        state.next_page = !!goods.next;
        state.filters.offset = 20;
        state.goodsCount = goods.count;
    },
    UPDATE_CURRENT_GOODS_TABLE(state, goods) {
        state.goods = goods;
    },
    SET_NEXT_GOODS(state, { results, next }) {
        state.goods = [...state.goods, ...results.map(i => ({...i, count: 1}))];
        state.next_page = !!next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTERS(state, filters) {
        state.filters = filters;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    SET_SAVED_FILTERS(state, filters) {
        state.savedFilters = filters;
    },
    CLEAR_NEXT(state) {
        state.next_page = false;
        state.filters.offset = 20;
    },
    CLEAR(state) {
        state.goods = {};
        state.next_page = false;
        state.busy = false;
        state.filters.offset = 20;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            shop: null,
            name: '',
            is_template: '',
            category: null,
            type: '',
            is_deleted: 'False',
            creator__id: null
        }
        state.next_page = null;
    },
    TOOGLE_VISIBLE(state, slug) {
        let find = state.goods.find(good => good.slug === slug);
        find.is_hidden = !find.is_hidden;
    },
    SET_SELECTED_CATEGORY(state, category) {
        state.selectedCategory = category;
    },
    CLEAR_CATEGORY(state) {
        state.selectedCategory = null;
    },
    SET_QUERY(state, query) {
        state.query = query;
    },
    SET_SEARCH(state, value) {
        state.search = value;
    },
};

const actions = {
    async GET_ALL_GOODS({ state, commit, dispatch, rootGetters }, filter = null) {
        try {
            state.busy = true;
            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;
            state.filters.is_template = rootGetters['ui/goodsModalMode'] === 'catalogs' ? 'True' : '';

            let filters = await dispatch('prepareFilters');

            commit('SET_ALL_GOODS', await getGoods(filters));
            state.busy = false;
        } catch (error) {
            console.log(error);
            if (error.message === 'Network Error') {
                localStorage.setItem('redirectUnavailable', location.pathname);
                router.push('/unavailable');
            }
        }
    },
    async GET_NEXT_PAGE ({ state, commit, dispatch }) {
        if (state.next_page && !state.busy) {
            try {
                state.busy = true;
                let filters = await dispatch('prepareFilters');
                const res = await getGoods(filters);
                commit('SET_NEXT_GOODS', res);
            } catch (error) {
                console.log(error);
            }
        }
    },
    async GET_GOODS_BY_TYPE({ commit }, type) {
        try {
            if (type === 'equipment') {
                commit('equipments/SET_ALL_EQUIPMENTS', await getGoodsByType(type), { root: true });
                return;
            }
            commit('SET_ALL_GOODS', await getGoodsByType(type));
        } catch (error) {
            console.log(error);
        }
    },
    async GET_NEXT_PAGE_GOODS_BY_TYPE({ commit, getters, rootGetters }, type) {
        if (rootGetters['equipments/next'] && type === 'equipment') {
            try {
                state.busy = true;
                commit('equipments/SET_NEXT_EQUIPMENTS', await getNextPageGoodsByType(20, rootGetters['equipments/offset'], type), { root: true });
            } catch (error) {
                console.log(error);
            };
        }
        if (getters['next']) {
            try {
                state.busy = true;
                commit('SET_NEXT_GOODS', await getNextPageGoodsByType(20, getters['offset'], type));
            } catch (error) {
                console.log(error);
            }
        }
    },
    async GET_GOODS_BY_FIELD({ commit, dispatch, rootGetters }, { field, value, type }) {
        const goodIsTemplate = rootGetters['ui/goodsModalMode'] === 'catalogs' ? 'True' : '';
        if (value !== 0 && value !== null && typeof value !== 'undefined') {
            try {
                commit('SET_ALL_GOODS', await getGoodsByField(field, value, type, goodIsTemplate));
            } catch (error) {
                console.log(error);
            };
        } else {
            dispatch('GET_ALL_GOODS', {});
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([key, value]) => {
            if (key && (value || value === false || value === 0)) {
                filters[key] = value;
            }
        })
        let activeFilters = Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    goods: state => state.goods,
    next: state => !!state.next_page,
    busy: state => state.busy,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    savedFilters: state => state.savedFilters,
    selectedCategory: state => state.selectedCategory,
    query: state => state.query,
    search: state => state.search,
    goodsCount: state => state.goodsCount,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

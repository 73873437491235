<template>
    <div class="app-filter">
        <div
            class="app-filter-current"
            :class="{'active': value || value === 0 || value === false, 'rotate': dropdown}"
            tabindex="-1"
            @blur="closeDropdown"
            @click.stop="toggle()"
        >
            <div v-if="label" class="app-filter__label">
                {{ label }}
            </div>
            <v-icon v-if="icon" :color="value ? '#1a73e8' : '#96A5B9'" style="width: 20px;margin-right: 9px;">
                mdi-{{ icon }}
            </v-icon>
            <v-icon
                v-if="computedValue && computedValue.icon"
                :color="computedValue && computedValue.color ? computedValue.color : computedValue ? '#1a73e8' : '#96A5B9'"
            >
                {{ computedValue.icon }}
            </v-icon>
            <div
                v-if="mod === 'status'"
                class="app-filter__icon"
                :class="{
                    'up-order': computedValue[field] == 'Оплачен',
                    'accept': computedValue[field]== 'Принят',
                    'payment': computedValue[field] == 'Создан',
                    'bouquet': computedValue[field] == 'Готов к выдаче',
                    'delivery-man': computedValue[field] == 'Курьер выехал',
                    'done': computedValue[field] == 'Выполнен',
                    'delivery-man': computedValue[field] == 'Курьер выехал',
                    'canceled': computedValue[field] == 'Отменен',
                    'manual': computedValue[field] == 'Ожидает отмены',
                    'return': computedValue[field] == 'Возврат товара'
                }"
            />
            <div v-if="mod === 'shop'" class="app-filter__icon shop" />
            <div
                v-if="mod === 'orderType'"
                class="app-filter__icon order-type__all"
                :class="{'order-type__self': computedValue[field] == 'Самовывоз', 'order-type__delivery': computedValue[field] == 'Доставка'}"
            />
            <div
                v-if="mod === 'notifications'"
                class="app-filter__icon notifications__all"
                :class="{'notifications__unread': computedValue[field] == 'Непрочитано', 'notifications__read': computedValue[field] == 'Прочитано'}"
            />
            <span :style="computedValue && computedValue.icon ? 'margin-left: 5px' : ''">{{ computedValue[field] }}</span>
        </div>
        <transition name="dropdown">
            <ul v-if="!mod || mod === 'shop' || mod === 'sort'" v-show="dropdown" class="app-filter-list">
                <li
                    v-for="(option, index) in options"
                    :key="index"
                    class="app-filter-item"
                    :class="{ 'current': computedValue && computedValue[prop] === option[prop] }"
                    @click.prevent="select(option)"
                >
                    <v-icon v-if="option.icon" :color="option.color ? option.color : ''">
                        {{ option.icon }}
                    </v-icon>
                    <div>
                        <span :class="{'ml-2': option.icon}">{{ option[field] }}</span>
                        <div v-if="subText && option[subText]" class="app-filter-item__subtext">
                            {{ option[subText] }}
                        </div>
                    </div>
                </li>
            </ul>
        </transition>
        <transition name="dropdown">
            <ul v-if="mod === 'status'" v-show="dropdown" class="status-change-list filter">
                <li
                    v-for="(item, index) in options"
                    :key="index"
                    class="status-change-item"
                    :class="{
                        'current': computedValue && computedValue[prop] === item[prop],
                        'all': item.name == 'Все статусы',
                        'up-order':item.name == 'Оплачен',
                        'accept':item.name == 'Принят',
                        'payment':item.name == 'Создан',
                        'bouquet':item.name == 'Готов к выдаче',
                        'delivery-man':item.name == 'Курьер выехал',
                        'done':item.name == 'Выполнен',
                        'delivery-man':item.name == 'Курьер выехал',
                        'canceled':item.name == 'Отменен',
                        'manual': item.name == 'Ожидает отмены',
                        'return': item.name == 'Возврат товара'
                    }"
                    @click.stop="select(item)"
                >
                    <span>{{ item.name }}</span>
                </li>
            </ul>
        </transition>
        <transition name="dropdown">
            <ul v-if="mod === 'orderType'" v-show="dropdown" class="status-change-list width-100 filter">
                <li
                    v-for="(item, index) in options"
                    :key="index"
                    class="status-change-item"
                    :class="{
                        'current': computedValue && computedValue[prop] === item[prop],
                        'order-type__all': item.name == 'Доставка и самовывоз',
                        'order-type__self': item.name == 'Самовывоз',
                        'order-type__delivery': item.name == 'Доставка',
                    }"
                    @click.stop="select(item)"
                >
                    <span>{{ item.name }}</span>
                </li>
            </ul>
        </transition>
        <transition name="dropdown">
            <ul v-if="mod === 'notifications'" v-show="dropdown" class="status-change-list width-100 filter">
                <li
                    v-for="(item, index) in options"
                    :key="index"
                    class="status-change-item"
                    :class="{
                        'current': computedValue && computedValue[prop] === item[prop],
                        'notifications__all': item.name == 'Все',
                        'notifications__unread': item.name == 'Непрочитано',
                        'notifications__read': item.name == 'Прочитано',
                    }"
                    @click.stop="select(item)"
                >
                    <span>{{ item.name }}</span>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AppFilter',
    props: {
        value: { type: [String, Number, Object, Boolean], default: '' },
        label: { type: String, default: '' },
        options: { type: Array, default: () => [] },
        field: { type: String, default: 'name' },
        prop: { type: String, default: 'id' },
        mod: { type: String, default: '' },
        icon: { type: String, default: '' },
        subText: { type: String, default: '' }
    },
    data() {
        return {
            dropdown: false,
            selected: null,
        };
    },
    computed: {
        ...mapGetters({}),
        computedValue() {
            const find = this.options.find((item) => item[this.prop] === this.value);
            if (find) {
                return find;
            }
            return ''
        }
    },
    methods: {
        toggle() {
            this.dropdown = !this.dropdown;
        },
        select(option) {
            this.$emit('select', option[this.prop]);
            if (this.dropdown) {
                this.toggle();
            }
        },
        async closeDropdown() {
            await this.$nextTick();
            this.dropdown = false;
        },
    }
};
</script>

<style lang="sass">
    .app-filter__icon
        width: 24px
        height: 24px
        background-image: url(~@/assets/img/icon/flag.svg)
        background-repeat: no-repeat
        background-position: center
        margin-right: 5px
        &.shop
            background-image: url(~@/assets/img/icon/shop.svg)
        &.order-type__all
            background-image: url(~@/assets/img/icon/all-status.svg)
        &.order-type__self
            background-image: url(~@/assets/img/icon/shop-active.svg)
        &.order-type__delivery
            background-image: url(~@/assets/img/icon/courier-active.svg)
        &.done
            background-image: url(~@/assets/img/icon/lvl6.svg)
        &.return
            background-image: url(~@/assets/img/icon/return-order.svg)
        &.payment
            background-image: url(~@/assets/img/icon/lvl3.svg)
        &.up-order
            background-image: url(~@/assets/img/icon/lvl1.svg)
        &.bouquet
            background-image: url(~@/assets/img/icon/lvl4.svg)
        &.delivery-man
            background-image: url(~@/assets/img/icon/lvl5.svg)
        &.accept
            background-image: url(~@/assets/img/icon/lvl2.svg)
        &.canceled
            background-image: url(~@/assets/img/icon/lvl7.svg)
        &.manual
            background-image: url(~@/assets/img/icon/wait-cancel.svg)
        &.notifications__all
            background-image: url(~@/assets/img/icon/all-notifications.svg)
        &.notifications__unread
            background-image: url(~@/assets/img/icon/notifications__unread-active.svg)
        &.notifications__read
            background-image: url(~@/assets/img/icon/notifications__read-active.svg)

    .app-filter-current
        position: relative
        display: flex
        align-items: center
        box-sizing: border-box
        padding: 0 16px
        border: 1px solid rgba(68, 68, 68, 0.32)
        border-radius: 4px
        background-color: transparent
        background-repeat: no-repeat
        background-position: calc(150px - 13px) center
        background-size: 13px
        font-size: 16px
        color: #5A646E
        line-height: 46px
        cursor: pointer
        outline: none
        &:after
            content: ''
            width: 10px
            height: 5px
            background-repeat: no-repeat
            background-image: url(~@/assets/img/icon/select-carret.svg)
            position: absolute
            top: 0px
            bottom: 0px
            right: 13px
            margin: auto
            transition: 0.3s
        &.rotate
            border: 1px solid #1a73e8
            &:after
                transform: rotate(180deg)
        span
            white-space: nowrap
            border-radius: 4px
            padding: 4px 0
            text-overflow: ellipsis
            overflow: hidden
        &.active
            .app-filter__icon
                &.shop
                    background-image: url(~@/assets/img/icon/shop-active.svg)
            span
                color: #1a73e8
        @media screen and (max-width: 1023px)
            text-overflow: ellipsis
            font-size: 16px

    .app-filter__label
        position: absolute
        top: -8px
        left: 11px
        background: #fff
        padding: 0 5px
        font-size: 12px
        line-height: normal
        color: rgba(68, 68, 68, 0.6)
        z-index: 1

    .app-filter-list
        padding: 8px 0 !important
        margin: 0
        list-style: none
        position: absolute
        top: 56px
        left: 1px
        right: 9px
        display: block
        width: 100%
        border-radius: 4px
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)
        background: #fff
        z-index: 5
        @media screen and (max-width: 1023px)
            right: 9px
            width: auto

    .app-filter-list-item
        flex-shrink: 0

    .app-filter-item
        position: relative
        width: 100%
        box-sizing: border-box
        display: flex
        align-items: center
        padding: 13px 16px
        background-color: #ffffff
        font-size: 16px
        color: #5A646E
        text-align: left
        cursor: pointer
        &:hover
            background-color: #f4f9ff
        span.count
            margin-left: auto
            font-size: 12px
            color: #aeaeae
        &.current
            background-color: #f4f9ff
        @media screen and (max-width: 1023px)
            width: 100%
            text-align: left
            &:hover
                background-color: #ffffff
                color: #333333

    .app-filter-item__subtext
        font-size: 11px
        color: #8F8F8F

</style>

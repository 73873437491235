<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Скидки" for-listing :show-save-btn="false" />
            <div class="page-container page-container--listing page-container--with-fab">
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="discountsWithoutPromo && discountsWithoutPromo.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 33%">Название</div>
                            <div class="listing-table__col" style="width: 33%">Величина</div>
                            <div class="listing-table__col" style="width: 33%">Активность</div>
                        </div>
                        <router-link
                            v-for="item in discountsWithoutPromo"
                            :key="item.id"
                            :to="`/discounts/${item.id}`"
                            class="listing-table__row"
                        >
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                {{ item.name }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                <div class="listing-table__col-label">Величина</div>
                                {{ formatNumber(item.amount) }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                <StatusSelect
                                    :value="item.is_active"
                                    :options="[
                                        { id: true, name: 'Активна', color: '#36B687', icon: 'check' },
                                        { id: false, name: 'Не активна', color: '#F00C29', icon: 'close' }
                                    ]"
                                    disabled
                                />
                            </div>
                        </router-link>
                    </div>
                    <empty-table v-show="!preloader && discountsWithoutPromo && !discountsWithoutPromo.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { numberFormat } from '@/utils/format';

export default {
    name: 'TheDiscounts',
    metaInfo: {
        title: 'Скидки'
    },
    components: {},
    computed: {
        ...mapGetters({
            width: 'ui/width',
            discounts: 'discounts/discounts',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
        }),
        discountsWithoutPromo() {
            return this.discounts.filter((item) => !item.is_for_promocode);
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        await this.$store.dispatch('discounts/LOAD_DISCOUNTS');
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    methods: {
        formatNumber(number) {
            const value = number.slice(0, -1);
            const type = number.slice(-1);
            return numberFormat(value) + type;
        },
    }
};
</script>

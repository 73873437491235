<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Инвентаризации"
                for-listing
                :show-save-btn="false"
                filter
                :filterIndicator="!!activeFilters.length"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'inventories-filter')"
            />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="inventories && inventories.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 25%">№ инвентаризации</div>
                            <div class="listing-table__col" style="width: 25%">Дата</div>
                            <div class="listing-table__col" style="width: 25%">Магазин</div>
                            <div class="listing-table__col" style="width: 25%">Статус</div>
                        </div>
                        <router-link
                            v-for="item in inventories"
                            :key="item.id"
                            :to="`/storeroom/inventories/${item.id}`"
                            class="listing-table__row"
                            :class="{'not-seen': !item.is_seen }"
                        >
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                {{ item.id }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Дата</div>
                                {{ dateFormat(item.date) }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Магазин</div>
                                {{ item.shop.shop_name }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <StatusSelect
                                    :value="item.status"
                                    :options="options"
                                    :disabled="authUser.read_only || item.status === 1"
                                    @select="patchStatus($event, item)"
                                />
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="inventories && !inventories.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { date } from '@/utils/format';

import { updateInventory } from '@/api/stock';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheStoreroomOffs',
    metaInfo: {
        title: 'Инвентаризации'
    },
    components: {
        Spinner,
    },
    data() {
        return {
            options: [
                {
                    name: 'Новая',
                    id: 0,
                    icon: 'lightning',
                    color: '#6893BE'
                },
                {
                    name: 'Выполнено',
                    id: 1,
                    icon: 'check',
                    color: '#36B687'
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            inventories: 'inventories/inventories',
            busy: 'inventories/busy',
            next: 'inventories/next',
            preloader: 'ui/preloader',
            activeFilters: 'inventories/activeFilters',
            authUser: 'auth/user',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            this.$store.dispatch('shops/GET_SHOPS');
            await this.$store.dispatch('inventories/GET_INVENTORIES');
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        dateFormat(value) {
            return date(new Date(value), 'ru');
        },
        async patchStatus(status, item) {
            try {
                if (status === 0) return;
                this.$store.commit('ui/SET_PRELOADER', true);
                await updateInventory(item.id, { status });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Успешно подтверждено', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка подтверждения', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        loadMore() {
            this.$store.dispatch('inventories/GET_NEXT_PAGE');
        },
    }
};
</script>

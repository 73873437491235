/* eslint-disable */
import axios from 'axios';
import store from '@/store';

const baseURL = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_HOST_AUTH;

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(function(config) {
    store.dispatch('ui/startLoading', true);
    return config;
})

instance.interceptors.response.use(
    function(response) {
        store.dispatch('ui/stopLoading', false);
        return response.data;
    },
    function(error) {
        store.dispatch('ui/stopLoading', false);
        const { status, data } = error.response;
        console.log({ status, data });
        return Promise.reject({ status, data });
    }
)

export async function auth({ username, password }) {
    return await instance({
        method: 'post',
        url: '/login/',
        data: {
            username,
            password,
        }
    });
}

export function fetchUserId() {
    const token = localStorage.getItem('token');
    return instance({
        method: 'POST',
        url: '/token/check/',
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        data: {
            token: token.substring(7)
        }
    });
}

let requestNewAccessToken = null;
export async function getNewAccessToken() {
    if (requestNewAccessToken === null) {
        requestNewAccessToken = refreshAccessToken();
    } 
    const res = await requestNewAccessToken;
    store.commit('auth/SET_TOKEN', `Bearer ${res.access_token}`);
    requestNewAccessToken = null;
}

async function refreshAccessToken() {
    return await instance({
        method: 'POST',
        url: '/refresh/',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('refreshToken')}`
        }
    });
}

export async function responseErrorHandler(error, axiosInstance) {
    const originalConfig = error.config;
    if (originalConfig.url !== '/' && error.response) {
        if (error.response.status === 403 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
                return await axiosInstance(originalConfig);
            } catch (err) {
                return Promise.reject(err);
            }
        }
    }
    return Promise.reject(error);
}

/* eslint-disable */
import router from '@/router';

import {
    getAllOrdersData,
    filteredOrdersByQuery,
    getOrdersStatuses,
    getShopsWithOrderCount,
} from '@/api/django';

const state = {
    orders: {},
    ordersCount: 0,
    filters: {
        limit: 20,
        offset: 0,
        sort: '-visible_id',
        delivery_date_after: null,
        delivery_date_before: null,
        pickup: null,
        shop: null,
        status: null,
        source: null,
        payment_method: null,
        review_rating: null,
        search: '',
        promocode_id: null,
        courier_id: null,
        florist__id: null,
        florist__isnull: null,
        is_referral: null,
        is_coffee: null
    },
    activeFilters: [],
    busy: false,
    next_page: false,
    statuses: {},
    sort: {
        type: 'visible_id',
        direction: '-'
    },
    fastFilter: [
        { id: 1, name: 'Сегодня', active: false, group: ['supervisor', 'administrators', 'managers', 'florist', 'courier'] },
        { id: 2, name: 'Завтра', active: false, group: ['supervisor', 'administrators', 'managers', 'florist', 'courier'] },
        { id: 3, name: 'Нет курьера', active: false, group: ['supervisor', 'administrators', 'managers', 'florist'] },
        { id: 4, name: 'Нет магазина', active: false, group: ['supervisor', 'administrators', 'managers'] },
        { id: 5, name: 'Нет фото флориста', active: false, group: ['supervisor', 'administrators', 'managers', 'florist'] },
    ],
    fastFilterQueryString: '',
    shopsWithOrderCount: [],
    showStatusCount: true,
};

const mutations = {
    SET_ORDERS(state, orders) {
        state.orders = orders.results;
        state.next_page = !!orders.next;
        state.filters.offset = 20;
        state.ordersCount = orders.count;
    },
    SET_NEXT_ORDERS(state, { results, next }) {
        state.orders.push(...results);
        state.next_page = !!next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    CLEAR(state) {
        state.orders = {};
        state.next_page = false;
        state.busy = false;
        state.filters.offset = 20;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            sort: '-visible_id',
            delivery_date_before: null,
            delivery_date_after: null,
            pickup: null,
            shop: null,
            status: null,
            source: null,
            payment_method: null,
            review_rating: null,
            search: '',
            promocode_id: null,
            courier_id: null,
            florist__id: null,
            florist__isnull: null,
            is_referral: null,
            is_coffee: null
        };
        state.next_page = false;
    },
    SET_ORDERS_RESULTS(state, orders) {
        state.orders.results = orders;
    },
    SET_STATUSES(state, statuses) {
        state.statuses = statuses;
    },
    SET_FASTFILTER_QUERYSTRING(state, string) {
        state.fastFilterQueryString = string;
    },
    SET_SHOPS_WITH_ORDER_COUNT(state, shops) {
        state.shopsWithOrderCount = shops[0];
    },
    ADD_ORDER_IN_LIST(state, order) {
        state.orders.results.unshift(order);
        state.offset++;
    },
    CHANGE_SORT(state, sort) {
        state.sort.type = sort.type;
        state.sort.direction = sort.direction;
        state.filters.sort = `${sort.direction}${sort.type}`;
        let sortObj = JSON.parse(localStorage.getItem('sort'));
        sortObj.orders = sort;
        localStorage.setItem('sort', JSON.stringify(sortObj));
    },
    CHANGE_FAST_FILTER(state, data) {
        if (!data) {
            state.fastFilter.forEach(item => {
                item.active = false;
            })
            return;
        }
        state.fastFilter.forEach(item => {
            item.active = false;
            if (item.name === data.name) {
                item.active = data.active;
            }
        })
    },
    SET_SHOW_STATUS_COUNT(state, val) {
        state.showStatusCount = val;
    },
};

const actions = {
    async GET_ALL_ORDERS({ state, commit, dispatch, rootGetters }, filter = null) {
        try {
            state.busy = true;
            let currentActiveFiltert = state.fastFilter.find(item => item.active);

            if (currentActiveFiltert && filter && filter.field === 'sort') {
                let index = state.fastFilterQueryString.indexOf('&sort=');
                let newQuery = state.fastFilterQueryString.substring(0, index);
                newQuery += `&sort=${state.sort.direction}${state.sort.type}`;
                commit('SET_FASTFILTER_QUERYSTRING', newQuery);
                const orders = await filteredOrdersByQuery(state.fastFilterQueryString);
                commit('SET_ORDERS', orders);
            } else {
                if (currentActiveFiltert) {
                    commit('CLEAR_FILTERS_STATE');
                    commit('CHANGE_FAST_FILTER');
                    commit('SET_FILTER', { field: 'sort', value: `${state.sort.direction}${state.sort.type}` });
                }

                if (filter) {
                    commit('SET_FILTER', filter);
                }
                state.filters.offset = 0;
                let filters = await dispatch('prepareFilters');

                const orders = await getAllOrdersData(filters);
                commit('SET_ORDERS', orders);
            }
            state.busy = false;
        } catch (error) {
            console.log(error);
            if(error.message === 'Network Error') {
                localStorage.setItem('redirectUnavailable', location.pathname);
                router.push('/unavailable');
            }
        }
    },
    async GET_NEXT_PAGE({ state, commit, dispatch }) {
        if (state.next_page && !state.busy) {
            try {
                state.busy = true;
                let currentActiveFiltert = state.fastFilter.find(item => item.active);

                if (!currentActiveFiltert) {
                    let filters = await dispatch('prepareFilters');
                    const orders = await getAllOrdersData(filters);
                    commit('SET_NEXT_ORDERS', orders);
                } else {
                    let newQuery = ''
                    let index = state.fastFilterQueryString.indexOf('&offset=');
                    if (index !== -1) {
                        newQuery = state.fastFilterQueryString.substring(0, index);
                        newQuery += `&offset=${state.filters.offset}`;
                    } else {
                        newQuery = state.fastFilterQueryString + `&offset=${state.filters.offset}`;
                    }
                    commit('SET_FASTFILTER_QUERYSTRING', newQuery);
                    const orders = await filteredOrdersByQuery(state.fastFilterQueryString);
                    commit('SET_NEXT_ORDERS', orders);
                }
            } catch (error) {
                console.log(error);
            }
        }
    },
    async GET_ORDER_STATUSES({ commit, getters }) {
        try {
            commit('SET_STATUSES', await getOrdersStatuses());
        } catch (error) {
            if(error.message === 'Network Error') {
                localStorage.setItem('redirectUnavailable', location.pathname);
                router.push('/unavailable');
            }
        }
    },
    async SET_FAST_FILTER({ commit, dispatch, getters }, query) {
        try {
            const orders = await filteredOrdersByQuery(query);
            commit('SET_ORDERS', orders);
            commit('SET_FASTFILTER_QUERYSTRING', query);
        } catch (error) {
            console.log(error);
        }
    },
    async GET_SHOPS_WITH_ORDER_COUNT({ state, commit }, status) {
        try {
            commit('SET_SHOPS_WITH_ORDER_COUNT', await getShopsWithOrderCount(status));
        } catch (error) {
            console.log(error);
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([key, value]) => {
            if (key && (value || value === false || value === 0)) {
                filters[key] = value;
            }
        })
        let activeFilters = Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    orders: state => state.orders,
    ordersCount: state => state.ordersCount,
    next: state => !!state.next_page,
    busy: state => state.busy,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    statuses: state => state.statuses,
    shopsWithOrderCount: state => state.shopsWithOrderCount,
    showStatusCount: state => state.showStatusCount,
    sort: state => state.sort,
    fastFilter: state => state.fastFilter
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"universal-modal__component"},[_c('UniversalModalHeader',{attrs:{"title":"История заказов товара"},on:{"click:right":function($event){return _vm.$emit('closeModal')}}}),_c('div',{staticClass:"universal-modal__content"},[_c('div',{staticClass:"page__info-group"},[_c('Row',[_c('Col',[_c('v-menu',{ref:"goodStatisticDatepicker",attrs:{"offset-y":"","close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('InputCustom',_vm._g({attrs:{"value":_vm.formatedStatisticGoodDate,"label":"Дата","left-icon":"calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.statistic.date = [], _vm.getGoodStatistic()}}},on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","range":"","first-day-of-week":"1"},on:{"input":() => {
                                if (_vm.statistic.date[0] && _vm.statistic.date[1]) {
                                    _vm.$refs.goodStatisticDatepicker.save(_vm.statistic.date);
                                    _vm.getGoodStatistic();
                                }
                            }},model:{value:(_vm.statistic.date),callback:function ($$v) {_vm.$set(_vm.statistic, "date", $$v)},expression:"statistic.date"}})],1)],1),_c('Col',[_vm._v(" За указанный период товар \""+_vm._s(_vm.product.name)+"\" продали "+_vm._s(_vm.statistic.results.count_of_product || 0)+" "+_vm._s(_vm.declOfNum(_vm.statistic.results.count_of_product || 0, ['раз', 'раза', 'раз']))+" ")])],1)],1),_c('div',{staticClass:"page__info-group"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}],staticClass:"listing-table__wrap"},[(_vm.statistic.results.orders && _vm.statistic.results.orders.length)?_c('div',{staticClass:"listing-table"},[_vm._m(0),_vm._l((_vm.statistic.results.orders),function(item){return _c('a',{key:item.order__visible_id,staticClass:"listing-table__row",attrs:{"href":`/orders/${item.order__visible_id}`,"target":"_blank"}},[_c('div',{staticClass:"listing-table__col",style:(_vm.width > 1024 ? 'width: 33%' : '')},[_c('div',{staticClass:"listing-table__col-label"},[_vm._v("Заказ")]),_vm._v(" "+_vm._s(item.order__visible_id)+" ")]),_c('div',{staticClass:"listing-table__col",style:(_vm.width > 1024 ? 'width: 33%' : '')},[_c('div',{staticClass:"listing-table__col-label"},[_vm._v("Товара продано")]),_vm._v(" "+_vm._s(item.count)+" ")]),_c('div',{staticClass:"listing-table__col",style:(_vm.width > 1024 ? 'width: 33%' : '')},[_c('div',{staticClass:"listing-table__col-label"},[_vm._v("Дата")]),_vm._v(" "+_vm._s(item.order__date_created ? _vm.moment(item.order__date_created).format('DD.MM.YYYY HH:mm') : '—')+" ")])])})],2):_vm._e()]),_c('ContentPreloader',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"type":"table"}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listing-table__row listing-table__row--head"},[_c('div',{staticClass:"listing-table__col",staticStyle:{"width":"33%"}},[_vm._v("Заказ")]),_c('div',{staticClass:"listing-table__col",staticStyle:{"width":"33%"}},[_vm._v("Товара продано")]),_c('div',{staticClass:"listing-table__col",staticStyle:{"width":"33%"}},[_vm._v("Дата")])])
}]

export { render, staticRenderFns }
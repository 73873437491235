<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Возврат ${$route.params.ID}` : 'Новый возврат'"
                left-btn="left-big"
                :show-save-btn="!user.read_only && !check_ticket && (group === 'supervisor' || group === 'administrators')"
                @click:left="$router.back()"
                @click:save="saveItem"
            />
            <div class="page-container">
                <div class="page__info-group">
                    <Row>
                        <Col :cols="width > 1024 ? 6 : 12">
                            <InputCustom
                                v-model="returns.order"
                                label="Номер заказа"
                                right-icon="new-window"
                                disabled
                                @click:right="openLink(`/orders/${returns.order}`)"
                            />
                        </Col>
                        <Col :cols="width > 1024 ? 6 : 12">
                            <InputCustom
                                v-model="receipt_id"
                                label="Номер чека"
                                disabled
                            />
                        </Col>
                        <Col :cols="width > 1024 ? 6 : 12">
                            <InputCustom
                                v-model="receipt_uuid"
                                label="Идентификатор чека"
                                disabled
                            />
                        </Col>
                        <Col :cols="width > 1024 ? 6 : 12">
                            <InputSelect
                                v-model="returns.shop"
                                :options="shops.map(item => ({ ...item, icon: item?.type?.icon_path || '' }))"
                                label="Магазин"
                                :input-text="'address'"
                                disabled
                            />
                        </Col>
                        <Col :cols="width > 1024 ? 6 : 12">
                            <InputCustom
                                :value="getDate(returns.date_created)"
                                label="Дата возврата"
                                disabled
                            />
                        </Col>
                        <Col v-if="!with_promocode" :cols="width > 1024 ? 6 : 12">
                            <InputCustom
                                :value="`${+returns.amount} (из ${+final_cost})`"
                                label="Сумма возврата"
                                disabled
                            />
                        </Col>
                        <Col v-else :cols="width > 1024 ? 6 : 12">
                            <InputCustom
                                :value="`${+final_cost}`"
                                label="Сумма заказа"
                                disabled
                            />
                        </Col>
                        <Col v-if="!check_ticket" :cols="width > 1024 ? 6 : 12">
                            <InputCustom
                                :value="+manual_amount"
                                label="Сумма вручную"
                                :error="with_promocode ? 'Для этого заказа сумму возврата необходимо рассчитать вручную' : ''"
                                @input="manual_amount = $event"
                            />
                        </Col>
                        <Col>
                            <TextareaCustom
                                v-model="returns.manager_comment"
                                label="Комментарий"
                                :disabled="check_ticket"
                            />
                        </Col>
                        <Col>
                            <div class="checkbox-group">
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="returns.cashbox_viewed"
                                        label="Распечатан чек (возврат выполнен)"
                                        :disabled="check_ticket"
                                        @change="returns.cashbox_viewed = !returns.cashbox_viewed"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div class="page__info-group">
                    <div v-show="!preloader" class="listing-table__wrap">
                        <div v-if="returns_good_list.length" class="listing-table listing-table--products">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                <div class="listing-table__col pl-0">Наименование</div>
                                <div class="listing-table__col" style="width: 100px; flex: 0 0 auto;">Стоимость</div>
                                <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;text-align:center;">Количество</div>
                                <div class="listing-table__col" style="width: 110px; flex: 0 0 auto;text-align:right;">Сумма</div>
                                <div class="listing-table__col" style="width: 210px; flex: 0 0 auto;" />
                            </div>
                            <div
                                v-for="(good, index) in returns_good_list"
                                :key="index"
                                class="listing-table__row"
                            >
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image listing-table__image--with-link">
                                        <img :src="good.image && good.image.preview ? good.image.preview : require('@/assets/img/flower.svg')" alt="">
                                        <a href="#" class="listing-table__image-link" @click.stop.prevent="openLink(`/goods/${good.vendor_code}`)">
                                            <v-icon color="primary">mdi-open-in-new</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1024 ? 'width: calc(100% - 682px)' : 'width: calc(100% - 68px)'"
                                >
                                    <div @click.stop.prevent="toggleEquipmentSet(good)">
                                        <div
                                            class="listing-table__product-name"
                                            :style="!$route.params.ID && good.order_variant !== 'regular' ? 'color: rgba(219, 28, 28, 0.87)' : ''"
                                        >
                                            <span>{{ good.name }}</span>
                                            <span v-if="good.order_variant === 'increased'"> ({{ good.custom_equipment_amount }} шт.)</span>
                                            <span v-else-if="good.order_variant === 'plus30'"> (+30%)</span>
                                            <span v-else-if="good.order_variant === 'plus60'"> (+60%)</span>
                                            <v-btn
                                                v-if="good.equipment_set.length"
                                                icon
                                                style="margin-left: 5px"
                                                @click.stop.prevent="toggleEquipmentSet(good)"
                                            >
                                                <v-icon color="#96A5B9" v-show="openedEquips.includes(good.vendor_code)">mdi-chevron-up</v-icon>
                                                <v-icon color="#96A5B9" v-show="!openedEquips.includes(good.vendor_code)">mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </div>
                                        <div v-if="good.discount || good.is_hidden" class="listing-table__badges">
                                            <div v-if="good.is_hidden" class="listing-table__badges-item">
                                                Недоступен
                                            </div>
                                            <div
                                                v-if="good.discount"
                                                class="listing-table__badges-item"
                                                :class="{ 'listing-table__badges-item--yellow': good.discount }"
                                            >
                                                Акция
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    v-if="good.equipment_set.length && openedEquips.includes(good.vendor_code)"
                                    class="listing-table__product-equipment-list"
                                >
                                    <div class="listing-table__product-equipment-list-label">Состав:</div>
                                    <div
                                        v-for="(eq, index) in good.equipment_set.slice().sort((a, b) => a.sort - b.sort)"
                                        :key="index"
                                        class="listing-table__product-equipment-item"
                                    >
                                        <div>{{ eq.name }}</div>
                                        <div>{{ eq.count }} шт.</div>
                                    </div>
                                </div>

                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 100px; flex: 0 0 auto;' : 'flex: 1 1 auto'"
                                >
                                    <div>
                                        <div class="listing-table__col-label">Стоимость</div>
                                        {{
                                            good.discount && good.discount.price_with_discount
                                                ? numberFormat(+good.discount.price_with_discount)
                                                : numberFormat(+good.price)
                                        }}₽
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : 'width: calc(50% - 8px);max-width: 150px;'"
                                >
                                    <InputCounter
                                        :value="good.count"
                                        :disabled-plus="good.count >= good.max_count"
                                        :max-number="good.max_count"
                                        @input="changeGoodCount($event, good)"
                                    />
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 110px; flex: 0 0 auto;text-align:right;' : 'flex: 1 1 auto;align-items: flex-end'"
                                >
                                    <div>
                                        <div class="listing-table__col-label">Сумма</div>
                                        {{
                                            (good.count * good.price) ||
                                            numberFormat(good.discount && good.discount.price_with_discount
                                                ? good.count * good.discount.price_with_discount
                                                : good.count * good.price
                                            )
                                        }} ₽
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col"
                                    :style="width > 1024 ? 'width: 210px; flex: 0 0 auto;' : ''"
                                >
                                    <StatusSelect
                                        :value="good.return"
                                        :options="returnOptions || []"
                                        :disabled="user.read_only || check_ticket"
                                        @select="changeCheck($event, good, index)"
                                    />
                                </div>
                            </div>
                            <div v-if="delivery_cost" class="listing-table__row">
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image">
                                        <img src="@/assets/img/courier.svg" alt="">
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1024 ? 'width: calc(100% - 672px)' : 'width: calc(100% - 68px)'"
                                >
                                    <div>
                                        <div class="listing-table__product-name">
                                            <span>Доставка курьером</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 90px; flex: 0 0 auto;' : 'flex: 1 1 auto'"
                                >
                                    {{ numberFormat(+delivery_cost) }}
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : 'width: calc(50% - 8px);max-width: 150px;'"
                                >
                                    <InputCounter :value="1" disabled />
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 110px; flex: 0 0 auto;text-align:right;' : 'flex: 1 1 auto;align-items: flex-end'"
                                >
                                    {{ numberFormat(+delivery_cost) }} ₽
                                </div>
                                <div
                                    class="listing-table__col"
                                    :style="width > 1024 ? 'width: 210px; flex: 0 0 auto;' : ''"
                                >
                                    <StatusSelect
                                        :value="returns.return_delivery"
                                        :options="returnOptions || []"
                                        :disabled="user.read_only || check_ticket"
                                        @select="returns.return_delivery = $event"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContentPreloader v-show="preloader" type="table" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';

import { createReturns, updateReturns } from '@/api/django';

import { date, numberFormat } from '@/utils/format';

export default {
    name: 'TheOrdersReturnCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Возврат ${this.$route.params.ID}` : 'Новый возврат' }
    },
    data: () => ({
        returns: {
            status: 1,
            amount: null,
            order: null,
            return_delivery: false,
            manager_comment: '',
            cashbox_viewed: false,
            date_created: '',
            shop: null
        },
        receipt_id: null,
        receipt_uuid: null,
        final_cost: null,
        delivery_cost: 0,
        select_all: true,
        error: null,
        check_ticket: false,
        manual_amount: null,
        historical_products_price: false,
        with_promocode: false,
        openedEquips: [],
        returnOptions: [
            { id: true, name: 'Вернуть', icon: 'check', color: '#0BD58A' },
            { id: false, name: 'Не возвращать', icon: 'close', color: '#F00C29' }
        ]
    }),
    computed: {
        ...mapGetters({
            width: 'ui/width',
            user: 'auth/user',
            shops: 'data/shops',
            returns_good_list: 'returns/returns_good_list',
            preloader: 'ui/preloader'
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        formatedDate() {
            return moment(this.returns.date_created).format('DD.MM.YYYY')
        },
    },
    watch: {
        'returns.return_delivery'(val) {
            if (val) {
                this.returns.amount += this.delivery_cost;
            } else {
                this.returns.amount -= this.delivery_cost;
            }
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            this.$store.dispatch('data/LOAD_SHOPS', JSON.parse(localStorage.getItem('user')).city);

            if (this.$route.params.ID) {
                const res = await this.$store.dispatch('returns/GET_RETURN_DATA', this.$route.params.ID)

                this.returns.amount = +res.amount;
                this.returns.manager_comment = res.manager_comment;
                this.returns.order = res.order.id;
                this.returns.return_delivery = res.return_delivery;
                this.returns.date_created = res.date_created;
                this.returns.cashbox_viewed = res.cashbox_viewed;
                this.select_all = res.status === 1 ? true : false;
                this.final_cost = +res.order.final_cost;
                this.returns.status = res.status;
                this.returns.shop = res.order.shop;
                this.receipt_uuid = res.order.receipt_uuid;
                this.returns.shop = res.order.shop;
                this.delivery_cost = +res.order.delivery_cost;
                this.check_ticket = res.cashbox_viewed;
                if (res.historical_products_price && res.historical_products_price[0]) {
                    this.$store.commit('returns/SET_RETURNS_GOOD_LIST', res.historical_products_price);
                    this.historical_products_price = true;
                } else {
                    this.$store.commit('returns/SET_RETURNS_GOOD_LIST', res.order_list.filter((item) => item.name !== 'Доставка'));
                }

                if (JSON.parse(localStorage.getItem('user')).groups[0] !== 'florist') {
                    const response = await this.$store.dispatch('order/GET_ORDER_DATA', this.returns.order)
                    this.receipt_id = response.receipt_id;
                    this.receipt_uuid = response.receipt_uuid;
                    this.returns.shop = response.shop;
                    this.with_promocode = !!(response.promocode !== null || +response.debiting_bonuses !== 0 || (response.discount && +response.discount !== 0));
                    if (this.with_promocode) {
                        this.manual_amount = +res.amount;
                    }
                }

                if (this.returns.return_delivery) {
                    this.returns.amount -= this.delivery_cost;
                }
            } else {
                this.returns.order = +this.$route.query.id;
                const res = await this.$store.dispatch('order/GET_ORDER_DATA', this.$route.query.id)
                this.final_cost = +res.final_cost;
                this.receipt_id = res.receipt_id;
                this.receipt_uuid = res.receipt_uuid;
                this.returns.shop = res.shop;
                if (res.historical_products_price && res.historical_products_price[0]) {
                    this.$store.commit('returns/SET_RETURNS_GOOD_LIST', res.historical_products_price);
                    this.historical_products_price = true;
                } else {
                    this.$store.commit('returns/SET_RETURNS_GOOD_LIST', res.order_list);
                }
                this.delivery_cost = +res.delivery_cost;
                this.with_promocode = !!(res.promocode !== null || +res.debiting_bonuses !== 0 || (res.discount && +res.discount !== 0));
                this.setPrice();
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        this.$store.commit('returns/CLEAR_GOODS_LIST');
        this.$store.commit('order/CLEAR_ORDER_LIST');
    },
    methods: {
        async saveItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const data = {
                    order: this.returns.order,
                    manager_comment: this.returns.manager_comment,
                    return_delivery: this.returns.return_delivery ? 1 : 0,
                    status: this.select_all ? 1 : 0,
                    cashbox_viewed: this.returns.cashbox_viewed
                };

                if (this.manual_amount !== '' && this.manual_amount !== null) {
                    data.amount = this.manual_amount;
                } else {
                    data.amount = this.returns.amount;
                }

                if (!this.historical_products_price) {
                    let orderList = [];
                    for (let i = 0; i < this.returns_good_list.length; i++) {
                        if (this.returns_good_list[i].return) {
                            let obj;
                            if (!this.$route.params.ID) {
                                obj = this.returns_good_list[i]
                            } else {
                                obj = {
                                    product: this.returns_good_list[i].vendor_code,
                                    count: this.returns_good_list[i].count
                                }
                            }
                            orderList.push(obj);
                        }
                    }
                    data.order_list = orderList;
                } else {
                    if (!this.$route.params.ID) {
                        data.historical_products_price = this.returns_good_list.filter((good) => good.return === true);
                    } else {
                        data.historical_products_price = this.returns_good_list;
                    }
                }

                if (!this.$route.params.ID) {
                    await createReturns(data)
                } else {
                    await updateReturns(this.$route.params.ID, data)
                }

                this.$store.commit('returns/CLEAR_GOODS_LIST');
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Возврат успешно сохранен', type: 'success' })
                this.$router.push('/orders-returns');
            } catch (error) {
                console.log(error);
                if (error.data) {
                    this.error = error.data;
                    if (this.error && this.error.non_field_errors && this.error.non_field_errors[0]) {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: this.error.non_field_errors[0], type: 'error' })
                    }
                    if (this.error && this.error[0]) {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: this.error[0], type: 'error' })
                    }
                    if (this.error && this.error.cashbox_viewed && this.error.cashbox_viewed === 'Нельзя оформить возврат для заказа в этом статусе') {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: 'Нельзя оформить возврат для заказа в этом статусе', type: 'error' })
                    }
                }
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        dateFormat(value, type) {
            return date(new Date(value), type);
        },
        checkKey({ vendor_code }) {
            if (Object.keys(vendor_code).length !== 0 && vendor_code.constructor === Object) {
                return vendor_code.vendor_code;
            }
            return vendor_code;
        },
        changeCheck(event, good, index) {
            good.return = event;
            this.$store.commit('returns/SET_GOOD_RETURN', { good, index });
            let not_return = this.returns_good_list.find((item) => item.return === false);
            if (not_return === undefined) {
                this.select_all = true;
            } else {
                this.select_all = false;
            }
            this.setPrice();
        },
        selectAllGoods() {
            if (this.select_all) {
                this.$store.commit('returns/CHECK_ALL_GOODS', true);
                this.setPrice();
            } else {
                this.$store.commit('returns/CHECK_ALL_GOODS', false);
                this.returns.amount = 0;
            }
        },
        setPrice() {
            let price = [];
            this.returns_good_list.forEach((item) => {
                if (item.return) {
                    if (item.order_variant === 'regular' && item.discount && item.discount.price_with_discount) {
                        price.push(item.discount.price_with_discount * item.count);
                    } else {
                        price.push(item.price * item.count);
                    }
                }
            });

            if (price.length !== 0) {
                this.returns.amount = price.reduce((sum, current) => {
                    return sum + current;
                });
            } else {
                this.returns.amount = 0;
            }
        },
        changeGoodCount(event, good) {
            this.$store.commit('returns/CHANGE_GOOD_FIELD_VALUE', {
                vendor_code: good.vendor_code,
                field: 'count',
                value: +event
            });
            this.setPrice();
        },
        getDate(date) {
            if (date !== undefined && date !== '') {
                return moment(date).format('DD.MM.YYYY');
            } else {
                return '';
            }
        },
        openLink(link) {
            window.open(link, '_blank');
        },
        toggleEquipmentSet(good) {
            const id = this.checkKey(good);
            if (this.openedEquips.includes(id)) {
                this.openedEquips = this.openedEquips.filter((a) => a !== id);
            } else {
                this.openedEquips.push(id);
            }
        },
        numberFormat
    }
};
</script>

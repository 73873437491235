/* eslint-disable */
import { getSalarysChanges, getNextPageSalarysChanges } from '@/api/django.js';

const state = {
    salaryChanges: [],
    next:null,
    offset:20,
    busy: false,
    limit:20
};

const mutations = {
    SET_CHANGES(state, changes) {
        state.salaryChanges = changes.results;
        state.next = !!changes.next;
        state.offset = 20;
        state.busy = false;
    },
    SET_NEXT_SALARY_CHANGES(state, changes) {
        state.salaryChanges = state.salaryChanges.concat(changes.results);
        state.next = !!changes.next;
        state.offset += 20;
        state.busy = false;
    },
}

const actions = {
    async GET_SALARY_CHANGES({ commit }) {
        state.busy = true;
        commit('SET_CHANGES', await getSalarysChanges())
    },
    async LOAD_MORE({ state, commit }) {
        try {
            if (state.next) {
                state.busy = true;
                const data = await getNextPageSalarysChanges(state.limit, state.offset);
                commit('SET_NEXT_SALARY_CHANGES', data);
            }
        } catch (error) {
            console.log(error);
        }
    },
}

const getters = {
    changes: state => state.salaryChanges,
    busy: state => state.busy,
    next: state => state.next,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<script>
import { VIcon } from 'vuetify/lib';

export default {
    name: 'Icon',
    components: {
        'v-icon': VIcon
    },
    data() {
        return {
            icon: null
        }
    },
    props: {
        color: { type: String, default: '' },
        size: { type: [String, Number], default: '' }
    },
    mounted() {
        this.setIcon();
    },
    updated() {
        this.setIcon();
    },
    methods: {
        setIcon() {
            if (this.$slots.default) {
                this.icon = this.$slots.default[0].text.trim();
            }
        }
    },
    render() {
        if (this.icon) {
            return (
                !this.icon.startsWith('mdi')
                    ? <i class={`icon icon-${this.icon}`} style={{color: this.color, fontSize: this.size + 'px'}} on={this.$listeners} />
                    : <v-icon color={this.color} size={this.size} on={this.$listeners}>{this.icon}</v-icon>
            )
        }
        return ''
    }
}
</script>

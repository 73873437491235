<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="`Пожелание №${$route.params.id}`"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push({ name: 'wishes-for-products' })"
            />
            <div class="page-container">
                <Row>
                    <Col :cols="width > 1023 ? 6 : 12">
                        <InputCustom
                            v-model="wish.user.first_name"
                            label="Пользователь"
                            right-icon="new-window"
                            disabled
                            @click:right="openLink(`/clients/${wish.user.id}`)"
                        />
                    </Col>
                    <Col :cols="width > 1023 ? 6 : 12">
                        <InputCustom
                            :value="moment(wish.datetime_created).format('DD.MM.YYYY HH:mm')"
                            label="Дата"
                            disabled
                        />
                    </Col>
                    <Col cols="12">
                        <TextareaCustom
                            v-model="wish.text"
                            label="Пожелание"
                            disabled
                        />
                    </Col>
                </Row>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { getWishForProducts } from '@/api/django'

export default {
    name: 'TheWishesForProductsID',
    metaInfo() {
        return { title: `Пожелание №${this.$route.params.id}` }
    },
    data: () => ({
        wish: {
            datetime_created: new Date(),
            text: '',
            user: { first_name: '' }
        },
    }),
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            if (this.$route.params.id) {
                this.wish = await getWishForProducts(this.$route.params.id);
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        openLink(url) {
            window.open(url, '_blank');
        },
        moment
    },
};
</script>

/* eslint-disable */
export function throttle(fn, ms) {
    let _func;
    let _run;
    return function() {
        const _context = this;
        const _args = arguments;
        if (!_run) {
            fn.apply(_context, _args);
            _run = Date.now();
        } else {
            clearTimeout(_func);
            _func = setTimeout(function() {
                if ((Date.now() - _run) >= ms) {
                    fn.apply(_context, _args);
                    _run = Date.now();
                }
            }, ms - (Date.now()  - _run))
        }
    }
}

export function debounce(fn, delay) {
    let timer;
    return function() {
        // const _context = this;
        // const _args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(this, arguments);
        }, delay)
    }
}

export function declOfNum(number, words) {
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]];
}

<template>
    <div class="app-input-search-box">
        <div class="app-input-search-container">
            <InputCustom
                :value="city"
                label="Город"
                :disabled="disabled"
                :error="error"
                @input="input"
            />
        </div>
        <transition name="fade">
            <ul v-show="showList" class="app-input-search__list">
                <li v-for="item in options" :key="item.id" @click="chooseVariant(item)">
                    {{ item.russian_name }}
                </li>
                <li v-show="!options.length">Город не найден</li>
            </ul>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from '@/utils/functions';

export default {
    name: 'AppInputSearch',
    props: {
        options: {
            type: Array,
            default: () => []
        },
        cityId: {
            type: Number,
            default: 0
        },
        disabled: { type: Boolean, default: false },
        error: { type: String, default: '' }
    },
    data() {
        return {
            city: '',
            showList: false
        }
    },
    computed: {
        ...mapGetters({
            allCities: 'data/allCities'
        })
    },
    async mounted() {
        await this.$store.dispatch('data/LOAD_ALL_CITIES');
        await this.$nextTick();
        this.findCityByID();
    },
    methods: {
        input(event) {
            this.city = event;
            this.showVariants(event);
        },
        findCityByID() {
            if (this.cityId) {
                const item = this.allCities.find((item) => item.id == this.cityId);
                this.city = item.russian_name;
            }
        },
        showVariants: debounce(async function (event) {
            if (event.length >= 1) {
                await this.$store.dispatch('data/LOAD_CITIES_BY_NAME', event)
                this.showList = true;
            } else if (event.length === 0) {
                this.showList = false;
                this.city = '';
            }
        }, 500),
        chooseVariant(city) {
            this.city = city.russian_name;
            this.$emit('select', city.id);
            this.$emit('input', city);
            this.showList = false;
        }
    },
};
</script>

<style lang="scss" scoped>
    .app-input-search-box {
        position: relative;
    }

    .app-input-search-container {
        display: flex;
        align-items: center;
    }

    .app-input-search__list {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        list-style-type: none;
        margin: 0;
        padding: 10px;
        background: #fff;
        border-radius: 16px;
        box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.15);
        z-index: 10;
        li {
            line-height: 30px;
            cursor: pointer;
        }
    }

</style>

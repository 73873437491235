<template>
    <div v-if="showModal" ref="modal" class="side-filter__wrap">
        <transition name="fade">
            <div v-show="showModalContent" class="side-filter__overlay" @click="closeModal" />
        </transition>
        <div ref="wrap" class="side-filter" @click.self.prevent="closeModal">
            <transition name="side-filter">
                <div v-show="showModalContent" ref="content" class="side-filter__inner" @click.self.prevent="closeModal">
                    <div class="side-filter__header">
                        <div class="side-filter__header-title">Фильтр</div>
                        <div class="side-filter__header-buttons">
                            <button type="button" class="side-filter__header-btn" @click.prevent="closeModal">
                                <Icon>check</Icon>
                            </button>
                        </div>
                    </div>
                    <slot />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import modalSwipeClose from '@/utils/modalSwipeClose';

export default {
    name: 'SideFilter',
    model: {
        prop: 'opened'
    },
    props: {
        opened: { type: Boolean, default: false },
    },
    data() {
        return {
            showModal: false,
            showModalContent: false,
            modalSwipeClose: {}
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
    },
    watch: {
        async opened(val) {
            if (val) {
                this.showModal = true;
                await this.$nextTick()
                this.showModalContent = true;
                await this.$nextTick()
                this.modalSwipeClose.initTouchHandler(this.$refs.wrap, this.$refs.content, this.closeModal, this.$refs.modal, '.side-filter__wrap');
                this.$store.dispatch('ui/toggle_opened_modals_count', 'open');
            } else if (this.showModal) {
                this.closeModal()
            }
        }
    },
    mounted() {
        this.modalSwipeClose = modalSwipeClose();
    },
    beforeDestroy() {
        this.modalSwipeClose.destroyTouch(this.$refs.wrap);
    },
    methods: {
        closeModal() {
            if (this.showModal) {
                this.showModalContent = false;
                setTimeout(async () => {
                    this.showModal = false;
                    this.$emit('input', false);
                    await this.$nextTick();
                    this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
                }, 300)
            }
        },
    }
}
</script>

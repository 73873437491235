<template>
    <div ref="chatWrap" class="chat__messages-section-wrap" :class="{'chat__messages-section-wrap--minimize': minimize}">
        <div
            v-if="selectedChat.id || minimize"
            class="chat__messages-section"
            @click="showSmiles = false, showFastAnswers = false"
        >
            <div v-if="!minimize" class="chat__messages-header">
                <Icon color="#8AACCD" style="cursor: pointer;" @click="$store.commit('chat/SET_SELECTED_CHAT', {})">
                    chevron-left
                </Icon>
                <div class="chat__messages-header-avatar" @click="goToUser">
                    <img
                        :src="selectedChat.client && selectedChat.client.avatar ? selectedChat.client.avatar :
                            require('@/assets/img/icon/avatar2.svg')"
                        alt=""
                    >
                </div>
                <div class="chat__messages-header-name">
                    {{ 
                        selectedChat.name === 'Гость' ? `${selectedChat.name} №${selectedChat.id}` :
                        selectedChat.user && selectedChat.user.first_name ? selectedChat.user.first_name :
                        selectedChat.name
                    }}
                </div>
                <a
                    v-if="selectedChat.client.phone"
                    :href="`tel:${selectedChat.client.phone}`"
                    class="chat__messages-header-call"
                >
                    <Icon color="#0D63F3">phone</Icon>
                </a>
            </div>
            <div class="chat__messages-wrap">
                <div ref="chatMessages" class="chat__messages">
                    <div
                        v-for="(msg, index) in messages"
                        :key="msg.id"
                        class="chat__messages-item-wrap"
                    >
                        <div v-if="dateComparer(index, index - 1)" class="chat__messages-item-date">
                            {{ dateParser(msg.date) }}
                        </div>
                        <div class="chat__messages-item" :class="{'manager': msg.user_id !== selectedChat.user_id}">
                            <div
                                class="chat__message"
                                :class="{'chat__message--image': isMessageImage(msg.text)}"
                                v-images-loaded="imagesLoaded"
                                v-html="isMessageImage(msg.text) ? parseImg(msg.text) : msg.text" 
                            />
                            <div 
                                v-if="!(messages[index + 1]) || msg.user_id !== messages[index + 1].user_id || (index && dateComparer(index, index + 1))"
                                class="chat__messages-item-time-wrap"
                            >
                                <div class="chat__messages-item-time-text">
                                    {{ 
                                        !msg.user.first_name ? 'Гость' :
                                        msg.user.fisrt_name ? msg.user.fisrt_name : msg.user.first_name 
                                    }}, {{ parseTime(msg.time, msg.date) }}
                                </div>
                                <div class="chat__messages-item-status">
                                    <img v-if="!msg.seen" src="@/assets/img/icon/good1.svg" alt="">
                                    <img v-else src="@/assets/img/icon/good.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="isLoading" class="chat__messages-loader">
                    <Loader />
                </div>
            </div>
            <div class="chat__messages-footer">
                <div class="chat__messages-footer-buttons">
                    <label class="chat__messages-footer-add-file">
                        <input type="file" class="photo" :disabled="authUser.read_only" @change="onFileUpload($event)">
                        <Icon color="#8AACCD">paperclip</Icon>
                    </label>
                    <div class="chat__toggle-smiles" @click.stop>
                        <Icon color="#8AACCD" @click.stop="showSmiles = !showSmiles, showFastAnswers = false">
                            smile
                        </Icon>
                        <div v-show="showSmiles" class="chat__smiles-container-wrap">
                            <div class="chat__smiles-container">
                                <div class="chat__smiles-items">
                                    <span
                                        v-for="smile in smiles"
                                        :key="smile.description"
                                        class="chat__smiles-item"
                                        :title="smile.description"
                                        @click="message += smile.emoji"
                                    >
                                        {{ smile.emoji }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat__toggle-fast-answers" @click.stop>
                        <Icon color="#8AACCD" @click="showFastAnswers = !showFastAnswers, showSmiles = false">
                            lightning
                        </Icon>
                        <div v-show="showFastAnswers" class="chat__fast-answers-wrap">
                            <div class="chat__fast-answers">
                                <div
                                    v-for="(item, index) in fastAnswers"
                                    :key="index"
                                    class="chat__fast-answers-item"
                                    @click="setFastMessage(item.message)"
                                >
                                    {{ item.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TextareaCustom
                    v-model="message"
                    class="chat__messages-footer-textarea"
                    placeholder="Ваше сообщение"
                    max-rows="4"
                    :disabled="authUser.read_only"
                    @keydown.enter.exact.prevent="onSendMessage"
                />
                <button type="button" class="chat__messages-footer-send" :disabled="!message" @click="onSendMessage">
                    <Icon color="#8AACCD">
                        mailing
                    </Icon>
                </button>
            </div>
        </div>
        <div v-else class="chat__messages-empty">
            <Icon size="120" color="#CFDDEA">message-circle</Icon>
            <div class="mt-2">Выберите чат</div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import imagesLoaded from 'vue-images-loaded'

import { debounce } from '@/utils/functions';
import * as unicodeEmoji from '@/assets/unicode-emoji';

export default {
    name: 'ChatMessageSection',
    directives: {
        imagesLoaded
    },
    props: {
        minimize: { type: Boolean, default: false }
    },
    data() {
        return {
            message: '',
            showSmiles: false,
            showFastAnswers: false,
            isLoading: false,
            debouncedImagesLoading: null
        }
    },
    computed: {
        ...mapGetters({
            wsReady: 'chat/wsReady',
            messages: 'chat/messages',
            selectedChat: 'chat/selectedChat',
            width: 'ui/width',
            user_id: 'chat/user_id',
            authUser: 'auth/user',
            fastAnswers: 'chat/fastAnswers'
        }),
        smiles() {
            return unicodeEmoji.default.emojis.filter((item) => item.category === 'face-emotion' && !item.version.includes('13.') && item.emoji !== '☺️')
        }
    },
    watch: {
        selectedChat(newChat) {
            if (newChat.id) {
                this.isLoading = true;
            }
        },
        messages(newValue) {
            if (newValue.length) {
                let isfoundImages = newValue.find(item => this.isMessageImage(item.text))
                if (this.minimize || !isfoundImages || !this.isLoading) {
                    setTimeout(() => {
                        this.scrollChatToBottom();
                    }, !this.minimize ? 0 : 300)
                }
            }
        },
    },
    mounted() {
        this.debouncedImagesLoading = debounce(this.scrollChatToBottom, 300);
    },
    methods: {
        ...mapActions({
            send: 'chat/SEND_MESSAGE',
        }),
        imagesLoaded(instance) {
            if (instance.isComplete) {
                this.debouncedImagesLoading();
            }
        },
        onSendMessage() {
            if (!this.message || !this.wsReady) return
            if (this.message.match(/^\s*$/)) {
                this.message = ''
                return
            }
            this.send(this.message)
            this.message = ''
        },
        onFileUpload(event) {
            if (!event.target.files[0]) return;
            let data = new FormData()
            data.append('file', event.target.files[0])
            event.target.value = ''
            this.$store.dispatch('chat/FILE_UPLOAD', { file: data, token: this.$store.state.auth.token });
        },
        isMessageImage(text) {
            const extensions = ['.jpeg', '.png', '.jpg'];
            let match = false;
            extensions.forEach((item) => {
                if (text.includes(item) && !text.includes('http')) {
                    match = true;
                }
            })
            return match;
        },
        parseImg(string) {
            if (string.includes('<img')) {
                return string;
            }
            const img = '<a href="' + this.$store.state.chat.imageUrl + string +'" target="_blank" rel="nofollow noopener"><img alt="" class="message-image" src="' + this.$store.state.chat.imageUrl + string + '"/></a>'
            return img
        },
        parseTime(time, date) {
            const dateFormat = `${date[3]}${date[4]}/${date[0]}${date[1]}/${date.slice(-4)}`
            const localtime = new Date(Date.parse(`${dateFormat} ${time} UTC`))
            const t = `${localtime.getHours().toString().padStart(2, '0')}:${localtime.getMinutes().toString().padStart(2, '0')}`
            return `${t}`
        },
        dateParser(d) {
            const date = moment(d, 'DD.MM.YYYY');
            const curentDate = moment();
            if (curentDate.format('DD-MM-YYYY') === date.format('DD-MM-YYYY')) {
                return 'Сегодня'
            } else if (curentDate.subtract(1, 'd').format('DD-MM-YYYY') === date.format('DD-MM-YYYY')) {
                return 'Вчера'
            } else {
                return date.format('DD.MM.YYYY')
            }
        },
        dateComparer(first, second) {
            if (first === 0) {
                return true
            } else if (this.messages[first].date !== this.messages[second].date) {
                return true
            } else {
                return false
            }
        },
        goToUser() {
            if (this.selectedChat.type && this.selectedChat.type === 'user') {
                window.open(`/clients/${this.selectedChat.user_id}`, '_blank')
            }
        },
        setFastMessage(message) {
            this.message = !this.message.length ? message : `${this.message} ${message}`;
            this.showFastAnswers = false
        },
        scrollChatToBottom() {
            if (this.$refs.chatMessages) {
                this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight;
                this.isLoading = false;
            }
        },
    },
}
</script>

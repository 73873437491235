<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Списания"
                for-listing
                :show-save-btn="false"
                filter
                :filterIndicator="!!activeFilters.length"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'offs-filter')"
            />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="writeoffs && writeoffs.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 25%">№ списания</div>
                            <div class="listing-table__col" style="width: 25%">Магазин</div>
                            <div class="listing-table__col" style="width: 25%">Дата списания</div>
                            <div class="listing-table__col" style="width: 25%">Статус</div>
                        </div>
                        <router-link
                            v-for="item in writeoffs"
                            :key="item.id"
                            :to="`/storeroom/offs/${item.id}`"
                            class="listing-table__row"
                            :class="{'not-seen': !item.is_seen }"
                        >
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                {{ item.id }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Магазин</div>
                                {{ item.stock }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Дата</div>
                                {{ date(item.date, 'ru') }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                <StatusSelect
                                    :value="item.status"
                                    :options="options"
                                    :disabled="authUser.read_only || item.status === 1 || authUserGroup === 'florist'"
                                    @select="patchStatus($event, item)"
                                />
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="writeoffs && !writeoffs.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { date } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheStoreroomOffs',
    metaInfo: {
        title: 'Списания'
    },
    components: {
        Spinner,
    },
    data() {
        return {
            options: [
                {
                    name: 'Ожидает подтверждения',
                    id: 0,
                    icon: 'clock',
                    color: '#F2C94C'
                },
                {
                    name: 'Выполнено',
                    id: 1,
                    icon: 'check',
                    color: '#36B687'
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            writeoffs: 'writeoffs/writeoffs',
            shops: 'shops/shops',
            busy: 'writeoffs/busy',
            next: 'writeoffs/next',
            preloader: 'ui/preloader',
            activeFilters: 'writeoffs/activeFilters',
            authUser: 'auth/user',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            if (!this.activeFilters.length) {
                this.$store.commit('writeoffs/CLEAR');
            }

            const user = JSON.parse(window.localStorage.getItem('user'));
            if (user.city) {
                await this.$store.dispatch('shops/GET_SHOPS');
            }
            if (!this.activeFilters.length) {
                if (this.authUserGroup !== 'florist') {
                    await this.$store.dispatch('writeoffs/GET_ALL_WRITEOFFS');
                } else {
                    await this.filterOffs(user.shop);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        async filterOffs(shop) {
            await this.$store.dispatch('writeoffs/GET_ALL_WRITEOFFS', { field: 'stock', value: shop });
        },
        async patchStatus(status, item) {
            try {
                if (status === 0) return;
                item.status = status;
                await this.$store.dispatch('writeoffs/APPLY_WRITEOFFS', item.id)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Успешно подтверждено', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка смены статуса', type: 'error' })
            }
        },
        loadMore() {
            this.$store.dispatch('writeoffs/LOAD_MORE');
        },
        date(value, type) {
            return date(new Date(value), type);
        },
    }
};
</script>

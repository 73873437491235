<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Возвраты" for-listing :show-save-btn="false" />
            <div
                class="page-container page-container--listing"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="returns_list && returns_list.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 20%">№ заказа</div>
                            <div class="listing-table__col" style="width: 20%">Дата возврата</div>
                            <div class="listing-table__col" style="width: 20%">Сумма возврата</div>
                            <div class="listing-table__col" style="width: 20%">Магазин</div>
                            <div class="listing-table__col" style="width: 20%">Статус</div>
                        </div>
                        <router-link
                            v-for="item in returns_list"
                            :key="item.id"
                            :to="`/orders-returns/${item.id}`"
                            class="listing-table__row"
                        >
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                {{ item.order.id }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 20%' : ''">
                                <div class="listing-table__col-label">Дата возврата</div>
                                {{ getDate(item.date_created) }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                <div class="listing-table__col-label">Сумма</div>
                                {{ formatNumber(+item.amount) }} ₽
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                <div class="listing-table__col-label">Магазин</div>
                                {{ (item.order.shop && shops.find(s => s.id === item.order.shop)) ?
                                    shops.find(s => s.id === item.order.shop).address : 'Не указан' }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                <StatusSelect
                                    :value="item.cashbox_viewed"
                                    :options="[
                                        { id: true, name: 'Выполнен', color: '#36B687', icon: 'check' },
                                        { id: false, name: 'Не выполнен', color: '#F00C29', icon: 'close' }
                                    ]"
                                    disabled
                                />
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="returns_list && !returns_list.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { date, numberFormat } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheOrdersReturns',
    metaInfo: {
        title: 'Возвраты'
    },
    components: { Spinner },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            shops: 'data/shops',
            returns_list: 'returns/returns_list',
            busy: 'returns/busy',
            next: 'returns/next',
            preloader: 'ui/preloader'
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        this.$store.dispatch('data/LOAD_SHOPS', JSON.parse(localStorage.getItem('user')).city);
        await this.$store.dispatch('returns/GET_RETURNS_LIST');
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    methods: {
        loadMore() {
            this.$store.dispatch('returns/LOAD_MORE');
        },
        dateFormat(val, type) {
            return date(new Date(val), type);
        },
        getDate(date) {
            if (date !== undefined) {
                return this.width > 1023 ? moment(date).format('DD.MM.YYYY') : moment(date).format('DD.MM.YYYY hh:mm');
            }
            return '';
        },
        formatNumber(number) {
            return number === 0 ? 0 : numberFormat(number);
        },
    },
};
</script>

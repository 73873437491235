<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Инвентаризации"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список инвентаризаций</h2>
                                <p>В списке выводятся поля:</p>
                                <ul>
                                    <li>Магазин</li>
                                    <li>Дата создания</li>
                                    <li>Статус</li>
                                </ul>

                                <p>Есть фильтр по магазину и по статусу.</p>
                                <p>Статус инвентаризации можно изменить через выпадающий список.</p>
                                <img src="@/assets/img/help/inventories/1.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание инвентаризации</h2>
                                <p>Имя сотрудника подставляется автоматически исходя из имени авторизованного пользователя.</p>
                                <p>После выбора магазина осуществляется загрузка остатков.</p>
                                <p>В таблице остатков выводится сколько сейчас числится товаров, сколько фактически. Разница рассчитывается автоматически.</p>
                                <img src="@/assets/img/help/inventories/2.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Просмотр и редактирование инвентаризации</h2>
                                <p>Редактирование инвентаризации доступно только в статусе "Новая". Пока инвентаризация в этом статусе, то можно редактировать остатки.</p>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{ name: 'Перемещения', link: '/help/transfers' }, { name: 'К оглавлению', link: '/help' }]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpInventories',
    metaInfo: {
        title: 'Инвентаризации - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Подписки" for-listing :show-save-btn="false" />
            <div
                class="page-container page-container--listing"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="300" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="subscriptions.results && subscriptions.results.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: calc(100% / 7)">ID Клиента</div>
                            <div class="listing-table__col" style="width: calc(100% / 7)">Начало</div>
                            <div class="listing-table__col" style="width: calc(100% / 7)">Окончание</div>
                            <div class="listing-table__col" style="width: calc(100% / 7)">Тариф</div>
                            <div class="listing-table__col" style="width: calc(100% / 7)">Сумма</div>
                            <div class="listing-table__col" style="width: calc(100% / 7)">Магазины</div>
                            <div class="listing-table__col" style="width: calc(100% / 7)" />
                        </div>
                        <div v-for="item in subscriptions.results" :key="item.id" class="listing-table__row">
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                {{ item.franchise || '&mdash;' }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                {{ dateFormat(item.date_activity_start, 'ru') }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                {{ item.date_activity_end ? dateFormat(item.date_activity_end, 'ru') : '&mdash;' }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                {{ item.tariff.name }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                {{ item.tariff.cost }} руб
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                {{ item.count_of_avaliable_shop }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                Осталось {{ item.count_days_until_end }} дней
                            </div>
                        </div>
                        <div class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col" />
                        </div>
                    </div>
                    <empty-table v-show="subscriptions.results && !subscriptions.results.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { date, numberFormat } from '@/utils/format';
import { getTariffs, getAllSubscriptions } from '@/api/tariff';

export default {
    name: 'TheSubscriptionsAdmin',
    metaInfo: {
        title: 'Подписки'
    },
    data: () => ({
        tariffs: [],
        subscriptions: {}
    }),
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
        }),
        group() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            const tariffs = await getTariffs();
            this.tariffs = tariffs.results;
            this.subscriptions = await getAllSubscriptions();
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        loadMore() {
            console.log('loadmore');
        },
        dateFormat(data, type) {
            return date(new Date(data), type);
        },
        numberFormat(num) {
            if (num) return numberFormat(num);
            return 0;
        },
        tariffName(id) {
            const find = this.tariffs.find((item) => item.id === id);
            return find.name;
        },
        tariffCost(id) {
            const find = this.tariffs.find((item) => item.id === id);
            return this.numberFormat(find.cost);
        },
    }
};
</script>

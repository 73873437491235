<template>
    <Row class="clients-horizontal-filter">
        <Col :cols="width > 1024 ? 6 : 12">
            <InputSelect
                :value="selectValues.sex"
                :options="sexOptions"
                label="Пол"
                input-value="value"
                @input="filterClients('sex', $event)"
            />
        </Col>
        <Col :cols="width > 1024 ? 6 : 12">
            <v-menu ref="date" offset-y :close-on-content-click="false" transition="scale-transition" max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                    <InputCustom
                        :value="datepickerFormatted"
                        label="Последняя покупка"
                        placeholder="Выберите период"
                        left-icon="calendar"
                        readonly
                        clearable
                        v-on="on"
                        @click:clear="datepicker = [], filterClients('last_order_date__in', datepicker)"
                    />
                </template>
                <v-date-picker
                    v-model="datepicker"
                    no-title
                    range
                    first-day-of-week="1"
                    @input="() => {
                            if (datepicker[0] && datepicker[1]) {
                                $refs.date.save(datepicker);
                                datepicker.sort();
                                filterClients('last_order_date__in', datepicker);
                            }
                        }"
                />
            </v-menu>
        </Col>
        <Col :cols="width > 1024 ? 3 : 12">
            <div v-show="!preloader" class="side-filter__content-group side-filter__content-group--block">
                <div class="side-filter__content-group-title">
                    Количество покупок
                </div>
                <v-range-slider
                    v-model="numOfPurchases"
                    track-color="#CFDDEA"
                    thumb-color="#FFFFFF"
                    dense
                    :min="filterMinMaxValues.min_num_of_purchases"
                    :max="filterMinMaxValues.max_num_of_purchases"
                    hide-details
                    @change="getFilteredClients('num_of_purchases', $event)"
                />
                <div class="side-filter__range-val-wrapper">
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(numOfPurchases[0])"
                            label="От"
                            clearable
                            without-preloader
                            @click:clear="$set(numOfPurchases, 0, toInt($event)), getFilteredClients('num_of_purchases', numOfPurchases)"
                            @change="$set(numOfPurchases, 0, toInt($event)), getFilteredClients('num_of_purchases', numOfPurchases)"
                        />
                    </div>
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(numOfPurchases[1])"
                            label="До"
                            clearable
                            without-preloader
                            @click:clear="$set(numOfPurchases, 1, toInt($event)), getFilteredClients('num_of_purchases', numOfPurchases)"
                            @change="$set(numOfPurchases, 1, toInt($event)), getFilteredClients('num_of_purchases', numOfPurchases)"
                        />
                    </div>
                </div>
            </div>
            <ContentPreloader v-show="preloader" height="178" />
        </Col>
        <Col :cols="width > 1024 ? 3 : 12">
            <div v-show="!preloader" class="side-filter__content-group side-filter__content-group--block">
                <div class="side-filter__content-group-title">
                    Сумма покупок
                </div>
                <v-range-slider
                    v-model="summOfPurchases"
                    track-color="#CFDDEA"
                    thumb-color="#FFFFFF"
                    dense
                    :min="filterMinMaxValues.min_summ_of_purchases"
                    :max="filterMinMaxValues.max_summ_of_purchases"
                    hide-details
                    @change="getFilteredClients('summ_amount_of_purchases', $event)"
                />
                <div class="side-filter__range-val-wrapper">
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(summOfPurchases[0])"
                            label="От"
                            clearable
                            without-preloader
                            @click:clear="$set(summOfPurchases, 0, toInt($event)), getFilteredClients('summ_of_purchases', summOfPurchases)"
                            @change="$set(summOfPurchases, 0, toInt($event)), getFilteredClients('summ_of_purchases', summOfPurchases)"
                        />
                    </div>
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(summOfPurchases[1])"
                            label="До"
                            clearable
                            without-preloader
                            @click:clear="$set(summOfPurchases, 1, toInt($event)), getFilteredClients('summ_of_purchases', summOfPurchases)"
                            @change="$set(summOfPurchases, 1, toInt($event)), getFilteredClients('summ_of_purchases', summOfPurchases)"
                        />
                    </div>
                </div>
            </div>
            <ContentPreloader v-show="preloader" height="178" />
        </Col>
        <Col :cols="width > 1024 ? 3 : 12">
            <div v-show="!preloader" class="side-filter__content-group side-filter__content-group--block">
                <div class="side-filter__content-group-title">
                    Ср. чек
                </div>
                <v-range-slider
                    v-model="avgeCheck"
                    track-color="#CFDDEA"
                    thumb-color="#FFFFFF"
                    dense
                    :min="filterMinMaxValues.min_avge_check"
                    :max="filterMinMaxValues.max_avge_check"
                    hide-details
                    @change="getFilteredClients('avge_check', $event)"
                />
                <div class="side-filter__range-val-wrapper">
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(avgeCheck[0])"
                            label="От"
                            clearable
                            without-preloader
                            @click:clear="$set(avgeCheck, 0, toInt($event)), getFilteredClients('avge_check', avgeCheck)"
                            @change="$set(avgeCheck, 0, toInt($event)), getFilteredClients('avge_check', avgeCheck)"
                        />
                    </div>
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(avgeCheck[1])"
                            label="До"
                            clearable
                            without-preloader
                            @click:clear="$set(avgeCheck, 1, toInt($event)), getFilteredClients('avge_check', avgeCheck)"
                            @change="$set(avgeCheck, 1, toInt($event)), getFilteredClients('avge_check', avgeCheck)"
                        />
                    </div>
                </div>
            </div>
            <ContentPreloader v-show="preloader" height="178" />
        </Col>
        <Col :cols="width > 1024 ? 3 : 12">
            <div v-show="!preloader" class="side-filter__content-group side-filter__content-group--block">
                <div class="side-filter__content-group-title">
                    Бонусы
                </div>
                <v-range-slider
                    v-model="numberOfBonus"
                    track-color="#CFDDEA"
                    thumb-color="#FFFFFF"
                    dense
                    :min="filterMinMaxValues.min_bonuses"
                    :max="filterMinMaxValues.max_bonuses"
                    hide-details
                    @change="getFilteredClients('number_of_bonus', $event)"
                />
                <div class="side-filter__range-val-wrapper">
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(numberOfBonus[0])"
                            label="От"
                            clearable
                            without-preloader
                            @click:clear="$set(numberOfBonus, 0, toInt($event)), getFilteredClients('number_of_bonus', numberOfBonus)"
                            @change="$set(numberOfBonus, 0, toInt($event)), getFilteredClients('number_of_bonus', numberOfBonus)"
                        />
                    </div>
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(numberOfBonus[1])"
                            label="До"
                            clearable
                            without-preloader
                            @click:clear="$set(numberOfBonus, 1, toInt($event)), getFilteredClients('number_of_bonus', numberOfBonus)"
                            @change="$set(numberOfBonus, 1, toInt($event)), getFilteredClients('number_of_bonus', numberOfBonus)"
                        />
                    </div>
                </div>
            </div>
            <ContentPreloader v-show="preloader" height="178" />
        </Col>
    </Row>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from '@/utils/functions';
import { numberFormat } from '@/utils/format';
import moment from "moment";

export default {
    data() {
        return {
            selectValues: {
                sex: null,
            },
            sexOptions: [
                { name: 'Все', value: null },
                { name: 'Мужской', value: 'man' },
                { name: 'Женский', value: 'woman' },
                { name: 'Не определён', value: 'True' },
            ],
            numOfPurchases: [],
            summOfPurchases: [],
            avgeCheck: [],
            numberOfBonus: [],
            datepicker: [],
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            filters: 'clients/filters',
            filterMinMaxValues: 'clients/filterMinMaxValues',
            preloader: 'ui/preloader',
        }),
        datepickerFormatted() {
            if (this.datepicker.length > 1) {
                return this.datepicker.map(i => this.moment(i).format('DD.MM.YYYY')).join(' - ')
            }
            return ''
        }
    },
    mounted() {
        if (this.filters.last_order_date__in) {
            let dates = this.filters.last_order_date__in.split(',');
            this.datepicker = [
                dates[0].split('.').reverse().join('-'),
                dates[1].split('.').reverse().join('-')
            ]
        }

        this.selectValues.sex = this.filters.sex__isnull ? this.filters.sex__isnull : this.filters.sex

        let interval = setInterval(() => {
            if (Object.keys(this.filterMinMaxValues).length) {
                this.setFilterValues();
                clearInterval(interval);
            }
        }, 200);
    },
    methods: {
        setFilterValues() {
            this.numOfPurchases = [this.filterMinMaxValues.min_num_of_purchases, this.filterMinMaxValues.max_num_of_purchases];
            this.summOfPurchases = [this.filterMinMaxValues.min_summ_of_purchases, this.filterMinMaxValues.max_summ_of_purchases];
            this.avgeCheck = [this.filterMinMaxValues.min_avge_check, this.filterMinMaxValues.max_avge_check];
            this.numberOfBonus = [this.filterMinMaxValues.min_bonuses, this.filterMinMaxValues.max_bonuses];
        },
        async filterClients(field, value) {
            this.$emit('loading', true);
            if (field === 'sex') {
                this.selectValues.sex = value;
                this.$store.commit('clients/SET_FILTER', { field: 'sex', value: '' });
                this.$store.commit('clients/SET_FILTER', { field: 'sex__isnull', value: '' });
                if (value === 'True') {
                    field = 'sex__isnull'
                }
            }

            if (field !== 'last_order_date__in' && Array.isArray(value)) {
                this.$store.commit('clients/SET_FILTER', { field: `${field}__gte`, value: value[0] });
                this.$store.commit('clients/SET_FILTER', { field: `${field}__lte`, value: value[1] });
                await this.$store.dispatch('clients/GET_USERS');
                this.$emit('loading', false);
                return;
            }

            if (field === 'last_order_date__in') {
                if (value[0] && value[1]) {
                    value = `${value[0]},${value[1]}`;
                } else {
                    value = null;
                }
            }
            await this.$store.dispatch('clients/GET_USERS', { field, value });
            this.$emit('loading', false);
        },
        getFilteredClients: debounce(function (field, value) {
            this.filterClients(field, value);
        }, 500),
        numberFormat(num) {
            if (num) return numberFormat(num);
            return 0;
        },
        toInt(str) {
            str = str ? parseInt(str.replace(/\s/g, ''), 10) : 0;
            if (str && !isNaN(str)) {
                return str;
            }
            return 0
        },
        moment
    }
}
</script>

<template>
    <div class="my-col" :class="`my-col-${cols}`">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Col',
    props: {
        cols: { type: [String, Number], default: 12 }
    }
}
</script>
<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.changesID ? change.name : 'Отменить'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only"
                @click:left="$router.push({ name: 'salarys-changes' })"
                @click:save="saveItem"
            />
            <div class="page-container">
                <div class="page__info-group">
                    <Row>
                        <Col :cols="width > 1024 ? 6 : 12">
                            <v-menu
                                ref="menu1"
                                offset-y
                                attach
                                :close-on-content-click="false"
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <InputCustom
                                        :value="formatedDate"
                                        label="Дата"
                                        readonly
                                        :disabled="change.taken_into_account === 2"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="change.date_added"
                                    no-title
                                    first-day-of-week="1"
                                    :disabled="change.taken_into_account === 2"
                                    @input="$refs.menu1.save(change.date_added)"
                                />
                            </v-menu>
                        </Col>
                        <Col :cols="width > 1024 ? 6 : 12">
                            <InputSelect
                                v-model="change.salary_choice"
                                :options="options"
                                label="Тип"
                                :disabled="change.taken_into_account === 2"
                            />
                        </Col>
                        <Col>
                            <InputCustom
                                v-model="change.name"
                                label="Название"
                                :disabled="change.taken_into_account === 2"
                                :error="error && error.name ? error.name : null"
                            />
                        </Col>
                        <Col>
                            <TextareaCustom
                                v-model="change.description"
                                label="Описание"
                                :disabled="change.taken_into_account === 2"
                                :error="error && error.description ? error.description : null"
                            />
                        </Col>
                        <Col>
                            <InputCustom
                                :value="change.amount"
                                label="Сумма"
                                type="number"
                                :disabled="change.taken_into_account === 2"
                                :error="(error && (error.amount || error.salary_choice)) ? (error.amount || error.salary_choice) : null"
                                @input="change.amount = $event"
                            />
                        </Col>
                    </Row>
                </div>
                <div class="page__info-group">
                    <div v-show="!preloader" class="listing-table__wrap">
                        <div v-if="users_list && users_list.length" class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 33%">Имя</div>
                                <div class="listing-table__col" style="width: 33%">Роль</div>
                                <div class="listing-table__col" style="width: 33%" />
                            </div>
                            <div v-for="(user, index) in users_list" :key="user.id" class="listing-table__row">
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                    {{ user.last_name }} {{ user.first_name }}
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-bordered"
                                    :style="width > 1024 ? 'width: 33%' : ''"
                                >
                                    <div class="listing-table__col-label">Роль</div>
                                    {{ printRole(index) }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                    <StatusSelect
                                        v-if="$route.params.changesID"
                                        :value="change.read_users.find(a => a.id === user.id).usertosalarychange__issued_to_user"
                                        :options="[
                                            { id: true, name: 'Учтено', color: '#0BD58A', icon: 'check' },
                                            { id: false, name: 'Не учтено', color: '#F00C29', icon: 'close' }
                                        ]"
                                        disabled
                                    />
                                    <v-btn v-else class="ml-a" icon @click.prevent="$store.commit('notifications/REMOVE_USER_FROM_USERS_LIST', user)">
                                        <Icon color="#0D63F3">
                                            trash
                                        </Icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContentPreloader v-show="preloader && $route.params.changesID" type="table" />
                    <div v-if="!$route.params.changesID" class="d-flex justify-center mt-4">
                        <Button icon-right="edit-2" bordered @click.prevent="isUsersModalOpen = true">
                            Изменить
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        <UniversalModal v-model="isUsersModalOpen" size="full">
            <UsersModal @closeModal="isUsersModalOpen = false" />
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from "moment";

import { date } from '@/utils/format';

import {
    createSalarysChanges, editSalarysChanges, getChangesData, getUserData
} from '@/api/django';

import UsersModal from '@/components/app//users/modal/UsersModal';

export default {
    name: 'TheSalarysChangesCreate',
    metaInfo() {
        return { title: this.$route.params.changesID ? `Премия / Штраф ${this.change.name}` : 'Новая Премия / Штраф' }
    },
    components: { UsersModal },
    data() {
        return {
            change: {
                name: '',
                description: '',
                amount: '',
                salary_choice: 'bonus',
                read_users: [],
                date_added: moment().format("YYYY-MM-DD")
            },
            options: [
                { name: 'Премия', id: 'bonus' },
                { name: 'Штраф', id: 'penalty' }
            ],
            error: null,
            isUsersModalOpen: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            users_list: 'notifications/users_list',
            preloader: 'ui/preloader',
            groups: 'users/groups',
            authUser: 'auth/user',
        }),
        formatedDate() {
            return moment(this.change.date_added).format('DD.MM.YYYY');
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            this.$store.commit('users/CLEAR_FILTERS_STATE');
            this.$store.commit('users/SET_FILTER', { field: 'is_staff', value: 'True' });
            if (this.$route.params.changesID) {
                const response = await getChangesData(this.$route.params.changesID)

                this.change = response;
                this.change.amount = +response.amount;

                response.read_users.forEach(async (u) => {
                    const res = await getUserData(u.id)
                    this.$store.commit('notifications/ADD_USER_TO_USERS_LIST', res);
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    destroyed() {
        this.$store.commit('users/CLEAR_FILTERS_STATE');
        this.$store.commit('notifications/CLEAR_USERS_LIST');
    },
    methods: {
        async saveItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                let data = { ...this.change };
                data.amount = +data.amount;
                data.users = [];
                this.users_list.forEach((a) => {
                    data.users.push({
                        user: a.id,
                        count: 1,
                    });
                });

                if (!this.$route.params.changesID) {
                    await createSalarysChanges(data)
                } else {
                    data.date_added = date(new Date(data.date_added));
                    await editSalarysChanges(this.$route.params.changesID, data)
                }

                this.$router.push({ name: 'salarys-changes' });
            } catch (error) {
                console.log(error);
                if (error.data) {
                    this.error = error.data;
                    if (error.data.users) {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: `Пользователи: ${error.data.users[0]}`, type: 'error' })
                    }
                }
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        name(index) {
            return `${this.users_list[index].last_name} ${this.users_list[index].first_name}`;
        },
        printRole(index) {
            const group = this.users_list[index].groups[0]
            return this.groups.find((g) => g.slug === group).name
        },
    }
}
</script>

<template>
    <v-app>
        <the-layout v-touch="{ left: () => closeMenu(), right: () => openMenu() }">
            <router-view :key="remountPageKey" /> <!-- Через key можно перезагрузить страницу без перезагрузки всей IS -->
            <!-- <transition name="fade">
                <div v-show="preloader && !indexPage" class="table_preloader full">
                    <Loader />
                </div>
            </transition> -->
        </the-layout>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import TheLayout from '@/layout/TheLayout';

export default {
    components: {
        TheLayout,
    },
    metaInfo() {
        return {
            title: 'IS',
            titleTemplate: '%s - IS',
            meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no' },
                { name: 'robots', content: 'noindex' },
                { name: 'googlebot', content: 'noindex' }
            ],
            link: [
                { rel: 'icon', type: 'image/icon', href: this.faviconList[this.activeFaviconIndex] },
                { rel: "apple-touch-icon", type: "image/png", href: "/touch-icon.png" },
                { rel: "touch-icon", type: "image/png", href: "/touch-icon.png" },
            ],
        }
    },
    data() {
        return {
            disableOpenMenuBySwipe: false,
            faviconIntervalID: null,
            activeFaviconIndex: 0,
            faviconList: ['/favicon.png', '/favicon-notice.png']
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            logged: 'auth/logged',
            preloader: 'ui/preloader',
            isOpenSidebar: 'ui/isOpenSidebar',
            remountPageKey: 'ui/remountPageKey',
            unreadChatMessages: 'chat/unread',
            openedModalsCount: 'ui/openedModalsCount'
        }),
        indexPage() {
            return this.$route.path === '/' || this.$route.path === '/registration';
        }
    },
    watch: {
        $route() {
            this.$store.commit('ui/CLOSE_ALL_MODALS');
            // обнуляем счетчик открытых модалок, чтобы не блочить скролл при смене страницы.
            this.$store.commit('ui/set_opened_modals_count', 0);
            this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
        },
        unreadChatMessages(value, prevValue) {
            if (!prevValue && value) {
                this.faviconIntervalID = setInterval(() => {
                    this.activeFaviconIndex = this.activeFaviconIndex ? 0 : 1;
                }, 1000);
            } else if (prevValue && !value) {
                clearInterval(this.faviconIntervalID);
                this.activeFaviconIndex = 0;
            }
        }
    },
    beforeCreate() {
        if (localStorage.getItem('token')) {
            this.$store.dispatch('franchises/GET_FRANCHISE_TARIFF');
            this.$store.dispatch('stock/GET_STOCK_INDICATIONS');
            setInterval(() => {
                this.$store.dispatch('franchises/GET_FRANCHISE_TARIFF');
                this.$store.dispatch('stock/GET_STOCK_INDICATIONS');
            }, 300000);
        }
        if (localStorage.getItem('sort')) {
            const { orders } = JSON.parse(localStorage.getItem('sort'));
            const { clients } = JSON.parse(localStorage.getItem('sort'));
            const { franchises } = JSON.parse(localStorage.getItem('sort'));
            if (!orders || !clients || !franchises) {
                const sortObj = {
                    orders: { type: 'visible_id', direction: '-' },
                    clients: { type: 'last_order', direction: '-' },
                    franchises: { type: 'id', direction: '-' },
                };
                localStorage.setItem('sort', JSON.stringify(sortObj));
            } else {
                this.$store.commit('orders/CHANGE_SORT', { type: orders.type, direction: orders.direction });
                this.$store.commit('clients/CHANGE_SORT', { type: clients.type, direction: clients.direction });
                this.$store.commit('franchises/CHANGE_SORT', { type: franchises.type, direction: franchises.direction });
            }
        } else {
            const sortObj = {
                orders: { type: 'visible_id', direction: '-' },
                clients: { type: 'last_order', direction: '-' },
                franchises: { type: 'id', direction: '-' },
            };
            localStorage.setItem('sort', JSON.stringify(sortObj));
        }
    },
    mounted() {
        window.addEventListener('resize', this.checkWindowWidth);
        window.addEventListener("orientationchange", this.checkWindowWidth);
        window.addEventListener("touchstart", this.checkSwipe);
        this.checkWindowWidth();
        if (this.logged) {
            this.$store.dispatch('users/GET_USERS_GROUPS');
            this.$store.dispatch('auth/LOAD_PROFILE_DATA');
            this.$store.dispatch('data/LOAD_CATEGORY');
            this.$store.dispatch("orders/GET_ORDER_STATUSES");
            this.$store.dispatch('shops/GET_SHOPS_TYPES');
            this.$store.commit('ui/SET_ORIGIN', JSON.parse(localStorage.getItem('user')).franchise_domain);
        }
    },
    updated() {
        this.$store.commit('ui/SET_PAGE', this.$route.name);
    },
    destroyed() {
        this.$store.commit('ui/DESTROYED');
        window.removeEventListener('resize', this.checkWindowWidth);
        window.removeEventListener("touchstart", this.checkSwipe);
    },
    methods: {
        checkWindowWidth() {
            this.$store.commit('ui/SET_WIDTH', window.innerWidth);
        },
        checkSwipe(e) {
            if (e.target.closest('.fast-filters__wrapper') || e.target.closest('.tabs__list') ||
                e.target.closest('.chips__wrap') || e.target.closest('.leaflet-container')) {
                this.disableOpenMenuBySwipe = true;
            } else {
                this.disableOpenMenuBySwipe = false;
            }
        },
        openMenu() {
            if (!this.isOpenSidebar && !this.disableOpenMenuBySwipe && !this.openedModalsCount) {
                this.$store.commit('ui/TOGGLE_SIDEBAR');
                this.$store.dispatch('ui/toggle_opened_modals_count', 'open');
            }
        },
        closeMenu() {
            if (this.isOpenSidebar) {
                this.$store.commit('ui/TOGGLE_SIDEBAR');
                this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
            }
        }
    }
};
</script>

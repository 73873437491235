<template>
    <div class="universal-modal__header-wrap">
        <div class="universal-modal__header">
            <button
                v-if="leftBtn"
                type="button"
                class="universal-modal__header-left-btn"
                @click.prevent="$emit('click:left')"
            >
                <Icon>{{ leftBtn }}</Icon>
            </button>
            <div v-if="title" class="universal-modal__header-title-wrap">
                <div class="universal-modal__header-title">{{ title }}</div>
                <slot />
            </div>
            <div class="universal-modal__header-right-btn-wrap">
                <slot name="rightButtons" />
                <button
                    v-if="rightBtn"
                    type="button"
                    class="universal-modal__header-right-btn"
                    @click.prevent="$emit('click:right')"
                >
                    <Icon>{{ rightBtn }}</Icon>
                </button>
            </div>
        </div>
        <div v-if="tabs.length" class="universal-modal__header-tabs">
            <div
                v-for="(tab, index) in tabs"
                :key="tab.name"
                class="universal-modal__header-tab-item"
                :class="{'active': tab.active}"
                @click="$emit('toggleTab', index)"
            >
                {{ tab.name }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'UniversalModalHeader',
    props: {
        leftBtn: { type: String, default: '' },
        rightBtn: { type: String, default: 'close' },
        title: { type: String, default: '' },
        tabs: { type: Array, default: () => [] }
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
    },
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Интеграции"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <p>В этом разделе расположены настройки интеграций со сторонними сервисами.</p>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список интеграций</h2>
                                <p>В списке выводится только название стороннего сервиса.</p>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">VK</h2>
                                <p>Раздел интеграции с VK позволяет настраивать синхронизацию товаров из административной панели с группой VK.</p>

                                <p>Нужно указать ID группы ВКонтакте. Ниже отображается количество синхронизированных товаров.</p>
                                <img src="@/assets/img/help/integrations/1.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>В таблице отображаются текущие синхронизированные альбомы. Именно альбомы. Это не категории товаров.</p>
                                <img src="@/assets/img/help/integrations/2.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>При нажатии на карандаш откроется модальное окно редактирования фото альбома.</p>

                                <p>При нажатии на "Изменить" откроется модальное окно со всеми возможными альбомами. Там можно выделить альбомы, которые требуется синхронизировать.</p>
                                <img src="@/assets/img/help/integrations/3.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <div class="page__info-group">
                                    <h2 class="page__info-group-title">Facebook</h2>
                                    <p>В разделе расположена инструкция по подключению автоматической синхронизации товаров с facebook. Какой-либо настройки не предусмотрено.</p>
                                </div>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{ name: 'Промокоды', link: '/help/promocodes' }, { name: 'Настройки', link: '/help/settings' }]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpIntegrations',
    metaInfo: {
        title: 'Интеграции - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

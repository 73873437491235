<template>
    <div class="chart__bar" :class="modClass">
        <div v-if="label" class="chart__title">
            {{ label }}
            <slot name="trigger" />
        </div>
        <transition-group name="fade">
            <div v-for="item in chartData" :key="item.name" class="chart__bar-item">
                <div class="bar-item-info">
                    <div class="bar-item-info-wrapper">
                        <span class="value-name">
                            <v-icon v-if="item.icon" color="#8AACCD" size="20">
                                {{ item.icon }}
                            </v-icon>
                            {{ item.name }}
                        </span>
                        <span class="bar-value-span">
                            {{ !inPercent ? formatNumber(item.revenue) : calcItemPercent(item.revenue) }} {{ unitsType }}
                        </span>
                    </div>
                    <div class="bar">
                        <div class="bar-value" :style="`width: ${(item.revenue / sumRevenue) * 100}%`" />
                    </div>
                </div>
            </div>
        </transition-group>
        <slot name="selection" />
    </div>
</template>

<script>
import { numberFormat } from '@/utils/format';

export default {
    name: 'HorizontalBarChart',
    props: {
        chartData: {
            type: Array,
            default: () => [],
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        modClass: {
            type: String,
            default: '',
        },
        unitsType: {
            type: String,
            default: '',
        },
        inPercent: { type: Boolean, default: false }
    },
    computed: {
        sumRevenue() {
            return this.chartData.reduce((sum, item) => sum + item.revenue, 0);
        }
    },
    methods: {
        calcItemPercent(value) {
            return Math.floor(((value / this.sumRevenue) * 100) + 0.5);
        },
        formatNumber(number) {
            if (number) {
                return numberFormat(number);
            }
            return 0;
        }
    }
}
</script>

<style>
    .chart__bar {
        width: 100%;
    }
    .chart__title {
        display: inline-block;
        font-size: 18px;
        font-weight: 500;
        color: #5a646e;
        margin-bottom: 24px;
    }
    .chart__bar>span{
        display: block;
        width: 100%;
        margin-bottom: 32px;
    }
    .chart__bar-item {
        display: flex;
        margin: 16px 0;
    }
    .bar-item-info {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .bar-item-info-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 4px
    }
    .bar-item-info-wrapper .bar-value-span {
        margin-left: auto;
    }
    .value-name {
        font-size: 15px;
        line-height: 20px;
        color: #4A7BAC;
    }
    .bar-value-span {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 30%;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #0F1922;
        white-space: nowrap;
        transition: .3s;
    }
    .bar {
        width: 100%;
        height: 8px;
        background: #F1F5F9;
        border-radius: 8px
    }
    .bar-value {
        height: 8px;
        background: #1a73e8;
        width: 0;
        transition: .4s;
        border-radius: 8px
    }
</style>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Сотрудники"
                for-listing
                :show-save-btn="false"
                :search="width <= 699"
                @click:search="isVisibleSearch = !isVisibleSearch"
            >
                <Button
                    :class="{'mr-2': width <= 699}"
                    text
                    compact
                    :icon="width <= 699"
                    icon-right="file-minus"
                    @click.prevent="isShowDownloadModal = true"
                >
                    Скачать в xls
                </Button>
            </Header>
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <Search
                    :value="filters.search"
                    class="mb-0"
                    :is-visible-mobile="isVisibleSearch"
                    @input="$store.commit('users/SET_FILTER', { field: 'search', value: $event}), getFilteredUsers()"
                />
                <FastFilters
                    :filters="fastFilters"
                    :class="{'mb-0': users && users.length && Object.keys(picked).length}"
                    @change="toogleFastFilter"
                />
                <ActionBar
                    v-show="users && users.length && Object.keys(picked).length"
                    class="action-bar--goods"
                    :with-notification="!allPicked || Object.keys(picked).length === users.length"
                    :picked="picked"
                    :all-picked="allPicked"
                    :picked-count="checkedCountComputed"
                    @checkbox="checkboxAllHandler"
                    @notification="sendNotification"
                />
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="users && users.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 80px; flex: 0 0 auto;" />
                            <div class="listing-table__col pl-0" style="width: calc((100% - 80px) / 4)">Имя</div>
                            <div class="listing-table__col" style="width: calc((100% - 80px) / 4)">Роль</div>
                            <div class="listing-table__col" style="width: calc((100% - 80px) / 4)">Посл.авториз</div>
                            <div class="listing-table__col" style="width: calc((100% - 80px) / 4)">Онлайн</div>
                        </div>
                        <router-link
                            v-for="(item, index) in users"
                            :key="item.id"
                            :to="`/users/${item.id}`"
                            class="listing-table__row"
                            :class="{ active: picked.includes(item) }"
                        >
                            <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                <div class="listing-table__image listing-table__image--mini" @click.prevent.stop="checkboxHandler(item)">
                                    <img :src="item.avatar ? item.avatar : require('@/assets/img/icon/avatar2.svg')" alt="">
                                </div>
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-inline"
                                :class="{'pt-0 mr-0': width <= 1024}"
                                :style="width > 1024 ? 'width: calc((100% - 80px) / 4)' : ''"
                            >
                                {{ item.last_name }} {{ item.first_name }}
                            </div>

                            <div v-if="width <= 1024" class=" listing-table__col listing-table__col--mobile-bordered" />

                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 80px) / 4)' : ''">
                                <div class="listing-table__col-label">Роль</div>
                                {{ printRole(index) }}
                            </div>
                            <div
                                class="listing-table__col"
                                :style="width > 1024 ? 'width: calc((100% - 80px) / 4)' : ''"
                            >
                                <div class="listing-table__col-label">Посл. авторизация</div>
                                {{ item.last_login ? date(item.last_login, 'hours-ru') : '&mdash;' }}
                            </div>
                            <div
                                class="listing-table__col"
                                :style="width > 1024 ? 'width: calc((100% - 80px) / 4)' : ''"
                            >
                                <span v-if="busyList.includes(item.id)" style="color:#36B687">Онлайн</span>
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="users && !users.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
        <MiniModal
            v-model="isShowDownloadModal"
            title="Вы уверены, что хотите скачать файл?"
            additional-btn-text="Отмена"
            main-btn-text="Скачать"
            @additionalBtnClick="isShowDownloadModal = false"
            @mainBtnClick="downloadFile"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { downloadUsers } from '@/api/django';

import { debounce } from '@/utils/functions';
import { date } from '@/utils/format';
import { userBusy } from '@/api/timetracking';

import Spinner from '@/components/app/Spinner';
import FastFilters from '@/components/app/FastFilters';
import ActionBar from '@/components/app/ActionBar';
import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'TheUsers',
    metaInfo: {
        title: 'Сотрудники'
    },
    components: { Spinner, FastFilters, ActionBar, MiniModal },
    data() {
        return {
            isVisibleSearch: false,
            busyList: [],
            picked: [],
            isShowDownloadModal: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            filters: 'users/filters',
            activeFilters: 'users/activeFilters',
            authUser: 'auth/user',
            users: 'users/users',
            busy: 'users/busy',
            next: 'users/next',
            groups: 'users/groups',
            fastFilters: 'users/fastFilter',
            usersCount: 'users/usersCount'
        }),
        allPicked() {
            if (this.users && this.users.length) {
                const arr = this.picked.map((item) => item.id) || [];
                return this.users.every((a) => arr.includes(a.id));
            }
            return false;
        },
        checkedCountComputed() {
            if (this.allPicked) return this.usersCount;
            return this.picked.length;
        }
    },
    watch: {
        async users(value) {
            if (value && value.length) {
                const response = await userBusy(value.map((i) => i.id));
                Object.keys(response).forEach((key) => {
                    if (response[key].id) {
                        this.busyList.push(+key);
                    }
                })
            }
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        this.$store.commit('users/SET_FILTER', { field: 'is_staff', value: 'True' });
        this.$store.commit('users/SET_FILTER', { field: 'group', value: null });
        this.$store.commit('users/CLEAR');
        const currentActiveFiltert = this.fastFilters.find((item) => item.active);
        if (currentActiveFiltert) {
            this.$store.commit('users/SET_FILTER', { field: 'group', value: currentActiveFiltert.slug });
        }
        await this.$store.dispatch('users/GET_USERS');
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    destroyed() {
        this.$store.commit('users/CLEAR');
    },
    methods: {
        getFilteredUsers: debounce(function (field, value) {
            this.filterUsers(field, value);
        }, 1000),
        async filterUsers(field, value) {
            this.$store.commit('ui/SET_PRELOADER', true);
            await this.$store.dispatch('users/GET_USERS', { field, value });
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        loadMore() {
            this.$store.dispatch('users/GET_NEXT_PAGE');
        },
        sendNotification() {
            this.$store.commit('users/SET_USERS_FOR_NOTIFICATION', this.picked);
            this.$router.push('/notifications/create');
        },
        async toogleFastFilter(event) {
            this.$store.commit('ui/SET_PRELOADER', true);

            const currentActiveFiltert = this.fastFilters.find((item) => item.active);
            this.$store.commit('users/CLEAR_FILTERS_STATE');

            if (currentActiveFiltert && currentActiveFiltert.name === event.name) {
                this.$store.commit('users/CHANGE_FAST_FILTER');
                await this.$store.dispatch('users/GET_USERS');
            } else {
                this.$store.commit('users/CHANGE_FAST_FILTER', { name: event.name, active: true });
                await this.$store.dispatch('users/GET_USERS', { field: 'group', value: event.slug });
            }
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        async downloadFile() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.isShowDownloadModal = false;

                const filters = await this.$store.dispatch('users/prepareFilters');
                for (let key in filters) {
                    if (!this.activeFilters.includes(key)) {
                        delete filters[key]
                    }
                }

                const file = await downloadUsers(filters);
                const fileUrl = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                link.download = 'Сотрудники.xlsx';
                link.click();
            } catch (e) {
                console.log(e)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки файла', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        printRole(index) {
            const group = this.users[index].groups[0];
            if (this.groups.find((g) => g.slug === group)) return this.groups.find((g) => g.slug === group).name;
            return 'Суперадминистратор';
        },
        checkboxHandler(user) {
            if (this.picked.includes(user)) {
                this.picked = this.picked.filter((item) => item.id !== user.id);
            } else {
                this.picked.push(user);
            }
        },
        async checkboxAllHandler(bool) {
            if (bool) {
                this.picked = this.users;
            } else {
                this.picked = [];
            }
        },
        date(value, type) {
            return date(new Date(value), type);
        },
    }
};
</script>

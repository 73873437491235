<template>
    <div class="universal-modal__component">
        <UniversalModalHeader
            title="Редактирование состава товара"
            left-btn="left-big"
            right-btn="check"
            @click:left="$emit('closeModal')"
            @click:right="updateEquipSet"
        />
        <div class="universal-modal__content">
            <div v-show="!preloader" class="listing-table">
                <div class="listing-table__row listing-table__row--head">
                    <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                    <div class="listing-table__col pl-0">Наименование</div>
                    <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;text-align:center;">Количество</div>
                    <div class="listing-table__col" style="width: 125px; flex: 0 0 auto;text-align: right;">Стоимость</div>
                    <div class="listing-table__col" style="width: 70px; flex: 0 0 auto;" />
                </div>
                <div
                    v-for="(equipment, index) in changingGood.equipment_set.slice().sort((a, b) => a.sort - b.sort)"
                    :key="index"
                    class="listing-table__row"
                >
                    <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                        <div class="listing-table__image">
                            <img
                                :src="equipment.image && equipment.image.preview ? equipment.image.preview :
                                    require('@/assets/img/flower.svg')"
                                alt=""
                            >
                        </div>
                    </div>
                    <div
                        class="listing-table__col listing-table__col--mobile-inline"
                        :class="{'pt-0 mr-0': width <= 1024}"
                        :style="width > 1024 ? 'width: calc(100% - 457px)' : 'width: calc(100% - 68px)'"
                    >
                        <div class="listing-table__product-name">
                            <span>{{ equipment.name }}</span>
                        </div>
                    </div>
                    <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />
                    <div
                        class="listing-table__col"
                        :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : ''"
                    >
                        <InputCounter v-model="equipment.count" @input="updateCount(equipment, $event)" />
                    </div>
                    <div
                        class="listing-table__col listing-table__col--mobile-inline"
                        :style="width > 1024 ? 'width: 125px; flex: 0 0 auto;text-align:right;' : ''"
                    >
                        <div class="listing-table__col-label">Стоимость</div>
                        {{ +equipment.price }} ₽
                    </div>
                    <div
                        class="listing-table__col listing-table__col--mobile-inline ml-a"
                        :style="width > 1024 ? 'width: 70px; flex: 0 0 auto;' : ''"
                    >
                        <v-btn icon @click="deleteEquipFromGood(equipment)">
                            <Icon color="#2B77F5">
                                trash
                            </Icon>
                        </v-btn>
                    </div>
                </div>
            </div>
            <ContentPreloader v-show="preloader" type="table" height="96" />
            <div class="d-flex justify-center mt-4 flex-wrap" style="gap: 16px">
                <Button
                    bordered
                    :block="width <= 699"
                    icon-right="edit-2"
                    @click.prevent="isPickEquipmentModalOpen = true"
                >
                    Изменить
                </Button>
                <Button
                    v-if="changingGood.upgrade_type === 'by_variant'"
                    :bordered="upgradeType !== 'plus30'"
                    :block="width <= 699"
                    icon-left="plus"
                    @click.prevent="increaseEquipment('plus30')"
                >
                    Добавить 30%
                </Button>
                <Button
                    v-if="changingGood.upgrade_type === 'by_variant'"
                    :bordered="upgradeType !== 'plus60'"
                    :block="width <= 699"
                    icon-left="plus"
                    @click.prevent="increaseEquipment('plus60')"
                >
                    Добавить 60%
                </Button>
            </div>
        </div>
        <UniversalModal v-model="isPickEquipmentModalOpen" size="full">
            <OrderPickEquipmentSet @closeModal="isPickEquipmentModalOpen = false" />
        </UniversalModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDeliveryPrice } from '@/api/django';

import OrderPickEquipmentSet from '@/components/app/orders/modal/OrderPickEquipmentSet';

export default {
    name: 'OrderChangeEquipment',
    components: { OrderPickEquipmentSet },
    props: {
        good: {
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            upgradeType: 'regular',
            defaultSet: null,
            isPickEquipmentModalOpen: false
        }
    },
    computed: {
        ...mapGetters({
            changingGood: 'changeEquipments/changingGoods',
            width: 'ui/width',
            preloader: 'ui/preloader',
        }),
    },
    created() {
        this.defaultSet = JSON.parse(JSON.stringify(this.good));
        this.$store.commit('changeEquipments/SET_GOOD', JSON.parse(JSON.stringify(this.good)));
        this.upgradeType = this.changingGood.order_variant;
    },
    methods: {
        deleteEquipFromGood(good) {
            this.upgradeType = 'regular';
            this.$store.commit('changeEquipments/REMOVE_EQUIPMENT', good);
            this.$store.commit('changeEquipments/SET_GOOD', { ...this.changingGood, order_variant: 'increased' });
        },
        updateCount(good, event) {
            this.upgradeType = 'regular';
            good.count = +event;
            this.$store.commit('changeEquipments/SET_EQUIPMENT', good);
            this.$store.commit('changeEquipments/SET_GOOD', { ...this.changingGood, order_variant: 'increased' });
        },
        updateEquipSet() {
            if (JSON.stringify(this.defaultSet) !== JSON.stringify(this.changingGood) && this.upgradeType === 'regular') {
                this.$store.commit('changeEquipments/SET_GOOD', { ...this.changingGood, order_variant: 'increased' });
            }
            this.$emit('closeModal');
            this.$emit('update');
        },
        async increaseEquipment(type) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                if (this.upgradeType === type) {
                    this.upgradeType = 'regular';
                } else {
                    this.upgradeType = type;
                }
                const data = {
                    order_list: [{ vendor_code: this.changingGood.vendor_code, count: 1, order_variant: this.upgradeType }],
                    city_id: JSON.parse(localStorage.getItem("user")).city,
                    need_equipment_set: true,
                    pickup: true,
                    amount_user_paid: 0
                };
                const res = await getDeliveryPrice(data);
                let equipmentSet = [];
                res.products[0].equipment_set.items.forEach((equipment) => {
                    equipmentSet.push({
                        vendor_code: equipment.equipment_product.vendor_code,
                        name: equipment.equipment_product.name,
                        price: equipment.equipment_product.price,
                        count: equipment.count,
                        image: equipment.image,
                        sort: equipment.sort,
                    })
                });
                this.$store.commit('changeEquipments/SET_NEW_EQUIPMENT_LIST', equipmentSet);
                this.$store.commit('changeEquipments/SET_GOOD', {
                    ...this.changingGood,
                    order_variant: this.upgradeType,
                    price: res.total_price_with_discount
                });
            } catch (error) {
                console.log(error);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        }
    }
}
</script>

<template>
    <div class="spinner">
        <div class="lds-ring">
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Spinner'
};
</script>

<style scoped>
    .spinner {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .lds-ring {
        position: relative;
        width: 36px;
        height: 36px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 36px;
        height: 36px;
        border: 4px solid #1a73e8;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #1a73e8 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>

<template>
    <div class="side-filter__content">
        <div class="side-filter__content-group-list">
            <div class="side-filter__content-group">
                <v-menu ref="date" offset-y :close-on-content-click="false" transition="scale-transition" max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                        <InputCustom
                            :value="datepickerFormatted"
                            label="Дата"
                            placeholder="Выберите период"
                            left-icon="calendar"
                            clearable
                            readonly
                            without-preloader
                            v-on="on"
                            @click:clear="datepicker = [], setFilterDate()"
                        />
                    </template>
                    <v-date-picker
                        v-model="datepicker"
                        no-title
                        range
                        first-day-of-week="1"
                        @input="() => {
                            if (datepicker[0] && datepicker[1]) {
                                $refs.date.save(datepicker);
                                setFilterDate();
                            }
                        }"
                    />
                </v-menu>
            </div>
            <div class="side-filter__content-group">
                <InputSelect
                    :value="filters.shop"
                    :options="filteredShops"
                    label="Магазин"
                    input-text="address"
                    input-icon="type_icon"
                    icon="store"
                    without-preloader
                    @input="filterArrivals('shop', $event)"
                />
            </div>
            <div class="side-filter__content-group">
                <Button
                    block compact text
                    icon-left="file-minus"
                    @click.prevent="isShowDownloadModal = true"
                >
                    Скачать поставки в xls
                </Button>
            </div>
        </div>

        <MiniModal
            v-model="isShowDownloadModal"
            title="Вы уверены, что хотите скачать файл?"
            additional-btn-text="Отмена"
            main-btn-text="Скачать"
            @additionalBtnClick="isShowDownloadModal = false"
            @mainBtnClick="downloadFile"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import moment from 'moment'

import { downloadStock } from '@/api/stock'

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'DeliveriesFilter',
    components: { MiniModal },
    data() {
        return {
            datepicker: [],
            isShowDownloadModal: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            filters: 'arrivals/filters',
            activeFilters: 'arrivals/activeFilters',
            shops: 'shops/shops',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        datepickerFormatted() {
            if (this.datepicker.length > 1) {
                return this.datepicker.map(i => this.moment(i).format('DD.MM.YYYY')).join(' - ')
            }
            return ''
        },
        filteredShops() {
            if (this.shops.results) {
                if (this.group === 'florist') {
                    return this.shops.results.filter((shop) => shop.id === JSON.parse(localStorage.getItem('user')).shop);
                } else {
                    return [{ id: null, address: 'Все', type_icon: 'store' }, ...this.shops.results || []];
                }
            }
            return [{ id: null, address: 'Все' }];
        }
    },
    mounted() {
        if (this.filters.date_from && this.filters.date_to) {
            this.datepicker = [
                this.filters.date_from.split('.').reverse().join('-'),
                this.filters.date_to.split('.').reverse().join('-')
            ]
        }
    },
    methods: {
        async filterArrivals(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('arrivals/GET_ALL_ARRIVALS', { field, value });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async setFilterDate() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.datepicker.sort();
                let dateFrom = this.datepicker[0] ? this.moment(this.datepicker[0]).format('DD.MM.YYYY') : null
                let dateTo = this.datepicker[1] ? this.moment(this.datepicker[1]).format('DD.MM.YYYY') : null

                this.$store.commit('arrivals/SET_FILTER', { field: 'date_from', value: dateFrom })
                this.$store.commit('arrivals/SET_FILTER', { field: 'date_to', value: dateTo })
                await this.$store.dispatch('arrivals/GET_ALL_ARRIVALS');
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async downloadFile() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.isShowDownloadModal = false;

                const filters = await this.$store.dispatch('arrivals/prepareFilters');
                for (let key in filters) {
                    if (!this.activeFilters.includes(key)) {
                        delete filters[key]
                    }
                }

                const file = await downloadStock({ page: 'arrivals', ...filters});
                const fileUrl = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                link.download = 'Поставки.xlsx';
                link.click();
            } catch (e) {
                console.log(e)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки файла', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        moment
    }
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Уведомления"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <p>При открытии страницы изначально загружаются непрочитанные уведомления. Если непрочитанных уведомлений нет, то автоматом загрузятся прочитанные.</p>
                                <p>Уведомления имеют цвета по приоритетам (желтый и красный). Непрочитанные уведомления без приоритета светло-голубого цвета. При клике на уведомление оно будет прочитано и станет белым.</p>
                                <img src="@/assets/img/help/notifications/1.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <template v-if="['administrators', 'supervisor'].includes(authUserGroup)">
                                    <p>Через дополнительное меню можно прочитать все уведомления или создать новое.</p>
                                    <img src="@/assets/img/help/notifications/2.jpg" class="help__image" alt="" style="margin: 16px auto">
                                </template>
                                <template v-else>
                                    <p>Через дополнительное меню можно прочитать все уведомления.</p>
                                    <img src="@/assets/img/help/notifications/2_1.jpg" class="help__image" alt="" style="margin: 16px auto">
                                </template>

                                <p>В фильтре можно выбрать тип отображаемых уведомлений.</p>
                                <img src="@/assets/img/help/notifications/3.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Если уведомление слишком длинное, то его можно развернуть с помощью стрелки.</p>
                                <img src="@/assets/img/help/notifications/4.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <div class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Перейти в объект, связанный с уведомлением, например в заказ, можно с помощью кнопки
                                        </div>
                                        <div class="help__table-col" style="max-width: 71px">
                                            <img src="@/assets/img/help/notifications/5.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{ name: 'Меню и профиль', link: '/help/menu' }, { name: 'Заказы', link: '/help/orders' }]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpNotifications',
    metaInfo: {
        title: 'Уведомления - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Магазины" for-listing :show-save-btn="false" />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="shops.results && shops.results.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: calc((100% - 60px) / 5)">Город</div>
                            <div class="listing-table__col" style="width: calc((100% - 60px) / 5)" />
                            <div class="listing-table__col" style="width: 60px;flex: 0 0 auto;" />
                            <div class="listing-table__col" style="width: calc((100% - 60px) / 5)">Адрес</div>
                            <div class="listing-table__col" style="width: calc((100% - 60px) / 5)">Часы работы</div>
                            <div class="listing-table__col" style="width: calc((100% - 60px) / 5)">Баланс кассы</div>
                        </div>
                        <router-link
                            v-for="item in shops.results"
                            :key="item.id"
                            :to="`/shops/${item.id}`"
                            class="listing-table__row"
                            :class="{ 'not-seen': item.count_of_unseen_encasement }"
                        >
                            <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered" />
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 60px) / 5)' : ''">
                                <div class="listing-table__col-label">Город</div>
                                {{ city.russian_name }}
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-inline"
                                :style="width > 1024 ? 'width: calc((100% - 60px) / 5)' : 'order: -1'"
                            >
                                <div v-if="item.is_main || item.is_order_default" class="listing-table__badges mt-0">
                                    <div v-if="item.is_main" class="listing-table__badges-item listing-table__badges-item--blue">
                                        Главный
                                    </div>
                                    <div
                                        v-if="item.is_order_default"
                                        class="listing-table__badges-item listing-table__badges-item--yellow"
                                    >
                                        Назначение доставок
                                    </div>
                                </div>
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-inline"
                                :style="width > 1024 ? 'width: 60px' : 'order: 1;'"
                            >
                                <v-icon color="#8AACCD">
                                    {{ item.type_icon }}
                                </v-icon>
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-inline"
                                :style="width > 1024 ? 'width: calc((100% - 60px) / 5)' : 'order: -2'"
                            >
                                {{ item.address }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 60px) / 5)' : ''">
                                <div class="listing-table__col-label">Часы работы</div>
                                {{ item.work_time_start }} - {{ item.work_time_end }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 60px) / 5)' : ''">
                                <div class="listing-table__col-label">Баланс кассы</div>
                                {{ (+item.balance).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }) }}
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="shops.results && !shops.results.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { numberFormat } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheShops',
    metaInfo: {
        title: 'Магазины'
    },
    components: { Spinner },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            shops: 'shops/shops',
            city: 'geo/city',
            busy: 'shops/busy',
            next: 'shops/next',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
            shopsTypes: 'shops/shopsTypes'
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        const user = JSON.parse(window.localStorage.getItem('user'));
        if (user.city) {
            this.$store.dispatch('geo/GET_CITY_SHOPS', user.city);
        }
        await this.$store.dispatch('shops/GET_SHOPS', true);
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    methods: {
        loadMore() {
            this.$store.dispatch('shops/GET_NEXT_PAGE');
        },
        formatNumber(number) {
            if (number) return numberFormat(number);
            return number;
        },
    }
};
</script>

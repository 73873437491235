<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Настройки"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <p>В разделе "Настройки" расположены различные параметры управления бизнесом.</p>
                                <p>Есть 3 вкладки для различных параметров</p>
                                <ul>
                                    <li>Основное</li>
                                    <li>Оплата</li>
                                    <li>Уведомления</li>
                                </ul>
                            </div>
                            <div id="main" class="page__info-group">
                                <h2 class="page__info-group-title">Основное</h2>
                                <p>Активация переключателя <b>"Отключить все товары"</b> приведет к тому, что у всех товаров поле <b>"Доступен для покупки"</b> станет неактивным.</p>
                                <img src="@/assets/img/help/settings/1.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Переключатель <b>"Бонусная программа"</b> отвечает за активность бонусной программы.</p>
                                <img src="@/assets/img/help/settings/2.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <p>При нажатии на карандаш откроется модальное окно с настройкой бонусов. У последнего созданного шага обязательно нужно указать какое-то максимальное значение. Например, 99999999 рублей.</p>
                                <img src="@/assets/img/help/settings/3.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>В дополнительном меню можно добавить телефон. Каждый из телефонов отобразится на сайте.</p>
                                <img src="@/assets/img/help/settings/4.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div id="alert" class="page__info-group">
                                <h2 class="page__info-group-title">Оповещение на сайте</h2>
                                <p>Раздел <b>"Оповещение на сайте"</b> активирует оповещение в шапке сайта.</p>
                                <p>Текст уведомления лучше всего редактировать в режиме HTML-кода. Так получится избавиться от лишних непонятных пробелов и прочих проблем.</p>
                                <div class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Включить режим HTML-кода
                                        </div>
                                        <div class="help__table-col" style="max-width: 80px">
                                            <img src="@/assets/img/help/settings/7.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>
                                <img src="@/assets/img/help/settings/5.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <img src="@/assets/img/help/settings/6.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div id="salaries" class="page__info-group">
                                <h2 class="page__info-group-title">Автоматические зарплаты</h2>
                                <p>Опцию можно включить/выключить. Можно выбрать периодичность. Еженедельно, либо в определенные даты.</p>
                            </div>
                            <div id="delivery" class="page__info-group">
                                <h2 class="page__info-group-title">Настройка доставки</h2>
                                <p><b>"Доставка активна"</b> включает возможность оформления заказа на доставку с сайта.</p>
                                <p>Переключатель <b>"Круглосуточно"</b> скрывает поля <b>"Часы доставки с"</b> и <b>"Часы доставки до"</b> и устанавливает время доставки с 00:00 - 23:59.</p>
                                <img src="@/assets/img/help/settings/8.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Переключатель <b>"Бесплатная доставка"</b> активирует бесплатную доставку. Если стоимость заказа больше, чем указанное значение, то стоимость доставки автоматически станет 0 рублей.</p>
                                <img src="@/assets/img/help/settings/9.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p><b>"Ограничение даты заказа"</b> позволяет выбрать промежуток дат, когда на сайте можно создать заказ. Даты учитываются в корзине, нельзя выбрать в календаре день, который не входит в промежуток.</p>
                                <img src="@/assets/img/help/settings/10.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div id="intervals" class="page__info-group">
                                <h2 class="page__info-group-title">Настройка интервалов доставки</h2>
                                <p>Интервалы доставки используются в праздничные или другие нагруженные дни, чтобы равномерно распределить нагрузку по курьерам. Чтобы не было ситуации, что на промежуток, например, 13-14 часов оформили 200 доставок, а на 15-16 - 20 доставок.</p>
                                <p>Чтобы создать новый режим интервалов, нужно нажать на кнопку <b>"Добавить"</b> рядом с заголовком раздела.</p>
                                <p>Откроется модальное окно, в котором нужно заполнить все поля.</p>
                                <ul>
                                    <li>Дата - нужно указать дату активации интервалов.</li>
                                    <li>Причина - можно указать любое значение. Например, 8 марта.</li>
                                    <li>Интервал доставки - вместо обычных промежутков по одному часу (12:00-13:00) можно активировать промежутки по 2, 3 или 4 часа. Например, 12:00 - 16:00.</li>
                                    <li>Количество доставок за интервал - если за какой-либо интервал количество заказов на доставку будет больше указанного числа, то этот интервал пропадет из списка доступных. Например, на сайте отображаются промежутки 12:00-15:00, 15:00-18:00, 18:00-21:00. На интервал 15:00-18:00 оформили 100 доставок. В таком случае на сайте отобразятся только 12:00-15:00, 18:00-21:00.</li>
                                    <li>Выводить "Ближайшее" время - включает или отключает пункт "Ближайшее" при выборе времени доставки.</li>
                                </ul>
                                <img src="@/assets/img/help/settings/11.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div id="payment-types" class="page__info-group">
                                <h2 class="page__info-group-title">Управление типами оплаты</h2>
                                <p>В таблице указаны все активные типы оплаты. Если какие-то типы оплаты не нужны или нужно добавить новые, то при нажатии <b>"Изменить типы оплаты"</b> откроется модальное окно со всеми доступными вариантами.</p>
                                <img src="@/assets/img/help/settings/13.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <p>Для некоторых типов оплаты, например, <b>"По счету"</b> или <b>"По карте (на сайте)</b> нужно дополнительно задать параметры.</p>
                                <div class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Открыть модальное окно настройки типа оплаты
                                        </div>
                                        <div class="help__table-col" style="max-width: 71px">
                                            <img src="@/assets/img/help/settings/12.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="notifications" class="page__info-group">
                                <h2 class="page__info-group-title">Настройка уведомлений</h2>
                                <p>В столбике "Активность" нужно выбрать роли, для которых уведомление будет работать.</p>
                                <p>Можно выбрать каналы, куда отправляется оповещение.</p>
                                <img src="@/assets/img/help/settings/14.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{ name: 'Интеграции', link: '/help/integrations' }, { name: 'Подписка', link: '/help/subscription' }]" />
                    </div>
                    <div class="page-small-column page-small-column--mobile-top">
                        <HelpSidebar
                            :links="[
                                { name: 'Основное', link: 'main' },
                                { name: 'Оповещение на сайте', link: 'alert' },
                                { name: 'Автоматические зарплаты', link: 'salaries' },
                                { name: 'Настройка доставки', link: 'delivery' },
                                { name: 'Интервалы доставки', link: 'intervals' },
                                { name: 'Типы оплаты', link: 'payment-types' },
                                { name: 'Уведомления', link: 'notifications' },
                            ]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpSidebar from '@/components/app/help/HelpSidebar';
import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter';

export default {
    name: 'TheHelpSettings',
    metaInfo: {
        title: 'Настройки - Помощь'
    },
    components: {
        HelpSidebar, HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

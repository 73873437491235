<template>
    <div class="universal-modal__component">
        <UniversalModalHeader title="Состав заказа" @click:right="$emit('closeModal')" />
        <div class="universal-modal__content">
            <div class="page__info-group">
                <div
                    class="d-flex justify-center px-4 py-2 mb-4"
                    style="background: #E1ECFE;border-radius: 8px;"
                >
                    <span style="font-weight: 500;font-size: 12px;line-height: 17px;color: #0D63F3;">
                        Товаров: {{ order.order_list.length }}
                    </span>
                </div>
                <div class="order__courier-products-list">
                    <OrderProductCard
                        v-for="item in order.order_list"
                        :key="item.vendor_code"
                        :product="item"
                        :order="order"
                    />
                    <!-- <div
                        v-for="item in order.order_list"
                        :key="item.vendor_code"
                        class="order__courier-products-list-item"
                    >
                        <div class="order__courier-products-list-item-image" @click.prevent="openLink(item)">
                            <img :src="item.image && item.image.preview ? item.image.preview : require('@/assets/img/flower.svg')" alt="">
                        </div>
                        <div v-if="item.order_variant !== 'regular'" class="order__courier-products-list-item-badge">
                            {{ getProductIncreaseText(item) }}
                        </div>
                        <div class="order__courier-products-list-item-name">
                            {{ item.name }}
                        </div>
                        <div class="order__courier-products-list-item-count">
                            {{ item.count }} шт.
                        </div>
                        <button
                            v-if="item.is_wishes_allow && item.wishes_for_product"
                            type="button"
                            class="order__courier-products-list-item-wish-btn"
                            @click.prevent="openProductWishesModal(item)"
                        >
                            <Icon class="mr-1">message</Icon>
                            Пожелание
                        </button>
                    </div> -->
                </div>
            </div>
        </div>
        <MiniModal
            v-model="wishesModal.open"
            title="Пожелание к товару"
            :text="wishesModal.text"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import OrderProductCard from '@/components/app/orders/OrderProductCard'
import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'OrderCourierProductsList',
    components: { OrderProductCard, MiniModal },
    props: {
        order: {
            type: Object,
            default: () => {},
            required: true
        }
    },
    data() {
        return {
            wishesModal: {
                open: false,
                text: ''
            }
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            franchiseTariff: 'franchises/franchiseTariff',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
    },
    methods: {
        openProductWishesModal(product) {
            this.wishesModal.open = true;
            this.wishesModal.text = product.wishes_for_product;
        },
        getProductIncreaseText(product) {
            if (product.order_variant === 'increased' && product.custom_equipment_amount) {
                return `${product.custom_equipment_amount} шт.`;
            } else if (product.order_variant === 'increased') {
                return 'Изменен';
            } else if (product.order_variant === 'plus30') {
                return '+30%';
            } else if (product.order_variant === 'plus60') {
                return '+60%'
            }
        },
        openLink(good) {
            if (good.slug && good.equipment_set.length && this.authUserGroup === "courier") {
                window.open(`https://${this.franchiseTariff.site_domain}/product/${good.slug}`);
            } else {
                window.open(`/goods/${good.vendor_code}`, '_blank');
            }
        },
    }
}
</script>

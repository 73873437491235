<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Поставки"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список поставок</h2>
                                <p>В списке поставок отображаются поля:</p>
                                <ul>
                                    <li>Магазин</li>
                                    <li>Дата поставки</li>
                                    <li>Статус</li>
                                </ul>

                                <template v-if="authUserGroup !== 'managers'">
                                    <p>Статус поставки можно изменить с помощью выпадающего списка.</p>
                                    <img src="@/assets/img/help/arrivals/1.jpg" class="help__image" alt="" style="margin: 16px auto">
                                </template>

                                <p v-if="authUserGroup !== 'florist'">Есть фильтр по магазинам. Флорист может просматривать только поставки своего магазина.</p>
                                <p>Непросмотренная поставка выделяется светло-желтым цветом.</p>
                                <img src="@/assets/img/help/arrivals/2.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div v-if="authUserGroup !== 'managers'" class="page__info-group">
                                <h2 class="page__info-group-title">Создание поставки</h2>
                                <p>Имя сотрудника подставляется автоматически исходя из имени авторизованного пользователя.</p>
                                <p>При создании поставки нужно обязательно выбрать магазин и комплектующие.</p>

                                <p v-if="authUserGroup !== 'florist'">В таблице комплектующих можно выбрать количество поставляемых товаров, задать себестоимость и розничную цену. При изменении себестоимости розничная цена автоматически пересчитается как себестоимость х2. Но при необходимости розничную цену можно изменить.</p>
                                <p v-else>Флорист в таблице комплектующих может изменить розничную цену.</p>

                                <img src="@/assets/img/help/arrivals/3.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Просмотр и редактирование поставки</h2>
                                <template v-if="authUserGroup !== 'managers'">
                                    <p>Редактировать <span v-if="authUserGroup === 'supervisor'">или удалить</span> поставку можно только в статусе <b>"Ожидает подтверждения"</b>. В статусе <b>"Выполнено"</b> кнопка сохранения изменений недоступна.</p>
                                    <p>Для редактирования доступен только список товаров.</p>
                                </template>
                                <div class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            При нажатии на кнопку будет сгенерирован csv файл поставки.
                                        </div>
                                        <div class="help__table-col" style="max-width: 161px">
                                            <img src="@/assets/img/help/arrivals/4.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>
                                <p v-if="authUserGroup === 'supervisor'">Удалить поставку можно через дополнительное меню.</p>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[leftNavigationLink, rightNavigationLink]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpArrivals',
    metaInfo: {
        title: 'Поставки - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
        leftNavigationLink() {
            if (this.authUserGroup === 'florist') {
                return  { name: 'К оглавлению', link: '/help' }
            }
            return { name: 'Остатки', link: '/help/stock' }
        },
        rightNavigationLink() {
            if (this.authUserGroup === 'managers') {
                return  { name: 'Перемещения', link: '/help/transfers' }
            }
            return { name: 'Списания', link: '/help/offs' }
        }
    },
};
</script>

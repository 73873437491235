<template>
    <div class="side-filter__content">
        <div class="side-filter__content-group-list">
            <div class="side-filter__content-group">
                <InputSelect
                    :value="filters.shop"
                    :options="filteredShops"
                    label="Магазин"
                    input-text="address"
                    input-icon="type_icon"
                    icon="store"
                    without-preloader
                    @input="filterGoods('shop', $event)"
                />
            </div>
            <div class="side-filter__content-group">
                <InputSelect
                    :value="filters.category"
                    :options="categories"
                    label="Товары"
                    without-preloader
                    @input="filterGoods('category', $event)"
                />
            </div>
            <div class="side-filter__content-group">
                <Button
                    block compact text
                    icon-left="file-minus"
                    @click.prevent="isShowDownloadModal = true"
                >
                    Скачать остатки в xls
                </Button>
            </div>
        </div>

        <MiniModal
            v-model="isShowDownloadModal"
            title="Вы уверены, что хотите скачать файл?"
            additional-btn-text="Отмена"
            main-btn-text="Скачать"
            @additionalBtnClick="isShowDownloadModal = false"
            @mainBtnClick="downloadFile"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { downloadStock } from '@/api/stock'

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'StoreFilter',
    components: { MiniModal },
    data() {
        return {
            isShowDownloadModal: false
        }
    },
    computed: {
        ...mapGetters({
            shops: 'shops/shops',
            filters: 'stock/filters',
            activeFilters: 'stock/activeFilters',
            allCategories: 'data/categories',
            user: 'auth/user',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        filteredShops() {
            if (this.shops.results) {
                if (this.group === 'florist') {
                    return this.shops.results.filter((shop) => shop.id === JSON.parse(localStorage.getItem('user')).shop);
                } else {
                    return this.shops.results || [];
                }
            }
            return [];
        },
        categories() {
            const categories = this.allCategories.find((item) => item.type == 'equipment');
            return categories ? [{ id: null, name: 'Все' }, ...categories.childrens || []] : [{ id: null, name: 'Все' }];
        },
    },
    methods: {
        async filterGoods(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('stock/GET_GOODS_IN_STOCK', { field, value });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async downloadFile() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.isShowDownloadModal = false;

                const filters = await this.$store.dispatch('stock/prepareFilters');
                for (let key in filters) {
                    if (!this.activeFilters.includes(key)) {
                        delete filters[key]
                    }
                }

                const file = await downloadStock({ page: 'stocks', ...filters});
                const fileUrl = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                link.download = 'Остатки.xlsx';
                link.click();
            } catch (e) {
                console.log(e)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки файла', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
    }
}
</script>

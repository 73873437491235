let scrollPosition = 0;
let scrollWidth = 0;

setTimeout(() => {
    const div = document.createElement('div');
    
    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    
    document.body.append(div);
    scrollWidth = div.offsetWidth - div.clientWidth;
    
    div.remove();
}, 0);

export default {
    enable() {
        scrollPosition = window.pageYOffset;
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.width = '100%';
        document.body.style.height = '100%';
        document.body.style.paddingRight = `${scrollWidth}px`;
        document.documentElement.style.height = '100%';
        window.scrollTo({ top: 0 })
    },
    disable() {
        document.documentElement.style.height = '';
        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('top');
        document.body.style.removeProperty('width');
        document.body.style.removeProperty('height');
        window.scrollTo(0, scrollPosition);
        document.body.style.paddingRight = '';
    }
};

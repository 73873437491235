<template>
    <div v-show="!preloader" class="app-wrapper">
        <transition name="side-filter-slide">
            <div v-show="isOpenGoodFilter" class="app-side-filter app-side-filter--catalogs">
                <v-btn class="app-side-filter__close" icon color="#96A5B9" @click="$store.commit('ui/CLOSE_GOODFILTER')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <div class="app-side-filter__title">Категории</div>
                <template v-if="catalogCategoriesList.length">
                    <div v-for="category in catalogCategoriesList" :key="category.id" class="app-side-filter-wrap">
                        <div v-if="category.is_name || category.name" class="app-side-filter__category-wrap" style="position: relative">
                            <v-icon class="goods__button-label" color="#96A5B9">mdi-folder</v-icon>
                            <button
                                type="button"
                                class="app-side-filter__category-btn"
                                @click.prevent
                            >
                                {{ category.is_name || category.name }}
                            </button>
                        </div>
                    </div>
                </template>
            </div>
        </transition>
        <section class="app-section goods" :class="[$route.name]">
            <v-container fluid class="custom-container">
                <v-row class="page-header align-center" :class="{'mb-4': width > 1023}">
                    <v-btn
                        class="mr-2"
                        :class="{'ml-3': width < 1024, 'ml-4': width > 1023}"
                        icon
                        @click="$router.push('/catalogs')"
                    >
                        <v-icon color="#96A5B9">mdi-arrow-left</v-icon>
                    </v-btn>
                    <span class="page__name mr-2">
                        {{ $route.params.ID ? `Каталог ${catalog.name}` : 'Новый каталог' }}
                    </span>
                    <v-btn
                        v-if="width < 1024"
                        class="header-mobile__filter-btn"
                        icon
                        style="margin-left: auto"
                        @click.stop="$store.commit('ui/OPEN_GOODFILTER')"
                    >
                        <v-icon color="#96A5B9">mdi-filter-variant</v-icon>
                    </v-btn>
                    <v-btn
                        :class="{ 'ml-3' : width < 1024, 'ml-a' : width >= 1024, 'mr-3': width < 1024, 'mr-4': width > 1023 }"
                        color="primary save-btn"
                        :icon="width < 1024 ? true : false"
                        @click.prevent="$route.params.ID ? updateItem() : createItem()"
                    >
                        <v-icon v-if="width < 1024">mdi-check</v-icon>
                        <span v-else>ОК</span>
                    </v-btn>
                </v-row>
                <v-divider v-if="width > 1023" class="mt-2" />
                <div class="page-content">
                    <div class="control-line">
                        <div class="control-line__inner">
                            <v-btn
                                class="product__status"
                                :class="{ active : catalog.is_active}"
                                rounded
                                @click="catalog.is_active = !catalog.is_active"
                            >
                                Активен
                            </v-btn>
                            <v-btn
                                class="product__status"
                                :class="{ active : catalog.is_main}"
                                rounded
                                @click="catalog.is_main = !catalog.is_main"
                            >
                                Главный набор
                            </v-btn>
                        </div>
                    </div>
                    <v-row>
                        <v-col :cols="width > 1023 ? 6 : 12">
                            <v-text-field
                                v-model="catalog.name"
                                label="Название"
                                outlined
                                hide-details="auto"
                                :error-messages="errors.name ? errors.name[0] : ''"
                            />
                        </v-col>
                    </v-row>
                    <v-divider class="my-4" />
                    <!-- <pre>{{catalogCategoriesList}}</pre><br> -->
                    <!-- <pre>{{catalogProductsList}}</pre> -->
                    <table v-if="catalogProductsList.length" class="table" style="margin-top: 5px">
                        <thead v-if="width > 1023" class="table-head no-border">
                            <tr class="table-row">
                                <td class="table-col pl-0" style="width: 1px" />
                                <td class="table-col" style="padding-left: 0">
                                    <span class="text-semi">Наименование</span>
                                </td>
                                <td class="table-col center" style="min-width: 70px">
                                    <span class="text-semi">Стоимость</span>
                                </td>
                                <td class="table-col center" />
                            </tr>
                        </thead>
                        <tbody v-if="width > 1023 && catalogProductsList.length" class="table-body">
                            <tr
                                v-for="good in catalogProductsList"
                                :key="good.vendor_code"
                                class="table-row hovered"
                                style="position: relative;cursor:pointer;"
                            >
                                <td class="table-col no-border fixed-cell pl-0">
                                    <div class="table-image">
                                        <img
                                            v-if="good.images.length"
                                            class="table-image__img-with-link"
                                            :src="good.images[0].image.preview ? good.images[0].image.preview : good.images[0].image"
                                            :alt="good.name"
                                        >
                                        <img v-else class="table-image__img-with-link" src="@/assets/img/flower.svg" :alt="good.name">
                                        <a href="#" class="order-goods-table__image-link" @click.stop.prevent="linkToGood(good)">
                                            <v-icon color="primary">
                                                mdi-open-in-new
                                            </v-icon>
                                        </a>
                                    </div>
                                </td>
                                <td class="table-col pl-0">
                                    <div style="width: 100%">
                                        {{ good.name }}
                                    </div>
                                </td>
                                <td class="table-col order-create-input fixed-cell">
                                    <div class="nowrap" style="text-align: end">
                                        {{ formatNumber(+good.price) }} ₽
                                    </div>
                                </td>
                                <td class="table-col fixed-cell">
                                    <v-btn icon @click.stop="$store.dispatch('catalogs/SET_PRODUCTS', good)">
                                        <v-icon color="error">mdi-delete</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        <div v-else v-for="good in catalogProductsList" :key="good.vendor_code" class="goods-table__row">
                            <div class="goods-item">
                                <div class="goods-item__inner">
                                    <div class="d-flex width-100 pb-4">
                                        <div class="goods-item__col_pic">
                                            <div class="table-image">
                                                <img
                                                    v-if="good.images.length"
                                                    class="table-image__img-with-link"
                                                    :src="good.images[0].image.preview ? good.images[0].image.preview : good.images[0].image"
                                                    :alt="good.name"
                                                >
                                                <img v-else class="table-image__img-with-link" src="@/assets/img/flower.svg" :alt="good.name">
                                                <a href="#" @click.stop.prevent="linkToGood(good)" class="order-goods-table__image-link">
                                                    <v-icon color="primary">
                                                        mdi-open-in-new
                                                    </v-icon>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="goods-item__details">
                                            <div class="goods-item__details-row">
                                                <div class="goods-item__name-equipment">
                                                    <div class="goods-item__name">
                                                        <span>{{ good.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="goods-item__details-row goods-item__details-row_second mt-0" style="flex-wrap: wrap">
                                        <div class="goods-item__details-col goods-item__details-col_one">
                                            <div class="goods__table-item-price mr-4">
                                                <span class="text-center">{{ formatNumber(+good.price) }} ₽</span>
                                            </div>
                                        </div>
                                        <div class="goods-item__details-row goods-item__details-row_third ml-a mb-2">
                                            <v-spacer />
                                            <v-menu top :offset-y="false">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn dark icon v-bind="attrs" v-on="on">
                                                        <v-icon color="#96A5B9">mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item @click.stop="$store.dispatch('catalogs/SET_PRODUCTS', good)">
                                                        <v-list-item-title class="va-c">
                                                            <v-icon size="24" color="error" class="mr-2">
                                                                mdi-delete
                                                            </v-icon>
                                                            Удалить
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </table>
                    <div class="text-center" style="margin: 24px 0; display: flex">
                        <v-btn outlined class="ma" color="primary" style="margin: 0 auto" @click="$store.commit('ui/OPEN_MODAL', 'modal-goods')">
                            <v-icon left>mdi-pencil</v-icon>{{ !catalogProductsList.length ? 'Добавить товары' : 'Изменить' }}
                        </v-btn>
                    </div>
                </div>
            </v-container>
            <v-speed-dial
                v-if="$route.params.ID"
                v-model="fab_open"
                close-on-click
                fixed
                class="btn__floating custom-speed-dial"
            >
                <template v-slot:activator>
                    <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                        <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                    </v-btn>
                </template>
                <div class="custom-speed-dial__menu">
                    <v-list>
                        <v-list-item @click="deleteItem()">
                            <v-list-item-icon class="mr-3">
                                <v-icon color="error">mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Удалить каталог</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </div>
            </v-speed-dial>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
    getCatalogItem, postProductCatalog, patchProductCatalog, deleteProductCatalog
} from '@/api/django';

import { numberFormat } from '@/utils/format';

export default {
    name: 'TheCatalogsCreate',
    metaInfo: {
        title: 'Каталог'
    },
    data() {
        return {
            catalog: {
                is_active: true,
                is_main: false,
                name: ''
            },
            errors: {},
            fab_open: false
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            catalogProductsList: 'catalogs/catalogProductsList',
            catalogCategoriesList: 'catalogs/catalogCategoriesList',
            isOpenGoodFilter: 'ui/isOpenGoodFilter',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    async created() {
        this.$store.commit('ui/SET_PRELOADER', true);
        this.$store.commit('catalogs/SET_PRODUCTS_LIST', []);
        this.$store.commit('catalogs/SET_CATEGORIES_LIST', []);
        this.$store.commit('ui/SET_GOODS_MODAL_MODE', 'catalogs');

        if (this.$route.params.ID) {
            const data = await getCatalogItem(this.$route.params.ID);
            this.catalog = data;
            this.$store.commit('catalogs/SET_PRODUCTS_LIST', data.product_templates);
            this.$store.commit('catalogs/SET_CATEGORIES_LIST', data.categories_templates);
        }
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    mounted() {
        const interval = setInterval(() => {
            if (this.width) {
                this.checkWidth();
                window.addEventListener('resize', this.checkWidth);
                clearInterval(interval);
            }
        }, 150);
    },
    beforeDestroy() {
        this.$store.commit('ui/SET_GOODS_MODAL_MODE', '');
        this.$store.commit('catalogs/SET_CATEGORIES_LIST', []);
        window.removeEventListener('resize', this.checkWidth);
    },
    methods: {
        async createItem() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.errors = {};
                await postProductCatalog({
                    ...this.catalog,
                    product_templates: this.catalogProductsList.map((item) => item.vendor_code),
                    categories_templates: this.catalogCategoriesList.map((item) => item.id)
                });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Каталог успешно создан', type: 'success' })
                this.$router.push({ name: 'catalogs' });
            } catch (error) {
                console.log(error);
                this.errors = error.data;
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка создания каталога', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async updateItem() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.errors = {};
                await patchProductCatalog(this.$route.params.ID, {
                    ...this.catalog,
                    product_templates: this.catalogProductsList.map((item) => item.vendor_code),
                    categories_templates: this.catalogCategoriesList.map((item) => item.id)
                });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Каталог успешно изменен', type: 'success' })
                this.$router.push({ name: 'catalogs' });
            } catch (error) {
                console.log(error);
                this.errors = error.data;
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка изменения каталога', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async deleteItem() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await deleteProductCatalog(this.$route.params.ID);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Каталог успешно удален', type: 'success' })
                this.$router.push({ name: 'catalogs' });
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка удаления каталога', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        linkToGood(good) {
            let siteUrl = 'https://lavandaflor.ru';
            if (document.location.host.startsWith('dev') || document.location.host.startsWith('local')) {
                siteUrl = 'https://dev.front.lavandaflor.ru';
            } else if (document.location.host.startsWith('test')) {
                siteUrl = 'https://test.front.lavandaflor.ru';
            }
            if (good.slug && good.equipment_set.length && this.group === "courierselect") {
                window.open(`${siteUrl}/product/${good.slug}/`);
            } else {
                window.open(`/goods/${good.vendor_code}`, '_blank');
            }
        },
        formatNumber(num) {
            return numberFormat(num);
        },
        checkWidth() {
            if (this.width && this.width < 1024) {
                this.$store.commit('ui/CLOSE_GOODFILTER');
            } else {
                this.$store.commit('ui/OPEN_GOODFILTER');
            }
        }
    }
};
</script>

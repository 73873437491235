/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';

import ui from './modules/ui';
import data from './modules/data';
import paymentType from './modules/paymentType';
import auth from './modules/auth';
import good from './modules/good';
import equipments from './modules/equipments';
import franchises from './modules/franchises';
import goods from './modules/goods';
import users from './modules/users';
import discounts from './modules/discounts';
import discount from './modules/discount';
import reviews from './modules/reviews';
import promocodes from './modules/promocodes';
import order from './modules/order';
import orders from './modules/orders';
import notifications from './modules/notifications';
import arrivals from './modules/arrivals';
import stock from './modules/stock';
import writeoffs from './modules/writeoffs';
import transfers from './modules/transfers';
import geo from './modules/geo';
import shops from './modules/shops';
import autoNotification from './modules/autoNotification';
import changeEquipments from './modules/changeEquipments';
import chat from './modules/chat';
import returns from './modules/returns';
import pages from './modules/pages';
import salaryChanges from './modules/salaryChanges';
import encashments from './modules/encashments';
import clients from './modules/clients';
import inventories from './modules/inventories';
import motivations from './modules/motivations';
import catalogs from './modules/catalogs';
import tags from './modules/tags';
import wishesForProducts from "@/store/modules/wishesForProducts";
import flowerSubscriptions from "@/store/modules/flowerSubscriptions";
import certificates from './modules/certificates';
import salaries from '@/store/modules/salaries';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        ui,
        data,
        paymentType,
        auth,
        good,
        equipments,
        franchises,
        goods,
        users,
        discounts,
        discount,
        reviews,
        promocodes,
        order,
        orders,
        notifications,
        stock,
        writeoffs,
        arrivals,
        transfers,
        geo,
        shops,
        autoNotification,
        chat,
        returns,
        pages,
        changeEquipments,
        salaryChanges,
        encashments,
        clients,
        inventories,
        motivations,
        catalogs,
        tags,
        wishesForProducts,
        flowerSubscriptions,
        certificates,
        salaries
    }
});

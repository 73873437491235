/* eslint-disable */
import axios from 'axios';

import store from '../store';
import router from '../router';

import token from '../utils/token';

import { responseErrorHandler, getNewAccessToken } from './auth'

export const baseURL = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_HOST_STOCK;

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(
    async function(config) {
        store.dispatch('ui/startLoading', true);

        if (config._retry) {
            await getNewAccessToken();
        }

        if (token()) {
            config.headers['Authorization'] = token();
        }

        return config;
    },
    function(error) {
        const { status, data } = error.response;
        return Promise.reject({ status, data });
    }
);

instance.interceptors.response.use(
    function(response) {
        store.dispatch('ui/stopLoading', false);
        return response.data;
    },
    async function(error) {
        store.dispatch('ui/stopLoading', false);

        // if (error.response && error.response.status === 401) {
        //     store.commit('auth/REMOVE_DATA_AUTH');
        //     router.push('/');
        // }

        try {
            const res = await responseErrorHandler(error, instance);
            return res;
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                console.log({ status, data });
                return Promise.reject({ status, data });
            } else {
                return Promise.reject(err);
            }
        }
    }
);

/* BEGIN GOODS IN STOCK */
export function getGoodsInStock(params) {
    return instance({
        method: 'get',
        url: `/products_in_stock/`,
        params
    });
}

export function filterStock(query) {
    return instance(`/products_in_stock/?${query}`);
}

/* END GOODS IN STOCK */

/* BEGIN ARIVALS */
export function getAllArrivals(params) {
    return instance({
        method: 'get',
        url: `/arrivals/`,
        params
    });
}
export function getArrivalData(id) {
    return instance(`/arrivals/${id}/`);
}

export function createArrival(data) {
    return instance({
        method: 'post',
        url: '/arrivals/',
        data
    })
}
export function updateArrival(id, data) {
    return instance({
        method: 'patch',
        url: `/arrivals/${id}/`,
        data
    })
}
export function applyingArrival(id) {
    return instance.post(`/arrivals/${id}/confirm/`);
}
export function deleteArrival(id) {
    return instance.delete(`/arrivals/${id}/`);
}

/* END ARIVALS */


/* BEGIN WRITEOFFS API */
export function getAllWriteOffs(params) {
    return instance({
        method: 'get',
        url: `/writeoffs/`,
        params
    });
}
export function getWriteOffsData(id) {
    return instance(`/writeoffs/${id}/`);
}

export function createWriteOffs(data) {
    return instance({
        method: 'post',
        url: '/writeoffs/',
        data
    });
}

export function saveWriteOffs(data) {
    return instance({
        method: 'patch',
        url: `/writeoffs/${data.id}/`,
        data
    });
}
export function applyingWriteOffs(id) {
    return instance.post(`/writeoffs/${id}/confirm/`);
}
export function deleteWriteOffs(id) {
    return instance.delete(`/writeoffs/${id}/`);
}

export function downloadWriteOffsImages(data) {
    return instance({
        method: 'post',
        url: `/writeoffs/zip_images/`,
        responseType: 'blob',
        data
    });
}

/* END AWRITEOFFS API */

/* BEGIN TRANSFER */
export function getAllTransfers(params) {
    return instance({
        method: 'get',
        url: `/transfers/`,
        params
    });
}

export function getTransferData(id) {
    return instance(`/transfers/${id}/`);
}

export function createTransfer(data) {
    return instance({
        method: 'post',
        url: '/transfers/',
        data
    })
}
export function editTransfer(id, data) {
    return instance({
        method: 'patch',
        url: `/transfers/${id}/`,
        data
    })
}
export function confirmTransfer(id) {
    return instance({
        method: 'post',
        url: `/transfers/${id}/confirm/`
    })
}

export function sendTransfer(id) {
    return instance({
        method: 'post',
        url: `/transfers/${id}/send/`
    })
}

export function acceptedTransfer(id) {
    return instance({
        method: 'post',
        url: `/transfers/${id}/accepted/`
    })
}

export function readyTransfer(id) {
    return instance({
        method: 'post',
        url: `/transfers/${id}/ready_to_ship/`
    })
}
export function fetchImageID(method, url = '/product_images/', headers = {}, data = {}) {
    return instance({
        method,
        url,
        headers,
        data,
    });
}
export function patchImageById(method, url = '/product_images/', data) {
    return instance({
        method,
        url,
        data
    })
}
export function getTransfersForSalary(courier, startDate, endDate, is_paid) {
    return instance(`/transfers/?courier=${courier}&date_received_after=${startDate}&date_received_before=${endDate}&status=4&is_paid_for_courier=${is_paid}&limit=9999`);
}
export function markTransferAsPaid(data) {
    return instance({
        method: 'post',
        url: `/transfers/is_paid/`,
        data
    })
}
export function getStockIndications() {
    return instance(`/stock/indication/`);
}

export function getInventories(params) {
    return instance({
        method: 'get',
        url: `/inventorys/`,
        params
    });
}
export function getInventoriesID(id) {
    return instance(`/inventorys/${id}/`);
}
export function createInventory(data) {
    return instance({
        method: 'post',
        url: `/inventorys/`,
        data
    })
}
export function updateInventory(id, data) {
    return instance({
        method: 'patch',
        url: `/inventorys/${id}/`,
        data
    })
}

export function deleteInventory(id) {
    return instance.delete(`/inventorys/${id}/`);
}

export function getStatisticsStock(params) {
    return instance({
        method: 'get',
        url: `/stats/single_franchise/`,
        params
    });
}

export function downloadStock(params) {
    return instance({
        method: 'get',
        url: `/admin/stock/download/`,
        params,
        responseType: 'blob'
    });
}

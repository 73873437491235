<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Рассылки" for-listing :show-save-btn="false" />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="300" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="notifications && notifications.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 50%">Текст</div>
                            <div class="listing-table__col" style="width: 25%">Дата отправки</div>
                            <div class="listing-table__col" style="width: 25%">Статус</div>
                        </div>
                        <router-link
                            v-for="item in notifications"
                            :key="item.id"
                            :to="`/mailings/${item.id}`"
                            class="listing-table__row"
                        >
                            <div class="listing-table__col" :style="width > 1023 ? 'width: 50%' : ''">
                                {{ item.text }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1023 ? 'width: 25%' : ''">
                                {{ dateFormat(item.send_date, 'hours-ru') }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-inline" :style="width > 1023 ? 'width: 25%' : ''">
                                {{ item.is_sent ? 'Отправлено' : 'Не отправлено' }}
                            </div>
                        </router-link>
                        <div class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner v-show="busy && next" />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="notifications && !notifications.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { date } from '@/utils/format';
import { getNotifications, getNextPageNotifications } from '@/api/mailings';
import Spinner from '@/components/app/Spinner.vue';

export default {
    name: 'TheNotifications',
    metaInfo: {
        title: 'Рассылки'
    },
    components: { Spinner },
    data() {
        return {
            notifications: [],
            offset: 20,
            busy: false,
            next: false,
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            const response = await getNotifications();
            this.notifications = response.results;
            this.next = !!response.next;
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        async loadMore() {
            if (this.next) {
                this.busy = true;

                const res = await getNextPageNotifications(this.offset);

                this.notifications = [...this.notifications, ...res.results];
                this.next = !!res.next;
                this.busy = false;
                this.offset += 20;
            }
        },
        dateFormat(val, type) {
            return date(new Date(val), type);
        },
    },
};
</script>

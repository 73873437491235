<template>
    <div class="side-filter__content">
        <div class="side-filter__content-group-list">
            <div class="side-filter__content-group">
                <InputSelect
                    :value="filters.shop"
                    :options="[{ id: null, address: 'Все' }, ...shops.results || []]"
                    label="Наличие в магазине"
                    input-text="address"
                    input-icon="type_icon"
                    without-preloader
                    @input="filterProducts('shop', $event)"
                />
            </div>
            <div class="side-filter__content-group">
                <InputSelect
                    :value="filters.is_deleted"
                    :options="[{ value: 'True', name: 'Да' }, { value: 'False', name: 'Нет' }]"
                    label="Удаленные"
                    input-value="value"
                    without-preloader
                    @input="filterProducts('is_deleted', $event)"
                />
            </div>
            <div class="side-filter__content-group">
                <InputSelect
                    :value="filters.creator__id"
                    :options="[{id: null, first_name: 'Не выбран'}, ...creatorOptions]"
                    label="Создатель"
                    input-text="first_name"
                    searchable
                    without-preloader
                    @input="filterProducts('creator__id', $event)"
                />
            </div>
        </div>

        <button class="side-filter__clear-btn" @click.prevent="clearFilter">
            Очистить
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getAllUsers } from '@/api/django'

export default {
    name: 'GoodsParametersFilter',
    data() {
        return {
            creatorOptions: []
        }
    },
    computed: {
        ...mapGetters({
            filters: 'goods/filters',
            shops: 'shops/shops',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    async mounted() {
        const { results } = await getAllUsers({ is_staff: 'True', limit: 9999 });
        this.creatorOptions = results
    },
    methods: {
        async filterProducts(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('goods/GET_ALL_GOODS', { field, value });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async clearFilter() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.$store.commit('goods/SET_FILTER', { field: 'shop', value: null });
                this.$store.commit('ui/CLOSE_FILTER', 'goods-parameters-filter');
                await this.$store.dispatch('goods/GET_ALL_GOODS');
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        }
    }
}
</script>

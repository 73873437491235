<template>
    <div>
        <!-- <span
            v-if="!orderHistory.length || (orderHistory.length === 1 && orderHistory[0].reason[0].field === 'last_change')"
            class="order-history-modal-changes-text"
        >
            Изменений в заказе еще не было
        </span> -->
        <div v-show="!preloader" class="listing-table__wrap">
            <div v-if="orderHistory.length" class="listing-table">
                <div class="listing-table__row listing-table__row--head">
                    <div class="listing-table__col" style="width: 20%">Дата</div>
                    <div class="listing-table__col" style="width: 20%">Пользователь</div>
                    <div class="listing-table__col" style="width: 60%" />
                </div>
                <div v-for="(change, index) in orderHistory" :key="index" class="listing-table__row">
                    <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                        <div class="listing-table__col-label">Дата</div>
                        {{ getDate(change.date_change) }}
                    </div>
                    <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                        <div class="listing-table__col-label">Пользователь</div>
                        {{ change.by_user ? change.by_user : 'Логин не указан' }}
                    </div>
                    <div class="listing-table__col" :style="width > 1024 ? 'width: 60%' : ''">
                        <div>
                            <div v-for="(changes, i) in change.reason" :key="i">
                                <template v-if="changes.field === 'payment_method'">
                                    Тип платежа изменен c
                                    <span class="order-history-modal__highlight-text">{{ getPaymentType(changes.old_value).name }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ getPaymentType(changes.new_value).name }}</span>
                                </template>
                                <template v-else-if="changes.field === 'total_cost'">
                                    Стоимость заказа изменена c
                                    <span class="order-history-modal__highlight-text">{{ +changes.old_value }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ +changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'additional_info'">
                                    Дополнительная информация изменен c
                                    "<span class="order-history-modal__highlight-text">{{ changes.old_value }}</span>" на
                                    "<span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>"
                                </template>
                                <template v-else-if="changes.field === 'manager_comment'">
                                    Комментарий менеджера изменен c
                                    "<span class="order-history-modal__highlight-text">{{ changes.old_value }}</span>" на
                                    "<span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>"
                                </template>
                                <template v-else-if="changes.field === 'take_photo_with_receiver'">
                                    Заказчик решил
                                        <span class="order-history-modal__highlight-text" v-if="changes.old_value">не делать</span>
                                        <span class="order-history-modal__highlight-text" v-else>делать</span>
                                        фото с получателем
                                </template>
                                <template v-else-if="changes.field === 'user_is_receiver'">
                                    Заказчик решил
                                    <span class="order-history-modal__highlight-text" v-if="changes.old_value">не забирать</span>
                                    <span class="order-history-modal__highlight-text" v-else>забрать</span>
                                    заказ самому
                                </template>
                                <template v-else-if="changes.field === 'receiver_phone'">
                                    Номер получателя изменен c
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'receiver_name'">
                                    Имя получателя изменено с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'delivery_date'">
                                    Дата доставки изменена с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'delivery_time_start'">
                                    Дата начала доставки заказа изменена с
                                    <span class="order-history-modal__highlight-text">{{ getDate(changes.old_value) }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ getDate(changes.new_value) }}</span>
                                </template>
                                <template v-else-if="changes.field === 'delivery_time_end'">
                                    Дата конца доставки заказа изменена с
                                    <span class="order-history-modal__highlight-text">{{ getDate(changes.old_value) }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ getDate(changes.new_value) }}</span>
                                </template>
                                <template v-else-if="changes.field === 'postcard'">
                                    Текст открытки изменен c
                                    "<span class="order-history-modal__highlight-text">{{ changes.old_value }}</span>" на
                                    "<span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>"
                                </template>
                                <template v-else-if="changes.field === 'status' && allStatuses.statuses">
                                    Статус заказа изменен c
                                    "<span class="order-history-modal__highlight-text">{{ allStatuses.statuses[changes.old_value].name }}</span>" на
                                    "<span class="order-history-modal__highlight-text">{{ allStatuses.statuses[changes.new_value].name }}</span>"
                                </template>
                                <template v-else-if="changes.field === 'shop'">
                                    Адрес магазина изменен c
                                    "<span class="order-history-modal__highlight-text">{{ getShopAddress(changes.old_value).address }}</span>" на
                                    "<span class="order-history-modal__highlight-text">{{ getShopAddress(changes.new_value).address }}</span>"
                                </template>
                                <template v-else-if="changes.field === 'courier'">
                                    Курьер изменен с
                                    <span class="order-history-modal__highlight-text">id: {{ changes.old_value || 'Не задано' }}</span> на
                                    <span class="order-history-modal__highlight-text">id: {{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'visible_id'">
                                    Назначен номер
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'image'">
                                    Назначено превью-фото
                                </template>
                                <template v-else-if="changes.field === 'total_prime_cost'">
                                    Себестоимость изменена с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value || 'Не задано' }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'final_cost'">
                                    Итоговая стоимость изменена с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value || 'Не задано' }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'number_of_bonus'">
                                    Начисленные бонусы изменены с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value || 'Не задано' }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'is_paid'">
                                    Заказ оплатили
                                </template>
                                <template v-else-if="changes.field === 'amount_user_paid'">
                                    "Оплачено покупателем" изменено с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value || 'Не задано' }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'payment_date' && changes.new_value">
                                    Время оплаты
                                    <span class="order-history-modal__highlight-text">{{ moment(changes.new_value).format('DD.MM.YYYY HH:mm:ss') }}</span>
                                </template>
                                <template v-else-if="changes.field === 'is_written_off'">
                                    {{ changes.new_value ? 'Товары списаны со склада' : 'Товары восстановлены на склад'}}
                                </template>
                                <template v-else-if="changes.field === 'cashbox_viewed'">
                                    {{ changes.new_value ? 'Заказ просмотрен на кассе' : '"Заказ просмотрен на кассе" сброшено'}}
                                </template>
                                <template v-else-if="changes.field === 'is_seen'">
                                    {{ changes.new_value ? 'Заказ просмотрен' : '"Заказ стал не просмотрен'}}
                                </template>
                                <template v-else-if="changes.field === 'florist'">
                                    Флорист изменен с
                                    <span class="order-history-modal__highlight-text">id: {{ changes.old_value || 'Не задано' }}</span> на
                                    <span class="order-history-modal__highlight-text">id: {{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'receiver_address'">
                                    Адрес получения изменен с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value || 'Не задано' }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'delivery_cost'">
                                    Стоимость доставки изменена с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value || 'Не задано' }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'discount'">
                                    Скидка изменена с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value || 'Не задано' }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value }}</span>
                                </template>
                                <template v-else-if="changes.field === 'florist_photo'">
                                    {{ !changes.old_value ? 'Добавлено' : 'Изменено'}} фото флориста
                                </template>
                                <template v-else-if="changes.field === 'chat_id'">
                                    Создан чат для заказа
                                </template>
                                <template v-else-if="changes.field === 'bonus_is_paid_for_user'">
                                    {{ changes.new_value ? 'Бонусы начислены пользователю' : 'Бонусы списаны с пользователя' }}
                                </template>
                                <template v-else-if="changes.field === 'pickup'">
                                    Типа заказа изменен с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value ? 'Самовывоз' : 'Доставка' }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value ? 'Самовывоз' : 'Доставка' }}</span>
                                </template>
                                <template v-else-if="changes.field === 'delivery_in_city'">
                                    Доставка по городу изменена с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value ? 'Да' : 'Нет' }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value ? 'Да' : 'Нет' }}</span>
                                </template>
                                <template v-else-if="changes.field === 'photo_with_receiver'">
                                    {{ !changes.old_value ? 'Добавлено' : 'Изменено'}} фото с получателем
                                </template>
                                <template v-else-if="changes.field === 'cancel_reason'">
                                    Причина отмены заказа изменена с
                                    <span class="order-history-modal__highlight-text">{{ changes.old_value || '' }}</span> на
                                    <span class="order-history-modal__highlight-text">{{ changes.new_value || '' }}</span>
                                </template>
                                <template v-else>
                                    Изменение системной информации
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <empty-table v-show="!orderHistory.length || (orderHistory.length === 1 && orderHistory[0].reason[0].field === 'last_change')" />
        </div>
        <ContentPreloader v-show="preloader" type="table" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { getOrderHistory } from '@/api/django';

export default {
    name: 'OrderHistory',
    data() {
        return {
            orderHistory: [],
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            allStatuses: 'orders/statuses',
            paymentMethods: 'paymentType/paymentMethods',
            shops: 'data/shops',
            preloader: 'ui/preloader',
        }),
    },
    async mounted() {
        this.orderHistory = await getOrderHistory(this.$route.params.orderID);
    },
    methods: {
        getDate(date) {
            return date ? this.moment(date).format('DD MMMM YYYY HH:mm:ss') : ''
        },
        getPaymentType(id) {
            return id === null || !this.paymentMethods ? '' : this.paymentMethods.find((method) => method.id === id)
        },
        getShopAddress(id) {
            return id === null || !this.shops ? '' : this.shops.find((shop) => shop.id === id)
        },
        moment
    }
}
</script>

<style lang="scss">
    .order-history-modal__highlight-text {
        color: #1a73e8;
    }
</style>

<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial
            v-if="$route.params.promocodeID"
            v-model="fab_open"
            class="btn__floating custom-speed-dial"
            close-on-click
            fixed
        >
            <template v-slot:activator>
                <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item @click="showConfirmDeleteModal = true">
                        <v-list-item-icon class="mr-3">
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Удалить промокод</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="$route.params.promocodeID ? `Промокод #${$route.params.promocodeID}` : 'Новый промокод'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only && discount.name && discount.activity_start && discount.activity_end"
                @click:left="$router.push({ name: 'promocodes' })"
                @click:save="$route.params.promocodeID ? updatePromocode($route.params.promocodeID) : createPromocode()"
            />
            <div v-if="$route.params.promocodeID" class="tabs__list">
                <TabItem :active="activeTab === 0" @click="activeTab = 0">
                    Основное
                </TabItem>
                <TabItem :active="activeTab === 1" @click="activeTab = 1">
                    Заказы
                </TabItem>
            </div>
            <div
                class="page-container"
                :class="{
                    'page-container--with-tabs': $route.params.promocodeID,
                    'page-container--with-fab': $route.params.promocodeID
                }"
            >
                <div v-show="activeTab === 0" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <Row>
                                <Col>
                                    <InputCustom
                                        v-model="discount.name"
                                        label="Название"
                                        :disabled="!!$route.params.promocodeID"
                                        :error="error && error.name ? error.name : null"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputSelect
                                        v-model="app.selected"
                                        :options="app.types"
                                        label="Тип скидки"
                                        :disabled="!!$route.params.promocodeID"
                                        :error="error && error._schema ? error._schema : null"
                                        @input="setFieldForDiscount"
                                    />
                                </Col>
                                <Col>
                                    <InputSelect
                                        v-model="promocode.category"
                                        :options="categoryOptions"
                                        label="Категория"
                                        searchable
                                        :disabled="!!$route.params.promocodeID"
                                        @input="setCategoryForPromocode"
                                    />
                                </Col>
                                <Col>
                                    <InputCustom
                                        v-model="app.amount"
                                        label="Величина скидки"
                                        :disabled="!!$route.params.promocodeID"
                                        :error="error && error._schema ? error._schema : null"
                                    />
                                </Col>
                                <Col>
                                    <InputCustom
                                        v-model.number="promocode.uses_number"
                                        label="Количество использований"
                                        :disabled="!!$route.params.promocodeID"
                                        :error="error && error.uses_number ? error.uses_number : null"
                                    />
                                </Col>
                                <Col>
                                    <v-menu
                                        ref="dateStart"
                                        class="ml-0"
                                        offset-y
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <InputCustom
                                                :value="formattedDateStart"
                                                label="Дата начала"
                                                readonly
                                                v-on="on"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="discount.activity_start"
                                            no-title
                                            first-day-of-week="1"
                                            @input="$refs.dateStart.save(discount.activity_start)"
                                        />
                                    </v-menu>
                                </Col>
                                <Col>
                                    <v-menu
                                        ref="dateEnd"
                                        offset-y
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <InputCustom
                                                :value="formattedDateEnd"
                                                label="Дата конца"
                                                readonly
                                                v-on="on"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="discount.activity_end"
                                            no-title
                                            first-day-of-week="1"
                                            @input="$refs.dateEnd.save(discount.activity_end)"
                                        />
                                    </v-menu>
                                </Col>
                            </Row>
                        </div>
                        <div class="page-small-column page-small-column--mobile-top">
                            <div class="checkbox-group">
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="promocode.is_active"
                                        label="Активен"
                                        @change="promocode.is_active = !promocode.is_active"
                                    />
                                </div>
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="promocode.is_single_use"
                                        label="Один раз для одного пользователя"
                                        @change="promocode.is_single_use = !promocode.is_single_use"
                                    />
                                </div>
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="promocode.is_for_new"
                                        label="Для новых пользователей"
                                        @change="promocode.is_for_new = !promocode.is_for_new"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="$route.params.promocodeID" v-show="activeTab === 1" class="tabs__content">
                    <template v-if="ordersWithThisPromoParams.ordersWithThisPromo.length">
                        <div class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                <div class="listing-table__col pl-0" style="width: calc((100% - 112px) / 4);">№ заказа</div>
                                <div class="listing-table__col" style="width: calc((100% - 112px) / 4);">Дата доставки</div>
                                <div class="listing-table__col" style="width: calc((100% - 112px) / 4);">Стоимость</div>
                                <div class="listing-table__col" style="width: calc((100% - 112px) / 4);">Статус</div>
                            </div>
                            <div
                                v-for="item in ordersWithThisPromoParams.ordersWithThisPromo"
                                :key="item.visible_id"
                                class="listing-table__row"
                            >
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image listing-table__image--with-link">
                                        <img :src="item.preview_image ? item.preview_image : require('@/assets/img/flower.svg')" alt="">
                                        <a href="#" class="listing-table__image-link" @click.stop.prevent="goToLink(`/orders/${item.visible_id}`)">
                                            <v-icon color="primary">mdi-open-in-new</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1024 ? 'width: calc((100% - 112px) / 4);' : ''"
                                >
                                    №{{ item.visible_id }}
                                </div>
                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 4);' : ''">
                                    <div class="listing-table__col-label">Дата доставки</div>
                                    {{ moment(item.delivery_date).format('DD.MM.YYYY') }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 4);' : ''">
                                    <div class="listing-table__col-label">Стоимость</div>
                                    {{ numberFormat(Math.ceil(item.final_cost)) }} ₽
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 4);' : ''">
                                    <StatusSelect
                                        :value="item.status"
                                        :options="orderStatuses.statuses || []"
                                        :order="item"
                                        mode="order"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="ordersWithThisPromoParams.canLoadMore" class="d-flex justify-center mt-4">
                            <Button
                                bordered
                                icon-right="arrow-down"
                                :block="width <= 699"
                                @click.prevent="getOrdersListWithThisPromo(true)"
                            >
                                Загрузить еще
                            </Button>
                        </div>
                    </template>
                    <div v-else>С этим промокодом еще нет заказов</div>
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmDeleteModal"
            :title="`Удалить промокод ${ discount.name }?`"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteModal = false"
            @mainBtnClick="deletePromocode"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from "moment";

import {
    createDiscount,
    updateDiscount,
} from '@/api/discounts';

import {
    createPromocodeItem,
    updatePromocodeItem,
    deletePromocodeItem,
    filteredOrdersByQuery,
    getGoods
} from '@/api/django';

import { date, numberFormat } from '@/utils/format';

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'ThePromocodeCreate',
    components: {
        MiniModal
    },
    metaInfo() {
        return { title: this.$route.params.promocodeID ? `Промокод ${this.$route.params.promocodeID}` : 'Новый промокод' }
    },
    data() {
        return {
            discount: {
                name: '',
                is_for_promocode: true,
                amount_perc: 0,
                amount_rub: 0,
                conditions: [],
                max_amount_rub: 0,
                priotiry: 500,
                activity_start: moment().format("YYYY-MM-DD"),
                activity_end: moment().format("YYYY-MM-DD"),
            },
            promocode: {
                promocode: '',
                is_active: false,
                uses_number: 0,
                category: null,
                is_single_use: false,
                is_for_new: false
            },
            app: {
                selected: 0,
                amount: 0,
                count: 0,
                types: [
                    {
                        id: '1',
                        name: 'В процентах',
                        type: '%'
                    },
                    {
                        id: '2',
                        name: 'В рублях',
                        type: 'rub'
                    },
                ]
            },
            fab_open: false,
            showConfirmDeleteModal: false,
            error: {},
            activeTab: 0,
            ordersWithThisPromoParams: {
                ordersWithThisPromo: [],
                canLoadMore: false
            }
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
            orderStatuses: 'orders/statuses',
            categories: 'data/categories',
        }),
        formattedDateStart() {
            return moment(this.discount.activity_start).format("DD.MM.YYYY");
        },
        formattedDateEnd() {
            return moment(this.discount.activity_end).format("DD.MM.YYYY");
        },
        categoryOptions() {
            let sub = [];
            const filteredCat = this.categories.filter((category) => category.type === 'bouquet');
            if (filteredCat) {
                filteredCat.forEach((item) => {
                    if (item.childrens && item.childrens.length) {
                        item.childrens.forEach((child) => {
                            sub.push(child);
                            if (child.childrens && child.childrens.length) {
                                child.childrens.forEach((c) => sub.push(c));
                            }
                        });
                    } else {
                        sub.push(item);
                    }
                });
            }
            return sub;
        },
    },
    watch: {
        'app.selected'(value) {
            if (value === 1) {
                this.discount.amount_perc = this.app.amount;
                this.discount.amount_rub = 0;
            }
            if (value === 2) {
                this.discount.amount_rub = this.app.amount;
                this.discount.amount_perc = 0;
            }
        },
        'promocode.uses_number'() {
            if (this.promocode.uses_number > 9999) {
                this.promocode.uses_number = 9999
            }
        },
        'app.amount'(val) {
            if (this.app.selected == '1') {
                this.discount.amount_perc = val;
                this.discount.amount_rub = 0;
            }
            if (this.app.selected == '2') {
                this.discount.amount_rub = val;
                this.discount.amount_perc = 0;
            }
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            if (this.$route.params.promocodeID) {
                const promocode = await this.$store.dispatch('promocodes/EDIT_PROMOCODE', this.$route.params.promocodeID)
                this.promocode = promocode;

                const res = await this.$store.dispatch('discounts/EDIT_DISCOUNT', promocode.discount)

                await this.getOrdersListWithThisPromo();
                this.$store.dispatch('orders/GET_ORDER_STATUSES');

                this.discount = res;
                this.app.amount = res.amount_perc || res.amount_rub;
                this.app.selected = res.amount_perc ? '1' : '2';
                this.discount.activity_start = res.activity_start;
                this.discount.activity_end = res.activity_end;
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        async createPromocode() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.error = {};
                this.discount.activity_start = date(new Date(this.discount.activity_start), null);
                this.discount.activity_end = date(new Date(this.discount.activity_end), null);

                if (this.promocode.uses_number === 0) {
                    this.promocode.uses_number = 9999;
                }

                if (this.app.selected === '1') {
                    this.discount.amount_perc = this.app.amount;
                }
                if (this.app.selected === '2') {
                    this.discount.amount_rub = this.app.amount;
                }

                const respDiscount = await createDiscount({
                    name: this.promocode.promocode,
                    is_active: this.promocode.is_active,
                    ...this.discount,
                });
                await createPromocodeItem({
                    discount: respDiscount.id,
                    name: this.discount.name,
                    ...this.promocode,
                    promocode: this.discount.name,
                })
                this.$router.push({ name: 'promocodes' });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Промокод успешно сохранен', type: 'success' })
            } catch (error) {
                console.log(error);
                this.error = error.data;
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении произошла ошибка', type: 'error' })
            }
        },
        async updatePromocode(promocodeID) {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                if (this.promocode.uses_number === 0) {
                    this.promocode.uses_number = 9999;
                }
                await this.setTypeDiscount();
                if (this.app.selected === 1) {
                    this.discount.amount_perc = this.app.amount;
                }
                if (this.app.selected === 2) {
                    this.discount.amount_rub = this.app.amount;
                }
                this.discount.conditions = this.discount.conditions || [];

                await updatePromocodeItem(promocodeID, {
                    is_active: this.promocode.is_active,
                    is_single_use: this.promocode.is_single_use,
                    is_for_new: this.promocode.is_for_new
                });
                await updateDiscount(this.promocode.discount, {
                    ...this.discount,
                    name: this.promocode.promocode,
                    is_active: this.promocode.is_active,
                    max_amount_rub: 0,
                });
                this.$router.push({ name: 'promocodes' });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Промокод успешно сохранен', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении произошла ошибка', type: 'error' })
            }
        },
        async deletePromocode() {
            this.showConfirmDeleteModal = false;
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await deletePromocodeItem(this.$route.params.promocodeID);
                this.$router.push({ name: 'promocodes' });
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Промокод успешно удален', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка удаления промокода', type: 'error' })
            }
        },
        setTypeDiscount() {
            if (this.discount.amount_rub !== 0) {
                this.app.selected = 2;
                return this.app.types[1].id;
            }
            if (this.discount.amount_perc !== 0) {
                this.app.selected = 1;
                return this.app.types[0].id;
            }
            return 0
        },
        setFieldForDiscount(field) {
            this.app.selected = field;
        },
        async setCategoryForPromocode() {
            try {
                this.$store.commit('discount/CLEAR_GOODS_LIST');

                if (this.promocode.category) {
                    this.$store.commit('ui/SET_PRELOADER', true);

                    const filters = {
                        limit: 9999,
                        category: this.promocode.category,
                        is_deleted: 'False',
                    }
                    const { results } = await getGoods(filters);

                    this.discount.conditions = results.map(item => {
                        return {
                            field: 'vendor_code',
                            value: `${item.vendor_code}`,
                            operator: '='
                        }
                    })
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async getOrdersListWithThisPromo(loadmore = false) {
            let limit = !loadmore ? 10 : 9999999;

            const ordersList = await filteredOrdersByQuery(`limit=${limit}&promocode_id=${this.$route.params.promocodeID}`);

            this.ordersWithThisPromoParams.ordersWithThisPromo = ordersList.results;
            if (!loadmore) {
                this.ordersWithThisPromoParams.canLoadMore = ordersList.count > 10;
            } else {
                this.ordersWithThisPromoParams.canLoadMore = false;
            }
        },
        goToLink(link) {
            window.open(link, '_blank');
        },
        numberFormat,
        moment
    }
};
</script>

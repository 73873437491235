<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial
            v-if="$route.params.ID && !inventory.task_completed && group === 'supervisor' && !user.read_only"
            v-model="fab_open"
            class="btn__floating custom-speed-dial"
            close-on-click
            fixed
        >
            <template v-slot:activator>
                <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item @click="showConfirmDeleteModal = true">
                        <v-list-item-icon class="mr-3">
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Удалить инвентаризацию</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Инвентаризация № ${$route.params.ID}` : 'Новая инвентаризация'"
                left-btn="left-big"
                :show-save-btn="!user.read_only && !inventory.task_completed"
                @click:left="$router.back()"
                @click:save="saveItem"
            >
                <Button
                    v-if="$route.params.ID"
                    :class="{'mr-4': !user.read_only && !inventory.task_completed}"
                    text
                    compact
                    :icon="width <= 699"
                    icon-right="file-minus"
                    @click.prevent="exportFile"
                >
                    Скачать в xls
                </Button>
            </Header>
            <div class="page-container page-container--with-fab">
                <div class="page__info-group">
                    <Row v-if="$route.params.ID">
                        <Col :cols="12">
                            <InputSelect
                                v-model="inventory.status"
                                label="Статус"
                                :options="options"
                                :disabled="inventory.status == 1"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col :cols="width > 1023 ? 6 : 12">
                            <InputCustom
                                :value="inventory.employee.first_name || user.first_name"
                                label="Сотрудник"
                                disabled
                            />
                        </Col>
                        <Col :cols="width > 1023 ? 6 : 12">
                            <InputSelect
                                v-model="inventory.shop.id"
                                :options="shops.results"
                                label="Магазин"
                                :input-text="'address'"
                                input-icon="type_icon"
                                :disabled="!!$route.params.ID"
                                :error="errors ? errors.shop : null"
                            />
                        </Col>
                    </Row>
                </div>
                <div class="page__info-group">
                    <div v-show="!preloader" class="listing-table__wrap">
                        <div v-if="inventory.inventoryitem && inventory.inventoryitem.length" class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                <div class="listing-table__col pl-0">Наименование</div>
                                <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;">Числится</div>
                                <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;">Фактически</div>
                                <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;">Разница</div>
                            </div>
                            <div v-for="good in inventory.inventoryitem" :key="good.id" class="listing-table__row">
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image listing-table__image--with-link">
                                        <img
                                            :src="good.product.image || good.image ? good.image || good.product.image :
                                                require('@/assets/img/flower.svg')
                                            "
                                            alt=""
                                        >
                                        <a href="#" class="listing-table__image-link" @click.stop.prevent="linkToGood(good.product.vendor_code || good.product)">
                                            <v-icon color="primary">mdi-open-in-new</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1024 ? 'width: calc(100% - 490px)' : 'width: calc(100% - 68px)'"
                                >
                                    <div>
                                        <div class="listing-table__product-name">
                                            <span>{{ good.name || good.product.name }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : 'flex: 1 1 calc(33% - 8px)'"
                                >
                                    <div>
                                        <div class="listing-table__col-label">Числится</div>
                                        {{ good.quantity_of_stock }}
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : 'flex: 1 1 calc(33% - 8px);max-width: 150px;'"
                                >
                                    <InputCustom
                                        v-model="good.actual_quantity"
                                        :label="width <= 1024 ? 'Фактически' : ''"
                                        type="number"
                                        style="flex: 0 0 auto"
                                        :disabled="inventory.status == 1"
                                        :error="!good.actual_quantity || isNaN(good.actual_quantity - good.quantity_of_stock) ? 'Проверьте значение' : ''"
                                    />
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : 'flex: 1 1 calc(33% - 8px); align-items: flex-end'"
                                >
                                    <div>
                                        <div class="listing-table__col-label">Разница</div>
                                        {{ good.actual_quantity - good.quantity_of_stock }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContentPreloader v-show="preloader" type="table" height="96" />
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmDeleteModal"
            title="Удалить инвентаризацию?"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteModal = false"
            @mainBtnClick="deleteItem"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import {
    getInventoriesID, filterStock, createInventory, updateInventory, deleteInventory
} from '@/api/stock';
import { exportInventoryToExcel } from '@/api/gateway';

import { date } from '@/utils/format';

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'TheInventoriesCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Инвентаризация ${this.$route.params.ID}` : 'Новая инвентаризация' }
    },
    components: {
        MiniModal
    },
    data() {
        return {
            inventory: {
                shop: {},
                employee: {
                    first_name: null
                },
                inventoryitem: [],
                status: 0
            },
            errors: {},
            options: [
                { name: 'Новая', id: 0 },
                { name: 'Выполнено', id: 1 },
            ],
            fab_open: false,
            showConfirmDeleteModal: false
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            shops: 'shops/shops',
            preloader: 'ui/preloader'
        }),
        user() {
            return JSON.parse(localStorage.getItem('user'));
        },
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        }
    },
    watch: {
        'inventory.shop': {
            handler() {
                if (!this.$route.params.ID) this.getShopStock();
            },
            deep: true
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            this.$store.dispatch('shops/GET_SHOPS');
            if (this.$route.params.ID) {
                const response = await getInventoriesID(this.$route.params.ID);
                this.inventory = response;
                this.markAsViewed();
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        async saveItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const data = JSON.parse(JSON.stringify(this.inventory));
                data.shop = data.shop.id;
                data.employee = !this.$route.params.ID ? JSON.parse(localStorage.getItem('user')).id : data.employee.id;
                data.inventoryitem.forEach((item) => { item.product = item.product.vendor_code });

                if (!this.$route.params.ID) {
                    await createInventory(data);
                } else {
                    delete data.is_seen;
                    await updateInventory(this.$route.params.ID, data);
                }
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Инвентаризация успешно сохранена', type: 'success' })
                this.$router.push('/storeroom/inventories');
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка сохранения инвентаризации', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async deleteItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await deleteInventory(this.$route.params.ID);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Инвентаризация успешно удалена', type: 'success' })
                this.$router.push('/storeroom/inventories');
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка удаления инвентаризации', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            } finally {
                this.showConfirmDeleteModal = false;
            }
        },
        async getShopStock() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                const { results } = await filterStock(`shop=${this.inventory.shop.id}&limit=99999`);
                this.inventory.inventoryitem = results.map((item) => ({
                    product: item.product,
                    actual_quantity: item.count,
                    quantity_of_stock: item.count
                }));
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка получения товаров со склада', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async markAsViewed() {
            this.$store.dispatch('ui/checkUserEditPermission');
            if (this.$route.params.ID && !this.inventory.is_seen) {
                await updateInventory(this.$route.params.ID, { is_seen: true });
                this.$store.dispatch('franchises/GET_FRANCHISE_TARIFF');
                this.$store.dispatch('stock/GET_STOCK_INDICATIONS');
            }
        },
        linkToGood(vendorCode) {
            window.open(`/goods/${vendorCode}`, '_blank');
        },
        async exportFile() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                const file = await exportInventoryToExcel(this.$route.params.ID);
                const data = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = data;
                link.target = '_blank';
                link.download = `Инвентаризация №${this.$route.params.ID}__${this.inventory.shop.shop_name}__${date(new Date(this.inventory.date), 'ru')}.xlsx`;
                link.click();

                setTimeout(() => {
                    window.URL.revokeObjectURL(data);
                }, 1000);
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка экспорта', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        isNaN(val) {
            return isNaN(val);
        }
    }
};
</script>

/* eslint-disable */
import axios from 'axios';

import store from '../store';
import router from '../router';

import token from '../utils/token';

import { responseErrorHandler, getNewAccessToken } from './auth'

const baseURL = process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_HOST_MAILING;

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(
    async function(config) {
        store.dispatch('ui/startLoading', true);

        if (config._retry) {
            await getNewAccessToken();
        }

        if (token()) {
            config.headers['Authorization'] = token();
        }

        return config;
    },
    function(error) {
        const { status, data } = error.response;
        return Promise.reject({ status, data });
    }
);

instance.interceptors.response.use(
    function(response) {
        store.dispatch('ui/stopLoading', false);
        return response.data;
    },
    async function(error) {
        store.dispatch('ui/stopLoading', false);

        // if (error.response && error.response.status === 401) {
        //     store.commit('auth/REMOVE_DATA_AUTH');
        //     router.push('/');
        // }

        try {
            const res = await responseErrorHandler(error, instance);
            return res;
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                console.log({ status, data });
                return Promise.reject({ status, data });
            } else {
                return Promise.reject(err);
            }
        }
    }
);

export function getNotificationsChanells() {
    return instance('/channels/');
}

export function createNotificationCustomer(data) {
    return instance({
        method: 'post',
        url: `/notifications/`,
        data
    });
}
export function getNotifications() {
    return instance('/notifications/');
}
export function getNextPageNotifications(offset) {
    return instance(`/notifications/?linit=20&offset=${offset}`);
}
export function getNotificationData(id) {
    return instance(`/notifications/${id}/`);
}


<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Пожелания по ассортименту" for-listing :show-save-btn="false" />
            <div
                class="page-container page-container--listing"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="300" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="wishes.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 25%">Пользователь</div>
                            <div class="listing-table__col" style="width: 25%">Дата</div>
                            <div class="listing-table__col" style="width: 50%">Текст пожелания</div>
                        </div>
                        <router-link v-for="item in wishes" :key="item.id" :to="`/wishes-for-products/${item.id}`" class="listing-table__row">
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                {{ item.user.first_name }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 25%' : ''">
                                <div class="listing-table__col-label">Дата</div>
                                {{ moment(item.datetime_created).format('DD.MM.YYYY') }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 50%' : ''">
                                <span class="text-cut">{{ item.text }}</span>
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="!wishes.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from "moment";

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheWishesForProducts',
    metaInfo: {
        title: 'Пожелания по ассортименту'
    },
    components: { Spinner },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
            width: 'ui/width',
            wishes: 'wishesForProducts/wishes',
            busy: 'wishesForProducts/busy',
            next: 'wishesForProducts/next',
            preloader: 'ui/preloader'
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await this.$store.dispatch('wishesForProducts/GET_WISHES');
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        loadMore() {
            this.$store.dispatch('wishesForProducts/LOAD_MORE');
        },
        moment
    },
};
</script>

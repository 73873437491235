/* eslint-disable */
import {
    fetchReviews,
    fetchReviewsID,
} from '@/api/django';

const state = {
    reviews: [],
    filters: {
        limit: 20,
        offset: 0,
        rating: null,
        date_from: null,
        date_to: null,
        florist: null
    },
    next: null,
    activeFilters: [],
    busy: false,
    review: [],
};

const mutations = {
    SET_REVIEWS(state, reviews) {
        state.reviews = reviews.results;
        state.next = !!reviews.next;
        state.filters.offset = 20;
    },
    SET_NEXT_PAGE(state, { results, next }) {
        state.reviews.push(...results);
        state.next = !!next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    CLEAR(state) {
        state.reviews = [];
        state.next = '';
        state.busy = false;
        state.filters.offset = 0;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            rating: null,
            date_from: null,
            date_to: null,
            florist: null
        };
        state.next = '';
    },
    SET_REVIEW(state, review) {
        state.review = review;
    },
};

const actions = {
    async GET_REVIEWS({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;
            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;
            let filters = await dispatch('prepareFilters');
            commit('SET_REVIEWS', await fetchReviews(filters));

            state.busy = false;
        } catch(error) {
            console.log(error);
        }
    },
    async GET_NEXT_PAGE({ state, commit, dispatch }) {
        try {
            if (state.next && !state.busy) {
                state.busy = true;
                let filters = await dispatch('prepareFilters');
                commit('SET_NEXT_PAGE', await fetchReviews(filters));
            }
        }
        catch(e) {
            console.log(e);
        }
     },
     async GET_REVIEW({ state, commit }, id) {
        try {
            const res = await fetchReviewsID(id);
            commit('SET_REVIEW', res);
            return state.review;
        }
        catch(e) {
            console.log(e);
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([key, value]) => {
            if (key && (value || value === false || value === 0)) {
                filters[key] = value;
            }
        })
        let activeFilters = Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    reviews: state => state.reviews,
    review: state => state.review,
    busy: state => state.busy,
    next: state => state.next,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

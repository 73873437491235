<template>
    <UniversalModal :opened="!!isModalOpened('help-modal')" @input="$store.commit('ui/CLOSE_MODAL', 'help-modal')">
        <UniversalModalHeader
            :title="'Написать в техподдержку'"
            @click:right="$store.commit('ui/CLOSE_MODAL', 'help-modal')"
        />
        <div class="universal-modal__content">
            <div v-show="preloader" class="table_preloader">
                <Loader />
            </div>

            <TextareaCustom
                v-model="messageInModal"
                label="Ваше сообщение"
                rows="6"
            />

            <div v-if="fileName">
                <span>{{ fileName }}</span>
                <v-btn icon @click="clearFile">
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </div>

            <label class="mt-4" style="display:block;">
                <input type="file" ref="modalFile" style="display: none;" @change="showFileName">
                <Button v-if="!fileName" block text icon-left="paperclip" @click="$refs.modalFile.click()">
                    Прикрепить изображение
                </Button>
            </label>

            <Button class="mt-4" block :disabled="!messageInModal" @click.prevent="sendMessage">
                Отправить
            </Button>
        </div>
    </UniversalModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { createSupportImage, sendSupportMessage } from '@/api/django';

export default {
    name: 'FeedbackModal',
    data() {
        return {
            messageInModal: '',
            fileName: ''
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            isModalOpened: 'ui/isModalOpened'
        }),
        group() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        }
    },
    methods: {
        async sendMessage() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const message = {
                    text: this.messageInModal,
                }

                if (this.$refs.modalFile.files.length) {
                    const image = new FormData();
                    const file = this.$refs.modalFile.files[0];
                    image.append('image', file);
                    const { id } = await createSupportImage(image);
                    message.images = [id];
                }

                await sendSupportMessage(message);

                this.messageInModal = '';
                this.clearFile();

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ваше сообщение успешно отправлено', type: 'success' })
                this.$store.commit('ui/CLOSE_MODAL', 'help-modal');
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При отправке сообщения произошла ошибка. Попробуйте повторить позже', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        showFileName(event) {
            if (event.target.files.length) {
                this.fileName = event.target.files[0].name;
            } else {
                this.clearFile();
            }
        },
        clearFile() {
            this.$refs.modalFile.value = '';
            this.fileName = '';
        },
    }
};
</script>

/* eslint-disable */
import {
    fetchPaymentMethods,
    fetchPaymentMethod,
    editPaymentType,
    savePaymentType,
    removePaymentType,
    getNextPagePayment
} from '@/api/django';

const state = {
    paymentMethods: [],
    next: null,
    offset: 20
};

const mutations = {
    SET_PAYMENT_METHODS(state, methods) {
        state.paymentMethods = methods.results;
        state.next = methods.next;
    },
    SET_NEXT_PAGES(state, data) {
        state.paymentMethods = state.paymentMethods.concat(data.results);
        state.next = data.next;
        state.offset += 20;
    },
};

const actions = {
    async LOAD_PAYMENT_TYPES({ commit }, param = false) {
        const results = await fetchPaymentMethods(param);
        commit('SET_PAYMENT_METHODS', results);
    },
    async LOAD_PAYMENT_TYPE({ commit },id) {
        const results = await fetchPaymentMethod(id);
        return results;
    },
    async EDIT_PAYMENT_TYPE({ commit }, data) {
        return await editPaymentType(data.id, data.method);
    },
    async SAVE_PAYMENT_TYPE({ commit },data) {
        return await savePaymentType(data);
    },
    async REMOVE_PAYMENT_TYPE({ commit }, id) {
        return await removePaymentType(id);
    },
    async LOAD_MORE({ state, commit }) {
        if (!!state.next) {
            try {
                const data = await getNextPagePayment(state.offset);
                commit('SET_NEXT_PAGES', data);
            } catch (error) {
                console.log(error);
            }
        }
    },
};

const getters = {
    paymentMethods: state => state.paymentMethods
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

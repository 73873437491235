<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial v-model="fab_open" class="btn__floating custom-speed-dial" close-on-click fixed>
            <template v-slot:activator>
                <v-btn color="#1a73e8" dark fab @click.stop="fab_open = !fab_open">
                    <v-icon v-if="fab_open">
                        mdi-close
                    </v-icon>
                    <v-icon v-else>
                        mdi-dots-vertical
                    </v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item @click="addPhoneLine">
                        <v-list-item-icon class="mr-3">
                            <v-icon>mdi-phone</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Добавить телефон</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Клиент ${franchise.site.name}` : 'Новый клиент'"
                :left-btn="group === 'supervisor' ? 'menu' : 'left-big'"
                :show-left-btn="(group === 'supervisor' && width <= 1024) || group !== 'supervisor'"
                :show-save-btn="!user.read_only"
                @click:left="group === 'supervisor' ? openNavigation() : back()"
                @click:save="saveFranchise"
            >
                <button
                    v-if="$route.params.ID && group !== 'supervisor'"
                    type="button"
                    class="header__button"
                    @click.prevent="removeFranchise"
                >
                    <v-icon color="#0F1922">mdi-delete</v-icon>
                </button>
            </Header>
            <div class="tabs__list">
                <TabItem :active="activeTab === 0" @click="activeTab = 0">
                    Основное
                </TabItem>
                <TabItem :active="activeTab === 1" @click="activeTab = 1">
                    Оплата
                </TabItem>
                <TabItem v-if="$route.params.ID" :active="activeTab === 2" @click="activeTab = 2">
                    Уведомления
                </TabItem>
            </div>
            <div class="page-container page-container--with-tabs page-container--with-fab">
                <div v-show="activeTab === 0" class="tabs__content">
                    <div class="page__info-group">
                        <div class="chips__wrap">
                            <Chips
                                :active="franchise.options.hide_all_products"
                                @click="franchise.options.hide_all_products = !franchise.options.hide_all_products"
                            >
                                Отключить все товары
                            </Chips>
                            <Chips
                                :active="franchise.options.bonus_is_active"
                                @click="franchise.options.bonus_is_active = !franchise.options.bonus_is_active"
                            >
                                Бонусная программа
                                <v-icon
                                    class="ml-2"
                                    size="14"
                                    :color="franchise.options.bonus_is_active ? '#fff' : '#96a5b9'"
                                    @click.stop="isOpenBonusesModal = true"
                                >
                                    mdi-pencil
                                </v-icon>
                            </Chips>
                        </div>
                        <Row>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <InputCustom
                                    v-model="franchise.site.name"
                                    label="Название"
                                    :error="error && error.site && error.site.name ? error.site.name[0] :
                                        $route.params.ID && error && error.name ? error.name[0] : ''
                                    "
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <InputCustom
                                    v-model="franchise.options.company_name"
                                    label="Название компании"
                                    :error="error && error.options && error.options.company_name ? error.options.company_name[0] : ''"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <InputSelect
                                    v-model="franchise.options.city"
                                    :options="allCities"
                                    label="Город"
                                    input-text="russian_name"
                                    searchable
                                    :error="error && error.options && error.options.city ? error.options.city[0] : ''"
                                />
                                <!-- <InputSearch
                                    label="Город:"
                                    :options="allCities"
                                    :city-id="cityID"
                                    :error="error && error.options && error.options.city ? error.options.city[0] : ''"
                                    @input="cityName = $event"
                                    @select="franchise.options.city = $event"
                                /> -->
                            </Col>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <InputCustom
                                    v-model="franchise.site.domain"
                                    label="Домен сайта"
                                    :error="error && error.site && error.site.domain ? error.site.domain[0] :
                                        $route.params.ID && error && error.domain ? error.domain[0] : ''
                                    "
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col v-for="(item, index) in phones_obs" :key="index" :cols="width > 1024 ? 3 : 12" style="position:relative;">
                                <InputCustom
                                    v-model="item.val"
                                    label="Телефон"
                                    placeholder="+7 (999) 999-99-99"
                                    :right-icon="index ? 'close' : ''"
                                    :error="error && error.options && error.options.phones ? 'Это поле не может быть пустым' :
                                        error && error.phones && error.phones[0] ? error.phones[0][0] : ''
                                    "
                                    @click:right="delPhoneLine(index)"
                                />
                            </Col>
                        </Row>
                    </div>
                    <div v-if="$route.params.ID" class="page__info-group">
                        <h2 class="page__info-group-title">Оповещение на сайте</h2>
                        <div class="chips__wrap">
                            <Chips
                                :active="franchise.options.header_alerts[0].is_active"
                                @click.prevent="franchise.options.header_alerts[0].is_active = !franchise.options.header_alerts[0].is_active"
                            >
                                Активность
                            </Chips>
                        </div>
                        <Row>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="alertDate"
                                    offset-y
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            :value="calendarText(alert[0].dates[0], alert[0].dates[1])"
                                            class="details-absolute"
                                            label="Период активности"
                                            readonly
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="alert[0].dates"
                                        no-title
                                        range
                                        first-day-of-week="1"
                                        @input="() => { if(alert[0].dates[0] && alert[0].dates[1]) $refs.alertDate.save(alert[0].dates) }"
                                    />
                                </v-menu>
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="franchise.options.header_alerts[0].header"
                                    label="Заголовок оповещения"
                                />
                            </Col>
                            <Col v-show="!preloader" cols="12">
                                <yimo-vue-editor
                                    v-model="franchise.options.header_alerts[0].text"
                                    style="min-height: 90px;"
                                />
                            </Col>
                            <Col v-show="preloader" cols="12">
                                <ContentPreloader v-show="preloader" height="140" />
                            </Col>
                        </Row>
                    </div>
                    <div v-if="!$route.params.ID" class="page__info-group">
                        <h2 class="page__info-group-title">Магазин</h2>
                        <Row>
                            <Col :cols="width > 1024 ? 2 : 12">
                                <InputCustom
                                    v-model="franchise.shop.zip_code"
                                    label="Почтовый индекс"
                                    :error="error && error.shop && error.shop.zip_code ? error.shop.zip_code[0] : ''"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 4 : 12">
                                <InputCustom
                                    v-model="franchise.shop.street"
                                    :error="error && error.shop && error.shop.street ? error.shop.street[0] : ''"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 2 : 12">
                                <InputCustom
                                    v-model="franchise.shop.house"
                                    label="Дом"
                                    :error="error && error.shop && error.shop.house ? error.shop.house[0] : ''"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 2 : 12">
                                <InputCustom
                                    v-model="franchise.shop.building"
                                    label="Корпус/строение/литера"
                                    :error="error && error.shop && error.shop.building ? error.shop.building[0] : ''"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 2 : 12">
                                <InputCustom
                                    v-model="franchise.shop.appartement"
                                    label="Офис/помещение"
                                    :error="error && error.shop && error.shop.appartement ? error.shop.appartement[0] : ''"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="work_time_start"
                                    :close-on-content-click="false"
                                    :return-value.sync="franchise.shop.work_time_start"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            v-model="franchise.shop.work_time_start"
                                            label="Часы работы c"
                                            :error="error && error.shop && error.shop.work_time_start ? 'Указано неверное время' : ''"
                                            v-on="on"
                                        />
                                    </template>
                                    <v-time-picker
                                        v-model="franchise.shop.work_time_start"
                                        format="24hr"
                                        full-width
                                        scrollable
                                        @click:minute="$refs.work_time_start.save(franchise.shop.work_time_start)"
                                    />
                                </v-menu>
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="work_time_end"
                                    :close-on-content-click="false"
                                    :return-value.sync="franchise.shop.work_time_end"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="franchise.shop.work_time_end"
                                            label="Часы работы до"
                                            outlined
                                            attach
                                            hide-details="auto"
                                            v-on="on"
                                            :error-messages="error && error.shop && error.shop.work_time_end ? 'Указано неверное время' : ''"
                                        />
                                    </template>
                                    <v-time-picker
                                        v-model="franchise.shop.work_time_end"
                                        format="24hr"
                                        full-width
                                        scrollable
                                        @click:minute="$refs.work_time_end.save(franchise.shop.work_time_end)"
                                    />
                                </v-menu>
                            </Col>
                            <Col :cols="12">
                                <p class="app-fieldset-row">
                                    <label class="app-input-label">Укажите здание магазина на карте:</label>
                                </p>
                                <div id="franchiseMap" ref="map" />
                            </Col>
                        </Row>
                    </div>
                    <div v-if="!$route.params.ID" class="page__info-group">
                        <h2 class="page__info-group-title">Пользователь (администратор)</h2>
                        <Row>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="franchise.admin.username"
                                    label="Логин"
                                    :error="error && error.admin && error.admin.username ? error.admin.username[0] : ''"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="franchise.admin.password"
                                    label="Пароль"
                                    :error="error && error.admin && error.admin.password ? error.admin.password[0] : ''"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="franchise.admin.first_name"
                                    label="Имя"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="franchise.admin.email"
                                    label="Email"
                                    :error="error && error.admin && error.admin.email ? error.admin.email[0] : ''"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="franchise.admin.phone"
                                    label="Телефон"
                                    :error="error && error.admin && error.admin.phone ? 'Проверьте введенные данные' : ''"
                                />
                            </Col>
                        </Row>
                    </div>
                    <div v-if="$route.params.ID" class="page__info-group">
                        <h2 class="page__info-group-title">Автозарплаты</h2>
                        <div class="chips__wrap">
                            <Chips
                                :active="franchise.options.autocreate_salary"
                                @click.prevent="franchise.options.autocreate_salary = !franchise.options.autocreate_salary"
                            >
                                Автоматически создавать зарплаты
                            </Chips>
                        </div>
                        <Row>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <InputSelect
                                    v-model="franchise.options.periodicity"
                                    :options="[{value: 'every week', name: 'Еженедельно'}, {value: 'on certain date', name: 'В определённые даты'}]"
                                    label="Периодичность"
                                    input-value="value"
                                    :disabled="!franchise.options.autocreate_salary"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <!-- <v-select
                                    v-if="franchise.options.periodicity === 'every week'"
                                    outlined
                                    hide-details="auto"
                                    label="День недели"
                                    :items="daysOfWeekOptions"
                                    v-model="franchise.options.day_of_week"
                                    item-value="id"
                                    item-text="name"
                                    :disabled="!franchise.options.autocreate_salary"
                                /> -->
                                <InputSelect
                                    v-if="franchise.options.periodicity === 'on certain date'"
                                    v-model="franchise.options.list_of_salary_dates"
                                    :options="daysOptions"
                                    label="Даты"
                                    input-value="name"
                                    multiple
                                    :disabled="!franchise.options.autocreate_salary"
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div v-show="activeTab === 1" class="tabs__content">
                    <div class="page__info-group">
                        <Row>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <div class="d-flex align-center">
                                    <InputCustom
                                        v-model="franchise.options.allowable_price_difference"
                                        label="Допустимая разница в цене, %"
                                        :error="error && error.options && error.options.allowable_price_difference ? error.options.allowable_price_difference[0] :
                                            $route.params.ID && error && error.allowable_price_difference ? error.allowable_price_difference[0] : ''
                                        "
                                    />
                                    <v-tooltip content-class="input-tooltip" right color="#000" max-width="155">
                                        <template v-slot:activator="{ on }">
                                            <v-icon class="ml-1" color="#96a5b9" v-on="on">
                                                mdi-information
                                            </v-icon>
                                        </template>
                                        Введите максимальную разницу между стоимостью заказа и оплаченной пользователем суммой.
                                    </v-tooltip>
                                </div>
                            </Col>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <InputCustom
                                    v-model="franchise.options.max_sales"
                                    label="Допустимая скидка, %"
                                    :error="error && error.options && error.options.max_sales ? error.options.max_sales[0] :
                                        $route.params.ID && error && error.max_sales ? error.max_sales[0] : ''
                                    "
                                />
                            </Col>
                        </Row>
                    </div>
                    <div class="page__info-group">
                        <h2 class="page__info-group-title">Доставка</h2>
                        <div class="chips__wrap">
                            <Chips
                                :active="franchise.options.delivery_enabled"
                                @click="franchise.options.delivery_enabled = !franchise.options.delivery_enabled"
                            >
                                Доставка активна
                            </Chips>
                            <v-tooltip right color="#000">
                                <template v-slot:activator="{ on, attrs }">
                                    <Chips
                                        :active="franchise.options.delivery_around_the_clock"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="franchise.options.delivery_around_the_clock = !franchise.options.delivery_around_the_clock"
                                    >
                                        Круглосуточно
                                    </Chips>
                                </template>
                                <span>Круглосуточная доставка</span>
                            </v-tooltip>
                        </div>
                        <Row v-if="!franchise.options.delivery_around_the_clock">
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="delivery_time_start"
                                    :close-on-content-click="false"
                                    :return-value.sync="franchise.options.delivery_time_start"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            v-model="franchise.options.delivery_time_start"
                                            label="Часы доставки c"
                                            :error="error && error.options && error.options.delivery_time_start ? 'Указано неверное время' :
                                                error && error.delivery_time_start ? 'Указано неверное время' : ''
                                            "
                                            v-on="on"
                                        />
                                    </template>
                                    <v-time-picker
                                        v-model="franchise.options.delivery_time_start"
                                        format="24hr"
                                        full-width
                                        scrollable
                                        @click:minute="$refs.delivery_time_start.save(franchise.options.delivery_time_start)"
                                    />
                                </v-menu>
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="delivery_time_end"
                                    :close-on-content-click="false"
                                    :return-value.sync="franchise.options.delivery_time_end"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            v-model="franchise.options.delivery_time_end"
                                            label="Часы доставки до"
                                            :error="error && error.options && error.options.delivery_time_end ? 'Указано неверное время' :
                                                error && error.delivery_time_end ? 'Указано неверное время' : ''
                                            "
                                            v-on="on"
                                        />
                                    </template>
                                    <v-time-picker
                                        v-model="franchise.options.delivery_time_end"
                                        format="24hr"
                                        full-width
                                        scrollable
                                        @click:minute="$refs.delivery_time_end.save(franchise.options.delivery_time_end)"
                                    />
                                </v-menu>
                            </Col>
                        </Row>
                        <Row>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    :value="+franchise.options.delivery_cost"
                                    label="Стоимость доставки, ₽"
                                    :error="error && error.options && error.options.delivery_cost ? error.options.delivery_cost[0] :
                                        $route.params.ID && error && error.delivery_cost ? error.delivery_cost[0] : ''
                                    "
                                    @input="franchise.options.delivery_cost = $event"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    :value="+franchise.options.delivery_tariff"
                                    label="Тариф доставки за городом (руб/км)"
                                    :error="error && error.options && error.options.delivery_tariff ? error.options.delivery_tariff[0] :
                                        $route.params.ID && error && error.delivery_tariff ? error.delivery_tariff[0] : ''
                                    "
                                    @input="franchise.options.delivery_tariff = $event"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <v-menu
                                    ref="delivery_markup_bytime_city_start"
                                    :close-on-content-click="false"
                                    :return-value.sync="franchise.options.delivery_markup_bytime_city_start"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            v-model="franchise.options.delivery_markup_bytime_city_start"
                                            label="Часы ночной доставки c"
                                            :error="error && error.options && error.options.delivery_markup_bytime_city_start ? 'Указано неверное время' :
                                                error && error.delivery_markup_bytime_city_start ? 'Указано неверное время' : ''
                                            "
                                            v-on="on"
                                        />
                                    </template>
                                    <v-time-picker
                                        v-model="franchise.options.delivery_markup_bytime_city_start"
                                        format="24hr"
                                        full-width
                                        scrollable
                                        @click:minute="$refs.delivery_markup_bytime_city_start.save(franchise.options.delivery_markup_bytime_city_start)"
                                    />
                                </v-menu>
                            </Col>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <v-menu
                                    ref="delivery_markup_bytime_city_end"
                                    :close-on-content-click="false"
                                    :return-value.sync="franchise.options.delivery_markup_bytime_city_end"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            v-model="franchise.options.delivery_markup_bytime_city_end"
                                            label="Часы ночной доставки до"
                                            :error="error && error.options && error.options.delivery_markup_bytime_city_end ? 'Указано неверное время' :
                                                error && error.delivery_markup_bytime_city_end ? 'Указано неверное время' : ''
                                            "
                                            v-on="on"
                                        />
                                    </template>
                                    <v-time-picker
                                        v-model="franchise.options.delivery_markup_bytime_city_end"
                                        format="24hr"
                                        full-width
                                        scrollable
                                        @click:minute="$refs.delivery_markup_bytime_city_end.save(franchise.options.delivery_markup_bytime_city_end)"
                                    />
                                </v-menu>
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    :value="+franchise.options.delivery_markup_bytime_city"
                                    type="number"
                                    label="Стоимость доставки ночью, ₽"
                                    :error="error && error.options && error.options.delivery_markup_bytime_city ? error.options.delivery_markup_bytime_city[0] :
                                        $route.params.ID && error && error.delivery_markup_bytime_city ? error.delivery_markup_bytime_city[0] : ''
                                    "
                                    @input="franchise.options.delivery_markup_bytime_city = $event"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <div class="chips__wrap">
                                    <Chips
                                        :active="franchise.options.delivery_is_free"
                                        @click="franchise.options.delivery_is_free = !franchise.options.delivery_is_free"
                                    >
                                        Бесплатная доставка
                                    </Chips>
                                </div>
                                <InputCustom
                                    :value="+franchise.options.cost_delivery_free"
                                    label="Бесплатная доставка, ₽"
                                    :error="error && error.options && error.options.cost_delivery_free ? error.options.cost_delivery_free[0] :
                                        $route.params.ID && error && error.cost_delivery_free ? error.cost_delivery_free[0] : ''
                                    "
                                    @input="franchise.options.cost_delivery_free = $event"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <div class="chips__wrap">
                                    <Chips
                                        :active="franchise.options.is_date_limit"
                                        @click="franchise.options.is_date_limit = !franchise.options.is_date_limit"
                                    >
                                        Ограничение даты заказа
                                    </Chips>
                                </div>
                                <InputCustom
                                    v-model="franchise.options.min_limit_days_for_create_order"
                                    label="Минимальная дата заказа, дн."
                                    type="number"
                                    maxlength="2"
                                    :error="error && error.min_limit_days_for_create_order ? error.min_limit_days_for_create_order[0] : ''"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 3 : 12">
                                <div v-if="width > 1024" style="width: 100%;height: 48px" />
                                <InputCustom
                                    v-model="franchise.options.limit_days_for_create_order"
                                    label="Максимальная дата заказа, дн."
                                    type="number"
                                    maxlength="2"
                                    :error="error && error.limit_days_for_create_order ? error.limit_days_for_create_order[0] : ''"
                                />
                            </Col>
                        </Row>
                        <!-- НЕ УДАЛЯТЬ КОД НИЖЕ-->
                        <!-- <v-row>
                            <v-col :cols="width > 1024 ? 3 : 12">
                                <v-menu offset-y attach :close-on-content-click="false" transition="scale-transition" max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            ref="calender1"
                                            outlined
                                            required
                                            hide-details="auto"
                                            label="Дата начала наценки"
                                            :value="app.delivery_markup_start ? new Date(app.delivery_markup_start).toLocaleString().substr(0, 10) : ''"
                                            persistent-hint
                                            v-on="on"
                                            :error-messages="error && error.options && error.options.delivery_markup_start ? error.options.delivery_markup_start[0] : $route.params.ID && error && error.delivery_markup_start ? error.delivery_markup_start[0] : ''"
                                        >
                                            <template slot="prepend-inner">
                                                <v-icon color="#96A5B9" class="mr-1" v-on="on">
                                                    mdi-calendar-range
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="app.delivery_markup_start"
                                        no-title
                                        @input="calender1 = false"
                                    />
                                </v-menu>
                            </v-col> -->
                            <!-- <v-col :cols="width > 1024 ? 3 : 12">
                                <v-menu offset-y attach :close-on-content-click="false" transition="scale-transition" max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            ref="calender2"
                                            outlined
                                            required
                                            hide-details="auto"
                                            label="Дата окончания наценки"
                                            :value="app.delivery_markup_end ? new Date(app.delivery_markup_end).toLocaleString().substr(0, 10) : ''"
                                            persistent-hint
                                            v-on="on"
                                            :error-messages="error && error.options && error.options.delivery_markup_end ? error.options.delivery_markup_end[0] : $route.params.ID && error && error.delivery_markup_end ? error.delivery_markup_end[0] : ''"
                                        >
                                            <template slot="prepend-inner">
                                                <v-icon color="#96A5B9" class="mr-1" v-on="on">
                                                    mdi-calendar-range
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="app.delivery_markup_end"
                                        no-title
                                        @input="calender2 = false"
                                    />
                                </v-menu>
                            </v-col> -->
                            <!-- <v-col :cols="width > 1024 ? 6 : 12">
                                <v-textarea
                                    label="Наценка стоимости доставки"
                                    auto-grow
                                    outlined
                                    hide-details="auto"
                                    rows="1"
                                    v-model="franchise.options.delivery_markup_cost"
                                    row-height="15"
                                    :error-messages="error && error.options && error.options.delivery_markup_cost ? error.options.delivery_markup_cost[0] : $route.params.ID && error && error.delivery_markup_cost ? error.delivery_markup_cost[0] : ''"
                                />
                            </v-col>
                        </v-row> -->
                        <!-- НЕ УДАЛЯТЬ КОД ВЫШЕ-->
                    </div>
                    <div class="page__info-group">
                        <div class="d-flex align-center page__info-group-title">
                            <div>Интервалы доставок</div>
                            <v-btn class="ml-4" color="primary" small @click.prevent="openDeliveryIntervalModal(null)">
                                + Добавить
                            </v-btn>
                        </div>
                        <div v-if="franchise.options.delivery_intervals.length" class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: calc((100% - 68px) / 5)">Дата</div>
                                <div class="listing-table__col" style="width: calc((100% - 68px) / 5)">Причина</div>
                                <div class="listing-table__col" style="width: calc((100% - 68px) / 5)">Интервал доставок</div>
                                <div class="listing-table__col" style="width: calc((100% - 68px) / 5)">Количество доставок за интервал</div>
                                <div class="listing-table__col" style="width: calc((100% - 68px) / 5);text-align: center;">Ближайшее</div>
                                <div class="listing-table__col" style="width: 68px; flex: 0 0 auto;" />
                            </div>
                            <a
                                v-for="(item, index) in franchise.options.delivery_intervals"
                                :key="item.id"
                                class="listing-table__row"
                                @click.prevent="openDeliveryIntervalModal(item)"
                            >
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 68px) / 5)' : ''">
                                    <div class="listing-table__col-label">Дата</div>
                                    {{ moment(item.date).format('DD.MM') }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 68px) / 5)' : ''">
                                    <div class="listing-table__col-label">Причина</div>
                                    {{ item.name || '&mdash;' }}
                                </div>
                                <div
                                    class="listing-table__col"
                                    :style="width > 1024 ? 'width: calc((100% - 68px) / 5)' : ''"
                                >
                                    <div class="listing-table__col-label">Интервал</div>
                                    {{ item.hours }}ч
                                </div>
                                <div
                                    class="listing-table__col"
                                    :style="width > 1024 ? 'width: calc((100% - 68px) / 5)' : ''"
                                >
                                    <div class="listing-table__col-label">Доставок за интервал</div>
                                    {{ item.delivery_count }}
                                </div>
                                <div
                                    class="listing-table__col"
                                    :style="width > 1024 ? 'width: calc((100% - 68px) / 5)' : ''"
                                >
                                    <div class="listing-table__col-label">Ближайшее</div>
                                    <Icon :color="item.is_soon ? '#36B687' : '#F00C29'">
                                        {{ item.is_soon ? 'check' : 'close' }}
                                    </Icon>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline ml-a"
                                    :style="width > 1024 ? 'width: 68px; flex: 0 0 auto;' : ''"
                                >
                                    <Icon color="#0D63F3" @click.stop.prevent="franchise.options.delivery_intervals.splice(index, 1)">
                                        trash
                                    </Icon>
                                </div>
                            </a>
                        </div>
                        <div v-else>Список интервалов доставки пуст</div>
                    </div>
                    <div class="page__info-group">
                        <h2 class="page__info-group-title">Типы оплаты</h2>
                        <div v-if="payment_list.length" class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: calc((100% - 68px) / 4)">Название</div>
                                <div class="listing-table__col" style="width: calc((100% - 68px) / 4);text-align:center">
                                    Оплата на сайте
                                </div>
                                <div class="listing-table__col" style="width: calc((100% - 68px) / 4);text-align:center">
                                    Оплата на кассе
                                </div>
                                <div class="listing-table__col" style="width: calc((100% - 68px) / 4);text-align:center">
                                    Чек наличными
                                </div>
                                <div class="listing-table__col" style="width: 68px; flex: 0 0 auto;" />
                            </div>
                            <div v-for="item in payment_list" :key="item.id" class="listing-table__row">
                                <div
                                    class="listing-table__col listing-table__col--mobile-bordered"
                                    :style="width > 1024 ? 'width: calc((100% - 68px) / 4)' : ''"
                                >
                                    {{ item.name }}
                                </div>
                                <div
                                    class="listing-table__col"
                                    :style="width > 1024 ? 'width: calc((100% - 68px) / 4)' : ''"
                                >
                                    <div class="listing-table__col-label">Оплата на сайте</div>
                                    <Icon :color="item.is_viewed ? '#36B687' : ''">
                                        {{ item.is_viewed ? 'check' : 'minus' }}
                                    </Icon>
                                </div>
                                <div
                                    class="listing-table__col"
                                    :style="width > 1024 ? 'width: calc((100% - 68px) / 4)' : ''"
                                >
                                    <div class="listing-table__col-label">Оплата на кассе</div>
                                    <Icon :color="item.is_for_cashbox ? '#36B687' : ''">
                                        {{ item.is_for_cashbox ? 'check' : 'minus' }}
                                    </Icon>
                                </div>
                                <div
                                    class="listing-table__col"
                                    :style="width > 1024 ? 'width: calc((100% - 68px) / 4)' : ''"
                                >
                                    <div class="listing-table__col-label">Чек наличными</div>
                                    <Icon :color="item.is_cash ? '#36B687' : ''">
                                        {{ item.is_cash ? 'check' : 'minus' }}
                                    </Icon>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline ml-a"
                                    :style="width > 1024 ? 'width: 68px; flex: 0 0 auto;' : ''"
                                >
                                    <v-btn v-if="item.id == '1306' || item.id == '1304'" icon @click.prevent="editPaymentType(item)">
                                        <v-icon color="#96A5B9">mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-center mt-4">
                            <Button bordered icon-left="edit-2" @click.prevent="isOpenPaymentTypeModal = true">
                                Изменить типы оплаты
                            </Button>
                        </div>
                    </div>
                </div>
                <div v-if="$route.params.ID" v-show="activeTab === 2" class="tabs__content">
                    <NotificationSettings />
                </div>
            </div>
        </div>
        <UniversalModal v-model="isOpenBillModal">
            <UniversalModalHeader
                title="По счету"
                @click:right="isOpenBillModal = false"
            />
            <div class="universal-modal__content">
                <Row>
                    <Col>
                        <InputCustom
                            v-model="franchise.options.actual_address"
                            label="Фактический адрес"
                            :error="error && error.options && error.options.actual_address ? error.options.actual_address[0] : ''"
                        />
                    </Col>
                    <Col>
                        <InputCustom
                            v-model="franchise.options.legal_address"
                            label="Юридический адрес"
                            :error="error && error.options && error.options.legal_address ? error.options.legal_address[0] : ''"
                        />
                    </Col>
                    <Col :cols="width > 1024 ? 6 : 12">
                        <InputCustom
                            v-model="franchise.options.tax_id"
                            label="ИНН"
                            :error="error && error.options && error.options.tax_id ? error.options.tax_id[0] : ''"
                        />
                    </Col>
                    <Col :cols="width > 1024 ? 6 : 12">
                        <InputCustom
                            v-model="franchise.options.registration_number"
                            label="ОГРН"
                            :error="error && error.options && error.options.registration_number ? error.options.registration_number[0] : ''"
                        />
                    </Col>
                    <Col :cols="width > 1024 ? 6 : 12">
                        <InputCustom
                            v-model="franchise.options.kpp_field"
                            label="КПП"
                            :error="error && error.options && error.options.kpp_field ? error.options.kpp_field[0] : ''"
                        />
                    </Col>
                    <Col :cols="width > 1024 ? 6 : 12">
                        <InputCustom
                            v-model="franchise.options.bank_checking_account"
                            label="Счет банка"
                            :error="error && error.options && error.options.bank_checking_account ? error.options.bank_checking_account[0] : ''"
                        />
                    </Col>
                    <Col :cols="width > 1024 ? 6 : 12">
                        <InputCustom
                            v-model="franchise.options.bank_identification_code"
                            label="БИК"
                            :error="error && error.options && error.options.bank_identification_code ? error.options.bank_identification_code[0] : ''"
                        />
                    </Col>
                    <Col :cols="width > 1024 ? 6 : 12">
                        <InputCustom
                            v-model="franchise.options.checking_account"
                            label="Расчетный счет"
                            :error="error && error.options && error.options.checking_account ? error.options.checking_account[0] : ''"
                        />
                    </Col>
                    <Col :cols="width > 1024 ? 12 : 12">
                        <InputCustom
                            v-model="franchise.options.bank_receiver"
                            label="Банк получателя"
                            :error="error && error.options && error.options.bank_receiver ? error.options.bank_receiver[0] : ''"
                        />
                    </Col>
                    <Col :cols="width > 1024 ? 6 : 12">
                        <InputCustom
                            v-model="franchise.options.director_name"
                            label="Руководитель"
                            :error="error && error.options && error.options.director_name ? error.options.director_name[0] : ''"
                        />
                    </Col>
                    <Col :cols="width > 1024 ? 6 : 12">
                        <InputCustom
                            v-model="franchise.options.accountant_name"
                            label="Бухгалтер"
                            :error="error && error.options && error.options.accountant_name ? error.options.accountant_name[0] : ''"
                        />
                    </Col>
                </Row>
                <Row v-if="$route.params.ID">
                    <Col :cols="width > 1024 ? 6 : 12">
                        <div class="app-photo-load-wrapper">
                            <label
                                class="app-photo-load"
                                :style="{'background-image': franchise.options.print_image ? `url(${franchise.options.print_image})` : false}"
                            >
                                <input
                                    type="file"
                                    accept="image/*"
                                    class="app-photo-load__file-input"
                                    @change="loadImage($event)"
                                >
                                <div v-if="!franchise.options.print_image" class="app-photo-drop">
                                    <b>Печать организации</b>
                                </div>
                                <div v-if="!franchise.options.print_image" class="app-photo-bg-image" />
                            </label>
                        </div>
                    </Col>
                </Row>
            </div>
        </UniversalModal>
        <UniversalModal v-model="isOpenPaymentTypeModal">
            <UniversalModalHeader
                :title="payment_list.length ? `${payment_list.length} шт.` : 'Выберите типы оплат'"
                @click:right="isOpenPaymentTypeModal = false"
            />
            <div class="universal-modal__content">
                <div class="listing-table">
                    <div class="listing-table__row listing-table__row--head">
                        <div class="listing-table__col" style="width: 25%">Название</div>
                        <div class="listing-table__col" style="width: 25%;text-align:center">Оплата на сайте</div>
                        <div class="listing-table__col" style="width: 25%;text-align:center">Оплата на кассе</div>
                        <div class="listing-table__col" style="width: 25%;text-align:center">Чек наличными</div>
                    </div>
                    <a
                        v-for="item in paymentMethods"
                        :key="item.id"
                        class="listing-table__row"
                        :class="{'active': selectedPaymentTypes(item.id)}"
                        @click.prevent="$store.dispatch('franchises/EVENT_HANDLER', item)"
                    >
                        <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 25%' : ''">
                            {{ item.name }}
                        </div>
                        <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                            <div class="listing-table__col-label">Оплата на сайте</div>
                            <Icon :color="item.is_viewed ? '#36B687' : ''">
                                {{ item.is_viewed ? 'check' : 'minus' }}
                            </Icon>
                        </div>
                        <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                            <div class="listing-table__col-label">Оплата на кассе</div>
                            <Icon :color="item.is_for_cashbox ? '#36B687' : ''">
                                {{ item.is_for_cashbox ? 'check' : 'minus' }}
                            </Icon>
                        </div>
                        <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                            <div class="listing-table__col-label">Чек наличными</div>
                            <Icon :color="item.is_cash ? '#36B687' : ''">
                                {{ item.is_cash ? 'check' : 'minus' }}
                            </Icon>
                        </div>
                    </a>
                </div>
            </div>
        </UniversalModal>
        <UniversalModal v-model="isOpenSberModal">
            <UniversalModalHeader
                title="По карте (на сайте)"
                @click:right="isOpenSberModal = false"
            />
            <div class="universal-modal__content">
                <Row>
                    <Col>
                        <InputCustom
                            v-model="franchise.options.merchant_username"
                            label="Логин от Сбербанка"
                            :error="error && error.options && error.options.merchant_username ? error.options.merchant_username[0] : ''"
                        />
                    </Col>
                    <Col>
                        <InputCustom
                            v-model="franchise.options.merchant_password"
                            label="Пароль от Сбербанка"
                            type="password"
                            :error="error && error.options && error.options.merchant_password ? error.options.merchant_password[0] : ''"
                        />
                    </Col>
                </Row>
            </div>
        </UniversalModal>
        <UniversalModal v-model="isOpenBonusesModal" size="big">
            <UniversalModalHeader
                title="Настройка бонусов"
                @click:right="isOpenBonusesModal = false"
            />
            <div class="universal-modal__content">
                <div class="listing-table">
                    <div v-for="(step, index) in franchise.options.bonuses_options" :key="index" class="listing-table__row">
                        <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 50px) / 3)' : ''">
                            <InputCustom
                                v-model="step.ratio_when_applied"
                                label="Бонусов за 1 рубль, %"
                                type="number"
                            />
                        </div>
                        <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 50px) / 3)' : ''">
                            <InputCustom
                                :value="+step.start_amount"
                                label="Начальное значение, ₽"
                                type="number"
                                @input="step.start_amount = $event"
                            />
                        </div>
                        <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 50px) / 3)' : ''">
                            <InputCustom
                                :value="+step.end_amount"
                                label="Конечное значение, ₽"
                                type="number"
                                @input="step.end_amount = $event"
                            />
                        </div>
                        <div class="listing-table__col listing-table__col--mobile-inline ml-a" :style="width > 1024 ? 'width: 50px' : ''">
                            <Icon
                                color="#0D63F3"
                                style="cursor: pointer;"
                                @click.prevent="franchise.options.bonuses_options.splice(index, 1)"
                            >
                                trash
                            </Icon>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center mt-4">
                    <Button text compact icon-left="folder-plus" @click.prevent="addBonusesStep">
                    Добавить шаг
                </Button>
                </div>
            </div>
        </UniversalModal>
        <UniversalModal v-model="isOpenIntervalModal">
            <UniversalModalHeader
                title="Настройка интервала доставки"
                left-btn="left-big"
                right-btn="check"
                @click:left="isOpenIntervalModal = false"
                @click:right="saveDeliveryInterval"
            />
            <div class="universal-modal__content">
                <Row>
                    <Col>
                        <v-menu
                            ref="deliveryIntervalDate"
                            offset-y
                            :close-on-content-click="false"
                            transition="scale-transition"
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <InputCustom
                                    :value="moment(deliveryIntervalForEdit.date).format('DD MMMM')"
                                    label="Дата доставки"
                                    left-icon="calendar"
                                    readonly
                                    v-on="on"
                                />
                            </template>
                            <v-date-picker
                                v-model="deliveryIntervalForEdit.date"
                                no-title
                                first-day-of-week="1"
                                @input="$refs.deliveryIntervalDate.save(deliveryIntervalForEdit.date)"
                            />
                        </v-menu>
                    </Col>
                    <Col>
                        <InputCustom v-model="deliveryIntervalForEdit.name" label="Причина" />
                    </Col>
                    <Col>
                        <InputSelect
                            v-model="deliveryIntervalForEdit.hours"
                            :options="[{id: 2, name: '2'}, {id: 3, name: '3'}, {id: 4, name: '4'}]"
                            label="Интервал доставки, ч"
                        />
                    </Col>
                    <Col>
                        <InputCustom
                            :value="+deliveryIntervalForEdit.delivery_count"
                            label="Количество доставок за интервал"
                            type="number"
                            @input="deliveryIntervalForEdit.delivery_count = $event"
                        />
                    </Col>
                    <Col>
                        <div class="checkbox-group">
                            <div class="checkbox-group__item">
                                <Checkbox
                                    :value="deliveryIntervalForEdit.is_soon"
                                    label='Выводить "Ближайшее" время'
                                    @change="deliveryIntervalForEdit.is_soon = !deliveryIntervalForEdit.is_soon"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from "moment";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { basemapLayer, featureLayer } from 'esri-leaflet';
import { geocode } from 'esri-leaflet-geocoder';

import { editFranchise } from '@/api/django';

import NotificationSettings from '@/components/app/notifications/NotificationSettings'
// import NotificationsSelectMultiple from '@/components/forms/NotificationsSelectMultiple';

import { date } from '@/utils/format';

let MAP_SEARCH_TIMEOUT_ID = null;
let franchiseMap = null;
let franchiseMarker = null;

export default {
    name: 'TheFranchiseCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Клиент ${this.franchise.site.name}` : 'Новый клиент' }
    },
    components: {
        NotificationSettings,
        // NotificationsSelectMultiple
    },
    data: () => ({
        phones_obs: [{ val: '' }],
        franchise: {
            site: {
                name: '',
                domain: '',
            },
            options: {
                city: '',
                delivery_enabled: true,
                delivery_cost: 350,
                delivery_tariff: 0,
                delivery_markup_cost: 0,
                delivery_time_start: '',
                delivery_time_end: '',
                delivery_markup_start: null,
                delivery_markup_end: null,
                phones: [{ val: '' }],
                enabled_payment_methods: [],
                merchant_username: '',
                merchant_password: '',
                actual_address: '',
                bank_checking_account: '',
                bank_identification_code: '',
                checking_account: '',
                bank_receiver: '',
                kpp_field: '',
                legal_address: '',
                company_name: '',
                tax_id: '',
                registration_number: '',
                bonus_is_active: false,
                director_name: '',
                accountant_name: '',
                print_image: null,
                delivery_is_free: false,
                cost_delivery_free: 1000000,
                autocreate_salary: false,
                periodicity: 'every week',
                day_of_week: 0,
                list_of_salary_dates: [],
                allowable_price_difference: 5,
                max_sales: 5,
                is_date_limit: false,
                min_limit_days_for_create_order: 0,
                limit_days_for_create_order: 0,
                hide_all_products: false,
                delivery_around_the_clock: false,
                bonuses_options: [
                    { ratio_when_applied: 0, start_amount: 0, end_amount: 0 }
                ],
                header_alerts: [
                    {
                        is_active: false, date_activity_start: null, date_activity_end: null, header: '', text: ''
                    }
                ],
                delivery_intervals: [],
                delivery_markup_bytime_city: 0,
                delivery_markup_bytime_city_start: '00:00',
                delivery_markup_bytime_city_end: '07:00'
            },
            shop: {
                zip_code: '',
                street: '',
                house: '',
                building: '',
                appartement: '',
                coords: '',
                work_time_start: '',
                work_time_end: ''
            },
            admin: {
                email: '',
                username: '',
                first_name: '',
                password: '',
                phone: null
            },
        },
        app: {
            delivery_markup_start: '',
            delivery_markup_end: ''
        },
        coords: [55.753994, 37.622093],
        errors: {
            show: false,
            data: {}
        },
        error: null,
        cityID: null,
        fab_open: false,
        showPass: false,
        group: localStorage.getItem('group'),
        daysOfWeekOptions: [
            { id: 0, name: 'Понедельник' },
            { id: 1, name: 'Вторник' },
            { id: 2, name: 'Среда' },
            { id: 3, name: 'Четверг' },
            { id: 4, name: 'Пятница' },
            { id: 5, name: 'Суббота' },
            { id: 6, name: 'Воскресенье' },
        ],
        alert: [
            { dates: [] }
        ],
        deliveryIntervalForEdit: {},
        activeTab: 0,
        isOpenBonusesModal: false,
        isOpenIntervalModal: false,
        isOpenSberModal: false,
        isOpenBillModal: false,
        isOpenPaymentTypeModal: false
    }),
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            allCities: 'data/allCities',
            payment_list: 'franchises/payment_list',
            paymentMethods: 'paymentType/paymentMethods',
            selectedPaymentTypes: 'franchises/selected',
            origin: 'ui/origin',
            user: 'auth/user',
        }),
        daysOptions() {
            let days = [];
            for (let i = 0; i < 30; i++) {
                days.push({name: i + 1});
            }
            return days;
        },
        calendarText() {
            return (date1, date2) => {
                if (date1 && date2) {
                    if (moment(date1) <= moment(date2)) {
                        return `${moment(date1).format('DD.MM.YYYY')} - ${moment(date2).format('DD.MM.YYYY')}`;
                    }
                    return `${moment(date2).format('DD.MM.YYYY')} - ${moment(date1).format('DD.MM.YYYY')}`;
                }
                return 'Период действия не указан';
            }
        },
    },
    watch: {
        'franchise.shop': {
            handler(val) {
                clearTimeout(MAP_SEARCH_TIMEOUT_ID);
                let city = this.allCities.find(item => item.id === this.franchise.options.city);
                if (!city) city = { russian_name: '' }

                const text = `${city.russian_name} ${val.street} ${val.house} ${val.building} ${val.appartement}`;
                MAP_SEARCH_TIMEOUT_ID = setTimeout(() => {
                    this.setCoordinatesForMapByText(text);
                }, 1000);
            },
            deep: true
        },
        'app.delivery_markup_start'(val) {
            if (typeof val !== 'string') {
                this.franchise.options.delivery_markup_start = date(val);
            }
        },
        'app.delivery_markup_end'(val) {
            if (typeof val !== 'string') {
                this.franchise.options.delivery_markup_end = date(val);
            }
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await this.$store.dispatch('data/LOAD_ALL_CITIES');
            this.$store.dispatch('paymentType/LOAD_PAYMENT_TYPES');

            if (this.$route.params.ID) {
                const res = await this.$store.dispatch('franchises/LOAD_FRANCHISE_DATA', this.$route.params.ID)

                this.franchise.site.domain = res.domain;
                this.franchise.site.name = res.name;
                this.cityID = res.city;
                this.franchise.options.delivery_enabled = res.delivery_enabled;
                this.franchise.options.city = res.city;
                this.franchise.options.delivery_cost = res.delivery_cost;
                this.franchise.options.delivery_markup_cost = res.delivery_markup_cost;
                this.franchise.options.delivery_tariff = res.delivery_tariff;
                this.franchise.options.delivery_time_start = res.delivery_time_start;
                this.franchise.options.delivery_time_end = res.delivery_time_end;
                this.franchise.options.merchant_username = res.merchant_username;
                this.franchise.options.merchant_password = res.merchant_password;
                this.franchise.options.actual_address = res.actual_address;
                this.franchise.options.legal_address = res.legal_address;
                this.franchise.options.company_name = res.company_name;
                this.franchise.options.tax_id = res.tax_id;
                this.franchise.options.bonus_is_active = res.bonus_is_active;
                this.franchise.options.registration_number = res.registration_number;
                this.franchise.options.bank_checking_account = res.bank_checking_account;
                this.franchise.options.checking_account = res.checking_account;
                this.franchise.options.bank_identification_code = res.bank_identification_code;
                this.franchise.options.bank_receiver = res.bank_receiver;
                this.franchise.options.checking_account = res.checking_account;
                this.franchise.options.kpp_field = res.kpp_field;
                this.franchise.options.director_name = res.director_name;
                this.franchise.options.accountant_name = res.accountant_name;
                this.franchise.options.print_image = res.print_image;
                this.franchise.options.delivery_is_free = res.delivery_is_free;
                this.franchise.options.cost_delivery_free = res.cost_delivery_free;
                this.franchise.options.autocreate_salary = res.autocreate_salary;
                this.franchise.options.periodicity = res.periodicity;
                this.franchise.options.list_of_salary_dates = res.list_of_salary_dates;
                this.franchise.options.allowable_price_difference = res.allowable_price_difference;
                this.franchise.options.max_sales = res.max_sales;
                this.franchise.options.is_date_limit = res.is_date_limit;
                this.franchise.options.min_limit_days_for_create_order = res.min_limit_days_for_create_order;
                this.franchise.options.limit_days_for_create_order = res.limit_days_for_create_order;
                this.franchise.options.hide_all_products = res.hide_all_products;
                this.franchise.options.delivery_around_the_clock = res.delivery_around_the_clock;
                this.franchise.options.delivery_intervals = res.delivery_intervals;
                this.franchise.options.delivery_markup_bytime_city = res.delivery_markup_bytime_city;
                this.franchise.options.delivery_markup_bytime_city_start = res.delivery_markup_bytime_city_start;
                this.franchise.options.delivery_markup_bytime_city_end = res.delivery_markup_bytime_city_end;

                if (res.delivery_markup_start && res.delivery_markup_end) {
                    this.app.delivery_markup_start = res.delivery_markup_start;
                    this.app.delivery_markup_end = res.delivery_markup_end;
                    this.franchise.options.delivery_markup_start = res.delivery_markup_start;
                    this.franchise.options.delivery_markup_end = res.delivery_markup_end;
                }

                if (res.phones.length > 0) {
                    this.franchise.options.phones.splice(0, 1);
                    this.phones_obs.splice(0, 1);
                }

                res.phones.forEach((item) => {
                    this.franchise.options.phones.push({ val: item });
                    this.phones_obs.push({ val: item });
                });

                if (res.bonuses_options.length) {
                    this.franchise.options.bonuses_options = res.bonuses_options;
                    this.franchise.options.bonuses_options.forEach((i) => {
                        i.ratio_when_applied = Math.round(i.ratio_when_applied * 100);
                    })
                } else {
                    this.franchise.options.bonuses_options = [
                        { ratio_when_applied: 0, start_amount: 0, end_amount: 0 }
                    ]
                }

                if (res.header_alerts.length) {
                    this.franchise.options.header_alerts = res.header_alerts;
                    this.alert = res.header_alerts.map((i) => ({ dates: [i.date_activity_start, i.date_activity_end] }));
                }

                this.$store.commit('franchises/SET_PAYMENT_TYPE_LIST', res.enabled_payment_methods);
            } else {
                setTimeout(() => {
                    this.initMap();
                }, 1000);
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
            if (document.activeElement != document.body) document.activeElement.blur(); // сбросить фокус с YimoVueEditor
            window.scrollTo(0, 0)
        }
    },
    beforeDestroy() {
        this.$store.commit('franchises/CLEAR_PAYMENT_TYPE_LIST');
    },
    methods: {
        addPhoneLine() {
            this.phones_obs.push({ val: '' });
        },
        delPhoneLine(index) {
            this.phones_obs.splice(index, 1);
        },
        back() {
            if (localStorage.getItem('group') === 'supervisor') {
                return;
            }
            this.$router.push('/business');
        },
        initMap() {
            franchiseMap = L.map('franchiseMap').setView([63.074865690586634, 102.65625000000001], 3);
            basemapLayer('Streets').addTo(franchiseMap);
            featureLayer({
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            }).addTo(franchiseMap);

            let center = franchiseMap.getCenter();
            let customIconMarker = L.icon({
                iconUrl: require('../assets/img/icon/mapMarker.svg'),
                iconSize: [46, 46],
            });
            this.coords = [center.lat, center.lng];
            franchiseMarker = L.marker(this.coords, { icon: customIconMarker }).addTo(franchiseMap);

            franchiseMap.on('move', (e) => {
                center = franchiseMap.getCenter();
                franchiseMarker.setLatLng(center);
                this.coords = [center.lat, center.lng];
            });
        },
        async saveFranchise() {
            this.$store.dispatch('ui/checkUserEditPermission');
            if (this.phones_obs.find((i) => !i.val)) {
                this.error = { options: { phones: true } }
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Телефон не может быть пустым', type: 'error' })
                return;
            }

            this.$store.commit('ui/SET_PRELOADER', true);

            this.franchise.shop.coords = `${this.coords[0]}, ${this.coords[1]}`;

            if (this.franchise.options.delivery_around_the_clock) {
                this.franchise.options.delivery_time_start = '00:00';
                this.franchise.options.delivery_time_end = '23:59';
            }

            if (this.$route.params.ID) {
                if (this.alert[0].dates[0] && this.alert[0].dates[1]) {
                    if (moment(this.alert[0].dates[0]) <= moment(this.alert[0].dates[1])) {
                        this.franchise.options.header_alerts[0].date_activity_start = this.alert[0].dates[0];
                        this.franchise.options.header_alerts[0].date_activity_end = this.alert[0].dates[1];
                    } else {
                        this.franchise.options.header_alerts[0].date_activity_start = this.alert[0].dates[1];
                        this.franchise.options.header_alerts[0].date_activity_end = this.alert[0].dates[0];
                    }
                }

                const data = {
                    name: this.franchise.site.name,
                    domain: this.franchise.site.domain,
                    delivery_time_start: this.franchise.options.delivery_time_start,
                    delivery_time_end: this.franchise.options.delivery_time_end,
                    delivery_cost: this.franchise.options.delivery_cost,
                    city: this.franchise.options.city,
                    delivery_enabled: this.franchise.options.delivery_enabled,
                    delivery_markup_cost: this.franchise.options.delivery_markup_cost,
                    delivery_tariff: this.franchise.options.delivery_tariff,
                    delivery_markup_start: this.franchise.options.delivery_markup_start,
                    delivery_markup_end: this.franchise.options.delivery_markup_end,
                    merchant_username: this.franchise.options.merchant_username,
                    merchant_password: this.franchise.options.merchant_password,
                    actual_address: this.franchise.options.actual_address,
                    legal_address: this.franchise.options.legal_address,
                    bank_checking_account: this.franchise.options.bank_checking_account,
                    checking_account: this.franchise.options.checking_account,
                    bank_identification_code: this.franchise.options.bank_identification_code,
                    bank_receiver: this.franchise.options.bank_receiver,
                    kpp_field: this.franchise.options.kpp_field,
                    company_name: this.franchise.options.company_name,
                    tax_id: this.franchise.options.tax_id,
                    registration_number: this.franchise.options.registration_number,
                    phones: this.phones_obs.map((item) => item.val),
                    bonus_is_active: this.franchise.options.bonus_is_active,
                    director_name: this.franchise.options.director_name,
                    accountant_name: this.franchise.options.accountant_name,
                    enabled_payment_methods: this.payment_list.map((item) => item.id),
                    delivery_is_free: this.franchise.options.delivery_is_free,
                    cost_delivery_free: this.franchise.options.cost_delivery_free,
                    autocreate_salary: this.franchise.options.autocreate_salary,
                    periodicity: this.franchise.options.periodicity,
                    list_of_salary_dates: this.franchise.options.list_of_salary_dates,
                    allowable_price_difference: this.franchise.options.allowable_price_difference,
                    max_sales: this.franchise.options.max_sales,
                    is_date_limit: this.franchise.options.is_date_limit,
                    min_limit_days_for_create_order: this.franchise.options.min_limit_days_for_create_order,
                    limit_days_for_create_order: this.franchise.options.limit_days_for_create_order,
                    hide_all_products: this.franchise.options.hide_all_products,
                    delivery_around_the_clock: this.franchise.options.delivery_around_the_clock,
                    bonuses_options: JSON.parse(JSON.stringify(this.franchise.options.bonuses_options)),
                    header_alerts: this.franchise.options.header_alerts,
                    delivery_intervals: this.franchise.options.delivery_intervals,
                    delivery_markup_bytime_city: this.franchise.options.delivery_markup_bytime_city,
                    delivery_markup_bytime_city_start: this.franchise.options.delivery_markup_bytime_city_start,
                    delivery_markup_bytime_city_end: this.franchise.options.delivery_markup_bytime_city_end
                };

                data.bonuses_options.forEach((i) => {
                    if (!i.start_amount) { i.start_amount = 0 }
                    if (!i.end_amount) { i.end_amount = 0 }
                    i.ratio_when_applied /= 100;
                })

                try {
                    await this.$store.dispatch('franchises/EDIT_FRANCHISE', { id: this.$route.params.ID, franchise: data })
                    if (localStorage.getItem('group') === 'supervisor') {
                        this.error = [];
                        this.$store.commit('ui/add_item_in_system_notifications', { message: 'Успешно сохранено', type: 'success' })
                    } else {
                        this.$router.push('/business');
                    }
                } catch (err) {
                    this.error = err.data;
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении произошла ошибка', type: 'error' })
                    if (this.error.non_field_errors) {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: this.non_field_errors[0], type: 'error' })
                    }
                    if (this.error && this.error.bonuses_options) {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: 'Проверьте корректность значений бонусной системы', type: 'error' })
                    }
                } finally {
                    this.$store.commit('ui/SET_PRELOADER', false);
                }
            } else {
                if (this.franchise.admin.phone === '') {
                    this.franchise.admin.phone = null;
                }
                if (this.franchise.options.delivery_markup_start === '') {
                    this.franchise.options.delivery_markup_start = null;
                }
                if (this.franchise.options.delivery_markup_end === '') {
                    this.franchise.options.delivery_markup_end = null;
                }
                if (this.franchise.admin.email === '') {
                    delete this.franchise.admin.email;
                }
                if (this.phones_obs.length > 0 && this.phones_obs[0].val !== '') {
                    this.franchise.options.phones = this.phones_obs.map((item) => item.val);
                } else {
                    this.franchise.options.phones = [{ val: '' }];
                }
                if (this.payment_list.length > 0) {
                    this.franchise.options.enabled_payment_methods = this.payment_list.map((item) => item.id);
                }

                if (this.franchise.options.bonuses_options.length) {
                    this.franchise.options.bonuses_options.forEach((i) => {
                        if (i.ratio_when_applied > 1) i.ratio_when_applied /= 100;
                    })
                }

                try {
                    await this.$store.dispatch('franchises/CREATE_FRANCHISE', this.franchise)
                    this.$store.dispatch('franchises/LOAD_FRANCHISES');
                    this.$router.push('/business');
                } catch (err) {
                    this.error = err.data;
                    if (this.error.non_field_errors) {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: this.non_field_errors[0], type: 'error' })
                    }
                } finally {
                    this.$store.commit('ui/SET_PRELOADER', false);
                }
            }
        },
        async removeFranchise() {
            this.$store.dispatch('ui/checkUserEditPermission');
            await this.$store.dispatch('franchises/REMOVE_FRANCHISE', this.$route.params.ID)
            this.$store.dispatch('franchises/LOAD_FRANCHISES');
            if (localStorage.getItem('group') === 'supervisor') {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Успешно удалено', type: 'success' })
                this.exit();
            } else {
                this.$router.push('/business');
            }
        },
        async exit() {
            try {
                await this.$store.dispatch('auth/LOGOUT');
                location.href = '/'
            } catch (error) {
                console.log(error);
            }
        },
        setCoordinatesForMapByText(text) {
            geocode().text(text).run((error, res) => {
                if (error) {
                    console.log(error);
                    return;
                }
                if (res.results.length > 0 && franchiseMap) {
                    franchiseMap.fitBounds(res.results[0].bounds);
                    this.coords = [res.results[0].latlng.lat, res.results[0].latlng.lng];
                }
            });
        },
        async loadImage(event) {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (event.target.files.length === 0) {
                    return;
                }
                this.$store.commit('ui/SET_PRELOADER', true);
                const file = event.target.files[0];
                const image = new FormData();

                image.append("print_image", file);
                event.target.value = "";

                const data = await editFranchise(this.$route.params.ID, image, {
                    "Content-Type": "multipart/form-data"
                });

                this.franchise.options.print_image = data.print_image;
                this.$store.commit('ui/SET_PRELOADER', false);
            } catch (error) {
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки фото', type: 'error' })
            }
        },
        editPaymentType(item) {
            if (item.id == '1306') this.isOpenBillModal = true;
            if (item.id == '1304') this.isOpenSberModal = true;
        },
        addBonusesStep() {
            this.franchise.options.bonuses_options.push({
                ratio_when_applied: 0,
                start_amount: 0,
                end_amount: 0
            });
        },
        openDeliveryIntervalModal(item) {
            if (item !== null) {
                this.deliveryIntervalForEdit = JSON.parse(JSON.stringify(item));
            } else {
                this.deliveryIntervalForEdit = {
                    date: moment().format('YYYY-MM-DD'),
                    name: '',
                    hours: 4,
                    delivery_count: 100,
                    is_soon: true
                }
            }
            this.isOpenIntervalModal = true;
        },
        async saveDeliveryInterval() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                if (!this.deliveryIntervalForEdit.pk) {
                    this.franchise.options.delivery_intervals.push(this.deliveryIntervalForEdit);
                } else {
                    let findIndex = this.franchise.options.delivery_intervals.findIndex(i => i.pk === this.deliveryIntervalForEdit.pk);
                    if (findIndex !== -1) {
                        this.franchise.options.delivery_intervals.splice(findIndex, 1, this.deliveryIntervalForEdit);
                    }
                }

                const res = await this.$store.dispatch('franchises/EDIT_FRANCHISE',
                    { id: this.$route.params.ID, franchise: { delivery_intervals: this.franchise.options.delivery_intervals } })

                this.franchise.options.delivery_intervals = res.delivery_intervals;
                this.isOpenIntervalModal = false;
            } catch (e) {
                console.log(e)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении интервалов произошла ошибка', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        openNavigation() {
            this.$store.commit('ui/TOGGLE_SIDEBAR')
            this.$store.dispatch('ui/toggle_opened_modals_count', 'open');
        },
        moment,
    }
};
</script>

<style lang="sass">
    .franchise-table-body .table-row
        cursor: pointer

    .app-input-label
        flex-shrink: 0
        margin-right: 10px
        color: #666666

    .worktime-input
        width: 65px
        margin-right: 20px

    .ymap-container
        width: 400px
        height: 400px
    #franchiseMap
        width: 100%
        height: 400px
        z-index: 1
        @media screen and (max-width: 550px)
            width: auto
            max-width: 100%
    .error
        margin-left: 15px
        color: #bf5c5c
    .d-flex
        display: flex
        align-items: center

    .app-phone-field
        background: #FFFFFF
        border: 1px solid #F1F2F4
        border-radius: 3px
        font-size: 14px
        line-height: 30px
        padding: 0 0 0 14px
        &:hover
            cursor: pointer
            border: 1px solid #A1A9B4
        &:focus
            border: 1px solid #1a73e8
</style>

/* eslint-disable */
import { getGoodsByType } from '@/api/django';
import {
    getAllTransfers,
    getTransferData,
    createTransfer,
} from '@/api/stock';

const state = {
    transfers: {},
    filters: {
        limit: 20,
        offset: 0,
        shop: null,
        direction: null,
        date_received_from: null,
        date_received_to: null
    },
    activeFilters: [],
    busy: false,
    next: null,
    transfers_goods: [],
    selectedShop: null,
};

const mutations = {
    SET_ALL_TRANSFERS(state, transfers) {
        state.transfers = transfers.results;
        state.next = !!transfers.next;
        state.filters.offset = 20;
    },
    SET_NEXT_PAGE_TRANSFERS(state, transfers) {
        state.transfers.push(...transfers.results);
        state.next = !!transfers.next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    SET_TRANSFER_GOODS(state, goods) {
        state.transfers_goods = goods;
    },
    ADD_GOOD_IN_TRANSFERS(state, good) {
        state.transfers_goods.push({
            count: 1,
            product: good.vendor_code,
            name: good.name,
            category: good.category,
            image: good.images.length && good.images[0].image ? good.images[0].image.preview : null
        });
    },
    REMOVE_GOOD_FROM_TRANSFERS(state, id) {
        state.transfers_goods = state.transfers_goods.filter(good => good.product !== id);
    },
    CLEAR(state) {
        state.transfers_goods = [];
    },
    CHANGE_COUNT_TRANSFERS_GOOD(state, { product, value }) {
        for (let i = 0; i < state.transfers_goods.length; i++) {
            if (state.transfers_goods[i].product === product) {
                state.transfers_goods[i].count = value;
                break;
            }
        }
    },
    SET_SHOP(state, shop) {
        state.selectedShop = shop;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            shop: null,
            direction: null,
            date_received_from: null,
            date_received_to: null
        }
        state.next = null;
    },
};

const actions = {
    async GET_ALL_TRANSFERS({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;
            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;
            
            let filters = await dispatch('prepareFilters');
            commit('SET_ALL_TRANSFERS', await getAllTransfers(filters));
            state.busy = false;
        } catch (error) {
            console.log(error);
        }
    },
    async LOAD_MORE({ state, commit, dispatch }) {
        try {
            if (state.next && !state.busy) {
                state.busy = true;
                let filters = await dispatch('prepareFilters');
                const res = await getAllTransfers(filters);
                commit('SET_NEXT_PAGE_TRANSFERS', res);
            }
        }
        catch(e) {
            console.log(e);
        }
    },
    async GET_TRANSFER_DATA({ commit }, id) {
        try {
            const transfer = await getTransferData(id);
            commit('SET_TRANSFER_GOODS', transfer.products.map(i => { 
                return { ...i.product, count: i.count, product: i.product.vendor_code }
            }));
            return transfer;
        } catch (error) {
            console.log(error);
        }
    },
    async GET_GOODS_FOR_NEW_TRANSFER({ commit }) {
        try {
            commit('equipments/SET_ALL_EQUIPMENTS', await getGoodsByType('equipment'), { root: true });
        } catch (error) {
            console.log(error);
        }
    },
    async CREATE_TRANSFER({ getters }, transfer) {
        try {
            transfer.products = getters['transfers_goods'];
            let data = transfer;
            await createTransfer(data);
        } catch (error) {
            throw error;
        }
    },
    EVENT_HANDLER({ commit, getters }, good) {
        if (getters['selected'](good.vendor_code)) {
            commit('REMOVE_GOOD_FROM_TRANSFERS', good.vendor_code);
        } else {
            commit('ADD_GOOD_IN_TRANSFERS', good);
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([ key, value ]) => {
            if (key && (value || value === false || value === 0) ) { 
                filters[key] = value;
            }
        })
        let activeFilters =  Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    transfers: state => state.transfers,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    transfers_goods: state => state.transfers_goods,
    selected: state => id => !!state.transfers_goods.find(item => item.product === id),
    shop: state => state.selectedShop,
    busy: state => state.busy,
    next: state => state.next,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

/* eslint-disable */
import {
    getGoodsInStock,
    getStockIndications,
} from '@/api/stock';

const state = {
    goods_in_stock: {},
    filters: {
        limit: 20,
        offset: 0,
        product__name: '',
        shop: null,
        category: null
    },
    activeFilters: [],
    next_page: false,
    busy: false,
    selectedShop: 0,
    stockIndications: {}
};

const mutations = {
    SET_GOODS(state, goods) {
        state.goods_in_stock = goods.results;
        state.next_page = goods.next;
        state.filters.offset = goods.next !== null ? +goods.next.split('&').find(string => string.trim().startsWith('offset=')).split('=').pop() : 0;
    },
    SET_NEXT_GOODS(state, { next, results }) {
        state.goods_in_stock.push(...results);
        state.next_page = next;
        state.busy = false;
        state.filters.offset = next !== null ? +next.split('&').find(string => string.trim().startsWith('offset=')).split('=').pop() : 0;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    SET_SHOP(state, shop) {
        state.selectedShop = shop
    },
    CLEAR(state) {
        state.goods_in_stock = {};
        state.next_page = false;
        state.busy = false;
        state.filters = {
            limit: 20,
            offset: 0,
            product__name: '',
            shop: null,
            category: null
        }
        state.activeFilters =  []
    },
    SET_STOCK_INDICATIONS(state, data) {
        state.stockIndications = data;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            product__name: '',
            shop: null,
            category: null
        }
        state.next_page = false;
    },
};

const actions = {
    async GET_GOODS_IN_STOCK({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;
            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;

            let filters = await dispatch('prepareFilters');
            commit('SET_GOODS', await getGoodsInStock(filters));

            state.busy = false;
        } catch (error) {
            console.log(error);
        }
    },
    async GET_NEXT_PAGE_GOODS({ commit, dispatch, state, getters }) {
        if (getters['next'] && !state.busy) {
            state.busy = true;
            try {
                let filters = await dispatch('prepareFilters');
                const res = await getGoodsInStock(filters);
                commit('SET_NEXT_GOODS', res);
            } catch (error) {
                console.log(error);
            }
        }
    },
    async GET_STOCK_INDICATIONS({ commit }) {
        try {
            const indications = await getStockIndications();
            commit('SET_STOCK_INDICATIONS', indications);
        } catch (error) {
            commit('SET_STOCK_INDICATIONS', {});
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([ key, value ]) => {
            if (key && (value || value === false || value === 0) ) {
                filters[key] = value;
            }
        })
        let activeFilters =  Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    goods_in_stock: state => state.goods_in_stock,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    next: state => !!state.next_page,
    busy: state => state.busy,
    shop: state => state.selectedShop,
    stockIndications: state => state.stockIndications
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <div class="help__navigation-footer">
        <router-link
            v-for="(item, index) in links"
            :key="index"
            v-show="item.name"
            :to="item.link || ''"
            class="help__navigation-footer-link"
            :class="{'right': index}"
        >
            <Icon v-if="index === 0">chevron-left</Icon>
            <div class="help__navigation-footer-link-text">
                {{ item.name }}
            </div>
            <Icon v-if="index === 1">chevron-right</Icon>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'HelpNavigationFooter',
    props: {
        links: { type: Array, default: () => [], required: true }
    }
}
</script>

import Vue from 'vue';

import VueInfiniteScroll from 'vue-infinite-scroll';
import YimoVueEditor from 'yimo-vue-editor';
import VueCookies from 'vue-cookies';
import DatePicker from 'vue2-datepicker';
import moment from 'moment'


import AppFilter from '@/components/app/AppFilter';

import Loader from '@/components/app/Loader';
import AppCreateButton from '@/components/app/AppCreateButton';
import EmptyTable from '@/components/app/EmptyTable';
import SortTable from '@/components/app/SortTable';
import InputCustom from '@/components/inputs/InputCustom';
import TextareaCustom from '@/components/inputs/TextareaCustom';
import Checkbox from '@/components/inputs/Checkbox';
import Chips from '@/components/inputs/Chips';
import InputSelect from '@/components/inputs/InputSelect';
import HorizontalPicker from '@/components/inputs/HorizontalPicker';
import InputCounter from '@/components/inputs/InputCounter';
import StatusSelect from '@/components/inputs/StatusSelect';
import Button from '@/components/buttons/Button';
import Header from '@/components/layout/Header';
import TabItem from '@/components/layout/TabItem';
import Row from '@/components/layout/Row';
import Col from '@/components/layout/Col';
import Icon from '@/components/layout/Icon';
import ContentPreloader from '@/components/layout/ContentPreloader';
import Search from '@/components/app/Search';
import ProductsCategories from '@/components/app/goods/ProductsCategories';
import UniversalModal from '@/components/modal/UniversalModal'
import UniversalModalHeader from '@/components/modal/UniversalModalHeader'

import 'normalize.css';
import 'vue2-datepicker/index.css';
import 'vuetify/dist/vuetify.min.css';
import '@/assets/style/style.scss';
import '@/assets/style/icon.css';

moment.locale('ru');

Vue.component('date-picker', DatePicker);
Vue.component('app-filter', AppFilter);

Vue.component('Loader', Loader);
Vue.component('app-create-button', AppCreateButton);
Vue.component('empty-table', EmptyTable);
Vue.component('sort-table', SortTable);
Vue.component('InputCustom', InputCustom);
Vue.component('TextareaCustom', TextareaCustom);
Vue.component('Chips', Chips);
Vue.component('InputSelect', InputSelect);
Vue.component('InputCounter', InputCounter);
Vue.component('StatusSelect', StatusSelect);
Vue.component('HorizontalPicker', HorizontalPicker);
Vue.component('Button', Button);
Vue.component('Checkbox', Checkbox);
Vue.component('Header', Header);
Vue.component('TabItem', TabItem);
Vue.component('Row', Row);
Vue.component('Col', Col);
Vue.component('Icon', Icon);
Vue.component('ContentPreloader', ContentPreloader);
Vue.component('Search', Search);
Vue.component('ProductsCategories', ProductsCategories);
Vue.component('UniversalModal', UniversalModal);
Vue.component('UniversalModalHeader', UniversalModalHeader);

Vue.use(VueInfiniteScroll);
Vue.use(VueCookies);
Vue.use(YimoVueEditor, {
    config: {
        uploadImgUrl: null,
        lang: {
            bold: 'Жирный',
            underline: 'Подчеркнутый',
            italic: 'Наклонный',
            forecolor: 'Цвет',
            bgcolor: 'Цвет фона',
            strikethrough: 'Перечеркнутый',
            eraser: 'Удалить форматирование',
            source: 'Исходный код',
            quote: 'Цитата',
            fontfamily: 'Шрифт',
            fontsize: 'Размер шрифта',
            head: 'Заголовок',
            orderlist: 'Нумерованный список',
            unorderlist: 'Список',
            alignleft: 'Выровнять влево',
            aligncenter: 'Выровнять по центру',
            alignright: 'Выровнять вправо',
            link: 'Вставить ссылку',
            linkTarget: 'Модификаторы',
            text: 'Текст',
            submit: 'Применить',
            cancel: 'Отмена',
            unlink: 'Удалить ссылку',
            table: 'Таблица',
            emotion: 'Смайлики',
            img: 'Изображение',
            // uploadImg: 'Upload',
            linkImg: 'Ссылка',
            video: 'Видео',
            width: 'width',
            height: 'height',
            location: 'Location',
            loading: 'Loading',
            searchlocation: 'search',
            dynamicMap: 'Dynamic',
            clearLocation: 'Clear',
            langDynamicOneLocation: 'Only one location in dynamic map',
            insertcode: 'Insert Code',
            undo: 'Назад',
            redo: 'Вперед',
            fullscreen: 'Полный экран',
            openLink: 'Открыть ссылку',
            uploadPlaceTxt: 'загрузка__',
            uploadTimeoutPlaceTxt: 'таймаут загрузки__',
            uploadErrorPlaceTxt: 'Ошибка загрузки__'
        },
        menus: [
            'source',
            '|',
            'bold',
            'underline',
            'italic',
            'strikethrough',
            'eraser',
            'forecolor',
            'bgcolor',
            '|',
            // 'quote',
            'fontfamily',
            'fontsize',
            'unorderlist',
            'orderlist',
            'alignleft',
            'aligncenter',
            'alignright',
            '|',
            'link',
            'unlink',
            'table',
            // 'emotion',
            '|',
            // 'img',
            // 'location',
            // 'insertcode',
            '|',
            'undo',
            'redo',
            // 'fullscreen'
        ]
    }
});

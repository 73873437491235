<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial v-model="fab_open" class="btn__floating custom-speed-dial" close-on-click fixed>
            <template v-slot:activator>
                <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item @click="readAll">
                        <v-list-item-icon class="mr-3">
                            <v-icon>mdi-check-all</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Прочитать все</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="group === 'supervisor' || group === 'administrators'" @click="$router.push('/notifications/create')">
                        <v-list-item-icon class="mr-3">
                            <v-icon>mdi-bell-plus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Уведомить</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                title="Мои уведомления"
                for-listing
                :show-save-btn="false"
                filter
                :filterIndicator="!!activeFilters.length"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'notifications-filter')"
            />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="user_notifications && user_notifications.length" class="listing-table">
                        <a
                            v-for="(item, index) in user_notifications"
                            :key="item.id"
                            class="listing-table__row"
                            :class="{'not-seen' : !item.seen}"
                            :style="{'background': !item.seen ? item.color : '' }"
                            @click.prevent="readMsg(item, index)"
                        >
                            <div
                                class="listing-table__col listing-table__col--image listing-table__col--mobile-inline"
                                :style="width > 1024 ? 'padding-top: 14px;' : ''"
                            >
                                <div class="listing-table__image listing-table__image--mini">
                                    <img v-if="item.image_link" :src="item.image_link">
                                    <v-icon v-else color="#fff">
                                        mdi-bell
                                    </v-icon>
                                </div>
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-inline"
                                :class="{'pt-0 mr-0': width <= 1024}"
                                :style="width > 1024 ? 'width: calc(100% - 140px)' : 'width: calc(100% - 92px)'"
                            >
                                <div class="user-notification__info-wrap" :class="{ 'full' : opened.includes(item.id) }">
                                    <div class="user-notification__info">
                                        <div class="user-notification__date">
                                            {{ moment(item.datetime).format('DD.MM.YYYY HH:mm') }}
                                        </div>
                                        <div
                                            class="user-notification__text"
                                            v-html="item.title + '<br>' + item.text"
                                        />
                                    </div>
                                    <v-btn class="user-notification__info-chevron" icon @click.stop="openHandler(item.id)">
                                        <Icon color="#8AACCD">
                                            down
                                        </Icon>
                                    </v-btn>
                                </div>
                            </div>

                            <div
                                class="listing-table__col listing-table__col--mobile-inline justify-start"
                                :style="width > 1024 ? 'width: 60px;padding-top:20px;' : 'padding-top:10px;'"
                            >
                                <v-btn
                                    v-if="item.order_id"
                                    title="Открыть заказ"
                                    icon
                                    @click.stop="openInNewWindow(item.order_id, 'orders')"
                                >
                                    <Icon color="#0D63F3">new-window</Icon>
                                </v-btn>
                                <v-btn
                                    v-if="item.user_id"
                                    title="Открыть пользователя"
                                    icon
                                    @click.stop="openInNewWindow(item.user_id, 'users')"
                                >
                                    <Icon color="#0D63F3">new-window</Icon>
                                </v-btn>
                                <v-btn
                                    v-if="(item.title.includes('списание') || item.title.includes('к отзыву')) && item.url"
                                    icon
                                    @click.stop="openInNewWindow(item.url, 'url')"
                                >
                                    <Icon color="#0D63F3">new-window</Icon>
                                </v-btn>
                            </div>
                        </a>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="user_notifications && !user_notifications.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" height="76" />
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { readAllNotify, markSeenNotification } from '@/api/notifications';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheUserNotifications',
    metaInfo: {
        title: 'Мои уведомления'
    },
    components: { Spinner },
    data() {
        return {
            fab_open: false,
            opened: []
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            user_notifications: 'notifications/user_notifications',
            notSeenMessages: 'notifications/notSeenMessages',
            activeFilters: 'notifications/activeFilters',
            next: 'notifications/next',
            busy: 'notifications/busy',
            user: 'auth/user',
            preloader: 'ui/preloader'
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            this.$store.commit('notifications/CLEAR_USER_NOTIFICATIONS');

            const user = JSON.parse(localStorage.getItem('user'));
            this.$store.dispatch('geo/GET_CITY_SHOPS', user.city);
            
            this.$store.dispatch('orders/GET_ORDER_STATUSES');

            const notifications = await this.$store.dispatch('notifications/GET_USER_NOTIFICATIONS', { field: 'seen', value: false });
            if (!notifications.length) {
                await this.$store.dispatch('notifications/GET_USER_NOTIFICATIONS', { field: 'seen', value: null });
                this.$store.commit('notifications/SET_NOT_SEEN_COUNT', 0);
            }
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        this.$store.commit('notifications/CLEAR_USER_NOTIFICATIONS');
    },
    methods: {
        readMsg(item, index) {
            if (!item.seen) {
                const msgCount = this.notSeenMessages - 1;
                this.$store.commit('notifications/SET_NOT_SEEN_COUNT', msgCount);

                let arr = this.user_notifications;
                arr[index].seen = true;
                this.$store.commit('notifications/SET_USER_NOTIFICATIONS', arr);

                let data;
                if (typeof item.id === 'object') {
                    data = { notifications: item.id };
                } else {
                    data = { notifications: [item.id] };
                }

                markSeenNotification(data);
            }
        },
        openInNewWindow(id, page) {
            if (page === 'url') {
                window.open(`${id}`, '_blank');
            } else {
                window.open(`/${page}/${id}`, '_blank');
            }
        },
        async readAll() {
            await readAllNotify();
            this.$store.dispatch('notifications/GET_USER_NOTIFICATIONS', { field: 'seen', value: '' });
            this.$store.commit('notifications/SET_NOT_SEEN_COUNT', 0);
        },
        IsJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        loadMore() {
            this.$store.dispatch('notifications/GET_NEXT_PAGE');
        },
        openHandler(id) {
            if (this.opened.includes(id)) {
                this.opened = this.opened.filter((a) => a !== id);
            } else {
                this.opened.push(id);
            }
        },
        moment
    },
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Настройки цветочной подписки"
                left-btn="menu"
                :show-left-btn="width <= 1024"
                :show-save-btn="!authUser.read_only"
                @click:left="openNavigation"
                @click:save="saveSettings"
            />
            <div class="page-container">
                <div class="page-columns">
                        <div class="page-big-column">
                            <div class="page__info-group-title">
                                Доступные цветы
                            </div>
                            <div v-show="!preloader" class="listing-table__wrap">
                                <div v-if="settings.categories.length" class="listing-table">
                                    <div class="listing-table__row listing-table__row--head">
                                        <div class="listing-table__col" style="width: calc((100% - 68px) / 4)">Название</div>
                                        <div class="listing-table__col" style="width: calc((100% - 68px) / 4)">Сортировка</div>
                                        <div class="listing-table__col" style="width: calc((100% - 68px) / 4)">Активность</div>
                                        <div class="listing-table__col" style="width: 68px; flex: 0 0 auto;" />
                                    </div>
                                    <a
                                        v-for="(item, index) in settings.categories"
                                        :key="item.id"
                                        class="listing-table__row"
                                        @click.prevent="openFlowerCategoriesModal(item)"
                                    >
                                        <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: calc((100% - 68px) / 4)' : ''">
                                            {{ item.name }}
                                        </div>
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 68px) / 4)' : ''">
                                            <div class="listing-table__col-label">Сортировка</div>
                                            {{ item.priority }}
                                        </div>
                                        <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: calc((100% - 68px) / 4)' : ''">
                                            <div class="listing-table__col-label">Статус</div>
                                            <span :style="{'color': item.is_active ? '#36B687' : '#EF6A6E'}">
                                                {{ item.is_active ? 'Активна' : 'Не активна' }}
                                            </span>
                                        </div>
                                        <div
                                            class="listing-table__col listing-table__col--mobile-inline ml-a"
                                            :style="width > 1024 ? 'width: 68px; flex: 0 0 auto;' : ''"
                                        >
                                            <Icon color="#0D63F3" @click.stop.prevent="settings.categories.splice(index, 1)">
                                                trash
                                            </Icon>
                                        </div>
                                    </a>
                                </div>
                                <div v-else>Список доступных цветов пуст</div>
                            </div>
                            <ContentPreloader v-show="preloader" type="table" />
                        </div>
                        <div class="page-small-column page-small-column--mobile-top">
                            <div class="checkbox-group">
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="settings.is_active"
                                        label="Активность"
                                        @change="settings.is_active = !settings.is_active"
                                    />
                                </div>
                            </div>
                            <Button
                                class="mt-4"
                                text block compact
                                icon-left="folder-plus"
                                @click.prevent="openFlowerCategoriesModal(null)"
                            >
                                Добавить цветы
                            </Button>
                        </div>
                </div>
            </div>
        </div>
        <UniversalModal v-model="isCategoryModalOpen" size="big">
            <UniversalModalHeader
                title="Настройка доступных цветов"
                left-btn="big-left"
                right-btn="check"
                @click:left="isCategoryModalOpen = false"
                @click:right="saveFlowerCategory"
            />
            <div class="universal-modal__content">
                <div class="page-columns">
                    <div class="page-big-column">
                        <Row>
                            <Col>
                                <InputCustom
                                    v-model="flowerCategoryForEdit.name"
                                    label="Название"
                                    :disabled="!!flowerCategoryForEdit.id"
                                />
                            </Col>
                            <Col>
                                <InputCustom
                                    v-model.number="flowerCategoryForEdit.priority"
                                    type="number"
                                    label="Сортировка"
                                />
                            </Col>
                            <Col>
                                <ImageLoader
                                    v-show="!preloader"
                                    :value="flowerCategoryForEdit.image ? flowerCategoryForEdit.image.image.preview : ''"
                                    @change="saveImage($event)"
                                />

                                <ContentPreloader v-show="preloader" height="150" />
                            </Col>
                        </Row>
                    </div>
                    <div class="page-small-column page-small-column--mobile-top">
                        <div class="checkbox-group">
                            <div class="checkbox-group__item">
                                <Checkbox
                                    :value="flowerCategoryForEdit.is_active"
                                    label="Активность"
                                    @change="flowerCategoryForEdit.is_active = !flowerCategoryForEdit.is_active"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { getFlowerSubscriptionSettings, saveFlowerSubscriptionSettings, fetchImageID } from '@/api/django'

import ImageLoader from '@/components/app/ImageLoader'


export default {
    name: "TheFlowerSubscriptionSettings",
    metaInfo() {
        return { title: "Настройки цветочной подписки" };
    },
    components: { ImageLoader },
    data() {
        return {
            settings: {
                is_active: true,
                categories: []
            },
            flowerCategoryForEdit: {},
            isCategoryModalOpen: false
        };
    },
    computed: {
        ...mapGetters({
            width: "ui/width",
            authUser: "auth/user",
            preloader: "ui/preloader",
            franchiseTariff: "franchises/franchiseTariff",
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
    },
    created() {
        this.$store.commit("ui/SET_PRELOADER", true);
    },
    mounted() {
        try {
            const interval = setInterval(async () => {
                if (this.franchiseTariff.site_id) {
                    clearInterval(interval);
                    this.settings = await getFlowerSubscriptionSettings(this.franchiseTariff.site_id);
                    this.$store.commit("ui/SET_PRELOADER", false);
                }
            }, 200);
            setTimeout(() => {
                if (!this.franchiseTariff.site_id) {
                    clearInterval(interval);
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Данные не были загружены. Попробуйте перезагрузить страницу', type: 'error' })
                }
            }, 20000);
        }
        catch (error) {
            console.log(error);
            this.$store.commit("ui/SET_PRELOADER", false);
        }
    },
    methods: {
        async saveSettings() {
            this.$store.dispatch("ui/checkUserEditPermission");
            try {
                this.$store.commit("ui/SET_PRELOADER", true);

                const settings = JSON.parse(JSON.stringify(this.settings));
                settings.categories = settings.categories.map(item => ({ ...item, image: item.image.id }));

                const res = await saveFlowerSubscriptionSettings(settings, this.franchiseTariff.site_id);

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Настройки сохранены', type: 'success' })
                return res;
            }
            catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении настроек произошла ошибка', type: 'error' })
            }
            finally {
                this.$store.commit("ui/SET_PRELOADER", false);
            }
        },
        async saveFlowerCategory() {
            try {
                if (!this.flowerCategoryForEdit.name || !this.flowerCategoryForEdit.image) {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Все поля обязательны для заполнения', type: 'error' })
                    return;
                }
                this.$store.commit("ui/SET_PRELOADER", true);
                if (!this.flowerCategoryForEdit.id) {
                    this.settings.categories.push(this.flowerCategoryForEdit);
                }
                else {
                    let findIndex = this.settings.categories.findIndex(i => i.id === this.flowerCategoryForEdit.id);
                    if (findIndex !== -1) {
                        this.settings.categories.splice(findIndex, 1, this.flowerCategoryForEdit);
                    }
                }
                this.$store.commit("ui/CLOSE_MODAL", "modal-flowers-subscription-categories");
                const res = await this.saveSettings();
                this.settings.categories = res.categories;
            }
            catch (e) {
                console.log(e);
                this.$store.commit("ui/SET_PRELOADER", false);
            }
        },
        async saveImage(event) {
            this.$store.dispatch("ui/checkUserEditPermission");
            try {
                this.$store.commit("ui/SET_PRELOADER", true);
                if (!event.target.files.length) {
                    return;
                }
                const data = new FormData();
                data.append("image", event.target.files[0]);
                event.target.value = "";
                this.flowerCategoryForEdit.image = await fetchImageID("POST", "/productsubscription/image/", { "Content-Type": "multipart/form-data" }, data);
            }
            catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении изображения произошла ошибка', type: 'error' })
            }
            finally {
                this.$store.commit("ui/SET_PRELOADER", false);
            }
        },
        openFlowerCategoriesModal(item) {
            if (item !== null) {
                this.flowerCategoryForEdit = JSON.parse(JSON.stringify(item));
            }
            else {
                this.flowerCategoryForEdit = {
                    is_active: true,
                    name: "",
                    image: null,
                    priority: 1
                };
            }
            this.isCategoryModalOpen = true;
        },
        openNavigation() {
            this.$store.commit("ui/TOGGLE_SIDEBAR");
            this.$store.dispatch("ui/toggle_opened_modals_count", "open");
        }
    },
}
</script>

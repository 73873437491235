/* eslint-disable */
import axios from 'axios'
import router from '@/router.js'

const state = {
    url: `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_HOST_CHAT}`,
    imageUrl: `${process.env.VUE_APP_API_STORAGE}chats`,
    socket: {
        socket: '',
        isConnected: false,
        wsReady: false,
    },
    user_id: '',
    list: [],
    selectedChat: {},
    messages: [],
    unreadCount: 0,
    limit: 30,
    offset: 0,
    step: 1,
    more: true,
    orderChat: {},
    orderListChats: [],
    allUnseenMessages: 0,
    chatConnectionLost: false
}

const mutations = {
    SOCKET_ONCLOSE(state) {
        state.socket.isConnected = false
        state.socket.wsReady = false
        state.socket.socket.close(1000, 'Чаты отключены');
    },
    SET_WS_READY(state, value) {
        state.socket.wsReady = value
    },
    SET_USER_ID(state, id) {
        state.user_id = id
    },
    SET_MORE(state, status) {
        state.more = status
    },
    SET_OFFSET(state) {
        state.offset += state.limit
    },
    RESET_OFFSET(state) {
        state.offset = 0
    },
    SET_CHAT_LIST(state, list) {
        state.list = list
    },
    ADD_TO_LIST(state, list) {
        state.list = state.list.concat(list)
    },
    SET_SELECTED_CHAT(state, chat) {
        state.selectedChat = chat
        if (chat && chat.unseen_count) state.unreadCount -= chat.unseen_count
    },
    SET_SELECTED_CHAT_NAME(state, name) {
        state.selectedChat.name = name
    },
    SET_UNREAD(state, count) {
        state.unreadCount = count
    },
    SET_STEP(state, step) {
        state.step = step;
    },
    ADD_UNREAD(state, number) {
        state.unreadCount += number
    },
    ADD_MESSAGE(state, message) {
        state.messages.push(message)
    },
    SET_MESSAGES(state, messages) {
        if (messages.length) state.messages = messages.reverse()
        else state.messages = messages
    },
    MOVE_UP(state, chat) {
        function moveItem(from, to) {
            let f = state.list.splice(from, 1)[0]
            state.list.splice(to, 0, f)
        }
        moveItem(state.list.indexOf(chat), 0)
    },
    SET_ORDER_CHAT(state, chat) {
        state.orderChat = chat;
    },
    SET_ORDER_LIST_CHATS(state, data) {
        state.orderListChats = data;
    },
    SET_ALL_UNSEEN_MESSAGES(state, count) {
        state.allUnseenMessages = count;
    },
    SET_CHAT_CONNECTION_LOST(state, value) {
        state.chatConnectionLost = value;
    }
}

const actions = {
    INIT({ state, commit, dispatch }) {
        connect();
        dispatch('ON_MESSAGE');

        function connect() {
            state.socket.socket = new WebSocket(process.env.VUE_APP_WS_HOST_CHAT)
            state.socket.socket.onopen = () => {
                let login = {
                    "jsonrpc": "2.0",
                    "method": "login",
                    "id": 12,
                    "params": {
                        "token": window.localStorage.getItem('token').split(' ')[1]
                    }
                }
                state.socket.socket.send(JSON.stringify(login))
            }

            state.socket.isConnected = true;

            state.socket.socket.onclose = (event) => {
                state.socket.isConnected = false;
                commit('SET_WS_READY', false);
                if (localStorage.getItem('token')) {
                    console.log('Закрытие сокетов чата', event.code, event);
                    if (!event.wasClean) {
                        console.log('Чаты закрыты некорректно');
                        commit('SET_CHAT_CONNECTION_LOST', true);
                        let time = 3000 + Math.random() * (6000 - 3000); // Рандомное время переподключения от 3 до 6 секунд.
                        setTimeout(() => {
                            console.log('Переподключение сокетов чата')
                            connect()
                            dispatch('ON_MESSAGE')
                        }, time)
                    }
                }
            }

            state.socket.socket.onerror = (error) => {
                console.log('Ошибка сокетов чата:', error );
            }
        }
    },
    ON_MESSAGE({ state, rootGetters, commit, dispatch }) {
        state.socket.socket.onmessage = (res) => {
            let data = JSON.parse(res.data)

            if (data.method === 'login' && data.result && data.result.status === 'ok') {
                commit('SET_WS_READY', true);
                commit('SET_CHAT_CONNECTION_LOST', false);
                dispatch('LOAD_CHATS')
                // if (router.app._route.path !== '/orders') dispatch('LOAD_ORDER_LIST_CHATS', []);
            } else if (data.method === 'login' && data.error) {
                commit('SET_CHAT_CONNECTION_LOST', true);
            }

            if (data.method === 'load_chats' && data.result) {
                if (data.result.length < state.limit) {
                    commit('SET_MORE', false)
                }

                if (state.offset > 0) {
                    commit('ADD_TO_LIST', data.result)
                    data.result.forEach(c => {
                        if (c.unseen_count) commit('ADD_UNREAD', c.unseen_count)
                    })
                } else {
                    commit('SET_CHAT_LIST', data.result)
                    commit('SET_UNREAD', 0)
                    data.result.forEach(c => {
                        if (c.unseen_count) commit('ADD_UNREAD', c.unseen_count)
                    })
                    // if (Object.keys(state.selectedChat).length === 0 && state.selectedChat.constructor === Object) {
                    //     commit('SET_SELECTED_CHAT', data.result[0])
                    //     dispatch('LOAD_MESSAGES')
                    // }
                }
            }

            if (data.method === 'chat_search') {
                if (data.result.length < state.limit) {
                    commit('SET_MORE', false);
                    commit('SET_STEP', 1)
                } else {
                    commit('SET_MORE', true);
                }

                if (state.step === 1) {
                    commit('SET_CHAT_LIST', data.result);
                } else {
                    commit('ADD_TO_LIST', data.result)
                }

                if (data.result && data.result.length === 1 && ['order', 'client', 'certificate'].includes(router.app._route.name)) {
                    commit('SET_ORDER_CHAT', data.result[0]);
                }
            }

            if (data.method === 'load_messages' && data.result) {
                commit('SET_MESSAGES', data.result)
            }

            if (data.method === 'new_message' && data.result) {
                const notification = new Audio('/audio/notification.wav');

                let chatForOrder = data.result.chat;
                let orderID = '';
                if (chatForOrder && chatForOrder.order_id) {
                    orderID = chatForOrder.order_id;
                } else if (chatForOrder && chatForOrder.name && chatForOrder.name.includes('Заказ')) {
                    orderID = chatForOrder.name.split(' ')[1].slice(1);
                }
                if (orderID) {
                    let allUnseenMessages = state.allUnseenMessages;
                    allUnseenMessages += 1;
                    commit('SET_ALL_UNSEEN_MESSAGES', allUnseenMessages);
                }

                if (router.app._route.path === '/orders') {
                    let orders = rootGetters['orders/orders'];
                    orders.forEach((item) => {
                        if (orderID && item.id === orderID) {
                            item.unseen_count += 1;
                        }
                    });
                    commit('orders/SET_ORDERS_RESULTS', orders, { root: true });
                }

                // if (state.selectedChat.id === data.result.chat_id) {
                //     dispatch('MARK_READ', data.result.chat_id)
                // }

                const chat = state.list.filter(c => c.id == data.result.chat_id)
                if (chat.length > 0 && data.result.id == chat[0].last_message.id) return
                if (chat.length === 0) {
                    notification.play()
                    dispatch('LOAD_CHATS')
                } else if (data.result.chat_id == state.selectedChat.id) {
                    if (data.result.user_id != state.user_id) {
                        try {
                            notification.play()
                        } catch (error) {
                            console.log(error)
                        }
                        dispatch('MARK_READ', data.result.chat_id)
                    }

                    state.list.map((c) => {
                        if (c.id == data.result.chat_id) {
                            c.last_message = data.result;
                        }
                        return c
                    })

                    commit('ADD_MESSAGE', data.result)
                } else {
                    if (!data.result.from_staff && !data.result.is_auto) {
                        notification.play();
                        commit('ADD_UNREAD', 1);
                    }

                    state.list.map((c) => {
                        if (c.id == data.result.chat_id) {
                            c.last_message = data.result
                            if (!data.result.from_staff && !data.result.is_auto) c.unseen_count++
                            commit('MOVE_UP', c)
                        }
                        return c
                    })
                }

            }

            if (data.method === 'new_chat' && data.result) {
                let chat = {
                    id: data.result.id,
                    name: data.result.name,
                    site_id: data.result.site_id,
                }
                if (data.result.name === 'Гость') chat.user_id = 0
                else chat.user_id = data.result.user_id
            }

            if (data.method === 'mark_read') {
                if (data.result.chat_id == state.selectedChat.id) {
                    state.selectedChat.last_message.seen = true
                    state.selectedChat.unseen_count = 0
                    state.messages.forEach(m => {
                        m.seen = true
                    })

                    state.list.forEach(chat => {
                        if (chat.id === state.selectedChat.id) {
                            chat.unseen_count = 0;
                        }
                    })
                } else {
                    state.list = state.list.map((c) => {
                        if (c.id == data.result.chat_id) {
                            c.last_message.seen = true;
                            commit('SET_UNREAD', state.unreadCount - c.unseen_count);
                            c.unseen_count = 0;
                        }
                        return c
                    })
                }
                // dispatch('LOAD_ORDER_LIST_CHATS',[]);
            }

            if (data.method === 'upgrade_anonymous_chat') {
                if (state.selectedChat.id === data.result.chat_id) {
                    commit('SET_SELECTED_CHAT_NAME', data.result.name)
                }
                dispatch('LOAD_CHATS')
            }
            if (data.method === 'get_chats_unseen_messages') {
                commit('SET_ALL_UNSEEN_MESSAGES', data.result.count_of_all_unseen_message);
                if (router.app._route.path === '/orders') {
                    let orders = rootGetters['orders/orders'];
                    let ordersListChat = data.result.data;
                    orders.forEach((item) => {
                        let findOrder = ordersListChat.find((chat) => {
                            let orderID = null;
                            if (chat.order_id) {
                                orderID = chat.order_id;
                            } else if (chat.name && chat.name.includes('Заказ')) {
                                orderID = chat.name.split(' ')[1].slice(1);
                            }
                            return orderID == item.id;
                        });
                        if (findOrder) {
                            item.unseen_count = findOrder.unseen_count;
                        }
                    });
                    commit('orders/SET_ORDERS_RESULTS', orders, { root: true });
                }
            }
        }
    },
    LOAD_CHATS ({ state }) {
        let request = {
            "jsonrpc": "2.0",
            "method": "load_chats",
            "id": 12,
            "params": {
                "limit": state.limit,
                "offset": state.offset,
                // "combined_type": "True"
            }
        }
        state.socket.socket.send(JSON.stringify(request))
    },
    LOAD_MESSAGES({ state }) {
        let request = {
            "jsonrpc": "2.0",
            "method": "load_messages",
            "id": 12,
            "params": {
                "chat_id": state.selectedChat.id,
                "limit": 9999,
                "offset": 0
            }
        }
        state.socket.socket.send(JSON.stringify(request))
    },
    SEND_MESSAGE({ state }, msg) {
        let request = {
            "jsonrpc": "2.0",
            "method": "new_message",
            "id": 12,
            "params": {
                "chat_id": state.selectedChat.id,
                "type": 'text',
                "text": msg
            }
        }
        state.socket.socket.send(JSON.stringify(request))
    },
    async SELECT_CHAT({ commit, dispatch }, chat) {
        let client = {};
        if (chat.user_id) {
            client = await dispatch('users/GET_USER', chat.user_id, { root: true });
        }

        commit('SET_SELECTED_CHAT', {...chat, client});
        if (chat.unseen_count) {
            dispatch('MARK_READ')
        }
        dispatch('LOAD_MESSAGES')
    },
    MARK_READ({ state }, id = state.selectedChat.id) {
        let request = {
            "jsonrpc": "2.0",
            "method": "mark_read",
            "id": 12,
            "params": {
                "chat_id": id
            }
        }
        state.socket.socket.send(JSON.stringify(request))
    },
    SEARCH_CHATS({ state }, { str, limit, type }) {
        let request = {
            "jsonrpc": "2.0",
            "method": "chat_search",
            "id": 12,
            "params": {
                "limit": limit,
                "offset": 0,
                "query": str,
                // "combined_type": type
            }
        };
        state.socket.socket.send(JSON.stringify(request))
    },
    SEARCH_CHATS_IN_ORDER({ state }, { id, limit }) {
        let request = {
            "jsonrpc": "2.0",
            "method": "chat_search",
            "id": 12,
            "params": {
                "limit": limit,
                "offset": 0,
                "order_id": id,
            }
        };
        state.socket.socket.send(JSON.stringify(request))
    },
    SEARCH_CHAT_BY_USER_ID({ state }, { id, limit }) {
        let request = {
            "jsonrpc": "2.0",
            "method": "chat_search",
            "id": 12,
            "params": {
                "limit": limit,
                "offset": 0,
                "user_id": id,
            }
        };
        if (state.socket.socket.readyState === 1) {
            state.socket.socket.send(JSON.stringify(request))
        }
    },
    LOAD_MORE_SEARCH({ state, commit }, str) {
        commit('SET_STEP', state.step + 1);
        let request = {
            "jsonrpc": "2.0",
            "method": "chat_search",
            "id": 12,
            "params": {
                "limit": state.limit,
                "offset": state.step * state.limit,
                "query": str,
                // "combined_type": 'True'
            }
        };
        state.socket.socket.send(JSON.stringify(request));
    },
    async FILE_UPLOAD({ dispatch, state }, { file, token }) {
        try {
            const { data } = await axios({
                method: 'post',
                url: `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_HOST_CHAT}/upload_file/`,
                data: file,
                headers: {
                    'Authorization': token
                }
            })
            dispatch('SEND_MESSAGE', data.file)
        } catch (error) {
            console.log(error);
            if (error.response) {
                console.log(error.response.data)
            }
        }
    },
    FILTER_CHATS({ state, commit }, filter) {
        commit('RESET_OFFSET')
        state.socket.socket.send(JSON.stringify(loadChats(filter)))
    },
    LOAD_MORE({ state, commit }, filter) {
        commit('SET_OFFSET')
        state.socket.socket.send(JSON.stringify(loadChats(filter)))
    },
    LOAD_ORDER_LIST_CHATS({ state }, data) {
        let request = {
            "jsonrpc": "2.0",
            "method": "get_chats_unseen_messages",
            "id": 12,
            "params": {
                chats_ids: data
            }
        }
        state.socket.socket.send(JSON.stringify(request))
    },
}

function loadChats(filter) {
    if (filter === 'all') {
        let request = {
            "jsonrpc": "2.0",
            "method": "load_chats",
            "id": 1,
            "params": {
                "limit": state.limit,
                "offset": state.offset,
                // "combined_type": "True"
            }
        }
        return request
    } else {
        let request = {
            "jsonrpc": "2.0",
            "method": "load_chats",
            "id": 1,
            "params": {
                "limit": state.limit,
                "offset": state.offset,
                // "combined_type": "True"
                "type": filter
            }
        }
        return request
    }
}

const getters = {
    wsReady: state => state.socket.wsReady,
    messages: state => state.messages,
    chatList: state => state.list,
    selectedChat: state => state.selectedChat,
    more: state => state.more,
    user_id: state => state.user_id,
    limit: state => state.limit,
    unread: state => state.unreadCount,
    step: state => state.step,
    orderChat: state => state.orderChat,
    orderListChats: state => state.orderListChats,
    allUnseenMessages: state => state.allUnseenMessages,
    chatConnectionLost: state => state.chatConnectionLost,
    fastAnswers: (state, getters, rootState, rootGetters) => {
        return [
            {
                title: 'Приветствие',
                message: (new Date().getHours() < 5 ? 'Доброй ночи!' :
                    new Date().getHours() < 12 ? 'Доброе утро!' :
                        new Date().getHours() < 18 ? 'Добрый день!' : 'Добрый вечер!') + ' Чем могу помочь?'
            },
            {
                title: 'Спросить номер заказа',
                message: 'Уточните, пожалуйста, номер Вашего заказа, чтобы я могла идентифицировать его.'
            },
            {
                title: 'Время работы мастерских',
                message: `Режим работы:
    ул. Ленина, 14 - с 8:00 до 22:00
    пр. Карла Маркса, 17 - с 8:00 до 22:00
    ул. Красный путь, 105 к.1 - с 8:00 до 20:00
    пр. Мира, 26 - с 7:30 до 20:00
    пр. Комарова, 16 - с 7:30 до 21:30
    ул. 70 лет Октября, 15 - круглосуточно`
            },
            {
                title: 'Стоимость доставки',
                message: `Стоимость доставки по городу:
С ${rootGetters['franchises/franchiseTariff'].delivery_markup_bytime_city_end} до ${rootGetters['franchises/franchiseTariff'].delivery_markup_bytime_city_start} - ${rootGetters['franchises/franchiseTariff'].delivery_cost} ₽, 1 адрес. При сумме заказа от ${rootGetters['franchises/franchiseTariff'].cost_delivery_free} ₽ - бесплатная.
С ${rootGetters['franchises/franchiseTariff'].delivery_markup_bytime_city_start} до ${rootGetters['franchises/franchiseTariff'].delivery_markup_bytime_city_end} - ${rootGetters['franchises/franchiseTariff'].delivery_markup_bytime_city} ₽, 1 адрес.
За городом и по Омской области - ${rootGetters['franchises/franchiseTariff'].delivery_tariff / 2} ₽/км в одну и другую сторону.
Доставка осуществляется в промежутке 1 часа, например с 15:00 до 16:00 (к определенному времени не доставляем). На ближайшее время - в течение 2-3х часов.
Заказы необходимо оформлять не позднее, чем за 2 часа до доставки и за час до самовывоза.`
            },
            {
                title: 'Как подписать открытку',
                message: 'Если Вы уже оформили заказ и желаете добавить открытку, напишите, пожалуйста, номер Вашего заказа и желаемый текст, я добавлю. Если заказ еще не оформлен - для того, чтобы добавить открытку, перейдите в раздел "Подарки", добавьте желаемую открытку в корзину, там же в корзине под товаром появится поле для ввода пожелания.'
            },
            {
                title: 'Причина отмены заказа',
                message: 'Ваш заказ был переведен в статус "Ожидает отмены". Он отменится только после подтверждения менеджера. Уточните, пожалуйста, по какой причине желаете отменить заказ?'
            },
            {
                title: 'Возврат осуществили',
                message: 'Возврат денежных средств осуществили, они поступят на карту, согласно условиям банка, обслуживающего Вашу карту. Как только денежные средства поступят, пожалуйста, сообщите. Это очень важно для нас!'
            },
            {
                title: 'Куда поступит фото букета',
                message: 'Фото Вашего заказа поступит в Ваш личный кабинет, в раздел "Уведомления" примерно за час до доставки. Раздел находится в верхнем правом углу (колокольчик).'
            },
            {
                title: 'Как списать бонусы',
                message: `Количество бонусов возможно увидеть в своем личном кабинете, а также в корзине при оформлении заказа. Чтобы воспользоваться бонусами:
    *добавьте понравившийся товар в корзину
    *справа сделайте активной кнопку "бонусы"
    *введите желаемое количество бонусов для списания
    *нажмите "Оформить заказ" и далее заполните все последующие поля.`
            },
            {
                title: 'На случай жалоб',
                message: 'Спасибо за обратную связь! Уточню все детали и вернусь к Вам.'
            }
        ]
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

<template>
    <div class="image-loader__wrap" :class="{'disabled': disabled && !value}">
        <label class="image-loader__label" :class="{'image-loader__label--with-photo': value}">
            <input
                ref="input"
                type="file"
                class="image-loader__input"
                accept="image/*"
                :disabled="disabled"
                @change="files = $event.target.files, $emit('change', $event)"
            >
            <div v-if="!value" class="image-loader__dropzone">
                <img src="@/assets/img/content/image-loader.svg" alt="">
                <div class="image-loader__dropzone-text">Загрузить фото</div>
            </div>
            <img :src="value" class="image-loader__photo" alt="">
        </label>
        <Button v-if="value" class="image-loader__photo-btn" text compact @click="openLink(value)">
            <Icon color="#0D63F3">new-window</Icon>
        </Button>
    </div>
</template>

<script>
export default {
    name: 'ImageLoader',
    props: {
        value: { type: String, default: null },
        disabled: { type: [Boolean, String, Number], default: false }
    },
    data() {
        return {
            files: []
        }
    },
    updated() {
        this.files = this.$refs.input.files;
    },
    methods: {
        openLink(urlString) {
            window.open(urlString, '_blank');
        },
    }
}
</script>

<template>
    <div>
        <SideFilter :opened="!!isFilterOpened('notifications-filter')" @input="closeFilter('notifications-filter')">
            <NotificationsFilter />
        </SideFilter>

        <SideFilter :opened="!!isFilterOpened('order-filter')" @input="closeFilter('order-filter')">
            <OrderFilter />
        </SideFilter>

        <SideFilter :opened="!!isFilterOpened('goods-parameters-filter')" @input="closeFilter('goods-parameters-filter')">
            <GoodsParametersFilter />
        </SideFilter>

        <SideFilter :opened="!!isFilterOpened('clients-filter')" @input="closeFilter('clients-filter')">
            <ClientsFilter />
        </SideFilter>

        <SideFilter :opened="!!isFilterOpened('store-filter')" @input="closeFilter('store-filter')">
            <StoreFilter />
        </SideFilter>

        <SideFilter :opened="!!isFilterOpened('arrivals-side-filter')" @input="closeFilter('arrivals-side-filter')">
            <ArrivalsSideFilter />
        </SideFilter>

        <SideFilter :opened="!!isFilterOpened('offs-filter')" @input="closeFilter('offs-filter')">
            <WriteOffsFilter />
        </SideFilter>

        <SideFilter :opened="!!isFilterOpened('transfers-filter')" @input="closeFilter('transfers-filter')">
            <TransferFilter />
        </SideFilter>

        <SideFilter :opened="!!isFilterOpened('inventories-filter')" @input="closeFilter('inventories-filter')">
            <InventoriesFilter />
        </SideFilter>

        <SideFilter :opened="!!isFilterOpened('salaries-filter')" @input="closeFilter('salaries-filter')">
            <SalariesFilter />
        </SideFilter>

        <SideFilter :opened="!!isFilterOpened('reviews-filter')" @input="closeFilter('reviews-filter')">
            <ReviewsFilter />
        </SideFilter>

        <SideFilter :opened="!!isFilterOpened('franchises-filter')" @input="closeFilter('franchises-filter')">
            <FranchisesFilter />
        </SideFilter>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SideFilter from '@/components/app/SideFilter';

import ArrivalsSideFilter from '@/components/app/arrivals/ArrivalsSideFilter';
import OrderFilter from '@/components/app/orders/OrderFilter';
import WriteOffsFilter from '@/components/app/writeoffs/WriteOffsFilter';
import TransferFilter from '@/components/app/transfers/TransferFilter';
import ClientsFilter from '@/components/app/users/ClientsFilter';
import StoreFilter from '@/components/app/store/StoreFilter';
import NotificationsFilter from '@/components/app/notifications/NotificationsFilter';
import FranchisesFilter from '@/components/app/franchises/FranchisesFilter';
import InventoriesFilter from '@/components/app/inventories/InventoriesFilter';
import GoodsParametersFilter from '@/components/app/goods/GoodsParametersFilter';
import ReviewsFilter from '@/components/app/reviews/ReviewsFilter';
import SalariesFilter from '@/components/app/salaries/SalariesFilter'

export default {
    name: 'Filters',
    components: {
        SideFilter,
        OrderFilter,
        ArrivalsSideFilter,
        WriteOffsFilter,
        TransferFilter,
        ClientsFilter,
        StoreFilter,
        NotificationsFilter,
        FranchisesFilter,
        InventoriesFilter,
        GoodsParametersFilter,
        ReviewsFilter,
        SalariesFilter
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            isFilterOpened: 'ui/isFilterOpened'
        }),
    },
    methods: {
        closeFilter(filter) {
            this.$store.commit('ui/CLOSE_FILTER', filter)
        }
    }
};
</script>

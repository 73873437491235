<template>
    <div
        v-if="!preloader"
        class="textarea-custom__wrapper"
        :class="{ 'error-item': error || (value && value.length == maxLength) }"
        v-on="$listeners"
    >
        <div
            class="textarea"
            :class="{
                'textarea--with-label': label,
                'textarea--with-right-icon': clearable || rightIcon,
                'disabled': disabled,
                'focus': isTexareaFocused
            }"
        >
            <label
                v-if="label"
                class="textarea-custom__label"
                :class="{'textarea-custom__label--is-top': value || isTexareaFocused}"
                @click.prevent="focusInput"
            >
                {{ label }}
            </label>
            <textarea
                ref="textarea"
                :value="value"
                :rows="rows"
                :placeholder="showPlaceholder ? placeholder : null"
                :maxlength="maxLength ? +maxLength : null"
                :disabled="disabled"
                :readonly="readonly"
                :style="`min-height: ${20 * rows}px; max-height: ${20 * maxRows || 9999}px;`"
                @focus="isTexareaFocused = true"
                @blur="isTexareaFocused = false"
                @input.stop="autosize"
                @select="getSelectedTextCount($event.target)"
                @click="$emit('click'), getSelectedTextCount($event.target)"
            />
            <button
                v-if="value && clearable && !disabled && !readonly && !rightIcon"
                type="button"
                class="textarea__clear"
                @click.prevent="autosize({ target: { value: '' } })"
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#F1F5F9"/>
                    <path d="M15 9L9 15" stroke="#2C4A67" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 9L15 15" stroke="#2C4A67" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <button
                v-if="rightIcon"
                type="button"
                class="input-custom__right-icon"
                @click.prevent="$emit('click:right')"
            >
                <Icon color="#2B77F5">{{ rightIcon }}</Icon>
            </button>
        </div>
        <div v-if="error || messages || counter" class="textarea-custom__bottom-block">
            <div v-if="error" class="textarea-custom__error">{{ error }}</div>
            <div v-if="messages && !error" class="textarea-custom__messages">{{ messages }}</div>
            <div v-if="counter" class="textarea-custom__counter">
                {{ value && value.length ? value.length : 0 }}
                <span v-show="selectedTextCount">({{ selectedTextCount }}) </span>
                <span v-if="maxLength">/ {{ maxLength }}</span>
            </div>
        </div>
    </div>
    <ContentPreloader v-else />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TextareaCustom',
    props: {
        value: {
            type: String,
            default: ''
        },
        label: { type: String, default: '' },
        placeholder: {
            type: String,
            default: ''
        },
        maxLength: { type: [Number, String], default: 0 },
        rows: { type: [String, Number], default: 1 },
        maxRows: { type: [String, Number], default: 0 },
        counter: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        messages: { type: [String, null], default: '' },
        rightIcon: { type: String, default: '' },
        error: { type: String, default: '' }
    },
    data() {
        return {
            isTexareaFocused: false,
            selectedTextCount: 0
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
        }),
        showPlaceholder() {
            if (this.placeholder && this.label && this.isTexareaFocused) {
                return true
            } else if (this.placeholder && !this.label) {
                return true
            }
            return false
        }
    },
    watch: {
        value() {
            this.autosize({ target: { value: this.value } });
        },
        preloader() {
            this.autosize({ target: { value: this.value } });
        }
    },
    updated() {
        this.autosize({ target: { value: this.value } }, false);
        this.getSelectedTextCount(this.$refs.textarea);
    },
    async mounted() {
        // Добавляем nextTick и setTimeout, чтобы размер textarea пересчитывался после отрисовки страницы или модалки
        await this.$nextTick();
        setTimeout(() => {
            this.autosize({ target: { value: this.value } });
        }, 0)
    },
    methods: {
        async autosize(event, withInputEvent = true) {
            if (withInputEvent) {
                this.$emit('input', event.target.value);
            }
            await this.$nextTick();
            if (this.$refs.textarea) {
                this.$refs.textarea.style.height = 'auto';
                this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
            }
        },
        focusInput() {
            if (this.disabled || this.readonly) {
                return;
            }
            this.$refs.textarea.focus();
            this.isTexareaFocused = true;
        },
        getSelectedTextCount(elem) {
            if (this.counter && elem) {
                setTimeout(() => {
                    this.selectedTextCount = elem.selectionEnd - elem.selectionStart;
                }, 0)
            }
        }
    }
}
</script>

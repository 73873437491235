<template>
    <Doughnut :chart-data="chartData" :chart-options="chartOptions" />
</template>

<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
    name: 'DoughnutChart',
    components: {
        Doughnut
    },
    props: {
        chartData: { type: Object, default: () => {} },
        chartOptions: { type: Object, default: () => {} },
    },
}
</script>

<template>
    <div class="block-form-login">
        <div class="block-form-login__form-wrapper">
            <div class="block-form-login__form-inner">
                <div class="login-logo">
                    <img src="@/assets/img/icon/saastra-logo.svg" alt="Lavanda IS">
                </div>
                <div>
                    <div v-if="step === 1">
                        <v-text-field v-model="first_name" label="Имя" outlined clearable class="mb-6" />
                        <v-text-field
                            v-model="email"
                            label="Email"
                            type="email"
                            class="mb-6"
                            outlined
                            clearable
                            :error-messages="error && error.admin && error.admin.email ? error.admin.email[0]: ''"
                        />
                        <v-text-field
                            v-model="phone"
                            label="Телефон"
                            type="tel"
                            hint="Введите телефон в формате +7xxxxxxxxxx"
                            outlined
                            clearable
                            :error-messages="error && error.admin && error.admin.phone ? error.admin.phone[0]: ''"
                        />
                        <div class="block-form__btn-block">
                            <router-link to="/auth" class="block-form-login__reg-btn">
                                Войти
                            </router-link>
                            <v-btn depressed color="primary" @click.prevent="toSecondStep">
                                Далее
                            </v-btn>
                        </div>
                    </div>
                    <div v-if="step === 2">
                        <div style="position: relative;">
                            <v-text-field
                                v-model="smsCode"
                                label="Код из SMS"
                                type="text"
                                class="mb-6"
                                outlined
                                clearable
                                :error-messages="error && error.admin && error.admin.sms_code ? error.admin.sms_code[0]: ''"
                            />
                            <button v-if="showRepeatSmsBtn" type="button" @click="repeatSMS" class="block-form-login__repeat-sms-btn">
                                Отправить СМС еще раз
                            </button>
                        </div>
                        <div class="block-form__btn-block mt-12">
                            <button type="button" class="block-form-login__reg-btn" @click.prevent="step = 1">
                                Назад
                            </button>
                            <v-btn @click.prevent="toThirdStep" depressed color="primary">
                                Далее
                            </v-btn>
                        </div>
                    </div>
                    <div v-if="step === 3">
                        <v-text-field
                            v-model="username"
                            label="Логин"
                            outlined
                            clearable
                            class="mb-6"
                            :error-messages="error && error.admin && error.admin.username ? error.admin.username[0]: ''"
                        />
                        <v-text-field
                            v-model="password"
                            label="Пароль"
                            class="mb-6"
                            :type="passInputType ? 'text' : 'password'"
                            :append-icon="passInputType ? 'mdi-eye' : 'mdi-eye-off'"
                            outlined
                            clearable
                            :error-messages="error && error.admin && error.admin.password ? error.admin.password[0]: ''"
                            @click:append="passInputType = !passInputType"
                        />
                        <v-text-field
                            v-model="password2"
                            label="Повторите пароль"
                            class="mb-6"
                            :type="passInputType2 ? 'text' : 'password'"
                            :append-icon="passInputType2 ? 'mdi-eye' : 'mdi-eye-off'"
                            outlined
                            clearable
                            :error-messages="error && error.admin && error.admin.password2 ? error.admin.password2[0]: ''"
                            @click:append="passInputType2 = !passInputType2"
                        />
                        <div class="block-form__btn-block">
                            <button type="button" class="block-form-login__reg-btn" @click.prevent="step = 1">
                                Назад
                            </button>
                            <v-btn type="button" depressed color="primary" @click.prevent="saveFranchise">
                                Далее
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { regPhoneConfirm, regPhoneCheck } from '@/api/django';

export default {
    name: 'TheRegistration',
    metaInfo: {
        title: 'Регистрация'
    },
    data: () => ({
        franchise: {},
        username: '',
        first_name: '',
        password: '',
        password2: '',
        email: '',
        phone: '',
        error: null,
        step: 1,
        smsCode: '',
        showRepeatSmsBtn: false,
        passInputType: false,
        passInputType2: false,
    }),
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
        })
    },
    methods: {
        async toSecondStep() {
            try {
                this.error = null;
                if (!this.email) {
                    this.error = { admin: { email: ['Поле не может быть пустым'] } }
                } else {
                    let regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!regExp.test(this.email)) this.error = { admin: { email: ['Не валидный email'] } };
                }
                if (!this.phone) this.error = { admin: { phone: ['Поле не может быть пустым'] } };
                if (this.error) return;

                if (this.phone && !this.phone.startsWith('+')) {
                    this.phone = `+${this.phone}`;
                }
                await regPhoneConfirm({ phone: this.phone });
                this.step = 2;
                this.error = null;
                setTimeout(() => {
                    this.showRepeatSmsBtn = true;
                }, 60000);
            } catch (error) {
                console.log(error);
                if (error.data.message === "Нельзя отправлять SMS так часто") this.error = { admin: { phone: ['Нельзя отправлять SMS так часто'] } };
                else this.error = { admin: { phone: ['Ошибка при отправке SMS'] } };
            }
        },
        async toThirdStep() {
            try {
                await regPhoneCheck({ phone: this.phone, sms_code: this.smsCode });
                this.step = 3;
                this.smsCode = '';
                this.error = null;
            } catch (error) {
                console.log(error);
                this.error = { admin: { sms_code: ['Неверный код'] } };
            }
        },
        async repeatSMS() {
            try {
                await regPhoneConfirm({ phone: this.phone });
                this.showRepeatSmsBtn = false;
                setTimeout(() => {
                    this.showRepeatSmsBtn = true;
                }, 60000);
            } catch (error) {
                this.error = { admin: { sms_code: ['Нельзя отправлять SMS так часто'] } };
            }
        },
        saveFranchise() {
            this.error = null;
            if (this.password !== this.password2) {
                this.error = { admin: { password2: ['Введенные пароли не совпадают'] } };
                return;
            }
            this.franchise = {
                site: {
                    name: this.username,
                    domain: `${this.username}.ru`
                },
                options: {
                    city: 350,
                    delivery_cost: 350,
                    delivery_tariff: 0,
                    delivery_markup_cost: 0,
                    delivery_time_start: "08:00",
                    delivery_time_end: "20:00",
                    enabled_payment_methods: [
                        1306,
                        1305,
                        1304,
                        1290,
                        1289,
                        1288
                    ],
                    merchant_username: "login",
                    merchant_password: "password",
                    company_name: this.username,
                    ratio_to_rub: 0,
                    ratio_when_applied: 0,
                    bonus_is_active: false,
                },
                shop: {
                    zip_code: "109012",
                    street: "Красная площадь",
                    house: "1",
                    coords: "55.75530999142458, 37.61782001593048",
                    work_time_start: "08:00",
                    work_time_end: "20:00"
                },
                admin: {
                    username: this.username,
                    first_name: this.first_name,
                    password: this.password,
                    phone: this.phone,
                    email: this.email
                }
            };
            this.$store.dispatch('franchises/CREATE_FRANCHISE', this.franchise)
                .then(async () => {
                    await this.$store.dispatch('auth/LOGIN', { username: this.franchise.admin.username, password: this.franchise.admin.password, grant_type: 'password' });
                })
                .catch((err) => {
                    this.error = err.data;
                    if (this.error && this.error.admin && this.error.admin.password && this.error.admin.password[0] === 'Введённый пароль слишком короткий. Он должен содержать как минимум 9 символов.') {
                        this.error.admin.password = ["Пароль минимум 9 символов"];
                    }
                    if (this.error.hasOwnProperty('non_field_errors')) {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: this.non_field_errors[0], type: 'error' })
                    }
                });
        },
    }
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Подписка"
                left-btn="menu"
                :show-left-btn="width <= 899"
                :show-save-btn="false"
                @click:left="openNavigation"
            />
            <div class="page-container">
                <div class="page__info-group">
                    <h2 class="page__info-group-title">Тарифы</h2>
                    <Row v-show="!preloader">
                        <template v-for="item in tariffs">
                            <Col v-if="item.is_view" :cols="width > 1024 ? 4 : 12" :key="item.id">
                                <div class="subscription__tariff-item">
                                    <div class="subscription__tariff-title">{{ item.name }}</div>
                                    <div class="subscription__tariff-cost">{{ numberFormat(item.cost) }} ₽/месяц</div>
                                    <div class="subscription__tariff-text" v-html="item.description" />
                                    <div class="subscription__tariff-current-wrapper" v-if="!!currentTariff(item)">
                                        <div style="color:red;">ТЕКУЩИЙ ТАРИФ</div>
                                        <div style="color:red;">Осталось дней: {{ currentTariff(item).count_days_until_end }}</div>
                                    </div>
                                    <Button v-if="!authUser.read_only" class="mt-6" block @click.prevent="buySubscription(item)">
                                        {{ !!currentTariff(item) ? 'Продлить' : 'Приобрести подписку' }}
                                    </Button>
                                </div>
                            </Col>
                        </template>
                    </Row>
                    <Row v-show="preloader">
                        <Col v-for="i in 3" :key="i" :cols="width > 1024 ? 4 : 12">
                            <ContentPreloader height="460" />
                        </Col>
                    </Row>
                </div>
                <div class="page__info-group">
                    <h2 class="page__info-group-title">История подписок</h2>
                    <div v-show="!preloader" class="listing-table__wrap">
                        <div v-if="subscriptions.results && subscriptions.results.length" class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: calc(100% / 7)">Начало</div>
                                <div class="listing-table__col" style="width: calc(100% / 7)">Окончание</div>
                                <div class="listing-table__col" style="width: calc(100% / 7)">Тариф</div>
                                <div class="listing-table__col" style="width: calc(100% / 7)">Сумма</div>
                                <div class="listing-table__col" style="width: calc(100% / 7)">Магазинов</div>
                                <div class="listing-table__col" style="width: calc(100% / 7)">Статус</div>
                                <div class="listing-table__col" style="width: calc(100% / 7)" />
                            </div>
                            <div v-for="item in subscriptions.results" :key="item.id" class="listing-table__row">
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                    <div class="listing-table__col-label">Начало</div>
                                    {{ dateFormat(item.date_activity_start, 'ru') }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                    <div class="listing-table__col-label">Окончание</div>
                                    {{ item.date_activity_end ? dateFormat(item.date_activity_end, 'ru') : '&mdash;' }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                    <div class="listing-table__col-label">Тариф</div>
                                    {{ item.tariff.name }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                    <div class="listing-table__col-label">Сумма</div>
                                    {{ item.tariff.cost }} руб
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                    <div class="listing-table__col-label">Магазинов</div>
                                    {{ item.count_of_avaliable_shop }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                    <div class="listing-table__col-label">Статус</div>
                                    <div :style="width > 1024 ? '' : 'text-align: right'">
                                        <div>{{ item.active ? 'Активна' : 'Не активна' }}</div>
                                        <div
                                            v-if="item.count_days_until_end"
                                            class="subscription__tables-days-until-end"
                                            :class="{'red': item.count_days_until_end <= 7}"
                                        >
                                            Осталось {{ item.count_days_until_end }} дней
                                        </div>
                                    </div>
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 7)' : ''">
                                    <Button v-if="!authUser.read_only && item.active" bordered icon-left="plus" @click.prevent="buyShop(item)">
                                        Магазин
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContentPreloader v-show="preloader" type="table" />
                </div>
            </div>
        </div>
        <UniversalModal :opened="isOpenPurchaseModal" size="big" @input="closePaymentModal">
            <UniversalModalHeader
                :title="tariffForPayment ? 'Оплатить подписку' : 'Добавить магазины'"
                @click:right="closePaymentModal"
            />
            <div class="universal-modal__content">
                <template v-if="tariffForPayment">
                    <h2 class="page__info-group-title">
                        Тариф "{{ tariffForPayment.name }}"
                    </h2>
                    <div class="subscription__tariff-cost">
                        {{ numberFormat(tariffForPayment.cost) }} ₽/месяц
                    </div>

                    <div class="subscription__tariff-description mb-4" v-html="tariffForPayment.description" />

                    <InputSelect
                        v-model="selectedPayMethod"
                        :options="payMethods"
                        label="Способ оплаты"
                        input-value="value"
                        :hint="selectedPayMethod === 'bill' ? 'Данные счета заполняются в настройках' : ''"
                    />
                    <Button class="mt-4" block @click.prevent="paySubscription">
                        {{ !!currentTariff(tariffForPayment) ? 'Продлить' : 'Приобрести подписку' }}
                    </Button>
                </template>
                <template v-if="subForAddShop">
                    <h2 class="page__info-group-title">
                        Тариф "{{ subForAddShop.tariff.name }}"
                    </h2>
                    <div class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: calc(100% / 6)">Начало</div>
                            <div class="listing-table__col" style="width: calc(100% / 6)">Окончание</div>
                            <div class="listing-table__col" style="width: calc(100% / 6)">Сумма</div>
                            <div class="listing-table__col" style="width: calc(100% / 6)">Стоимость 1 доп магазина</div>
                            <div class="listing-table__col" style="width: calc(100% / 6)">Добавить магазинов</div>
                        </div>
                        <div class="listing-table__row">
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 6)' : ''">
                                <div class="listing-table__col-label">Начало</div>
                                {{ dateFormat(subForAddShop.date_activity_start, 'ru') }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 6)' : ''">
                                <div class="listing-table__col-label">Окончание</div>
                                {{ subForAddShop.date_activity_end ?
                                    dateFormat(subForAddShop.date_activity_end, 'ru') : '&mdash;' }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 6)' : ''">
                                <div class="listing-table__col-label">Сумма</div>
                                {{ numberFormat(subForAddShop.tariff.cost) }} ₽
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 6)' : ''">
                                <div class="listing-table__col-label">Стоимость 1 доп магазина</div>
                                {{ subForAddShop.tariff.cost_of_one_shop }} ₽
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: calc(100% / 6)' : ''">
                                <div class="listing-table__col-label">Добавить магазинов</div>
                                <InputCounter v-model="shopCounter" @input="inputShopCounter" />
                            </div>
                        </div>
                    </div>
                    <v-tooltip top color="#000">
                        <template v-slot:activator="{ on, attrs }">
                            <Button class="mt-4" block v-bind="attrs" v-on="on" @click="payShop">
                                Оплатить {{ shopCounter }}шт.
                            </Button>
                        </template>
                        <span>Сумма на оплату зависит от оставшихся дней подписки</span>
                    </v-tooltip>
                </template>
            </div>
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import {
    getTariffs, getSubscriptions, createSubscription, paySubscriptionSberbank, payAddShop, paySubscriptionBill
} from '@/api/tariff';

import { date, numberFormat } from '@/utils/format';

export default {
    name: 'TheSubsciption',
    metaInfo: {
        title: 'Подписка'
    },
    data: () => ({
        tariffs: [],
        subscriptions: [],
        tariffForPayment: null,
        subForAddShop: null,
        shopCounter: 1,
        payMethods: [
            { name: 'По карте', value: 'card' },
            { name: 'По счету', value: 'bill' },
        ],
        selectedPayMethod: 'card',
        isOpenPurchaseModal: false
    }),
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            origin: 'ui/origin',
            franchiseTariff: 'franchises/franchiseTariff',
            authUser: 'auth/user',
        }),
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            const tariffs = await getTariffs();
            const sub = await getSubscriptions();
            this.tariffs = tariffs.results;
            this.subscriptions = sub;
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        currentTariff(tariff) {
            let find = this.subscriptions.results.find((item) => item.active && item.tariff.id === tariff.id);
            return find || null;
        },
        buySubscription(tariff) {
            this.tariffForPayment = tariff;
            this.isOpenPurchaseModal = true;
        },
        buyShop(subscription) {
            this.subForAddShop = subscription;
            this.isOpenPurchaseModal = true;
        },
        async paySubscription() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                let prolong = this.currentTariff(this.tariffForPayment);
                let sub;
                const subscription = {
                    tariff: this.tariffForPayment.id,
                    franchise: this.franchiseTariff.franchise_id,
                    count_days_until_end: this.tariffForPayment.count_activity_days
                };
                if (!!prolong) {
                    sub = this.subscriptions.results.find((item) => item.active);
                } else {
                    sub = await createSubscription(subscription);
                }

                // Для работы на серверах
                let subDomen = 'prod.';
                if (document.location.host === 'localhost:8080') {
                    subDomen = 'dev.';
                } else if (document.location.host.split('.')[0].startsWith('dev') || document.location.host.split('.')[0].startsWith('test')) {
                    subDomen = `${document.location.host.split('.')[0]}.`;
                }

                const link = `https://${subDomen}tariff.lavandaflor.ru`;

                const payObj = {
                    orderNumber: sub.id,
                    returnUrl: `${link}/api/v1/payment/result?return_domen=${document.location.origin}`,
                    failUrl: `${link}/api/v1/payment/result?return_domen=${document.location.origin}`
                };

                let pay;
                if (this.selectedPayMethod === 'card') {
                    pay = await paySubscriptionSberbank(payObj);
                } else if (this.selectedPayMethod === 'bill') {
                    pay = await paySubscriptionBill(payObj);
                }
                if (pay.formUrl) {
                    this.tariffForPayment = null;
                    document.location.href = pay.formUrl;
                } else {
                    throw new Error('Ссылка на оплату не получена');
                }
            } catch (error) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Не удалось оплатить подписку', type: 'error' })
            }
        },
        async payShop() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                // Для работы на серверах
                let subDomen = 'prod.';
                if (document.location.host === 'localhost:8080') {
                    subDomen = 'dev.';
                } else if (document.location.host.split('.')[0].startsWith('dev') || document.location.host.split('.')[0].startsWith('test')) {
                    subDomen = `${document.location.host.split('.')[0]}.`;
                }

                const link = `https://${subDomen}tariff.lavandaflor.ru`;

                const shopObj = {
                    orderNumber: this.subForAddShop.id,
                    returnUrl: `${link}/api/v1/payment/buy_shop/result/?return_domen=${document.location.origin}&count_of_shop=${this.shopCounter}`,
                    failUrl: `${link}/api/v1/payment/buy_shop/result/?return_domen=${document.location.origin}&count_of_shop=${this.shopCounter}`,
                    count_of_shop: this.shopCounter
                };
                const pay = await payAddShop(shopObj);
                if (pay.formUrl) {
                    this.subForAddShop = null;
                    this.shopCounter = 1;
                    document.location.href = pay.formUrl;
                } else {
                    throw new Error('Ссылка на оплату не получена');
                }
            } catch (error) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Не удалось оплатить магазин', type: 'error' })
            }
        },
        closePaymentModal() {
            this.tariffForPayment = null;
            this.subForAddShop = null;
            this.shopCounter = 1;
            this.isOpenPurchaseModal = false;
        },
        inputShopCounter() {
            if (!this.shopCounter || this.shopCounter <= 0 || this.shopCounter.startsWith('-')) {
                this.shopCounter = 1;
            }
        },
        dateFormat(data, type) {
            return date(new Date(data), type);
        },
        numberFormat(num) {
            if (num) return numberFormat(num);
            return 0;
        },
        openNavigation() {
            this.$store.commit('ui/TOGGLE_SIDEBAR')
            this.$store.dispatch('ui/toggle_opened_modals_count', 'open');
        }
    }
}
</script>

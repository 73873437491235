<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Теги" for-listing :show-save-btn="false" />
            <div class="page-container page-container--listing page-container--with-fab">
                <div class="goods__category-name">
                    {{ activeCategory && activeCategory.name ? activeCategory.name : 'Все теги' }}
                </div>

                <div class="page-columns">
                    <div class="page-big-column">
                        <div v-show="!preloader" class="listing-table__wrap">
                            <div v-if="tags && tags.length" class="listing-table">
                                <router-link
                                    v-for="item in tags"
                                    :key="item.id"
                                    :to="`/tags/${item.id}`"
                                    class="listing-table__row"
                                >
                                    <div class="listing-table__col">
                                        <div class="d-flex align-center">
                                            <div class="d-flex px-2 py-2 mr-2" style="background: #F1F5F9; border-radius: 8px;">
                                                <Icon>tag</Icon>
                                            </div>
                                            {{ item.name }}
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <empty-table v-show="!tags.length" />
                        </div>
                        <ContentPreloader v-show="preloader" type="table" />
                    </div>
                    <div v-if="width > 699" class="page-small-column">
                        <div class="products-navbar__wrap">
                            <router-link
                                v-if="!authUser.read_only && ['supervisor', 'administrators'].includes(group)"
                                to="/tags/create"
                                class="products-navbar__link"
                            >
                                <Icon>plus-circle</Icon>
                                <div class="products-navbar__link-text">Создать тег</div>
                            </router-link>
                        </div>
                        <div ref="categoriesList" class="mt-4">
                            <div class="products-categories__wrap">
                                <div class="products-categories__list">
                                    <div
                                        v-for="item in categories"
                                        :key="item.id"
                                        :class="[`product-categories__item`, {'is-active': activeCategory && item.id === activeCategory.id}]"
                                        @click="filterTags(item)"
                                    >
                                        <div class="product-categories__item-name-wrap">
                                            <Icon :color="activeCategory && item.id === activeCategory.id ? '#0D63F3' : '#8AACCD'">
                                                folder
                                            </Icon>
                                            <div class="product-categories__item-name">
                                                {{ item.name }}
                                            </div>
                                        </div>
                                        <a
                                            v-if="!authUser.read_only && ['supervisor', 'administrators'].includes(group)"
                                            :href="`/tags/category/${item.id}`"
                                            class="product-categories__item-btn edit"
                                            @click.stop.prevent="editCategory(item)"
                                        >
                                            <Icon color="#8AACCD">edit-2</Icon>
                                        </a>
                                    </div>
                                </div>

                                <a
                                    v-if="!authUser.read_only && ['supervisor', 'administrators'].includes(group)"
                                    href="/tags/category/create"
                                    class="action-btn"
                                    @click.prevent="addNewCategory"
                                >
                                    <Icon color="#0D63F3">folder-plus</Icon>
                                    Добавить категорию тегов
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="width <= 699" class="products-navbar__wrap">
                    <div class="products-navbar__link" @click.prevent="showCategoryModal = true">
                        <Icon>grid</Icon>
                        <div class="products-navbar__link-text">Категории</div>
                    </div>
                    <router-link
                        v-if="!authUser.read_only && ['supervisor', 'administrators'].includes(group)"
                        to="/tags/create"
                        class="products-navbar__link"
                    >
                        <Icon>plus-circle</Icon>
                        <div class="products-navbar__link-text">Создать тег</div>
                    </router-link>
                </div>
            </div>
        </div>
        <UniversalModal v-model="showCategoryModal">
            <UniversalModalHeader
                :title="'Категории'"
                @closeModal="showCategoryModal = false"
            />
            <div class="universal-modal__content">
                <div class="products-categories__wrap">
                    <div class="products-categories__list">
                        <div
                            v-for="item in categories"
                            :key="item.id"
                            :class="[`product-categories__item`, {'is-active': activeCategory && item.id === activeCategory.id}]"
                            @click="filterTags(item)"
                        >
                            <div class="product-categories__item-name-wrap">
                                <Icon :color="activeCategory && item.id === activeCategory.id ? '#0D63F3' : '#8AACCD'">
                                    folder
                                </Icon>
                                <div class="product-categories__item-name">
                                    {{ item.name }}
                                </div>
                            </div>
                            <a
                                v-if="!authUser.read_only && ['supervisor', 'administrators'].includes(group)"
                                :href="`/tags/category/${item.id}`"
                                class="product-categories__item-btn edit"
                                @click.stop.prevent="editCategory(item)"
                            >
                                <Icon color="#8AACCD">edit-2</Icon>
                            </a>
                        </div>
                    </div>

                    <a
                        v-if="!authUser.read_only && ['supervisor', 'administrators'].includes(group)"
                        href="/tags/category/create"
                        class="action-btn"
                        @click.prevent="addNewCategory"
                    >
                        <Icon color="#0D63F3">folder-plus</Icon>
                        Добавить категорию тегов
                    </a>
                </div>
            </div>
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TheTags',
    metaInfo: {
        title: 'Теги'
    },
    data() {
        return {
            activeCategory: null,
            showCategoryModal: false,
            fab_open: false
        };
    },
    computed: {
        ...mapGetters({
            categories: 'tags/categories',
            tags: 'tags/tags',
            width: 'ui/width',
            preloader: 'ui/preloader',
            franchiseTariff: 'franchises/franchiseTariff',
            authUser: 'auth/user',
        }),
        group() {
            return localStorage.getItem('group');
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await this.$store.dispatch('tags/getTags');
            this.calculateCategoriesHeight();
            window.addEventListener('resize', this.calculateCategoriesHeight);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calculateCategoriesHeight);
    },
    methods: {
        async filterTags(category) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                if (this.activeCategory && this.activeCategory.id === category.id) {
                    this.activeCategory = null;
                    this.$store.commit('tags/SET_CATEGORY_ID_FOR_CREATE', null);
                    await this.$store.dispatch('tags/getTags');
                } else {
                    this.activeCategory = category;
                    this.$store.commit('tags/SET_CATEGORY_ID_FOR_CREATE', category.id);
                    await this.$store.dispatch('tags/getTags', category.id);
                }
                this.showCategoryModal = false;
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        addNewCategory() {
            this.$router.push(`/tags/category/create`);
            this.$store.dispatch('ui/toggle_opened_modals_count', 'close')
        },
        editCategory(category) {
            this.$router.push(`/tags/category/${category.id}`);
            this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
        },
        calculateCategoriesHeight() {
            if (this.width > 699 && this.$refs.categoriesList && this.$refs.categoriesList.firstElementChild.firstElementChild) {
                let categoriesListEl = this.$refs.categoriesList.firstElementChild.firstElementChild;
                let maxHeight = document.documentElement.clientHeight - 240; // 240 - включает отступы, высоту шапки, navbar и кнопки "Добавить категорию" 
                categoriesListEl.style.maxHeight = `${maxHeight}px`;
            }
        }
    }
};
</script>

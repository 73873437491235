/* eslint-disable */
import { getAllSalarys } from '@/api/django'

const state = {
    salaries: {},
    filters: {
        limit: 20,
        offset: 0,
        group: null,
        date_from: null,
        date_to: null
    },
    activeFilters: [],
    busy: false,
    next: false,
};

const mutations = {
    SET_SALARIES(state, salaries) {
        state.salaries = salaries.results;
        state.next = !!salaries.next;
        state.filters.offset = 20;
    },
    SET_NEXT_PAGE_SALARIES(state, salaries) {
        state.salaries.push(...salaries.results);
        state.next = !!salaries.next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            group: null,
            date_from: null,
            date_to: null
        };
        state.next = null;
    },
};

const actions = {
    async GET_SALARIES({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;

            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;
            
            let filters = await dispatch('prepareFilters');

            commit('SET_SALARIES', await getAllSalarys(filters));
        } catch (error) {
            console.log(error);
        } finally {
            state.busy = false;
        }
    },
    async LOAD_MORE({ state, commit, dispatch }) {
        try {
            if (state.next && !state.busy) {
                state.busy = true;
                let filters = await dispatch('prepareFilters');
                const res = await getAllSalarys(filters);
                commit('SET_NEXT_PAGE_SALARIES', res);
            }
        }
        catch(e) {
            console.log(e);
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([ key, value ]) => {
            if (key && (value || value === false || value === 0) ) { 
                filters[key] = value;
            }
        })
        let activeFilters =  Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    salaries: state => state.salaries,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    busy: state => state.busy,
    next: state => state.next,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

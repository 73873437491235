/* eslint-disable */
import { getTags, getTag } from '@/api/django';

const state = {
    categories: [],
    tags: [],
    tagsProductsList: [],
    categoryIDForCreate: null
};

const mutations = {
    SET_TAGS(state, tags) {
        state.categories = tags.results;
        state.tags = [];
        state.categories.forEach(item => state.tags.push(...item.childrens))
    },
    SET_TAGS_BY_CATEGORY(state, tags) {
        state.tags = tags.childrens;
    },
    CLEAR(state) {
        state.categories = [];
        state.tags = [];
    },
    ADD_GOOD_TO_PRODUCTS_LIST(state, good) {
        state.tagsProductsList.push({ ...good });
    },
    REMOVE_GOOD_FROM_PRODUCTS_LIST(state, good) {
        state.tagsProductsList = state.tagsProductsList.filter(item => item.vendor_code !== good.vendor_code);
    },
    SET_PRODUCTS_LIST(state, data) {
        state.tagsProductsList = data;
    },
    SET_CATEGORY_ID_FOR_CREATE(state, id) {
        state.categoryIDForCreate = id;
    }
};

const actions = {
    async getTags({ commit }, categoryID = null) {
        try {
            if (!categoryID) {
                commit('SET_TAGS', await getTags());
            } else {
                commit('SET_TAGS_BY_CATEGORY', await getTag(categoryID));
            }
        } catch (error) {
            console.log(error);
        }
    },
    setTagProducts({ commit, getters }, good) {
        if (getters['pickedProducts'](good.vendor_code)) {
            commit('REMOVE_GOOD_FROM_PRODUCTS_LIST', good);
        } else {
            commit('ADD_GOOD_TO_PRODUCTS_LIST', good)
        }
    },
};

const getters = {
    categories: state => state.categories,
    tags: state => state.tags,
    tagsProductsList: state => state.tagsProductsList,
    pickedProducts: state => id => !!state.tagsProductsList.find(good => good.vendor_code === id),
    categoryIDForCreate: state => state.categoryIDForCreate
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

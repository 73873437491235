<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Премии и штрафы" for-listing :show-save-btn="false" />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="changes && changes.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 20%">Тип</div>
                            <div class="listing-table__col" style="width: 20%">Название</div>
                            <div class="listing-table__col" style="width: 20%">Дата</div>
                            <div class="listing-table__col" style="width: 20%">Сумма</div>
                            <div class="listing-table__col" style="width: 20%">Статус</div>
                        </div>
                        <router-link v-for="item in changes" :key="item.id" :to="`/salarys-changes/${item.id}`" class="listing-table__row">
                            <div v-if="width >= 1024" class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                {{ item.salary_choice === 'bonus' ? 'Премия' : 'Штраф' }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                <div>
                                    <span v-if="width <= 1024">{{ item.salary_choice === 'bonus' ? 'Премия:' : 'Штраф:' }}</span>
                                    {{ item.name }}
                                </div>
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 20%' : ''">
                                <div class="listing-table__col-label">Дата</div>
                                {{ item.date_added ? date(new Date(item.date_added), 'ru') : 'Не задано' }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                <div class="listing-table__col-label">Сумма</div>
                                {{ formatNumber(+item.amount) }}₽
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                <StatusSelect
                                    :value="item.taken_into_account"
                                    :options="[
                                        { id: 0, name: 'Не учтено', color: '#F00C29', icon: 'close' },
                                        { id: 1, name: 'Частично учтено', color: '#F2C94C', icon: 'alert-triangle' },
                                        { id: 2, name: 'Учтено', color: '#36B687', icon: 'check' }
                                    ]"
                                    disabled
                                />
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="changes && !changes.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { date, numberFormat } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheSalaryChanges',
    metaInfo: {
        title: 'Премии / Штрафы'
    },
    components: { Spinner },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            changes: 'salaryChanges/changes',
            busy: 'salaryChanges/busy',
            next: 'salaryChanges/next',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        this.$store.commit('users/SET_ONLY_PERSONAL', true);
        await this.$store.dispatch('salaryChanges/GET_SALARY_CHANGES');
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    beforeDestroy() {
        this.$store.commit('users/SET_ONLY_PERSONAL', false);
    },
    methods: {
        loadMore() {
            this.$store.dispatch('salaryChanges/LOAD_MORE');
        },
        date(value, type) {
            return date(value, type);
        },
        formatNumber(number) {
            if (number < 0) {
                const type = number.toString().slice(0, 1);
                const value = number.toString().slice(1);
                return type + numberFormat(value);
            }
            return numberFormat(number);
        },
    }
}
</script>

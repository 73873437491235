<template>
    <div class="side-filter__content">
        <div class="side-filter__content-group-list">
            <div class="side-filter__content-group">
                <InputSelect
                    :value="selectValues.sex"
                    :options="sexOptions"
                    label="Пол"
                    input-value="value"
                    without-preloader
                    @input="filterClients('sex', $event)"
                />
            </div>

            <div class="side-filter__content-group">
                <InputSelect
                    :value="selectValues.referral"
                    :options="referralOptions"
                    label="Реферальная программа"
                    input-value="value"
                    without-preloader
                    @input="filterClients('referral', $event)"
                />
            </div>

            <div class="side-filter__content-group side-filter__content-group--block">
                <div class="side-filter__content-group-title">
                    Количество покупок
                </div>
                <v-range-slider
                    v-model="numOfPurchases"
                    track-color="#CFDDEA"
                    thumb-color="#FFFFFF"
                    dense
                    :min="filterMinMaxValues.min_num_of_purchases"
                    :max="filterMinMaxValues.max_num_of_purchases"
                    hide-details
                    @change="getFilteredClients('num_of_purchases', $event)"
                />
                <div class="side-filter__range-val-wrapper">
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(numOfPurchases[0])"
                            label="От"
                            clearable
                            without-preloader
                            @click:clear="$set(numOfPurchases, 0, toInt($event)), getFilteredClients('num_of_purchases', numOfPurchases)"
                            @change="$set(numOfPurchases, 0, toInt($event)), getFilteredClients('num_of_purchases', numOfPurchases)"
                        />
                    </div>
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(numOfPurchases[1])"
                            label="До"
                            clearable
                            without-preloader
                            @click:clear="$set(numOfPurchases, 1, toInt($event)), getFilteredClients('num_of_purchases', numOfPurchases)"
                            @change="$set(numOfPurchases, 1, toInt($event)), getFilteredClients('num_of_purchases', numOfPurchases)"
                        />
                    </div>
                </div>
            </div>

            <div class="side-filter__content-group side-filter__content-group--block">
                <div class="side-filter__content-group-title">
                    Сумма покупок
                </div>
                <v-range-slider
                    v-model="summOfPurchases"
                    track-color="#CFDDEA"
                    thumb-color="#FFFFFF"
                    dense
                    :min="filterMinMaxValues.min_summ_of_purchases"
                    :max="filterMinMaxValues.max_summ_of_purchases"
                    hide-details
                    @change="getFilteredClients('summ_amount_of_purchases', $event)"
                />
                <div class="side-filter__range-val-wrapper">
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(summOfPurchases[0])"
                            label="От"
                            clearable
                            without-preloader
                            @click:clear="$set(summOfPurchases, 0, toInt($event)), getFilteredClients('summ_of_purchases', summOfPurchases)"
                            @change="$set(summOfPurchases, 0, toInt($event)), getFilteredClients('summ_of_purchases', summOfPurchases)"
                        />
                    </div>
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(summOfPurchases[1])"
                            label="До"
                            clearable
                            without-preloader
                            @click:clear="$set(summOfPurchases, 1, toInt($event)), getFilteredClients('summ_of_purchases', summOfPurchases)"
                            @change="$set(summOfPurchases, 1, toInt($event)), getFilteredClients('summ_of_purchases', summOfPurchases)"
                        />
                    </div>
                </div>
            </div>

            <div class="side-filter__content-group side-filter__content-group--block">
                <div class="side-filter__content-group-title">
                    Ср. чек
                </div>
                <v-range-slider
                    v-model="avgeCheck"
                    track-color="#CFDDEA"
                    thumb-color="#FFFFFF"
                    dense
                    :min="filterMinMaxValues.min_avge_check"
                    :max="filterMinMaxValues.max_avge_check"
                    hide-details
                    @change="getFilteredClients('avge_check', $event)"
                />
                <div class="side-filter__range-val-wrapper">
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(avgeCheck[0])"
                            label="От"
                            clearable
                            without-preloader
                            @click:clear="$set(avgeCheck, 0, toInt($event)), getFilteredClients('avge_check', avgeCheck)"
                            @change="$set(avgeCheck, 0, toInt($event)), getFilteredClients('avge_check', avgeCheck)"
                        />
                    </div>
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(avgeCheck[1])"
                            label="До"
                            clearable
                            without-preloader
                            @click:clear="$set(avgeCheck, 1, toInt($event)), getFilteredClients('avge_check', avgeCheck)"
                            @change="$set(avgeCheck, 1, toInt($event)), getFilteredClients('avge_check', avgeCheck)"
                        />
                    </div>
                </div>
            </div>

            <div class="side-filter__content-group side-filter__content-group--block">
                <div class="side-filter__content-group-title">
                    Бонусы
                </div>
                <v-range-slider
                    v-model="numberOfBonus"
                    track-color="#CFDDEA"
                    thumb-color="#FFFFFF"
                    dense
                    :min="filterMinMaxValues.min_bonuses"
                    :max="filterMinMaxValues.max_bonuses"
                    hide-details
                    @change="getFilteredClients('number_of_bonus', $event)"
                />
                <div class="side-filter__range-val-wrapper">
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(numberOfBonus[0])"
                            label="От"
                            clearable
                            without-preloader
                            @click:clear="$set(numberOfBonus, 0, toInt($event)), getFilteredClients('number_of_bonus', numberOfBonus)"
                            @change="$set(numberOfBonus, 0, toInt($event)), getFilteredClients('number_of_bonus', numberOfBonus)"
                        />
                    </div>
                    <div class="side-filter__range-val">
                        <InputCustom
                            :value="numberFormat(numberOfBonus[1])"
                            label="До"
                            clearable
                            without-preloader
                            @click:clear="$set(numberOfBonus, 1, toInt($event)), getFilteredClients('number_of_bonus', numberOfBonus)"
                            @change="$set(numberOfBonus, 1, toInt($event)), getFilteredClients('number_of_bonus', numberOfBonus)"
                        />
                    </div>
                </div>
            </div>

            <div class="side-filter__content-group">
                <v-menu ref="date" offset-y :close-on-content-click="false" transition="scale-transition" max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                        <InputCustom
                            :value="datepickerFormatted"
                            label="Последняя покупка"
                            placeholder="Выберите период"
                            left-icon="calendar"
                            readonly
                            clearable
                            without-preloader
                            v-on="on"
                            @click:clear="datepicker = [], filterClients('last_order_date__in', datepicker)"
                        />
                    </template>
                    <v-date-picker
                        v-model="datepicker"
                        no-title
                        range
                        first-day-of-week="1"
                        @input="() => {
                            if (datepicker[0] && datepicker[1]) {
                                $refs.date.save(datepicker);
                                datepicker.sort();
                                filterClients('last_order_date__in', datepicker);
                            }
                        }"
                    />
                </v-menu>
            </div>

            <div class="side-filter__content-group">
                <Button
                    block compact text
                    icon-left="file-minus"
                    @click.prevent="isShowDownloadModal = true"
                >
                    Скачать клиентов в xls
                </Button>
            </div>
        </div>

        <Button class="mt-8" @click="$store.commit('ui/CLOSE_FILTER', 'clients-filter')">
            Смотреть {{ numberFormat(usersCount) }} {{ declOfNum(usersCount, ['клиент', 'клиента', 'клиентов']) }}
        </Button>
        <button class="side-filter__clear-btn" @click.prevent="clearFilter">
            Очистить
        </button>

        <MiniModal
            v-model="isShowDownloadModal"
            title="Вы уверены, что хотите скачать файл?"
            additional-btn-text="Отмена"
            main-btn-text="Скачать"
            @additionalBtnClick="isShowDownloadModal = false"
            @mainBtnClick="downloadFile"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'
import { debounce } from '@/utils/functions';

import { declOfNum } from '@/utils/functions';
import { numberFormat } from '@/utils/format';

import { downloadUsers } from '@/api/django';

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'ClientsFilter',
    components: { MiniModal },
    data() {
        return {
            selectValues: {
                sex: null,
                referral: null,
            },
            sexOptions: [
                { name: 'Все', value: null },
                { name: 'Мужской', value: 'man' },
                { name: 'Женский', value: 'woman' },
                { name: 'Не определён', value: 'True' },
            ],
            referralOptions: [
                { value: null, name: 'Все' },
                { value: 'referral', name: 'Реферал' },
                { value: 'referer', name: 'Рефер' }
            ],
            numOfPurchases: [],
            summOfPurchases: [],
            avgeCheck: [],
            numberOfBonus: [],
            datepicker: [],
            isShowDownloadModal: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            filters: 'clients/filters',
            filterMinMaxValues: 'clients/filterMinMaxValues',
            activeFilters: 'clients/activeFilters',
            sort: 'clients/sort',
            usersCount: 'clients/usersCount',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        datepickerFormatted() {
            if (this.datepicker.length > 1) {
                return this.datepicker.map(i => this.moment(i).format('DD.MM.YYYY')).join(' - ')
            }
            return ''
        }
    },
    mounted() {
        if (this.filters.last_order_date__in) {
            let dates = this.filters.last_order_date__in.split(',');
            this.datepicker = [
                dates[0].split('.').reverse().join('-'),
                dates[1].split('.').reverse().join('-')
            ]
        }

        this.selectValues.sex = this.filters.sex__isnull ? this.filters.sex__isnull : this.filters.sex

        let interval = setInterval(() => {
            if (Object.keys(this.filterMinMaxValues).length) {
                this.setFilterValues();
                clearInterval(interval);
            }
        }, 200);
    },
    methods: {
        setFilterValues() {
            this.numOfPurchases = [this.filterMinMaxValues.min_num_of_purchases, this.filterMinMaxValues.max_num_of_purchases];
            this.summOfPurchases = [this.filterMinMaxValues.min_summ_of_purchases, this.filterMinMaxValues.max_summ_of_purchases];
            this.avgeCheck = [this.filterMinMaxValues.min_avge_check, this.filterMinMaxValues.max_avge_check];
            this.numberOfBonus = [this.filterMinMaxValues.min_bonuses, this.filterMinMaxValues.max_bonuses];
        },
        async filterClients(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                if (field === 'sex') {
                    this.selectValues.sex = value;
                    this.$store.commit('clients/SET_FILTER', { field: 'sex', value: null });
                    this.$store.commit('clients/SET_FILTER', { field: 'sex__isnull', value: null });
                    if (value === 'True') {
                        field = 'sex__isnull'
                    }
                }

                if (field === 'referral') {
                    this.selectValues.referral = value;
                    this.$store.commit('clients/SET_FILTER', { field: 'is_referral_participant', value: null });
                    this.$store.commit('clients/SET_FILTER', { field: 'is_referer', value: null });
                    if (value) {
                        if (value === 'referral') {
                            field = 'is_referral_participant'
                        } else if (value === 'referer') {
                            field = 'is_referer'
                        }
                        value = 'True'
                    }
                }

                if (field !== 'last_order_date__in' && Array.isArray(value)) {
                    this.$store.commit('clients/SET_FILTER', { field: `${field}__gte`, value: value[0] });
                    this.$store.commit('clients/SET_FILTER', { field: `${field}__lte`, value: value[1] });
                    await this.$store.dispatch('clients/GET_USERS');
                    return;
                }

                if (field === 'last_order_date__in') {
                    if (value[0] && value[1]) {
                        value = `${value[0]},${value[1]}`;
                    } else {
                        value = null;
                    }
                }

                await this.$store.dispatch('clients/GET_USERS', { field, value });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        getFilteredClients: debounce(function (field, value) {
            this.filterClients(field, value);
        }, 500),
        async clearFilter() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.$store.commit('clients/CLEAR_FILTERS_STATE');
                this.setFilterValues();
                this.$store.commit('ui/CLOSE_FILTER', 'clients-filter');
                await this.$store.dispatch('clients/GET_USERS', { field: 'ordering', value: `${this.sort.direction}${this.sort.type}` });
                window.scrollTo(0, 0);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async downloadFile() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.isShowDownloadModal = false;

                const filters = await this.$store.dispatch('clients/prepareFilters');
                for (let key in filters) {
                    if (!this.activeFilters.includes(key)) {
                        delete filters[key]
                    }
                }

                const file = await downloadUsers(filters);
                const fileUrl = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                link.download = 'Клиенты.xlsx';
                link.click();
            } catch (e) {
                console.log(e)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки файла', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        numberFormat(num) {
            if (num) return numberFormat(num);
            return 0;
        },
        toInt(str) {
            str = str ? parseInt(str.replace(/\s/g, ''), 10) : 0;
            if (str && !isNaN(str)) {
                return str;
            }
            return 0
        },
        moment,
        declOfNum
    }
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial
            v-if="$route.params.ID"
            v-model="fab_open"
            class="btn__floating custom-speed-dial"
            close-on-click
            fixed
        >
            <template v-slot:activator>
                <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item @click="isOpenBalanceHistoryModal = true">
                        <v-list-item-icon class="mr-3">
                            <v-icon>
                                mdi-history
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>История баланса</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="!authUser.read_only" @click="removeShop">
                        <v-list-item-icon class="mr-3">
                            <v-icon color="error">
                                mdi-delete
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Удалить магазин</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Магазин ${shop.street} ${shop.house}` : 'Новый магазин'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only"
                @click:left="$router.push('/shops')"
                @click:save="saveShop"
            />
            <div class="tabs__list">
                <TabItem :active="tabActive === 0" @click="tabActive = 0">
                    Основное
                </TabItem>
                <TabItem v-if="$route.params.ID" :active="tabActive === 1" @click="tabActive = 1">
                    Касса
                    <div v-if="shop.count_of_unseen_encasement" class="tabs__item-indication">
                        {{ shop.count_of_unseen_encasement }}
                    </div>
                </TabItem>
                <TabItem v-if="$route.params.ID" :active="tabActive === 2" @click="tabActive = 2">
                    Мотивация
                </TabItem>
            </div>
            <div
                class="page-container page-container--with-tabs"
                :class="{'page-container--with-fab': $route.params.ID}"
            >
                <div v-show="tabActive === 0" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <div class="page__info-group">
                                <Row>
                                    <Col v-if="!$route.params.ID">
                                        <InputSearch
                                            label="Город:"
                                            :options="allCities"
                                            @select="shop.city = $event"
                                            @input="cityName = $event, setCoordinatesForMapByText()"
                                        />
                                    </Col>
                                    <Col>
                                        <InputSelect
                                            v-model="shop.type"
                                            :options="shopsTypes"
                                            label="Тип магазина"
                                            input-text="type_name"
                                        />
                                    </Col>
                                    <Col v-if="!shop.work_around_the_clock" :cols="width > 1024 ? 6 : 12">
                                        <v-menu
                                            ref="work_time_start"
                                            :close-on-content-click="false"
                                            :return-value.sync="shop.work_time_start"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <InputCustom
                                                    v-model="shop.work_time_start"
                                                    label="Время работы c"
                                                    v-on="on"
                                                    :error="error && error.work_time_start && shop.work_time_start.startsWith('24') ?
                                                        'Не поддерживается время 24, используйте 00' :
                                                        error && error.work_time_start && !shop.work_time_start.startsWith('24') ? 'Указано неверное время' : ''"
                                                />
                                            </template>
                                            <v-time-picker
                                                v-model="shop.work_time_start"
                                                format="24hr"
                                                full-width
                                                scrollable
                                                @click:minute="$refs.work_time_start.save(shop.work_time_start)"
                                            />
                                        </v-menu>
                                    </Col>
                                    <Col v-if="!shop.work_around_the_clock" :cols="width > 1024 ? 6 : 12">
                                        <v-menu
                                            ref="work_time_end"
                                            :close-on-content-click="false"
                                            :return-value.sync="shop.work_time_end"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <InputCustom
                                                    v-model="shop.work_time_end"
                                                    label="Время работы до"
                                                    v-on="on"
                                                    :error="error && error.work_time_end && shop.work_time_end.startsWith('24') ?
                                                        'Не поддерживается время 24, используйте 00' :
                                                        error && error.work_time_end && !shop.work_time_end.startsWith('24') ? 'Указано неверное время' : ''"
                                                />
                                            </template>
                                            <v-time-picker
                                                v-model="shop.work_time_end"
                                                format="24hr"
                                                full-width
                                                scrollable
                                                @click:minute="$refs.work_time_end.save(shop.work_time_end)"
                                            />
                                        </v-menu>
                                    </Col>
                                </Row>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Адрес</h2>
                                <Row>
                                    <Col :cols="width > 1024 ? 6 : 12">
                                        <InputCustom
                                            v-model="shop.zip_code"
                                            label="Почтовый индекс"
                                            :error="error && error.zip_code ? error.zip_code : ''"
                                        />
                                    </Col>
                                    <Col :cols="width > 1024 ? 6 : 12">
                                        <InputCustom
                                            v-model="shop.street"
                                            label="Улица"
                                            :error="error && error.street ? error.street : ''"
                                            @change="setCoordinatesForMapByText"
                                        />
                                    </Col>
                                    <Col :cols="width > 1024 ? 6 : 12">
                                        <InputCustom
                                            v-model="shop.house"
                                            label="Дом"
                                            :error="error && error.house ? error.house : ''"
                                            @change="setCoordinatesForMapByText"
                                        />
                                    </Col>
                                    <Col :cols="width > 1024 ? 6 : 12">
                                        <InputCustom
                                            v-model="shop.building"
                                            label="Корпус/строение/литера"
                                            @change="setCoordinatesForMapByText"
                                        />
                                    </Col>
                                    <Col :cols="width > 1024 ? 6 : 12">
                                        <InputCustom
                                            v-model="shop.appartement"
                                            label="Офис/помещение"
                                            @change="setCoordinatesForMapByText"
                                        />
                                    </Col>
                                    <Col>
                                        <InputCustom
                                            v-model="shop.coords"
                                            label="Координаты"
                                            :error="error && error.coords ? error.coords : ''"
                                        />
                                    </Col>
                                    <Col>
                                        <div id="franchiseMap" />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div class="page-small-column page-small-column--mobile-top">
                            <div class="checkbox-group">
                                <v-tooltip bottom color="#000">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="checkbox-group__item" v-bind="attrs" v-on="on">
                                            <Checkbox
                                                :value="shop.is_main"
                                                label="Главный магазин"
                                                @change="shop.is_main = !shop.is_main"
                                            />
                                        </div>
                                    </template>
                                    <span>От данного магазина рассчитывается расстояние при доставке за город</span>
                                </v-tooltip>
                                <v-tooltip bottom color="#000">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="checkbox-group__item" v-bind="attrs" v-on="on">
                                            <Checkbox
                                                :value="shop.is_order_default"
                                                label="Назначение доставок"
                                                @change="shop.is_order_default = !shop.is_order_default"
                                            />
                                        </div>
                                    </template>
                                    <span>Назначается автоматически на заказ через 30 минут после его оплаты, если не выбран магазин</span>
                                </v-tooltip>
                                <v-tooltip bottom color="#000">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="checkbox-group__item" v-bind="attrs" v-on="on">
                                            <Checkbox
                                                :value="shop.work_around_the_clock"
                                                label="Круглосуточно"
                                                @change="shop.work_around_the_clock = !shop.work_around_the_clock"
                                            />
                                        </div>
                                    </template>
                                    <span>Магазин работает круглосуточно</span>
                                </v-tooltip>
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="shop.is_view"
                                        label="Показывать на сайте"
                                        @change="shop.is_view = !shop.is_view"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="tabActive === 1" class="tabs__content">
                    <div class="page__info-group">
                        <Row>
                            <Col v-if="$route.params.ID && Object.keys(cashbox).length" :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="cashbox.username"
                                    label="Логин кассы"
                                    :error="error && error.username ? error.username[0] : ''"
                                />
                            </Col>
                            <Col v-if="$route.params.ID && Object.keys(cashbox).length" :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    v-model="cashbox.password"
                                    label="Новый пароль кассы"
                                    :error="error && error.password ? error.password[0] : ''"
                                    @change="passwordChanged = true"
                                />
                            </Col>
                            <Col v-if="shop.balance || shop.balance == 0" :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    :value="(+shop.balance).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB'})"
                                    label="Баланс"
                                    disabled
                                />
                            </Col>
                        </Row>
                    </div>
                    <div class="page__info-group">
                        <h2 class="page__info-group-title">Инкассации</h2>
                        <div v-if="encashments && encashments.length" class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 25%">Название</div>
                                <div class="listing-table__col" style="width: 25%">Дата создания</div>
                                <div class="listing-table__col" style="width: 25%">Сумма</div>
                                <div class="listing-table__col" style="width: 25%">Статус</div>
                            </div>
                            <router-link
                                v-for="item in encashments"
                                :key="item.id"
                                :to="`/finances/encashments/${item.id}`"
                                class="listing-table__row"
                                :class="{'not-seen': !item.is_seen }"
                            >
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                    Инкассация №{{ item.id }}
                                </div>
                                <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 25%' : ''">
                                    <div class="listing-table__col-label">Дата создания</div>
                                    {{ formatDate(item.date_created, 'ru') }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                    <div class="listing-table__col-label">Сумма</div>
                                    {{ formatNumber(+item.amount) }} ₽
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                    <StatusSelect
                                        :value="item.status"
                                        :options="[
                                            { id: 'new', name: 'Новая', color: '#F2C94C', icon: 'clock' },
                                            { id: 'confirmed', name: 'Подтверждена', color: '#36B687', icon: 'check' },
                                            { id: 'cancelled', name: 'Отменена', color: '#EF6A6E', icon: 'close' }
                                        ]"
                                        disabled
                                    />
                                </div>
                            </router-link>
                        </div>
                        <div class="d-flex justify-center mt-4" :class="{'flex-wrap': width <= 699}" style="gap: 16px">
                            <Button
                                v-if="next"
                                bordered
                                icon-right="arrow-down"
                                :block="width <= 699"
                                @click.prevent="loadMoreEncashments"
                            >
                                Загрузить еще
                            </Button>
                            <Button
                                v-if="!authUser.read_only"
                                bordered
                                icon-right="plus"
                                :block="width <= 699"
                                @click.prevent="createEncashment"
                            >
                                Добавить инкассацию
                            </Button>
                        </div>
                    </div>
                </div>
                <div v-show="tabActive === 2" class="tabs__content">
                    <div class="page__info-group">
                        <Row>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    :value="+shop.sales_plans_monthly"
                                    label="План продаж на месяц (руб)"
                                    @input="shop.sales_plans_monthly = $event"
                                />
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <InputCustom
                                    :value="+shop.sales_plans_for_shift"
                                    label="План продаж на смену (руб)"
                                    @input="shop.sales_plans_for_shift = $event"
                                />
                            </Col>
                        </Row>
                    </div>
                    <div class="page__info-group">
                        <div v-if="motivations && motivations.length" class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 33%">Состав</div>
                                <div class="listing-table__col" style="width: 33%">Период активности</div>
                                <div class="listing-table__col" style="width: 33%">Активность</div>
                            </div>
                            <router-link
                                v-for="item in motivations"
                                :key="item.id"
                                :to="`/motivations/${item.id}`"
                                class="listing-table__row"
                            >
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                    <div v-if="item.products.length">
                                        <span v-for="product in item.products" :key="product.product.name"> {{ product.product.name }}, {{ product.percent }}%; </span>
                                    </div>
                                    <span v-else>Товары не указаны</span>
                                </div>
                                <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 33%' : ''">
                                    {{ formatDate(item.active_from, 'ru') }} - {{ formatDate(item.active_to, 'ru') }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                    <div class="d-flex align-center">
                                        <v-icon v-if="width > 1023" :color="item.is_active ? '#36B687' : '#EF6A6E'" class="mr-2">
                                            {{ item.is_active ? 'mdi-check-circle' : 'mdi-close-circle' }}
                                        </v-icon>
                                        <span :style="{'color': item.is_active ? '#36B687' : '#EF6A6E'}">
                                            {{ item.is_active ? 'Активна' : 'Не активна' }}
                                        </span>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                        <empty-table v-show="motivations && !motivations.length" />
                    </div>
                </div>
            </div>
        </div>
        <UniversalModal v-model="isOpenBalanceHistoryModal" size="full">
            <ShopBalanceModal @closeModal="isOpenBalanceHistoryModal = false" />
        </UniversalModal>
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import L from 'leaflet';
// import { basemapLayer } from 'esri-leaflet';
import { geocode, reverseGeocode } from 'esri-leaflet-geocoder';
import 'leaflet/dist/leaflet.css';

import { getCashboxes, changePasswordUser, updateUser } from '@/api/django';
import { date, numberFormat } from '@/utils/format';

import InputSearch from '@/components/inputs/InputSearch';
import ShopBalanceModal from '@/components/app/shop/ShopBalanceModal'

// let MAP_SEARCH_TIMEOUT_ID = null;
let franchiseMap = null;
let franchiseMarker = null;

export default {
    name: 'TheShopCreate',
    components: { InputSearch, ShopBalanceModal },
    metaInfo() {
        return { title: this.$route.params.ID ? `Магазин ${this.shop.street} ${this.shop.house}` : 'Новый магазин' }
    },
    data: () => ({
        shop: {
            appartement: '',
            building: '',
            city: '',
            coords: '',
            house: '',
            is_main: false,
            is_order_default: false,
            is_view: true,
            sales_plans_monthly: 0,
            sales_plans_for_shift: 0,
            street: '',
            type: null,
            work_around_the_clock: false,
            work_time_start: '',
            work_time_end: '',
            zip_code: '',
        },
        cityName: {},
        error: {},
        fab_open: false,
        cashbox: {},
        passwordChanged: false,
        tabActive: 0,
        isOpenBalanceHistoryModal: false
    }),
    computed: {
        ...mapGetters({
            allCities: 'data/allCities',
            width: 'ui/width',
            preloader: 'ui/preloader',
            encashments: 'encashments/encashments',
            next: 'encashments/next',
            motivations: 'motivations/motivations',
            busyMotivation: 'motivations/busy',
            nextMotivation: 'motivations/next',
            authUser: 'auth/user',
            shopsTypes: 'shops/shopsTypes'
        })
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.name === 'encashment' || from.name === 'encashment-create') vm.tabActive = 1;
            if (from.name === 'motivation' || from.name === 'motivation-create') vm.tabActive = 2;
        });
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await this.$store.dispatch('data/LOAD_ALL_CITIES');
            if (this.$route.params.ID) {
                const res = await this.$store.dispatch('shops/GET_SHOP_DATA', { shopID: this.$route.params.ID, with_balance: true });
                this.shop = res;
                this.cityName.russian_name = this.allCities.find((item) => item.id == this.shop.city).russian_name;

                if (this.shop.coords !== '') {
                    this.initMap();
                }
                const cashbox = await getCashboxes(this.$route.params.ID);
                if (cashbox.length) {
                    this.cashbox = cashbox[0];
                    this.cashbox.password = '';
                }
                this.$store.dispatch('encashments/GET_ENCASHMENTS_FOR_SHOP', this.$route.params.ID);
                this.$store.dispatch('motivations/GET_MOTIVATIONS', { field: 'shop', value: this.$route.params.ID });
            } else {
                setTimeout(() => {
                    this.initMap();
                }, 1000);
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        this.$store.commit('motivations/CLEAR_FILTERS_STATE');
    },
    methods: {
        initMap() {
            let coords = [54.989347, 73.368221];
            if (this.shop.coords !== '') {
                coords = [+this.shop.coords.split(',')[0], +this.shop.coords.split(',')[1]]
            }
            franchiseMap = L.map('franchiseMap').setView(coords, this.shop.coords !== '' ? 15 : 4);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(franchiseMap);
            // basemapLayer('Streets').addTo(franchiseMap);  старая карта
            const customIconMarker = L.icon({
                iconUrl: require('@/assets/img/icon/mapMarker.svg'),
                iconSize: [46, 46],
                iconAnchor: [22, 46]
            });

            franchiseMarker = L.marker(coords, { icon: customIconMarker }).addTo(franchiseMap);

            franchiseMap.on('click', (e) => {
                franchiseMarker.setLatLng(e.latlng);
                this.shop.coords = `${+(e.latlng.lat.toString().slice(0, 9))},${+(e.latlng.lng.toString().slice(0, 9))}`;
                reverseGeocode().latlng(e.latlng).run((error, result) => {
                    if (error) {
                        console.log(error);
                        return;
                    }
                    if (result.address.Postal) {
                        this.shop.zip_code = result.address.Postal;
                    }
                    geocode().text(result.address.LongLabel).run((error, res) => {
                        if (error) {
                            console.log(error);
                            return;
                        }
                        if (res.results && res.results[0] && res.results[0].properties.StName) {
                            this.shop.street = res.results[0].properties.StName
                            if (res.results[0].properties.StPreType !== 'улица') {
                                this.shop.street += ` ${res.results[0].properties.StPreType}`
                            }
                        }
                    });
                });
            })
            // Установка маркера происходила не по клику, а при движении карты
            // franchiseMap.on('move', (e) => {
            //     center = franchiseMap.getCenter();
            //     franchiseMarker.setLatLng(center);
            //     this.shop.coords = `${+(center.lat.toString().slice(0, 9))},${+(center.lng.toString().slice(0, 9))}`;
            // });
        },
        setCoordinatesForMapByText() {
            const text = `${this.cityName.russian_name} ${this.shop.street} ${this.shop.house} ${this.shop.building} ${this.shop.appartement}`;
            if (text && !text.trim().includes('undefined')) {
                geocode().text(text).run((error, res) => {
                    if (error) {
                        console.log(error);
                        return;
                    }
                    if (res.results.length > 0) {
                        franchiseMap.fitBounds(res.results[0].bounds);
                        franchiseMarker.setLatLng(res.results[0].latlng);
                        this.shop.coords = `${res.results[0].latlng.lat},${res.results[0].latlng.lng}`;
                    }
                });
            }
        },
        async saveShop() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                if (this.shop.work_around_the_clock) {
                    this.shop.work_time_start = '00:00';
                    this.shop.work_time_end = '23:59';
                }
                if (this.$route.params.ID) {
                    if (this.cashbox.id) {
                        await this.saveCashbox();
                    }

                    await this.$store.dispatch('shops/EDIT_SHOP', { shopID: this.$route.params.ID, data: this.shop })
                } else {
                    await this.$store.dispatch('shops/CREATE_SHOP', this.shop)
                }
                this.$router.push('/shops');
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Магазин сохранен', type: 'success' })
            } catch (error) {
                console.log(error);
                if (error.data) {
                    this.error = error.data;
                    if (error.data.is_main) {
                        this.shop.is_main = false;
                        this.$store.commit('ui/add_item_in_system_notifications', { message: this.error.is_main[0], type: 'error' })
                    }
                    if (error.data.is_order_default) {
                        this.shop.is_order_default = false;
                        this.$store.commit('ui/add_item_in_system_notifications', { message: this.error.is_order_default[0], type: 'error' })
                    }
                    if (error.data.error && error.data.error === 'Максимальное количество магазинов для этого тарифа') {
                        this.$store.commit('ui/add_item_in_system_notifications', { message: error.data.error, type: 'error' })
                    }
                }
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async removeShop() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('shops/REMOVE_SHOP', this.$route.params.ID)
                this.$router.push('/shops');
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Магазин удален', type: 'success' })
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async saveCashbox() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (this.$route.params.ID) {
                    if (this.passwordChanged) {
                        await changePasswordUser(this.cashbox.id, { password: this.cashbox.password });
                    }
                    await updateUser(this.cashbox.id, { username: this.cashbox.username || null });
                }
            } catch (error) {
                this.error = error.data;
                throw error;
            }
        },
        pushToChanges(item) {
            if (item.name === 'encasement') this.$router.push(`/finances/encashments/${item.id}`);
            if (item.name === 'order') this.$router.push(`/orders/${item.id}`);
        },
        createEncashment() {
            this.$store.dispatch('ui/checkUserEditPermission');
            this.$store.commit('encashments/SET_SHOP_FOR_ENCASHMENT', this.$route.params.ID);
            this.$router.push('/finances/encashments/create');
        },
        loadMoreEncashments() {
            this.$store.dispatch('encashments/LOAD_MORE_FOR_SHOP', this.$route.params.ID);
        },
        formatNumber(num) {
            if (num) {
                return numberFormat(num);
            } else {
                return 0;
            }
        },
        formatDate(value, type) {
            return date(new Date(value), type);
        },
    }
};
</script>

<style lang="sass">
    .app-input-label
        flex-shrink: 0
        margin-right: 10px
        color: #666666

    .worktime-input
        width:65px
        font-size: 14px
        line-height: 30px
        margin-right:20px
        background: #FFFFFF
        border: 1px solid #F1F2F4
        border-radius: 3px
        padding: 0 0 0 14px
        &:hover
            border: 1px solid #A1A9B4
        &:focus
            border: 1px solid #1a73e8
</style>

/* eslint-disable */
import router from '@/router';

import {
    fetchFranchises,
    fetchFranchiseData,
    editFranchise,
    createFranchise,
    removeFranchise,
    getFilterMinMaxValuesFranchise,
    getFranchiseTarif
} from '@/api/django';

const state = {
    franchises: {},
    filters: {
        limit: 20,
        offset: 0,
        ordering: '-id',
        search: '',
        tariff: null,
        days_until_end__range: null
    },
    activeFilters: [],
    next_page: false,
    busy: false,
    payment_list:[],
    sort: {
        type: 'id',
        direction: '-'
    },
    filterMinMaxValues: {
        days_until_end: {}
    },
    franchiseTariff: {}
};

const mutations = {
    SET_FRANCHISES(state, franchises) {
        state.franchises = franchises.results;
        state.next_page = !!franchises.next;
        state.filters.offset = 20;
    },
    SET_NEXT_FRANCHISES(state, { results, next }) {
        state.franchises.push(...results);
        state.next_page = next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    CLEAR(state) {
        state.franchises = {};
        state.next_page = false;
        state.busy = false;
        state.filters.offset = 20;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            ordering: '-id',
            search: '',
            tariff: null,
            days_until_end__range: null
        },
        state.next_page = false;
    },
    SET_PAYMENT_TYPE_LIST(state, payment_list) {
        state.payment_list = state.payment_list.concat(payment_list);
    },
    ADD_TYPE_FROM_PAYMENT_LIST(state, type) {
        state.payment_list.push(type);
    },
    REMOVE_TYPE_FROM_PAYMENT_LIST(state, type) {
        state.payment_list = state.payment_list.filter(item => item.id !== type.id);
    },
    CLEAR_PAYMENT_TYPE_LIST(state) {
        state.payment_list = [];
    },
    CHANGE_SORT(state, sort) {
        state.sort.type = sort.type;
        state.sort.direction = sort.direction;
        state.filters.ordering = `${sort.direction}${sort.type}`;
        let sortObj = JSON.parse(localStorage.getItem('sort'));
        sortObj.franchises = sort;
        localStorage.setItem('sort', JSON.stringify(sortObj));
    },
    SET_FILTER_MIN_MAX_VALUES(state, data) {
        state.filterMinMaxValues = data;
    },
    SET_FRANCHISE_TARIFF(state, tariff) {
        state.franchiseTariff = tariff;
    }
};

const actions = {
    async GET_FRANCHISE_TARIFF ({ commit }) {
        try {
            const res = await getFranchiseTarif();
            localStorage.setItem('tariff', JSON.stringify(res));
            commit('SET_FRANCHISE_TARIFF', res);   
        } catch (error) {
            if (location.pathname !== '/unavailable') {
                localStorage.setItem('redirectUnavailable', location.pathname);
            }
            router.push('/unavailable');
        }
    },
    async LOAD_FRANCHISES({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;

            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;

            let filters = await dispatch('prepareFilters');
            const res = await fetchFranchises(filters);
            commit('SET_FRANCHISES', res);

            state.busy = false;
        } catch (error) {
            console.log(error);
        }
    },
    async GET_NEXT_PAGE({ state, commit, dispatch }) {
        try {
            if (state.next_page && !state.busy) {
                state.busy = true;
                let filters = await dispatch('prepareFilters');
                const res = await fetchFranchises(filters);
                commit('SET_NEXT_FRANCHISES', res);
            }
        } catch (error) {
            console.log(error);
        }
    },
    async LOAD_FRANCHISE_DATA({ commit }, id) {
        const results = await fetchFranchiseData(id);
        return results;
    },
    async EDIT_FRANCHISE({ commit }, data) {
        return await editFranchise(data.id, data.franchise);
    },
    async CREATE_FRANCHISE({ commit }, data) {
        return await createFranchise(data);
    },
    async REMOVE_FRANCHISE({ commit }, id) {
        return await removeFranchise(id);
    },
    EVENT_HANDLER({ commit, getters }, type) {
        if (getters['selected'](type.id)) {
            commit('REMOVE_TYPE_FROM_PAYMENT_LIST', type);
        } else {
            commit('ADD_TYPE_FROM_PAYMENT_LIST', type)
        }
    },
    async GET_FILTER_MIN_MAX_VALUES({commit}) {
        try {
            const values = await getFilterMinMaxValuesFranchise();
            commit('SET_FILTER_MIN_MAX_VALUES', values);
        } catch (error) {
            console.log(error);
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([key, value]) => {
            if (key && (value || value === false || value === 0)) {
                filters[key] = value;
            }
        })
        let activeFilters = Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    franchises: state => state.franchises,
    payment_list: state => state.payment_list,
    selected: state => id => !!state.payment_list.find(item => item.id === id),
    next: state => !!state.next_page,
    busy: state => state.busy,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    sort: state => state.sort,
    filterMinMaxValues: state => state.filterMinMaxValues,
    franchiseTariff: state => state.franchiseTariff
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <div v-if="['florist', 'supervisor', 'administrators', 'superadministrators'].includes(group)" class="products-navbar__wrap">
        <router-link
            v-if="(group === 'supervisor' || group === 'administrators')"
            to="/tags"
            class="products-navbar__link"
        >
            <Icon>tag</Icon>
            <div class="products-navbar__link-text">Теги</div>
        </router-link>
        <router-link
            v-if="!authUser.read_only"
            to="/goods/create"
            class="products-navbar__link products-navbar__link--create"
        >
            <Icon color="#0d63f3">plus-circle</Icon>
            <div class="products-navbar__link-text">Создать товар</div>
        </router-link>
        <div
            v-if="!authUser.read_only && goodsCount && (group === 'supervisor' || group === 'administrators')"
            class="products-navbar__link"
            @click.prevent="exportGoods"
        >
            <Icon>download</Icon>
            <div class="products-navbar__link-text">Выгрузить</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { exportGoodsToExcel } from '@/api/django';

export default {
    name: 'ProductsNavbar',
    computed: {
        ...mapGetters({
            width: 'ui/width',
            authUser: 'auth/user',
            goodsCount: 'goods/goodsCount',
        }),
        group() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
    },
    methods: {
        async exportGoods() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                const file = await exportGoodsToExcel();
                const data = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = data;
                link.target = '_blank';
                link.download = 'products.csv';
                link.click();

                setTimeout(() => {
                    window.URL.revokeObjectURL(data);
                }, 1000);
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка экспорта товаров', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        }
    }
}
</script>

<template>
    <div v-if="!preloader" @click="isShowDropdown = false">
        <template v-if="images.length">
            <div class="photo-slider__main-photo">
                <img
                    :src="images[activeImage].image && images[activeImage].image.preview ? images[activeImage].image.preview : require('@/assets/img/flower.svg')"
                    alt=""
                    @click.prevent="clickPhotoHandler"
                >
                <v-tooltip v-if="withCheckbox" bottom color="#000">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="photo-slider__main-photo-checkbox"
                            :class="{'active': pickedImages.includes(images[activeImage].id)}"
                            icon
                            v-bind="attrs" v-on="on"
                            @click.prevent="clickPhotoHandler"
                        >
                            <v-icon>
                                {{ !pickedImages.includes(images[activeImage].id) ? 'mdi-check-circle-outline' : 'mdi-check-circle'}}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Выбрать</span>
                </v-tooltip>
                <div v-if="images[activeImage].image && images[activeImage].image.preview" class="photo-slider__main-photo-dropdown-wrap">
                    <button type="button" class="photo-slider__main-photo-dropdown-btn" @click.stop.prevent="isShowDropdown = true">
                        <Icon color="#4A8AF6">more</Icon>
                    </button>
                    <transition name="fade">
                        <div v-if="isShowDropdown" class="photo-slider__main-photo-dropdown">
                            <button
                                type="button"
                                class="photo-slider__main-photo-dropdown-item"
                                @click.prevent="openPhoto(images[activeImage].image.original)"
                            >
                                <Icon color="#8AACCD">eye</Icon>
                                Посмотреть
                            </button>
                            <template v-if="images.length > 1">
                                <button
                                    v-if="activeImage"
                                    type="button"
                                    class="photo-slider__main-photo-dropdown-item"
                                    @click.prevent="$emit('changeSort', { index: activeImage, direction: 'main'}, activeImage = 0)"
                                >
                                    <Icon color="#8AACCD">home</Icon>
                                    Сделать основным
                                </button>
                                <button
                                    v-if="activeImage"
                                    type="button"
                                    class="photo-slider__main-photo-dropdown-item"
                                    @click="$emit('changeSort', { index: activeImage, direction: 'left'}), activeImage--"
                                >
                                    <Icon color="#8AACCD">arrow-up</Icon>
                                    Сместить вперед
                                </button>
                                <button
                                    v-if="activeImage !== (images.length - 1)"
                                    type="button"
                                    class="photo-slider__main-photo-dropdown-item"
                                    @click="$emit('changeSort', { index: activeImage, direction: 'right'}), activeImage++"
                                >
                                    <Icon color="#8AACCD">arrow-down</Icon>
                                    Сместить назад
                                </button>
                                <button
                                    type="button"
                                    class="photo-slider__main-photo-dropdown-item"
                                    @click.prevent="$emit('remove', activeImage), activeImage ? activeImage-- : 0"
                                >
                                    <Icon color="#8AACCD">trash</Icon>
                                    Удалить
                                </button>
                            </template>
                        </div>
                    </transition>
                </div>
            </div>
            <div ref="sliderContainer" class="photo-slider__images-list-container" @touchstart.stop>
                <div class="photo-slider__images-list-wrap">
                    <div ref="sliderImages" class="photo-slider__images-list hide-scrollbar">
                        <div
                            v-for="(item, index) in images"
                            :key="item.id"
                            class="photo-slider__images-list-item"
                            :class="{
                                'active': activeImage === index,
                                'checked': pickedImages.includes(item.id)
                            }"
                            @click.prevent="activeImage = index"
                        >
                            <img
                                :src="item.image && item.image.preview ? item.image.preview : require('@/assets/img/flower.svg')"
                                alt=""
                            >
                            <Icon
                                v-show="pickedImages.includes(item.id)"
                                class="photo-slider__images-list-item-checkbox"
                                size="35"
                                color="#fff"
                            >
                                check
                            </Icon>
                        </div>
                    </div>
                </div>
                <button v-show="scrollData.currentScroll" type="button" class="photo-slider__arrow left" @click.prevent="scrollSlider('left')">
                    <Icon color="#8AACCD">chevron-left</Icon>
                </button>
                <button v-show="scrollData.currentScroll < scrollData.maxScroll" type="button" class="photo-slider__arrow right" @click.prevent="scrollSlider('right')">
                    <Icon color="#8AACCD">chevron-right</Icon>
                </button>
            </div>
        </template>
        <label v-if="withNewPhotoBtn" class="photo-slider__input-wrap">
            <input
                ref="newPhotoInput"
                type="file"
                accept="image/*"
                @change="$emit('newPhoto', $event)"
            >
            <Button text block icon-left="camera" @click="$refs.newPhotoInput.click()">
                Загрузить фото
            </Button>
            <!--<div class="action-btn">
                <Icon color="#0D63F3">camera</Icon>
                Загрузить фото
            </div>-->
        </label>
    </div>
    <ContentPreloader v-else height="400" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'PhotoSlider',
    props: {
        images: { type: Array, default: () => [], required: true },
        withNewPhotoBtn: { type: Boolean, default: false },
        withCheckbox: { type: Boolean, default: false },
        pickedImages: { type: Array, default: () => [] }
    },
    data() {
        return {
            activeImage: 0,
            isShowDropdown: false,
            scrollData: {
                currentScroll: 0,
                maxScroll: 0,
                scrollContainer: 0
            }
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
        }),
    },
    watch: {
        'images.length'() {
            this.setScrollData();
        }
    },
    mounted() {
        this.setScrollData();
        window.addEventListener('resize', this.setScrollData);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setScrollData);
    },
    methods: {
        clickPhotoHandler() {
            if (!this.withCheckbox) {
                this.openPhoto(this.images[this.activeImage].image.original)
            } else {
                this.$emit('checkPhoto', this.images[this.activeImage].id);
            }
        },
        setScrollData() {
            let interval = setInterval(() => {
                if (this.$refs.sliderContainer) {
                    clearInterval(interval);
                    this.scrollData.currentScroll = 0;
                    this.scrollData.scrollContainer = this.$refs.sliderContainer.clientWidth;
                    this.scrollData.maxScroll = this.$refs.sliderImages.scrollWidth - this.scrollData.scrollContainer;
                    this.$refs.sliderImages.scrollTo({ left: 0, behavior: 'smooth'});
                }
            }, 150);
        },
        scrollSlider(direction) {
            let offset = this.width > 1280 ? 350 : 265;
            if (direction === 'left') {
                this.scrollData.currentScroll = this.scrollData.currentScroll - offset < 0 ? 0 : this.scrollData.currentScroll - offset;
                this.$refs.sliderImages.scrollTo({ left: this.scrollData.currentScroll, behavior: 'smooth'});
            } else if (direction === 'right') {
                this.scrollData.currentScroll = this.scrollData.currentScroll + offset > this.scrollData.maxScroll ? this.scrollData.maxScroll :
                    this.scrollData.currentScroll + offset;
                this.$refs.sliderImages.scrollTo({ left: this.scrollData.currentScroll, behavior: 'smooth'});
            }
        },
        openPhoto(link) {
            window.open(link, '_blank');
        }
    }
}
</script>
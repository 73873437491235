/* eslint-disable */
import { getAllCategories } from '@/api/django';

const state = {
    equipment_categories: [],
    all_equipments: {},
    next_page: '',
    busy: '',
    offset: 0,
    equipment_set: [],
    equipment_set_server: [],
    current_category_id: null
};

const mutations = {
    SET_ALL_EQUIPMENTS(state, equipments) {
        state.all_equipments = equipments;
        state.next_page = equipments.next;
        state.busy = equipments.next;
        state.offset = state.offset + 20;
    },
    SET_NEXT_EQUIPMENTS(state, { results, next }) {
        state.all_equipments.results.push(...results);
        state.all_equipments.next = next;
        state.next_page = next;
        state.busy = next;
        state.offset = state.offset + 20;
    },
    SET_EQUIPMENT(state, equipment_set) {
        state.equipment_set = equipment_set.sort((a, b) => a.sort - b.sort);
        state.equipment_set.forEach(item => {
            if (item.price == null) {
                item.price = item.equipment_product.price
            }
        })
    },
    SET_EQUIPMENT_SERVER(state, equipment_set) {
        state.equipment_set_server = equipment_set;
    },
    REMOVE_GOOD_FROM_EQUIPMENT(state, vendor_code) {
        state.equipment_set = state.equipment_set.filter(equipment => equipment.equipment_product.vendor_code !== vendor_code);
        state.equipment_set_server = state.equipment_set_server.filter(equipment => equipment.equipment_product !== vendor_code);
    },
    SET_EQUIPMENT_CATEGORIES(state, categories) {
        state.equipment_categories = categories.filter(category => category.is_equipment);
    },
    ADD_TO_EQUIPMENT(state, { vendor_code, name, price, show_amount, images, price_change, count, is_calculate = true, display_amount = true }) {
        let sortNumber = state.equipment_set.length ? state.equipment_set[state.equipment_set.length - 1].sort + 1 : 1;
        state.equipment_set.push({
            equipment_product: { vendor_code, name, price, show_amount, images : [images[0]], price_change },
            price,
            count: +count || 1,
            sort: sortNumber,
            is_calculate,
            display_amount
        });
        state.equipment_set_server.push({
            equipment_product: vendor_code,
            count: +count || 1,
            show_amount,
            is_calculate,
            display_amount,
            sort: sortNumber,
            price
        });
    },
    CHANGE_VALUE_FIELD(state, { vendor_code, value, field }) {
        state.equipment_set.forEach((item, index) => {
            if (item.equipment_product.vendor_code === vendor_code) {
                item[field] = value;
                state.equipment_set_server[index][field] = value;
            }
        })
    },
    CLEAR_EQUIPMENTS(state) {
        state.equipment_set = [];
        state.equipment_set_server = [];
    },
    SET_CURRENT_CATEGORY_ID(state, category_id) {
        state.current_category_id = category_id;
    },
    UPDATE_EQUIPMENT_SET(state, set) {
        state.equipment_set = set;
        state.equipment_set_server.forEach(prod => {
            prod.sort = set.find(p => p.equipment_product.vendor_code === prod.equipment_product).sort;
        })
    },
    PATCH_SET(state, set) {
        set.forEach(item => {
            if (state.equipment_set_server.find(p => p.equipment_product === item.equipment_product.vendor_code)) {
                let elem = state.equipment_set_server.find(p => p.equipment_product === item.equipment_product.vendor_code);
                elem.display_amount = item.display_amount;
                elem.is_calculate = item.is_calculate;
            }
        })
    }
};

const actions = {
    FILTERED_EQUIPMENT_SET({ commit }, vendor_code) {
        commit('REMOVE_GOOD_FROM_EQUIPMENT', vendor_code);
    },
    async FILTERED_CATEGORIES({ commit }) {
        try {
            const results = await getAllCategories();
            commit('SET_EQUIPMENT_CATEGORIES', results);
        } catch (error) {}
    },
    ADD_TO_EQUIPMENTS({ commit, getters }, good) {
        if (getters['selected'](good.vendor_code)) {
            commit('REMOVE_GOOD_FROM_EQUIPMENT', good.vendor_code);
        } else {
            commit('ADD_TO_EQUIPMENT', good);
        }
    },
    TRANSFORM_EQUIPMENT_SET({ commit }, { equipment_set }) {
        let set = [];
        equipment_set.forEach(equipment => {
            set.push({...equipment, equipment_product: equipment.equipment_product.vendor_code });
        });
        commit('SET_EQUIPMENT_SERVER', set);
    }
};

const getters = {
    all_equipments: state => state.all_equipments,
    next: state => !!state.next_page,
    busy: state => !state.busy,
    offset: state => state.offset,
    equipment_set: state => state.equipment_set,
    equipment_set_server: state => state.equipment_set_server,
    equipment_categories: state => state.equipment_categories,
    selected: state => id => !!state.equipment_set.find(good => good.equipment_product.vendor_code === id),
    current_category_id: state => state.current_category_id,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

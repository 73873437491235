<template>
    <div class="help__sidebar">
        <div v-for="(item, index) in links" :key="index" class="help__sidebar-link-wrap">
            <a
                v-if="!item.groups || item.groups.includes(authUserGroup)"
                :href="`#${item.link}` || ''"
                class="help__sidebar-link"
                :class="{'active': routeHash === `#${item.link}`}"
                @click.prevent="smoothScroll(item.link)"
            >
                {{ item.name }}
            </a>
            <template v-if="item.children && item.children.length">
                <a
                    v-for="(child, i) in item.children" :key="i"
                    v-show="(!child.groups || child.groups.includes(authUserGroup))"
                    :href="`#${child.link}` || ''"
                    class="help__sidebar-link help__sidebar-link--level-2"
                    :class="{'active': routeHash === `#${child.link}`}"
                    @click.prevent="smoothScroll(child.link)"
                >
                    {{ child.name }}
                </a>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelpNavigationFooter',
    props: {
        links: { type: Array, default: () => [], required: true }
    },
    data() {
        return {
            routeHash: this.$route.hash
        }
    },
    computed: {
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    mounted() {
        setTimeout(() => {
            if (this.routeHash) {
                this.smoothScroll(this.routeHash.slice(1))
            }
        }, 500)
    },
    methods: {
        smoothScroll(link) {
            const findEl = document.getElementById(link);
            findEl.scrollIntoView({ behavior: 'smooth'});
            history.pushState(null, null, `#${link}`);
            this.routeHash = `#${link}`;
        }
    }
}
</script>

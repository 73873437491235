<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Интеграция Facebook"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.back()"
            />
            <div class="page-container">
                <div class="mb-3">Для подключение вашего каталога к Facebook выполните последующие действия:</div>
                <ol class="page-content__ol">
                    <li>Зайдите в бизнес кабинет Facebook <a href="https://business.facebook.com">https://business.facebook.com</a></li>
                    <li>Выберите пункт в меню "Catalog Manager" и перейдите в него</li>
                    <li>Нажмите создать каталог</li>
                    <li>Выберите тип электронная коммерция</li>
                    <li>Выберите способ загрузки "Загрузить информацию о товарах"</li>
                    <li>В качестве владельца каталога укажите текущий рекламный аккаунт</li>
                    <li>Перейдите в созданный каталог</li>
                    <li>Откройте пункт меню "Источники данных" и нажмите "Добавить товары"</li>
                    <li>Выберите "Использовать массовую загрузку"</li>
                    <li>Выберите пункты по порядку:
                        <ul class="mt-3">
                            <li>обновление фида по графику</li>
                            <li>в качестве URL укажите https://prod.gateway.lavandaflor.ru/main/api/v1/sync/facebook/?referer=https://{{ franchiseTariff.site_domain }}</li>
                            <li>график обновления "Ежедневно"</li>
                            <li>валюта Рубль</li>
                            <li>загрузить</li>
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'TheIntegrationsFb',
    metaInfo: {
        title: 'Интеграция с FB'
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
            franchiseTariff: 'franchises/franchiseTariff',
        })
    },
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Статистика"
                left-btn="menu"
                :show-left-btn="width <= 1024"
                :show-save-btn="false"
                @click:left="openNavigation"
            />
            <div class="page-container">
                <FastFilters :filters="fastFilters" @change="fastFilterApply($event)" />
                <div class="statistic__container">
                    <Row>
                        <Col :cols="width >= 600 ? 6 : 12">
                            <v-menu
                                ref="statisticDatepicker"
                                offset-y
                                :close-on-content-click="false"
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <InputCustom
                                        :value="statisticDatesFormated"
                                        label="Дата"
                                        left-icon="calendar"
                                        readonly
                                        without-preloader
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker
                                    v-model="statisticDates"
                                    no-title
                                    range
                                    first-day-of-week="1"
                                    @input="() => {
                                        if (statisticDates[0] && statisticDates[1]) {
                                            $refs.statisticDatepicker.save(statisticDates)
                                        }
                                    }"
                                    @change="changeStatisticDates"
                                />
                            </v-menu>
                        </Col>
                        <Col :cols="width >= 600 ? 6 : 12">
                            <InputSelect
                                v-model="filters.sources"
                                :options="sources"
                                label="Источник"
                                multiple
                                icon="archive-tray"
                                searchable
                                without-preloader
                                @blur="updateStatistic"
                            />
                        </Col>
                        <Col :cols="width >= 600 ? 6 : 12">
                            <InputSelect
                                v-model="filters.shop"
                                :options="shopsWithEmpty"
                                label="Магазин"
                                icon="store"
                                input-text="address"
                                input-icon="type_icon"
                                without-preloader
                                @input="updateStatistic"
                            />
                        </Col>
                        <Col :cols="width >= 600 ? 6 : 12">
                            <InputSelect
                                v-model="filters.florist_id"
                                :options="floristOptions"
                                label="Флорист"
                                icon="user"
                                input-text="first_name"
                                searchable
                                without-preloader
                                @input="updateStatistic"
                            />
                        </Col>
                    </Row>
                    <div v-show="!preloader" class="statistic__parameters">
                        <div
                            v-if="mainStatistic.income"
                            class="statistic__parameters-item"
                            :class="{'active': currentMainChart === 'income'}"
                            @click="changeCurrentMainChart(mainStatistic, 'income')"
                        >
                            <div class="statistic__parameters-item-title">Прибыль</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{
                                        reduceParam(mainStatistic.income.current) ?
                                            `${formatNumber(Math.round(reduceParam(mainStatistic.income.current)))} ₽` : '-'
                                    }}
                                </div>
                                <div
                                    v-if="reduceParam(mainStatistic.income.current)"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': reduceParam(mainStatistic.income.current) > reduceParam(mainStatistic.income.old)}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (reduceParam(mainStatistic.income.old) * 100 / reduceParam(mainStatistic.income.current))).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="mainStatistic.revenue"
                            class="statistic__parameters-item"
                            :class="{'active': currentMainChart === 'revenue'}"
                            @click="changeCurrentMainChart(mainStatistic, 'revenue')"
                        >
                            <div class="statistic__parameters-item-title">Выручка</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{ reduceParam(mainStatistic.revenue.current) ? `${formatNumber(Math.round(reduceParam(mainStatistic.revenue.current)))} ₽` : '-' }}
                                </div>
                                <div
                                    v-if="reduceParam(mainStatistic.revenue.current)"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': reduceParam(mainStatistic.revenue.current) > reduceParam(mainStatistic.revenue.old)}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (reduceParam(mainStatistic.revenue.old) * 100 / reduceParam(mainStatistic.revenue.current))).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="mainStatistic.amount_of_orders"
                            class="statistic__parameters-item"
                            :class="{'active': currentMainChart === 'amount_of_orders'}"
                            @click="changeCurrentMainChart(mainStatistic, 'amount_of_orders')"
                        >
                            <div class="statistic__parameters-item-title">Продажи</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{
                                        reduceParam(mainStatistic.amount_of_orders.current) ?
                                            `${formatNumber(Math.round(reduceParam(mainStatistic.amount_of_orders.current)))} шт.` : '-'
                                    }}
                                </div>
                                <div
                                    v-if="reduceParam(mainStatistic.amount_of_orders.current)"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': reduceParam(mainStatistic.amount_of_orders.current) > reduceParam(mainStatistic.amount_of_orders.old)}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (reduceParam(mainStatistic.amount_of_orders.old) * 100 / reduceParam(mainStatistic.amount_of_orders.current))).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="mainStatistic.avg_order_cost"
                            class="statistic__parameters-item"
                            :class="{'active': currentMainChart === 'avg_order_cost'}"
                            @click="changeCurrentMainChart(mainStatistic, 'avg_order_cost')"
                        >
                            <div class="statistic__parameters-item-title">Средний чек</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{
                                        reduceParam(mainStatistic.avg_order_cost.current) ?
                                            `${formatNumber(Math.round(reduceParam(mainStatistic.avg_order_cost.current) / Object.keys(mainStatistic.avg_order_cost.current).length))} ₽` :
                                            '-'
                                    }}
                                </div>
                                <div
                                    v-if="reduceParam(mainStatistic.avg_order_cost.current)"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': reduceParam(mainStatistic.avg_order_cost.current) > reduceParam(mainStatistic.avg_order_cost.old)}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{
                                            `${(100 - ((reduceParam(mainStatistic.avg_order_cost.old) / Object.keys(mainStatistic.avg_order_cost.old).length) * 100 / (reduceParam(mainStatistic.avg_order_cost.current) / Object.keys(mainStatistic.avg_order_cost.current).length))).toFixed(0)} %`
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="mainStatistic.amount_of_returns"
                            class="statistic__parameters-item"
                            :class="{'active': currentMainChart === 'amount_of_returns'}"
                            @click="changeCurrentMainChart(mainStatistic, 'amount_of_returns')"
                        >
                            <div class="statistic__parameters-item-title">Возвраты</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{
                                        reduceParam(mainStatistic.amount_of_returns.current) ?
                                            `${formatNumber(Math.round(reduceParam(mainStatistic.amount_of_returns.current)))} шт.` : '-'
                                    }}
                                </div>
                                <div
                                    v-if="reduceParam(mainStatistic.amount_of_returns.current)"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': reduceParam(mainStatistic.amount_of_returns.current) > reduceParam(mainStatistic.amount_of_returns.old)}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (reduceParam(mainStatistic.amount_of_returns.old) * 100 / reduceParam(mainStatistic.amount_of_returns.current))).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="mainStatistic.returned_money"
                            class="statistic__parameters-item"
                            :class="{'active': currentMainChart === 'returned_money'}"
                            @click="changeCurrentMainChart(mainStatistic, 'returned_money')"
                        >
                            <div class="statistic__parameters-item-title">Денег вернули</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{ reduceParam(mainStatistic.returned_money.current) ? `${formatNumber(Math.round(reduceParam(mainStatistic.returned_money.current)))} ₽` : '-' }}
                                </div>
                                <div
                                    v-if="reduceParam(mainStatistic.returned_money.current)"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': reduceParam(mainStatistic.returned_money.current) > reduceParam(mainStatistic.returned_money.old)}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (reduceParam(mainStatistic.returned_money.old) * 100 / reduceParam(mainStatistic.returned_money.current))).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="stockStatistic.sum_prices"
                            class="statistic__parameters-item"
                            :class="{'active': currentMainChart === 'sum_prices'}"
                            @click="changeCurrentMainChart(stockStatistic, 'sum_prices')"
                        >
                            <div class="statistic__parameters-item-title">Списания</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{ reduceParam(stockStatistic.sum_prices.current) ? `${formatNumber(Math.round(reduceParam(stockStatistic.sum_prices.current)))} ₽` : '-' }}
                                </div>
                                <div
                                    v-if="reduceParam(stockStatistic.sum_prices.current)"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': reduceParam(stockStatistic.sum_prices.current) > reduceParam(stockStatistic.sum_prices.old)}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (reduceParam(stockStatistic.sum_prices.old) * 100 / reduceParam(stockStatistic.sum_prices.current))).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="stockStatistic.sum_units"
                            class="statistic__parameters-item"
                            :class="{'active': currentMainChart === 'sum_units'}"
                            @click="changeCurrentMainChart(stockStatistic, 'sum_units')"
                        >
                            <div class="statistic__parameters-item-title">Списания</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{ reduceParam(stockStatistic.sum_units.current) ? `${formatNumber(Math.round(reduceParam(stockStatistic.sum_units.current)))} шт.` : '-' }}
                                </div>
                                <div
                                    v-if="reduceParam(stockStatistic.sum_units.current)"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': reduceParam(stockStatistic.sum_units.current) > reduceParam(stockStatistic.sum_units.old)}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (reduceParam(stockStatistic.sum_units.old) * 100 / reduceParam(stockStatistic.sum_units.current))).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="mainStatistic.avg_showcase_cost"
                            class="statistic__parameters-item"
                            style="cursor: default;"
                        >
                            <div class="statistic__parameters-item-title">Букетов собрано</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{
                                        mainStatistic.avg_showcase_cost.current ?
                                            `${formatNumber(Math.round(mainStatistic.avg_showcase_cost.current))} ₽` : '-'
                                    }}
                                </div>
                                <div
                                    v-if="+mainStatistic.avg_showcase_cost.old"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': +mainStatistic.avg_showcase_cost.current > +mainStatistic.avg_showcase_cost.old}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (+mainStatistic.avg_showcase_cost.old * 100 / +mainStatistic.avg_showcase_cost.current)).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="mainStatistic.avg_showcase_price"
                            class="statistic__parameters-item"
                            style="cursor: default;"
                        >
                            <div class="statistic__parameters-item-title">Букетов продано</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{
                                        mainStatistic.avg_showcase_price.current ?
                                            `${formatNumber(Math.round(mainStatistic.avg_showcase_price.current))} ₽` : '-'
                                    }}
                                </div>
                                <div
                                    v-if="+mainStatistic.avg_showcase_price.old"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': +mainStatistic.avg_showcase_price.current > +mainStatistic.avg_showcase_price.old}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (+mainStatistic.avg_showcase_price.old * 100 / +mainStatistic.avg_showcase_price.current)).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="mainStatistic.percentage_bonus_orders"
                            class="statistic__parameters-item"
                            style="cursor: default;"
                        >
                            <div class="statistic__parameters-item-title">Бонусов в заказах</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{
                                        mainStatistic.percentage_bonus_orders.current ?
                                            `${formatNumber(Math.round(mainStatistic.percentage_bonus_orders.current))} %` : '-'
                                    }}
                                </div>
                                <div
                                    v-if="+mainStatistic.percentage_bonus_orders.old"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': +mainStatistic.percentage_bonus_orders.current > +mainStatistic.percentage_bonus_orders.old}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (+mainStatistic.percentage_bonus_orders.old * 100 / +mainStatistic.percentage_bonus_orders.current)).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="mainStatistic.percentage_user_orders"
                            class="statistic__parameters-item"
                            style="cursor: default;"
                        >
                            <div class="statistic__parameters-item-title">Заказы с бонусами</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{
                                        mainStatistic.percentage_user_orders.current ?
                                            `${formatNumber(Math.round(mainStatistic.percentage_user_orders.current))} %` : '-'
                                    }}
                                </div>
                                <div
                                    v-if="+mainStatistic.percentage_user_orders.old"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': +mainStatistic.percentage_user_orders.current > +mainStatistic.percentage_user_orders.old}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (+mainStatistic.percentage_user_orders.old * 100 / +mainStatistic.percentage_user_orders.current)).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="mainStatistic.percentage_other_goods"
                            class="statistic__parameters-item"
                            style="cursor: default;"
                        >
                            <div class="statistic__parameters-item-title">Заказов с доп.товарами</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{ mainStatistic.percentage_other_goods.current ? `${formatNumber(Math.round(mainStatistic.percentage_other_goods.current))} %` : '-' }}
                                </div>
                                <div
                                    v-if="+mainStatistic.percentage_other_goods.current"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': +mainStatistic.percentage_other_goods.current > mainStatistic.percentage_other_goods.old}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (+mainStatistic.percentage_other_goods.old * 100 / +mainStatistic.percentage_other_goods.current)).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="mainStatistic.nps"
                            class="statistic__parameters-item"
                            style="cursor: default;"
                        >
                            <div class="statistic__parameters-item-title">Индекс лояльности (NPS)</div>
                            <div class="statistic__parameters-item-value-wrap">
                                <div class="statistic__parameters-item-value">
                                    {{
                                        mainStatistic.nps.current ?
                                            `${formatNumber(Math.round(mainStatistic.nps.current))} %` : '-'
                                    }}
                                </div>
                                <div
                                    v-if="+mainStatistic.nps.current"
                                    class="statistic__parameters-item-stats"
                                    :class="{'positive': +mainStatistic.nps.current > +mainStatistic.nps.old}"
                                >
                                    <div class="statistic__parameters-item-stats-text">
                                        {{ `${(100 - (+mainStatistic.nps.old * 100 / +mainStatistic.nps.current)).toFixed(0)} %` }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContentPreloader v-show="preloader" class="my-2" height="360" />
                    <div v-show="showMainChart" class="statistic__main-chart">
                        <simple-chart
                            v-if="pageLoad"
                            :chart-data="mainChartData"
                            :chart-options="mainChartOptions"
                            :styles="{ height: '400px' }"
                        />
                        <div class="statistic__chart-simple-legends">
                            <div class="statistic__chart-legends-item">
                                <div class="statistic__chart-legends-item-line" />
                                <div class="statistic__chart-legends-item-text">
                                    {{ statisticDatesFormatedForMainChart('current') }}
                                </div>
                            </div>
                            <div class="statistic__chart-legends-item">
                                <div class="statistic__chart-legends-item-line prev" />
                                <div class="statistic__chart-legends-item-text">
                                    {{ statisticDatesFormatedForMainChart('old') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="!preloader" class="statistic__row">
                        <div class="statistic__col">
                            <div class="statistic__block-wrap">
                                <div class="statistic__block-title">
                                    Способы оплаты
                                    <HorizontalPicker
                                        v-show="!preloader"
                                        v-model="paymentTypesByPercent"
                                        :items="[{value: false, name: '₽'}, {value: true, name: '%'}]"
                                        style="width: 100px;"
                                    />
                                </div>
                                <div class="statistic__block">
                                    <div class="statistic__block-content">
                                        <div class="statistic__pie-chart-container">
                                            <pie-chart
                                                v-if="pageLoad"
                                                :chart-data="chartDataPaymentTypes"
                                                :chart-options="pieChartOptions"
                                            />
                                        </div>
                                        <div class="statistic__pie-chart-legend">
                                            <div v-for="(label, index) in chartDataPaymentTypes.labels" :key="index" class="statistic__pie-chart-legend-item">
                                                <div class="bar-item-info">
                                                    <div class="bar-item-info-wrapper">
                                                        <span class="value-name ml-0">{{ label }}</span>
                                                        <span class="bar-value-span">
                                                            {{ paymentTypesByPercent ? calcPieChartItemProcent(chartDataPaymentTypes, index) :
                                                                `${formatNumber(chartDataPaymentTypes.datasets[0].data[index])} ₽`
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div class="bar">
                                                        <div
                                                            class="bar-value"
                                                            :style="{
                                                                'width': `${calcPieChartItemProcent(chartDataPaymentTypes, index)}`,
                                                                'background' : chartDataPaymentTypes.datasets[0].backgroundColor[index]
                                                            }"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="statistic__col">
                            <div class="statistic__block-wrap">
                                <div class="statistic__block-title">
                                    Источники
                                    <HorizontalPicker
                                        v-show="!preloader"
                                        v-model="sourcesByPercent"
                                        :items="[{value: false, name: '₽'}, {value: true, name: '%'}]"
                                        style="width: 100px;"
                                    />
                                </div>
                                <div class="statistic__block">
                                    <div class="statistic__block-content">
                                        <div class="statistic__pie-chart-container">
                                            <pie-chart
                                                v-if="pageLoad"
                                                :chart-data="chartDataSources"
                                                :chart-options="pieChartOptions"
                                            />
                                        </div>
                                        <div class="statistic__pie-chart-legend">
                                            <div v-for="(label, index) in chartDataSources.labels" :key="index" class="statistic__pie-chart-legend-item">
                                                <div class="bar-item-info">
                                                    <div class="bar-item-info-wrapper">
                                                        <span class="value-name ml-0">{{ label }}</span>
                                                        <span class="bar-value-span">
                                                            {{ sourcesByPercent ? calcPieChartItemProcent(chartDataSources, index) :
                                                                `${formatNumber(chartDataSources.datasets[0].data[index])} ₽`
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div class="bar">
                                                        <div
                                                            class="bar-value"
                                                            :style="{
                                                                'width': `${calcPieChartItemProcent(chartDataSources, index)}`,
                                                                'background' : chartDataSources.datasets[0].backgroundColor[index]
                                                            }"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="statistic__col">
                            <div class="statistic__block-wrap">
                                <div class="statistic__block-title">
                                    Лидеры
                                    <div class="d-flex align-center justify-end" style="width: 100px;height: 44px;">
                                        <HorizontalPicker
                                            v-show="!leaderDiagramBusy"
                                            v-model="leaderDiagramParams.mode"
                                            :items="leaderHorizontalPickerOptions"
                                            @input="changeLeaderStatistic"
                                        />
                                        <Spinner v-show="leaderDiagramBusy" />
                                    </div>
                                </div>
                                <div class="statistic__block statistic__block--with-tab">
                                    <SimpleTabs
                                        v-model="leaderDiagramParams.tab"
                                        :options="['Продаж', 'Списаний', 'Магазинов']"
                                    />
                                    <div v-show="leaderDiagramParams.tab === 'Продаж'" class="statistic__block-content">
                                        <horizontal-bar-chart
                                            :chart-data="mainStatistic.bestsellers"
                                            units-type="₽"
                                        />
                                    </div>
                                    <div v-show="leaderDiagramParams.tab === 'Списаний'" class="statistic__block-content">
                                        <horizontal-bar-chart
                                            :chart-data="stockStatistic.bestsellers"
                                            :units-type="leaderDiagramParams.mode ? 'шт.' : '₽'"
                                        />
                                    </div>
                                    <div v-show="leaderDiagramParams.tab === 'Магазинов'" class="statistic__block-content">
                                        <horizontal-bar-chart
                                            :chart-data="mainStatistic.shops"
                                            :units-type="leaderDiagramParams.mode ? '₽' : '%'"
                                            :in-percent="!leaderDiagramParams.mode"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="statistic__col">
                            <div class="statistic__block-wrap">
                                <div class="statistic__block-title">
                                    Количество
                                    <HorizontalPicker
                                        v-model="clientsDiagramParams.mode"
                                        :items="clientsHorizontalPickerOptions"
                                        style="width: 100px;"
                                    />
                                </div>
                                <div class="statistic__block statistic__block--with-tab">
                                    <SimpleTabs
                                        v-model="clientsDiagramParams.tab"
                                        :options="['Клиентов', 'Бонусов']"
                                        @input="prepareClientsStatistic"
                                    />
                                    <div class="statistic__block-content">
                                        <div class="statistic__pie-chart-container">
                                            <pie-chart
                                                v-if="pageLoad"
                                                :chart-data="chartDataClients"
                                                :chart-options="pieChartOptions"
                                            />
                                        </div>
                                        <div v-if="pageLoad" class="statistic__pie-chart-legend">
                                            <div class="statistic__pie-chart-legend-item">
                                                <div class="bar-item-info">
                                                    <div class="bar-item-info-wrapper">
                                                        <span class="value-name ml-0">Всего</span>
                                                        <span class="bar-value-span">
                                                            {{ !clientsDiagramParams.mode ? '100%' :
                                                                `${formatNumber(clientsDiagramParams.tab === 'Клиентов' ?
                                                                    mainStatistic.users_info.count_of_users :
                                                                    mainStatistic.users_info.count_of_bonuses)}`
                                                            }}
                                                            {{ clientsDiagramParams.tab === 'Клиентов' || !clientsDiagramParams.mode ? '' : '₽' }}
                                                        </span>
                                                    </div>
                                                    <div class="bar">
                                                        <div class="bar-value" style="width: 100%;background: #b9b9b9" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-for="(label, index) in chartDataClients.labels" :key="index" class="statistic__pie-chart-legend-item">
                                                <div class="bar-item-info">
                                                    <div class="bar-item-info-wrapper">
                                                        <span class="value-name ml-0">{{ label }}</span>
                                                        <span class="bar-value-span">
                                                            {{ clientsDiagramParams.mode === false ? calcPieChartItemProcent(chartDataClients, index) :
                                                                `${formatNumber(chartDataClients.datasets[0].data[index])}`
                                                            }}
                                                            {{ clientsDiagramParams.tab === 'Клиентов' || !clientsDiagramParams.mode ? '' : '₽' }}
                                                        </span>
                                                    </div>
                                                    <div class="bar">
                                                        <div
                                                            class="bar-value"
                                                            :style="{
                                                                'width': `${calcPieChartItemProcent(chartDataClients, index)}`,
                                                                'background' : chartDataClients.datasets[0].backgroundColor[index]
                                                            }"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Row v-show="preloader">
                        <Col v-for="i in 6" :key="i" :cols="width > 1024 ? 6 : 12">
                            <ContentPreloader height="300" />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { getStatistics, getUserByGroup } from '@/api/django';
import { getStatisticsStock } from '@/api/stock';
import { numberFormat } from '@/utils/format';

import SimpleChart from '@/components/charts/SimpleChart';
import PieChart from '@/components/charts/PieChart';
import HorizontalBarChart from '@/components/charts/HorizontalBarChart';
import FastFilters from '@/components/app/FastFilters';
import SimpleTabs from "@/components/layout/SimpleTabs";
import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheStatistics',
    metaInfo: {
        title: 'Статистика'
    },
    components: {
        SimpleTabs,
        SimpleChart,
        PieChart,
        HorizontalBarChart,
        FastFilters,
        Spinner
    },
    data() {
        return {
            mainStatistic: {},
            stockStatistic: {},
            chartDataSources: {
                datasets: [{ data: [], backgroundColor: [], borderWidth: 0 }],
                labels: [],
            },
            chartDataPaymentTypes: {
                datasets: [{ data: [], backgroundColor: [], borderWidth: 0 }],
                labels: [],
            },
            chartDataClients: {
                datasets: [{ data: [], backgroundColor: [], borderWidth: 0 }],
                labels: [],
            },
            mainChartData: {
                labels: [],
                datasets: [
                    {
                        data: [],
                        borderColor: '#C3D8FC',
                        backgroundColor: 'rgba(13, 99, 243, 0.1)',
                        fill: true,
                        pointRadius: 0,
                    },
                    {
                        data: [],
                        borderColor: 'rgba(161, 169, 180, 0.4)',
                        borderDash: [3, 3],
                        backgroundColor: 'transparent',
                        pointRadius: 0,
                    },
                ],
            },
            mainChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            title: (ctx) => {
                                if (ctx.length > 1) {
                                    let date = ctx[0].label.split('-').reverse().join('-');
                                    return [`${moment(date).format('D MMMM')}`, `${moment(date).subtract(ctx[0].dataset.data.length, 'days').format('D MMMM')}`]
                                }

                                if (ctx[0].datasetIndex === 0) {
                                    let date = ctx[0].label.split('-').reverse().join('-');
                                    return `${moment(date).format('D MMMM')}`
                                } else {
                                    let date = ctx[0].label.split('-');
                                    return `${moment(date).subtract(ctx[0].dataset.data.length, 'days').format('D MMMM')}`
                                }
                            },
                            label: (ctx) => {
                                return this.formatNumber(Math.round(ctx.raw))
                            },
                            labelColor: (ctx) => {
                                if (ctx.datasetIndex === 0) {
                                    return {
                                        borderColor: '#C3D8FC',
                                        backgroundColor: '#C3D8FC'
                                    };
                                } else {
                                    return {
                                        borderColor: 'rgba(161, 169, 180, 0.4)',
                                        backgroundColor: 'rgba(161, 169, 180, 0.4)'
                                    };
                                }
                            },
                        },
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                        },
                        ticks: {
                            callback: (value, index, ticks) => {
                                let date = this.mainChartData.labels[index].split('-').reverse().join('-');
                                return moment(date).format('D');
                            },
                            color: '#0F1922',
                            font: { size: 15 }
                        },
                    },
                    y: {
                        grid: {
                            display: true,
                            color: "#CFDDEA",
                            borderDash: [2, 2]
                        },
                        ticks: {
                            callback: (value, index, ticks) => {
                                return this.formatNumber(value)
                            },
                            color: '#0F1922',
                            font: { size: 15 }
                        }
                    }
                },
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
            },
            pieChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                cutout: 90,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                let total = context.dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
                                let percentage = Math.floor(((context.parsed / total) * 100) + 0.5);

                                return `${context.label} - ${context.formattedValue} ₽ (${percentage}%)`
                            }
                        },
                    }
                },
            },
            filters: {
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
                shop: null,
                is_equipments: true,
                by_units: true,
                florist_id: null,
                sources: []
            },
            statisticDates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            currentMainChart: 'revenue',
            showMainChart: false,
            fastFilters: [
                { id: 2, name: 'Вчера', active: false },
                { id: 1, name: 'Сегодня', active: true },
                { id: 3, name: '7 дней', active: false },
                { id: 4, name: '31 день', active: false }
            ],
            sourcesByPercent: false,
            paymentTypesByPercent: false,
            leaderDiagramParams: {
                tab: 'Продаж',
                mode: true
            },
            leaderDiagramBusy: false,
            clientsDiagramParams: {
                tab: 'Клиентов',
                mode: true
            },
            floristOptions: [{id: null, first_name: 'Все'}],
            pageLoad: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            shops: 'shops/shops',
            preloader: 'ui/preloader',
            sources: 'data/sources',
        }),
        shopsWithEmpty() {
            if (this.shops.results) {
                return [{ id: null, address: 'Все', type_icon: 'store' }, ...this.shops.results];
            }
            return []
        },
        statisticDatesFormated() {
            if (this.statisticDates[0] && this.statisticDates[1]) {
                if (this.statisticDates[0] > this.statisticDates[1]) {
                    return `${moment(this.statisticDates[1]).format('DD.MM')} ~ ${moment(this.statisticDates[0]).format('DD.MM')}`;
                } else if (this.statisticDates[0] == this.statisticDates[1]) {
                    return `${moment(this.statisticDates[0]).format('DD.MM')}`;
                }
                return `${moment(this.statisticDates[0]).format('DD.MM')} ~ ${moment(this.statisticDates[1]).format('DD.MM')}`;
            }
            return 'Дата не задана'
        },
        leaderHorizontalPickerOptions() {
            if (this.leaderDiagramParams.tab === 'Продаж') {
                return [{value: true, name: '🌷'}, {value: false, name: '💐'}]
            } else if (this.leaderDiagramParams.tab === 'Списаний') {
                return [{value: true, name: 'Шт'}, {value: false, name: '₽'}]
            }
            return [{value: true, name: '₽'}, {value: false, name: '%'}]
        },
        clientsHorizontalPickerOptions() {
            if (this.clientsDiagramParams.tab === 'Клиентов') {
                return [{value: true, name: 'Шт'}, {value: false, name: '%'}]
            }
            return [{value: true, name: '₽'}, {value: false, name: '%'}]
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        await this.getStatistics();
        await this.$store.dispatch('shops/GET_SHOPS');
        await this.$store.dispatch('data/GET_SOURCES');
        getUserByGroup('florist', 'all')
            .then(res => {
                this.floristOptions = [...this.floristOptions, ...res.results];
            })
        this.pageLoad = true;
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    methods: {
        async getStatistics() {
            try {
                const params = this.prepareFilters();
                this.mainStatistic = await getStatistics(params);
                this.stockStatistic = await getStatisticsStock(params);
                this.createMainChart(this.mainStatistic, this.currentMainChart);
                this.createPieChart(this.mainStatistic, 'sources', this.chartDataSources);
                this.createPieChart(this.mainStatistic, 'payment_methods', this.chartDataPaymentTypes);
                await this.prepareClientsStatistic();

                // Добавление иконки типа для магазинов
                if (this.mainStatistic.shops && this.mainStatistic.shops.length) {
                    this.mainStatistic.shops = this.mainStatistic.shops.map(item => {
                        return {
                            ...item,
                            icon: item.type === 2 ? 'mdi-coffee' : item.type === 3 ? 'mdi-store-marker' : 'mdi-flower-tulip'
                        };
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        createMainChart(response, responseField) {
            let valuesCurrent = Object.values(response[responseField].current);
            let valuesOld = Object.values(response[responseField].old);
            let labels = Object.keys(response[responseField].current);

            this.mainChartData.datasets[0].data = valuesCurrent;
            this.mainChartData.datasets[1].data = valuesOld;
            this.mainChartData.labels = labels;
        },
        createPieChart(response, responseField, chartVariable) {
            chartVariable.datasets[0].data = response[responseField].map((i) => i.total);
            chartVariable.datasets[0].backgroundColor = response[responseField].map((i) => i.color);
            chartVariable.labels = response[responseField].map((i) => i.name);
        },
        changeCurrentMainChart(response, responseField) {
            this.currentMainChart = responseField;
            this.createMainChart(response, this.currentMainChart);
        },
        async changeStatisticDates() {
            if (this.statisticDates[0] && this.statisticDates[1]) {
                this.showMainChart = true;
                if (this.statisticDates[0] > this.statisticDates[1]) {
                    this.filters.start_date = this.statisticDates[1];
                    this.filters.end_date = this.statisticDates[0];
                } else if (this.statisticDates[0] == this.statisticDates[1]) {
                    this.filters.start_date = this.statisticDates[0];
                    this.filters.end_date = this.statisticDates[0];
                    this.showMainChart = false;
                } else {
                    this.filters.start_date = this.statisticDates[0];
                    this.filters.end_date = this.statisticDates[1];
                }
            }
            this.fastFilters.forEach((item) => {
                item.active = false;
            });
            this.updateStatistic();
        },
        async updateStatistic() {
            this.$store.commit('ui/SET_PRELOADER', true);
            await this.getStatistics();
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        async changeLeaderStatistic() {
            this.leaderDiagramBusy = true;
            this.filters.by_units = this.leaderDiagramParams.mode;
            this.filters.is_equipments = this.leaderDiagramParams.mode;
            await this.getStatistics();
            this.leaderDiagramBusy = false;
        },
        async prepareClientsStatistic() {
            this.chartDataClients.datasets[0].backgroundColor = ['#fdcb17', '#c74d4d', '#4086F4'];
            this.chartDataClients.labels = ['Не указано', 'Женщины', 'Мужчины'];

            if (this.clientsDiagramParams.tab === 'Клиентов') {
                this.chartDataClients.datasets[0].data = [
                    this.mainStatistic.users_info.count_of_not_gender,
                    this.mainStatistic.users_info.count_of_female,
                    this.mainStatistic.users_info.count_of_male
                ];
            } else {
                this.chartDataClients.datasets[0].data = [
                    this.mainStatistic.users_info.count_of_bonuses_not_gender,
                    this.mainStatistic.users_info.count_of_bonuses_of_female,
                    this.mainStatistic.users_info.count_of_bonuses_of_male
                ];
            }
        },
        calcPieChartItemProcent(chart, index) {
            let dataset = chart.datasets[0];
            if (dataset.data) {
                let total = dataset.data.reduce((sum, currentValue) => sum + currentValue, 0);
                let currentValue = dataset.data[index];

                return `${Math.floor(((currentValue / total) * 100) + 0.5)}%`;
            }
            return 'Не определено'
        },
        async fastFilterApply(option) {
            if (option.name === 'Сегодня' || option.name === 'Вчера') {
                this.showMainChart = false;
            } else {
                this.showMainChart = true;
            }

            this.fastFilters.forEach((item) => {
                item.active = false;
                if (item.id === option.id) {
                    item.active = true;
                }
            });

            if (option.id === 1) {
                this.filters.start_date = moment().format('YYYY-MM-DD');
                this.filters.end_date = moment().format('YYYY-MM-DD');
                this.statisticDates = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
            }
            if (option.id === 2) {
                this.filters.start_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
                this.filters.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
                this.statisticDates = [moment().subtract(1, 'days').format('YYYY-MM-DD'), moment().subtract(1, 'days').format('YYYY-MM-DD')];
            }
            if (option.id === 3) {
                this.filters.start_date = moment().subtract(7, 'days').format('YYYY-MM-DD');
                this.filters.end_date = moment().format('YYYY-MM-DD');
                this.statisticDates = [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
            }
            if (option.id === 4) {
                this.filters.start_date = moment().subtract(31, 'days').format('YYYY-MM-DD');
                this.filters.end_date = moment().format('YYYY-MM-DD');
                this.statisticDates = [moment().subtract(31, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
            }

            this.updateStatistic();
        },
        statisticDatesFormatedForMainChart(type) {
            // Вместо income можно указывать любой график. Нам важно знать только даты статистики.
            if (this.mainStatistic.income) {
                let dates = Object.keys(this.mainStatistic.income[type]);
                let firstDate = dates[0].split('-').reverse().join('-');
                let lastDate = dates[dates.length - 1].split('-').reverse().join('-');
                return `${moment(firstDate).format('DD MMM')} - ${moment(lastDate).format('DD MMM')}`;
            }
            return 'Даты не определены';
        },
        prepareFilters() {
            let filters = {};
            Object.entries(this.filters).forEach(([key, value]) => {
                if (key && (value || value === false || value === 0)) {
                    filters[key] = value;
                }
            })
            return filters;
        },
        reduceParam(parameter) {
            const values = Object.values(parameter);
            let summ = values.reduce((sum, val) => sum + val, 0);
            if (!isNaN(summ)) {
                return summ;
            }
            return 0;
        },
        openNavigation() {
            this.$store.commit('ui/TOGGLE_SIDEBAR')
            this.$store.dispatch('ui/toggle_opened_modals_count', 'open');
        },
        formatNumber(number) {
            return number ? numberFormat(number) : 0;
        },
    }
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header title="Каталоги" for-listing :show-save-btn="false" />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="300" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="catalogs.results && catalogs.results.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 33%">Название</div>
                            <div class="listing-table__col" style="width: 33%">Дата изменения</div>
                            <div class="listing-table__col" style="width: 33%">Главный набор</div>
                        </div>
                        <router-link v-for="item in catalogs.results" :key="item.id" :to="`/catalogs/${item.id}`" class="listing-table__row">
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 33%' : ''">
                                {{ item.name }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                <div class="listing-table__col-label">Дата изменения</div>
                                Даты изменения нет на бэке
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                <div class="listing-table__col-label">Главный набор</div>
                                Главного набора нет на бэке
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="catalogs.results && !catalogs.results.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { date } from '@/utils/format';
import Spinner from '@/components/app/Spinner.vue';

export default {
    name: 'TheCatalogs',
    metaInfo: {
        title: 'Каталоги'
    },
    components: { Spinner },
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            catalogs: 'catalogs/catalogs',
            busy: 'catalogs/busy',
            next: 'catalogs/next',
            preloader: 'ui/preloader',
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await this.$store.dispatch('catalogs/GET_CATALOGS');
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        this.$store.commit('catalogs/CLEAR_FILTER');
    },
    methods: {
        loadMore() {
            this.$store.dispatch('catalogs/GET_NEXT_PAGE');
        },
        dateFormat(value, type) {
            return date(new Date(value), type);
        }
    }
};
</script>

/* eslint-disable */
import { getWishesForProducts } from '@/api/django';

const state = {
    wishes: [],
    filters: {
        limit: 20,
        offset: 0,
    },
    next: null,
    busy: false,
    activeFilters: [],
};

const mutations = {
    SET_WISHES(state, wishes) {
        state.wishes = wishes.results;
        state.next = !!wishes.next;
        state.filters.offset = 20;
    },
    SET_NEXT_PAGE(state, data) {
        state.wishes.push(...data.results);
        state.next = !!data.next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    CLEAR(state) {
        state.wishes = [];
        state.next = false;
        state.busy = false;
        state.filters.offset = 0;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
        };
        state.next = false;
    },
};

const actions = {
    async GET_WISHES({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;
            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;
            let filters = await dispatch('prepareFilters');

            commit('SET_WISHES', await getWishesForProducts(filters));

            state.busy = false;
        } catch (error) {
            console.log(error);
        }
    },
    async LOAD_MORE({ state, commit, dispatch }) {
        try {
            if (state.next && !state.busy) {
                state.busy = true;
                let filters = await dispatch('prepareFilters');
                commit('SET_NEXT_PAGE', await getWishesForProducts(filters));
            }
        } catch (error) {
            console.log(error);
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([key, value]) => {
            if (key && (value || value === false || value === 0)) {
                filters[key] = value;
            }
        })
        let activeFilters = Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    wishes: state => state.wishes,
    busy: state => state.busy,
    next: state => state.next,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

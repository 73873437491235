import Vue from "vue";
import Vuetify from "vuetify/lib";
import 'vuetify/dist/vuetify.min.css';
import ru from 'vuetify/es5/locale/ru'
// import ru from '@/i18n/vuetify/ru';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { ru },
        current: 'ru',
    },
    theme: {
        themes: {
            light: {
                primary: "#1a73e8",
                secondary: "#424242",
                accent: "#82B1FF",
                error: "#EF6A6E",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107"
            }
        }
    },
});

/* eslint-disable */
import { getShopsInCity } from '@/api/django';

const state = {
    cities: [],
    shops: []
};

const mutations = {
    SET_CITIES(state, cities) {
        state.cities = cities || [];
    },
    SET_SHOPS(state, { shops }) {
        state.shops = shops || [];
    }
};

const actions = {
    async GET_CITY_SHOPS({ commit }, city_id) {
        try {
            const data = await getShopsInCity(city_id);
            commit('SET_CITIES', data);
            commit('SET_SHOPS', data);
        } catch (error) {
            console.log(error);
        }
    }
};

const getters = {
    city: state => state.cities,
    shops: state => state.shops
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

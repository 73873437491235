<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Интеграция ВКонтакте"
                left-btn="left-big"
                :show-save-btn="!user.read_only"
                @click:left="$router.back()"
                @click:save="saveButton"
            >
                <button v-if="!user.read_only" type="button" class="header__button" :disabled="preloader" @click.prevent="syncGoods">
                    <v-icon color="#0F1922">mdi-autorenew</v-icon>
                </button>
            </Header>
            <div class="page-container">
                <div class="page__info-group">
                    <Row>
                        <Col cols="12">
                            <InputCustom v-model="vk.vk_group" label="ID группы VK" />
                        </Col>
                        <Col v-if="vk.vk_products && !preloader" cols="12">
                            <span :style="`color: ${vk.vk_products.uploaded_count < vk.vk_products.total_count ? '#FF6E40' : '#36b687'} `">
                                Выгружено товаров: {{ vk.vk_products.uploaded_count }} из {{ vk.vk_products.total_count }}
                            </span>
                        </Col>
                        <Col v-show="preloader">
                            <ContentPreloader width="240" height="19" />
                        </Col>
                    </Row>
                </div>
                <div class="page__info-group">
                    <div v-show="!preloader" class="listing-table__wrap">
                        <div v-if="vkAlbumsList && vkAlbumsList.length" class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                <div class="listing-table__col pl-0">Наименование</div>
                                <div class="listing-table__col" style="width: 70px; flex: 0 0 auto;" />
                            </div>
                            <div
                                v-for="item in vkAlbumsList.filter(item => item.is_unload)"
                                :key="item.id"
                                class="listing-table__row"
                            >
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image">
                                        <img :src="item.image ? item.image : require('@/assets/img/flower.svg')" :alt="item.name">
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1023 ? 'width: calc(100% - 182px)' : 'width: calc(100% - 68px)'"
                                >
                                    <div>
                                        <div class="listing-table__product-name">
                                            <span>{{ item.name }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline ml-a"
                                    :style="width > 1024 ? 'width: 70px; flex: 0 0 auto;' : ''"
                                >
                                    <v-btn icon :disabled="user.read_only" @click.prevent="changeAlbumImage(item)">
                                        <v-icon color="#96A5B9">mdi-pencil</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContentPreloader v-show="preloader" type="table" />
                    <div class="d-flex justify-center mt-4">
                        <Button
                            bordered
                            icon-right="edit-2"
                            :disabled="user.read_only"
                            @click="isVkCategoriesModalOpen = true"
                        >
                            Изменить
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        <UniversalModal v-model="isChangeImageModalOpen">
            <UniversalModalHeader
                title="Редактирование фото категории"
                @click:right="isChangeImageModalOpen = false"
            />
            <div class="universal-modal__content">
                <ImageLoader
                    v-show="!preloader"
                    :value="changedVKAlbum.image"
                    @change="loadAlbumImage($event)"
                />

                <ContentPreloader v-show="preloader" height="150" />
            </div>
        </UniversalModal>
        <UniversalModal v-model="isVkCategoriesModalOpen">
            <UniversalModalHeader
                :title="vkAlbumsList.length && vkAlbumsList.filter(item => item.is_unload).length ?
                    `${vkAlbumsList.filter(item => item.is_unload).length} шт.` : 'Выберите категории'
                "
                @click:right="isVkCategoriesModalOpen = false"
            >
                <template #rightButtons>
                    <button
                        v-if="vkAlbumsList.length"
                        type="button"
                        class="universal-modal__header-right-btn"
                        @click.prevent="clearAlbumsList"
                    >
                        <Icon>file-minus</Icon>
                    </button>
                </template>
            </UniversalModalHeader>
            <div class="universal-modal__content">
                <div v-if="vkAlbumsList && vkAlbumsList.length" class="listing-table">
                    <div class="listing-table__row listing-table__row--head">
                        <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                        <div class="listing-table__col pl-0">Наименование</div>
                    </div>
                    <a
                        v-for="item in vkAlbumsList"
                        :key="item.id"
                        class="listing-table__row"
                        :class="{'active': item.is_unload}"
                        @click="item.is_unload = !item.is_unload"
                    >
                        <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                            <div class="listing-table__image">
                                <img :src="item.image ? item.image : require('@/assets/img/flower.svg')" :alt="item.name">
                            </div>
                        </div>
                        <div
                            class="listing-table__col listing-table__col--mobile-inline"
                            :class="{'pt-0 mr-0': width < 1024}"
                            :style="width > 1024 ? 'width: calc(100% - 112px)' : 'width: calc(100% - 68px)'"
                        >
                            <div>
                                <div class="listing-table__product-name">
                                    <span>{{ item.name }}</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import {
    VKAuth, getSyncSettings, patchSyncSettings, patchSyncAlbums, patchSyncAlbumsStatus, getSyncAlbums
} from '@/api/django';

import ImageLoader from '@/components/app/ImageLoader'

export default {
    name: 'TheIntegrationsVK',
    metaInfo: {
        title: 'Интеграция ВКонтакте'
    },
    components: {
        ImageLoader
    },
    data: () => ({
        vk: {
            id: null,
            vk_group: null
        },
        vkAlbumsList: [],
        changedVKAlbum: {},
        fab_open: false,
        error: null,
        isChangeImageModalOpen: false,
        isVkCategoriesModalOpen: false
    }),
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            user: 'auth/user',
            origin: 'ui/origin',
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            const { results } = await getSyncSettings();
            this.vk = results.find((item) => !!item.vk_group) || {};

            const albums = await getSyncAlbums();
            this.vkAlbumsList = albums.results;
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        async saveButton() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const albums = this.vkAlbumsList.map((item) => ({
                    id: item.id,
                    is_unload: item.is_unload
                }));
                await patchSyncSettings(this.vk.id, this.vk);
                await patchSyncAlbumsStatus({ albums });

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Сохранено', type: 'success' })
                this.$router.push({ name: 'integrations' });
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка сохранения', type: 'error' })
            }
        },
        async syncGoods() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const backUrl = `${process.env.VUE_APP_API_HOST}sync`;

                const { redirect_url } = await VKAuth(this.origin, backUrl, document.location.origin);
                if (redirect_url) document.location.href = redirect_url;
            } catch (error) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Не удалось синхронизировать', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        changeAlbumImage(item) {
            this.changedVKAlbum = item;
            this.isChangeImageModalOpen = true;
        },
        async loadAlbumImage(event) {
            try {
                if (event.target.files.length === 0) {
                    return;
                }
                this.$store.commit('ui/SET_PRELOADER', true);
                const file = event.target.files[0];
                const image = new FormData();

                image.append("image", file);
                event.target.value = "";

                const data = await patchSyncAlbums(this.changedVKAlbum.id, image, {
                    "Content-Type": "multipart/form-data"
                });

                this.changedVKAlbum.image = data.image;

                this.vkAlbumsList.forEach((item) => {
                    if (item.id === this.changedVKAlbum.id) {
                        item.image = data.image;
                    }
                });
                this.$store.commit('ui/SET_PRELOADER', false);
            } catch (error) {
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки фото', type: 'error' })
            }
        },
        clearAlbumsList() {
            this.vkAlbumsList.forEach(item => {
                item.is_unload = false;
            });
        },
    }
};
</script>

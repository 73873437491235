<template>
    <div class="system-notifications__wrap">
        <transition-group name="notifications-list" tag="div" class="system-notifications__list">
            <div
                v-for="(item, index) in systemNotifications"
                :key="item.key"
                class="system-notifications__item"
                :class="{
                    'base': item.type === 'base',
                    'reload': item.type === 'reload',
                    'success-item': item.type === 'success',
                    'error-item': item.type === 'error'
                }"
                @click="notificationClickHandler(item.message, index)"
            >
                <div class="system-notifications__item-text">{{ getNotificationText(item.message) }}</div>
                <div v-if="['base', 'reload'].includes(item.type)" class="system-notifications__item-buttons-wrap">
                    <button v-if="item.type === 'base'" class="system-notifications__item-close-btn" @click.stop.prevent="closeNotification(index)">
                        <Icon color="#fff">close</Icon>
                    </button>
                    <button v-if="item.type === 'reload'" class="system-notifications__item-close-btn" @click.stop.prevent="reloadPage">
                        <Icon color="#fff">refresh</Icon>
                    </button>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'systemNotifications',
    computed: {
        ...mapGetters({
            width: 'ui/width',
            systemNotifications: 'ui/systemNotifications',
        }),
    },
    methods: {
        notificationClickHandler(message, index) {
            if (typeof message === 'object' && message.order_id) {
                this.$router.push(`/orders/${message.order_id}`);
            }
            this.closeNotification(index);
        },
        getNotificationText(message) {
            return typeof message === 'object' ? `${message.title}: ${message.text}` : message;
        },
        closeNotification(index) {
            this.$store.commit('ui/remove_item_in_system_notifications', index);
        },
        reloadPage() {
            window.location.reload();
        },
    }
}
</script>
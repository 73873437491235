/* eslint-disable */
import axios from 'axios';

import store from '../store';
import router from '../router';

import token from '../utils/token';

import { responseErrorHandler, getNewAccessToken } from './auth'

const baseURL = process.env.VUE_APP_API_HOST;

const instance = axios.create({
    baseURL
});

instance.interceptors.request.use(
    async function(config) {
        store.dispatch('ui/startLoading', true);

        if (config._retry) {
            await getNewAccessToken();
        }

        if (token()) {
            config.headers['Authorization'] = token();
        }

        return config;
    },
    function(error) {
        const { status, data } = error.response;
        return Promise.reject({ status, data });
    }
);

instance.interceptors.response.use(
    function(response) {
        store.dispatch('ui/stopLoading', false);
        return response.data;
    },
    async function(error) {
        store.dispatch('ui/stopLoading', false);

        // if (error.response && error.response.status === 401) {
        //     store.commit('auth/REMOVE_DATA_AUTH');
        //     router.push('/');
        // }

        try {
            const res = await responseErrorHandler(error, instance);
            return res;
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                console.log({ status, data });
                return Promise.reject({ status, data });
            } else {
                return Promise.reject(err);
            }
        }
    }
);

export function deviceSubscribe(data) {
    return instance({
        method: 'post',
        url: `fcm/api/v1/devices/`,
        data
    });
}

export function getNotificationsList(params) {
    return instance('msg_broker/api/v1/messages/', { params });
}

export function patchNotification(id, data) {
    return instance({
        method: 'patch',
        url: `msg_broker/api/v1/messages/${id}/`,
        data
    });
}

export function patchChannels(id, data) {
    return instance({
        method: 'patch',
        url: `msg_broker/api/v1/channels/${id}/`,
        data
    });
}

export function exportInventoryToExcel(id) {
    return instance({
        method: 'get',
        url: `stock/api/v1/sync/inventory/${id}/xml/`,
        responseType: 'blob'
    });
}

export function exportArrivalToExcel(id) {
    return instance({
        method: 'get',
        url: `stock/api/v1/sync/arrivals/${id}/xml/`,
        responseType: 'blob'
    });
}

export function getBonusesListForClient(id) {
    return instance(`main/api/v1/users/${id}/bonuses/transactions/`);
}

export function getFAQSections() {
    return instance(`knowledge/api/v1/documentation/sections/`);
}
export function getFAQ() {
    return instance(`knowledge/api/v1/documentation/questions/`);
}

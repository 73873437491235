<template>
    <div class="simple-tabs__wrap">
        <div
            v-for="item in options"
            :key="item"
            class="simple-tabs__item"
            :class="{'active': item === value}"
            @click="$emit('input', item)"
        >
            {{ item }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'SimpleTabs',
    props: {
        value: { type: [Number, String], default: 0 },
        options: { type: Array, default: () => [] }
    }
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Категории и теги"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <p>Через блок управления можно создавать и редактировать категории, управлять тегами.</p>
                                <div class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Для создания новой категории нужно нажать на кнопку "Добавить категорию"
                                        </div>
                                        <div class="help__table-col" style="max-width: 216px">
                                            <img src="@/assets/img/help/categories/1.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Открывает управление тегами
                                        </div>
                                        <div class="help__table-col" style="max-width: 80px">
                                            <img src="@/assets/img/help/categories/3.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>

                                <p>Чтобы перейти к редактированию категорию, нужно навести на нее курсор, а затем нажать на кнопку редактирования</p>
                                <img src="@/assets/img/help/categories/11.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 id="categories" class="page__info-group-title">Категории</h2>
                                <h3 id="categories-create">Создание и редактирование</h3>
                                <p>Раздел содержит 2 вкладки: “Основное” и “Теги”</p>

                                <h3 id="categories-main">Вкладка основное</h3>
                                <p>Тут заполняются основные поля категории.</p>

                                <p><b>Короткое название</b> - используется в основном меню на сайте и в селекторе категорий в детальной странице товара.</p>
                                <img src="@/assets/img/help/categories/4.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p><b>Полное название</b> - используется в хлебных крошках на сайте, в фильтрах категорий в IS. Если полное название не будет задано при создании, то оно автоматически заполнится коротким.</p>
                                <img src="@/assets/img/help/categories/5.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <img src="@/assets/img/help/categories/6.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Поле <b>"Сортировка"</b> отвечает за расположение категории внутри одного уровня. При создании это значение лучше не изменять.</p>

                                <p>Категории имеют несколько переключателей</p>
                                <img src="@/assets/img/help/categories/7.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p><b>Выводить на сайте</b> - категория доступна на сайте. Если она недоступна, то даже при переходе по прямой ссылке просмотреть ее будет невозможно, произойдет редирект на главную.</p>
                                <p><b>Выводить на главной</b> - отображение категории на главной странице сайта</p>
                                <p><b>Выводить в меню</b> - влияет на отображение категории в основном меню сайта.</p>
                                <img src="@/assets/img/help/categories/4.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p><b>Выделенная категория</b> - добавляет точку-индикатор к категории в выпадающем меню сайта</p>
                                <img src="@/assets/img/help/categories/8.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p><b>Добавьте к заказу</b> - добавляет категорию в блок дополнительных товаров. Блок отображается на сайте в корзине или на странице товара.</p>
                                <img src="@/assets/img/help/categories/9.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p><b>Для витрины</b> - указывает, что категория будет использоваться для витрины. Витрина имеет особый статус категорий, ее функциональность уникальна. Товары с витрины отображаются в блоке сторис на сайте. В эту категорию флористы могут добавлять цветы. Товары из этой категории после покупки автоматически удаляются.</p>
                                <img src="@/assets/img/help/categories/10.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <h3 id="categories-tags">Вкладка теги</h3>
                                <p>На этой вкладке нужно выделить те теги, которые будут отображаться в фильтре категории.</p>
                                <p>Например, отметим теги Маме и Любимой.</p>
                                <img src="@/assets/img/help/categories/12.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <p>На сайте в фильтре этой категории появились указанные теги. Теги будут отображаться, если счетчик найденных товаров больше нуля.</p>
                                <img src="@/assets/img/help/categories/13.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 id="tags" class="page__info-group-title">Теги</h2>
                                <p>По аналогии с категориями товаров слева отображаются теги, а справа категории тегов.</p>
                                <img src="@/assets/img/help/categories/14.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <h3 id="tags-categories">Категории тегов</h3>
                                <p>Добавление и редактирование категории тегов похоже на работу с категориями товаров.</p>
                                <p>Переключатель <b>"Тип: цвет"</b> нужен для указания того, что теги этой категории обозначают цвета.</p>
                                <p>При удалении категории все теги этой категории сами превратятся в категории, поэтому лучше сначала удалять или переназначать привязанные теги.</p>
                                <img src="@/assets/img/help/categories/15.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <h3 id="tags-items">Создание, просмотр, редактирование тегов</h3>
                                <p>Доступны переключатели:</p>
                                <p><b>Активность</b> - так же, как и с категорией тегов, не ясно, на что влияет. Так как выключение активности не скрывает тег из фильтра.</p>
                                <p><b>Показывать на главной</b> - отображение тега на главной странице сайта</p>
                                <p><b>Показывать в меню</b> - отображение тега в основном меню сайта (именно в основном, в выпадающем меню тег будет выводиться, если он привязан к категории)</p>
                                <p><b>Общий тег</b> - тег будет отображаться в фильтре каждой категории, даже если он не привязан.</p>
                                <p><b>Мультицвет</b> - переключатель становится доступен только для категории "Тип: Цвет". Мультицвет применяется для товаров, которые содержат разные цвета.</p>
                                <img src="@/assets/img/help/categories/16.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Если тег привязан к категории, которая отмечена "Тип: Цвет", то появится селектор цвета. Этот цвет будет отображаться в фильтре вместо буквенного названия.</p>
                                <img src="@/assets/img/help/categories/17.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <p>К тегу можно сразу привязать товары. Именно эти товары будут учитываться фильтром, если выбрать тег.</p>
                                <img src="@/assets/img/help/categories/18.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{ name: 'Уведомления', link: '/help/notifications' }, {}]" />
                    </div>
                    <div class="page-small-column page-small-column--mobile-top">
                        <HelpSidebar
                            :links="[
                                {
                                    name: 'Категории',
                                    link: 'categories',
                                    children: [
                                        { name: 'Создание и редактирование', link: 'categories-create' },
                                        { name: 'Вкладка основное', link: 'categories-main' },
                                        { name: 'Вкладка теги', link: 'categories-tags' },
                                    ]
                                },
                                {
                                    name: 'Теги',
                                    link: 'tags',
                                    children: [
                                        { name: 'Категории тегов', link: 'tags-categories' },
                                        { name: 'Создание, просмотр, редактирование тегов', link: 'tags-items' },
                                    ]
                                }
                            ]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'
import HelpSidebar from '@/components/app/help/HelpSidebar'

export default {
    name: 'TheHelpCategories',
    metaInfo: {
        title: 'Категории и теги - Помощь'
    },
    components: {
        HelpNavigationFooter, HelpSidebar
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
};
</script>

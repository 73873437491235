<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Инкассация ${$route.params.ID}` : 'Отменить'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only && encashment.shop && encashment.amount"
                @click:left="$router.back()"
                @click:save="handle"
            />
            <div class="page-container">
                <Row>
                    <Col v-if="$route.params.ID" :cols="width > 1023 ? 6 : 12">
                        <InputCustom
                            :value="formatDate(encashment.date_created, 'hours-ru')"
                            label="Дата создания"
                            disabled
                        />
                    </Col>
                    <Col :cols="width > 1023 ? 6 : 12">
                        <InputSelect
                            v-if="!$route.params.ID"
                            v-model="encashment.shop"
                            :options="shops.map(item => ({ ...item, icon: item?.type?.icon_path || '' }))"
                            label="Магазин"
                            :input-text="'address'"
                            :disabled="group === 'florist'"
                            :error="errors && errors.shop ? errors.shop[0] : ''"
                        />
                        <InputCustom
                            v-else
                            v-model="encashment.shop"
                            label="Магазин"
                            disabled
                        />
                    </Col>
                    <Col :cols="width > 1023 ? 6 : 12">
                        <InputCustom
                            :value="+encashment.amount"
                            label="Сумма"
                            type="number"
                            clearable
                            :disabled="!!$route.params.ID || group === 'florist'"
                            :error="errors && errors.amount ? errors.amount[0] : ''"
                            @input="encashment.amount = $event"
                        />
                    </Col>
                    <Col :cols="width > 1023 ? 6 : 12">
                        <TextareaCustom
                            v-model="encashment.comment"
                            label="Комментарий"
                            :disabled="!!$route.params.ID || group === 'florist'"
                            :error="errors && errors.comment ? errors.comment[0] : ''"
                        />
                    </Col>
                    <Col v-if="$route.params.ID" :cols="width > 1023 ? 6 : 12">
                        <InputSelect
                            v-model="encashment.status"
                            :options="statuses"
                            label="Статус"
                            :input-text="'value'"
                            :disabled="group !== 'supervisor' || encashment.status === 'confirmed'"
                            :error="errors && errors.status ? errors.status[0] : ''"
                        />
                    </Col>
                    <Col v-if="$route.params.ID" :cols="width > 1023 ? 6 : 12">
                        <InputCustom
                            :value="encashment.user.first_name"
                            label="Создатель"
                            disabled
                        />
                    </Col>
                </Row>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import {
    createEncashment, getEncashmentData, updateEncashmentStatus, editEncashment
} from '@/api/django';
import { date } from '@/utils/format';

export default {
    name: 'TheEncashmentsCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Инкассация ${this.$route.params.ID}` : 'Новая инкассация' }
    },
    data: () => ({
        encashment: {
            shop: null,
            amount: 0,
            comment: '',
            status: null,
            user: {}
        },
        statuses: [
            { id: 'new', value: 'Новая' },
            { id: 'confirmed', value: 'Подтверждена' },
            { id: 'cancelled', value: 'Отменена' },
        ],
        errors: {},
    }),
    computed: {
        ...mapGetters({
            width: 'ui/width',
            shops: 'data/shops',
            preloader: 'ui/preloader',
            shopForEncashment: 'encashments/shopForEncashment',
            authUser: 'auth/user',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        }
    },
    async created() {
        try {
            this.$store.commit('ui/SET_PRELOADER', true);
            this.$store.dispatch('data/LOAD_SHOPS', JSON.parse(localStorage.getItem('user')).city);
            if (this.$route.params.ID) {
                const response = await getEncashmentData(this.$route.params.ID)
                this.encashment = response;
                this.markAsViewed();
            }
            if (!this.$route.params.ID && this.shopForEncashment) {
                this.encashment.shop = +this.shopForEncashment;
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        this.$store.commit('encashments/SET_SHOP_FOR_ENCASHMENT', null);
    },
    methods: {
        handle() {
            if (this.$route.params.ID) {
                this.edit();
            } else {
                this.create();
            }
        },
        async create() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const data = { ...this.encashment };
                delete data.user;
                await createEncashment(data)
                this.$router.back();
            } catch (error) {
                console.log(error);
                this.setErrors(error.data);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async edit() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                const data = { status: this.encashment.status };

                await updateEncashmentStatus(this.encashment.id, data)
                this.$router.back();
            } catch (error) {
                console.log(error);
                this.setErrors(error.data);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        setErrors(errors) {
            this.errors = errors;
            const nonFieldError = 'non_field_errors' in this.errors ? this.errors.non_field_errors[0] : false;
            const detailError = 'detail' in this.errors ? this.errors.detail : false;

            if (nonFieldError) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: nonFieldError, type: 'error' })
            } else if (detailError) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: detailError, type: 'error' })
            }
        },
        async markAsViewed() {
            this.$store.dispatch('ui/checkUserEditPermission');
            if (this.$route.params.ID && !this.encashment.is_seen) {
                await editEncashment(this.$route.params.ID, { is_seen: true });
                this.$store.dispatch('franchises/GET_FRANCHISE_TARIFF');
                this.$store.dispatch('stock/GET_STOCK_INDICATIONS');
            }
        },
        formatDate(value, type) {
            return date(new Date(value), type);
        },
    }
}
</script>

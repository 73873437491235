<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial
            v-if="!authUser.read_only && $route.params.ID"
            v-model="fab_open"
            class="btn__floating custom-speed-dial"
            fixed
            close-on-click
        >
            <template v-slot:activator>
                <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item @click="showConfirmDeleteModal = true">
                        <v-list-item-icon class="mr-3">
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Удалить страницу</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Страница ${page.name}` : 'Новая страница'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only"
                @click:left="$router.push({ name: 'static-pages' })"
                @click:save="savePage"
            />
            <div class="tabs__list">
                <TabItem :active="activeTab === 0" @click="activeTab = 0">
                    Основное
                    <v-icon v-show="error.name" color="error" class="ml-4">mdi-alert-circle</v-icon>
                </TabItem>
                <TabItem :active="activeTab === 1" @click="activeTab = 1">
                    Текст
                    <v-icon v-show="error.text_full" color="error" class="ml-4">mdi-alert-circle</v-icon>
                </TabItem>
            </div>
            <div class="page-container page-container--with-tabs page-container--with-fab">
                <div v-show="activeTab === 0" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <div class="page__info-group">
                                <Row>
                                    <Col>
                                        <InputCustom
                                            v-model="page.name"
                                            label="Название"
                                            :error="error.name ? error.name[0] : ''"
                                            @input="clearError('name')"
                                        />
                                    </Col>
                                    <Col>
                                        <InputCustom
                                            v-model="page.slug"
                                            label="Адрес страницы"
                                            :error="error.slug ? error.slug[0] : ''"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">SEO</h2>
                                <Row>
                                    <Col>
                                        <InputCustom
                                            v-model="page.meta_h1"
                                            label="Заголовок h1"
                                            :error="error.meta_h1 ? error.meta_h1[0] : ''"
                                        />
                                    </Col>
                                    <Col>
                                        <InputCustom
                                            v-model="page.meta_title"
                                            label="Title"
                                            :error="error.meta_title ? error.meta_title[0] : ''"
                                        />
                                    </Col>
                                    <Col>
                                        <InputCustom
                                            v-model="page.meta_description"
                                            label="Description"
                                            :error="error.meta_description ? error.meta_description[0] : ''"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div class="page-small-column page-small-column--mobile-top">
                            <div class="checkbox-group">
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="page.is_active"
                                        label="Активность"
                                        @change="page.is_active = !page.is_active"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="activeTab === 1" class="tabs__content">
                    <yimo-vue-editor v-model="page.text_full" @input="clearError('text_full')" />
                    <div v-if="error && error.text_full" class="app-input-tip" style="color:red;">
                        {{ error.text_full[0] }}
                    </div>
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmDeleteModal"
            :title="`Удалить страницу ${ page.name }?`"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteModal = false"
            @mainBtnClick="deletePage"
        />
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import {
    getPageData, createPage, updatePage, deletePage
} from '@/api/django';

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'ThePagesCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Страница ${this.page.name}` : 'Новая страница' }
    },
    components: {
        MiniModal,
    },
    data: () => ({
        page: {
            is_active: true,
            sort: 500,
            name: '',
            slug: '',
            meta_title: '',
            meta_description: '',
            meta_h1: '',
            text_preview: '',
            text_full: ''
        },
        fab_open: false,
        activeTab: 0,
        showConfirmDeleteModal: false,
        error: {},
    }),
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
            width: 'ui/width',
            preloader: 'ui/preloader',
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            if (this.$route.params.ID) {
                const page = await getPageData(this.$route.params.ID);
                this.page = page;
            }
        } catch (error) {
            console.log(error);
            this.$store.commit('ui/add_item_in_system_notifications', { message: 'При загрузке данных произошла ошибка', type: 'error' })
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        async savePage() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                if (!this.$route.params.ID) {
                    await createPage({ ...this.page, site: this.authUser.site });
                } else {
                    await updatePage(this.$route.params.ID, this.page);
                }

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Страница успешно сохранена', type: 'success' })

                this.$router.push('/static-pages');
            } catch (error) {
                console.log(error);
                if (error.data) {
                    this.error = error.data;
                }
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении страницы произошла ошибка', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async deletePage() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                await deletePage(this.$route.params.ID)

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Страница удалена', type: 'success' })
                this.$router.push('/static-pages');
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При удалении страницы произошла ошибка', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        clearError(field) {
            if (this.error && this.error[field]) {
                delete this.error[field]
            }
        },
    }
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Перемещения"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список перемещений</h2>
                                <p>В списке перемещений отображаются поля:</p>
                                <ul>
                                    <li>Склад-отправитель</li>
                                    <li>Склад-получатель</li>
                                    <li>Дата отправки</li>
                                    <li>Дата получения</li>
                                    <li>Статус</li>
                                </ul>

                                <p>Статус перемещения можно изменить с помощью выпадающего списка.</p>
                                <img src="@/assets/img/help/transfers/1.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <p>Есть фильтр по направлению перемещения и магазинам.</p>

                                <p>Непросмотренное перемещение выделяется светло-желтым цветом.</p>
                                <img src="@/assets/img/help/transfers/2.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание, просмотр, редактирование перемещения</h2>
                                <p>При создании перемещения нужно указать склад-отправитель, склад-получатель и выбрать список товаров. Также можно выбрать курьера, который осуществит перемещение.</p>
                                <p>Редактировать и удалять перемещения нельзя.</p>
                            </div>
                        </div>
                        <HelpNavigationFooter
                            v-if="authUserGroup !== 'courier'"
                            :links="[leftNavigationLink, rightNavigationLink]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpTransfers',
    metaInfo: {
        title: 'Перемещения - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
        leftNavigationLink() {
            if (this.authUserGroup === 'managers') {
                return { name: 'Поставки', link: '/help/arrivals' }
            }
            return { name: 'Списания', link: '/help/offs' }
        },
        rightNavigationLink() {
            if (['florist', 'managers'].includes(this.authUserGroup)) {
                return { name: 'К оглавлению', link: '/help' }
            }
            return { name: 'Инвентаризации', link: '/help/inventories' }
        }
    },
};
</script>

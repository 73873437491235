<template>
    <div>
        <div class="chips__wrap">
            <Chips :active="tabActive === 0" @click="tabActive = 0">
                Персонал
            </Chips>
            <Chips :active="tabActive === 1" @click="tabActive = 1">
                Клиенты
            </Chips>
        </div>
        <div class="listing-table">
            <div class="listing-table__row listing-table__row--head">
                <div class="listing-table__col" style="width: 33%">Название</div>
                <div class="listing-table__col" style="width: 33%">Активность</div>
                <div class="listing-table__col" style="width: 33%">Каналы</div>
            </div>
            <div v-for="item in notifications" :key="item.pk" class="listing-table__row">
                <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                    {{ item.name }}
                </div>
                <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                    <div>
                        <InputSelect
                            v-if="tabActive === 0"
                            v-model="item.roles"
                            :options="notificationGroupsOptions"
                            input-value="value"
                            multiple
                            @blur="patchNotification(item)"
                        />
                        <Checkbox
                            v-else
                            :value="item.roles.includes('customers')"
                            inline
                            label-align="right"
                            @change="$event ? item.roles.push('customers') : item.roles = item.roles.filter(i => i !== 'customers'),
                                patchNotification(item)
                            "
                        />
                    </div>
                </div>
                <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                    <InputSelect
                        v-model="item.channels"
                        :options="notificationChannelsOptions"
                        input-value="value"
                        multiple
                        @blur="patchNotification(item)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getNotificationsList, patchNotification } from '@/api/gateway';

export default {
    name: 'NotificationSettings',
    data() {
        return {
            tabActive: 0,
            notificationsPersonal: [],
            notificationsClients: [],
            notificationGroupsOptions: [
                { name: 'Флорист', value: 'florist' },
                { name: 'Курьер', value: 'courier' },
                { name: 'Менеджер', value: 'managers' },
                { name: 'Касса', value: 'cashboxes' },
                { name: 'Администратор', value: 'administrators' },
                { name: 'Руководитель', value: 'supervisor' },
                { name: 'Покупатель', value: 'customers' },
            ],
            notificationChannelsOptions: [
                { name: 'Пуш', value: 'push' },
                { name: 'Уведомление', value: 'notification' },
                { name: 'VK бот', value: 'vk' }
            ],
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        notifications() {
            return this.tabActive === 0 ? this.notificationsPersonal : this.notificationsClients;
        }
    },
    mounted() {
        this.getNotificationsList();
    },
    methods: {
        async getNotificationsList() {
            let results = await Promise.all([
                getNotificationsList({categories: 'staff'}),
                getNotificationsList({categories: 'clients'})
            ]);

            this.notificationsPersonal = results[0];
            this.notificationsClients = results[1];
        },
        patchNotification(notif) {
            this.$store.dispatch('ui/checkUserEditPermission');
            let findIndexPersonal = this.notificationsPersonal.findIndex(item => item.pk === notif.pk);
            let findIndexClients = this.notificationsClients.findIndex(item => item.pk === notif.pk);

            this.notificationsPersonal.splice(findIndexPersonal, 1, notif);
            this.notificationsClients.splice(findIndexClients, 1, notif);

            patchNotification(notif.pk, { roles: notif.roles, channels: notif.channels });
        },
    }
}
</script>

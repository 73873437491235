/* eslint-disable */
import firebase from 'firebase/app';
import 'firebase/messaging';

import scrollLock from '@/utils/scroll-lock';

let notificationCounter = 0 // Используем как ключ key, для корректной анимации списков, а также для работы с массивом systemNotifications

const state = {
    width: null,
    page: null,
    sidebar: false,
    filter: false,
    goodFilter: true,
    modals: [],
    filters: [],
    loading: 0,
    message: '',
    showErrorMessage: false,
    error: false,
    origin: '',
    errorMessage: '',
    preloader: false,
    reloadMessage: '',
    goodsModalMode: '',
    messaging: '',
    messagingToken: '',
    supportFirebase: true,
    openedModalsCount: 0,
    remountPageKey: 0, // переменная используется для перемонтирования компонента <router-view>. Таким образом можно перезагрузить страницу без перезагрузки всей IS.
    systemNotifications: [] // массив всех всплывающих уведомлений (успешное сохранение, ошибки, уведомления по сокетам)
};

let timer = null

const mutations = {
    SET_WIDTH(state, width) {
        state.width = width;
    },
    SET_PAGE(state, page) {
        state.page = page;
    },
    OPEN_SIDEBAR(state) {
        state.sidebar = true;
    },
    CLOSE_SIDEBAR(state) {
        state.sidebar = false;
    },
    TOGGLE_SIDEBAR(state) {
        state.sidebar = !state.sidebar;
    },
    TOGGLE_FILTER(state) {
        state.filter = !state.filter;
    },
    OPEN_GOODFILTER(state) {
        state.goodFilter = true;
    },
    CLOSE_GOODFILTER(state) {
        state.goodFilter = false;
    },
    OPEN_MODAL(state, name) {
        state.modals.push(name);
    },
    OPEN_FILTER(state, name) {
        state.filters.push(name);
    },
    CLOSE_FILTER(state, name) {
        state.filters = state.filters.filter(a => a !== name);
    },
    CLOSE_MODAL(state, name) {
        state.modals = state.modals.filter(modal => modal !== name);
    },
    CLOSE_ALL_MODALS(state) {
        state.modals = [];
        state.filters = [];
    },
    START_LOADING(state) {
        state.loading = state.loading + 1;
    },
    STOP_LOADING(state) {
        state.loading = state.loading - 1;
    },
    SET_MESSAGE(state, message) {
        state.message = message;
    },
    SET_ERROR_MESSAGE(state, message) {
        state.errorMessage = message;
    },
    SHOW_ERROR_MESSAGE(state) {
        state.showErrorMessage = true;
    },
    HIDE_ERROR_MESSAGE(state) {
        state.showErrorMessage = false;
    },
    CLOSE_POPUP(state) {
        state.message = "";
    },
    DESTROYED(state) {
        state.width = null;
        state.page = null;
        state.sidebar = false;
        state.goodFilter = false;
        state.modals = [];
        state.loading = 0;
        state.message = "";
        state.error = false;
    },
    SET_ORIGIN(state, origin) {
        state.origin = origin;
    },
    SET_PRELOADER(state, bool) {
        if (bool) {
            state.preloader = bool;
        } else {
            clearTimeout(timer)
            timer = setTimeout(() => {
                state.preloader = bool;
            },300)
        }
    },
    SET_RELOAD_MESSAGE(state, message) {
        state.reloadMessage = message;
    },
    SET_GOODS_MODAL_MODE(state, value) {
        state.goodsModalMode = value;
    },
    SET_MESSAGING(state, messaging) {
        state.messaging = messaging;
    },
    SET_MESSAGING_TOKEN(state, token) {
        state.messagingToken = token;
    },
    SET_SUPPORT_FIREBASE(state, bool) {
        state.supportFirebase = bool;
    },
    SET_REMOUNT_PAGE_KEY(state) {
        state.remountPageKey += 1
    },
    set_opened_modals_count(state, count) {
        state.openedModalsCount = count >= 0 ? count : 0;
    },
    add_item_in_system_notifications(state, { message, type }) {
        const key = ++notificationCounter;
        const delay = ['success', 'error'].includes(type) ? 2000 : 5000;

        state.systemNotifications = [...state.systemNotifications, { message, type, key }];

        setTimeout(() => {
            const findIndex = state.systemNotifications.findIndex(item => item.key === key);
            if (findIndex !== -1) {
                state.systemNotifications.splice(findIndex, 1);
            }
        }, delay);
    },
    remove_item_in_system_notifications(state, index) {
        state.systemNotifications.splice(index, 1)
    }
};

const actions = {
    startLoading({ commit }) {
        commit('START_LOADING');
    },
    stopLoading({ commit }) {
        commit('STOP_LOADING');
    },
    initFirebase({ state, commit }) {
        try {
            if (!state.messaging) {
                firebase.initializeApp({
                    apiKey: "AIzaSyCapjsIEdulMnYDCvSOScrFmbl152RH2oM",
                    authDomain: "lavanda-test-1.firebaseapp.com",
                    databaseURL: "https://lavanda-test-1.firebaseio.com",
                    projectId: "lavanda-test-1",
                    storageBucket: "lavanda-test-1.appspot.com",
                    messagingSenderId: "17183709402",
                    appId: "1:17183709402:web:cdb6213f58fcf357dfade3"
                });
                commit('SET_MESSAGING', firebase.messaging());
                if (!!localStorage.getItem('sentFirebaseMessagingToken')) {
                    commit('SET_MESSAGING_TOKEN', localStorage.getItem('sentFirebaseMessagingToken'));
                }
            }
        } catch (error) {
            commit('SET_SUPPORT_FIREBASE', false);
        }
    },
    checkUserEditPermission({ commit, rootGetters }) {
        if (rootGetters['auth/user'].read_only) {
            commit('ui/add_item_in_system_notifications', { message: 'У вас есть права только на просмотр информации', type: 'error' }, { root: true })
            throw new Error('У вас есть права только на просмотр информации');
        }
    },
    toggle_opened_modals_count({ state, commit }, action) {
        if (action === 'open') {
            commit('set_opened_modals_count', state.openedModalsCount + 1);
            if (state.openedModalsCount === 1) {
                scrollLock.enable();
            }
        } else if (action === 'close') {
            commit('set_opened_modals_count', state.openedModalsCount - 1);
            if (!state.openedModalsCount) {
                scrollLock.disable();
            }
        }
    }
};

const getters = {
    width: state => state.width,
    page: state => state.page,
    isOpenSidebar: state => state.sidebar,
    isOpenFilter: state => state.filter,
    isOpenGoodFilter: state => state.goodFilter,
    isModalOpened: state => name => state.modals.find(modal => modal === name),
    isFilterOpened: state => name => state.filters.find(filter => filter === name),
    isLoading: state => !!state.loading,
    message: state => state.message,
    showErrorMessage: state => state.showErrorMessage,
    errorMessage: state => state.errorMessage,
    error: state => state.error,
    origin: state => state.origin,
    preloader: state => state.preloader,
    clearAllFilters: state => state.clearAllFilters,
    reloadMessage: state => state.reloadMessage,
    goodsModalMode: state => state.goodsModalMode,
    messaging: state => state.messaging,
    messagingToken: state => state.messagingToken,
    supportFirebase: state => state.supportFirebase,
    remountPageKey: state => state.remountPageKey,
    openedModalsCount: state => state.openedModalsCount,
    systemNotifications: state => state.systemNotifications
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Основное меню и профиль"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Меню</h2>
                                <div class="help__table mt-0">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Нажатие на логотип обновляет страницу
                                        </div>
                                        <div class="help__table-col" style="max-width: 136px">
                                            <img src="@/assets/img/help/menu/1.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                    <div v-if="authUserGroup !== 'florist'" class="help__table-row">
                                        <div class="help__table-col">
                                            Кнопка "Запустить таймер" отвечает за начало и завершение рабочего дня
                                        </div>
                                        <div class="help__table-col" style="max-width: 227px">
                                            <img src="@/assets/img/help/menu/2.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                    <div v-if="authUserGroup !== 'courier'" class="help__table-row">
                                        <div class="help__table-col">
                                            Кнопка "Написать в ТП" открывает модальное окно обращения в техподдержку
                                        </div>
                                        <div class="help__table-col" style="max-width: 227px">
                                            <img src="@/assets/img/help/menu/3.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>

                                <template v-if="authUserGroup !== 'courier'">
                                    <p>В модальном окне <b>"Написать в техподдержку"</b> можно ввести текст и прикрепить изображение.</p>
                                    <img src="@/assets/img/help/menu/4.jpg" class="help__image" alt="" style="margin: 16px auto">
                                </template>
                            </div>

                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Профиль</h2>
                                <div class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Чтобы попасть в профиль, нужно нажать на закрепленное в нижней части меню имя пользователя
                                        </div>
                                        <div class="help__table-col" style="max-width: 227px">
                                            <img src="@/assets/img/help/menu/5.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Чтобы сделать выход из профиля, нужно нажать на иконку "Выход" в верхнем правом углу
                                        </div>
                                        <div class="help__table-col" style="max-width: 63px">
                                            <img src="@/assets/img/help/menu/6.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>

                                <p>Для подписки на push-уведомления нужно открыть дополнительное меню в правом нижнем углу личного кабинета и выбрать <b>"Получать обновления"</b>.</p>
                                <img src="@/assets/img/help/menu/7.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <p>Если такой пункт отсутствует, значит операционная система или браузер не поддерживают получение пушей. (Например, в iOS пуши в браузер недоступны).</p>
                                <p>Чтобы отписаться от пушей, нужно нажать в дополнительном меню на пункт <b>"Не получать обновления"</b>.</p>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{ name: 'К оглавлению', link: '/help' }, { name: 'Уведомления', link: '/help/notifications' }]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpMenu',
    metaInfo: {
        title: 'Меню и профиль - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
    },
};
</script>

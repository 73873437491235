/* eslint-disable */
import {
    getShopsInCity,
    fetchCities,
    fetchCitiesByName,
    fetchUnits,
    fetchCategories,
    fetchTypesForProducts,
    fetchPaymentMethods,
    fetchStickers,
    fetchSources
} from '@/api/django';


const state = {
    units: {},
    categories: [],
    equipCategory: {},
    bouquetCategoryID: '',
    categoriesWithoutEquip: [],
    categoryForEdit: null,
    children: [],
    statuses: {},
    cities: [],
    allCities: [],
    shops: [],
    types: [],
    equipments: [],
    reviews: [],
    paymentMethods: [],
    stickers: [],
    sources: [],
};

const mutations = {
    SET_TYPES(state, types) {
        state.types = types;
    },
    SET_EQUIPMENTS(state, equipments) {
        state.equipments = equipments;
    },
    SET_UNITS(state, units) {
        state.units = units;
    },
    SET_CATEGORIES(state, categories) {
        state.categories = categories;
        let cat = categories.filter(item => {
            return item.type == 'equipment';
        });
        let bouquet = categories.filter(item => {
            return item.type == 'bouquet';
        });
        state.equipCategory = cat.length ? cat[0] : '';
        state.bouquetCategoryID = bouquet.length ? bouquet[0].id : '';
    },
    SET_CATEGORIES_WITHOUT_EQUIP(state, categories) {
        state.categoriesWithoutEquip = categories;
        let bouquet = categories.filter(item => {
            return item.type == 'bouquet';
        });
        state.bouquetCategoryID = bouquet[0].id;
    },
    SET_CHILDREN_CATEGORY(state, children) {
        state.children = children;
    },
    CLEAR_CHILDREN_CATEGORY(state) {
        state.children = [];
    },
    SET_CATEGORY_FOR_EDIT(state, id) {
        state.categoryForEdit = id;
    },
    SET_ORDERS(state, orders) {
        state.orders = orders;
    },
    SET_CITIES(state, cities) {
        state.cities = cities;
    },
    SET_ALL_CITIES(state, cities) {
        state.allCities = cities;
    },
    SET_SHOPS(state, shops) {
        state.shops = shops;
    },
    SET_REVIEWS(state, reviews) {
        state.reviews = reviews;
    },
    SET_PAY_METHODS(state, paymentMethods) {
        state.paymentMethods = paymentMethods;
    },
    SET_STICKERS(state, stickers) {
        state.stickers = stickers;
        state.stickers.unshift({
            id: null,
            name: 'Без стикера'
        })
    },
    SET_SOURCES(state, sources) {
        state.sources = sources;
    }
};

const actions = {
    async LOAD_TYPES({ commit }) {
        const types = await fetchTypesForProducts();
        let filteredGoodType = [];
        if (window.localStorage.getItem('group') === 'coffee_managers') {
            for (let i = types.type_product.length; i--;) {
                if (types.type_product[i].slug === 'equipment' || types.type_product[i].slug === 'coffee_product') {
                    filteredGoodType.push(types.type_product[i]);
                }
            }
        } else {
            filteredGoodType = types.type_product;
        }
        commit('SET_TYPES', filteredGoodType);
        commit('SET_EQUIPMENTS', types.equipment_choices);
    },
    async LOAD_UNITS({ commit }) {
        const units = await fetchUnits();
        commit('SET_UNITS', units);
    },
    async LOAD_CATEGORY({ commit }) {
        const category = await fetchCategories();
        commit('SET_CATEGORIES', category);
    },
    async LOAD_CATEGORY_WITHOUT_EQUIP({ commit }) {
        const category = await fetchCategories(true);
        commit('SET_CATEGORIES_WITHOUT_EQUIP', category);
    },
    async LOAD_ALL_CITIES({ commit }) {
        const results = await fetchCities();
        commit('SET_ALL_CITIES', results);
    },
    async LOAD_CITIES_BY_NAME({ commit }, name) {
        const results = await fetchCitiesByName(name);
        commit('SET_ALL_CITIES', results);
    },
    async LOAD_SHOPS({ commit }, city_id) {
        try {
            const results = await getShopsInCity(city_id);
            localStorage.setItem('timeZone', results.timezone);
            commit('SET_SHOPS', results.shops);
        } catch (error) {}
    },
    CHILDREN_CATEGORY({ commit, state }, index) {
        if (state.categories[index].childrens.length > 0) {
            commit('SET_CHILDREN_CATEGORY', state.categories[index].children);
        } else {
            commit('CLEAR_CHILDREN_CATEGORY');
        }
    },
    async GET_STICKERS({ commit }) {
        try {
            const { results } = await fetchStickers();
            commit('SET_STICKERS', results);
        } catch (e) {
            console.log(e);
        }
    },
    async GET_SOURCES({ commit }) {
        try {
            const data = await fetchSources();
            commit('SET_SOURCES', data);
        } catch (e) {
            console.log(e);
        }
    },
};

const getters = {
    units: state => state.units,
    categories: state => state.categories,
    categoriesWithoutEquip: state => state.categoriesWithoutEquip,
    equipCategory: state => state.equipCategory,
    bouquetCategoryID: state => state.bouquetCategoryID,
    children: state => state.children,
    cities: state => state.cities,
    allCities: state => state.allCities,
    shops: state => state.shops,
    types: state => state.types,
    equipments: state => state.equipments,
    reviews: state => state.reviews,
    paymentMethods: state => state.paymentMethods,
    stickers: state => state.stickers,
    sources: state => state.sources,
    categoryForEdit: state => state.categoryForEdit
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

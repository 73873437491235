<template>
    <div class="subscribe-btn" @click="!messagingToken ? subscribe() : unsubscribe()">
        <span>{{ !messagingToken ? 'Получать обновления' : 'Не получать обновления' }}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { deviceSubscribe } from '@/api/gateway';

export default {
    name: 'SubscribePush',
    computed: {
        ...mapGetters({
            messaging: 'ui/messaging',
            messagingToken: 'ui/messagingToken',
        })
    },
    methods: {
        async unsubscribe() {
            this.$store.commit('ui/SET_PRELOADER', true);
            this.messaging.deleteToken(this.messagingToken);
            this.setTokenSentToServer(false);
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        async subscribe() {
            this.$store.commit('ui/SET_PRELOADER', true);
            if (!this.messaging) await this.$store.dispatch('ui/initFirebase');
            this.messaging.requestPermission()
                .then(() => {
                    this.messaging.getToken()
                        .then((currentToken) => {
                            if (currentToken) {
                                this.sendTokenToServer(currentToken);
                            } else {
                                console.warn('Не удалось получить токен.');
                                this.setTokenSentToServer(false);
                            }
                        })
                        .catch((err) => {
                            console.warn('При получении токена произошла ошибка.', err);
                            this.setTokenSentToServer(false);
                            this.$store.commit('ui/SET_PRELOADER', false);
                        });
                })
                .catch((err) => {
                    console.warn('Не удалось получить разрешение на показ уведомлений.', err);
                    this.$store.commit('ui/SET_PRELOADER', false);
                });
        },
        async sendTokenToServer(currentToken) {
            if (!this.isTokenSentToServer(currentToken)) {
                const data = {
                    registration_id: currentToken,
                    type: 'web'
                }
                await deviceSubscribe(data)
                try {
                    this.setTokenSentToServer(currentToken);
                } catch (e) {
                    console.log(e);
                }
                this.$store.commit('ui/SET_PRELOADER', false);
            } else {
                console.log('Токен уже отправлен на сервер.');
                this.$store.commit('ui/SET_MESSAGING_TOKEN', currentToken ? currentToken : '');
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        setTokenSentToServer(currentToken) {
            window.localStorage.setItem('sentFirebaseMessagingToken', currentToken ? currentToken : '');
            this.$store.commit('ui/SET_MESSAGING_TOKEN', currentToken ? currentToken : '');
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        isTokenSentToServer(currentToken) {
            return window.localStorage.getItem('sentFirebaseMessagingToken') === currentToken;
        },
    },
};
</script>

<template>
    <div
        :class="[`product-categories__item product-categories__item--level-${level}`, {'is-active': isActive}]"
        v-on="$listeners"
    >
        <div class="product-categories__item-name-wrap">
            <Icon :color="!isActive ? '#8AACCD' : '#0D63F3'">
                {{ !category.for_showcase ? 'folder' : 'flower' }}
            </Icon>
            <div class="product-categories__item-name">
                {{ category.is_name || category.name }}
            </div>
            <button
                v-if="category.childrens.length && level == 2"
                type="button"
                class="product-categories__item-btn expand"
                :class="{'is-opened': isOpened}"
                @click.stop.prevent="$emit('click:expand', category.id)"
            >
                <Icon color="#8AACCD">chevron-right</Icon>
            </button>
        </div>
        <a
            v-if="editable"
            :href="`/categories/${category.id}`"
            class="product-categories__item-btn edit"
            @click.stop.prevent="editCategory"
        >
            <Icon color="#8AACCD">edit-2</Icon>
        </a>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ProductCategoriesItem',
    props: {
        category: { type: Object, default: () => {}, required: true },
        level: { type: [String, Number], default: 1 },
        isOpened: { type: Boolean, default: false },
        isActive: { type: Boolean, default: false },
        editable: { type: Boolean, default: false }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
    },
    methods: {
        editCategory() {
            this.$router.push(`/categories/${this.category.id}`);
            this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
        }
    }
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Остатки"
                for-listing
                :show-save-btn="false"
                filter
                :filterIndicator="!!activeFilters.length"
                :search="width <= 699"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'store-filter')"
                @click:search="isVisibleSearch = !isVisibleSearch"
            />
            <div
                class="page-container page-container--listing"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <Search
                    :value="filters.product__name"
                    :is-visible-mobile="isVisibleSearch"
                    @input="$store.commit('stock/SET_FILTER', { field: 'product__name', value: $event}), getFilteredGoods()"
                />
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="goods_in_stock && goods_in_stock.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                            <div class="listing-table__col pl-0">Наименование</div>
                            <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;">Стоимость</div>
                            <div class="listing-table__col" style="width: 130px; flex: 0 0 auto;">Кол-во ед.</div>
                            <div class="listing-table__col" style="width: 180px; flex: 0 0 auto;" />
                        </div>
                        <a
                            v-for="item in goods_in_stock"
                            :href="`/goods/${item.product.vendor_code}`"
                            :key="item.product.vendor_code"
                            class="listing-table__row"
                            target="_blank"
                        >
                            <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                <div class="listing-table__image listing-table__image--with-link">
                                    <img :src="item.product.image ? item.product.image : require('@/assets/img/flower.svg')" alt="">
                                    <div class="listing-table__image-link">
                                        <v-icon color="primary">mdi-open-in-new</v-icon>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-inline"
                                :class="{'pt-0 mr-0': width <= 1024}" :style="width > 1024 ? 'width: calc(100% - 372px)' : 'width: calc(100% - 68px)'"
                            >
                                <div>
                                    <div class="listing-table__product-name">
                                        <span>{{ item.product.name }}</span>
                                    </div>
                                    <div v-if="item.product.is_hidden" class="listing-table__badges">
                                        <div v-if="item.product.is_hidden" class="listing-table__badges-item">
                                            Недоступен
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                            <div class="listing-table__col" :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : ''">
                                <div class="listing-table__col-label">Стоимость</div>
                                {{ formatNumber(+item.product.price) }} ₽
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 130px; flex: 0 0 auto;' : ''">
                                <div class="listing-table__col-label">Кол-во</div>
                                {{ formatNumber(item.count) }} {{ item.product.units }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 180px; flex: 0 0 auto;' : ''">
                                <div class="listing-table__col-label">История товара</div>
                                <a :href="`/goods/${item.product.vendor_code}/?tab=3`" class="d-flex align-center" target="_blank">
                                    <Icon color="#0D63F3" :class="{'mr-4': width > 1024}">
                                        bar-chart
                                    </Icon>
                                    <span v-if="width > 1024" style="font-weight: 500;font-size: 15px;line-height: 20px;color: #0D63F3;">
                                        История товара
                                    </span>
                                </a>
                            </div>
                        </a>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="goods_in_stock && !goods_in_stock.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" height="96" />
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { debounce } from '@/utils/functions';
import { numberFormat } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheStoreroomStore',
    metaInfo: {
        title: 'Склад магазина'
    },
    components: {
        Spinner,
    },
    data() {
        return {
            isVisibleSearch: false,
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            goods_in_stock: 'stock/goods_in_stock',
            activeFilters: 'stock/activeFilters',
            busy: 'stock/busy',
            next: 'stock/next',
            preloader: 'ui/preloader',
            filters: 'stock/filters',
            shops: 'shops/shops',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
    },
    watch: {
        width() {
            if (this.width < 1024) {
                this.$store.commit('ui/CLOSE_GOODFILTER');
            } else {
                this.$store.commit('ui/OPEN_GOODFILTER');
            }
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            this.$store.dispatch('data/LOAD_CATEGORY');
            await this.$store.dispatch('shops/GET_SHOPS');
            await this.$store.dispatch('stock/GET_GOODS_IN_STOCK', { field: 'shop', value: JSON.parse(localStorage.getItem('user')).shop || this.shops.results[0].id });
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        getFilteredGoods: debounce(function (field, value) {
            this.filterGoods(value, field);
        }, 1000),
        async filterGoods(value, field) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('stock/GET_GOODS_IN_STOCK', { field, value });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        loadMore() {
            this.$store.dispatch('stock/GET_NEXT_PAGE_GOODS');
        },
        formatNumber(number) {
            return number === 0 ? 0 : numberFormat(number);
        },
    }
};
</script>

/* eslint-disable */
import {
    fetchPromocodes,
    fetchPromocodeID,
} from '@/api/django';

const state = {
    promocodes: []
};

const mutations = {
    SET_PROMOCODES(state, promocodes) {
        state.promocodes = promocodes;
    },
    CLEAR(state) {
        state.promocodes = [];
    }
};

const actions = {
    async LOAD_PROMOCODES({ commit }) {
        const { results } = await fetchPromocodes();
        commit('SET_PROMOCODES', results);
    },
    async EDIT_PROMOCODE({}, id) {
        return await fetchPromocodeID(id);
    },
};

const getters = {
    promocodes: state => state.promocodes
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <div class="input-counter__wrap" :class="{'disabled': disabled, 'focused': isInputFocused}">
        <v-btn
            class="input-counter__button input-counter__button--minus"
            icon
            :disabled="value == minNumber || disabled"
            @click.stop.prevent="countDown"
        >
            <Icon>minus</Icon>
        </v-btn>
        <input
            ref="input"
            :value="value"
            type="number"
            class="input-counter__input"
            min="1"
            :disabled="disabled"
            @input="input($event.target.value)"
            @change="change($event.target.value)"
            @focus="isInputFocused = true"
            @blur="isInputFocused = false"
            @click.stop
        >
        <v-btn
            class="input-counter__button input-counter__button--plus"
            icon
            :disabled="disabled || disabledPlus"
            @click.stop.prevent="countUp"
        >
            <Icon>plus</Icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "InputCounter",
    props: {
        value: { type: [Number, String], default: 1 },
        disabled: { type: Boolean, default: false },
        disabledPlus: { type: [Boolean, Number], default: false },
        minNumber: { type: [Number, String, Boolean], default: 1 },
        maxNumber: { type: [Number], default: 0 }
    },
    data() {
        return {
            isInputFocused: false
        }
    },
    methods: {
        countDown() {
            this.$refs.input.value--;
            this.$emit('input', +this.$refs.input.value);
        },
        countUp() {
            this.$refs.input.value++;
            this.$emit('input', +this.$refs.input.value);
        },
        input() {
            this.$emit('input', +this.$refs.input.value);
        },
        change(value) {
            if (!value || value.startsWith(0)) this.$refs.input.value = 1;
            if (this.maxNumber && (value > this.maxNumber)) this.$refs.input.value = this.maxNumber;
            this.$emit('input', +this.$refs.input.value);
        }
    }
};
</script>

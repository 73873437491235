<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.ID ? `Уведомление ${$route.params.ID}` : 'Новое уведомление'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only && !notification.sended"
                @click:left="$router.back()"
                @click:save="saveNotification"
            />
            <div class="page-container">
                <div class="page__info-group">
                    <Row>
                        <Col>
                            <InputCustom
                                v-model="notification.title"
                                label="Заголовок"
                                :error="error && error.title && error.title[0] === 'Field may not be null.' ? 'Поле не может быть пустым' :
                                    error && error.title ? 'Ошибка поля при сохранении' : ''"
                            />
                        </Col>
                        <Col>
                            <TextareaCustom
                                v-model="notification.text"
                                label="Сообщение"
                                :disabled="!!$route.params.ID"
                                :error="error && error.text && error.text[0] === 'Field may not be null.' ? 'Поле не может быть пустым' :
                                    error && error.text ? 'Ошибка поля при сохранении' : ''"
                            />
                        </Col>
                        <Col v-if="!$route.params.ID" :cols="12">
                            <InputSelect
                                v-model="scheduledMode"
                                :options="[{ value: null, name: 'Отправить сейчас' }, { value: true, name: 'Отложить отправку' }]"
                                label="Запланировать"
                                input-value="value"
                            />
                        </Col>
                        <template v-if="!$route.params.ID && scheduledMode">
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="notificationDate"
                                    offset-y
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            :value="formattedDate"
                                            label="Дата доставки"
                                            left-icon="calendar"
                                            readonly
                                            v-on="on"
                                        />
                                    </template>
                                    <v-date-picker
                                        :value="notification.scheduled"
                                        no-title
                                        first-day-of-week="1"
                                        :min="moment().format('YYYY-MM-DD')"
                                        @input="setScheduledDate($event)"
                                    />
                                </v-menu>
                            </Col>
                            <Col :cols="width > 1024 ? 6 : 12">
                                <v-menu
                                    ref="notificationTime"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <InputCustom
                                            :value="notification.scheduled ? moment(notification.scheduled).format('HH:mm') : ''"
                                            label="Время отправки"
                                            readonly
                                            v-on="on"
                                        />
                                    </template>
                                    <v-time-picker
                                        :value="notification.scheduled ? moment(notification.scheduled).format('HH:mm') : ''"
                                        format="24hr"
                                        full-width
                                        scrollable
                                        @input="setScheduledTime($event)"
                                        @click:minute="$refs.notificationTime.save()"
                                    />
                                </v-menu>
                            </Col>
                        </template>
                        <Col v-if="$route.params.ID">
                            <InputCustom
                                :value="scheduledMode ? moment(notification.scheduled).format('DD.MM.YYYY HH:mm') : moment(notification.created).format('DD.MM.YYYY HH:mm')"
                                label="Время отправки"
                                disabled
                            />
                        </Col>
                        <Col v-if="$route.params.ID">
                            <InputCustom
                                :value="notification.is_sent || notification.sended ? 'Отправлено' : 'Не отправлено'"
                                label="Статус"
                                disabled
                            />
                        </Col>
                        <template v-if="!$route.params.ID">
                            <Col>
                                <InputSelect
                                    v-model="notification.receivers.type"
                                    :options="receiversTypes"
                                    label="Получатель"
                                />
                            </Col>
                            <Col v-if="notification.receivers.type === 'group'">
                                <InputSelect
                                    v-model="notification.receivers_group"
                                    :options="groups"
                                    label="Группа"
                                    :input-value="'slug'"
                                    :error="error && error.receivers && error.receivers.type && error.receivers.type[0] === 'Field may not be null.' ?
                                        'Поле не может быть пустым' : error && error.receivers ? 'Ошибка поля при сохранении' : ''"
                                />
                            </Col>
                        </template>
                        <Col v-else>
                            <InputCustom
                                :value="notification.receivers_group !== 'users' ? groupFormat(notification.receivers_group) : ''"
                                label="Кому"
                                readonly
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div v-if="users_list && users_list.length" class="listing-table">
                                <div class="listing-table__row listing-table__row--head">
                                    <div class="listing-table__col" style="width: calc((100% - 70px) / 4)">Имя</div>
                                    <div class="listing-table__col" style="width: calc((100% - 70px) / 4)">Роль</div>
                                    <div class="listing-table__col" style="width: calc((100% - 70px) / 4)">Телефон</div>
                                    <div class="listing-table__col" style="width: calc((100% - 70px) / 4)">Посл. авториз</div>
                                    <div v-if="!$route.params.ID" class="listing-table__col" style="width: 70px" />
                                </div>
                                <div v-for="item in users_list" :key="item.id" class="listing-table__row">
                                    <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 70px) / 4)' : ''">
                                        {{ item.last_name }} {{ item.first_name }}
                                    </div>
                                    <div
                                        class="listing-table__col listing-table__col--mobile-bordered"
                                        :style="width > 1024 ? 'width: calc((100% - 70px) / 4)' : 'color: #1a73e8;text-transform: uppercase;'"
                                    >
                                        {{ groupFormat(item.groups[0]) }}
                                    </div>
                                    <div
                                        class="listing-table__col listing-table__col--mobile-inline"
                                        :style="width > 1024 ? 'width: calc((100% - 70px) / 4)' : ''"
                                    >
                                        {{ item.phone }}
                                    </div>
                                    <div
                                        v-if="width > 1024"
                                        class="listing-table__col listing-table__col--mobile-inline"
                                        :style="width > 1024 ? 'width: calc((100% - 70px) / 4)' : ''"
                                    >
                                        {{ item.last_login ? moment(item.last_login).format('DD.MM.YYYY HH:mm') : '&mdash;' }}
                                    </div>
                                    <div
                                        v-if="!$route.params.ID"
                                        class="listing-table__col listing-table__col--mobile-inline ml-a"
                                        :style="width > 1024 ? 'width: 70px' : ''"
                                    >
                                        <Button text compact @click.stop="$store.commit('notifications/REMOVE_USER_FROM_USERS_LIST', item)">
                                            <Icon color="#0D63F3">trash</Icon>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-center mt-4" :class="{'flex-wrap': width <= 699}" style="gap: 16px">
                                <Button
                                    v-if="$route.params.ID && notification.receivers_group === 'user' && usersIDsData.next"
                                    bordered
                                    icon-right="arrow-down"
                                    :block="width <= 699"
                                    @click.prevent="loadMore"
                                >
                                    Загрузить еще
                                </Button>
                                <Button
                                    v-if="notification.receivers.type === 'user'"
                                    bordered
                                    icon-right="edit-2"
                                    :block="width <= 699"
                                    @click.prevent="isUsersModalOpen = true"
                                >
                                    Изменить
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
        <UniversalModal v-model="isUsersModalOpen" size="full">
            <UsersModal @closeModal="isUsersModalOpen = false" />
        </UniversalModal>
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';

import { createNotification, removeNotification, editNotification } from '@/api/notifications';
import { getUserForNotifications, getUserForNotificationsPage } from '@/api/django';

import { numberFormat } from '@/utils/format';

import UsersModal from '@/components/app/users/modal/UsersModal';

export default {
    name: 'TheNotificationsCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Уведомление ${this.$route.params.ID}` : 'Новое уведомление' }
    },
    components: {
        UsersModal
    },
    data() {
        return {
            notification: {
                title: '',
                text: '',
                scheduled: this.moment().format(),
                receivers: {
                    type: 'user',
                    values: []
                },
                type: 'manual',
                receivers_group: 'user',
            },
            receiversTypes: [
                {
                    name: 'Пользователь',
                    id: 'user',
                },
                {
                    name: 'Группа пользователей',
                    id: 'group',
                },
                /* {
                    name: 'Клиенты - Администраторы',
                    id: 'franchises_admins',
                }, */
            ],
            error: {},
            usersIDsData: {
                listUsersIDs: [],
                next: false,
                offset: 20,
            },
            isUsersModalOpen: false,
            scheduledMode: null
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            groups: 'users/groups',
            users_list: 'notifications/users_list',
            preloader: 'ui/preloader',
            usersForNotification: 'users/usersForNotification',
            authUser: 'auth/user',
        }),
        formattedDate() {
            if (this.notification.scheduled) {
                return moment(this.notification.scheduled).format("DD.MM.YYYY");
            }
            return moment().format("DD.MM.YYYY");
        },
    },
    watch: {
        users_list(value) {
            this.notification.receivers.values = value.map((item) => String(item.id));
        },
        'notification.receivers.type'(value) {
            this.$store.commit('notifications/CLEAR_USERS_LIST');
            if (!this.$route.params.ID && value !== 'group') {
                this.notification.receivers_group = value;
            }
        },
    },
    async mounted() {
        this.$store.commit('ui/SET_PRELOADER', true);

        if (this.$route.params.ID) {
            const notification = await this.$store.dispatch('notifications/GET_NOTIFICATION_DATA', this.$route.params.ID)
            this.notification = notification;
            if (notification.receivers.length) {
                this.usersIDsData.listUsersIDs = notification.receivers.map((item) => item.user_id).join();

                const res = await getUserForNotifications(this.usersIDsData.listUsersIDs)
                res.results.forEach((item) => {
                    this.$store.commit('notifications/ADD_USER_TO_USERS_LIST', item);
                });
                this.usersIDsData.next = res.next;
            }
        } else if (!this.$route.params.ID && this.usersForNotification.length) {
            this.$store.commit('notifications/SET_USERS_LIST', this.usersForNotification);
        }
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    beforeDestroy() {
        this.$store.commit('notifications/CLEAR_USERS_LIST');
        this.$store.commit('users/SET_USERS_FOR_NOTIFICATION', []);
        this.$store.commit('users/SET_USERS_IDS', []);
    },
    methods: {
        saveNotification() {
            if (this.$route.params.ID) {
                this.editNotification();
            } else {
                this.createNotification();
            }
        },
        async createNotification() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                let data = { ...this.notification };
                delete data.receivers_group

                // await createNotification(data)
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Уведомление успешно создано', type: 'success' })
                this.$router.back();
            } catch (error) {
                this.error = error.data;
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async editNotification() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                const data = {
                    title: this.notification.title,
                    text: this.notification.text,
                };
                // await editNotification(this.$route.params.ID, data)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Уведомление успешно обновлено', type: 'success' })
                this.$router.push('/notifications');
            } catch (error) {
                console.log(error);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async deleteNotification() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                // await removeNotification(this.$route.params.ID)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Уведомление успешно удалено', type: 'success' })
                this.$router.push('/notifications');
            } catch (error) {
                console.log(error);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async loadMore() {
            const res = await getUserForNotificationsPage(this.usersIDsData.listUsersIDs, this.usersIDsData.offset)
            this.usersIDsData.next = res.next;
            this.usersIDsData.offset += 20;
            this.$store.commit('notifications/SET_USERS_LIST', res.results);
        },
        setScheduledDate(event) {
            let scheduledTime = this.notification.scheduled.split('T')[1];
            this.notification.scheduled = this.moment(`${event}T${scheduledTime}`).format('YYYY-MM-DDTHH:mm')
        },
        setScheduledTime(event) {
            let scheduledDate = this.notification.scheduled.split('T')[0];
            this.notification.scheduled = this.moment(`${scheduledDate}T${event}`).format('YYYY-MM-DDTHH:mm')
        },
        numberFormat(value) {
            if (value) return numberFormat(value);
            return value || 0;
        },
        groupFormat(group) {
            let find = this.groups.find((g) => g.slug === group);
            if (find) {
                return this.groups.find((g) => g.slug === group).name;
            } else if (group === 'franchises_admins') {
                return 'Клиенты - Администраторы';
            }
            return 'Пользователи';
        },
        moment

    }
};
</script>

/* eslint-disable */
import { getGoodsByType } from '@/api/django';
import {
    getAllArrivals,
    getArrivalData,
    createArrival,
    applyingArrival,
} from '@/api/stock';

const state = {
    arrivals: {},
    filters: {
        limit: 20,
        offset: 0,
        shop: null,
        date_from: null,
        date_to: null
    },
    activeFilters: [],
    arrivals_goods: [],
    busy: false,
    next: false,
};

const mutations = {
    SET_ALL_ARRIVALS(state, arrivals) {
        state.arrivals = arrivals.results;
        state.filters.offset = 20;
        state.next = !!arrivals.next;
    },
    SET_NEXT_ARRIVALS(state, arrivals){
        state.arrivals.push(...arrivals.results);
        state.next = !!arrivals.next;
        state.filters.offset += 20;
        state.busy = false;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    SET_ARRIVALS_GOODS(state, goods) {
        state.arrivals_goods = goods;
        state.busy = false;
    },
    ADD_GOOD_IN_ARRIVALS(state, good) {
        let obj = {
            product: good.vendor_code,
            count: good.count || 1,
            name: good.name,
            category: good.category,
            prime_price: +good.prime_cost,
            retail_price: +good.price
        }
        if (good.images[0]) {
            obj.image = good.images[0].image
        }
        state.arrivals_goods.push(obj);
    },
    CLEAR(state) {
        state.arrivals_goods = [];
        state.next = false;
        state.busy = false;
        state.filters.offset = 0;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            shop: null,
            date_from: null,
            date_to: null
        };
        state.next = false;
    },
    REMOVE_GOOD_FROM_ARRIVALS(state, id) {
        state.arrivals_goods = state.arrivals_goods.filter(good => good.product !== id);
    },
    CHANGE_COUNT_ARRIVALS_GOOD(state, { product, value }) {
        for (let i = state.arrivals_goods.length; i--;) {
            if (state.arrivals_goods[i].product === product) {
                state.arrivals_goods[i].count = value;
                break;
            }
        }
    },
};

const actions = {
    async GET_ALL_ARRIVALS({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;
            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;

            let filters = await dispatch('prepareFilters');

            commit('SET_ALL_ARRIVALS', await getAllArrivals(filters));
            state.busy = false;
        } catch (error) {
            console.log(error);
        }
    },
    async LOAD_MORE({ state, commit, dispatch }) {
        try {
            if (state.next && !state.busy) {
                state.busy = true;
                let filters = await dispatch('prepareFilters');

                const res = await getAllArrivals(filters);
                commit('SET_NEXT_ARRIVALS', res);
            }
        }
        catch(e) {
            console.log(e);
        }
    },
    async GET_ARRIVAL_DATA({ commit }, id) {
        try {
            const arrival = await getArrivalData(id);
            commit('SET_ARRIVALS_GOODS', arrival.products);
            return arrival;
        } catch (error) {
            console.log(error);
        }
    },
    async GET_GOODS_FOR_NEW_ARRIVAL({ commit }) {
        try {
            commit('equipments/SET_ALL_EQUIPMENTS', await getGoodsByType('equipment'), { root: true });
        } catch (error) {
            console.log(error);
        }
    },
    async CREATE_ARRIVAL({ getters }, data) {
        try {
            await createArrival(data);
        } catch (error) {
            throw error;
        }
    },
    async APPLY_ARRIVALS({ commit }, id) {
        try {
            await applyingArrival(id);
        } catch (error) {
            throw error;
        }
    },
    EVENT_HANDLER({ commit, getters }, good) {
        if (getters['selected'](good.vendor_code)) {
            commit('REMOVE_GOOD_FROM_ARRIVALS', good.vendor_code);
        } else {
            commit('ADD_GOOD_IN_ARRIVALS', good);
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([ key, value ]) => {
            if (key && (value || value === false || value === 0) ) {
                filters[key] = value;
            }
        })
        let activeFilters =  Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    arrivals: state => state.arrivals,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    arrivals_goods: state => state.arrivals_goods,
    selected: state => id => !!state.arrivals_goods.find(item => item.product === id),
    busy: state => state.busy,
    next: state => state.next,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

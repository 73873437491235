<template>
    <div v-if="showModal" class="mini-modal__wrapper">
        <transition name="fade">
            <div v-show="showModalContent" class="mini-modal__overlay" @click="closeModal" />
        </transition>
        <transition name="fade">
            <div v-show="showModalContent" class="mini-modal__outer" @click.self="closeModal">
                <div class="mini-modal__inner">
                    <div class="mini-modal__content">
                        <div v-if="title" class="mini-modal__title">
                            {{ title }}
                        </div>
                        <div v-if="text" class="mini-modal__text">
                            {{ text }}
                        </div>
                        <slot />
                    </div>
                    <div
                        v-if="additionalBtnText || mainBtnText"
                        class="mini-modal__buttons"
                        :class="{'middle-border': additionalBtnText && mainBtnText}"
                    >
                        <button
                            v-if="additionalBtnText"
                            type="button"
                            class="mini-modal__button additional"
                            @click.prevent="$emit('additionalBtnClick')"
                        >
                            {{ additionalBtnText }}
                        </button>
                        <button
                            v-if="mainBtnText"
                            type="button"
                            class="mini-modal__button main"
                            @click.prevent="$emit('mainBtnClick')"
                        >
                            {{ mainBtnText }}
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'MiniModal',
    model: {
        prop: 'opened'
    },
    props: {
        opened: { type: Boolean, default: false },
        title: { type: String, default: '' },
        text: { type: String, default: '' },
        additionalBtnText: { type: String, default: '' },
        mainBtnText: { type: String, default: '' }
    },
    data() {
        return {
            showModal: false,
            showModalContent: false,
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
    },
    watch: {
        async opened(val) {
            if (val) {
                this.showModal = true;
                await this.$nextTick();
                this.showModalContent = true;
                await this.$nextTick();
                this.$store.dispatch('ui/toggle_opened_modals_count', 'open');
            } else if (this.showModal) {
                this.closeModal()
            }
        }
    },
    mounted() {
        document.addEventListener('keydown', this.onKeydownESC);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydownESC);
    },
    methods: {
        closeModal() {
            if (this.showModal) {
                this.showModalContent = false;
                setTimeout(async () => {
                    this.showModal = false;
                    this.$emit('input', false);
                    await this.$nextTick();
                    this.$store.dispatch('ui/toggle_opened_modals_count', 'close');
                }, 300)
            }
        },
        onKeydownESC(event) {
            if (event.key === 'Escape') {
                this.closeModal();
            }
        }
    }
}
</script>

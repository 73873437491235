<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Чаты"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <p>В списке отображаются чаты с зарегистрированными пользователями и с гостями.</p>
                                <p>Есть поиск по чатам.</p>
                                <div class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Чтобы отправить фотографию нужно нажать на иконку скрепки
                                        </div>
                                        <div class="help__table-col" style="max-width: 57px">
                                            <img src="@/assets/img/help/chat/1.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Для выбора смайлика нажимаем на соответствующую кнопку
                                        </div>
                                        <div class="help__table-col" style="max-width: 57px">
                                            <img src="@/assets/img/help/chat/2.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Молния открывает меню быстрых ответов
                                        </div>
                                        <div class="help__table-col" style="max-width: 57px">
                                            <img src="@/assets/img/help/chat/3.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>
                                <p>При выборе быстрого ответа в сообщение вставится заготовленный текст.</p>
                                <img src="@/assets/img/help/chat/4.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'Заказы', link: '/help/orders'}, {name: 'Товары', link: '/help/products'}]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpChat',
    metaInfo: {
        title: 'Чаты - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Магазины"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список магазинов</h2>
                                <p>В списке магазинов отображаются поля:</p>
                                <ul>
                                    <li>Город</li>
                                    <li>Адрес магазина</li>
                                    <li>Часы работы</li>
                                    <li>Текущий баланс кассы</li>
                                </ul>

                                <p>У адреса могут быть значки.</p>
                                <p><b>Назначение доставок</b> - этот магазин назначается на заказ через 30 минут после оплаты, если не выбран магазин.</p>
                                <p><b>Главный</b> - от этого магазина рассчитывается стоимость доставки за город.</p>
                                <p>Если у магазина есть непросмотренная инкассация, то он будет выделен светло-желтым цветом.</p>
                                <img src="@/assets/img/help/shops/1.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание магазина</h2>
                                <p>При создании магазина нужно обязательно заполнить поля:</p>
                                <ul>
                                    <li>Город (нужно начать вводить название и обязательно выбрать вариант из выпадающего списка)</li>
                                    <li>Время работы</li>
                                    <li>Почтовый индекс</li>
                                    <li>Улица</li>
                                    <li>Дом</li>
                                    <li>Координаты (координаты заполняются автоматически при заполнении адреса или при установке маркера на карте)</li>
                                </ul>
                                <p>При вводе адреса карта автоматически перестраивается, если удалось корректно найти введенный адрес. Если же адрес не найден, то пользователь может вручную задать маркер на карте.</p>
                                <p>При установке маркера на карте происходит поиск наиболее подходящего адреса. Из найденного адреса в поля подставятся почтовый индекс и улица. Дом, корпус/строение пользователь должен заполнить вручную, чтобы все данные были корректны.</p>

                                <p>Можно активировать переключатели:</p>
                                <ul>
                                    <li><b>Назначение доставок</b> - этот магазин назначается на заказ через 30 минут после оплаты, если не выбран магазин.</li>
                                    <li><b>Главный</b> - от этого магазина рассчитывается стоимость доставки за город.</li>
                                    <li><b>Круглосуточно</b> -  скрывает поля "Время работы с" и "Время работы до" и устанавливает значение с 00:00 - 23:59.</li>
                                </ul>
                                <img src="@/assets/img/help/shops/2.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>

                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Просмотр и редактирование магазина</h2>
                                <p>На вкладке <b>"Основное"</b> задаются базовые параметры. Все можно редактировать.</p>
                                <p>На вкладке <b>"Касса"</b> можно задать логин и пароль для кассы, посмотреть текущий баланс.</p>
                                <p>Также тут отображаются инкассации магазина.</p>

                                <p>Чтобы создать новую инкассацию, нужно нажать на кнопку <b>"Добавить инкассацию"</b>.</p>

                                <p>Через дополнительное меню можно просмотреть историю баланса кассы или удалить магазин.</p>
                                <img src="@/assets/img/help/shops/3.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'К оглавлению', link: '/help'}, {name: 'Возвраты', link: '/help/returns'}]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpShops',
    metaInfo: {
        title: 'Магазины - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

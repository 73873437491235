<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Зарплаты"
                for-listing
                :show-save-btn="false"
                filter
                :filterIndicator="!!activeFilters.length"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'salaries-filter')"
            />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="salaries.length" class="listing-table">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 25%">Сотрудник</div>
                            <div class="listing-table__col" style="width: 25%">К выплате</div>
                            <div class="listing-table__col" style="width: 25%">Рассчетный период</div>
                            <div class="listing-table__col" style="width: 25%">Статус</div>
                        </div>
                        <router-link v-for="item in salaries" :key="item.id" :to="`/salarys/${item.id}`" class="listing-table__row">
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                {{ item.read_user.last_name || item.read_user.first_name }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 33%' : ''">
                                <div class="listing-table__col-label">К выплате</div>
                                {{
                                    (item.total_amount != 0 ? formatNumber(Math.round(+item.total_amount)) :
                                        formatNumber(Math.round(+item.total_calculate_amount))) || `&mdash;`
                                }}
                                {{ +item.total_amount || +item.total_calculate_amount ? '₽' : '' }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                <div class="listing-table__col-label">Период</div>
                                {{ formatDate(item.date_salary_start) }} - {{ formatDate(item.date_salary_end) }}
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                <StatusSelect
                                    :value="item.issued_to_user"
                                    :options="options"
                                    :disabled="authUser.read_only || item.issued_to_user"
                                    @select="patchSalary(item, $event)"
                                />
                                <!-- <app-select
                                    :input-value="item.issued_to_user"
                                    :default-value="null"
                                    :mod-class="item.issued_to_user ? 'salary-confirm' : 'salary-await'"
                                    :options="options"
                                    :mobile="width < 1024"
                                    :disabled="authUser.read_only || item.issued_to_user"
                                    @select="patchSalary(item, $event)"
                                /> -->
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="!salaries.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" />
            </div>
        </div>
        <app-create-button v-if="!authUser.read_only" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import moment from 'moment';

import { patchSalary, patchCourierOrders } from '@/api/django';
import { markTransferAsPaid } from '@/api/stock';
import { patchUserChange } from '@/api/timetracking';

import { numberFormat } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'TheSalarys',
    metaInfo: {
        title: 'Зарплаты'
    },
    components: {
        Spinner,
    },
    data() {
        return {
            options: [
                { name: 'Ожидает подтверждения', id: false, icon: 'clock', color: '#F2C94C' },
                { name: 'Выплачено', id: true, icon: 'check', color: '#36B687' },
            ]
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            preloader: 'ui/preloader',
            authUser: 'auth/user',
            salaries: 'salaries/salaries',
            busy: 'salaries/busy',
            next: 'salaries/next',
            activeFilters: 'salaries/activeFilters'
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        await this.$store.dispatch('salaries/GET_SALARIES');
        this.$store.commit('ui/SET_PRELOADER', false);
    },
    methods: {
        loadMore() {
            this.$store.dispatch('salaries/LOAD_MORE');
        },
        async patchSalary(salary, event) {
            if (event) {
                salary.issued_to_user = true;

                await patchSalary(salary);

                const dates = salary.additional_ids.length ? salary.additional_ids.map((item) => item.date).join() : '';
                const data = {
                    dates,
                    uid: salary.user,
                    salary_id: salary.id
                }

                patchUserChange(data);

                if (salary.read_user.groups[0] !== 'florist') {
                    if (salary.couriers_payment.orders.length) {
                        patchCourierOrders({
                            order_ids: salary.couriers_payment.orders.map((item) => item.id),
                        });
                    }
                    if (salary.couriers_payment.transfers.length) {
                        markTransferAsPaid({
                            transfer_ids: salary.couriers_payment.transfers.map((item) => item.id),
                            is_paid_for_courier: 'True'
                        });
                    }
                }
            }
        },
        formatDate(date) {
            return moment(date).format('DD.MM.YYYY');
        },
        formatNumber(number) {
            return numberFormat(number);
        },
    }
}
</script>

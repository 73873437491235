<template>
    <div class="sort-col__wrapper" :class="{'active': active, 'down': direction === '-'}">
        <button class="sort-col__title" @click.prevent="changeSort">
            <span>{{ title }}</span>
            <Icon v-if="active" size="16" class="ml-2">
                arrow-up
            </Icon>
        </button>
    </div>
</template>

<script>
import { debounce } from '@/utils/functions';

export default {
    name: 'SortTable',
    props: {
        title: { type: String, default: '' },
        direction: { type: String, default: '-' },
        type: { type: String, default: 'id' },
        active: { type: Boolean, default: false }
    },
    methods: {
        changeSort: debounce(function () {
            this.changeSortFunc();
        }, 250),
        changeSortFunc() {
            this.$emit('change', { type: this.type });
        }
    }
}
</script>

<style lang="scss" scoped>
    .sort-col__wrapper {
        position: relative;

        &.active {
            span {
                color: #0F1922 !important;
            }

            .icon {
                transition: .2s;
            }

            &.down .icon {
                transform: rotate(180deg);
            }
        }
    }


    .sort-col__title {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 20px;
        color: #8AACCD;
        outline: none;
        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

</style>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Заказы"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 id="list" class="page__info-group-title">Список заказов</h2>
                                <p>В списке заказов выводятся поля:</p>
                                <ul>
                                    <li>Фото заказа</li>
                                    <li>№ заказа</li>
                                    <li>Имя заказчика (если покупка произошла в магазине, то заказчиком будет адрес магазина)</li>
                                    <li>Дата и время доставки</li>
                                    <li>Адрес доставки/самовывоза</li>
                                    <li>Магазин</li>
                                    <li>Статус заказа</li>
                                    <li>Стоимость заказа</li>
                                </ul>

                                <template v-if="authUserGroup !== 'courier'">
                                    <p>В шапке таблицы можно нажать на некоторые ячейки, чтобы выполнить сортировку.</p>
                                    <img src="@/assets/img/help/orders/1.jpg" class="help__image" alt="" style="margin: 16px auto">
                                </template>

                                <p>Изменить статус заказа можно из выпадающего списка.</p>
                                <img src="@/assets/img/help/orders/2.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Есть несколько быстрых фильтров.</p>
                                <p><b>Сегодня</b> - выводятся заказы на сегодня с доставкой в статусах “Оплачен”, “Принят”, “Готов к выдаче”, “Курьер выехал”.</p>
                                <p><b>Завтра</b> - выводятся заказы на завтра с доставкой в статусах “Оплачен”, “Принят”, “Готов к выдаче”, “Курьер выехал”.</p>
                                <template v-if="authUserGroup !== 'courier'">
                                    <p><b>Нет курьера</b> - выводятся заказы с доставкой в статусах “Оплачен”, “Принят”, “Готов к выдаче”, “Курьер выехал”, у которых нет курьера.</p>
                                    <p v-if="authUserGroup !== 'florist'"><b>Нет магазина</b> - выводятся заказы в статусах “Оплачен”, “Принят”, “Готов к выдаче”, “Курьер выехал”, у которых нет магазина.</p>
                                    <p><b>Нет фото флориста</b> - выводятся заказы в статусах “Оплачен”, “Принят”, “Готов к выдаче”, “Курьер выехал”, у которых нет фото флориста.</p>
                                    <img v-if="authUserGroup !== 'florist'" src="@/assets/img/help/orders/3.jpg" class="help__image" alt="" style="margin: 16px auto">
                                </template>

                                <p>Есть поиск по номеру заказа и по номеру телефона заказчика.</p>

                                <p>Есть фильтр по параметрам:</p>
                                <ul>
                                    <li>Дата заказа</li>
                                    <li v-if="authUserGroup !== 'courier'">Способ доставки</li>
                                    <li v-if="authUserGroup !== 'florist'">Магазин</li>
                                    <li>Статус</li>
                                    <li v-if="authUserGroup !== 'courier'">Источник заказа</li>
                                    <li v-if="authUserGroup !== 'courier'">Способ оплаты</li>
                                    <li v-if="authUserGroup !== 'courier'">Оценка заказа</li>
                                    <li v-if="['supervisor'].includes(authUserGroup)">Промокоды</li>
                                    <li v-if="authUserGroup !== 'courier'">Курьеры</li>
                                    <li v-if="!['courier', 'florist'].includes(authUserGroup)">Флористы</li>
                                    <li v-if="!['courier', 'florist'].includes(authUserGroup)">Рефельность</li>
                                </ul>

                                <p>Есть индикатор срочного заказа. Он должен активироваться, когда до времени заказа остался 1 час.</p>
                                <img src="@/assets/img/help/orders/5.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>В мобильной версии из списка заказов можно сразу прикрепить фотографию. Если авторизованный пользователь с ролью <b>"Курьер"</b>, то добавляется фото курьера. Для всех остальных ролей кнопка прикрепляет фото флориста.</p>
                                <img src="@/assets/img/help/orders/6.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <div v-if="!['florist', 'courier'].includes(authUserGroup)" class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Создать новый заказ можно с помощью кнопки добавления нового элемента
                                        </div>
                                        <div class="help__table-col" style="max-width: 104px">
                                            <img src="@/assets/img/help/orders/7.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="authUserGroup !== 'courier'" class="page__info-group">
                                <h2 id="create" class="page__info-group-title">
                                    {{ authUserGroup !== 'florist' ? 'Создание заказов' : 'Просмотр и редактирование заказов' }}
                                </h2>

                                <template v-if="authUserGroup !== 'florist'">
                                    <p>Сначала необходимо выбрать способ доставки заказа. Остальные поля отобразятся после выбора.</p>
                                    <img src="@/assets/img/help/orders/24.jpg" class="help__image" alt="" style="margin: 16px auto">

                                    <p>При выборе <b>"Доставка"</b> предлагается ввести адрес доставки. Адрес необходимо указывать с названием населенного пункта. Если покупатель не знает адрес для доставки, то можно активировать переключатель <b>"Заказчик не знает адрес"</b>. В таком случае менеджеру нужно согласовать адрес с получателем.</p>
                                    <img src="@/assets/img/help/orders/25.jpg" class="help__image" alt="" style="margin: 16px auto">
                                    <p>Стоимость доставки рассчитывается автоматически. Но если по какой-либо причине стоимость доставки рассчиталась некорректно, то ее можно вручную изменить в сводной таблице стоимости заказ. Нужно нажать на "карандаш" и ввести нужную сумму.</p>
                                    <img src="@/assets/img/help/orders/26.jpg" class="help__image" alt="" style="margin: 16px auto">

                                    <p>При выборе <b>"Самовывоз"</b> необходимо указать пункт самовывоза.</p>

                                    <h3 id="reason">Повод заказа</h3>
                                    <p>Блок <b>"Повод заказа"</b> позволяет сразу сохранить повод для пользователя.</p>
                                    <p>Если переключатель <b>"Напомнить"</b> активен, то пользователь будет получать уведомление о поводе за 1 день до указанной даты.</p>
                                    <p>Если не указывать название повода, то никакие остальные данные, связанные с поводом, сохраняться не будут.</p>
                                    <img src="@/assets/img/help/orders/20.jpg" class="help__image" alt="" style="margin: 16px auto">

                                    <h3 id="bonuses">Использование бонусов</h3>
                                    <p>При создании заказа при вводе номера телефона заказчика происходит поиск пользователя по базе. Если пользователь найден, то его имя будет вставлено в поле <b>"Имя покупателя"</b> и появится возможность оплатить заказ бонусами.</p>
                                    <p>Применять бонусы нужно в самую последнюю очередь перед сохранением заказа. Иначе любое изменение стоимости заказа приведет к отмене введенных бонусов.</p>
                                    <p>Можно получить ошибку "Некорректное значение", если выполнены определенные условия при применении бонусов:</p>
                                    <ul>
                                        <li>Поле пустое</li>
                                        <li>Значение меньше нуля</li>
                                        <li>Значение не является числом</li>
                                        <li>Значение больше суммы заказа</li>
                                        <li>Значение больше, чем количество бонусов у пользователя</li>
                                    </ul>
                                    <p>Если в поле ввода бонусов указано значение "0", то при активации система зарезирвирует максимально возможное количество бонусов. Например, у пользователя 500 бонусов, а сумма заказа 1500р. Будут зарезервированы все 500 бонусов. Но если бы у пользователя было 2000 бонусов, то зарезервировалось бы 1500.</p>
                                    <img src="@/assets/img/help/orders/18.jpg" class="help__image" alt="" style="margin: 16px auto">

                                    <h3 id="triggers">Переключатели параметров заказа</h3>
                                    <p>Есть переключатели:</p>
                                    <ul>
                                        <li><b>Другой получатель</b> - указывает, что заказчик и получатель - это разные люди. Если этот переключатель не активен, то при заполнении полей <b>"Телефон заказчика"</b> и <b>"Имя заказчика"</b> поля, связанные с получателем, будут автоматически заполняться. При изменении состояния переключателя поля получателя будут автоматически меняться.</li>
                                        <li><b>Анонимный заказ</b> - указывает, что получатель не должен знать, кто является заказчиком.</li>
                                        <li><b>Фото с получателем</b> - указывает, что нужно сделать фото с получателем. Если заказ с самовывозом, то этот переключатель будет недоступен.</li>
                                    </ul>
                                    <img src="@/assets/img/help/orders/19.jpg" class="help__image" alt="" style="margin: 16px auto">
                                </template>

                                <h3 id="order-list">Управление списком товаров</h3>
                                <div class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            При нажатии на кнопку "Каталог" откроется модальное окно редактирования состава заказа
                                        </div>
                                        <div class="help__table-col" style="max-width: 167px">
                                            <img src="@/assets/img/help/orders/8.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>

                                <p>Справа отображаются категории товаров, а слева список. При клике на категорию можно посмотреть товары именно этой категории. Есть поиск по названию товара.</p>
                                <img src="@/assets/img/help/orders/9.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Состав заказа можно просмотреть в компактном и полном виде. Для переключения нужно нажать соответствующие кнопки.</p>
                                <img src="@/assets/img/help/orders/32.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Компактный режим предназначен для быстрого просмотра информации о составе заказа. Все изменения в товарах выполняются в полном режиме.</p>
                                <img src="@/assets/img/help/orders/33.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <img src="@/assets/img/help/orders/10.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <div class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            С помощью стрелки можно раскрыть состав товара. Если стрелки нет, значит товар не составной или состав отсутствует
                                        </div>
                                        <div class="help__table-col" style="max-width: 56px">
                                            <img src="@/assets/img/help/orders/11.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>

                                <p>У некоторых товаров есть поле <b>"Пожелание"</b>. Оно используется для того, чтобы покупатель мог добавить текст к товару. Наличие этого поля зависит от настроек товара. Под полем ввода находится счетчик символов. Если выделить фрагмент текста, то в скобках отобразится длина выделенного фрагмента.</p>
                                <img src="@/assets/img/help/orders/12.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <div class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Кнопка карандаша открывает модальное окно редактирования состава товара
                                        </div>
                                        <div class="help__table-col" style="max-width: 112px">
                                            <img src="@/assets/img/help/orders/13.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>
                                <img src="@/assets/img/help/orders/14.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <p>При нажатии на кнопку <b>"Изменить"</b> откроется модальное окно с выбором комплектующих.</p>
                                <p>Для товаров, у которых установлен тип увеличения количества на 30%/60%, добавляются соответствующие кнопки.</p>
                                <p>При нажатии на одну из этих кнопок состав товара автоматически пересчитывается. Если нажать на уже активную кнопку, то состав заказа вернется в вариант "Стандарт". Если для увеличенного на проценты букета изменить количество хотя бы одного составляющего, то активность кнопки спадет, и букет будет считаться с измененным составом.</p>
                                <p>Если изменить состав букета, то его стоимость будет автоматически пересчитываться исходя из стоимости выбранных комплектующих. <b>Важно отметить, что во время редактирования заказа сохранение изменений состава букета автоматически сохраняет весь заказ .</b></p>
                                <p>У товаров по акции выводится переключатель, на котором прописано значение скидки. Но при изменении состава стоимость букета начинает рассчитываться исходя из стоимости комплектующих. Поэтому скидка сбрасывается. Если скидку нужно восстановить, то нужно нажать на этот переключатель.</p>
                                <img src="@/assets/img/help/orders/15.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <h3 id="discount">Установка скидки и промокода</h3>
                                <div class="help__table">
                                    <div class="help__table-row">
                                        <div class="help__table-col">
                                            Если в составе заказа есть хотя бы 1 товар, то активируется кнопка для добавления скидки
                                        </div>
                                        <div class="help__table-col" style="max-width: 164px">
                                            <img src="@/assets/img/help/orders/16.jpg" class="help__image" alt="">
                                        </div>
                                    </div>
                                </div>
                                <p>Можно ввести сумму скидки вручную, либо выбрать готовый вариант.</p>
                                <p>Когда указан номер телефона покупателя, то появляется возможность ввести промокод. Если после применения промокода изменить размер скидки вручную, то промокод отменяется.</p>
                                <img src="@/assets/img/help/orders/17.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <template v-if="authUserGroup !== 'florist'">
                                    <h3 id="review">Отзыв на заказ</h3>
                                    <p>Если заказ выполнен, и к нему оставили отзыв, то этот отзыв будет виден. Можно изменить активность, оценку и текст отзыва сразу из заказа.</p>
                                    <img src="@/assets/img/help/orders/21.jpg" class="help__image" alt="" style="margin: 16px auto">

                                    <h3 id="bill-payment">Оплата по счету</h3>
                                    <p>Если у заказа установлен способ оплаты <b>"По счету"</b>, то будут активны поля:</p>
                                    <ul>
                                        <li>Название компании</li>
                                        <li>Юридический адрес</li>
                                        <li>ИНН</li>
                                        <li>КПП</li>
                                    </ul>
                                    <img src="@/assets/img/help/orders/22.jpg" class="help__image" alt="" style="margin: 16px auto">
                                    <p>А в дополнительном меню заказа будет пункт <b>"Сформировать счет"</b></p>
                                </template>


                                <h3 id="additional-menu">Дополнительное меню</h3>
                                <p>Также в дополнительном меню есть пункты <b>"Скопировать заказ"</b>, <b>"Скопировать состав"</b>.</p>
                                <img src="@/assets/img/help/orders/23.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Кнопка <b>"Скопировать заказ"</b> формирует текст, который содержит состав заказа и основные важные параметры. Этот текст сохраняется в буфер обмена операционной системы.</p>
                                <p>Кнопка <b>"Скопировать состав"</b> формирует текст, который содержит подробный состав заказа. Тут перечисляются букеты и полностью все составляющие для каждого. Этот текст сохраняется в буфер обмена операционной системы.</p>
                            </div>
                            <div v-else class="page__info-group">
                                <h2 class="page__info-group-title">Просмотр заказа</h2>
                                <p>Если нужно сделать фото с получателем, то при каждом открытии заказа будет открываться модальное окно с напоминанием.</p>
                                <img src="@/assets/img/help/orders/27.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Чтобы посмотреть список товаров в заказе, нужно нажать на кнопку <b>"Список товаров"</b></p>
                                <img src="@/assets/img/help/orders/28.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <p>Откроется модальное окно со всеми товарами. При нажатии на стрелку рядом с названием раскроется состав товара.</p>
                                <img src="@/assets/img/help/orders/29.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Если в заказ нужно добавить фото с получателем, то будет доступна кнопка "Фото с получателем".</p>
                                <img src="@/assets/img/help/orders/30.jpg" class="help__image" alt="" style="margin: 16px auto">
                                <p>При нажатии откроется модальное окно, в котором можно загрузить фото, либо отметить, что получатель отказался фотографироваться.</p>
                                <img src="@/assets/img/help/orders/31.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{ name: 'Уведомления', link: '/help/notifications' }, rightNavigationLink]" />
                    </div>
                    <div class="page-small-column page-small-column--mobile-top">
                        <HelpSidebar
                            v-if="authUserGroup !== 'courier'"
                            :links="[
                                { name: 'Список заказов', link: 'list' },
                                {
                                    name: authUserGroup !== 'florist' ? 'Создание, просмотр и редактирование заказа' : 'Просмотр и редактирование заказа',
                                    link: 'create',
                                    children: [
                                        { name: 'Добавление повода', link: 'reason', groups: ['managers', 'administrators', 'supervisor'] },
                                        { name: 'Использование бонусов', link: 'bonuses', groups: ['managers', 'administrators', 'supervisor'] },
                                        { name: 'Переключатели параметров', link: 'triggers', groups: ['managers', 'administrators', 'supervisor'] },
                                        { name: 'Управление списком товаров', link: 'order-list' },
                                        { name: 'Установка скидки и промокода', link: 'discount' },
                                        { name: 'Отзыв на заказ', link: 'review', groups: ['managers', 'administrators', 'supervisor'] },
                                        { name: 'Оплата по счету', link: 'bill-payment', groups: ['managers', 'administrators', 'supervisor'] },
                                        { name: 'Дополнительное меню', link: 'additional-menu' },
                                    ]
                                }
                            ]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'
import HelpSidebar from '@/components/app/help/HelpSidebar'

export default {
    name: 'TheHelpOrders',
    metaInfo: {
        title: 'Заказы - Помощь'
    },
    components: {
        HelpNavigationFooter, HelpSidebar
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        rightNavigationLink() {
            if (this.authUserGroup === 'florist') {
                return { name: 'Товары', link: '/help/products' }
            } else if (this.authUserGroup === 'courier') {
                return { name: 'К оглавлению', link: '/help' }
            }
            return { name: 'Чаты', link: '/help/chat' }
        }
    },
};
</script>

<template>
    <div class="time-tracking-wrap" @click.stop.prevent="toggleTracking">
        <button
            type="button"
            class="button-time-tracking"
            :class="tracking ? 'stop' : 'start'"
        >
            <svg v-show="!tracking" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 3.83166C5 3.0405 5.87525 2.56266 6.54076 2.99049L13.0248 7.15882C13.6372 7.55246 13.6372 8.44754 13.0248 8.84118L6.54076 13.0095C5.87525 13.4373 5 12.9595 5 12.1683V3.83166Z" fill="white"/>
            </svg>
            <svg v-show="tracking" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="3" y="3" width="10" height="10" rx="2" fill="white"/>
            </svg>
        </button>
        <div class="time-tracking__info">
            <div class="time-tracking__text">
                {{ !tracking ? 'Запустить таймер' : 'Остановить' }}
            </div>
        </div>
    </div>
</template>

<script>
import { userBusy, createEntry, updateEntry } from '@/api/timetracking';

export default {
    name: 'ButtonTimeTracking',
    data() {
        return {
            group: '',
            tracking: false
        };
    },
    async mounted() {
        if (!localStorage || !localStorage.getItem('user')) return;

        this.group = localStorage.getItem('group');
        const uid = JSON.parse(localStorage.getItem('user')).id;

        const response = await userBusy(uid)
        if (response[uid].id) {
            this.tracking = true;
            localStorage.setItem('time_tracking_id', response[uid].id);
        }
    },
    methods: {
        async toggleTracking() {
            if (!localStorage || !localStorage.getItem('user')) return;

            const uid = JSON.parse(localStorage.getItem('user')).id;

            if (this.tracking) {
                const id = window.localStorage.getItem('time_tracking_id');

                await updateEntry(id)
                localStorage.removeItem('time_tracking_id');
                this.tracking = false;
            } else {
                const response = await createEntry(uid)
                if (response && response.id) {
                    localStorage.setItem('time_tracking_id', response.id);
                    this.tracking = true;
                }
            }
        }
    },
};
</script>

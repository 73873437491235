<template>
    <div class="order__bottom-bar-wrap">
        <button
            id="order-tutorial__step-2"
            type="button"
            class="order__bottom-bar-item"
            :disabled="disabledCatalog"
            @click.prevent="$emit('click:catalog')"
        >
            <Icon>grid</Icon>
            <div class="order__bottom-bar-item-text">Каталог</div>
        </button>
        <button
            id="order-tutorial__step-6"
            type="button"
            class="order__bottom-bar-item"
            :class="{'order__bottom-bar-item--error': saleError}"
            :disabled="disabledSale"
            @click.prevent="$emit('click:sale')"
        >
            <Icon>sale</Icon>
            <div class="order__bottom-bar-item-text">Скидка</div>
        </button>
        <div class="order__bottom-bar-item">
            <Icon>rub-circle</Icon>
            <div class="order__bottom-bar-item-text">
                {{ orderCost || 0 }}₽
            </div>
        </div>
        <div v-if="width > 699" class="order__bottom-bar-item">
            <Button :disabled="disabledSave" @click.prevent="$emit('click:save')">
                Сохранить
            </Button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'OrderBottomBar',
    props: {
        orderCost: { type: [Number, String], default: 0 },
        disabledCatalog:  { type: [Number, String, Boolean], default: false },
        disabledSale:  { type: [Number, String, Boolean], default: false },
        disabledSave:  { type: [Number, String, Boolean], default: true },
        saleError:  { type: [Number, String, Boolean], default: false },
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            authUser: 'auth/user',
        }),
    },
    methods: {}
}
</script>

<template>
    <div class="universal-modal__component">
        <UniversalModalHeader
            :title="pickedProducts.length ? `${pickedProducts.length} шт.` : 'Выберите товары'"
            @click:right="$emit('closeModal')"
        >
            <template #rightButtons>
                <button
                    v-if="pickedProducts.length"
                    type="button"
                    class="universal-modal__header-right-btn"
                    @click.prevent="clearPickedProducts"
                >
                    <Icon>file-minus</Icon>
                </button>
                <button
                    v-if="width <= 699"
                    type="button"
                    class="universal-modal__header-right-btn"
                    @click.prevent="isSearchShow = !isSearchShow"
                >
                    <Icon>search</Icon>
                </button>
            </template>
        </UniversalModalHeader>
        <div
            ref="scrolledBlock"
            class="universal-modal__content"
            v-infinite-scroll="loadMore" infinite-scroll-distance="300" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
        >
            <Search
                :value="filters.name"
                :is-visible-mobile="isSearchShow"
                observer-margin="45"
                @input="$store.commit('goods/SET_FILTER', { field: 'name', value: $event}), getFilteredGoods()"
            />

            <button type="button" class="goods__category-name" @click.prevent="showCategoryModal = true">
                {{ selectedCategory.is_name || 'Все товары' }}
                <Icon class="ml-2" color="#8AACCD">down</Icon>
            </button>

            <div class="page-columns">
                <div class="page-big-column">
                    <div v-show="!preloader" class="listing-table__wrap">
                        <div v-if="goods && goods.length" class="listing-table listing-table--products">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                <div class="listing-table__col pl-0">Наименование</div>
                                <div class="listing-table__col" style="width: 96px; flex: 0 0 auto;">Cборка</div>
                                <div class="listing-table__col" style="width: 100px; flex: 0 0 auto;">Стоимость</div>
                                <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;text-align:center;">Количество</div>
                            </div>
                            <a
                                v-for="item in goods"
                                :key="item.vendor_code"
                                class="listing-table__row"
                                :class="{ active: selected(item.vendor_code) }"
                                @click.prevent="$store.dispatch('equipments/ADD_TO_EQUIPMENTS', item)"
                            >
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image">
                                        <img
                                            :src="item.images.length && item.images[0].image ? item.images[0].image.preview : require('@/assets/img/flower.svg')"
                                            alt=""
                                        >
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width < 1024}"
                                    :style="width > 1024 ? 'width: calc(100% - 458px)' : 'width: calc(100% - 68px)'"
                                >
                                    <div>
                                        <div class="listing-table__product-name">
                                            <span>{{ item.name }}</span>
                                            <Icon
                                                v-if="item.equipment_set.length"
                                                class="listing-table__product-name-chevron"
                                                :class="{'rotate': opened.includes(item.vendor_code)}"
                                                color="#8AACCD"
                                                @click.stop.prevent="openHandler(item.vendor_code)"
                                            >
                                                down
                                            </Icon>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                <div class="listing-table__col" :style="width > 1024 ? 'width: 96px; flex: 0 0 auto;' : ''">
                                    <div class="listing-table__col-label">Сборка</div>
                                    {{ item.order_time }} ч.
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 100px; flex: 0 0 auto;' : ''">
                                    <div class="listing-table__col-label">Стоимость</div>
                                    {{ formatNumber(+item.price) }} ₽
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : ''">
                                    <InputCounter v-model="item.count" @input="changeProductCount(item)" />
                                </div>
                                <div
                                    v-if="group !== 'florist'"
                                    class="checkbox-group"
                                    :class="{
                                        'checkbox-group--inline mx-4 mb-2': width > 1024,
                                        'mt-2' : width <= 1024
                                    }"
                                >
                                    <div class="checkbox-group__item">
                                        <Checkbox :value="item.is_calculate" label="Изменять кол-во" @change="toggleProductProp(item, 'is_calculate')" />
                                    </div>
                                    <div class="checkbox-group__item">
                                        <Checkbox :value="item.display_amount" label="Показывать кол-во" @change="toggleProductProp(item, 'display_amount')" />
                                    </div>
                                </div>
                                <!-- <v-chip
                                    v-if="group !== 'florist'"
                                    class="control-line__btn ml-0"
                                    :color="item.is_calculate ? 'primary' : '#e0e0e0'"
                                    style="height: 24px"
                                    @click.stop="toggleProductProp(item, 'is_calculate')"
                                >
                                    Изменять кол-во
                                </v-chip>
                                <v-chip
                                    v-if="group !== 'florist'"
                                    class="control-line__btn ml-0"
                                    style="height: 24px"
                                    :color="item.display_amount ? 'primary' : '#e0e0e0'"
                                    @click.stop="toggleProductProp(item, 'display_amount')"
                                >
                                    Показывать кол-во
                                </v-chip> -->
                            </a>
                            <div class="listing-table__row listing-table__row--preloader">
                                <div class="listing-table__col">
                                    <Spinner v-show="busy && next" />
                                </div>
                            </div>
                        </div>
                        <empty-table v-show="goods && !goods.length" />
                    </div>
                    <ContentPreloader v-show="preloader" type="table" height="96" />
                </div>
                <div v-if="width > 699" class="page-small-column">
                    <ProductsCategories
                        visible-categories-type="equipment"
                        :offsetHeight="190"
                        :activeCategory="filters.category"
                        @changeCategory="filterByCategory"
                    />
                </div>
            </div>
        </div>
        <UniversalModal v-model="showCategoryModal">
            <UniversalModalHeader
                :title="'Категории'"
                @click:right="showCategoryModal = false"
            />
            <div class="universal-modal__content">
                <ProductsCategories
                    visible-categories-type="equipment"
                    :activeCategory="filters.category"
                    @changeCategory="filterByCategory"
                />
            </div>
        </UniversalModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from '@/utils/functions';
import { numberFormat } from '@/utils/format';

import Spinner from '@/components/app/Spinner';

export default {
    name: 'EquipmentsModal',
    components: { Spinner },
    data() {
        return {
            selectedCategory: {},
            opened: [],
            isSearchShow: false,
            showCategoryModal: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            busy: 'goods/busy',
            preloader: 'ui/preloader',
            equipCategory: 'data/equipCategory',
            pickedProducts: 'equipments/equipment_set',
            filters: 'goods/filters',
            savedFilters: 'goods/savedFilters',
            goods: 'goods/goods',
            next: 'goods/next',
            selected: 'equipments/selected',
        }),
        group() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    mounted() {
        try {
            this.$store.commit('goods/SET_SAVED_FILTERS', this.filters);
            this.$store.commit('goods/CLEAR');
            this.$store.commit('goods/CLEAR_FILTERS_STATE');
            let interval = setInterval(async () => {
                if (this.equipCategory.id) {
                    clearInterval(interval);
                    this.activeCategory = this.equipCategory;
                    await this.$store.dispatch('goods/GET_ALL_GOODS', { field: 'category', value: this.equipCategory.id });
                    this.syncEquipmentListWithProductList();
                    this.$store.commit('ui/SET_PRELOADER', false);
                }
            }, 100);

            let delay = this.group === 'florist' ? 1000  : 15000;
            setTimeout(async () => {
                clearInterval(interval);
                if (!this.equipCategory.id) {
                    await this.$store.dispatch('goods/GET_ALL_GOODS');
                    this.syncEquipmentListWithProductList();
                    this.$store.commit('ui/SET_PRELOADER', false);
                }
            }, delay);
        } catch {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        this.$store.commit('goods/CLEAR_FILTERS_STATE');
        this.$store.commit('goods/SET_FILTERS', this.savedFilters);
        this.$store.commit('goods/SET_SAVED_FILTERS', {});
    },
    methods: {
        getFilteredGoods: debounce(function (field, value) {
            this.filterGoods(field, value);
        }, 1000),
        async filterGoods(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('goods/GET_ALL_GOODS', { field, value });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async loadMore() {
            if (this.next) {
                await this.$store.dispatch('goods/GET_NEXT_PAGE');
                this.syncEquipmentListWithProductList();
            }
        },
        async filterByCategory(category) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.showCategoryModal = false;
                this.$refs.scrolledBlock.scrollIntoView();
                if (this.filters.category === null || this.filters.category !== category.id) {
                    this.selectedCategory = category;
                    await this.$store.dispatch('goods/GET_ALL_GOODS', { field: 'category', value: category.id });
                } else {
                    this.selectedCategory = this.equipCategory;
                    await this.$store.dispatch('goods/GET_ALL_GOODS', { field: 'category', value: this.equipCategory.id });
                }
                this.syncEquipmentListWithProductList();
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        changeProductCount(product) {
            if (!this.selected(product.vendor_code)) {
                this.$store.dispatch('equipments/ADD_TO_EQUIPMENTS', product)
            } else {
                this.$store.commit('equipments/CHANGE_VALUE_FIELD', {
                    vendor_code: product.vendor_code,
                    value: +product.count,
                    field: 'count'
                });
            }
        },
        async toggleProductProp(product, prop) {
            product[prop] = !product[prop];
            if (!this.selected(product.vendor_code)) {
                this.$store.dispatch('equipments/ADD_TO_EQUIPMENTS', product)
            } else {
                this.$store.commit('equipments/CHANGE_VALUE_FIELD', {
                    vendor_code: product.vendor_code,
                    value: product[prop],
                    field: prop
                });
            }
        },
        syncEquipmentListWithProductList() {
            // синхронизируем количество в составе товара с количеством товаров в текущей модалке
            if (this.goods.length) {
                this.goods.forEach((item) => {
                    // Добавляем в каждый товар реактивные поля
                    this.$set(item, 'count', 1);
                    this.$set(item, 'is_calculate', true);
                    this.$set(item, 'display_amount', true);
                    if (this.selected(item.vendor_code)) {
                        const find = this.pickedProducts.find((product) => product.equipment_product.vendor_code === item.vendor_code);
                        if (find) {
                            item.count = find.count;
                            item.is_calculate = find.is_calculate;
                            item.display_amount = find.display_amount;
                        }
                    }
                })
            }
        },
        clearPickedProducts() {
            this.$store.commit('equipments/CLEAR_EQUIPMENTS');
        },
        openHandler(id) {
            if (this.opened.includes(id)) {
                this.opened = this.opened.filter((a) => a !== id);
            } else {
                this.opened.push(id);
            }
        },
        formatNumber(number) {
            return number === 0 ? 0 : numberFormat(number);
        },
    }
};
</script>

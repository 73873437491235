<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial
            v-if="$route.params.ID && writeoffs.status === 0 && group === 'supervisor' && !user.read_only"
            v-model="fab_open"
            class="btn__floating custom-speed-dial"
            close-on-click
            fixed
        >
            <template v-slot:activator>
                <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item @click="showConfirmDeleteModal = true">
                        <v-list-item-icon class="mr-3">
                            <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Удалить списание</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="!!$route.params.ID ? `Списание № ${$route.params.ID}` : 'Новое списание товара'"
                left-btn="left-big"
                :show-save-btn="!user.read_only && writeoffs_goods.length && (!$route.params.ID || ($route.params.ID && writeoffs.status === 0))"
                @click:left="$router.push({ name: 'offs' })"
                @click:save="saveItem"
            />
            <div class="page-container page-container--with-fab">
                <ActionBar
                    v-if="pickedImages.length"
                    class="writeoffs-acticon-bar"
                    with-download
                    :with-hide="false"
                    :picked="pickedImages"
                    :all-picked="writeoffs.images.every(image => pickedImages.includes(image.id))"
                    :observerMargin="1"
                    @download="downloadImages"
                    @checkbox="checkboxAllHandler"
                />
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="page__info-group">
                            <Row>
                                <Col v-if="$route.params.ID" :cols="width > 1023 ? 6 : 12">
                                    <InputSelect
                                        :value="writeoffs.status"
                                        label="Статус"
                                        :options="options"
                                        input-value="id"
                                        input-text="name"
                                        :disabled="!$route.params.ID || writeoffs.status === 1 || group === 'florist'"
                                        @input="patchStatus($event)"
                                    />
                                </Col>
                                <Col v-if="writeoffs.date" :cols="width > 1023 ? 6 : 12">
                                    <InputCustom
                                        :value="date(writeoffs.date, 'hours-ru')"
                                        label="Дата списания"
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col :cols="width > 1023 ? 6 : 12">
                                    <InputCustom
                                        :value="`${writeoffs.employee === '' ? `${user.first_name} ${user.last_name}` : writeoffs.employee}`"
                                        label="Сотрудник"
                                        disabled
                                    />
                                </Col>
                                <Col :cols="width > 1023 ? 6 : 12">
                                    <InputSelect
                                        v-model="writeoffs.stock"
                                        :options="shops.results"
                                        label="Магазин"
                                        :input-value="'id'"
                                        :input-text="'address'"
                                        input-icon="type_icon"
                                        :disabled="!!$route.params.ID"
                                        :error="errors && errors.stock ? errors.stock : null"
                                    />
                                </Col>
                                <Col cols="12">
                                    <InputCustom
                                        v-model="writeoffs.manager_comment"
                                        label="Комментарий"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div class="page__info-group">
                            <div v-show="!preloader" class="listing-table__wrap">
                                <div v-if="writeoffs_goods.length" class="listing-table">
                                    <div class="listing-table__row listing-table__row--head">
                                        <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                        <div class="listing-table__col pl-0">Наименование</div>
                                        <div class="listing-table__col" style="width: 150px; flex: 0 0 auto;text-align: center;">Количество</div>
                                        <div class="listing-table__col" style="width: 70px; flex: 0 0 auto;" />
                                    </div>
                                    <div v-for="good in writeoffs_goods" :key="good.id" class="listing-table__row">
                                        <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                            <div class="listing-table__image listing-table__image--with-link">
                                                <img
                                                    :src="good.product.image || good.image ? good.image || good.product.image :
                                                        require('@/assets/img/flower.svg')
                                                    "
                                                    alt=""
                                                >
                                                <a
                                                    href="#"
                                                    class="listing-table__image-link"
                                                    @click.stop.prevent="linkToGood(good.product.vendor_code || good.product)"
                                                >
                                                    <v-icon color="primary">mdi-open-in-new</v-icon>
                                                </a>
                                            </div>
                                        </div>
                                        <div
                                            class="listing-table__col listing-table__col--mobile-inline"
                                            :class="{'pt-0 mr-0': width <= 1024}"
                                            :style="width > 1024 ? 'width: calc(100% - 332px)' : 'width: calc(100% - 68px)'"
                                        >
                                            <div>
                                                <div class="listing-table__product-name">
                                                    <span>{{ good.name || good.product.name }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                        <div
                                            class="listing-table__col listing-table__col--mobile-inline"
                                            :style="width > 1024 ? 'width: 150px; flex: 0 0 auto;' : 'max-width: 150px'"
                                        >
                                            <InputCounter
                                                v-model="good.count"
                                                :disabled="$route.params.ID && group !== 'supervisor'"
                                                @input="$store.commit('transfers/CHANGE_COUNT_TRANSFERS_GOOD', { product: good.product, value: +$event })"
                                            />
                                        </div>
                                        <div
                                            class="listing-table__col listing-table__col--mobile-inline ml-a"
                                            :style="width > 1024 ? 'width: 70px; flex: 0 0 auto;' : ''"
                                        >
                                            <v-btn
                                                icon
                                                :disabled="$route.params.ID && group !== 'supervisor'"
                                                @click.stop="$store.commit('writeoffs/REMOVE_GOOD_FROM_WRITEOFFS', good.product)"
                                            >
                                                <Icon color="#0D63F3">trash</Icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ContentPreloader v-show="preloader" type="table" />
                            <div v-if="!$route.params.ID" class="d-flex justify-center mt-4">
                                <Button bordered icon-right="edit-2" @click.prevent="openModalHandler">
                                    Изменить
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div class="page-small-column page-small-column--mobile-top">
                        <PhotoSlider
                            :images="writeoffs.images"
                            :with-new-photo-btn="!user.read_only && writeoffs.images.length < 9"
                            with-checkbox
                            :picked-images="pickedImages"
                            @newPhoto="loadImage($event)"
                            @changeSort="changeSort($event)"
                            @remove="removeImage($event)"
                            @checkPhoto="toggleImageChecked($event)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmDeleteModal"
            title="Удалить списание?"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteModal = false"
            @mainBtnClick="deleteItem"
        />
        <UniversalModal v-model="isModalOpen" size="full">
            <WriteOffsModal @closeModal="isModalOpen = false" />
        </UniversalModal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { date } from '@/utils/format';

import {
    saveWriteOffs, fetchImageID, downloadWriteOffsImages, deleteWriteOffs
} from '@/api/stock';

import MiniModal from "@/components/modal/MiniModal";
import ActionBar from '@/components/app/ActionBar';
import WriteOffsModal from '@/components/app/writeoffs/modals/WriteOffsModal';
import PhotoSlider from '@/components/app/PhotoSlider';

export default {
    name: 'TheStoreroomOffsCreate',
    metaInfo() {
        return { title: this.$route.params.ID ? `Списание ${this.$route.params.ID}` : 'Новое списание' }
    },
    components: { MiniModal, ActionBar, WriteOffsModal, PhotoSlider },
    data() {
        return {
            writeoffs: {
                employee: '',
                stock: null,
                manager_comment: '',
                images: [],
            },
            errors: {},
            options: [
                { name: 'Ожидает подтверждения', id: 0 },
                { name: 'Выполнено', id: 1 },
            ],
            pickedImages: [],
            fab_open: false,
            showConfirmDeleteModal: false,
            isModalOpen: false
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            writeoffs_goods: 'writeoffs/writeoffs_goods',
            shops: 'shops/shops',
            preloader: 'ui/preloader',
        }),
        user() {
            return JSON.parse(localStorage.getItem('user'));
        },
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        }
    },
    watch: {
        'writeoffs.stock'() {
            delete this.errors.stock;
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            if (this.$route.params.ID) {
                const writeoffs = await this.$store.dispatch('writeoffs/GET_WRITEOFFS_DATA', this.$route.params.ID)
                this.writeoffs = writeoffs;
                this.markAsViewed();
            }
            if (this.user) {
                await this.$store.dispatch('shops/GET_SHOPS');
                if (this.user.groups[0] === 'florist') {
                    this.writeoffs.stock = this.user.shop;
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    destroyed() {
        this.$store.commit('writeoffs/CLEAR_WRITEOFFS_GOODS');
    },
    methods: {
        async saveItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                let checkZeroCount = false;
                this.writeoffs_goods.forEach((good) => {
                    if (good.count < 1) {
                        checkZeroCount = true;
                    }
                });
                if (checkZeroCount) {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Невозможно создать списание с нулевым количеством товара', type: 'error' })
                    return;
                }

                const data = {
                    stock: this.writeoffs.stock,
                    images: this.writeoffs.images.map((image) => image.id),
                    manager_comment: this.writeoffs.manager_comment
                }

                if (!this.$route.params.ID) {
                    await this.$store.dispatch('writeoffs/CREATE_NEW_WRITEOFFS', data)
                } else {
                    await this.$store.dispatch('writeoffs/SAVE_WRITEOFF', { id: this.$route.params.ID, ...data })
                }

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Списание успешно сохранено', type: 'success' })
                this.$router.push({ name: 'offs' });
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                if (error.data) {
                    this.errors = error.data;
                }
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка сохранения списания', type: 'error' })
            }
        },
        async deleteItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                await deleteWriteOffs(this.$route.params.ID);

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Списание успешно удалено', type: 'success' })
                this.$router.push({ name: 'offs' });
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка удаления списания', type: 'error' })
            } finally {
                this.showConfirmDeleteModal = false;
            }
        },
        linkToGood(vendorСode) {
            window.open(`/goods/${vendorСode}`, '_blank');
        },
        linkToImage(image) {
            window.open(`${image.image.original}`, '_blank');
        },
        async confirmWriteOffs(id) {
            this.$store.dispatch('ui/checkUserEditPermission');

            await this.$store.dispatch('writeoffs/APPLY_WRITEOFFS', id);

            this.$store.commit('ui/add_item_in_system_notifications', { message: 'Успешно подтверждено', type: 'success' })
            this.$router.push({ name: 'offs' });
        },
        async patchStatus(status) {
            this.$store.dispatch('ui/checkUserEditPermission');
            if (status === 0) return;

            await this.$store.dispatch('writeoffs/APPLY_WRITEOFFS', this.$route.params.ID);

            this.$store.commit('ui/add_item_in_system_notifications', { message: 'Успешно подтверждено', type: 'success' })
            const writeoffs = await this.$store.dispatch('writeoffs/GET_WRITEOFFS_DATA', this.$route.params.ID);
            this.writeoffs = writeoffs;
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        openModalHandler() {
            if (this.writeoffs.stock) {
                this.isModalOpen = true;
            } else {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Выберите магазин', type: 'error' })
                this.errors.stock = 'Выберите магазин';
            }
        },
        async loadImage(event) {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (event.target.files.length === 0) {
                    return;
                }

                this.$store.commit('ui/SET_PRELOADER', true);
                const file = event.target.files[0];
                const data = new FormData();

                data.append('image', file);
                event.target.value = '';
                const { id, image } = await fetchImageID(
                    'post',
                    '/writeoff_images/',
                    { 'Content-Type': 'multipart/form-data' },
                    data,
                );

                this.writeoffs.images.push({ id, image });
            } catch (error) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки фото', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        changeSort({ index, direction }) {
            this.$store.dispatch('ui/checkUserEditPermission');
            const changedImage = JSON.parse(JSON.stringify(this.writeoffs.images[index]));
            this.writeoffs.images.splice(index, 1)

            if (direction === 'left') {
                this.writeoffs.images.splice(index - 1, 0, changedImage)
            } else if (direction === 'right') {
                this.writeoffs.images.splice(index + 1, 0, changedImage)
            } else if (direction === 'main') {
                this.writeoffs.images.splice(0, 0, changedImage)
            }
        },
        removeImage(index) {
            this.$store.dispatch('ui/checkUserEditPermission');
            this.writeoffs.images.splice(index, 1);
        },
        toggleImageChecked(index) {
            if (this.pickedImages.includes(index)) {
                this.pickedImages = this.pickedImages.filter((elem) => elem !== index);
            } else {
                this.pickedImages.push(index);
            }
        },
        async checkboxAllHandler(bool) {
            if (bool) {
                this.pickedImages = this.writeoffs.images.map((i) => i.id);
            } else {
                this.pickedImages = [];
            }
        },
        async downloadImages() {
            const response = await downloadWriteOffsImages({ images_ids: this.pickedImages });

            const blob = new Blob([response], { type: 'application/zip' });
            const fileUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.target = '_blank';
            link.download = 'images.zip';
            link.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(fileUrl);
            }, 1000);
        },
        async markAsViewed() {
            this.$store.dispatch('ui/checkUserEditPermission');
            if (this.$route.params.ID && !this.writeoffs.is_seen) {
                await saveWriteOffs({ id: this.$route.params.ID, is_seen: true });
                this.$store.dispatch('franchises/GET_FRANCHISE_TARIFF');
                this.$store.dispatch('stock/GET_STOCK_INDICATIONS');
            }
        },
        date(value, type) {
            return date(new Date(value), type);
        },
    }
};
</script>

<template>
    <div v-if="!logged" class="block-form-login">
        <div class="block-form-login__form-wrapper">
            <div class="block-form-login__form-inner">
                <div class="login-logo">
                    <img src="@/assets/img/icon/saastra-logo.svg" alt="Lavanda IS">
                </div>
                <form @submit.prevent="handleLogin">
                    <!--
                    <v-text-field
                        v-model="app.login"
                        label="Логин"
                        class="mb-6"
                        hide-details="auto"
                        outlined
                        clearable
                        :error-messages="error && error.login ? error.login[0] : ''"
                    />
                    <v-text-field
                        v-model="app.password"
                        label="Пароль"
                        :type="passInputType ? 'text' : 'password'"
                        :append-icon="passInputType ? 'mdi-eye' : 'mdi-eye-off'"
                        outlined
                        hide-details="auto"
                        clearable
                        :error-messages="error && error.password ? error.password[0] : ''"
                        @click:append="passInputType = !passInputType"
                    />
                    -->
                    <InputCustom
                        v-model="app.login"
                        label="Логин"
                        class="mb-6"
                        clearable
                        :error="error && error.login ? error.login[0] : ''"
                    />
                    <InputCustom
                        v-model="app.password"
                        label="Пароль"
                        :type="passInputType ? 'text' : 'password'"
                        right-icon="eye"
                        :right-icon-color="passInputType ? '#2B77F5' : '#8AACCD'"
                        :error="error && error.password ? error.password[0] : ''"
                        @click:right="passInputType = !passInputType"
                    />
                    <div class="block-form__btn-block">
                        <!--<router-link to="/registration" class="block-form-login__reg-btn">
                            Регистрация
                        </router-link>-->
                        <!--<v-btn type="submit" depressed color="primary">Войти</v-btn>-->
                        <Button block @click="handleLogin">Войти</Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'TheAuth',
    metaInfo: {
        title: 'Авторизация'
    },
    data() {
        return {
            app: {
                login: '',
                password: '',
            },
            passInputType: false,
            error: {
                login: [],
                password: []
            }
        };
    },
    computed: {
        ...mapGetters({
            logged: 'auth/logged'
        }),
    },
    methods: {
        ...mapActions({
            auth: 'auth/LOGIN'
        }),
        async handleLogin() {
            if (this.app.login && this.app.password) {
                try {
                    await this.auth({ username: this.app.login, password: this.app.password });
                } catch (error) {
                    this.error.login = ['Неправильный логин или пароль'];
                    this.error.password = ['Неправильный логин или пароль'];
                }
            }
            if (!this.app.login) this.error.login = ['Поле не может быть пустым'];
            if (!this.app.password) this.error.password = ['Поле не может быть пустым'];
        }
    },
};
</script>

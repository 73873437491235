<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Заказы"
                for-listing
                :show-save-btn="false"
                filter
                :filterIndicator="!!activeFilters.length"
                :search="width <= 699"
                @click:filter="$store.commit('ui/OPEN_FILTER', 'order-filter')"
                @click:search="isVisibleSearch = !isVisibleSearch"
            />
            <div
                class="page-container page-container--listing page-container--with-fab"
                v-infinite-scroll="loadMore"
                infinite-scroll-distance="500" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy"
            >
                <Search
                    :value="filters.search"
                    class="mb-0"
                    :is-visible-mobile="isVisibleSearch"
                    @input="$store.commit('orders/SET_FILTER', { field: 'search', value: $event}), getFilteredOrders()"
                />
                <FastFilters :filters="filteredFastFilters" @change="toogleFastFilter" />
                <div v-show="!preloader" class="listing-table__wrap">
                    <div v-if="orders && orders.length" class="listing-table listing-table--orders">
                        <div class="listing-table__row listing-table__row--head">
                            <div class="listing-table__col" style="width: 112px;flex: 0 0 auto;"  />
                            <div class="listing-table__col pl-0" :style="`width: calc((100% - ${widthPropForTable}px) / 4 * 0.5);`">
                                <sort-table
                                    title="№ заказа"
                                    :direction="sort.direction"
                                    type="visible_id"
                                    :active="sort.type === 'visible_id'"
                                    @change="changeTableSort"
                                />
                            </div>
                            <div class="listing-table__col" :style="`width: calc((100% - ${widthPropForTable}px) / 4 * 0.8);`">
                                <sort-table
                                    title="Дата доставки"
                                    :direction="sort.direction"
                                    type="delivery_date"
                                    :active="sort.type === 'delivery_date'"
                                    @change="changeTableSort"
                                />
                            </div>
                            <div class="listing-table__col" :style="`width: calc((100% - ${widthPropForTable}px) / 4 * 1.15);`">
                                Адрес
                            </div>
                            <div class="listing-table__col flex-shrink-0" :style="`width: calc((100% - ${widthPropForTable}px) / 4 * 0.4); min-width: 120px;`">
                                Оплата
                            </div>
                            <div class="listing-table__col" :style="`width: 210px;flex: 0 0 auto;`">
                                Статус
                            </div>
                            <div v-if="width >= 1270 && authUserGroup === 'courier'" class="listing-table__col" style="width: 64px; flex: 0 0 auto;" />
                        </div>
                        <router-link
                            v-for="item in orders"
                            :key="item.visible_id"
                            :to="`/orders/${item.visible_id}`"
                            class="listing-table__row"
                        >
                            <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                <div class="listing-table__image">
                                    <img :src="item.preview_image ? item.preview_image : require('@/assets/img/flower.svg')" alt="">
                                    <v-tooltip bottom color="#000">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div
                                                v-if="item.is_hot"
                                                class="orders__hot-icon"
                                                v-bind="attrs" v-on="on"
                                            >
                                                <img src="@/assets/img/icon/order__hot.png" />
                                            </div>
                                        </template>
                                        <span>Срочно</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-inline"
                                :class="{'pt-0 mr-0': width <= 1024}"
                                :style="width > 1024 ? `width: calc((100% - ${widthPropForTable}px) / 4 * 0.5)` : 'width: calc(100% - 68px)'"
                            >
                                <div class="text-cut">
                                    <div class="mb-1" :class="{'order-table__highlight-number': item.visible_id == filters.search}">
                                        {{ item.visible_id }}
                                    </div>
                                    <span>
                                        {{ item.user.first_name ? item.user.first_name : 'Пользователь' }}
                                    </span>
                                </div>

                                <div v-if="width <= 1024 && item.order_from_cashbox" class="badge badge--purple" style="position: absolute;top:23px;right:16px;">
                                    Быстрый заказ
                                </div>
                            </div>

                            <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pb-0" />

                            <div class="listing-table__col" :style="width > 1024 ? `width: calc((100% - ${widthPropForTable}px) / 4 * 0.8)` : ''">
                                <div :class="{'d-flex align-center': width <= 1024 && item.delivery_time !== 'Согласовать с получателем'}">
                                    <img
                                        v-if="item.is_hot && width <= 1024"
                                        src="@/assets/img/icon/order__hot.png"
                                        class="mr-1"
                                    />
                                    <span :class="{'mr-1': width <= 1024}">
                                        {{ item.delivery_date ? moment(item.delivery_date).format('DD MMMM YYYY') : '&mdash;'}}
                                    </span>
                                    <br v-if="width > 1024 || item.delivery_time === 'Согласовать с получателем'">
                                    <span v-if="item.pickup && item.pickup_time">
                                        {{
                                            !['Ближайшее', 'Ввести самому', 'Согласовать с получателем'].includes(item.pickup_time) ? item.pickup_time :
                                            item.delivery_time_start ? `с ${formatDate(item.delivery_time_start, 'time-utc')} до ${formatDate(item.delivery_time_end, 'time-utc')}` :
                                                item.pickup_time === 'Согласовать с получателем' ? 'Согласовать с получателем' : 'Ближайшее'
                                        }}
                                    </span>

                                    <span v-if="!item.pickup && item.delivery_time">
                                        {{
                                            !['Ближайшее', 'Ввести самому', 'Согласовать с получателем'].includes(item.delivery_time) ? item.delivery_time :
                                            item.delivery_time_start ? `с ${formatDate(item.delivery_time_start, 'time-utc')} до ${formatDate(item.delivery_time_end, 'time-utc')}` : item.delivery_time === 'Согласовать с получателем' ? 'Согласовать с получателем' : 'Ближайшее'
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div class="listing-table__col" :style="width > 1024 ? `width: calc((100% - ${widthPropForTable}px) / 4 * 1.15)` : ''">
                                <div>
                                    <div class="d-flex align-center">
                                        <Icon color="#8AACCD" size="16" class="mr-2">store</Icon>
                                        <span style="display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;">
                                            {{ item.shop ? item.shop.address : '&mdash;' }}
                                        </span>
                                    </div>
                                    <div v-if="!item.pickup" class="d-flex align-center mt-1">
                                        <Icon color="#8AACCD" size="16" class="mr-2">map</Icon>
                                        <span style="display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;">
                                            {{ item.receiver_address }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-bordered flex-shrink-0"
                                :style="width > 1024 ? `width: calc((100% - ${widthPropForTable}px) / 4 * 0.4); min-width: 120px;` : ''"
                            >
                                <div>
                                    <div class="d-flex align-center">
                                        <Icon :color="item.is_paid ? '#0BD58A' : '#F00C29'" size="16" class="mr-1">
                                            {{ item.is_paid ? 'check' : 'close' }}
                                        </Icon>
                                        {{ formatNumber(Math.ceil(+item.amount_user_paid || +item.final_cost)) }} ₽
                                    </div>
                                    <!-- <div v-if="franchiseTariff.tariff && franchiseTariff.tariff.includes('site') && item.status === 6" class="order__table-rating">
                                        <v-tooltip bottom color="#000">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div v-bind="attrs" v-on="on">
                                                    <v-rating
                                                        :value="item.review ? item.review.rating : 0"
                                                        :color="setStarColor(item.review ? item.review.rating : 0)"
                                                        background-color="#b2b2b2"
                                                        size="18"
                                                        readonly
                                                    />
                                                </div>
                                            </template>
                                            <span>Отзыв клиента</span>
                                        </v-tooltip>
                                    </div> -->
                                </div>
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-inline"
                                :style="width > 1024 ? `width: 210px;flex: 0 0 auto;` : ''"
                                @click.stop.prevent
                            >
                                <StatusSelect
                                    :value="item.status"
                                    :order="item"
                                    :options="orderStatuses.statuses || []"
                                    mode="order"
                                    :disabled="user.read_only ||
                                        (authUserGroup === 'florist' && item.status !== 3) ||
                                        (authUserGroup === 'courier' && item.status !== 4 && item.status !== 5)
                                    "
                                    @select="showCheckEquipmentModal($event, item)"
                                />
                            </div>
                            <div
                                v-if="width <= 1024 || (authUserGroup === 'courier' && width >= 1270)"
                                class="listing-table__col listing-table__col--mobile-inline"
                                :style="width > 1024 ? 'width: 64px; flex: 0 0 auto;' : 'margin-left: auto;'"
                            >
                                <div
                                    v-if="(authUserGroup === 'courier' && item.take_photo_with_receiver) || authUserGroup !== 'courier'"
                                    class="d-flex justify-center"
                                    @click.stop
                                >
                                    <label class="d-flex" style="cursor: pointer;">
                                        <input
                                            v-show="false"
                                            type="file"
                                            accept="image/*"
                                            :disabled="user.read_only || item.status === 6"
                                            @change="setOrderPhoto($event, item)"
                                        >
                                        <Icon color="#0D63F3">camera</Icon>
                                    </label>
                                </div>
                            </div>
                        </router-link>
                        <div v-show="busy && next" class="listing-table__row listing-table__row--preloader">
                            <div class="listing-table__col">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                    <empty-table v-show="orders && !orders.length" />
                </div>
                <ContentPreloader v-show="preloader" type="table" height="96" />
            </div>
        </div>
        <app-create-button
            v-if="!user.read_only"
            v-show="authUserGroup === 'supervisor' || authUserGroup === 'administrators' || authUserGroup === 'managers'"
            class="create__btn"
        >
            <transition name="fade">
                <div v-show="!user.is_tutorial_complete && isVisibleTutorialTooltip" class="tutorial-tooltip__wrap" @click.stop>
                    <div class="tutorial-tooltip__title">Создание заказа</div>
                    <div class="tutorial-tooltip__text">Предлагаю создать тестовый заказ вместе, попробуем? 🙂</div>
                    <div class="tutorial-tooltip__buttons">
                        <router-link to="/orders-tutorial" class="tutorial-tooltip__btn-1">
                            Поехали
                        </router-link>
                        <button type="button" class="tutorial-tooltip__btn-2" @click="completeTutorial">
                            Пропустить
                        </button>
                    </div>
                </div>
            </transition>
        </app-create-button>
        <MiniModal
            v-model="checkEquipmentModal.isShow"
            title="Соответствует ли состав букета действительности?"
            additional-btn-text="Да"
            main-btn-text="Отмена"
            @additionalBtnClick="checkEquipmentModal.isShow = false, changeStatus(4, checkEquipmentModal.order)"
            @mainBtnClick="checkEquipmentModal.isShow = false, checkEquipmentModal.order = {}"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { debounce } from '@/utils/functions';
import { date, numberFormat } from '@/utils/format';

import { setStatusOrder, updateOrderID, updateCourierOrderID } from '@/api/django';

import Spinner from '@/components/app/Spinner';
import MiniModal from "@/components/modal/MiniModal";
import FastFilters from '@/components/app/FastFilters';

export default {
    name: 'TheOrders',
    metaInfo: {
        title: 'Заказы'
    },
    components: { MiniModal, Spinner, FastFilters },
    data() {
        return {
            isVisibleSearch: false,
            checkEquipmentModal: {
                isShow: false,
                order: {}
            },
            isVisibleTutorialTooltip: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            shops: 'geo/shops',
            user: 'auth/user',
            orders: 'orders/orders',
            busy: 'orders/busy',
            next: 'orders/next',
            preloader: 'ui/preloader',
            sort: 'orders/sort',
            filters: 'orders/filters',
            fastFilters: 'orders/fastFilter',
            activeFilters: 'orders/activeFilters',
            franchiseTariff: 'franchises/franchiseTariff',
            orderStatuses: 'orders/statuses'
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        filteredFastFilters() {
            return this.fastFilters.filter((item) => item.group.includes(this.authUserGroup));
        },
        widthPropForTable() {
            return this.width > 1024 && this.authUserGroup === 'courier' ? 386 : 362
        }
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        this.$store.dispatch('shops/GET_SHOPS');
        this.$store.dispatch('orders/GET_ORDER_STATUSES');
        this.$store.dispatch('orders/GET_SHOPS_WITH_ORDER_COUNT');
        this.$store.dispatch('data/GET_SOURCES');
        this.$store.dispatch('paymentType/LOAD_PAYMENT_TYPES');
        if (this.authUserGroup === 'supervisor') {
            this.$store.dispatch('promocodes/LOAD_PROMOCODES');
        }

        if (this.authUserGroup !== 'courier') {
            this.$store.commit('users/CLEAR_FILTERS_STATE');
            this.$store.commit('users/SET_FILTER', { field: 'limit', value: 9999 });
            this.$store.dispatch("users/GET_USERS", { field: 'group', value: 'courier' });
            this.$store.commit('users/SET_FILTER', { field: 'limit', value: 20 });
        }

        await this.$store.dispatch('orders/GET_ALL_ORDERS', { field: 'sort', value: `${this.sort.direction}${this.sort.type}` });
        this.$store.commit('ui/SET_PRELOADER', false);

        setTimeout(() => {
            this.isVisibleTutorialTooltip = true;
        }, 3000)
    },
    methods: {
        async filterOrders(field, value) {
            this.$store.commit('ui/SET_PRELOADER', true);
            await this.$store.dispatch('orders/GET_ALL_ORDERS', { field, value });
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        getFilteredOrders: debounce(function (field, value) {
            this.filterOrders(field, value);
        }, 1000),
        async toogleFastFilter(event) {
            this.$store.commit('ui/SET_PRELOADER', true);
            this.$store.commit('orders/CLEAR_FILTERS_STATE');

            const currentActiveFiltert = this.fastFilters.find((item) => item.active);

            if (currentActiveFiltert && currentActiveFiltert.name === event.name) {
                this.$store.commit('orders/CHANGE_FAST_FILTER');
                await this.$store.dispatch('orders/GET_ALL_ORDERS', { field: 'sort', value: `${this.sort.direction}${this.sort.type}` });
            } else {
                this.$store.commit('orders/CHANGE_FAST_FILTER', { name: event.name, active: true });
                let newDate = new Date();
                const today = date(newDate);
                const tomorrow = date(new Date(newDate.setDate(newDate.getDate() + 1)));

                if (event.name === 'Сегодня') {
                    const string = `delivery_date_after=${today}&delivery_date_before=${today}&pickup=false&status__in=2,3,4,5&sort=${this.sort.direction}${this.sort.type}`;
                    await this.$store.dispatch('orders/SET_FAST_FILTER', string);
                } else if (event.name === 'Завтра') {
                    const string = `delivery_date_after=${tomorrow}&delivery_date_before=${tomorrow}&pickup=false&status__in=2,3,4,5&sort=${this.sort.direction}${this.sort.type}`;
                    await this.$store.dispatch('orders/SET_FAST_FILTER', string);
                } else if (event.name === 'Нет курьера') {
                    const string = `pickup=false&status__in=2,3,4,5&courier__isnull=True&sort=${this.sort.direction}${this.sort.type}`;
                    await this.$store.dispatch('orders/SET_FAST_FILTER', string);
                } else if (event.name === 'Нет магазина') {
                    const string = `shop__isnull=True&status__in=2,3,4,5&sort=${this.sort.direction}${this.sort.type}`;
                    await this.$store.dispatch('orders/SET_FAST_FILTER', string);
                } else if (event.name === 'Нет фото флориста') {
                    const string = `florist_photo__isnull=True&status__in=2,3,4,5&sort=${this.sort.direction}${this.sort.type}`;
                    await this.$store.dispatch('orders/SET_FAST_FILTER', string);
                }
            }
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        async changeTableSort({ type }) {
            if (this.sort.type === type) {
                this.$store.commit('orders/CHANGE_SORT', { type, direction: this.sort.direction === '' ? '-' : '' });
            }
            this.$store.commit('orders/CHANGE_SORT', { type, direction: this.sort.direction });
            this.$store.commit('ui/SET_PRELOADER', true);
            await this.$store.dispatch('orders/GET_ALL_ORDERS', { field: 'sort', value: `${this.sort.direction}${this.sort.type}` });
            window.scrollTo(0, 0);
            this.$store.commit('ui/SET_PRELOADER', false);
        },
        loadMore() {
            this.$store.dispatch('orders/GET_NEXT_PAGE');
        },
        showCheckEquipmentModal(event, order) {
            if (order.status === 3 && event === 4) {
                this.checkEquipmentModal.isShow = true;
                this.checkEquipmentModal.order = order;
            } else {
                this.changeStatus(event, order);
            }
        },
        async changeStatus(statusID, order) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                const res = await setStatusOrder(statusID, order.visible_id);
                if (res.status === 'ok') {
                    order.status = statusID;
                    if (statusID >= 2) order.is_paid = true;
                }
            } catch (error) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка смены статуса', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
                this.checkEquipmentModal.order = {} // на всякий случай очищаем order для модалки, чтобы не обновить статус не того заказа
            }
        },
        formatNumber(number) {
            return number === 0 ? 0 : numberFormat(number);
        },
        formatDate(value, type) {
            return date(new Date(value), type);
        },
        async setOrderPhoto(event, item) {
            try {
                if (!event.target.files.length) {
                    return;
                }

                this.$store.commit('ui/SET_PRELOADER', true);
                const file = event.target.files[0];
                const image = new FormData();

                if (this.authUserGroup !== 'courier') {
                    image.append("florist_photo", file);
                } else {
                    image.append("photo_with_receiver", file);
                }
                event.target.value = "";

                if (this.authUserGroup === "courier") {
                    await updateCourierOrderID(item.visible_id, image, {
                        "Content-Type": "multipart/form-data"
                    });
                } else {
                    await updateOrderID(item.visible_id, image, {
                        "Content-Type": "multipart/form-data"
                    });
                }
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Фото успешно добавлено', type: 'success' })
            } catch (error) {
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки фото', type: 'error' })
            }
        },
        completeTutorial() {
            this.isVisibleTutorialTooltip = false;
            this.$store.dispatch('auth/PATCH_PROFILE_DATA', { is_tutorial_complete: true });
        },
        // setStarColor(mark) {
        //     if (mark) {
        //         if (mark == 4) {
        //             return '#FFC200';
        //         } else if (mark == 5) {
        //             return '#36B687';
        //         } else {
        //             return '#EE6A6E';
        //         }
        //     } else {
        //         return '#b2b2b2';
        //     }
        // },
        moment
    }
};
</script>

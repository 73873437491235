/* eslint-disable */
import router from '@/router';
import moment from 'moment';
import {
    getAllUsers,
    getFilterMinMaxValues,
    getClientsIDs
} from '@/api/django';

const state = {
    users: [],
    filters: {
        limit: 20,
        offset: 0,
        group: null,
        search: '',
        is_staff: 'False',
        ordering: '-last_order',
        sex: null,
        sex__isnull: '',
        num_of_purchases__gte: null,
        num_of_purchases__lte: null,
        summ_amount_of_purchases__gte: null,
        summ_amount_of_purchases__lte: null,
        avge_check__gte: null,
        avge_check__lte: null,
        number_of_bonus__gte: null,
        number_of_bonus__lte: null,
        last_order_date__in: null,
        is_referral_participant: null,
        is_referer: null
    },
    activeFilters: [],
    next_page: false,
    busy: false,
    usersCount: 0,
    sort: {
        type: 'last_order',
        direction: '-'
    },
    filterMinMaxValues: {},
    clientsForNotification: [],
    clientsIDs: []
};

const mutations = {
    SET_USERS(state, users) {
        state.users = users.results;
        state.next_page = !!users.next;
        state.filters.offset = 20;
        state.usersCount = users.count;
    },
    SET_NEXT_USERS(state, { results, next }) {
        state.users.push(...results);
        state.next_page = next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    CLEAR(state) {
        state.users = [];
        state.next_page = false;
        state.busy = false;
        state.filters.offset = 0;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            group: null,
            search: '',
            is_staff: 'False',
            sex: null,
            sex__isnull: '',
            num_of_purchases__gte: null,
            num_of_purchases__lte: null,
            summ_amount_of_purchases__gte: null,
            summ_amount_of_purchases__lte: null,
            avge_check__gte: null,
            avge_check__lte: null,
            number_of_bonus__gte: null,
            number_of_bonus__lte: null,
            last_order_date__in: null,
            is_referral_participant: null,
            is_referer: null
        };
        state.next_page = false;
    },
    SET_CLIENTS_FOR_NOTIFICATION(state, list) {
        state.clientsForNotification = list;
    },
    CHANGE_SORT(state, sort) {
        state.sort.type = sort.type;
        state.sort.direction = sort.direction;
        state.filters.ordering = `${sort.direction}${sort.type}`;
        let sortObj = JSON.parse(localStorage.getItem('sort'));
        sortObj.clients = sort;
        localStorage.setItem('sort', JSON.stringify(sortObj));
    },
    SET_FILTER_MIN_MAX_VALUES(state, data) {
        state.filterMinMaxValues = data;
    },
    SET_CLIENTS_IDS(state, value) {
        state.clientsIDs = value;
    },
};

const actions = {
    async GET_USERS({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;
            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;
            let filters = await dispatch('prepareFilters');
            commit('SET_USERS', await getAllUsers(filters));

            if (router.app._route.path === '/mailings/create') {
                const ids = await getClientsIDs(filters);
                commit('SET_CLIENTS_IDS', ids);
            }

            state.busy = false;
        } catch (error) {
            console.log(error);
        }
    },
    async GET_NEXT_PAGE({ state, commit, dispatch }) {
        try {
            if (state.next_page && !state.busy) {
                state.busy = true;
                let filters = await dispatch('prepareFilters');
                commit('SET_NEXT_USERS', await getAllUsers(filters));
            }
        } catch (error) {
            console.log(error);
        }
    },
    async GET_FILTER_MIN_MAX_VALUES({commit}) {
        try {
            const values = await getFilterMinMaxValues();
            commit('SET_FILTER_MIN_MAX_VALUES', values);
        } catch (error) {
            console.log(error);
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([key, value]) => {
            if (key && (value || value === false || value === 0)) {
                filters[key] = value;
            }
        })
        let activeFilters = Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    },
    getUniqueReasons({ rootGetters }, { primalArray, date, newReason }) {
        primalArray.forEach((item, index) => {
            if (moment(item.date).format('DD.MM') === moment(date).format('DD.MM')) {
                primalArray.splice(index, 1, {
                    ...newReason, date, site: +rootGetters['franchises/franchiseTariff'].site_id
                });
            }
        })

        let array = []
        primalArray.forEach((item) => {
            if (!array.find(i => i.date === item.date)) {
                array.push(item)
            }
        })
        return array;
    }
};

const getters = {
    users: state => state.users,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    next: state => !!state.next_page,
    busy: state => state.busy,
    sort: state => state.sort,
    filterMinMaxValues: state => state.filterMinMaxValues,
    clientsForNotification: state => state.clientsForNotification,
    usersCount: state => state.usersCount,
    clientsIDs: state => state.clientsIDs
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

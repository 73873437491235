<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Премии и штрафы"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список премий и штрафов</h2>

                                <p>В списке отображаются поля:</p>
                                <ul>
                                    <li>Тип записи (премия или штраф)</li>
                                    <li>Название</li>
                                    <li>Дата создания</li>
                                    <li>Сумма</li>
                                    <li>Статус ("Частично учтено" означает, что не для всех пользователей выплачена премия/вычтен штраф)</li>
                                </ul>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание премии/штрафа</h2>
                                <p>Для создания премии или штрафа нужно выбрать тип, заполнить название, дописать описание и сумму.</p>
                                <p>С помощью кнопки "Изменить" нужно добавить пользователей.</p>
                                <img src="@/assets/img/help/salarys-changes/1.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Просмотр и редактирование</h2>
                                <p>Список пользователей редактировать нельзя. Удалить запись нельзя. Но остальные поля доступны для редактирования.</p>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'Возвраты', link: '/help/returns'}, {name: 'Зарплаты', link: '/help/salaries'}]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpSalarysChanges',
    metaInfo: {
        title: 'Премии и штрафы - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

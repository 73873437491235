/* eslint-disable */

import { getInventories } from '@/api/stock';

const state = {
    inventories: {},
    filters: {
        limit: 20,
        offset: 0,
        shop: null,
        status: null,
        date_from: null,
        date_to: null
    },
    next: false,
    busy: false,
    activeFilters: [],
};

const mutations = {
    SET_INVENTORIES(state, inventories) {
        state.inventories = inventories.results;
        state.next = inventories.next;
        state.filters.offset = 20;
    },
    SET_NEXT_INVENTORIES(state, { results, next }) {
        state.inventories.push(...results);
        state.next = next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            shop: null,
            status: null,
            date_from: null,
            date_to: null
        };
        state.next = false;
    },
};

const actions = {
    async GET_INVENTORIES ({ state, commit, dispatch }, filter = null) {
        state.busy = true;
        if (filter) {
            commit('SET_FILTER', filter);
        }
        state.filters.offset = 0;
        let filters = await dispatch('prepareFilters');

        commit('SET_INVENTORIES', await getInventories(filters));
        state.busy = false;
    },
    async GET_NEXT_PAGE({ state, commit, dispatch, getters }) {
        if (getters['next'] && !state.busy) {
            state.busy = true;
            let filters = await dispatch('prepareFilters');
            try {
                const response = await getInventories(filters);
                commit('SET_NEXT_INVENTORIES', response);
            } catch (error) {
                console.log(error);
            }
        }
    },
    async CLEAR_FILTERS({ commit, dispatch }) {
        commit('CLEAR_FILTERS_STATE');
        await dispatch('GET_INVENTORIES');
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([ key, value ]) => {
            if (key && (value || value === false || value === 0) ) { 
                filters[key] = value;
            }
        })
        let activeFilters =  Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    inventories: state => state.inventories,
    next: state => !!state.next,
    busy: state => state.busy,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

<template>
    <div class="side-filter__content">
        <div class="side-filter__content-group-list">
            <div class="side-filter__content-group">
                <v-menu ref="date" offset-y :close-on-content-click="false" transition="scale-transition" max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                        <InputCustom
                            :value="datepickerFormatted"
                            label="Дата"
                            placeholder="Выберите период"
                            left-icon="calendar"
                            clearable
                            readonly
                            without-preloader
                            v-on="on"
                            @click:clear="datepicker = [], setFilterDate()"
                        />
                    </template>
                    <v-date-picker
                        v-model="datepicker"
                        no-title
                        range
                        first-day-of-week="1"
                        @input="() => {
                            if (datepicker[0] && datepicker[1]) {
                                $refs.date.save(datepicker);
                                setFilterDate();
                            }
                        }"
                    />
                </v-menu>
            </div>
            <div class="side-filter__content-group">
                <InputSelect
                    :value="filters.rating"
                    :options="ratingOptions"
                    label="Оценка"
                    input-value="value"
                    icon="star"
                    without-preloader
                    @input="filterReviews('rating', $event)"
                />
            </div>

            <div class="side-filter__content-group">
                <InputSelect
                    :value="filters.florist"
                    :options="[{ id: null, first_name: 'Не задан' } ,...florists]"
                    label="Флорист"
                    input-text="first_name"
                    icon="users"
                    searchable
                    without-preloader
                    @input="filterReviews('florist', $event)"
                />
            </div>

            <div class="side-filter__content-group">
                <Button
                    block compact text
                    icon-left="file-minus"
                    @click.prevent="isShowDownloadModal = true"
                >
                    Скачать отзывы в xls
                </Button>
            </div>
        </div>

        <MiniModal
            v-model="isShowDownloadModal"
            title="Вы уверены, что хотите скачать файл?"
            additional-btn-text="Отмена"
            main-btn-text="Скачать"
            @additionalBtnClick="isShowDownloadModal = false"
            @mainBtnClick="downloadFile"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import moment from 'moment'

import { downloadReviews, getUserByGroup } from "@/api/django";

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'ReviewsFilterOld',
    components: { MiniModal },
    data() {
        return {
            datepicker: [],
            ratingOptions: [
                { name: 'Все оценки', value: null },
                { name: '1', value: '1' },
                { name: '2', value: '2' },
                { name: '3', value: '3' },
                { name: '4', value: '4' },
                { name: '5', value: '5' },
            ],
            florists: [],
            isShowDownloadModal: false
        }
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            filters: 'reviews/filters',
            activeFilters: 'reviews/activeFilters',
        }),
        datepickerFormatted() {
            if (this.datepicker.length > 1) {
                return this.datepicker.map(i => this.moment(i).format('DD.MM.YYYY')).join(' - ')
            }
            return ''
        }
    },
    mounted() {
        if (this.filters.date_from && this.filters.date_to) {
            this.datepicker = [
                this.filters.date_from.split('.').reverse().join('-'),
                this.filters.date_to.split('.').reverse().join('-')
            ]
        }

        // При переработке прелоадеров и фильтров нужно вынести запрос отсюда. Сейчас он вызывается при каждом открытии фильтра, что плохо
        getUserByGroup('florist', 'all')
            .then(res => {
                this.florists = res.results;
            })
    },
    methods: {
        async filterReviews(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('reviews/GET_REVIEWS', { field, value });
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async setFilterDate() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.datepicker.sort();
                let dateFrom = this.datepicker[0] ? this.moment(this.datepicker[0]).format('DD.MM.YYYY') : null
                let dateTo = this.datepicker[1] ? this.moment(this.datepicker[1]).format('DD.MM.YYYY') : null

                this.$store.commit('reviews/SET_FILTER', { field: 'date_from', value: dateFrom })
                this.$store.commit('reviews/SET_FILTER', { field: 'date_to', value: dateTo })
                await this.$store.dispatch('reviews/GET_REVIEWS');
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async downloadFile() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.isShowDownloadModal = false;

                const filters = await this.$store.dispatch('reviews/prepareFilters');
                for (let key in filters) {
                    if (!this.activeFilters.includes(key)) {
                        delete filters[key]
                    }
                }

                const file = await downloadReviews(filters);
                const fileUrl = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                link.download = 'Отзывы.xlsx';
                link.click();
            } catch (e) {
                console.log(e)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки отзывов', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        moment
    }
}
</script>

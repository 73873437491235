<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Стандарты сервиса"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 id="standards" class="page__info-group-title">Стандарты сервиса</h2>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/1.png" alt="">
                                    Сервис
                                </h3>
                                <p>это отношения, выстраиваемые компанией, в процессе работы с клиентами. Качество продукта обеспечивает привлечение клиента, качество сервиса (эмоциональная связь) обеспечивает его удержание. Хороший сервис поможет выделиться среди конкурентов и сформировать у покупателей лояльность и желание приходить к вам снова. Как сделать клиента своим постоянным покупателем, уладить возможный конфликт и зачем нужна обратная связь? Как же работает сервис, в который хочется вернуться?</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/2.png" alt="">
                                    Не оценивать покупателей по внешнему виду.
                                </h3>
                                <p>Оценивать покупателей на основании их внешнего вида ― дурной тон. Продавцу следует быть одинаково вежливым и внимательным со всеми людьми независимо от их внешности, возраста и социального статуса. Нельзя игнорировать вопросы покупателей, позволять себе фамильярность, грубость и скучающее выражение лица во время разговора с посетителями.</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/3.png" alt="">
                                    Не заставлять покупателей ждать.
                                </h3>
                                <p>Ждать никто не любит. Если покупатель нуждается в помощи, её нужно оказать. Если продавец не может сразу обслужить покупателя, следует извиниться и предложить немного подождать. Подобные ситуации должны быть редкостью, а не правилом, иначе покупатели выберут магазин с более расторопным персоналом.</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/4.png" alt="">
                                    Относиться ко всем покупателям искренне и доброжелательно.
                                </h3>
                                <p>Вымученную улыбку видно сразу. Покупатели ценят искренность и хорошее отношение. Продавцу следует об этом помнить и оставлять плохое настроение за пределами торговой точки.</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/5.png" alt="">
                                    Не заниматься своими делами.
                                </h3>
                                <p>При нахождении в торговом зале, флористу нельзя принимать пищу, разговаривать по телефону по личным вопросам, сидеть в соцсетях или читать книги. Если покупатель зайдёт и увидит, что продавцу сейчас не до него, он может передумать совершать здесь покупку.</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/6.png" alt="">
                                    В любой ситуации оставаться спокойным и вежливым.
                                </h3>
                                <p>Клиенты бывают разными. Некоторые приходят в магазин в плохом настроении с желанием выместить на ком-то свои эмоции. Любому сотруднику нужно в любом случае оставаться спокойным и вежливым. Нельзя вступать в конфликты, начинать кричать и спорить с посетителями.</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/7.png" alt="">
                                    Говорить правду.
                                </h3>
                                <p>Покупатели нередко спрашивают о свежести цветов, десертов и сроках поставки. Честность сотрудника всегда располагает к себе, и, даже если в моменте покупка будет некрупной, впоследствии довольный клиент может стать постоянным покупателем.</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/8.png" alt="">
                                    Ничего не навязывать.
                                </h3>
                                <p>Больше всего покупатели не любят, когда им что-то навязывают. Если продавец видит, что покупатель в замешательстве, он аккуратно с помощью открытых вопросов выясняет потребности клиента и предлагает товары, исходя из запроса. Любое навязывании клиент чувствует.</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/9.png" alt="">
                                    Продажа
                                </h3>
                                <p>это процесс выяснения и удовлетворения потребностей клиента. Любой процесс продажи строится на алгоритме работы с покупателем, который является универсальным инструментом для этого.</p>
                                <p>Алгоритм профессиональной коммуникации с клиентом состоит из следующих этапов:</p>
                                <ol>
                                    <li>подготовка и мерчандайзинг;</li>
                                    <li>приветствие и установление контакта;</li>
                                    <li>выявление потребностей;</li>
                                    <li>презентация;</li>
                                    <li>работа с возражениями;</li>
                                    <li>расширение покупки;</li>
                                    <li>завершение сделки.</li>
                                </ol>
                                <p>Каждый из этапов алгоритма играет определенную роль и без него нельзя полноценно продать продукт.</p>
                                <ul>
                                    <li>Без правильного установления контакта нельзя рассчитывать на интерес и доверие со стороны покупателя.</li>
                                    <li>Без выявления потребностей вы не поймете, что влияет на решение о покупке и не сможете использовать это для продажи.</li>
                                    <li>Без презентации вы не убедите клиента в том, что ваш товар или услуга необходимы ему сейчас.</li>
                                    <li>И т.д.  … Будьте внимательны и старайтесь добиться цели на каждом этапе вашей работы.</li>
                                </ul>
                            </div>

                            <div class="page__info-group">
                                <h2 id="preparation" class="page__info-group-title">Подготовка и мерчандайзинг</h2>
                                <img src="@/assets/img/help/services-standards/10.svg" class="help__image" alt="" style="margin: 16px auto">
                                <h3>1.1.Подготовка помещения</h3>
                                <p>Клиенту в помещении должно быть приятно и комфортно. И первое на что обращает внимание клиент, заходя в помещение – чистота. Если пол будет грязным, а витрины заляпанными, то и сами цветы будут казаться менее красивыми. Необходимо следить за чистотой комплексно и поддерживать помещение в опрятном виде.</p>
                                <div class="help__services-standard-list">
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/11.png" alt="">
                                        <div class="help__services-standard-list-item-title">Чистый пол</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/12.png" alt="">
                                        <div class="help__services-standard-list-item-title">Чистая витрина</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/13.png" alt="">
                                        <div class="help__services-standard-list-item-title">Отсутствие пыли</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/14.png" alt="">
                                        <div class="help__services-standard-list-item-title">Опрятное рабочее место</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/15.png" alt="">
                                        <div class="help__services-standard-list-item-title">Отсутствие мусора</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/16.png" alt="">
                                        <div class="help__services-standard-list-item-title">Неприятный запах</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/17.png" alt="">
                                        <div class="help__services-standard-list-item-title">Комфортная температура</div>
                                    </div>
                                </div>

                                <p><b>Чек-лист чистоты торгового зала:</b></p>
                                <ul>
                                    <li>Чистое крыльцо/отсутствие мусора</li>
                                    <li>Чистый пол</li>
                                    <li>Опрятное рабочее место флориста/бариста</li>
                                    <li>Отсутствие пыли</li>
                                    <li>Чистые витрины</li>
                                    <li>Комфортная температура помещения</li>
                                    <li>Отсутствие неприятных запахов</li>
                                </ul>

                                <img src="@/assets/img/help/services-standards/18.svg" class="help__image" alt="" style="margin: 16px auto">
                                <h3>1.2.Подготовка витрины</h3>
                                <p>Весь товар должен хорошо просматриваться. Особое внимание стоит уделить витрине-холодильнику: на него покупатели сразу обращают внимание (после чистоты). Подумайте, как расставить цветы и готовые букеты так, чтобы это было красиво. На витрине размещайте букеты разных размеров и ценовых категорий, чтобы каждый сразу смог подобрать что-то на свой вкус и кошелек. Рядом с каждым букетом обязательно наличие ценника.Также и с десертами, витрина должна выглядеть вкусно и аппетитно.</p>

                                <img src="@/assets/img/help/services-standards/19.svg" class="help__image" alt="" style="margin: 16px auto">
                                <h3>1.3.Подготовка себя</h3>
                                <p>Этот пункт включает в себя внешний вид и внутренний настрой. Внешний вид подразумевает – наличие определенной формы, бейдж с именем, ухоженный и опрятный вид, чистые волосы, ногти, опрятная обувь и одежда. Также при подготовке себя важен внутренний настрой. Настоящий профессионал умеет оставлять плохое настроение, личные проблемы за пределами торговой точки.</p>
                            </div>

                            <div class="page__info-group">
                                <h2 id="greeting" class="page__info-group-title">Приветствие. Установление контакта.</h2>
                                <p><b>Установление доверительного контакта. Каналы влияния:</b></p>
                                <ol>
                                    <li>Вербальный – смысл слов, которые мы произносим.</li>
                                    <li>Невербальный – мимика, жесты, дистанция, взгляд, внешний вид, поза.</li>
                                    <li>Паралингвистический – интонация, темп речи, громкость, наличие пауз.</li>
                                </ol>

                                <div class="help__services-standard-list">
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/20.png" alt="">
                                        <div class="help__services-standard-list-item-title">Вербальный</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/21.png" alt="">
                                        <div class="help__services-standard-list-item-title">Невербальный</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/22.png" alt="">
                                        <div class="help__services-standard-list-item-title">Паралингви-<br>стический</div>
                                    </div>
                                </div>

                                <div class="help__services-standard-list">
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/23.png" alt="">
                                        <div class="help__services-standard-list-item-title">Смотрите за входом</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/24.png" alt="">
                                        <div class="help__services-standard-list-item-title">Зрительный контакт с посетителем</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/25.png" alt="">
                                        <div class="help__services-standard-list-item-title">Дать осмотреть витрину</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/26.png" alt="">
                                        <div class="help__services-standard-list-item-title">Наблюдать за поведением</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/27.png" alt="">
                                        <div class="help__services-standard-list-item-title">Задать вопрос</div>
                                    </div>
                                </div>

                                <ul>
                                    <li>Всегда находитесь в зоне, где просматривается вход.</li>
                                    <li>При появлении клиента необходимо установить зрительный контакт, улыбнуться, поздороваться (даже если вы работаете с другим покупателем).</li>
                                    <li>Дать клиенту возможность осмотреть витрины с продукций (осторожно наблюдайте за поведение клиента для того, чтобы сделать предположения о его мотивах); если в течение 2-3 минут клиент не задает вопрос, первым начать разговор.</li>
                                </ul>

                                <p>Разговор всегда необходимо начинать с открытых вопросов. Они, предполагают развернутый ответ.</p>

                                <h3>Возможные варианты</h3>
                                <p><b>Поведенческие особенности:</b></p>
                                <ul>
                                    <li>Клиенту интересно все, что находится в торговой точке</li>
                                    <li>Ходит по залу, не останавливается на чем-то конкретно</li>
                                    <li>Не спешит</li>
                                    <li>Выражение лица клиента может напоминать посетителя музея или выставки</li>
                                    <li>Клиенту интересна отдельная группа товара</li>
                                    <li>Взгляд посетителя направлен только на один из стеллажей</li>
                                    <li>Рассматривает товар как бы издалека, может переводить взгляды с одного варианта на другой</li>
                                    <li>Он сосредоточен и серьезен, мысленно рассматривая имеющиеся варианты возможной покупки</li>
                                </ul>
                                <p><b>Поведение консультанта:</b></p>
                                <p><b>Задать классификацию</b> (сориентировать по залу) + открытый вопрос/ «здесь у нас представлены … (назвать группы
                                    товара), а дальше … (назвать другую группу товаров). что интересно в первую очередь?»</p>

                                <p><b>Презентуем отдельную группу товаров</b> (важно обратить внимание, на что смотрит покупатель) + вопрос на уточнение
                                    «Здесь у нас представлены букеты из сухоцветов, есть такие…, есть… отличаются… Что вам понравилось больше?»</p>

                                <p><b>Поведенческие особенности:</b></p>
                                <ul>
                                    <li>Клиент, заинтересовавшийся конкретным товаром</li>
                                    <li>Занимает активную позиции по исследованию данного товара и детально изучает, оценивающе рассматривает.</li>
                                </ul>

                                <p><b>Поведение консультанта:</b></p>
                                <p>Описываем именно этот товар + открытый вопрос «Этот товар отличает … (называем преимущества товара, при этом информация должна быть сжатой). Что Вы думаете по поводу предложенного варианта?»</p>
                                <p>«Этот меренговый рулет очень нежный, имеет воздушную текстуру и раскрывается яркой кислинкой маракуйи в конце, что думаете по поводу такого вкусового сочетания?»</p>
                                <p><b>Примечание</b></p>
                                <p>Еще один очень хороший прием, позволяющий быстро установить контакт с клиентом – это представиться ему. Сказав свое имя, вы располагаете к себе другого человека.</p>
                                <p>Например: «Добрый день! Меня зовут …, для кого хотели бы выбрать букет?» «Здравствуйте! Меня зовут…, как я могу к Вам обращаться? Какие цветы предпочитаете?</p>
                            </div>
                            <div class="page__info-group">
                                <h2 id="requirement" class="page__info-group-title">Выявление потребностей</h2>
                                <p><b>Потребность покупателя</b> - это зачастую не сам товар, который он выбирает, а то, ради чего этот товар приобретается.</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/28.png" alt="">
                                    1.Экономия времени.
                                </h3>
                                <p>Для такого человека важно достичь как можно больших результатов за короткий промежуток времени.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/29.png" alt="">
                                    2.Красота
                                </h3>
                                <p>Существует определенный тип покупателей, ориентированных в основном на внешний вид товара, на его привлекательность и эстетичность.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/30.png" alt="">
                                    3.Комфорт.
                                </h3>
                                <p>Для такого клиента важны его собственные ощущения удобства. За них он готов заплатить достаточно высокую цену.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/31.png" alt="">
                                    4.Практичность.
                                </h3>
                                <p>Это стремление приобретать надежный, качественный и долговечный товар. Для таких клиентов важным становится аргумент о соотношении цены и качества.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/32.png" alt="">
                                    5.Здоровье.
                                </h3>
                                <p>Это мощный стимул приобретения полезных товаров и услуг, которые содержат оздоровительный эффект.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/33.png" alt="">
                                    6.Хорошее отношение.
                                </h3>
                                <p>Иногда покупки совершаются ради того, чтобы сделать приятное для близкого человека, а также, чтобы наладить или поддержать хорошие отношения с продавцом.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/34.png" alt="">
                                    7.Самобытность
                                </h3>
                                <p>Стремление быть не таким как все, подчеркнуть свою индивидуальность, неповторимость.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/35.png" alt="">
                                    8.Престиж
                                </h3>
                                <p>Для таких клиентов важен собственный статус, они стараются никогда на нем не экономить.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/35.png" alt="">
                                    9.Новизна
                                </h3>
                                <p>Для таких клиентов важно идти в ногу со временем и быть немного впереди. Они постоянно следят за модой, внимательно знакомятся с новинками по каталогам.</p>

                                <h3 style="text-transform: uppercase">Виды вопросов и способы их формирования.</h3>
                                <img src="@/assets/img/help/services-standards/37.png" class="help__image" alt="" style="margin: 16px auto">

                                <div class="help__services-standard__table">
                                    <div class="help__services-standard__table-head">
                                        <div class="help__services-standard__table-col">Закрытые вопросы</div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                        <div class="help__services-standard__table-col" style="max-width: 100%">
                                            <b>Определения</b>
                                            <p>Вопросы, предполагающие однозначный ответ «да» или «нет» для того, чтобы ускорить принятие решения. Применять дозировано.</p>

                                            <b class="d-flex mt-6">Как это сделать?</b>
                                            <p>«Вам понравился этот вариант?»</p>
                                        </div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                    </div>
                                </div>

                                <img src="@/assets/img/help/services-standards/38.png" class="help__image" alt="" style="margin: 16px auto">
                                <div class="help__services-standard__table">
                                    <div class="help__services-standard__table-head">
                                        <div class="help__services-standard__table-col">Альтернативные вопросы</div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                        <div class="help__services-standard__table-col" style="max-width: 100%">
                                            <b>Определения</b>
                                            <p>Вопросы, в формулировке которых содержатся варианты ответов</p>

                                            <b class="d-flex mt-6">Как это сделать?</b>
                                            <p>«Какой букет хотите получить: яркий или нежный?»<br>
                                                «Вы любите более сладкие десерты или с кислинкой?»</p>
                                        </div>
                                    </div>
                                </div>

                                <img src="@/assets/img/help/services-standards/39.png" class="help__image" alt="" style="margin: 16px auto">
                                <div class="help__services-standard__table">
                                    <div class="help__services-standard__table-head">
                                        <div class="help__services-standard__table-col">Открытые вопросы</div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                        <div class="help__services-standard__table-col" style="max-width: 100%">
                                            <b>Определения</b>
                                            <p>Вопросы, предполагающие развернутый ответ. Дают возможность: начать беседу, выяснить потребность, побудить людей к размышлению и анализу, продемонстрировать интерес к только что сказанному.</p>

                                            <b class="d-flex mt-6">Как это сделать?</b>
                                            <p>Начинайте вопрос со слов: «Где? Куда? Сколько? Что? Как? Почему? Каким образом? При каких условиях? и т. п.»<br>
                                                «Какие любите цветы?»<br>
                                                «Какие пожелания по упаковке?»<br>
                                                «Какой кофе предпочитаете?»</p>
                                        </div>
                                    </div>
                                </div>

                                <h3 style="text-transform: uppercase">Активное и пассивное слушание.</h3>
                                <div class="help__services-standard__table">
                                    <div class="help__services-standard__table-head">
                                        <div class="help__services-standard__table-col d-flex flex-column align-center">
                                            <img src="@/assets/img/help/services-standards/40.png" alt="">
                                            <span style="color: #6E6CAD;text-align: center;">Активное слушание</span>
                                        </div>
                                        <div class="help__services-standard__table-col d-flex flex-column align-center">
                                            <img src="@/assets/img/help/services-standards/41.png" alt="">
                                            <span style="color: #6E6CAD;text-align: center;">Пассивное слушание</span>
                                        </div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                        <div class="help__services-standard__table-col">
                                            <p>Попытки побудить партнера к разговору</p>
                                        </div>
                                        <div class="help__services-standard__table-col">
                                            <p>Терпеливое ожидание того, чтобы партнер заговорил.</p>
                                        </div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                        <div class="help__services-standard__table-col">
                                            <p>Попытки точно воспринять сказанное партнером. Попытки убедиться в точности своего восприятия.</p>
                                        </div>
                                        <div class="help__services-standard__table-col">
                                            <p>Ожидание того, когда партнер окажется в состоянии сказать нечто действительно важное или интересное.</p>
                                        </div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                        <div class="help__services-standard__table-col">
                                            <p>Попытки удерживать излишне многословного или отвлекающегося партнера в рамках темы; попытки вернуть его  к обсуждаемой теме.</p>
                                        </div>
                                        <div class="help__services-standard__table-col">
                                            <p>Отвлечение внимания на что-либо другое при сохранении «маски внимания». Ожидание того, когда партнер вернется к теме разговора. Ожидание того, когда партнер перестанет говорить.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="page__info-group">
                                <h2 id="presentation" class="page__info-group-title">Презентация</h2>
                                <p>В продаже все взаимосвязано: установили контакт с клиентом – через вопросы выявили его потребности – далее презентуем свое предложение как средство удовлетворении его потребностей</p>
                                <p>Презентация должна быть краткой и содержать в себе только ключевые моменты, основанные на потребностях клиента.</p>

                                <h3 style="text-transform: uppercase">Приемы эффективной презентации.</h3>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/42.png" alt="">
                                    Эмоциональность
                                </h3>
                                <p>Эмоциональная интонация позволяет обращаться непосредственно к потребностям клиента.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/43.png" alt="">
                                    Жесты
                                </h3>
                                <p>Жесты убедительно воздействуют на клиента, если усиливают смысл высказывания.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/44.png" alt="">
                                    Цифры
                                </h3>
                                <p>Использование цифр повышает надежность и обоснованность высказываний продавца.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/45.png" alt="">
                                    Действия
                                </h3>
                                <p>Включение в действие позволяет покупателю самому убедиться в преимуществах товара.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/46.png" alt="">
                                    Позицию помощника, консультанта
                                </h3>
                                <p>Высказывание будет убедительнее воздействовать на клиента, если продавец занимает позицию помощника, консультанта.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/47.png" alt="">
                                    Термины
                                </h3>
                                <p>Уровень использования профессиональных терминов должен соответствовать уровню компетентности покупателя в данной области.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/48.png" alt="">
                                    Наглядность
                                </h3>
                                <p>Наглядность позволяет получить целостную информацию о товаре за короткий промежуток времени.</p>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/49.png" alt="">
                                    Комплимент
                                </h3>
                                <p>Комплимент создает позитивную атмосферу для ситуации заключения сделки.</p>
                            </div>
                            <div class="page__info-group">
                                <h2 id="work-with-opposition" class="page__info-group-title">Работа с возражениями</h2>
                                <p>Возражением называется форма, в которой клиент выражает своё несогласие с необходимостью ему данного товара, качеством, ценой и/или другими характеристиками. Задача продавца — внимательно выслушать все озвученные возражения и убедить покупателя в их несостоятельности.</p>
                                <p>Возражения бывают явные и скрытые, истинные и ложные.</p>

                                <div class="help__services-standard-list">
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/50.png" alt="">
                                        <div class="help__services-standard-list-item-title">Явное</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/51.png" alt="">
                                        <div class="help__services-standard-list-item-title">Скрытое</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/52.png" alt="">
                                        <div class="help__services-standard-list-item-title">Истинное</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/53.png" alt="">
                                        <div class="help__services-standard-list-item-title">Ложное</div>
                                    </div>
                                </div>

                                <h3 style="text-transform: uppercase">Возражения бывают явные и скрытые, истинные и ложные.</h3>
                                <p><b>Явное</b></p>
                                <p>когда покупатель открыто говорит Вам, что ему не нравится. Например, «дорого» или «мне не нравится цвет.</p>

                                <p class="mt-8"><b>Скрытое возражение</b></p>
                                <p>когда покупатель не говорит причину, например, «я подумаю».</p>

                                <p class="mt-8"><b>Истинное возражение</b></p>
                                <p>когда покупатель честно говорит Вам, что думает.».</p>

                                <p class="mt-8"><b>Ложное возражение</b></p>
                                <p>это, когда настоящая причина сомнения покупателя скрыта от вас. Важно понимать, что за каждым ложным возражением стоит или истинное возражение, или какой-либо фактор, мешающий закрытию сделки.</p>

                                <h3 style="text-transform: uppercase">Правила работы с сомнениями и возражениями клиента.</h3>
                                <p class="mt-8"><b>Правило 1</b></p>
                                <p>Сомнения клиента необходимо внимательно выслушать.</p>

                                <p class="mt-8"><b>Правило 2</b></p>
                                <p>При ответе на возражение клиента не стоит нервничать и раздражаться. Если клиент начинает сомневаться, значит, его заинтересованность в товаре увеличивается.</p>

                                <p class="mt-8"><b>Правило 3</b></p>
                                <p>Если ваши аргументы не «производят» впечатление на клиента, скорее всего вы отвечаете не на тот «скрытый» вопрос. В этом случае следует прояснить, в чем конкретно заключается опасения покупателя.</p>

                                <div class="help__services-standard-list">
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/54.png" alt="">
                                        <div class="help__services-standard-list-item-title">Внимательно слушать</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/55.png" alt="">
                                        <div class="help__services-standard-list-item-title">Не нервничать при ответе</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/56.png" alt="">
                                        <div class="help__services-standard-list-item-title">Прояснять скрытие вопросы</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/57.png" alt="">
                                        <div class="help__services-standard-list-item-title">Не спорить, используй ”согласись и опровергни”</div>
                                    </div>
                                    <div class="help__services-standard-list-item">
                                        <img src="@/assets/img/help/services-standards/58.png" alt="">
                                        <div class="help__services-standard-list-item-title">Получи согласие на свой доводы</div>
                                    </div>
                                </div>

                                <p class="mt-8"><b>Правило 4</b></p>
                                <p>Спорить с клиентом бесполезно и даже более того, небезопасно. При работе с сомнениями покупателя необходимо пользоваться приемом «согласись и опровергни».</p>

                                <p class="mt-8"><b>Правило 5</b></p>
                                <p>Прежде чем переходить к другому аргументу, опровергающему сомнения клиента, необходимо получить его согласие с предыдущим доводом.</p>
                            </div>
                            <div class="page__info-group">
                                <h2 id="expansion" class="page__info-group-title">Расширение покупки.</h2>
                                <p>На этом этапе, как правило, продавец предлагает что-то из сопутствующих товаров. Например: открытку, шарики, свечи, добавить зелень к букету. В кофейне можно предложить десерт. Если в мастерской или кофейне проходят акции, обязательно рассказать о них.</p>
                                <img src="@/assets/img/help/services-standards/63.png" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                            <div class="page__info-group">
                                <h2 id="finish" class="page__info-group-title">Завершение сделки.</h2>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/59.png" alt="">
                                    Если клиент не готов сделать покупку:
                                </h3>
                                <ul>
                                    <li>Подвести итог вашего разговора. Еще раз выделить основные преимущества заинтересовавшей продукции.</li>
                                    <li>Дать клиенту визитку магазина, буклеты с интересовавшейся продукцией.</li>
                                    <li>Сообщить время поступления интересующей продукции, сроки обновления ассортимента или мастерскую/кофейню, в котором есть в наличии интересующий продукт.</li>
                                </ul>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/60.png" alt="">
                                    Если клиент совершил покупку:
                                </h3>
                                <p>Поблагодарите за покупку, пожелайте хорошего дня, предложите посетить мастерскую/кофейню снова.</p>

                                <h3 style="text-transform: uppercase">Обычно конфликтные посетители делятся на два типа:</h3>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/61.png" alt="">
                                    Не зависит от вас
                                </h3>
                                <p>Поблагодарите за покупку, пожелайте хорошего дня, предложите посетить мастерскую/кофейню снова.</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/62.png" alt="">
                                    Не доволен продуктом
                                </h3>
                                <p>Те, кто по каким-то причинам недоволен товаром, обслуживанием и т. п. ― в этом случае продавец обязан постараться решить проблему.</p>

                                <h3 style="text-transform: uppercase">Как вести себя в конфликтной ситуации:</h3>
                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/64.png" alt="">
                                    Шаг 1 – Выслушать
                                </h3>
                                <p>В результате клиент «выпускает пар», а Вы имеете возможность спланировать дальнейшие действия. Очень трудный шаг. Он требует от нас терпения, внимания и даже мужества. Очень хочется сразу оборвать возмущенного человека, особенно если он кричит не по делу. Но если мы так поступим, то есть оборвем его на полуслове, то, как, же мы поймем, чем недоволен этот клиент?</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/65.png" alt="">
                                    Шаг 2 – Уточнить
                                </h3>
                                <p>Итак, после того, как вы клиента выслушали, очень важно показать ему, что вы правильно поняли суть высказанной претензии.</p>
                                <p> Для этого надо просто повторить ее своими словами. Используйте следующие обороты «понимающего слушания»: «Если я вас правильно понял(а), произошла следующая ситуация ….», «То есть вы хотите сказать…» Для чего и кому это нужно? Важно убедиться, что вы клиента правильно поняли, разобрались, в чем именно состоит причина недовольства, иначе конфликтная ситуация не может быть разрешена. Это нужно и клиенту, поскольку показывает ему, что вы всерьез отнеслись к его словам, услышали их.</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/66.png" alt="">
                                    Шаг 3 - Выразить понимание
                                </h3>
                                <p>Покажите клиенту, что вы его поняли, что его проблемы вам не безразличны. Например: «Я хорошо понимаю, что …», «Спасибо, Иван Иванович, что Вы затронули этот вопрос», «Да, это неприятный момент».</p>
                                <p>Крылатая фраза «Клиент всегда прав» может быть переформулирована таким образом: «Клиент не всегда прав, но он всегда имеет право на выражение своих эмоций». И это право надо признать. Мало этого, клиенту обязательно надо посочувствовать.</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/67.png" alt="">
                                    Шаг 4 - Извиниться или выразить сожаление
                                </h3>
                                <p>Приносите извинения в случае обоснованной или спорной претензии. Если претензия не обоснованна, выразите сожаление. Например: «Приношу свои извинения от лица компании за доставленные неудобства». «Мне очень жаль, что произошла такая ситуация»</p>

                                <h3 class="help__subtitle">
                                    <img src="@/assets/img/help/services-standards/68.png" alt="">
                                    Шаг 5 - Предпринять действия
                                </h3>
                                <p>Помните – от Вас ждут решения своей проблемы. Также клиентов нужно держать в курсе событий, о том, как продвигаются дела. Важно не только предпринимать действия, но и показывать клиенту, что вы не сидите, сложа руки, и у вас все под контролем. Бывают случаи, когда исправить ситуацию и что-то предпринять уже не получится. В этих случаях, можно выбрать один из следующих сценариев:</p>
                                <ul>
                                    <li>Предложить клиенту скидку на следующий заказ</li>
                                    <li>Подарить промокод для заказа на сайте</li>
                                    <li>Угостить кофе</li>
                                    <li>Положить к заказу небольшой подарок и др.</li>
                                </ul>
                                <p>Клиент станет доброжелательным, преданным!</p>

                                <h3 style="text-transform: uppercase">Примеры обращения в конфликтных ситуациях.</h3>
                                <div class="help__services-standard__table">
                                    <div class="help__services-standard__table-head">
                                        <div class="help__services-standard__table-col">
                                            <b>Неприемлемо</b>
                                        </div>
                                        <div class="help__services-standard__table-col">
                                            <b>Дипломатично</b>
                                        </div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                        <div class="help__services-standard__table-col">
                                            <p>«Женщина/мужчина/девушка, послушайте…»</p>
                                        </div>
                                        <div class="help__services-standard__table-col">
                                            <p>«Вам понравился этот вариант?»</p>
                                        </div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                        <div class="help__services-standard__table-col">
                                            <p>«Только не надо нервничать/успокойтесь!»</p>
                                        </div>
                                        <div class="help__services-standard__table-col">
                                            <p>«Возможно, я могу ошибаться, давайте спокойно разберемся»</p>
                                        </div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                        <div class="help__services-standard__table-col">
                                            <p>«Давайте скорее перейдем к делу»</p>
                                        </div>
                                        <div class="help__services-standard__table-col">
                                            <p>«Предлагаю обсудить, как я могу вам помочь»</p>
                                        </div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                        <div class="help__services-standard__table-col">
                                            <p>«Мне жаль, что это вас так задевает, но вернуть деньги мы не сможем»</p>
                                        </div>
                                        <div class="help__services-standard__table-col">
                                            <p>«Мне жаль, что мы не вправе вернуть вам деньги. Можем ли мы сделать для вас что-нибудь другое?»</p>
                                        </div>
                                    </div>
                                    <div class="help__services-standard__table-row">
                                        <div class="help__services-standard__table-col">
                                            <p>«Вы ничего не понимаете/не знаете наших правил»</p>
                                        </div>
                                        <div class="help__services-standard__table-col">
                                            <p>«На эту ситуацию можно посмотреть с другой стороны»</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'К оглавлению', link: '/help'}, {}]" />
                    </div>
                    <div class="page-small-column page-small-column--mobile-top">
                        <HelpSidebar
                            :links="[
                                { name: 'Стандарты сервиса', link: 'standards' },
                                { name: 'Подготовка и мерчандайзинг', link: 'preparation' },
                                { name: 'Приветствие. Установление контакта', link: 'greeting' },
                                { name: 'Выявление потребностей', link: 'requirement' },
                                { name: 'Презентация', link: 'presentation' },
                                { name: 'Работа с возражениями', link: 'work-with-opposition' },
                                { name: 'Расширение покупки', link: 'expansion' },
                                { name: 'Завершение сделки', link: 'finish' },
                            ]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter';
import HelpSidebar from '@/components/app/help/HelpSidebar';

export default {
    name: 'TheServicesStandards',
    metaInfo: {
        title: 'Стандарты сервиса - Помощь'
    },
    components: {
        HelpNavigationFooter, HelpSidebar
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

<template>
    <div class="product-card__wrap" :class="{'product-card__wrap--mini': size === 'mini'}">
        <div class="product-card__top">
            <div class="product-card__image" @click.prevent="openLink(product)">
                <img :src="product.image && product.image.preview ? product.image.preview : require('@/assets/img/flower.svg')" alt="">
            </div>
            <div class="product-card__info" :class="{'pr-0': authUserGroup === 'courier'}">
                <Button
                    v-if="authUserGroup !== 'courier'"
                    class="product-card__remove-btn"
                    text
                    compact
                    :disabled="order.status >= 4 || ['courier', 'florist'].includes(authUserGroup)"
                    @click.stop="$emit('click:remove')"
                >
                    <Icon color="#0D63F3">trash</Icon>
                </Button>
                <div v-if="product.discount || product.is_hidden" v-show="size === 'big'" class="product-card__badges">
                    <div v-if="product.discount" class="product-card__badges-item product-card__badges-item--yellow">
                        Акция
                    </div>
                    <div v-if="product.is_hidden" class="product-card__badges-item">
                        Недоступен
                    </div>
                </div>
                <div class="product-card__name-wrap">
                    <div class="product-card__name" :class="{'product-card__name--attention': product.order_variant !== 'regular'}">
                        <span>{{ product.name }}</span>
                        <span v-if="product.order_variant === 'increased' && product.custom_equipment_amount"> ({{ product.custom_equipment_amount }} шт.)</span>
                        <span v-if="product.order_variant === 'increased'"> (изменен)</span>
                        <span v-else-if="product.order_variant === 'plus30'"> (+30%)</span>
                        <span v-else-if="product.order_variant === 'plus60'"> (+60%)</span>
                    </div>

                    <Icon
                        v-if="product.equipment_set.length"
                        class="listing-table__product-name-chevron"
                        :class="{'rotate': isOpenedEquipmentList}"
                        color="#8AACCD"
                        @click.stop.prevent="isOpenedEquipmentList = !isOpenedEquipmentList"
                    >
                        down
                    </Icon>
                </div>
                <div v-show="size === 'big'" class="product-card__price-block">
                    <button v-if="!$route.params.orderID && product.price_change" class="product-card__price-edit-btn" @click.prevent="togglePriceEditMode">
                        <Icon>{{ !isPriceEditMode ? 'edit-2' : 'check' }}</Icon>
                    </button>
                    <div class="product-card__price-wrap">
                        <template v-if="!isPriceEditMode">
                            <div class="product-card__total-price">
                                {{ numberFormat(+product.price_in_receipt) }} ₽
                            </div>
                            <!--<div v-show="product.count > 1" class="product-card__price">
                                {{ numberFormat(+product.price) }}₽ / шт
                            </div>-->
                        </template>
                        <InputCustom
                            v-else
                            v-model.number="priceInput"
                            type="number"
                            compact
                            style="width: 80px"
                        />
                    </div>
                </div>
                <div v-show="size === 'mini'" class="product-card__price-block-mini">
                    <div class="product-card__price-block-mini__item">
                        <div class="product-card__price-block-mini__item-label">Кол-во</div>
                        <div class="product-card__price-block-mini__item-value">{{ product.count }} шт</div>
                    </div>
                    <div class="product-card__price-block-mini__item">
                        <div class="product-card__price-block-mini__item-label">Сумма</div>
                        <div class="product-card__price-block-mini__item-value">{{ numberFormat(+product.price_in_receipt) }} ₽</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="size === 'big' || isOpenedEquipmentList" class="product-card__bottom">
            <div v-show="size === 'big'" class="product-card__bottom-item">
                <button
                    v-if="product.equipment_set && product.equipment_set.length"
                    type="button"
                    class="product-card__structure-btn"
                    :disabled="['courier'].includes(authUserGroup)"
                    @click.stop.prevent="$emit('openEquipmentModal')"
                >
                    <Icon class="mr-1">edit-2</Icon>
                    Состав
                </button>
            </div>
            <div v-show="size === 'big'" class="product-card__bottom-item">
                <Checkbox
                    v-if="product.discount && ['managers', 'administrators', 'supervisor'].includes(authUserGroup)"
                    :value="!!(product.discount && product.discount.active)"
                    :label="product.discount.amount_perc ? `${product.discount.amount_perc} %` : `${product.discount.amount_rub} ₽`"
                    label-align="right"
                    inline
                    :disabled="order.status === 6"
                    @change="$emit('toggleDiscount')"
                />
            </div>
            <div v-show="size === 'big'" class="product-card__bottom-item">
                <InputCounter
                    :value="product.count"
                    :disabled="order.status >= 4 || ['courier', 'florist'].includes(authUserGroup)"
                    :style="product.discount && ['managers', 'administrators', 'supervisor'].includes(authUserGroup) ? 'max-width:125px;' :
                        'width: 125px'"
                    @input="$emit('changeCount', $event)"
                />
            </div>
            <div v-if="product.is_wishes_allow" class="width-100 mt-2">
                <TextareaCustom
                    v-model="product.wishes_for_product"
                    label="Пожелание"
                    counter
                    :disabled="['courier', 'florist'].includes(authUserGroup)"
                />
            </div>
            <div
                v-if="product.equipment_set.length && isOpenedEquipmentList"
                class="listing-table__product-equipment-list mx-0 mb-0 mt-2"
            >
                <div class="listing-table__product-equipment-list-label">Состав:</div>
                <div
                    v-for="(eq, index) in product.equipment_set.slice().sort((a, b) => a.sort - b.sort)"
                    :key="index"
                    class="listing-table__product-equipment-item"
                >
                    <div>{{ eq.name }}</div>
                    <div>{{ eq.count }} шт.</div>
                </div>
                <Button v-if="authUserGroup !== 'courier'" bordered block compact class="mt-2" @click.stop.prevent="$emit('openEquipmentModal')">
                    Изменить состав
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { numberFormat } from "@/utils/format";

export default {
    name: 'ProductCard',
    props: {
        product: { type: Object, default: () => {}, required: true },
        order: { type: Object, default: () => {} },
        size: { type: String, default: 'mini' }
    },
    data() {
        return {
            priceInput: +this.product.price,
            isPriceEditMode: false,
            isOpenedEquipmentList: false
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
            width: 'ui/width',
            franchiseTariff: 'franchises/franchiseTariff',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
    },
    methods: {
        togglePriceEditMode() {
            if (this.isPriceEditMode) {
                this.$emit('changePrice', this.priceInput);
            }
            this.isPriceEditMode = !this.isPriceEditMode;
        },
        openLink(good) {
            if (good.slug && good.equipment_set.length && this.authUserGroup === "courier") {
                window.open(`https://${this.franchiseTariff.site_domain}/product/${good.slug}/`);
            } else {
                window.open(`/goods/${good.vendor_code}`, '_blank');
            }
        },
        numberFormat
    }
}
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Возвраты"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список возвратов</h2>
                                <p>В списке возвратов отображаются поля:</p>
                                <ul>
                                    <li>№ заказа</li>
                                    <li>Дата возврата</li>
                                    <li>Сумма возврата</li>
                                    <li>Магазин</li>
                                    <li>Статус</li>
                                </ul>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание возврата</h2>
                                <p><b>Возвраты создаются из детальной страницы заказа.</b></p>
                                <p>Необходимо соблюсти условия:</p>
                                <ul>
                                    <li>Заказ в статусе <b>"Ожидает отмены"</b></li>
                                    <li>Активна кнопка <b>"Распечатан чек"</b></li>
                                </ul>
                                <p>Если условия соблюдены, то в дополнительном меню появится кнопка <b>"Оформить возврат"</b>.</p>
                                <img src="@/assets/img/help/returns/1.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Для каждого товара можно выбрать статус возврата. Доставка изначально не возвращается.</p>
                                <p>У каждого товара можно задать количество для возврата. Это количество не может быть больше, чем количество купленных товаров.</p>
                                <img src="@/assets/img/help/returns/2.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Для заказов, которые не содержат никаких скидок, сумма возврата рассчитывается автоматически.</p>
                                <p>Но при необходимости можно задать сумму вручную. Если к заказу были применены промокоды, бонусы или другие скидки, то сумму возврата необходимо рассчитывать вручную.</p>
                                <img src="@/assets/img/help/returns/3.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>Для создания возврата нужно обязательно выбрать магазин заказа. Но магазин задается именно в заказе. В создании возврата он только отображается для проверки.</p>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Просмотр и редактирование возврата</h2>
                                <p>Пока возврат находится в статусе <b>"Не выполнен"</b>, то его можно редактировать. Доступны манипуляции с товарами и суммой возврата.</p>
                                <p>Чтобы возврат перешел в статус <b>"Выполнен"</b>, нужно проставить состояние <b>"Распечатан чек (возврат выполнен)"</b> и сохранить запись.</p>
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{name: 'Магазины', link: '/help/shops'}, {name: 'Премии и штрафы', link: '/help/salarys-changes'}]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpReturns',
    metaInfo: {
        title: 'Возвраты - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                title="Промокоды"
                left-btn="left-big"
                :show-save-btn="false"
                @click:left="$router.push('/help')"
            />
            <div class="page-container">
                <div class="page-columns">
                    <div class="page-big-column">
                        <div class="help__content">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Список промокодов</h2>
                                <p>Список промокодов содержит следующие поля:</p>
                                <ul>
                                    <li>Промокод</li>
                                    <li>Период действия</li>
                                    <li>Величина</li>
                                    <li>Активность</li>
                                </ul>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Создание и редактирование промокода</h2>
                                <p>Переключатель <b>"Для новых пользователей"</b> означает, что промокод будет работать только для самого первого заказа пользователя.</p>
                                <img src="@/assets/img/help/promocodes/1.jpg" class="help__image" alt="" style="margin: 16px auto">

                                <p>При редактировании промокода можно изменить значение переключателей:</p>
                                <ul>
                                    <li>Активен</li>
                                    <li>Один раз для одного пользователя</li>
                                    <li>Для новых пользователей</li>
                                </ul>
                                <p>Также можно изменить период действия. Удалить промокод нельзя.</p>
                                <p>На вкладке "Заказы" отображается список заказов, в которых был применен текущий промокод.</p>
                                <img src="@/assets/img/help/promocodes/2.jpg" class="help__image" alt="" style="margin: 16px auto">
                            </div>
                        </div>
                        <HelpNavigationFooter :links="[{ name: 'Скидки', link: '/help/discounts' }, { name: 'Интеграции', link: '/help/integrations' }]" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import HelpNavigationFooter from '@/components/app/help/HelpNavigationFooter'

export default {
    name: 'TheHelpPromocodes',
    metaInfo: {
        title: 'Промокоды - Помощь'
    },
    components: {
        HelpNavigationFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            width: 'ui/width',
        })
    },
};
</script>

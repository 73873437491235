<template>
    <section :class="['page-section', $route.name]">
        <v-speed-dial
            v-if="$route.params.ID == authUserID && supportFirebase"
            v-show="tabActive !== 5"
            v-model="fab_open"
            class="btn__floating custom-speed-dial"
            close-on-click
            fixed
        >
            <template v-slot:activator>
                <v-btn fab dark color="#1a73e8" @click.stop="fab_open = !fab_open">
                    <v-icon>{{ fab_open ? 'mdi-close' : 'mdi-dots-vertical' }}</v-icon>
                </v-btn>
            </template>
            <div class="custom-speed-dial__menu">
                <v-list>
                    <v-list-item class="v-list-item--link hidden-ios">
                        <v-list-item-icon class="mr-3">
                            <v-icon>mdi-bell</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title><SubscribePush /></v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-speed-dial>
        <div class="page-wrap">
            <Header
                :title="$route.params.ID && user.groups[0] == 'superadministrators' ? `Суперадминистратор ${name}` :
                        $route.params.ID && groups && groups.length && user.groups && user.groups[0] && user.groups[0] !== 'superadministrators' &&
                                groups.find(g => g.slug === user.groups[0]) ? `${groups.find(g => g.slug === user.groups[0]).name} ${name}` :
                                    $route.params.ID ? name : 'Новый пользователь'"
                left-btn="left-big"
                :show-save-btn="isVisibleSaveBtn"
                @click:left="$router.back()"
                @click:save="eventHandlerButton"
            >
                <button v-if="$route.params.ID == authUserID" type="button" class="header__button" @click="showExitModal = true">
                    <v-icon color="#0F1922">mdi-logout-variant</v-icon>
                </button>
            </Header>
            <div v-if="$route.params.ID" class="tabs__list">
                <TabItem :active="tabActive === 0" @click="tabActive = 0">
                    Общее
                </TabItem>
                <TabItem
                    v-if="['customers', 'courier'].includes(app.group) &&
                        (['managers', 'administrators', 'supervisor'].includes(authUserGroup) || +$route.params.ID === +user.id)"
                    :active="tabActive === 1"
                    @click="tabActive = 1"
                >
                    Заказы
                </TabItem>
                <TabItem
                    v-if="!['customers', 'courier'].includes(app.group) && !['customers', 'courier'].includes(authUserGroup)"
                    :active="tabActive === 2"
                    @click="tabActive = 2"
                >
                    Рабочее время
                </TabItem>
                <TabItem
                    v-if="app.group === 'customers' && ['managers', 'administrators', 'supervisor'].includes(authUserGroup)"
                    :active="tabActive === 3"
                    @click="tabActive = 3"
                >
                    Бонусы
                </TabItem>
                <TabItem
                    v-if="app.group === 'customers' && ['managers', 'administrators', 'supervisor'].includes(authUserGroup)"
                    :active="tabActive === 4"
                    @click="tabActive = 4"
                >
                    Поводы
                </TabItem>
                <TabItem
                    v-if="$route.params.ID && ['managers', 'administrators', 'supervisor'].includes(authUserGroup) &&
                        app.group === 'customers' && franchiseTariff.tariff && franchiseTariff.tariff.includes('site')"
                    :active="tabActive === 5"
                    @click="tabActive = 5"
                >
                    Чат
                    <div v-if="orderChat.unseen_count" class="tabs__item-indication">
                        {{ orderChat.unseen_count }}
                    </div>
                </TabItem>
            </div>
            <div
                class="page-container"
                :class="{ 'page-container--with-tabs': $route.params.ID, 'page-container--with-fab': tabActive !== 5 }"
            >
                <div v-show="tabActive === 0" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <Row>
                                <Col v-if="!$route.params.ID || ($route.params.ID && app.group && app.group !== 'superadministrators')">
                                    <InputSelect
                                        :value="app.group"
                                        :options="groups"
                                        label="Группа"
                                        :input-value="'slug'"
                                        :disabled="!canChangeUserGroup || (authUserGroup !== 'supervisor' && authUserGroup !== 'administrators')"
                                        @input="setUserGroup"
                                    />
                                </Col>
                                <Col v-if="app.group && (app.group === 'florist' || app.group === 'cashboxes')">
                                    <InputSelect
                                        v-model="user.shop"
                                        :options="shops.results"
                                        label="Магазин"
                                        :input-text="'address'"
                                        input-icon="type_icon"
                                        :disabled="authUserGroup !== 'supervisor' && authUserGroup !== 'administrators'"
                                    />
                                </Col>
                                <Col v-if="app.group">
                                    <InputCustom
                                        v-model="user.first_name"
                                        label="Имя"
                                        :disabled="authUserGroup !== 'supervisor' && authUserGroup !== 'administrators'"
                                        :error="error && error.first_name ? error.first_name[0] : ''"
                                    />
                                </Col>
                                <Col v-if="app.group && app.group !== 'customers'">
                                    <InputCustom
                                        v-model="user.username"
                                        label="Логин"
                                        :disabled="authUserGroup !== 'supervisor' && authUserGroup !== 'administrators'"
                                        :error="error && error.username ? error.username[0] : ''"
                                    />
                                </Col>
                                <Col v-if="!$route.params.ID && app.group && app.group !== 'customers'">
                                    <InputCustom
                                        v-model="password"
                                        label="Пароль"
                                        hint="Пароль должен содержать минимум 9 символов и хотя бы одну цифру"
                                        :disabled="authUserGroup !== 'supervisor' && authUserGroup !== 'administrators'"
                                        :error="error && error.password ? error.password[0] : ''"
                                    />
                                </Col>
                                <Col v-if="$route.params.ID && app.group !== 'customers' && (authUserGroup === 'supervisor' || authUserGroup === 'administrators')">
                                    <InputCustom
                                        v-model="new_password"
                                        label="Новый пароль"
                                        hint="Пароль должен содержать минимум 9 символов и хотя бы одну цифру"
                                        :error="error && error.password ? error.password[0] : ''"
                                        @change="passwordChanged = true"
                                    />
                                </Col>
                                <Col v-if="app.group && ['customers', 'courier'].includes(app.group)">
                                    <InputCustom
                                        v-model="user.phone"
                                        label="Телефон"
                                        hint="Телефон должен начинаться с '+' и соответствовать международному формату"
                                        :disabled="authUserGroup !== 'supervisor' && authUserGroup !== 'administrators'"
                                        :error="error && error.phone ? error.phone[0] : ''"
                                    />
                                </Col>
                                <Col v-if="app.group && app.group !== 'cashboxes'">
                                    <InputCustom
                                        v-model="user.email"
                                        label="Email"
                                        :disabled="authUserGroup !== 'supervisor' && authUserGroup !== 'administrators'"
                                        :error="error && error.email ? error.email[0] : ''"
                                    />
                                </Col>
                                <Col v-if="user.groups[0] === 'customers'">
                                    <InputSelect
                                        v-model="user.sex"
                                        :options="[{ name: 'Мужский', value: 'Man'}, { name: 'Женский', value: 'Woman' }]"
                                        label="Пол"
                                        :input-value="'value'"
                                        :disabled="authUserGroup !== 'supervisor' && authUserGroup !== 'administrators'"
                                    />
                                </Col>
                                <Col v-if="user.groups[0] === 'customers'">
                                    <v-menu
                                        ref="birthday"
                                        offset-y
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <InputCustom
                                                :value="user.birthday ? formatDate(user.birthday, 'ru') : 'Не указано'"
                                                label="Дата рождения"
                                                readonly
                                                v-on="on"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="user.birthday"
                                            no-title
                                            first-day-of-week="1"
                                            :max="formatDate(new Date())"
                                            @input="$refs.birthday.save(user.birthday)"
                                        />
                                    </v-menu>
                                </Col>
                                <Col v-if="app.group && app.group !== 'customers' && app.group !== 'cashboxes'">
                                    <InputCustom
                                        :value="+user.salary_rate"
                                        label="Ставка"
                                        type="number"
                                        :disabled="authUserGroup !== 'supervisor' && authUserGroup !== 'administrators'"
                                        @input="user.salary_rate = $event"
                                    />
                                </Col>
                                <Col v-if="$route.params.ID && app.group && app.group === 'florist'">
                                    <InputCustom
                                        v-model="user.pin"
                                        label="PIN"
                                        disabled
                                    />
                                </Col>
                                <Col v-if="user.groups[0] !== 'customers'">
                                    <TextareaCustom
                                        v-model="user.additional_info"
                                        label="Комментарий"
                                        rows="4"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div class="page-small-column">
                            <div v-if="$route.params.ID" class="page__info-group">
                                <h2 class="page__info-group-title">Фото пользователя</h2>
                                <ImageLoader
                                    v-show="!preloader"
                                    ref="userAvatar"
                                    :value="user.avatar"
                                    @change="changePhoto($event, 'courier')"
                                />
                                <ContentPreloader v-show="preloader" :height="150" />
                            </div>
                            <div class="page__info-group">
                                <div class="checkbox-group">
                                    <div v-if="user.groups[0] === 'customers'" class="checkbox-group__item">
                                        <Checkbox
                                            :value="user.is_send_notifies"
                                            label="Участвует в рассылке"
                                            @change="user.is_send_notifies = !user.is_send_notifies"
                                        />
                                    </div>
                                    <div class="checkbox-group__item">
                                        <Checkbox
                                            :value="user.not_accrue_bonuses"
                                            label="Не начислять бонусы"
                                            @change="user.not_accrue_bonuses = !user.not_accrue_bonuses"
                                        />
                                    </div>
                                    <div v-if="user.groups[0] !== 'customers'" class="checkbox-group__item">
                                        <Checkbox
                                            :value="user.read_only"
                                            label="Только просмотр"
                                            @change="user.read_only = !user.read_only"
                                        />
                                    </div>
                                    <div v-if="user.groups[0] === 'courier'" class="checkbox-group__item">
                                        <Checkbox
                                            :value="user.phone_visible"
                                            label="Возможность связаться с курьером"
                                            :disabled="user.is_external_courier"
                                            @change="user.phone_visible = !user.phone_visible"
                                        />
                                    </div>
                                    <div v-if="user.groups[0] === 'courier'" class="checkbox-group__item">
                                        <Checkbox
                                            :value="user.is_external_courier"
                                            label="Курьер-такси"
                                            @change="user.is_external_courier = !user.is_external_courier"
                                        />
                                    </div>
                                    <div v-if="user.groups[0] !== 'customers'" class="checkbox-group__item">
                                        <Checkbox
                                            :value="user.is_coffee"
                                            label="Сотрудник кофейни"
                                            @change="user.is_coffee = !user.is_coffee"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                v-if="$route.params.ID && !loggedUser.read_only"
                                class="mt-4"
                                block compact text
                                icon-left="trash"
                                @click.prevent="showConfirmDeleteUser = true"
                            >
                                Удалить пользователя
                            </Button>
                        </div>
                    </div>
                </div>
                <div v-show="tabActive === 1" class="tabs__content">
                    <div v-if="app.group === 'customers'" class="user__parameters-list">
                        <div class="user__parameters-item">
                            <div class="user__parameters-item-label">Сумма покупок</div>
                            <div class="user__parameters-item-value">{{ formatNumber(user.summ_of_purchases) }}</div>
                        </div>
                        <div class="user__parameters-item user__parameters-item--50">
                            <div class="user__parameters-item-label">Ср. чек</div>
                            <div class="user__parameters-item-value">{{ formatNumber(user.avge_check) }}</div>
                        </div>
                        <div class="user__parameters-item user__parameters-item--50">
                            <div class="user__parameters-item-label">Покупок</div>
                            <div class="user__parameters-item-value">{{ user.num_of_purchases }}</div>
                        </div>
                    </div>

                    <div v-if="order_list.length" class="user__table">
                        <div class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                <div class="listing-table__col pl-0" style="width: calc((100% - 112px) / 4);">№ заказа</div>
                                <div class="listing-table__col" style="width: calc((100% - 112px) / 4);">Дата доставки</div>
                                <div class="listing-table__col" style="width: calc((100% - 112px) / 4);">Стоимость</div>
                                <div class="listing-table__col" style="width: calc((100% - 112px) / 4);">Статус</div>
                            </div>
                            <div v-for="item in order_list" :key="item.visible_id" class="listing-table__row">
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image listing-table__image--with-link">
                                        <img :src="item.preview_image ? item.preview_image : require('@/assets/img/flower.svg')" alt="">
                                        <a href="#" class="listing-table__image-link" @click.stop.prevent="goToLink(`/orders/${item.visible_id}`)">
                                            <v-icon color="primary">mdi-open-in-new</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1024 ? 'width: calc((100% - 112px) / 4);' : ''"
                                >
                                    №{{ item.visible_id }}
                                </div>

                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 4);' : ''">
                                    <div class="listing-table__col-label">Дата доставки</div>
                                    {{ formatDate(item.delivery_date, 'ru') }}
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 4);' : ''">
                                    <div class="listing-table__col-label">Стоимость</div>
                                    {{ formatNumber(Math.ceil(item.final_cost)) }} ₽
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 112px) / 4);' : ''">
                                    <StatusSelect
                                        :value="item.status"
                                        :options="orderStatuses.statuses || []"
                                        :order="item"
                                        mode="order"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="app.group === 'customers' && customerOrders.next" class="d-flex justify-center mt-4">
                            <Button
                                bordered
                                icon-right="arrow-down"
                                @click.prevent="loadMoreCustomerOrders"
                            >
                                Загрузить еще
                            </Button>
                        </div>
                    </div>

                    <div v-if="app.group === 'courier' && courierOrders.results.length">
                        <div class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px; flex: 0 0 auto;" />
                                <div class="listing-table__col pl-0" style="width: calc((100% - 174px) / 4);">Номер заказа</div>
                                <div class="listing-table__col" style="width: calc((100% - 174px) / 4);">Дата доставки</div>
                                <div class="listing-table__col" style="width: calc((100% - 174px) / 4);">Стоимость</div>
                                <div class="listing-table__col" style="width: calc((100% - 174px) / 4);">Статус</div>
                                <div class="listing-table__col" style="width: 70px;flex: 0 0 auto;" />
                            </div>
                            <div v-for="(item, index) in courierOrders.results" :key="item.visible_id" class="listing-table__row">
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image listing-table__image--with-link">
                                        <img src="@/assets/img/flower.svg" alt="">
                                        <a href="#" class="listing-table__image-link" @click.stop.prevent="goToLink(`/orders/${item.visible_id}`)">
                                            <v-icon color="primary">mdi-open-in-new</v-icon>
                                        </a>
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1024 ? 'width: calc((100% - 174px) / 4);' : ''"
                                >
                                    №{{ item.visible_id }}
                                </div>

                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pt-0" />

                                <div class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 174px) / 4);' : ''">
                                    {{ formatDate(item.delivery_date, 'ru') }}
                                </div>
                                <div class="listing-table__col listing-table__col--mobile-inline" :style="width > 1024 ? 'width: calc((100% - 174px) / 4);' : ''">
                                    {{ formatNumber(Math.ceil(item.final_cost)) }} ₽
                                </div>
                                <div v-if="width > 1024" class="listing-table__col" :style="width > 1024 ? 'width: calc((100% - 174px) / 4);' : ''">
                                    <StatusSelect
                                        :value="item.status"
                                        :options="orderStatuses.statuses || []"
                                        :order="item"
                                        mode="order"
                                        disabled
                                    />
                                </div>
                                <div class="listing-table__col listing-table__col--mobile-inline ml-a" :style="width > 1024 ? 'width: 70px' : ''">
                                    <Button
                                        text
                                        compact
                                        :disabled="authUserGroup !== 'supervisor' && authUserGroup !== 'administrators'"
                                        @click.stop="unbind(item.visible_id, index)"
                                    >
                                        <Icon color="#0D63F3">trash</Icon>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div v-if="courierOrders.next" class="d-flex justify-center mt-4">
                            <Button
                                bordered
                                icon-right="arrow-down"
                                :block="width <= 699"
                                @click.prevent="loadMoreCourierOrders"
                            >
                                Загрузить еще
                            </Button>
                        </div>
                    </div>
                </div>
                <div v-show="tabActive === 2" class="tabs__content">
                    <div v-if="app.group !== 'customers' && showTimeTable" class="table-working-time">
                        <div v-if="workingTime.length" class="listing-table">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 33%">Дата</div>
                                <div class="listing-table__col" style="width: 33%">Период</div>
                                <div class="listing-table__col" style="width: 33%">Продолжительность</div>
                            </div>
                            <div v-for="item in workingTime" :key="item.id" class="listing-table__row">
                                <div class="listing-table__col" :style="width > 1024 ? 'width: 33%' : ''">
                                    {{ formatDate(item.date, 'ru') }}
                                </div>
                                <div class="listing-table__col listing-table__col--mobile-bordered" :style="width > 1024 ? 'width: 33%' : ''">
                                    {{ formatTime(item.start_time, 'time') }} - {{ formatTime(item.end_time, 'time') }}
                                </div>
                                <div class="listing-table__col listing-table__col--mobile-inline" :style="width > 1024 ? 'width: 33%' : ''">
                                    {{ formatDuration(item.duration) }}
                                </div>
                            </div>
                        </div>
                        <a v-if="!canLoadMoreTime" class="load-more" href="#" @click.prevent="loadTimesheet">
                            <div class="text-load">Загрузить еще</div>
                        </a>
                    </div>
                </div>
                <div v-show="tabActive === 3" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <Row>
                                <Col>
                                    <InputCustom
                                        :value="+user.number_of_bonus"
                                        label="Бонусы"
                                        :disabled="authUserGroup !== 'supervisor' && authUserGroup !== 'administrators'"
                                        @input="user.number_of_bonus = $event"
                                    />
                                </Col>
                                <Col v-if="user.number_of_bonus != initial_bonuses_count">
                                    <TextareaCustom
                                        v-model="user.reason_change_bonuses"
                                        label="Причина изменения бонусов"
                                        rows="4"
                                        :error="error.reason_change_bonuses ? error.reason_change_bonuses : ''"
                                    />
                                </Col>
                            </Row>
                            <div class="user__table">
                                <div v-if="bonusesList.length" class="listing-table">
                                    <div class="listing-table__row listing-table__row--head">
                                        <div class="listing-table__col" style="width: 25%">Дата</div>
                                        <div class="listing-table__col" style="width: 20%">Изменение</div>
                                        <div class="listing-table__col" style="width: 55%">Причина</div>
                                    </div>
                                    <div v-for="item in bonusesList" :key="item.id" class="listing-table__row">
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: 25%' : ''">
                                            {{ item.date_created ? moment(item.date_created).format('DD MMMM YYYY, HH:mm') : '' }}
                                        </div>
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: 20%' : ''">
                                            <span :style="`color: ${item.type === 1 ? '#0BD58A' : '#F00C29'}`">
                                                {{ item.type === 1 ? '+' : '-' }}{{ +item.amount }}
                                            </span>
                                        </div>
                                        <div class="listing-table__col" :style="width > 1024 ? 'width: 55%' : ''">
                                            {{ item.reason || 'Не указана' }}
                                        </div>
                                    </div>
                                </div>
                                <span v-else class="order-history-modal-changes-text">Изменений бонусов еще не было</span>
                            </div>
                        </div>
                        <div class="page-small-column page-small-column--mobile-top">
                            <div class="user__parameters-item">
                                <div class="user__parameters-item-label">Бонусов на счёте</div>
                                <div class="user__parameters-item-value">{{ formatNumber(+user.number_of_bonus) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="tabActive === 4" class="tabs__content">
                    <div v-if="user.user_reasons.length" class="listing-table">
                        <div v-for="(item, index) in user.user_reasons" :key="item.id" class="listing-table__row">
                            <div
                                class="listing-table__col"
                                :class="{'pt-0 mr-0': width <= 1024}"
                                :style="width > 1024 ? 'width: calc(100% - 325px);' : ''"
                            >
                                <InputCustom
                                    v-model="item.name"
                                    label="Название"
                                    :class="{'mt-2': width <= 1024}"
                                    style="flex: 0 0 auto;"
                                />
                            </div>
                            <div
                                class="listing-table__col"
                                :style="width > 1024 ? 'width: 155px;flex: 0 0 auto;' : ''"
                            >
                                <Checkbox
                                    v-model="item.is_remind"
                                    label="Напоминать"
                                    style="flex: 0 0 auto;"
                                />
                            </div>
                            <div
                                class="listing-table__col listing-table__col--mobile-inline"
                                :style="width > 1024 ? 'width: 125px;flex: 0 0 auto;' : ''"
                            >
                                <div class="mb-2" style="font-size: 12px;line-height: 14px;color: #8AACCD;">
                                    Дата добавления
                                </div>
                                {{ formatDate(item.date, 'ru') }}
                            </div>
                            <div class="listing-table__col listing-table__col--mobile-inline ml-a" :style="width > 1024 ? 'width: 45px;flex: 0 0 auto;' : ''">
                                <Icon color="#0D63F3" style="cursor: pointer;" @click.prevent="user.user_reasons.splice(index, 1)">
                                    trash
                                </Icon>
                            </div>
                        </div>
                    </div>
                    <span v-else class="order-history-modal-changes-text">
                        Пользователь не добавил ни одного повода заказа
                    </span>
                </div>
                <div
                    v-if="$route.params.ID && (authUserGroup === 'supervisor' || authUserGroup === 'administrators' || authUserGroup === 'managers') &&
                        app.group === 'customers' && franchiseTariff.tariff && franchiseTariff.tariff.includes('site')"
                    v-show="tabActive === 5"
                    class="tabs__content"
                >
                    <ChatMessageSection minimize />
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showExitModal"
            title="Вы уверены, что хотите выйти?"
            additional-btn-text="Отмена"
            main-btn-text="Выйти"
            @additionalBtnClick="showExitModal = false"
            @mainBtnClick="exit"
        />
        <MiniModal
            v-model="showConfirmDeleteUser"
            :title="`Удалить пользователя ${ user.first_name }?`"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteUser = false"
            @mainBtnClick="deleteUser"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import { getBonusesListForClient } from '@/api/gateway';

import { date, numberFormat } from '@/utils/format';
import { getTimesheet } from '@/api/timetracking';

import {
    getCourierOrder,
    unbindOrder,
    getOrdersForUser,
    getProfileData,
    getNextCourierOrder,
} from '@/api/django';

import SubscribePush from '@/components/buttons/SubscribePush';
import ChatMessageSection from '@/components/app/chat/ChatMessageSection';
import MiniModal from "@/components/modal/MiniModal";
import ImageLoader from '@/components/app/ImageLoader'

export default {
    name: 'UserDetail',
    components: {
        MiniModal,
        SubscribePush,
        ChatMessageSection,
        ImageLoader
    },
    data() {
        return {
            user: {
                avatar: null,
                username: null,
                first_name: '',
                last_name: '',
                email: null,
                phone: null,
                shop: null,
                city: null,
                last_login: '',
                groups: [],
                is_active: true,
                is_cashbox: false,
                is_coffee: false,
                is_send_notifies: true,
                is_staff: false,
                pin: '',
                salary_rate: 0,
                additional_info: null,
                reason_change_bonuses: '',
                birthday: '',
                user_reasons: [],
                not_accrue_bonuses: true,
                read_only: false,
                phone_visible: false,
                is_external_courier: false
            },
            app: {
                group: ''
            },
            password: null,
            new_password: '',
            passwordChanged: false,
            city: '',
            error: {},
            workingTime: [],
            courierOrders: {
                results: [],
                next: false,
                offset: 20
            },
            showTimeTable: false,
            dateInterval: 0,
            startDate: new Date(),
            endTime: new Date(),
            canLoadMoreTime: true,
            order_list: [],
            customerOrders: {
                next: false,
                offset: 0
            },
            fab_open: false,
            initial_bonuses_count: null,
            tabActive: 0,
            canChangeUserGroup: true,
            showConfirmDeleteUser: false,
            showExitModal: false,
            bonusesList: []
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            allCities: 'data/allCities',
            shops: 'shops/shops',
            groups: 'users/groups',
            loggedUser: 'auth/user',
            preloader: 'ui/preloader',
            supportFirebase: 'ui/supportFirebase',
            orderChat: 'chat/orderChat',
            chatLimit: 'chat/limit',
            franchiseTariff: 'franchises/franchiseTariff',
            chatWsReady: 'chat/wsReady',
            orderStatuses: 'orders/statuses'
        }),
        name() {
            return `${this.user.last_name} ${this.user.first_name}`;
        },
        authUserID() {
            return JSON.parse(localStorage.getItem('user')).id;
        },
        authUserGroup() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        isVisibleSaveBtn() {
            return (this.authUserGroup === 'supervisor' || this.authUserGroup === 'administrators') && !this.loggedUser.read_only &&
                ((!this.$route.params.ID && this.app.group === 'customers' && this.user.first_name && this.user.phone) ||
                (!this.$route.params.ID && this.user.first_name && this.password && this.user.groups.length && this.user.groups[0] !== null) ||
                (!this.$route.params.ID && this.user.is_staff && this.password && this.user.groups.length && this.user.groups[0] !== null) ||
                (!!this.$route.params.ID && this.user.username && this.user.is_staff && this.user.groups.length && this.user.groups[0] !== null) ||
                (!!this.$route.params.ID && this.user.first_name && this.user.groups.length && this.user.groups[0] !== null))
        }
    },
    watch: {
        'user.city'(value) {
            if (value) {
                const { russian_name } = this.allCities.find((city) => city.id === value);
                if (russian_name) {
                    this.city = russian_name;
                }
            }
        },
        'app.group'(value) {
            if (!this.$route.params.ID) {
                this.user.username = null;
                this.user.first_name = '';
                this.user.last_name = '';
                this.user.email = null;
                this.user.phone = null;
                this.user.shop = null;
                this.user.is_staff = false;
                this.user.is_cashbox = false;
                this.user.salary_rate = 0;
                this.user.pin = '';
                this.password = null;
                this.user.not_accrue_bonuses = true;
                if (value === 'customers') {
                    this.user.not_accrue_bonuses = false;
                }
            }
            if (value === 'cashboxes') {
                this.user.is_staff = false;
                this.user.is_cashbox = true;
            } else if (value === 'customers') {
                this.user.is_cashbox = false;
                this.user.is_staff = false;
            }
        },
        tabActive() {
            if (this.tabActive === 5) {
                this.$store.dispatch('chat/SELECT_CHAT', this.orderChat);
            } else {
                this.$store.commit('chat/SET_SELECTED_CHAT', {});
            }
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await this.$store.dispatch('data/LOAD_ALL_CITIES');
            this.$store.dispatch('data/LOAD_SHOPS', JSON.parse(localStorage.getItem('user')).city);
            this.$store.dispatch('shops/GET_SHOPS');
            this.$store.dispatch('orders/GET_ORDER_STATUSES');
            if (this.authUserGroup === 'supervisor' || this.authUserGroup === 'administrators' || this.authUserGroup === 'superadministrators') {
                await this.$store.dispatch('users/GET_USERS_GROUPS');
            }

            if (this.$route.params.ID) {
                let user = {}
                if (this.authUserGroup === 'supervisor' || this.authUserGroup === 'administrators' || this.authUserGroup === 'superadministrators') {
                    user = await this.$store.dispatch('users/GET_USER', this.$route.params.ID)
                    if (this.chatWsReady) {
                        this.$store.dispatch('chat/SEARCH_CHAT_BY_USER_ID', { id: `${this.$route.params.ID}`, limit: 1 });
                    }
                } else {
                    user = await getProfileData()
                }
                this.$emit('set-user', user)
                this.user = user;
                this.initial_bonuses_count = +user.number_of_bonus;
                this.app.group = user.groups[0];

                if (this.app.group === 'customers') {
                    this.canChangeUserGroup = false;
                    const res = await getOrdersForUser(user.id)
                    this.order_list = res.results;
                    this.customerOrders.next = !!res.next;

                    const bonuses = await getBonusesListForClient(this.$route.params.ID);
                    this.bonusesList = bonuses;
                }

                if (this.app.group !== 'customers') {
                    this.loadTimesheet();
                }

                if (this.app.group === 'courier') {
                    this.$store.dispatch('orders/GET_ORDER_STATUSES');
                    this.loadCourierOrders();
                }
            } else if (this.$route.name === 'clients-create') {
                this.setUserGroup('customers');
            }
            this.$store.dispatch('ui/initFirebase');
        } catch (error) {
            console.log(error);
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        this.$store.dispatch('chat/SEARCH_CHATS', { str: '', limit: this.chatLimit, type: 'True' });
        this.$store.commit('chat/SET_SELECTED_CHAT', {});
        this.$store.commit('chat/SET_ORDER_CHAT', {});
        this.$store.commit('chat/SET_MESSAGES', []);
    },
    methods: {
        async createUser() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                const user = JSON.parse(JSON.stringify(this.user));

                if (user.email === '') {
                    user.email = null;
                }

                if (!user.birthday) {
                    delete user.birthday;
                }

                if (user.username === '') {
                    user.username = null;
                }

                if (this.password === '') {
                    this.password = null;
                }

                if (user.phone && !user.phone.startsWith('+')) {
                    user.phone = `+${user.phone}`;
                } else if (!user.phone) {
                    delete user.phone;
                }

                if (user.groups[0] !== 'cashboxes' && user.groups[0] !== 'customers') {
                    user.is_staff = true;
                } else if (user.groups[0] === 'cashboxes' || user.groups[0] === 'customers') {
                    user.is_staff = false;
                }

                if (user.groups[0] !== 'courier') {
                    user.phone_visible = false;
                    user.is_external_courier = false;
                }

                await this.$store.dispatch('users/CREATE_USER', { ...user, password: this.password })
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Пользователь успешно создан', type: 'success' })
                this.$router.back();
            } catch (error) {
                console.log(error);
                if (error.data) {
                    this.error = error.data;
                }
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении пользователя произошла ошибка', type: 'error' })
            }
        },
        async updateUser() {
            this.error = {};
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                if (this.user.number_of_bonus != this.initial_bonuses_count && !this.user.reason_change_bonuses) {
                    this.error.reason_change_bonuses = 'Это поле обязательно';
                    this.$forceUpdate();
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении пользователя произошла ошибка', type: 'error' })
                    this.$store.commit('ui/SET_PRELOADER', false);
                    return;
                }
                this.user.number_of_bonus = +this.user.number_of_bonus;

                if (!this.user.username) this.$delete(this.user, 'username', '');

                if (this.user.phone && !this.user.phone.startsWith('+')) {
                    this.user.phone = `+${this.user.phone}`;
                } else if (!this.user.phone) {
                    this.$delete(this.user, 'phone');
                }

                if (this.user.groups[0] !== 'cashboxes' && this.user.groups[0] !== 'customers') {
                    this.user.is_staff = true;
                } else if (this.user.groups[0] === 'cashboxes' || this.user.groups[0] === 'customers') {
                    this.user.is_staff = false;
                }

                if (this.$refs.userAvatar.files.length) {
                    try {
                        const data = new FormData();
                        data.append("avatar", this.$refs.userAvatar.files[0]);

                        await this.$store.dispatch('users/UPDATE_USER', {
                            user: data,
                            id: this.$route.params.ID,
                            headers: { "Content-Type": "multipart/form-data" }
                        })
                    } catch (error) {
                        this.$store.commit('ui/SET_PRELOADER', false);
                        this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки фото', type: 'error' })
                        throw new Error('Ошибка загрузки фото');
                    }
                }

                const user = JSON.parse(JSON.stringify(this.user));
                delete user.avatar;

                await this.$store.dispatch('users/UPDATE_USER', { user: user, id: this.$route.params.ID })
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Пользователь успешно обновлён', type: 'success' })
                this.$router.back();
            } catch (error) {
                console.log(error);
                if (error.data) {
                    this.error = error.data;
                }
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При сохранении пользователя произошла ошибка', type: 'error' })
            } finally {
                setTimeout(() => {
                    if (this.$route.name === 'user' || this.$route.name === 'client') {
                        this.$store.commit('ui/SET_PRELOADER', false);
                    }
                }, 300)
            }
        },
        async eventHandlerButton() {
            if (this.$route.params.ID) {
                if (this.passwordChanged) {
                    await this.changePassword();
                }
                this.updateUser();
            } else {
                this.createUser();
            }
        },
        async deleteUser() {
            this.showConfirmDeleteUser = false;
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('users/DELETE_USER', this.$route.params.ID)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Пользователь успешно удалён', type: 'success' })
                this.$router.back();
            } catch (error) {
                console.log(error);
                this.$store.commit('ui/SET_PRELOADER', false);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'При удалении пользователя произошла ошибка', type: 'error' })
            }
        },
        async changePassword() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                await this.$store.dispatch('users/CHANGE_PASSWORD', { id: this.$route.params.ID, data: { password: this.new_password } });
            } catch (error) {
                this.error = error.data;
                throw error;
            }
        },
        setUserGroup(group) {
            this.user.groups[0] = group;
            this.app.group = group;
        },
        async loadTimesheet(startDate, endDate) {
            try {
                this.dateInterval += 3;

                this.startDate.setDate(this.startDate.getDate() - this.dateInterval);

                if (startDate && endDate) {
                    const response = await getTimesheet(this.$route.params.ID, startDate, endDate)
                    this.workingTime = response;
                } else {
                    const response = await getTimesheet(this.$route.params.ID, null, null, false)
                    if (response.length > 0) {
                        this.showTimeTable = true;
                    }
                    this.workingTime = response;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async loadCourierOrders() {
            try {
                const res = await getCourierOrder(this.$route.params.ID)
                this.courierOrders = res;
                this.courierOrders.offset = 20;
            } catch (error) {
                console.log(error);
            }
        },
        async loadMoreCustomerOrders() {
            try {
                this.customerOrders.offset += 20;
                const res = await getOrdersForUser(this.$route.params.ID, this.customerOrders.offset);
                this.order_list = this.order_list.concat(res.results);
                this.customerOrders.next = !!res.next;
            } catch (error) {
                console.log(error);
            }
        },
        async changePhoto(event) {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                if (event.target.files.length === 0) {
                    return;
                }
                this.$store.commit('ui/SET_PRELOADER', true);

                this.user.avatar = URL.createObjectURL(event.target.files[0]);

            } catch (error) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки фото', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        formatDate(value, type) {
            return date(new Date(value), type);
        },
        formatTime(value, type) {
            return date(new Date(`1970-01-01T${value}Z`), type);
        },
        formatDuration(value) {
            let duration = value.replace(" days", "д").split(" ");
            let days = "";
            let hours = "";
            let minutes = "";
            let time = duration[0];
            if (duration.length == 2) {
                days = duration[0];
                time = duration[1];
            }
            time = time.split(":");
            hours = parseInt(time[0]) + "ч ";
            minutes = parseInt(time[1])+ "м ";

            return days + " " + hours + minutes;
        },
        async unbind(orderID, index) {
            this.$store.dispatch('ui/checkUserEditPermission');
            await unbindOrder({ courier: null }, orderID);
            this.courierOrders.results.splice(index, 1);
        },
        async exit() {
            try {
                this.showExitModal = false;
                await this.$store.dispatch('auth/LOGOUT');
                location.href = '/'
            } catch (error) {
                console.log(error);
            }
        },
        formatNumber(number) {
            return number === 0 ? 0 : numberFormat(number);
        },
        async loadMoreCourierOrders() {
            if (this.courierOrders.next) {
                const res = await getNextCourierOrder(this.$route.params.ID, this.courierOrders.offset);
                this.courierOrders.results = this.courierOrders.results.concat(res.results);
                this.courierOrders.next = res.next;
                this.courierOrders.offset += 20;
            }
        },
        goToLink(link) {
            window.open(link, '_blank');
        },
        moment
    }
};
</script>

<template>
    <button
        type="button"
        class="button-custom"
        :class="{
            'button-custom--bordered': bordered,
            'button-custom--text': text,
            'button-custom--compact': compact,
            'width-100': block
        }"
        :disabled="disabled"
        v-on="$listeners"
    >
        <Icon v-if="iconLeft" class="button-custom__icon button-custom__icon--left" :class="{'mr-0': icon}">
            {{ iconLeft }}
        </Icon>
        <slot v-if="!icon" />
        <Icon v-if="iconRight" class="button-custom__icon button-custom__icon--right" :class="{'ml-0': icon}">
            {{ iconRight }}
        </Icon>
    </button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        bordered: { type: Boolean, default: false },
        text: { type: Boolean, default: false },
        compact: { type: Boolean, default: false },
        block: { type: Boolean, default: false },
        iconLeft: { type: String, default: '' },
        iconRight: { type: String, default: '' },
        icon: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false }
    }
}
</script>


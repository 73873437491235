<template>
    <header class="header-wrap" :class="{'header-wrap--listing': forListing}">
        <button
            v-if="!forListing && leftBtn && showLeftBtn"
            type="button"
            class="header__button"
            @click.stop.prevent="$emit('click:left', $event)"
        >
            <Icon>{{ leftBtn }}</Icon>
        </button>
        <button
            v-if="forListing"
            type="button"
            class="header__button header__button--menu"
            @click.stop.prevent="openNavigation"
        >
            <Icon>menu</Icon>
        </button>

        <h1 class="header__title" :class="{'header__title--left-btn': leftBtn && showLeftBtn}">
            {{ title }}
        </h1>

        <div class="header__right-buttons-wrap">
            <slot />
            <button
                v-if="search"
                type="button"
                class="header__button"
                @click.stop.prevent="$emit('click:search', $event)"
            >
                <Icon>search</Icon>
            </button>
            <button
                v-if="filter"
                type="button"
                class="header__button"
                :class="{'header__button--indicated': filterIndicator}"
                @click.stop.prevent="$emit('click:filter', $event)"
            >
                <Icon>filter</Icon>
            </button>
            <button
                v-if="showSaveBtn"
                type="button"
                class="header__button"
                :disabled="preloader"
                @click.stop.prevent="$emit('click:save', $event)"
            >
                <Icon>check</Icon>
            </button>
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Header',
    props: {
        title: { type: String, default: '' },
        leftBtn: { type: String, default: '' },
        showLeftBtn: { type: [String, Number, Boolean, Object, Array], default: true },
        showSaveBtn: { type: [String, Number, Boolean, Object, Array], default: true },
        forListing: { type: Boolean, default: false },
        filter: { type: Boolean, default: false },
        filterIndicator: { type: Boolean, default: false },
        search: { type: Boolean, default: false },
    },
    computed: {
        ...mapGetters({
            preloader: 'ui/preloader',
        }),
    },
    methods: {
        openNavigation() {
            this.$store.commit('ui/TOGGLE_SIDEBAR')
            this.$store.dispatch('ui/toggle_opened_modals_count', 'open');
        },
    }
}
</script>


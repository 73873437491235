<template>
    <div class="fast-filters__wrapper">
        <div class="fast-filters__list">
            <div v-for="item in filters" :key="item.id" class="fast-filters__list-item" :class="{'active': item.active}">
                <span @click="toggleFilter($event, item)">
                    {{ item.name }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FastFilters',
    props: {
        filters: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        toggleFilter(event, item) {
            this.$emit('change', item);
        }
    }
}
</script>

<style lang="sass">
    .fast-filters__wrapper
        overflow: hidden
        -webkit-overflow-scrolling: touch
        scrollbar-width: none
        padding: 8px 0
        margin: 0 -16px 24px
        -webkit-appearance: none
        @media screen and (max-width: 699px)
            margin: 0 -16px 16px
        @media screen and (max-width: 374px)
            margin: 0 -8px 16px
        &::-webkit-scrollbar
            display: none
            height: 0
            -webkit-appearance: none
        &::-webkit-scrollbar-thumb
            display: none
            -webkit-appearance: none

    .fast-filters__list
        overflow: hidden
        overflow-x: auto
        display: flex
        align-items: center
        width: 100%
        scrollbar-width: none
        -webkit-appearance: none
        &::-webkit-scrollbar
            display: none
            height: 0
            -webkit-appearance: none
        &::-webkit-scrollbar-thumb
            display: none
            -webkit-appearance: none

    .fast-filters__list-item
        cursor: pointer
        &:first-child
            padding-left: 12px
            @media screen and (max-width: 374px)
                padding-left: 4px
        &:last-child
            padding-right: 12px
            @media screen and (max-width: 374px)
                padding-right: 4px
        span
            display: inline-block
            padding: 0 12px
            background: #fff
            border-radius: 50px
            font-size: 15px
            line-height: 31px
            height: 32px
            margin: 0 4px
            white-space: nowrap
            transition: background .3s ease
            &:hover
                background: #d8d8d8
        &.active
            span
                background: #2B77F5!important
                color: #fff
</style>

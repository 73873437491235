<template>
    <div class="side-filter__content">
        <div class="side-filter__content-group-list">
            <div class="side-filter__content-group">
                <v-menu ref="date" offset-y :close-on-content-click="false" transition="scale-transition" max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                        <InputCustom
                            :value="datepickerFormatted"
                            label="Дата"
                            placeholder="Выберите период"
                            left-icon="calendar"
                            clearable
                            readonly
                            without-preloader
                            v-on="on"
                            @click:clear="datepicker = [], setFilterDate()"
                        />
                    </template>
                    <v-date-picker
                        v-model="datepicker"
                        no-title
                        range
                        first-day-of-week="1"
                        @input="() => {
                            if (datepicker[0] && datepicker[1]) {
                                $refs.date.save(datepicker);
                                setFilterDate();
                            }
                        }"
                    />
                </v-menu>
            </div>
            <div class="side-filter__content-group">
                <InputSelect
                    :value="filters.group"
                    :options="[{ slug: null, name: 'Все' } ,...groups.filter(i => i.slug !== 'customers')]"
                    label="Группа"
                    input-value="slug"
                    icon="user"
                    without-preloader
                    @input="filterList('group', $event)"
                />
            </div>
            <div class="side-filter__content-group">
                <Button
                    block compact text
                    icon-left="file-minus"
                    @click.stop="isShowDownloadModal = true"
                >
                    Скачать зарплаты в xls
                </Button>
            </div>
        </div>

        <button class="side-filter__clear-btn" @click.prevent="clearFilter">
            Очистить
        </button>

        <MiniModal
            v-model="isShowDownloadModal"
            title="Вы уверены, что хотите скачать файл?"
            additional-btn-text="Отмена"
            main-btn-text="Скачать"
            @additionalBtnClick="isShowDownloadModal = false"
            @mainBtnClick="downloadSalaries"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import moment from 'moment'

import { downloadSalariesFile } from '@/api/django'

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'SalariesFilter',
    components: { MiniModal },
    data() {
        return {
            datepicker: [],
            isShowDownloadModal: false
        };
    },
    computed: {
        ...mapGetters({
            width: 'ui/width',
            filters: 'salaries/filters',
            groups: 'users/groups',
            activeFilters: 'salaries/activeFilters',
        }),
        group() {
            return JSON.parse(localStorage.getItem('user')).groups[0];
        },
        datepickerFormatted() {
            if (this.datepicker.length > 1) {
                return this.datepicker.map(i => this.moment(i).format('DD.MM.YYYY')).join(' - ')
            }
            return ''
        }
    },
    mounted() {
        if (this.filters.date_from && this.filters.date_to) {
            this.datepicker = [
                this.filters.date_from.split('.').reverse().join('-'),
                this.filters.date_to.split('.').reverse().join('-')
            ]
        }
    },
    methods: {
        async filterList(field, value) {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await this.$store.dispatch('salaries/GET_SALARIES', { field, value });
                window.scrollTo(0, 0);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async setFilterDate() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                this.datepicker.sort();
                let dateFrom = this.datepicker[0] ? this.moment(this.datepicker[0]).format('DD.MM.YYYY') : null
                let dateTo = this.datepicker[1] ? this.moment(this.datepicker[1]).format('DD.MM.YYYY') : null

                this.$store.commit('salaries/SET_FILTER', { field: 'date_from', value: dateFrom })
                this.$store.commit('salaries/SET_FILTER', { field: 'date_to', value: dateTo })

                await this.$store.dispatch('salaries/GET_SALARIES');
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async clearFilter() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);

                this.datepicker = [];
                this.$store.commit('salaries/CLEAR_FILTERS_STATE');
                this.$store.commit('ui/CLOSE_FILTER', 'salaries-filter');

                await this.$store.dispatch('salaries/GET_SALARIES');

                window.scrollTo(0, 0);
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async downloadSalaries() {
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                this.isShowDownloadModal = false;

                let filters = JSON.parse(JSON.stringify(this.filters))
                delete filters.limit
                delete filters.offset

                const file = await downloadSalariesFile(filters);
                const fileUrl = window.URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                link.download = 'Зарплаты.xlsx';
                link.click();
            } catch (e) {
                console.log(e)
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка загрузки файла', type: 'error' })
            } finally {
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        moment
    },
};
</script>

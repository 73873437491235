<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <Header
                :title="$route.params.id ? category.is_name || category.name : 'Новая категория'"
                left-btn="left-big"
                :show-save-btn="!authUser.read_only"
                @click:left="$router.push('/goods')"
                @click:save="saveItem"
            />
            <div class="tabs__list">
                <TabItem :active="activeTab === 0" @click="activeTab = 0">
                    Описание
                </TabItem>
                <TabItem :active="activeTab === 1" @click="activeTab = 1">
                    Теги
                </TabItem>
            </div>
            <div class="page-container page-container--with-tabs">
                <div v-show="activeTab === 0" class="tabs__content">
                    <div class="page-columns">
                        <div class="page-big-column">
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">Общее</h2>
                                <Row>
                                    <Col>
                                        <InputCustom
                                            v-model="category.name"
                                            label="Название"
                                            :error="error.name ? error.name[0] : ''"
                                        />
                                    </Col>
                                    <Col>
                                        <InputCustom
                                            v-model="category.is_name"
                                            label="Полное название"
                                            :error="error.is_name ? error.is_name[0] : ''"
                                        />
                                    </Col>
                                    <Col>
                                        <InputSelect
                                            v-model="category.type"
                                            :options="productTypes"
                                            label="Тип товаров"
                                            input-value="slug"
                                            :error="error.type ? error.type[0] : ''"
                                        />
                                    </Col>
                                    <Col>
                                        <InputSelect
                                            v-model="category.parent_category"
                                            :options="parentsList"
                                            label="Родительская категория"
                                            :error="error.parent_category ? error.parent_category[0] : ''"
                                        />
                                    </Col>
                                    <Col>
                                        <InputCustom
                                            v-model="category.sort"
                                            label="Сортировка"
                                            :error="error.sort ? error.sort[0] : ''"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div class="page__info-group">
                                <h2 class="page__info-group-title">SEO</h2>
                                <Row>
                                    <Col>
                                        <InputCustom
                                            v-model="category.meta_h1"
                                            label="H1"
                                            :error="error.meta_h1 ? error.meta_h1[0] : ''"
                                        />
                                    </Col>
                                    <Col>
                                        <TextareaCustom
                                            v-model="category.meta_title"
                                            label="Title"
                                            :error="error.meta_title ? error.meta_title[0] : ''"
                                        />
                                    </Col>
                                    <Col>
                                        <TextareaCustom
                                            v-model="category.meta_description"
                                            label="Description"
                                            :error="error.meta_description ? error.meta_description[0] : ''"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div class="page-small-column">
                            <div class="checkbox-group">
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="category.is_active"
                                        label="Выводить на сайте"
                                        @change="category.is_active = !category.is_active"
                                    />
                                </div>
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="category.display"
                                        label="Выводить на главной"
                                        :disabled="!category.is_active"
                                        @change="category.display = !category.display"
                                    />
                                </div>
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="category.display_in_menu"
                                        label="Выводить в меню"
                                        :disabled="!category.is_active"
                                        @change="category.display_in_menu = !category.display_in_menu"
                                    />
                                </div>
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="category.highlighted_category"
                                        label="Выделенная категория"
                                        :disabled="!category.is_active"
                                        @change="category.highlighted_category = !category.highlighted_category"
                                    />
                                </div>
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="category.is_additional"
                                        label="Добавьте к заказу"
                                        @change="category.is_additional = !category.is_additional"
                                    />
                                </div>
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="category.for_showcase"
                                        label="Для витрины"
                                        @change="category.for_showcase = !category.for_showcase"
                                    />
                                </div>
                                <div class="checkbox-group__item">
                                    <Checkbox
                                        :value="category.is_coffee"
                                        label="Для кофейни"
                                        @change="category.is_coffee = !category.is_coffee"
                                    />
                                </div>
                            </div>
                            <button
                                v-if="$route.params.id"
                                type="button"
                                class="action-btn"
                                @click.prevent="showConfirmDeleteCategory = true"
                            >
                                <Icon color="#0D63F3">trash</Icon>
                                Удалить категорию
                            </button>
                        </div>
                    </div>
                </div>
                <div v-show="activeTab === 1" class="tabs__content">
                    <Row>
                        <Col cols="12">
                            <div v-for="item in tags" :key="item.id" class="tags-manage__category-item">
                                <div class="tags-manage__category-item-title">
                                    {{ item.name }}
                                </div>
                                <div class="tags-manage__category-tags">
                                    <button
                                        v-for="tag in item.childrens"
                                        :key="tag.id"
                                        type="button"
                                        class="tags-manage__category-tag-item"
                                        :class="{
                                            'colors': tag.color || tag.multi_color,
                                            'active': category.categories_tags.find(item => item.id === tag.id)
                                        }"
                                        :style="getBackgroundColorForFilter(tag)"
                                        @click.prevent="toggleTagActive(tag)"
                                    >
                                        {{ !tag.color && !tag.multi_color ? tag.name : '' }}
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
        <MiniModal
            v-model="showConfirmDeleteCategory"
            :title="`Удалить категорию ${ category.name }?`"
            additional-btn-text="Отмена"
            main-btn-text="Удалить"
            @additionalBtnClick="showConfirmDeleteCategory = false"
            @mainBtnClick="deleteCategory"
        />
    </section>
</template>

<script>

import { mapGetters } from 'vuex';
import {
    getAllCategories, getCategoryID, postCategory, putCategoryID, deleteCategoryID, fetchTypesForProducts
} from '@/api/django';

import MiniModal from "@/components/modal/MiniModal";

export default {
    name: 'TheProductCategoiesCreate',
    metaInfo() {
        return { title: this.$route.params.id ? `Категория ${this.category.name}` : 'Новая категория' }
    },
    components: { MiniModal },
    data() {
        return {
            category: {
                name: '',
                is_name: '',
                is_active: true,
                display: true,
                display_in_menu: true,
                is_additional: false,
                is_coffee: false,
                highlighted_category: false,
                parent_category: null,
                meta_h1: '',
                meta_title: '',
                meta_description: '',
                type: null,
                is_equipment: false,
                sort: 500,
                for_showcase: false,
                categories_tags: []
            },
            parentsList: [
                { id: null, name: 'Нет' }
            ],
            productTypes: [],
            error: {},
            activeTab: 0,
            showConfirmDeleteCategory: false
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
            width: 'ui/width',
            preloader: 'ui/preloader',
            tags: 'tags/categories',
        })
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            const allCategories = await getAllCategories();
            allCategories.forEach((item) => {
                this.parentsList.push(item);
                if (item.childrens && item.childrens.length) {
                    item.childrens.forEach((child) => {
                        this.parentsList.push(child);
                    })
                }
            });
            const { type_product } = await fetchTypesForProducts();
            this.productTypes = type_product;

            if (this.$route.params.id) {
                const res = await getCategoryID(this.$route.params.id);
                this.category = res;
            }

            if (!this.tags.length) {
                this.$store.dispatch('tags/getTags');
            }
        } catch (error) {
            console.log(error);
            this.$store.commit('ui/add_item_in_system_notifications', { message: 'При загрузке данных произошла ошибка', type: 'error' })
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    methods: {
        async saveItem() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                if (!this.category.is_name) {
                    this.category.is_name = this.category.name;
                }

                const data = {
                    ...this.category,
                    categories_tags: this.category.categories_tags.map((i) => i.id)
                }

                if (!this.$route.params.id) {
                    await postCategory(data);
                } else {
                    await putCategoryID(this.$route.params.id, data);
                }

                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Категория успешно сохранена', type: 'success' })
                this.$store.dispatch('data/LOAD_CATEGORY');
                this.$router.push('/goods');
            } catch (error) {
                console.log(error);
                this.error = error.data;
                if (error.data.for_showcase) {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Может быть только 1 категория для витрины', type: 'error' })
                } else {
                    this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка создания', type: 'error' })
                }
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        async deleteCategory() {
            this.$store.dispatch('ui/checkUserEditPermission');
            try {
                this.$store.commit('ui/SET_PRELOADER', true);
                await deleteCategoryID(this.$route.params.id);
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Категория удалена', type: 'success' })
                this.$store.dispatch('data/LOAD_CATEGORY');
                this.$router.push('/goods');
            } catch (error) {
                this.$store.commit('ui/add_item_in_system_notifications', { message: 'Ошибка удаления категории', type: 'error' })
                this.$store.commit('ui/SET_PRELOADER', false);
            }
        },
        toggleTagActive(tag) {
            if (this.category.categories_tags.find((item) => item.id === tag.id)) {
                this.category.categories_tags = this.category.categories_tags.filter((item) => item.id !== tag.id);
            } else {
                this.category.categories_tags.push(tag);
            }
        },
        getBackgroundColorForFilter(tag) {
            if (tag.color || tag.multi_color) {
                let str = 'background: ';
                if (tag.multi_color) {
                    str += 'conic-gradient(from 180deg at 50% 50%, #20FA1B -20.63deg, #FF0000 20.63deg, #FF9C06 90deg, #FF1CC2 163.12deg, #8155FF 195deg, #0A40FF 271.87deg, #20FA1B 339.37deg, #FF0000 380.62deg)'
                } else {
                    str += tag.color;
                }
                return str;
            }
            return '';
        },
    }
};
</script>

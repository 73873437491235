<template>
    <section :class="['page-section', $route.name]">
        <div class="page-wrap">
            <template v-if="tutorialStep !== 8">
                <Header title="Новый заказ" left-btn="left-big" />
                <div class="page-container page-container--with-fab">
                    <div class="tabs__content">
                        <div class="page-columns">
                            <div class="page-big-column">
                                <div class="page__info-group">
                                    <div id="order-tutorial__step-1">
                                        <h2 class="page__info-group-title">Тип заказа</h2>
                                        <HorizontalPicker
                                            v-model="order.pickup"
                                            :items="[{value: false, name: 'Доставка'}, {value: true, name: 'Самовывоз'}]"
                                        />
                                    </div>
                                    <template v-if="order.pickup !== null && order.pickup !== undefined">
                                        <div id="order-tutorial__step-4">
                                            <Row class="mt-3">
                                                <Col>
                                                    <TextareaCustom
                                                        v-model="order.receiver_address"
                                                        label="Адрес доставки c городом"
                                                        readonly
                                                    />
                                                    <div
                                                        class="d-flex justify-center px-4 py-2 mt-2"
                                                        style="background: #E1ECFE;border-radius: 8px;"
                                                    >
                                                    <span style="font-weight: 500;font-size: 12px;line-height: 17px;color: #0D63F3;">
                                                        {{ numberFormat(+order.delivery_cost) }} ₽ доставка
                                                    </span>
                                                    </div>

                                                    <div class="d-flex justify-end ml-a mt-4 mb-6">
                                                        <Checkbox
                                                            v-model="order.address_is_hidden"
                                                            label="Заказчик не знает адрес"
                                                            label-align="right"
                                                            inline
                                                            readonly
                                                        />
                                                    </div>
                                                </Col>
                                                <Col :cols="width > 1280 ? 3 : 6">
                                                    <InputCustom
                                                        value=""
                                                        label="Дата заказа"
                                                        left-icon="calendar"
                                                        readonly
                                                    />
                                                </Col>
                                                <Col :cols="width > 1280 ? 3 : 6">
                                                    <InputCustom
                                                        value=""
                                                        label="Время"
                                                        left-icon="clock"
                                                        readonly
                                                    />
                                                </Col>
                                                <Col :cols="width > 1280 ? 6 : 12">
                                                    <InputCustom
                                                        v-model="order.order_reason.name"
                                                        label="Повод"
                                                        left-icon="party"
                                                    />
                                                </Col>
                                            </Row>
                                            <div class="d-flex justify-end ml-a mt-4 mb-6">
                                                <Checkbox
                                                    v-model="order.order_reason.is_remind"
                                                    label="Напоминать"
                                                    label-align="right"
                                                    inline
                                                />
                                            </div>
                                        </div>
                                        <Row>
                                            <Col>
                                                <TextareaCustom
                                                    v-model="order.additional_info"
                                                    label="Доп. информация"
                                                />
                                            </Col>
                                            <Col>
                                                <TextareaCustom
                                                    v-model="order.manager_comment"
                                                    label="Комментарий менеджера"
                                                    messages="Текст виден только сотрудникам"
                                                />
                                            </Col>
                                        </Row>
                                    </template>
                                </div>
                                <template v-if="order.pickup !== null && order.pickup !== undefined">
                                    <div id="order-tutorial__step-5" class="page__info-group">
                                        <h2 class="page__info-group-title">Заказчик</h2>
                                        <Row>
                                            <Col :cols="width > 1024 ? 6 : 12">
                                                <InputCustom
                                                    v-model="order.user.phone"
                                                    label="Телефон"
                                                    left-icon="phone"
                                                    clearable
                                                    disabled-left-icon
                                                />
                                            </Col>
                                            <Col :cols="width > 1024 ? 6 : 12">
                                                <InputCustom
                                                    v-model="order.user.first_name"
                                                    label="Имя"
                                                    clearable
                                                />
                                            </Col>
                                            <div class="d-flex align-center mt-3 width-100">
                                                <InputCustom
                                                    v-model.number="bonuses.value"
                                                    type="number"
                                                    label="Оплата бонусами клиента"
                                                    clearable
                                                    :disabled="bonuses.readonly"
                                                    style="flex: 1 1 auto; margin-right: 16px"
                                                />
                                                <Checkbox
                                                    :value="bonuses.readonly"
                                                    label="Бонусы 1143 ₽"
                                                    label-align="right"
                                                    inline
                                                    style="flex: 1 0 auto"
                                                />
                                            </div>
                                        </Row>
                                    </div>
                                    <div class="page__info-group">
                                        <h2 class="page__info-group-title">Получатель</h2>
                                        <Row>
                                            <Col :cols="width > 1024 ? 6 : 12">
                                                <InputCustom
                                                    v-model="order.receiver_phone"
                                                    label="Телефон"
                                                    left-icon="phone"
                                                    clearable
                                                    :disabled="order.user_is_receiver"
                                                />
                                            </Col>
                                            <Col :cols="width > 1024 ? 6 : 12">
                                                <InputCustom
                                                    v-model="order.receiver_name"
                                                    label="Имя"
                                                    clearable
                                                    :disabled="order.user_is_receiver"
                                                />
                                            </Col>
                                        </Row>
                                        <div class="d-flex justify-end ml-a mt-4">
                                            <Checkbox
                                                :value="!order.user_is_receiver"
                                                label="Другой получатель"
                                                label-align="right"
                                                inline
                                            />
                                        </div>
                                        <div class="d-flex justify-end ml-a mt-4">
                                            <Checkbox
                                                v-model="order.is_anonymous_order"
                                                label="Анонимный заказ"
                                                label-align="right"
                                                inline
                                            />
                                        </div>
                                        <div v-if="!order.pickup" class="d-flex justify-end ml-a mt-4">
                                            <Checkbox
                                                v-model="order.take_photo_with_receiver"
                                                label="Сделать фото с получателем"
                                                label-align="right"
                                                inline
                                                readonly
                                            />
                                        </div>
                                    </div>
                                    <div class="page__info-group">
                                        <h2 class="page__info-group-title">Магазин</h2>
                                        <InputSelect
                                            v-model="order.shop"
                                            :options="[{ id: null, address: 'Не выбран' }]"
                                            input-text="address"
                                            readonly
                                        />
                                    </div>
                                    <div class="page__info-group">
                                        <h2 class="page__info-group-title">Курьер</h2>
                                        <InputSelect
                                            v-model="order.courier"
                                            :options="[{ id: null, first_name: 'Не выбран' }]"
                                            input-text="first_name"
                                            searchable
                                            readonly
                                        />
                                    </div>
                                    <div class="page__info-group">
                                        <div class="price-list__wrap">
                                            <div class="price-list__item">
                                                <div class="price-list__item-label">Итого</div>
                                                <div class="price-list__item-value">
                                                    {{ numberFormat(+order.final_cost) }} ₽
                                                </div>
                                            </div>
                                            <div class="price-list__item">
                                                <div class="price-list__item-label">Оплачено заказчиком:</div>
                                                <div class="price-list__item-value">
                                                    0 ₽
                                                </div>
                                            </div>
                                            <div class="price-list__item">
                                                <div class="price-list__item-label">Доставка:</div>
                                                <div class="price-list__item-value d-flex">
                                                    <Button class="mr-1" compact text>
                                                        <Icon>edit-2</Icon>
                                                    </Button>
                                                    <span>{{ numberFormat(+order.delivery_cost) }} ₽</span>
                                                </div>
                                            </div>
                                            <div class="price-list__notification">
                                               3500 ₽ до бесплатной доставки
                                            </div>
                                            <div class="price-list__item price-list__item--red">
                                                <div class="price-list__item-label">
                                                    Бонусов будет потрачено:
                                                </div>
                                                <div class="price-list__item-value">
                                                    -0 ₽
                                                </div>
                                            </div>
                                            <div class="price-list__item">
                                                <div class="price-list__item-label">
                                                    Бонусов начислено:
                                                </div>
                                                <div class="price-list__item-value">
                                                    {{ numberFormat(+order.number_of_bonus) }} ₽
                                                    ({{ addedBonusesPercentValue }}%)
                                                </div>
                                            </div>
                                            <div v-if="order.number_of_bonus" class="price-list__notification">
                                                1 000₽ до бонусов 7%
                                            </div>
                                            <div class="price-list__item">
                                                <div class="price-list__item-label">
                                                    Себестоимость:
                                                </div>
                                                <div class="price-list__item-value">
                                                    {{ numberFormat(+order.total_prime_cost) }} ₽
                                                </div>
                                            </div>
                                            <Row id="order-tutorial__step-7" class="mt-3">
                                                <Col :cols="width > 1280 ? 6 : 12">
                                                    <InputSelect
                                                        v-model="order.payment_method"
                                                        :options="[
                                                            { id: 1, name: 'Сбербанк онлайн' }
                                                        ]"
                                                        placeholder="Способ оплаты"
                                                    />
                                                </Col>
                                                <Col :cols="width > 1280 ? 6 : 12">
                                                    <InputSelect
                                                        v-model="order.source"
                                                        :options="[]"
                                                        placeholder="Источник"
                                                    />
                                                </Col>
                                            </Row>

                                            <div class="mt-4" :class="{'d-flex justify-end ml-a': width > 1280}">
                                                <Checkbox
                                                    :value="order.cashbox_viewed"
                                                    label="Распечатан чек"
                                                    :label-align="width > 1280 ? 'right' : ''"
                                                    :inline="width > 1280"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="page-small-column page-small-column--mobile-top">
                                <div class="order__product-list">
                                    <div class="order__product-list-title-wrap">
                                        <div class="order__product-list-title">
                                            Состав <span>{{ order.order_list.length }}</span>
                                        </div>
                                        <div class="order__product-list-title-triggers">
                                            <Button compact text>
                                                <Icon color="#0D63F3">
                                                    list-ul
                                                </Icon>
                                            </Button>
                                            <Button compact text>
                                                <Icon color="#8AACCD">
                                                    grid
                                                </Icon>
                                            </Button>
                                        </div>
                                    </div>
                                    <OrderProductCard
                                        v-for="item in order.order_list"
                                        :key="item.vendor_code"
                                        :product="item"
                                        :order="order"
                                        size="mini"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OrderBottomBar
                    v-if="order.pickup !== null && order.pickup !== undefined"
                    :order-cost="numberFormat(+order.final_cost)"
                    :disabled-sale="!order.order_list.length"
                    @click:catalog="$store.commit('ui/OPEN_MODAL', 'modal-goods')"
                />
                <UniversalModal v-model="isProductsModalOpened" size="full">
                    <GoodsModal />
                </UniversalModal>
            </template>
            <template v-else>
                <Header
                    title="Заказы"
                    for-listing
                    :show-save-btn="false"
                    filter
                    :search="width <= 699"
                />
                <div class="page-container page-container--listing page-container--with-fab">
                    <Search value="" class="mb-0" />
                    <FastFilters :filters="fastFilters" />
                    <div v-show="!preloader" class="listing-table__wrap">
                        <div class="listing-table listing-table--orders">
                            <div class="listing-table__row listing-table__row--head">
                                <div class="listing-table__col" style="width: 112px;flex: 0 0 auto;"  />
                                <div class="listing-table__col pl-0" :style="`width: calc((100% - 362px) / 4 * 0.5);`">
                                    № заказа
                                </div>
                                <div class="listing-table__col" :style="`width: calc((100% - 362px) / 4 * 0.8);`">
                                    Дата доставки
                                </div>
                                <div class="listing-table__col" :style="`width: calc((100% - 362px) / 4 * 1.15);`">
                                    Адрес
                                </div>
                                <div class="listing-table__col flex-shrink-0" :style="`width: calc((100% - 362px) / 4 * 0.4); min-width: 120px;`">
                                    Оплата
                                </div>
                                <div class="listing-table__col" :style="`width: 210px;flex: 0 0 auto;`">
                                    Статус
                                </div>
                            </div>
                            <div
                                v-for="(item, index) in ordersList"
                                :key="item.id"
                                :id="!index ? 'order-tutorial__step-8' : ''"
                                class="listing-table__row"
                            >
                                <div class="listing-table__col listing-table__col--image listing-table__col--mobile-inline">
                                    <div class="listing-table__image">
                                        <img :src="item.image" alt="">
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :class="{'pt-0 mr-0': width <= 1024}"
                                    :style="width > 1024 ? `width: calc((100% - 362px) / 4 * 0.5)` : 'width: calc(100% - 68px)'"
                                >
                                    <div class="text-cut">
                                        <div class="mb-1">
                                            {{ item.id }}
                                        </div>
                                        <span>
                                            {{ item.user_name }}
                                        </span>
                                    </div>
                                </div>

                                <div v-if="width <= 1024" class="listing-table__col listing-table__col--mobile-bordered pb-0" />

                                <div class="listing-table__col" :style="width > 1024 ? `width: calc((100% - 362px) / 4 * 0.8)` : ''">
                                    <div :class="{'d-flex align-center': width <= 1024}">
                                        <span :class="{'mr-1': width <= 1024}">
                                            {{ item.delivery_date }}
                                        </span>
                                        <br v-if="width > 1024">
                                        <span>
                                            {{ item.pickup ? 'Ближайшее' : 'Согласовать с получателем' }}
                                        </span>
                                    </div>
                                </div>
                                <div class="listing-table__col" :style="width > 1024 ? `width: calc((100% - 362px) / 4 * 1.15)` : ''">
                                    <div>
                                        <div class="d-flex align-center">
                                            <Icon color="#8AACCD" size="16" class="mr-2">store</Icon>
                                            <span style="display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;">
                                            {{ item.shop }}
                                        </span>
                                        </div>
                                        <div v-if="!item.pickup" class="d-flex align-center mt-1">
                                            <Icon color="#8AACCD" size="16" class="mr-2">map</Icon>
                                            <span style="display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;overflow: hidden;">
                                            {{ item.receiver_address }}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-bordered flex-shrink-0"
                                    :style="width > 1024 ? `width: calc((100% - 362px) / 4 * 0.4); min-width: 120px;` : ''"
                                >
                                    <div>
                                        <div class="d-flex align-center">
                                            <Icon :color="item.is_paid ? '#0BD58A' : '#F00C29'" size="16" class="mr-1">
                                                {{ item.is_paid ? 'check' : 'close' }}
                                            </Icon>
                                            {{ item.price }} ₽
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? `width: 210px;flex: 0 0 auto;` : ''"
                                    @click.stop.prevent
                                >
                                    <StatusSelect
                                        :value="item.status"
                                        :options="[
                                        { id: 1, name: 'Создан', color: '#666666', icon: 'lightning' },
                                        { id: 2, name: 'Оплачен', color: '#b66637', icon: 'rub' }
                                    ]"
                                    />
                                </div>
                                <div
                                    v-if="width <= 1024"
                                    class="listing-table__col listing-table__col--mobile-inline"
                                    :style="width > 1024 ? 'width: 64px; flex: 0 0 auto;' : 'margin-left: auto;'"
                                >
                                    <div class="d-flex justify-center">
                                        <Icon color="#0D63F3">camera</Icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div class="order-tutorial__wrap">
            <div
                ref="transparentBlock"
                class="order-tutorial__transparent-block"
                :class="{'animated': isAnimatedTransparentBlock}"
                :style="`padding: ${transparentBlockOffset}px`"
            >
                <div class="order-tutorial__instruction" :class="`order-tutorial__instruction--step-${tutorialStep}`">
                    <div class="order-tutorial__instruction-title">
                        {{ tutorialText[tutorialStep - 1].title }}
                    </div>
                    <div class="order-tutorial__instruction-text">
                        {{ tutorialText[tutorialStep - 1].text }}
                    </div>
                    <div class="order-tutorial__instruction-buttons">
                        <Button @click="nextStep">
                            {{ tutorialStep !== 8 ? 'Далее' : 'Завершить обучение' }}
                        </Button>
                        <button
                            v-if="tutorialStep !== 8"
                            type="button"
                            class="order-tutorial__instruction-button-close"
                            @click.prevent="closeTutorial"
                        >
                            Завершить обучение
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import moment from "moment";

import { numberFormat } from "@/utils/format";

import OrderBottomBar from '@/components/app/orders/OrderBottomBar';
import OrderProductCard from '@/components/app/orders/OrderProductCard';
import FastFilters from "@/components/app/FastFilters";
import GoodsModal from '@/components/app/goods/modal/GoodsModal';

export default {
    name: 'TheOrderTutorial',
    metaInfo() {
        return { title: 'Обучение созданию заказа' }
    },
    components: {
        GoodsModal,
        FastFilters,
        OrderBottomBar, OrderProductCard,
    },
    data() {
        return {
            tutorialStep: 1,
            transparentBlockOffset: 16,
            isAnimatedTransparentBlock: false,
            tutorialText: [
                { title: 'Выбор типа заказа', text: 'Типами заказа могут быть "доставка" или "самовывоз". Следующим этапом соберем состав заказа.' },
                { title: 'Добавим состав заказа', text: 'Необходимо перейти в каталог, далее выбрать необходимые товары.' },
                { title: 'Выбор товаров', text: 'После выбора категории необходимо выбрать товары и их количество.' },
                { title: 'Заполняем данные о доставке', text: `1. Необходимо ввести адрес доставки с городом.
                2. Указать дату и время доставки.
                3. По желанию клиента уточнить повод.` },
                { title: 'Уточняем данные заказа', text: 'Номер телефона и имя заказчика для связи и накопления или списания бонусов. Так же данные получателя.' },
                { title: 'Применение скидки или промокода 🤩', text: 'Но важно помнить, что при списании бонусных баллов, невозможно применить промокод или скидку.' },
                { title: 'Выбор способа оплаты', text: 'Здесь выбираем подходящий для клиента способ оплаты и источник заказа из списка.' },
                { title: 'Ура 🥳 Заказ создан!', text: 'Вы можете кликнуть на него и посмотреть всю информацию внутри.' },
            ],
            order: {
                additional_info: "",
                address_is_hidden: false,
                courier: null,
                delivery_cost: 350,
                final_cost: 0,
                is_anonymous_order: false,
                manager_comment: "",
                number_of_bonus: 0,
                order_list: [],
                order_reason: {
                    name: '',
                    is_remind: false
                },
                payment_method: null,
                pickup: null,
                receiver_address: '',
                receiver_name: "",
                receiver_phone: "",
                shop: null,
                source: null,
                take_photo_with_receiver: false,
                total_prime_cost: 0,
                user: {
                    first_name: '',
                    phone: ''
                },
                user_is_receiver: false,
            },
            bonuses: {
                value: 0,
                readonly: false
            },
            fastFilters: [
                { id: 1, name: 'Сегодня', active: false, group: ['supervisor', 'administrators', 'managers', 'florist', 'courier'] },
                { id: 2, name: 'Завтра', active: false, group: ['supervisor', 'administrators', 'managers', 'florist', 'courier'] },
                { id: 3, name: 'Нет курьера', active: false, group: ['supervisor', 'administrators', 'managers', 'florist'] },
                { id: 4, name: 'Нет магазина', active: false, group: ['supervisor', 'administrators', 'managers'] },
                { id: 5, name: 'Нет фото флориста', active: false, group: ['supervisor', 'administrators', 'managers', 'florist'] },
            ],
            ordersList: [
                {
                    id: 89478, user_name: 'Мария', delivery_date: '22.08.2022', pickup: true, shop: 'Комарова 16',
                    receiver_address: '', is_paid: false, status: 1, image: 'https://storage.saastra.ru/main/__sized__/uploads/products/PAVELZHDAN00849-thumbnail-300x300-100.jpg', price: '1 500'
                },
                {
                    id: 89477, user_name: 'Иван', delivery_date: '21.08.2022', pickup: false, shop: 'Ленина 14',
                    receiver_address: 'г Омск, ул Химиков, д 20, кв 10', is_paid: true, status: 2, image: 'https://storage.saastra.ru/main/__sized__/uploads/products/PAVELZHDAN03864-thumbnail-800x800-100.jpg', price: '2 460'
                },
                {
                    id: 89476, user_name: 'Алексей', delivery_date: '21.08.2022', pickup: false, shop: 'Ленина 14',
                    receiver_address: 'г Омск, ул Мира, д 32, кв 18', is_paid: true, status: 2, image: 'https://storage.saastra.ru/main/__sized__/uploads/products/ZHP01595-thumbnail-800x800-100.jpg', price: '1 250'
                },
                {
                    id: 89475, user_name: 'Дмитрий', delivery_date: '21.08.2022', pickup: false, shop: 'Мира 26',
                    receiver_address: 'г Омск, ул Маркса, д 152, кв 60', is_paid: true, status: 2, image: 'https://storage.saastra.ru/main/__sized__/uploads/products/ZHP03310-thumbnail-800x800-100.jpg', price: '3200'
                },
            ],
            isProductsModalOpened: false
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'auth/user',
            width: 'ui/width',
            preloader: 'ui/preloader',
        }),
        authUserGroup() {
            return JSON.parse(localStorage.getItem("user")).groups[0];
        },
        addedBonusesPercentValue() {
            let userPaidValue = +this.order.final_cost;
            let addedBonusesValue = +this.order.number_of_bonus;

            let result = Math.ceil(addedBonusesValue * 100 / userPaidValue);
            return !isNaN(result) ? result : 0;
        },
    },
    created() {
        this.$store.commit('ui/SET_PRELOADER', true);
    },
    async mounted() {
        try {
            await this.$nextTick();
            this.transparentBlockOffset = this.width <= 699 ? 8 : 16;
            this.calculateTransparentBlockParams();
            window.addEventListener('scroll', this.calculateTransparentBlockParams, { passive: true });
            window.addEventListener('resize', this.calculateTransparentBlockParams, { passive: true });
        } catch (error) {
            console.log(error);
            this.$store.commit('ui/add_item_in_system_notifications', { message: 'При загрузке страницы произошла ошибка', type: 'error' })
        } finally {
            this.$store.commit('ui/SET_PRELOADER', false);
        }
    },
    beforeDestroy() {
        this.$store.commit('order/CLEAR_ORDER_LIST');
        window.removeEventListener('scroll', this.calculateTransparentBlockParams);
        window.removeEventListener('resize', this.calculateTransparentBlockParams);
    },
    methods: {
        calculateTransparentBlockParams() {
            let currentWatchedEl = document.getElementById(`order-tutorial__step-${this.tutorialStep}`);
            if (currentWatchedEl) {
                let topCoords = currentWatchedEl.getBoundingClientRect().top - this.transparentBlockOffset;
                let leftCoords = Math.max(currentWatchedEl.getBoundingClientRect().left - this.transparentBlockOffset, 4);

                this.$refs.transparentBlock.style.width = `${currentWatchedEl.clientWidth + this.transparentBlockOffset * 2}px`;
                this.$refs.transparentBlock.style.height = `${currentWatchedEl.clientHeight + this.transparentBlockOffset * 2}px`;
                this.$refs.transparentBlock.style.top = `${topCoords}px`;
                this.$refs.transparentBlock.style.left = `${leftCoords}px`;
            } else {
                this.$refs.transparentBlock.style.top = '9000px'
            }
        },
        async nextStep() {
            if (this.tutorialStep < 8) {
                this.tutorialStep++;
                await this.$nextTick();

                let pageNeedScroll = [4, 5, 7, 8].includes(this.tutorialStep);

                if (this.tutorialStep === 2) {
                    this.order.pickup = false;
                    this.transparentBlockOffset = 2;
                } else if (this.tutorialStep === 3) {
                    this.transparentBlockOffset = 16;
                    this.isProductsModalOpened = true;
                } else if (this.tutorialStep === 4) {
                    this.order.final_cost = 1500;
                    this.order.number_of_bonus = 75;
                    this.order.total_prime_cost = 750;
                    this.order.order_list = [
                        {
                            price: 1500,
                            name: "Композиция в стаканчике из нобилиса и диантусов",
                            vendor_code: 101050,
                            order_variant: "regular",
                            count: 1,
                            equipment_set: [],
                            image: {
                                original: "https://storage.saastra.ru/main/uploads/products/PAVELZHDAN00849.jpg",
                                preview: "https://storage.saastra.ru/main/__sized__/uploads/products/PAVELZHDAN00849-thumbnail-300x300-100.jpg",
                                product_card: "https://storage.saastra.ru/main/__sized__/uploads/products/PAVELZHDAN00849-thumbnail-1500x1500-100.jpg"
                            },
                        }
                    ]
                    this.isProductsModalOpened = false;
                } else if (this.tutorialStep === 6) {
                    this.order.payment_method = 1;
                    this.transparentBlockOffset = 2;
                } else if (this.tutorialStep === 7) {
                    this.transparentBlockOffset = 16;
                }

                await this.$nextTick();

                if (pageNeedScroll) {
                    let el = document.getElementById(`order-tutorial__step-${this.tutorialStep}`);
                    let top = el.getBoundingClientRect().top + window.pageYOffset;
                    if (this.tutorialStep !== 4 && this.tutorialStep !== 7) {
                        window.scrollTo(0, top - 64)
                    } else if (this.tutorialStep === 4) {
                        setTimeout(() => {
                            window.scrollTo(0, top - 64)
                        }, 310)
                    } else if (this.tutorialStep === 7) {
                        window.scrollTo(0, top - 300)
                    }
                }

                this.isAnimatedTransparentBlock = true;
                await this.$nextTick();

                if (this.tutorialStep !== 3) {
                    this.calculateTransparentBlockParams();
                } else {
                    setTimeout(() => {
                        this.calculateTransparentBlockParams();
                    }, 310)
                }

                setTimeout(() => {
                    this.isAnimatedTransparentBlock = false;
                }, 610)
            } else {
                this.closeTutorial();
            }
        },
        async closeTutorial() {
            await this.$store.dispatch('auth/PATCH_PROFILE_DATA', { is_tutorial_complete: true });
            this.$router.push('/orders');
        },
        moment,
        numberFormat
    }
};
</script>

/* eslint-disable */
import {
    getAllUsers,
    getUserGroups,
    getUserData,
    createUser,
    updateUser,
    deleteUser,
    changePasswordUser
} from '@/api/django';

const state = {
    user: {},
    users: [],
    filters: {
        limit: 20,
        offset: 0,
        group: '',
        search: '',
        is_staff: 'True'
    },
    activeFilters: [],
    next_page: false,
    busy: false,
    onlyPersonal: false,
    singleModalMode: false,
    filteredGroup: null,
    usersCount: 0,
    groups: [],
    fastFilter: [
        { id: 6, name: "Флорист", slug: "florist", active: false },
        { id: 7, name: "Курьер", slug: "courier", active: false },
        { id: 4, name: "Менеджер", slug: "managers", active: false },
        { id: 3, name: "Администратор", slug: "administrators", active: false },
        { id: 9, name: "Руководитель", slug: "supervisor", active: false },
    ],
    usersForNotification: [],
    userIDs: []
};

const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
    SET_USERS(state, users) {
        state.users = users.results;
        state.next_page = !!users.next;
        state.filters.offset = 20;
        state.usersCount = users.count;
    },
    SET_NEXT_USERS(state, { results, next }) {
        state.users.push(...results);
        state.next_page = next;
        state.busy = false;
        state.filters.offset += 20;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    SET_GROUPS(state, groups) {
        state.groups = groups;
    },
    SET_USERS_FOR_NOTIFICATION(state, list) {
        state.usersForNotification = list;
    },
    CLEAR(state) {
        state.users = [];
        state.next_page = false;
        state.busy = false;
        state.filters.offset = 0;
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            limit: 20,
            offset: 0,
            group: '',
            search: '',
            is_staff: 'True'
        };
        state.next_page = false;
        state.fastFilter = [
            { id: 6, name: "Флорист", slug: "florist", active: false },
            { id: 7, name: "Курьер", slug: "courier", active: false },
            { id: 4, name: "Менеджер", slug: "managers", active: false },
            { id: 3, name: "Администратор", slug: "administrators", active: false },
            { id: 9, name: "Руководитель", slug: "supervisor", active: false },
        ]
    },
    SET_ONLY_PERSONAL(state, bool) {
        state.onlyPersonal = bool;
    },
    SET_SINGLE_MODAL_MODE(state, bool) {
        state.singleModalMode = bool;
    },
    SET_FILTERED_GROUP(state, group) {
        state.filteredGroup = group;
    },
    CHANGE_FAST_FILTER(state, data) {
        if (!data) {
            state.fastFilter.forEach(item => {
                item.active = false;
            })
            return;
        }
        state.fastFilter.forEach(item => {
            item.active = false;
            if (item.name === data.name) {
                item.active = data.active;
            }
        })
    },
    SET_USERS_IDS(state, value) {
        state.userIDs = value;
    },
};

const actions = {
    async GET_USER({ state, commit }, id) {
        try {
            const user = await getUserData(id);
            commit('SET_USER', user);
            return state.user;
        } catch (error) {
            console.log(error);
        }
    },
    async GET_USERS({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;
            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.offset = 0;
            let filters = await dispatch('prepareFilters');
            commit('SET_USERS', await getAllUsers(filters));
            state.busy = false;
        } catch (error) {
            console.log(error);
        }
    },
    async GET_NEXT_PAGE({ state, commit, dispatch }) {
        try {
            if (state.next_page && !state.busy) {
                state.busy = true;
                let filters = await dispatch('prepareFilters');
                commit('SET_NEXT_USERS', await getAllUsers(filters));
            }
        } catch (error) {
            console.log(error);
        }
    },
    async GET_USERS_GROUPS({ commit }) {
        commit('SET_GROUPS', await getUserGroups());
    },
    async CREATE_USER({}, user) {
        return await createUser(user);
    },
    async UPDATE_USER({}, { user, id, headers }) {
        return await updateUser(id, user, headers);
    },
    async DELETE_USER({}, id) {
        return await deleteUser(id);
    },
    async CHANGE_PASSWORD({}, { id, data }) {
        return await changePasswordUser(id, data);
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([key, value]) => {
            if (key && (value || value === false || value === 0)) {
                filters[key] = value;
            }
        })
        let activeFilters = Object.keys(filters).filter(item => item !== 'limit' && item !== 'offset');
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    users: state => state.users,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    groups: state => state.groups,
    next: state => !!state.next_page,
    busy: state => state.busy,
    onlyPersonal: state => state.onlyPersonal,
    singleModalMode: state => state.singleModalMode,
    filteredGroup: state => state.filteredGroup,
    fastFilter: state => state.fastFilter,
    usersForNotification: state => state.usersForNotification,
    usersCount: state => state.usersCount,
    userIDs: state => state.userIDs
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

/* eslint-disable */
import {
    getNotifications,
    getNotification,
    getNotificationsForUser,
} from "@/api/notifications";

const state = {
    notifications: [],
    users_list: [],
    user_notifications: [],
    filters: {
        page: 1,
        seen: false
    },
    busy: false,
    next: true,
    activeFilters: [],
    notSeenMessages: 0,
    createForClients: false
};

const mutations = {
    SET_NOTIFICATIONS(state, notifications) {
        state.notifications = notifications;
    },
    SET_NEXT_NOTIFICATIONS(state, res) {
        state.notifications.push(...res);
    },
    SET_USER_NOTIFICATIONS(state, notifications) {
        notifications.forEach(item => {
            if (item.text && item.text.includes('(id:')) {
                let userID = item.text.split('(id:').pop().split(')')[0].trim();
                item.user_id = userID;
            }
        })
        state.user_notifications = notifications;
        state.filters.page = 2;
    },
    SET_NEXT_USER_NOTIFICATIONS(state, res) {
        res.forEach(item => {
            if (item.text && item.text.includes('(id:')) {
                let userID = item.text.split('(id:').pop().split(')')[0].trim();
                item.user_id = userID;
            }
        })
        state.user_notifications = [...state.user_notifications, ...res];
        state.filters.page++;
    },
    SET_FILTER(state, { field, value }) {
        state.filters[field] = value;
    },
    SET_ACTIVE_FILTERS(state, array) {
        state.activeFilters = array;
    },
    SET_USERS_LIST(state, users_list) {
        state.users_list = state.users_list.concat(users_list);
    },
    ADD_USER_NOTIFICATION(state, data) {
        state.user_notifications.unshift(data);
    },
    ADD_USER_TO_USERS_LIST(state, user) {
        state.users_list.push(user);
    },
    REMOVE_USER_FROM_USERS_LIST(state, user) {
        state.users_list = state.users_list.filter(item => item.id !== user.id);
    },
    CLEAR_USERS_LIST(state) {
        state.users_list = [];
    },
    SET_NOT_SEEN_COUNT(state, count) {
        state.notSeenMessages = count;
    },
    CLEAR_USER_NOTIFICATIONS(state) {
        state.user_notifications = [];
    },
    CLEAR_FILTERS_STATE(state) {
        state.filters = {
            page: 1,
            seen: false
        },
        state.next = true;
    },
    SET_CREATE_FOR_CLIENTS(state, value) {
        state.createForClients = value;
    }
};

const actions = {
    async GET_NOTIFICATIONS({ commit }) {
        try {
            const data = await getNotifications();
            commit('SET_NOTIFICATIONS', data);
        } catch (error) {
            console.log(error);
        }
    },
    async GET_USER_NOTIFICATIONS({ state, commit, dispatch }, filter = null) {
        try {
            state.busy = true;

            if (filter) {
                commit('SET_FILTER', filter);
            }
            state.filters.page = 1;
            state.next = true;

            let filters = await dispatch('prepareFilters');

            const data = await getNotificationsForUser(filters)
            commit('SET_USER_NOTIFICATIONS', data);

            state.busy = false;
            return data;
        } catch (error) {
            console.log(error);
        }
    },
    async GET_NEXT_PAGE({ commit, state, dispatch }) {
        if (state.next && !state.busy) {
            try {
                state.busy = true;
                let filters = await dispatch('prepareFilters');

                const res = await getNotificationsForUser(filters);

                if (res.length) {
                    commit("SET_NEXT_USER_NOTIFICATIONS", res);
                } else {
                    state.next = false;
                }
                state.busy = false;
            } catch (error) { 
                console.log(error);
            }
        }
    },
    async GET_NOTIFICATION_DATA({ commit }, id) {
        try {
            const notif = await getNotification(id);
            return notif;
        } catch (error) {
            console.log(error);
        }
    },
    EVENT_HANDLER({ commit, getters, rootGetters }, user) {
        if (!rootGetters['users/singleModalMode']) {
            if (getters['selected'](user.id)) {
                commit('REMOVE_USER_FROM_USERS_LIST', user);
            } else {
                commit('ADD_USER_TO_USERS_LIST', user)
            }
        } else {
            commit('CLEAR_USERS_LIST');
            commit('ADD_USER_TO_USERS_LIST', user);
        }
    },
    prepareFilters({ state, commit }) {
        let filters = {};
        Object.entries(state.filters).forEach(([key, value]) => {
            if (key && (value || value === false || value === 0)) {
                filters[key] = value;
            }
        })
        let activeFilters = Object.keys(filters);
        commit('SET_ACTIVE_FILTERS', activeFilters);
        return filters;
    }
};

const getters = {
    notifications: state => state.notifications,
    user_notifications: state => state.user_notifications,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    users_list: state => state.users_list,
    selected: state => id => !!state.users_list.find(item => item.id === id),
    notSeenMessages: state => state.notSeenMessages,
    next: state => state.next,
    page: state => state.page,
    busy: state => state.busy,
    createForClients: state => state.createForClients
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
